<mat-toolbar class="app-header"
    color="primary">
  <mat-icon aria-label="profile">perm_contact_calendar</mat-icon>
  <span>Agenda</span>
  <div class="spacer"></div>
  <mat-icon aria-label="profile">account_circle</mat-icon>
</mat-toolbar>
<app-shell>
  <router-outlet></router-outlet>
</app-shell>
<mat-toolbar class="app-footer"
    color="primary">Footer</mat-toolbar>
