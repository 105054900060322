import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './components/index';


@NgModule({
  declarations: [ShellComponent],
  imports: [CommonModule, MatListModule, MatSidenavModule, RouterModule,],
  exports: [ShellComponent],
})
export class SharedModule { }
