<mat-card class="admin-card">
  <mat-card-header>
    <mat-card-title>
      Setari
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Rame">
        <div class="admin-card-tab">
        </div>
      </mat-tab>
      <mat-tab label="Lentile"
          class="admin-card-tab">
        <div class="admin-card-tab">
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
