import { Injectable } from '@angular/core';
import { Districts } from '../data/districts';
import { City, District } from '../models/index';
@Injectable({
  providedIn: 'root'
})
export class AgendaHelperService {

  districts: District[] = Districts;
  constructor() { }

  getDistirctsList(): string[] {
    return this.districts.map(d => d.name);
  }

  getCities(districtName: string): City[] {
    const districts = this.districts.filter(d => d.name === districtName);
    return districts ? districts[0].cities.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : [];
  }
}
