    <mat-card class="app-PatientForm-card">
      <mat-card-header>
        <mat-card-title>Pacient</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div [formGroup]="patientForm"
            class="app-PatientForm-form">
          <fieldset class="app-PatientForm-col">
            <legend>Date Pacient</legend>
            <mat-form-field>
              <mat-label>Nume</mat-label>
              <input matInput
                  formControlName="lastName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Prenume</mat-label>
              <input matInput
                  formControlName="firstName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Varsta</mat-label>
              <input matInput
                  formControlName="age" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput
                  formControlName="email" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Telefon</mat-label>
              <input matInput
                  formControlName="phone" />
            </mat-form-field>
          </fieldset>

          <fieldset formGroupName="address"
              class="app-PatientForm-col">
            <legend>Adresa</legend>
            <mat-form-field>
              <mat-label>Strada</mat-label>
              <input matInput
                  formControlName="street" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Numar</mat-label>
              <input matInput
                  formControlName="streetNumber" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Bloc</mat-label>
              <input matInput
                  formControlName="building" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Apartament</mat-label>
              <input matInput
                  formControlName="flat" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Judet</mat-label>
              <mat-select formControlName="district"
                  (selectionChange)="onDistrictSlected($event)">
                <mat-option *ngFor="let district of districts"
                    [value]="district">{{district}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Oras</mat-label>
              <mat-select formControlName="city">
                <mat-option *ngFor="let city of (cities$  |async)"
                    [value]="city.name">{{city.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Cod postal</mat-label>
              <input matInput
                  formControlName="code" />
            </mat-form-field>
          </fieldset>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button
            color="primary"
            (click)="onSubmit()">Save</button>
      </mat-card-actions>
    </mat-card>
