<mat-card class="patient-card">
  <mat-card-header>
    <mat-card-title>Pacienti</mat-card-title>
  </mat-card-header>
  <mat-card-content class="patient-card-content">
    <mat-table [dataSource]="dataSource"
        class="patient-table">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef> Nume </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.lastName }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef> Prenume </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.firstName }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef> Varsta </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.age }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef> Telefon </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.phone }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef> Adresa </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.address?.street }},
          {{ element.address?.streetNumber }},
          {{ element.address?.building }},
          {{ element.address?.flat }},
          {{ element.address?.city }},
          {{ element.address?.district }},
          {{ element.address?.code }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button
              aria-label="Edit"
              (click)="edit(element)">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </mat-card-content>

  <mat-card-actions>
    <a [routerLink]="['/patient/new']">Adauga pacient </a>
  </mat-card-actions>
</mat-card>
