import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminModule } from './admin/index';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PatientsModule } from './patients/index';
import { PlanningModule } from './planning/index';
import { SettingsModule } from './settings/index';
import { SharedModule } from './shared/index';
import { WarrantyComponent } from './warranty/index';

@NgModule({
  declarations: [AppComponent, WarrantyComponent],
  imports: [
    BrowserModule,
    MatIconModule,
    MatToolbarModule,
    AppRoutingModule,
    AdminModule,
    PlanningModule,
    PatientsModule,
    SettingsModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
