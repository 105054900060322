<mat-card class="settings-card">
  <mat-card-header>
    <mat-card-title>
      Setari
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Programari">
        <div class="settings-card-tab">
          <mat-label>Durata Programare</mat-label><br />
          <mat-button-toggle-group name="program"
              aria-label="Font Style">
            <mat-button-toggle value="bold">15 min</mat-button-toggle>
            <mat-button-toggle value="italic">30 min</mat-button-toggle>
            <mat-button-toggle value="underline">60min</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </mat-tab>
      <mat-tab label="Orar"
          class="settings-card-tab">
        <div class="settings-card-tab">
          <div class="example-form"
              [formGroup]="settingsForm">

            <div class="row">
              <mat-checkbox class="day"
                  formControlName="monday"
                  (change)="toggleDisable($event,'monday')"
                  color="primary">
                Luni
              </mat-checkbox>
              <mat-form-field class="startTime">
                <input matInput
                    type="time"
                    formControlName="mondayStartTime">
              </mat-form-field>
              <mat-form-field class="endTime">
                <input matInput
                    type="time"
                    formControlName="mondayEndTime">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox class="day"
                  formControlName="tuesday"
                  (change)="toggleDisable($event,'tuesday')"
                  color="primary">
                Tuesday
              </mat-checkbox>
              <mat-form-field class="startTime">
                <input matInput
                    type="time"
                    formControlName="tuesdayStartTime">
              </mat-form-field>
              <mat-form-field class="endTime">
                <input matInput
                    type="time"
                    formControlName="tuesdayEndTime">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox class="day"
                  formControlName="wednesday"
                  (change)="toggleDisable($event,'wednesday')"
                  color="primary">
                Wednesday
              </mat-checkbox>
              <mat-form-field class="startTime">
                <input matInput
                    type="time"
                    formControlName="wednesdayStartTime">
              </mat-form-field>
              <mat-form-field class="endTime">
                <input matInput
                    type="time"
                    formControlName="wednesdayEndTime">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox class="day"
                  formControlName="thursday"
                  (change)="toggleDisable($event,'thursday')"
                  color="primary">
                Thursday
              </mat-checkbox>
              <mat-form-field class="startTime">
                <input matInput
                    type="time"
                    formControlName="thursdayStartTime">
              </mat-form-field>
              <mat-form-field class="endTime">
                <input matInput
                    type="time"
                    formControlName="thursdayEndTime">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox class="day"
                  formControlName="friday"
                  (change)="toggleDisable($event,'friday')"
                  color="primary">
                Friday
              </mat-checkbox>
              <mat-form-field class="startTime">
                <input matInput
                    type="time"
                    formControlName="fridayStartTime">
              </mat-form-field>
              <mat-form-field class="endTime">
                <input matInput
                    type="time"
                    formControlName="fridayEndTime">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox class="day"
                  formControlName="saturday"
                  (change)="toggleDisable($event,'saturday')"
                  color="primary">
                Saturday
              </mat-checkbox>
              <mat-form-field class="startTime">
                <input matInput
                    type="time"
                    formControlName="saturdayStartTime">
              </mat-form-field>
              <mat-form-field class="endTime">
                <input matInput
                    type="time"
                    formControlName="saturdayEndTime">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox class="day"
                  formControlName="sunday"
                  (change)="toggleDisable($event,'sunday')"
                  color="primary">
                Sunday
              </mat-checkbox>
              <mat-form-field class="startTime">
                <input matInput
                    type="time"
                    formControlName="sundayStartTime">
              </mat-form-field>
              <mat-form-field class="endTime">
                <input matInput
                    type="time"
                    formControlName="sundayEndTime">
              </mat-form-field>
            </div>
            <button mat-stroked-button
                color="primary"
                (click)="save()">
              Save
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Third"
          class="settings-card-tab">
        <div class="settings-card-tab">
          aaaa
        </div>
      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
</mat-card>
