<mat-card class="app-Planning-card">
  <mat-card-header>
    <mat-card-title>Planificare</mat-card-title>
  </mat-card-header>
  <mat-card-content class="app-Planning-card-content">
    <div class="app-Planning-card-content">
      <app-planning-day-view></app-planning-day-view>
      <app-planning-calendar></app-planning-calendar>
    </div>
  </mat-card-content>
</mat-card>
