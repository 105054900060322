import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgendaHelperService, City } from '../../shared/index';
import { PatientService } from '../patients.service';
import { Patient } from '../patinet.model';
import { BehaviorSubject } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-PatientForm' },
  providers: [AgendaHelperService]
})
export class PatientFormComponent implements OnInit {
  currentPatient: Patient = {};
  patientForm: FormGroup = new FormGroup({});
  districts: string[] = [];
  cities$: BehaviorSubject<City[]> = new BehaviorSubject(null);
  cities: City[] = [];

  constructor(private patientService: PatientService,
    private agendaHelperService: AgendaHelperService,
    @Inject(FormBuilder) private fb: FormBuilder) { }


  ngOnInit(): void {
    this.districts = this.agendaHelperService.getDistirctsList();
    this.currentPatient = this.patientService.patient || {
      id: '5efe057a69198be5184f4e7d',
      age: 33,
      firstName: 'Ida',
      lastName: 'Dominguez',
      company: 'GEEKOLA',
      email: 'ida.dominguez@geekola.tv',
      phone: '+1 (892) 582-2234',
      address: {
        street: 'Hinsdale Street',
        streetNumber: 927,
        building: null,
        flat: null,
        city: 'Vincent',
        district: 'Minnesota',
        code: '3826',
      }
    };
    this.patientForm = this.fb.group({
      lastName: [this.currentPatient.lastName, [Validators.required]],
      firstName: [this.currentPatient.firstName, [Validators.required]],
      age: [this.currentPatient.age, [Validators.required]],
      phone: [this.currentPatient.phone, [Validators.required]],
      email: [this.currentPatient.email, Validators.email],
      address: this.fb.group({
        street: [this.currentPatient.address.street, [Validators.required]],
        streetNumber: [this.currentPatient.address.streetNumber, [Validators.required]],
        building: [this.currentPatient.address.building, []],
        flat: [this.currentPatient.address.flat, []],
        city: [this.currentPatient.address.city, [Validators.required]],
        district: [this.currentPatient.address.district, [Validators.required]],
        code: [this.currentPatient.address.code, []],
      })
    });
  }
  onSubmit(): void {
    console.log(this.currentPatient);
    console.log(this.patientForm.value);
    console.log(JSON.stringify(this.currentPatient));
  }
  onDistrictSlected(district: MatSelectChange): void {
    this.currentPatient.address.city = null;

    this.cities$.next(this.agendaHelperService.getCities(district.value));
    console.log(this.cities);

  }
}
