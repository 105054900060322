import { District } from '../models/index';

export const Districts: District[] = [
    {
        auto: 'AB ',
        name: 'Alba ',
        cities: [
            { name: 'Abrud ' },
            { name: 'Aiud ' },
            { name: 'Alba Iulia ' },
            { name: 'Albac ' },
            { name: 'Almaşu Mare ', simpleName: 'Almasu Mare ' },
            { name: 'Arieşeni ', simpleName: 'Arieseni ' },
            { name: 'Avram Iancu ' },
            { name: 'Baia de Arieş ', simpleName: 'Baia de Aries ' },
            { name: 'Berghin ' },
            { name: 'Bistra ' },
            { name: 'Blaj ' },
            { name: 'Blandiana ' },
            { name: 'Bucium ' },
            { name: 'Cenade ' },
            { name: 'Ceru-Băcăinţi ', simpleName: 'Ceru-Bacainti ' },
            { name: 'Cetatea de Baltă ', simpleName: 'Cetatea de Balta ' },
            { name: 'Ciugud ' },
            { name: 'Ciuruleasa ' },
            { name: 'Cricău ', simpleName: 'Cricau ' },
            { name: 'Crăciunelu de Jos ', simpleName: 'Craciunelu de Jos ' },
            { name: 'Cugir ' },
            { name: 'Câlnic ', simpleName: 'Calnic ' },
            { name: 'Câmpeni ', simpleName: 'Campeni ' },
            { name: 'Daia Română ', simpleName: 'Daia Romana ' },
            { name: 'Doştat ', simpleName: 'Dostat ' },
            { name: 'Fărău ', simpleName: 'Farau ' },
            { name: 'Galda de Jos ' },
            { name: 'Gârbova ', simpleName: 'Garbova ' },
            { name: 'Gârda de Sus ', simpleName: 'Garda de Sus ' },
            { name: 'Hopârta ', simpleName: 'Hoparta ' },
            { name: 'Horea ' },
            { name: 'Ighiu ' },
            { name: 'Jidvei ' },
            { name: 'Livezile ' },
            { name: 'Lopadea Nouă ', simpleName: 'Lopadea Noua ' },
            { name: 'Lunca Mureşului ', simpleName: 'Lunca Muresului ' },
            { name: 'Lupşa ', simpleName: 'Lupsa ' },
            { name: 'Meteş ', simpleName: 'Metes ' },
            { name: 'Mihalţ ', simpleName: 'Mihalt ' },
            { name: 'Mirăslău ', simpleName: 'Miraslau ' },
            { name: 'Mogoş ', simpleName: 'Mogos ' },
            { name: 'Noşlac ', simpleName: 'Noslac ' },
            { name: 'Ocna Mureş ', simpleName: 'Ocna Mures ' },
            { name: 'Ocoliş ', simpleName: 'Ocolis ' },
            { name: 'Ohaba ' },
            { name: 'Poiana Vadului ' },
            { name: 'Ponor ' },
            { name: 'Roşia Montană ', simpleName: 'Rosia Montana ' },
            { name: 'Roşia de Secaş ', simpleName: 'Rosia de Secas ' },
            { name: 'Râmetea ', simpleName: 'Rametea ' },
            { name: 'Râmeţ ', simpleName: 'Ramet ' },
            { name: 'Rădeşti ', simpleName: 'Radesti ' },
            { name: 'Scărişoara ', simpleName: 'Scarisoara ' },
            { name: 'Sebeş ', simpleName: 'Sebes ' },
            { name: 'Sohodol ' },
            { name: 'Stremţ ', simpleName: 'Stremt ' },
            { name: 'Sâncel ', simpleName: 'Sancel ' },
            { name: 'Sântimbru ', simpleName: 'Santimbru ' },
            { name: 'Săliştea ', simpleName: 'Salistea ' },
            { name: 'Săsciori ', simpleName: 'Sasciori ' },
            { name: 'Teiuş ', simpleName: 'Teius ' },
            { name: 'Unirea ' },
            { name: 'Vadu Moţilor ', simpleName: 'Vadu Motilor ' },
            { name: 'Valea Lungă ', simpleName: 'Valea Lunga ' },
            { name: 'Vidra ' },
            { name: 'Vinţu de Jos ', simpleName: 'Vintu de Jos ' },
            { name: 'Zlatna ' },
            { name: 'Întregalde ', simpleName: 'Intregalde ' },
            { name: 'Şibot ', simpleName: 'Sibot ' },
            { name: 'Şona ', simpleName: 'Sona ' },
            { name: 'Şpring ', simpleName: 'Spring ' },
            { name: 'Şugag ', simpleName: 'Sugag ' },
            { name: 'Abrud-Sat ', village: 'Abrud ' },
            { name: 'Gura Cornei ', village: 'Abrud ' },
            { name: 'Soharu ', village: 'Abrud ' },
            { name: 'Aiudul de Sus ', village: 'Aiud ' },
            { name: 'Ciumbrud ', village: 'Aiud ' },
            { name: 'Gâmbaş ', simpleName: 'Gambas ', village: 'Aiud ' },
            { name: 'Gârbova de Jos ', simpleName: 'Garbova de Jos ', village: 'Aiud ' },
            { name: 'Gârbova de Sus ', simpleName: 'Garbova de Sus ', village: 'Aiud ' },
            { name: 'Gârboviţa ', simpleName: 'Garbovita ', village: 'Aiud ' },
            { name: 'Măgina ', simpleName: 'Magina ', village: 'Aiud ' },
            { name: 'Păgida ', simpleName: 'Pagida ', village: 'Aiud ' },
            { name: 'Sâncrai ', simpleName: 'Sancrai ', village: 'Aiud ' },
            { name: 'Ţifra ', simpleName: 'Tifra ', village: 'Aiud ' },
            { name: 'Bărăbanţ ', simpleName: 'Barabant ', village: 'Alba Iulia ' },
            { name: 'Miceşti ', simpleName: 'Micesti ', village: 'Alba Iulia ' },
            { name: 'Oarda ', village: 'Alba Iulia ' },
            { name: 'Pâclişa ', simpleName: 'Paclisa ', village: 'Alba Iulia ' },
            { name: 'Budăieşti ', simpleName: 'Budaiesti ', village: 'Albac ' },
            { name: 'Bărăşti ', simpleName: 'Barasti ', village: 'Albac ' },
            { name: 'Cioneşti ', simpleName: 'Cionesti ', village: 'Albac ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Albac ' },
            { name: 'Dealu Lămăşoi ', simpleName: 'Dealu Lamasoi ', village: 'Albac ' },
            { name: 'Deve ', village: 'Albac ' },
            { name: 'După Pleşe ', simpleName: 'Dupa Plese ', village: 'Albac ' },
            { name: 'Faţa ', simpleName: 'Fata ', village: 'Albac ' },
            { name: 'Pleşeşti ', simpleName: 'Plesesti ', village: 'Albac ' },
            { name: 'Potionci ', village: 'Albac ' },
            { name: 'Rogoz ', village: 'Albac ' },
            { name: 'Roşeşti ', simpleName: 'Rosesti ', village: 'Albac ' },
            { name: 'Ruseşti ', simpleName: 'Rusesti ', village: 'Albac ' },
            { name: 'Sohodol ', village: 'Albac ' },
            { name: 'Tamboreşti ', simpleName: 'Tamboresti ', village: 'Albac ' },
            { name: 'Almaşu de Mijloc ', simpleName: 'Almasu de Mijloc ', village: 'Almasu Mare ' },
            { name: 'Brădet ', simpleName: 'Bradet ', village: 'Almasu Mare ' },
            { name: 'Cheile Cibului ', village: 'Almasu Mare ' },
            { name: 'Cib ', village: 'Almasu Mare ' },
            { name: 'Glod ', village: 'Almasu Mare ' },
            { name: 'Nădăştia ', simpleName: 'Nadastia ', village: 'Almasu Mare ' },
            { name: 'Avrămeşti ', simpleName: 'Avramesti ', village: 'Arieseni ' },
            { name: 'Bubeşti ', simpleName: 'Bubesti ', village: 'Arieseni ' },
            { name: 'Casa de Piatră ', simpleName: 'Casa de Piatra ', village: 'Arieseni ' },
            { name: 'Cobleş ', simpleName: 'Cobles ', village: 'Arieseni ' },
            { name: 'Dealu Bajului ', village: 'Arieseni ' },
            { name: 'Faţa Cristesei ', simpleName: 'Fata Cristesei ', village: 'Arieseni ' },
            { name: 'Faţa Lăpuşului ', simpleName: 'Fata Lapusului ', village: 'Arieseni ' },
            { name: 'Galbena ', village: 'Arieseni ' },
            { name: 'Hodobana ', village: 'Arieseni ' },
            { name: 'Izlaz ', village: 'Arieseni ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Arieseni ' },
            { name: 'Pănteşti ', simpleName: 'Pantesti ', village: 'Arieseni ' },
            { name: 'Pătrăhăiţeşti ', simpleName: 'Patrahaitesti ', village: 'Arieseni ' },
            { name: 'Raviceşti ', simpleName: 'Ravicesti ', village: 'Arieseni ' },
            { name: 'Sturu ', village: 'Arieseni ' },
            { name: 'Vanvuceşti ', simpleName: 'Vanvucesti ', village: 'Arieseni ' },
            { name: 'Ştei-Arieşeni ', simpleName: 'Stei-Arieseni ', village: 'Arieseni ' },
            { name: 'Achimeţeşti ', simpleName: 'Achimetesti ', village: 'Avram Iancu ' },
            { name: 'Avrămeşti ', simpleName: 'Avramesti ', village: 'Avram Iancu ' },
            { name: 'Boldeşti ', simpleName: 'Boldesti ', village: 'Avram Iancu ' },
            { name: 'Bădăi ', simpleName: 'Badai ', village: 'Avram Iancu ' },
            { name: 'Coceşti ', simpleName: 'Cocesti ', village: 'Avram Iancu ' },
            { name: 'Cocoşeşti ', simpleName: 'Cocosesti ', village: 'Avram Iancu ' },
            { name: 'Coroieşti ', simpleName: 'Coroiesti ', village: 'Avram Iancu ' },
            { name: 'Cândeşti ', simpleName: 'Candesti ', village: 'Avram Iancu ' },
            { name: 'Cârăşti ', simpleName: 'Carasti ', village: 'Avram Iancu ' },
            { name: 'Cârţuleşti ', simpleName: 'Cartulesti ', village: 'Avram Iancu ' },
            { name: 'Călugăreşti ', simpleName: 'Calugaresti ', village: 'Avram Iancu ' },
            { name: 'Căsoaia ', simpleName: 'Casoaia ', village: 'Avram Iancu ' },
            { name: 'Dealu Crişului ', simpleName: 'Dealu Crisului ', village: 'Avram Iancu ' },
            { name: 'Doleşti ', simpleName: 'Dolesti ', village: 'Avram Iancu ' },
            { name: 'Dumăceşti ', simpleName: 'Dumacesti ', village: 'Avram Iancu ' },
            { name: 'Gojeieşti ', simpleName: 'Gojeiesti ', village: 'Avram Iancu ' },
            { name: 'Helereşti ', simpleName: 'Heleresti ', village: 'Avram Iancu ' },
            { name: 'Inceşti ', simpleName: 'Incesti ', village: 'Avram Iancu ' },
            { name: 'Jojei ', village: 'Avram Iancu ' },
            { name: 'Mărteşti ', simpleName: 'Martesti ', village: 'Avram Iancu ' },
            { name: 'Orgeşti ', simpleName: 'Orgesti ', village: 'Avram Iancu ' },
            { name: 'Plai ', village: 'Avram Iancu ' },
            { name: 'Puşeleşti ', simpleName: 'Puselesti ', village: 'Avram Iancu ' },
            { name: 'Pătruţeşti ', simpleName: 'Patrutesti ', village: 'Avram Iancu ' },
            { name: 'Târsa ', simpleName: 'Tarsa ', village: 'Avram Iancu ' },
            { name: 'Târsa-Plai ', simpleName: 'Tarsa-Plai ', village: 'Avram Iancu ' },
            { name: 'Valea Maciului ', village: 'Avram Iancu ' },
            { name: 'Valea Uţului ', simpleName: 'Valea Utului ', village: 'Avram Iancu ' },
            { name: 'Verdeşti ', simpleName: 'Verdesti ', village: 'Avram Iancu ' },
            { name: 'Vidrişoara ', simpleName: 'Vidrisoara ', village: 'Avram Iancu ' },
            { name: 'Şoiceşti ', simpleName: 'Soicesti ', village: 'Avram Iancu ' },
            { name: 'Şterteşti ', simpleName: 'Stertesti ', village: 'Avram Iancu ' },
            { name: 'Brăzeşti ', simpleName: 'Brazesti ', village: 'Baia De Aries ' },
            { name: 'Cioara de Sus ', village: 'Baia De Aries ' },
            { name: 'Muncelu ', village: 'Baia De Aries ' },
            { name: 'Sartăş ', simpleName: 'Sartas ', village: 'Baia De Aries ' },
            { name: 'Simuleşti ', simpleName: 'Simulesti ', village: 'Baia De Aries ' },
            { name: 'Ghirbom ', village: 'Berghin ' },
            { name: 'Henig ', village: 'Berghin ' },
            { name: 'Straja ', village: 'Berghin ' },
            { name: 'Aroneşti ', simpleName: 'Aronesti ', village: 'Bistra ' },
            { name: 'Bârleşti ', simpleName: 'Barlesti ', village: 'Bistra ' },
            { name: 'Băleşti ', simpleName: 'Balesti ', village: 'Bistra ' },
            { name: 'Băleşti-Cătun ', simpleName: 'Balesti-Catun ', village: 'Bistra ' },
            { name: 'Cheleteni ', village: 'Bistra ' },
            { name: 'Ciuldeşti ', simpleName: 'Ciuldesti ', village: 'Bistra ' },
            { name: 'Creţeşti ', simpleName: 'Cretesti ', village: 'Bistra ' },
            { name: 'Dealu Muntelui ', village: 'Bistra ' },
            { name: 'Durăşti ', simpleName: 'Durasti ', village: 'Bistra ' },
            { name: 'Dâmbureni ', simpleName: 'Dambureni ', village: 'Bistra ' },
            { name: 'Gârde ', simpleName: 'Garde ', village: 'Bistra ' },
            { name: 'Găneşti ', simpleName: 'Ganesti ', village: 'Bistra ' },
            { name: 'Hodişeşti ', simpleName: 'Hodisesti ', village: 'Bistra ' },
            { name: 'Hudriceşti ', simpleName: 'Hudricesti ', village: 'Bistra ' },
            { name: 'Lipaia ', village: 'Bistra ' },
            { name: 'Lunca Largă ', simpleName: 'Lunca Larga ', village: 'Bistra ' },
            { name: 'Lunca Merilor ', village: 'Bistra ' },
            { name: 'Mihăieşti ', simpleName: 'Mihaiesti ', village: 'Bistra ' },
            { name: 'Novăceşti ', simpleName: 'Novacesti ', village: 'Bistra ' },
            { name: 'Nămaş ', simpleName: 'Namas ', village: 'Bistra ' },
            { name: 'Perjeşti ', simpleName: 'Perjesti ', village: 'Bistra ' },
            { name: 'Poiana ', village: 'Bistra ' },
            { name: 'Poiu ', village: 'Bistra ' },
            { name: 'Runcuri ', village: 'Bistra ' },
            { name: 'Rătitiş ', simpleName: 'Ratitis ', village: 'Bistra ' },
            { name: 'Sălăgeşti ', simpleName: 'Salagesti ', village: 'Bistra ' },
            { name: 'Tolăceşti ', simpleName: 'Tolacesti ', village: 'Bistra ' },
            { name: 'Tomnatec ', village: 'Bistra ' },
            { name: 'Trişoreşti ', simpleName: 'Trisoresti ', village: 'Bistra ' },
            { name: 'Vârşi-Rontu ', simpleName: 'Varsi-Rontu ', village: 'Bistra ' },
            { name: 'Vârşii Mari ', simpleName: 'Varsii Mari ', village: 'Bistra ' },
            { name: 'Vârşii Mici ', simpleName: 'Varsii Mici ', village: 'Bistra ' },
            { name: 'Ştefanca ', simpleName: 'Stefanca ', village: 'Bistra ' },
            { name: 'Ţărăneşti ', simpleName: 'Taranesti ', village: 'Bistra ' },
            { name: 'Deleni-Obârşie ', simpleName: 'Deleni-Obarsie ', village: 'Blaj ' },
            { name: 'Fliteşti ', simpleName: 'Flitesti ', village: 'Blaj ' },
            { name: 'Izvoarele ', village: 'Blaj ' },
            { name: 'Mănărade ', simpleName: 'Manarade ', village: 'Blaj ' },
            { name: 'Petrisat ', village: 'Blaj ' },
            { name: 'Spătac ', simpleName: 'Spatac ', village: 'Blaj ' },
            { name: 'Tiur ', village: 'Blaj ' },
            { name: 'Veza ', village: 'Blaj ' },
            { name: 'Acmariu ', village: 'Blandiana ' },
            { name: 'Ibru ', village: 'Blandiana ' },
            { name: 'Poieni ', village: 'Blandiana ' },
            { name: 'Răcătău ', simpleName: 'Racatau ', village: 'Blandiana ' },
            { name: 'Angheleşti ', simpleName: 'Anghelesti ', village: 'Bucium ' },
            { name: 'Bisericani ', village: 'Bucium ' },
            { name: 'Bucium-Sat ', village: 'Bucium ' },
            { name: 'Cerbu ', village: 'Bucium ' },
            { name: 'Ciuculeşti ', simpleName: 'Ciuculesti ', village: 'Bucium ' },
            { name: 'Coleşeni ', simpleName: 'Coleseni ', village: 'Bucium ' },
            { name: 'Dogăreşti ', simpleName: 'Dogaresti ', village: 'Bucium ' },
            { name: 'Fereşti ', simpleName: 'Feresti ', village: 'Bucium ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Bucium ' },
            { name: 'Gura Izbitei ', village: 'Bucium ' },
            { name: 'Heleşti ', simpleName: 'Helesti ', village: 'Bucium ' },
            { name: 'Izbicioara ', village: 'Bucium ' },
            { name: 'Izbita ', village: 'Bucium ' },
            { name: 'Jurcuieşti ', simpleName: 'Jurcuiesti ', village: 'Bucium ' },
            { name: 'Lupuleşti ', simpleName: 'Lupulesti ', village: 'Bucium ' },
            { name: 'Muntari ', village: 'Bucium ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Bucium ' },
            { name: 'Petreni ', village: 'Bucium ' },
            { name: 'Poiana ', village: 'Bucium ' },
            { name: 'Poieni ', village: 'Bucium ' },
            { name: 'Stâlnişoara ', simpleName: 'Stalnisoara ', village: 'Bucium ' },
            { name: 'Valea Abruzel ', village: 'Bucium ' },
            { name: 'Valea Albă ', simpleName: 'Valea Alba ', village: 'Bucium ' },
            { name: 'Valea Cerbului ', village: 'Bucium ' },
            { name: 'Valea Negrilesii ', village: 'Bucium ' },
            { name: 'Valea Poienii ', village: 'Bucium ' },
            { name: 'Valea Şesii ', simpleName: 'Valea Sesii ', village: 'Bucium ' },
            { name: 'Vâlcea ', simpleName: 'Valcea ', village: 'Bucium ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Bucium ' },
            { name: 'Cut ', village: 'Calnic ' },
            { name: 'Deal ', village: 'Calnic ' },
            { name: 'Bonceşti ', simpleName: 'Boncesti ', village: 'Campeni ' },
            { name: 'Borleşti ', simpleName: 'Borlesti ', village: 'Campeni ' },
            { name: 'Boteşti ', simpleName: 'Botesti ', village: 'Campeni ' },
            { name: 'Certege ', village: 'Campeni ' },
            { name: 'Coasta Vâscului ', simpleName: 'Coasta Vascului ', village: 'Campeni ' },
            { name: 'Dealu Bistrii ', village: 'Campeni ' },
            { name: 'Dealu Capsei ', village: 'Campeni ' },
            { name: 'Dric ', village: 'Campeni ' },
            { name: 'Dănduţ ', simpleName: 'Dandut ', village: 'Campeni ' },
            { name: 'Faţa Abrudului ', simpleName: 'Fata Abrudului ', village: 'Campeni ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Campeni ' },
            { name: 'Furduieşti ', simpleName: 'Furduiesti ', village: 'Campeni ' },
            { name: 'Mihoeşti ', simpleName: 'Mihoesti ', village: 'Campeni ' },
            { name: 'Motorăşti ', simpleName: 'Motorasti ', village: 'Campeni ' },
            { name: 'Peste Valea Bistrii ', village: 'Campeni ' },
            { name: 'Poduri ', village: 'Campeni ' },
            { name: 'Sorliţa ', simpleName: 'Sorlita ', village: 'Campeni ' },
            { name: 'Tomuşeşti ', simpleName: 'Tomusesti ', village: 'Campeni ' },
            { name: 'Valea Bistrii ', village: 'Campeni ' },
            { name: 'Valea Caselor ', village: 'Campeni ' },
            { name: 'Vârşi ', simpleName: 'Varsi ', village: 'Campeni ' },
            { name: 'Capu Dealului ', village: 'Cenade ' },
            { name: 'Gorgan ', village: 'Cenade ' },
            { name: 'Cergău Mare ', simpleName: 'Cergau Mare ', village: 'Cergau ' },
            { name: 'Cergău Mic ', simpleName: 'Cergau Mic ', village: 'Cergau ' },
            { name: 'Lupu ', village: 'Cergau ' },
            { name: 'Bolovăneşti ', simpleName: 'Bolovanesti ', village: 'Ceru-Bacainti ' },
            { name: 'Bulbuc ', village: 'Ceru-Bacainti ' },
            { name: 'Cucuta ', village: 'Ceru-Bacainti ' },
            { name: 'Curpeni ', village: 'Ceru-Bacainti ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Ceru-Bacainti ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Ceru-Bacainti ' },
            { name: 'Groşi ', simpleName: 'Grosi ', village: 'Ceru-Bacainti ' },
            { name: 'Valea Mare ', village: 'Ceru-Bacainti ' },
            { name: 'Viezuri ', village: 'Ceru-Bacainti ' },
            { name: 'Craciunelu de Sus ', village: 'Cetatea De Balta ' },
            { name: 'Sântămărie ', simpleName: 'Santamarie ', village: 'Cetatea De Balta ' },
            { name: 'Tătârlaua ', simpleName: 'Tatarlaua ', village: 'Cetatea De Balta ' },
            { name: 'Drâmbar ', simpleName: 'Drambar ', village: 'Ciugud ' },
            { name: 'Hăpria ', simpleName: 'Hapria ', village: 'Ciugud ' },
            { name: 'Limba ', village: 'Ciugud ' },
            { name: 'Teleac ', village: 'Ciugud ' },
            { name: 'Şeuşa ', simpleName: 'Seusa ', village: 'Ciugud ' },
            { name: 'Bidigeşti ', simpleName: 'Bidigesti ', village: 'Ciuruleasa ' },
            { name: 'Bodreşti ', simpleName: 'Bodresti ', village: 'Ciuruleasa ' },
            { name: 'Bogleşti ', simpleName: 'Boglesti ', village: 'Ciuruleasa ' },
            { name: 'Buninginea ', village: 'Ciuruleasa ' },
            { name: 'Gheduleşti ', simpleName: 'Ghedulesti ', village: 'Ciuruleasa ' },
            { name: 'Morăreşti ', simpleName: 'Moraresti ', village: 'Ciuruleasa ' },
            { name: 'Mătişeşti ', simpleName: 'Matisesti ', village: 'Ciuruleasa ' },
            { name: 'Vulcan ', village: 'Ciuruleasa ' },
            { name: 'Bucerdea Grânoasă ', simpleName: 'Bucerdea Granoasa ', village: 'Craciunelu De Jos ' },
            { name: 'Cornu ', village: 'Craciunelu De Jos ' },
            { name: 'Pânca ', simpleName: 'Panca ', village: 'Craciunelu De Jos ' },
            { name: 'Pădure ', simpleName: 'Padure ', village: 'Craciunelu De Jos ' },
            { name: 'Craiva ', village: 'Cricau ' },
            { name: 'Tibru ', village: 'Cricau ' },
            { name: 'Bocşitura ', simpleName: 'Bocsitura ', village: 'Cugir ' },
            { name: 'Bucuru ', village: 'Cugir ' },
            { name: 'Călene ', simpleName: 'Calene ', village: 'Cugir ' },
            { name: 'Feţeni ', simpleName: 'Feteni ', village: 'Cugir ' },
            { name: 'Goaşele ', simpleName: 'Goasele ', village: 'Cugir ' },
            { name: 'Mugeşti ', simpleName: 'Mugesti ', village: 'Cugir ' },
            { name: 'Vinerea ', village: 'Cugir ' },
            { name: 'Boz ', village: 'Dostat ' },
            { name: 'Dealu Doştatului ', simpleName: 'Dealu Dostatului ', village: 'Dostat ' },
            { name: 'Heria ', village: 'Farau ' },
            { name: 'Medveş ', simpleName: 'Medves ', village: 'Farau ' },
            { name: 'Sânbenedic ', simpleName: 'Sanbenedic ', village: 'Farau ' },
            { name: 'Şilea ', simpleName: 'Silea ', village: 'Farau ' },
            { name: 'Benic ', village: 'Galda De Jos ' },
            { name: 'Cetea ', village: 'Galda De Jos ' },
            { name: 'Galda de Sus ', village: 'Galda De Jos ' },
            { name: 'Lupşeni ', simpleName: 'Lupseni ', village: 'Galda De Jos ' },
            { name: 'Mesentea ', village: 'Galda De Jos ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Galda De Jos ' },
            { name: 'Oiejdea ', village: 'Galda De Jos ' },
            { name: 'Poiana Galdei ', village: 'Galda De Jos ' },
            { name: 'Răicani ', simpleName: 'Raicani ', village: 'Galda De Jos ' },
            { name: 'Zăgriş ', simpleName: 'Zagris ', village: 'Galda De Jos ' },
            { name: 'Cărpiniş ', simpleName: 'Carpinis ', village: 'Garbova ' },
            { name: 'Reciu ', village: 'Garbova ' },
            { name: 'Biharia ', village: 'Garda De Sus ' },
            { name: 'Dealu Frumos ', village: 'Garda De Sus ' },
            { name: 'Dealu Ordâncuşii ', simpleName: 'Dealu Ordancusii ', village: 'Garda De Sus ' },
            { name: 'Dobreşti ', simpleName: 'Dobresti ', village: 'Garda De Sus ' },
            { name: 'Gheţari ', simpleName: 'Ghetari ', village: 'Garda De Sus ' },
            { name: 'Gârda Seacă ', simpleName: 'Garda Seaca ', village: 'Garda De Sus ' },
            { name: 'Hanăşeşti ', simpleName: 'Hanasesti ', village: 'Garda De Sus ' },
            { name: 'Huzăreşti ', simpleName: 'Huzaresti ', village: 'Garda De Sus ' },
            { name: 'Izvoarele ', village: 'Garda De Sus ' },
            { name: 'Munună ', simpleName: 'Mununa ', village: 'Garda De Sus ' },
            { name: 'Ocoale ', village: 'Garda De Sus ' },
            { name: 'Plai ', village: 'Garda De Sus ' },
            { name: 'Plişti ', simpleName: 'Plisti ', village: 'Garda De Sus ' },
            { name: 'Scoarţa ', simpleName: 'Scoarta ', village: 'Garda De Sus ' },
            { name: 'Snide ', village: 'Garda De Sus ' },
            { name: 'Suceşti ', simpleName: 'Sucesti ', village: 'Garda De Sus ' },
            { name: 'Silivaş ', simpleName: 'Silivas ', village: 'Hoparta ' },
            { name: 'Turdaş ', simpleName: 'Turdas ', village: 'Hoparta ' },
            { name: 'Vama Seacă ', simpleName: 'Vama Seaca ', village: 'Hoparta ' },
            { name: 'Şpălnaca ', simpleName: 'Spalnaca ', village: 'Hoparta ' },
            { name: 'Baba ', village: 'Horea ' },
            { name: 'Buteşti ', simpleName: 'Butesti ', village: 'Horea ' },
            { name: 'Dârleşti ', simpleName: 'Darlesti ', village: 'Horea ' },
            { name: 'Fericet ', village: 'Horea ' },
            { name: 'Giurgiuţ ', simpleName: 'Giurgiut ', village: 'Horea ' },
            { name: 'Mănceşti ', simpleName: 'Mancesti ', village: 'Horea ' },
            { name: 'Mătişeşti ', simpleName: 'Matisesti ', village: 'Horea ' },
            { name: 'Niculeşti ', simpleName: 'Niculesti ', village: 'Horea ' },
            { name: 'Petreasa ', village: 'Horea ' },
            { name: 'Preluca ', village: 'Horea ' },
            { name: 'Pătruşeşti ', simpleName: 'Patrusesti ', village: 'Horea ' },
            { name: 'Teiu ', village: 'Horea ' },
            { name: 'Trifeşti ', simpleName: 'Trifesti ', village: 'Horea ' },
            { name: 'Zânzeşti ', simpleName: 'Zanzesti ', village: 'Horea ' },
            { name: 'Bucerdea Vânoasă ', simpleName: 'Bucerdea Vanoasa ', village: 'Ighiu ' },
            { name: 'Ighiel ', village: 'Ighiu ' },
            { name: 'Şard ', simpleName: 'Sard ', village: 'Ighiu ' },
            { name: 'Ţelna ', simpleName: 'Telna ', village: 'Ighiu ' },
            { name: 'Dealu Geoagiului ', village: 'Intregalde ' },
            { name: 'Ghioncani ', village: 'Intregalde ' },
            { name: 'Ilieşti ', simpleName: 'Iliesti ', village: 'Intregalde ' },
            { name: 'Ivăniş ', simpleName: 'Ivanis ', village: 'Intregalde ' },
            { name: 'Modoleşti ', simpleName: 'Modolesti ', village: 'Intregalde ' },
            { name: 'Mărineşti ', simpleName: 'Marinesti ', village: 'Intregalde ' },
            { name: 'Necrileşti ', simpleName: 'Necrilesti ', village: 'Intregalde ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Intregalde ' },
            { name: 'Sfârcea ', simpleName: 'Sfarcea ', village: 'Intregalde ' },
            { name: 'Tecşeşti ', simpleName: 'Tecsesti ', village: 'Intregalde ' },
            { name: 'Bălcaciu ', simpleName: 'Balcaciu ', village: 'Jidvei ' },
            { name: 'Căpâlna de Jos ', simpleName: 'Capalna de Jos ', village: 'Jidvei ' },
            { name: 'Feisa ', village: 'Jidvei ' },
            { name: 'Veseuş ', simpleName: 'Veseus ', village: 'Jidvei ' },
            { name: 'Izvoarele ', village: 'Livezile ' },
            { name: 'Poiana Aiudului ', village: 'Livezile ' },
            { name: 'Vălişoara ', simpleName: 'Valisoara ', village: 'Livezile ' },
            { name: 'Asinip ', village: 'Lopadea Noua ' },
            { name: 'Beţa ', simpleName: 'Beta ', village: 'Lopadea Noua ' },
            { name: 'Băgău ', simpleName: 'Bagau ', village: 'Lopadea Noua ' },
            { name: 'Cicârd ', simpleName: 'Cicard ', village: 'Lopadea Noua ' },
            { name: 'Ciuguzel ', village: 'Lopadea Noua ' },
            { name: 'Ocnişoara ', simpleName: 'Ocnisoara ', village: 'Lopadea Noua ' },
            { name: 'Odverem ', village: 'Lopadea Noua ' },
            { name: 'Gura Arieşului ', simpleName: 'Gura Ariesului ', village: 'Lunca Muresului ' },
            { name: 'Bârdeşti ', simpleName: 'Bardesti ', village: 'Lupsa ' },
            { name: 'Bârzan ', simpleName: 'Barzan ', village: 'Lupsa ' },
            { name: 'Curmătură ', simpleName: 'Curmatura ', village: 'Lupsa ' },
            { name: 'După Deal ', simpleName: 'Dupa Deal ', village: 'Lupsa ' },
            { name: 'Geamăna ', simpleName: 'Geamana ', village: 'Lupsa ' },
            { name: 'Holobani ', village: 'Lupsa ' },
            { name: 'Hădărău ', simpleName: 'Hadarau ', village: 'Lupsa ' },
            { name: 'Lazuri ', village: 'Lupsa ' },
            { name: 'Lunca ', village: 'Lupsa ' },
            { name: 'Muşca ', simpleName: 'Musca ', village: 'Lupsa ' },
            { name: 'Mănăstire ', simpleName: 'Manastire ', village: 'Lupsa ' },
            { name: 'Mărgaia ', simpleName: 'Margaia ', village: 'Lupsa ' },
            { name: 'Piţiga ', simpleName: 'Pitiga ', village: 'Lupsa ' },
            { name: 'Poşogani ', simpleName: 'Posogani ', village: 'Lupsa ' },
            { name: 'Pârâu-Cărbunări ', simpleName: 'Parau-Carbunari ', village: 'Lupsa ' },
            { name: 'Trifeşti ', simpleName: 'Trifesti ', village: 'Lupsa ' },
            { name: 'Valea Holhorii ', village: 'Lupsa ' },
            { name: 'Valea Lupşii ', simpleName: 'Valea Lupsii ', village: 'Lupsa ' },
            { name: 'Valea Şesii ', simpleName: 'Valea Sesii ', village: 'Lupsa ' },
            { name: 'Vinţa ', simpleName: 'Vinta ', village: 'Lupsa ' },
            { name: 'Văi ', simpleName: 'Vai ', village: 'Lupsa ' },
            { name: 'Şasa ', simpleName: 'Sasa ', village: 'Lupsa ' },
            { name: 'Ampoiţa ', simpleName: 'Ampoita ', village: 'Metes ' },
            { name: 'Isca ', village: 'Metes ' },
            { name: 'Lunca Ampoiţei ', simpleName: 'Lunca Ampoitei ', village: 'Metes ' },
            { name: 'Lunca Meteşului ', simpleName: 'Lunca Metesului ', village: 'Metes ' },
            { name: 'Poiana Ampoiului ', village: 'Metes ' },
            { name: 'Poiana Ursului ', village: 'Metes ' },
            { name: 'Presaca Ampoiului ', village: 'Metes ' },
            { name: 'Pădurea ', simpleName: 'Padurea ', village: 'Metes ' },
            { name: 'Remetea ', village: 'Metes ' },
            { name: 'Tăuţi ', simpleName: 'Tauti ', village: 'Metes ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Metes ' },
            { name: 'Cistei ', village: 'Mihalt ' },
            { name: 'Obreja ', village: 'Mihalt ' },
            { name: 'Zărieş ', simpleName: 'Zaries ', village: 'Mihalt ' },
            { name: 'Cicău ', simpleName: 'Cicau ', village: 'Miraslau ' },
            { name: 'Decea ', village: 'Miraslau ' },
            { name: 'Lopadea Veche ', village: 'Miraslau ' },
            { name: 'Ormeniş ', simpleName: 'Ormenis ', village: 'Miraslau ' },
            { name: 'Rachiş ', simpleName: 'Rachis ', village: 'Miraslau ' },
            { name: 'Boceşti ', simpleName: 'Bocesti ', village: 'Mogos ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Mogos ' },
            { name: 'Buteşti ', simpleName: 'Butesti ', village: 'Mogos ' },
            { name: 'Bârleşti ', simpleName: 'Barlesti ', village: 'Mogos ' },
            { name: 'Bârleşti-Cătun ', simpleName: 'Barlesti-Catun ', village: 'Mogos ' },
            { name: 'Bârzogani ', simpleName: 'Barzogani ', village: 'Mogos ' },
            { name: 'Bărbeşti ', simpleName: 'Barbesti ', village: 'Mogos ' },
            { name: 'Cojocani ', village: 'Mogos ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ', village: 'Mogos ' },
            { name: 'Mămăligani ', simpleName: 'Mamaligani ', village: 'Mogos ' },
            { name: 'Negreşti ', simpleName: 'Negresti ', village: 'Mogos ' },
            { name: 'Onceşti ', simpleName: 'Oncesti ', village: 'Mogos ' },
            { name: 'Poienile-Mogoş ', simpleName: 'Poienile-Mogos ', village: 'Mogos ' },
            { name: 'Tomeşti ', simpleName: 'Tomesti ', village: 'Mogos ' },
            { name: 'Valea Barnii ', village: 'Mogos ' },
            { name: 'Valea Bârluţeşti ', simpleName: 'Valea Barlutesti ', village: 'Mogos ' },
            { name: 'Valea Coceşti ', simpleName: 'Valea Cocesti ', village: 'Mogos ' },
            { name: 'Valea Giogeşti ', simpleName: 'Valea Giogesti ', village: 'Mogos ' },
            { name: 'Valea Mlacii ', village: 'Mogos ' },
            { name: 'Valea Ţupilor ', simpleName: 'Valea Tupilor ', village: 'Mogos ' },
            { name: 'Copand ', village: 'Noslac ' },
            { name: 'Căptălan ', simpleName: 'Captalan ', village: 'Noslac ' },
            { name: 'Găbud ', simpleName: 'Gabud ', village: 'Noslac ' },
            { name: 'Stâna de Mureş ', simpleName: 'Stana de Mures ', village: 'Noslac ' },
            { name: 'Valea Ciuciului ', village: 'Noslac ' },
            { name: 'Cisteiu de Mureş ', simpleName: 'Cisteiu de Mures ', village: 'Ocna Mures ' },
            { name: 'Micoşlaca ', simpleName: 'Micoslaca ', village: 'Ocna Mures ' },
            { name: 'Războieni-Cetate ', simpleName: 'Razboieni-Cetate ', village: 'Ocna Mures ' },
            { name: 'Uioara de Jos ', village: 'Ocna Mures ' },
            { name: 'Uioara de Sus ', village: 'Ocna Mures ' },
            { name: 'Lunca Largă ', simpleName: 'Lunca Larga ', village: 'Ocolis ' },
            { name: 'Runc ', village: 'Ocolis ' },
            { name: 'Vidolm ', village: 'Ocolis ' },
            { name: 'Colibi ', village: 'Ohaba ' },
            { name: 'Măghierat ', simpleName: 'Maghierat ', village: 'Ohaba ' },
            { name: 'Secăşel ', simpleName: 'Secasel ', village: 'Ohaba ' },
            { name: 'Pianu de Jos ', village: 'Pianu ' },
            { name: 'Pianu de Sus ', village: 'Pianu ' },
            { name: 'Plaiuri ', village: 'Pianu ' },
            { name: 'Purcăreţi ', simpleName: 'Purcareti ', village: 'Pianu ' },
            { name: 'Strungari ', village: 'Pianu ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Poiana Vadului ' },
            { name: 'Duduieni ', village: 'Poiana Vadului ' },
            { name: 'Făgetu de Jos ', simpleName: 'Fagetu de Jos ', village: 'Poiana Vadului ' },
            { name: 'Făgetu de Sus ', simpleName: 'Fagetu de Sus ', village: 'Poiana Vadului ' },
            { name: 'Hănăşeşti ', simpleName: 'Hanasesti ', village: 'Poiana Vadului ' },
            { name: 'Lupăieşti ', simpleName: 'Lupaiesti ', village: 'Poiana Vadului ' },
            { name: 'Morcăneşti ', simpleName: 'Morcanesti ', village: 'Poiana Vadului ' },
            { name: 'Petelei ', village: 'Poiana Vadului ' },
            { name: 'Păşteşti ', simpleName: 'Pastesti ', village: 'Poiana Vadului ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ', village: 'Poiana Vadului ' },
            { name: 'Dupa Deal ', village: 'Ponor ' },
            { name: 'Geogel ', village: 'Ponor ' },
            { name: 'Măcăreşti ', simpleName: 'Macaresti ', village: 'Ponor ' },
            { name: 'Vale In Jos ', village: 'Ponor ' },
            { name: 'Valea Bucurului ', village: 'Ponor ' },
            { name: 'Corţeşti ', simpleName: 'Cortesti ', village: 'Posaga ' },
            { name: 'Inceşti ', simpleName: 'Incesti ', village: 'Posaga ' },
            { name: 'Lunca ', village: 'Posaga ' },
            { name: 'Orăşti ', simpleName: 'Orasti ', village: 'Posaga ' },
            { name: 'Poşaga de Jos ', simpleName: 'Posaga de Jos ', village: 'Posaga ' },
            { name: 'Poşaga de Sus ', simpleName: 'Posaga de Sus ', village: 'Posaga ' },
            { name: 'Sagagea ', village: 'Posaga ' },
            { name: 'Leorinţ ', simpleName: 'Leorint ', village: 'Radesti ' },
            { name: 'Meşcreac ', simpleName: 'Mescreac ', village: 'Radesti ' },
            { name: 'Şoimuş ', simpleName: 'Soimus ', village: 'Radesti ' },
            { name: 'Boţani ', simpleName: 'Botani ', village: 'Ramet ' },
            { name: 'Brădeşti ', simpleName: 'Bradesti ', village: 'Ramet ' },
            { name: 'Cheia ', village: 'Ramet ' },
            { name: 'Cotorăşti ', simpleName: 'Cotorasti ', village: 'Ramet ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Ramet ' },
            { name: 'Olteni ', village: 'Ramet ' },
            { name: 'Valea Făgetului ', simpleName: 'Valea Fagetului ', village: 'Ramet ' },
            { name: 'Valea Inzelului ', village: 'Ramet ' },
            { name: 'Valea Mănăstirii ', simpleName: 'Valea Manastirii ', village: 'Ramet ' },
            { name: 'Valea Poienii ', village: 'Ramet ' },
            { name: 'Valea Uzei ', village: 'Ramet ' },
            { name: 'Vlădeşti ', simpleName: 'Vladesti ', village: 'Ramet ' },
            { name: 'Colţeşti ', simpleName: 'Coltesti ', village: 'Rametea ' },
            { name: 'Tău ', simpleName: 'Tau ', village: 'Rosia De Secas ' },
            { name: 'Ungurei ', village: 'Rosia De Secas ' },
            { name: 'Blideşti ', simpleName: 'Blidesti ', village: 'Rosia Montana ' },
            { name: 'Bunta ', village: 'Rosia Montana ' },
            { name: 'Bălmoşeşti ', simpleName: 'Balmosesti ', village: 'Rosia Montana ' },
            { name: 'Coasta Henţii ', simpleName: 'Coasta Hentii ', village: 'Rosia Montana ' },
            { name: 'Corna ', village: 'Rosia Montana ' },
            { name: 'Curături ', simpleName: 'Curaturi ', village: 'Rosia Montana ' },
            { name: 'Cărpiniş ', simpleName: 'Carpinis ', village: 'Rosia Montana ' },
            { name: 'Dăroaia ', simpleName: 'Daroaia ', village: 'Rosia Montana ' },
            { name: 'Gura Roşiei ', simpleName: 'Gura Rosiei ', village: 'Rosia Montana ' },
            { name: 'Gârda-Bărbuleşti ', simpleName: 'Garda-Barbulesti ', village: 'Rosia Montana ' },
            { name: 'Iacobeşti ', simpleName: 'Iacobesti ', village: 'Rosia Montana ' },
            { name: 'Ignăţeşti ', simpleName: 'Ignatesti ', village: 'Rosia Montana ' },
            { name: 'Vârtop ', simpleName: 'Vartop ', village: 'Rosia Montana ' },
            { name: 'Şoal ', simpleName: 'Soal ', village: 'Rosia Montana ' },
            { name: 'Ţarina ', simpleName: 'Tarina ', village: 'Rosia Montana ' },
            { name: 'Dealu Caselor ', village: 'Salciua ' },
            { name: 'Dumeşti ', simpleName: 'Dumesti ', village: 'Salciua ' },
            { name: 'Sub Piatră ', simpleName: 'Sub Piatra ', village: 'Salciua ' },
            { name: 'Sălciua de Jos ', simpleName: 'Salciua de Jos ', village: 'Salciua ' },
            { name: 'Sălciua de Sus ', simpleName: 'Salciua de Sus ', village: 'Salciua ' },
            { name: 'Valea Largă ', simpleName: 'Valea Larga ', village: 'Salciua ' },
            { name: 'Mărgineni ', simpleName: 'Margineni ', village: 'Salistea ' },
            { name: 'Săliştea-Deal ', simpleName: 'Salistea-Deal ', village: 'Salistea ' },
            { name: 'Tărtăria ', simpleName: 'Tartaria ', village: 'Salistea ' },
            { name: 'Iclod ', village: 'Sancel ' },
            { name: 'Pănade ', simpleName: 'Panade ', village: 'Sancel ' },
            { name: 'Coşlariu ', simpleName: 'Coslariu ', village: 'Santimbru ' },
            { name: 'Dumitra ', village: 'Santimbru ' },
            { name: 'Galtiu ', village: 'Santimbru ' },
            { name: 'Totoi ', village: 'Santimbru ' },
            { name: 'Căpâlna ', simpleName: 'Capalna ', village: 'Sasciori ' },
            { name: 'Dumbrava ', village: 'Sasciori ' },
            { name: 'Laz ', village: 'Sasciori ' },
            { name: 'Loman ', village: 'Sasciori ' },
            { name: 'Pleşi ', simpleName: 'Plesi ', village: 'Sasciori ' },
            { name: 'Răchita ', simpleName: 'Rachita ', village: 'Sasciori ' },
            { name: 'Sebeşel ', simpleName: 'Sebesel ', village: 'Sasciori ' },
            { name: 'Tonea ', village: 'Sasciori ' },
            { name: 'Boteşti ', simpleName: 'Botesti ', village: 'Scarisoara ' },
            { name: 'Bârleşti ', simpleName: 'Barlesti ', village: 'Scarisoara ' },
            { name: 'Faţa-Lăzeşti ', simpleName: 'Fata-Lazesti ', village: 'Scarisoara ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Scarisoara ' },
            { name: 'Lespezea ', village: 'Scarisoara ' },
            { name: 'Lăzeşti ', simpleName: 'Lazesti ', village: 'Scarisoara ' },
            { name: 'Maţei ', simpleName: 'Matei ', village: 'Scarisoara ' },
            { name: 'Negeşti ', simpleName: 'Negesti ', village: 'Scarisoara ' },
            { name: 'Prelucă ', simpleName: 'Preluca ', village: 'Scarisoara ' },
            { name: 'Runc ', village: 'Scarisoara ' },
            { name: 'Sfoartea ', village: 'Scarisoara ' },
            { name: 'Trânceşti ', simpleName: 'Trancesti ', village: 'Scarisoara ' },
            { name: 'Ştiuleţi ', simpleName: 'Stiuleti ', village: 'Scarisoara ' },
            { name: 'Lancrăm ', simpleName: 'Lancram ', village: 'Sebes ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Sebes ' },
            { name: 'Răhău ', simpleName: 'Rahau ', village: 'Sebes ' },
            { name: 'Balomiru de Câmp ', simpleName: 'Balomiru de Camp ', village: 'Sibot ' },
            { name: 'Băcăinţi ', simpleName: 'Bacainti ', village: 'Sibot ' },
            { name: 'Sărăcsău ', simpleName: 'Saracsau ', village: 'Sibot ' },
            { name: 'Bilăneşti ', simpleName: 'Bilanesti ', village: 'Sohodol ' },
            { name: 'Bobăreşti ', simpleName: 'Bobaresti ', village: 'Sohodol ' },
            { name: 'Brădeana ', simpleName: 'Bradeana ', village: 'Sohodol ' },
            { name: 'Burzoneşti ', simpleName: 'Burzonesti ', village: 'Sohodol ' },
            { name: 'Băzeşti ', simpleName: 'Bazesti ', village: 'Sohodol ' },
            { name: 'Deonceşti ', simpleName: 'Deoncesti ', village: 'Sohodol ' },
            { name: 'Dilimani ', village: 'Sohodol ' },
            { name: 'Furduieşti ', simpleName: 'Furduiesti ', village: 'Sohodol ' },
            { name: 'Gura Sohodol ', village: 'Sohodol ' },
            { name: 'Hoancă ', simpleName: 'Hoanca ', village: 'Sohodol ' },
            { name: 'Joldişeşti ', simpleName: 'Joldisesti ', village: 'Sohodol ' },
            { name: 'Lazuri ', village: 'Sohodol ' },
            { name: 'Leheşti ', simpleName: 'Lehesti ', village: 'Sohodol ' },
            { name: 'Lumineşti ', simpleName: 'Luminesti ', village: 'Sohodol ' },
            { name: 'Medreşti ', simpleName: 'Medresti ', village: 'Sohodol ' },
            { name: 'Morăreşti ', simpleName: 'Moraresti ', village: 'Sohodol ' },
            { name: 'Muneşti ', simpleName: 'Munesti ', village: 'Sohodol ' },
            { name: 'Nelegeşti ', simpleName: 'Nelegesti ', village: 'Sohodol ' },
            { name: 'Nicoreşti ', simpleName: 'Nicoresti ', village: 'Sohodol ' },
            { name: 'Năpăieşti ', simpleName: 'Napaiesti ', village: 'Sohodol ' },
            { name: 'Peleş ', simpleName: 'Peles ', village: 'Sohodol ' },
            { name: 'Poiana ', village: 'Sohodol ' },
            { name: 'Robeşti ', simpleName: 'Robesti ', village: 'Sohodol ' },
            { name: 'Sebişeşti ', simpleName: 'Sebisesti ', village: 'Sohodol ' },
            { name: 'Sicoieşti ', simpleName: 'Sicoiesti ', village: 'Sohodol ' },
            { name: 'Surdeşti ', simpleName: 'Surdesti ', village: 'Sohodol ' },
            { name: 'Toci ', village: 'Sohodol ' },
            { name: 'Valea Verde ', village: 'Sohodol ' },
            { name: 'Vlădoşeşti ', simpleName: 'Vladosesti ', village: 'Sohodol ' },
            { name: 'Şimoceşti ', simpleName: 'Simocesti ', village: 'Sohodol ' },
            { name: 'Alecuş ', simpleName: 'Alecus ', village: 'Sona ' },
            { name: 'Biia ', village: 'Sona ' },
            { name: 'Doptău ', simpleName: 'Doptau ', village: 'Sona ' },
            { name: 'Lunca Târnavei ', simpleName: 'Lunca Tarnavei ', village: 'Sona ' },
            { name: 'Sânmiclăuş ', simpleName: 'Sanmiclaus ', village: 'Sona ' },
            { name: 'Valea Sasului ', village: 'Sona ' },
            { name: 'Carpen ', village: 'Spring ' },
            { name: 'Carpenii de Sus ', village: 'Spring ' },
            { name: 'Cunţa ', simpleName: 'Cunta ', village: 'Spring ' },
            { name: 'Draşov ', simpleName: 'Drasov ', village: 'Spring ' },
            { name: 'Vingard ', village: 'Spring ' },
            { name: 'Faţa Pietrii ', simpleName: 'Fata Pietrii ', village: 'Stremt ' },
            { name: 'Geoagiu de Sus ', village: 'Stremt ' },
            { name: 'Geomal ', village: 'Stremt ' },
            { name: 'Arţi ', simpleName: 'Arti ', village: 'Sugag ' },
            { name: 'Bârsana ', simpleName: 'Barsana ', village: 'Sugag ' },
            { name: 'Dobra ', village: 'Sugag ' },
            { name: 'Jidoştina ', simpleName: 'Jidostina ', village: 'Sugag ' },
            { name: 'Mărtinie ', simpleName: 'Martinie ', village: 'Sugag ' },
            { name: 'Tău Bistra ', simpleName: 'Tau Bistra ', village: 'Sugag ' },
            { name: 'Beldiu ', village: 'Teius ' },
            { name: 'Coşlariu Nou ', simpleName: 'Coslariu Nou ', village: 'Teius ' },
            { name: 'Căpud ', simpleName: 'Capud ', village: 'Teius ' },
            { name: 'Peţelca ', simpleName: 'Petelca ', village: 'Teius ' },
            { name: 'Ciugudu de Jos ', village: 'Unirea ' },
            { name: 'Ciugudu de Sus ', village: 'Unirea ' },
            { name: 'Dumbrava ', village: 'Unirea ' },
            { name: 'Inoc ', village: 'Unirea ' },
            { name: 'Măhăceni ', simpleName: 'Mahaceni ', village: 'Unirea ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ', village: 'Vadu Motilor ' },
            { name: 'Burzeşti ', simpleName: 'Burzesti ', village: 'Vadu Motilor ' },
            { name: 'Dealu Frumos ', village: 'Vadu Motilor ' },
            { name: 'Lăzeşti ', simpleName: 'Lazesti ', village: 'Vadu Motilor ' },
            { name: 'Necşeşti ', simpleName: 'Necsesti ', village: 'Vadu Motilor ' },
            { name: 'Poduri-Briceşti ', simpleName: 'Poduri-Bricesti ', village: 'Vadu Motilor ' },
            { name: 'Popeştii de Jos ', simpleName: 'Popestii de Jos ', village: 'Vadu Motilor ' },
            { name: 'Popeştii de Sus ', simpleName: 'Popestii de Sus ', village: 'Vadu Motilor ' },
            { name: 'Tomuţeşti ', simpleName: 'Tomutesti ', village: 'Vadu Motilor ' },
            { name: 'Toţeşti ', simpleName: 'Totesti ', village: 'Vadu Motilor ' },
            { name: 'Vâltori ', simpleName: 'Valtori ', village: 'Vadu Motilor ' },
            { name: 'Făget ', simpleName: 'Faget ', village: 'Valea Lunga ' },
            { name: 'Glogoveţ ', simpleName: 'Glogovet ', village: 'Valea Lunga ' },
            { name: 'Lodroman ', village: 'Valea Lunga ' },
            { name: 'Lunca ', village: 'Valea Lunga ' },
            { name: 'Tăuni ', simpleName: 'Tauni ', village: 'Valea Lunga ' },
            { name: 'Bobăreşti ', simpleName: 'Bobaresti ', village: 'Vidra ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Vidra ' },
            { name: 'Bordeştii Poieni ', simpleName: 'Bordestii Poieni ', village: 'Vidra ' },
            { name: 'Băi ', simpleName: 'Bai ', village: 'Vidra ' },
            { name: 'Culdeşti ', simpleName: 'Culdesti ', village: 'Vidra ' },
            { name: 'Dealu Goieşti ', simpleName: 'Dealu Goiesti ', village: 'Vidra ' },
            { name: 'Dos ', village: 'Vidra ' },
            { name: 'Dosu Luncii ', village: 'Vidra ' },
            { name: 'Dosu Văseşti ', simpleName: 'Dosu Vasesti ', village: 'Vidra ' },
            { name: 'Drăgoieşti-Luncă ', simpleName: 'Dragoiesti-Lunca ', village: 'Vidra ' },
            { name: 'Ficăreşti ', simpleName: 'Ficaresti ', village: 'Vidra ' },
            { name: 'Gligoreşti ', simpleName: 'Gligoresti ', village: 'Vidra ' },
            { name: 'Goieşti ', simpleName: 'Goiesti ', village: 'Vidra ' },
            { name: 'Haiduceşti ', simpleName: 'Haiducesti ', village: 'Vidra ' },
            { name: 'Hoancă ', simpleName: 'Hoanca ', village: 'Vidra ' },
            { name: 'Hărăşti ', simpleName: 'Harasti ', village: 'Vidra ' },
            { name: 'Jefleşti ', simpleName: 'Jeflesti ', village: 'Vidra ' },
            { name: 'Lunca ', village: 'Vidra ' },
            { name: 'Lunca Bisericii ', village: 'Vidra ' },
            { name: 'Lunca Goieşti ', simpleName: 'Lunca Goiesti ', village: 'Vidra ' },
            { name: 'Lunca Veseşti ', simpleName: 'Lunca Vesesti ', village: 'Vidra ' },
            { name: 'Lunca de Jos ', village: 'Vidra ' },
            { name: 'Modoleşti ', simpleName: 'Modolesti ', village: 'Vidra ' },
            { name: 'Nemeşi ', simpleName: 'Nemesi ', village: 'Vidra ' },
            { name: 'Oideşti ', simpleName: 'Oidesti ', village: 'Vidra ' },
            { name: 'Pitărceşti ', simpleName: 'Pitarcesti ', village: 'Vidra ' },
            { name: 'Pleşcuţa ', simpleName: 'Plescuta ', village: 'Vidra ' },
            { name: 'Poieni ', village: 'Vidra ' },
            { name: 'Ponorel ', village: 'Vidra ' },
            { name: 'Puiuleţeşti ', simpleName: 'Puiuletesti ', village: 'Vidra ' },
            { name: 'Runc ', village: 'Vidra ' },
            { name: 'Segaj ', village: 'Vidra ' },
            { name: 'Urdeş ', simpleName: 'Urdes ', village: 'Vidra ' },
            { name: 'Valea Morii ', village: 'Vidra ' },
            { name: 'Vâlceşti ', simpleName: 'Valcesti ', village: 'Vidra ' },
            { name: 'Vâlcăneasa ', simpleName: 'Valcaneasa ', village: 'Vidra ' },
            { name: 'Vârtăneşti ', simpleName: 'Vartanesti ', village: 'Vidra ' },
            { name: 'Văseşti ', simpleName: 'Vasesti ', village: 'Vidra ' },
            { name: 'Ciocaşu ', simpleName: 'Ciocasu ', village: 'Vintu De Jos ' },
            { name: 'Crişeni ', simpleName: 'Criseni ', village: 'Vintu De Jos ' },
            { name: 'Câmpu Goblii ', simpleName: 'Campu Goblii ', village: 'Vintu De Jos ' },
            { name: 'Dealu Ferului ', village: 'Vintu De Jos ' },
            { name: 'Gura Cuţului ', simpleName: 'Gura Cutului ', village: 'Vintu De Jos ' },
            { name: 'Haţegana ', simpleName: 'Hategana ', village: 'Vintu De Jos ' },
            { name: 'Inuri ', village: 'Vintu De Jos ' },
            { name: 'Laz ', village: 'Vintu De Jos ' },
            { name: 'Mereteu ', village: 'Vintu De Jos ' },
            { name: 'Mătăcina ', simpleName: 'Matacina ', village: 'Vintu De Jos ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Vintu De Jos ' },
            { name: 'Pârău lui Mihai ', simpleName: 'Parau lui Mihai ', village: 'Vintu De Jos ' },
            { name: 'Stăuini ', simpleName: 'Stauini ', village: 'Vintu De Jos ' },
            { name: 'Valea Goblii ', village: 'Vintu De Jos ' },
            { name: 'Valea Vinţului ', simpleName: 'Valea Vintului ', village: 'Vintu De Jos ' },
            { name: 'Valea lui Mihai ', village: 'Vintu De Jos ' },
            { name: 'Vurpăr ', simpleName: 'Vurpar ', village: 'Vintu De Jos ' },
            { name: 'Boteşti ', simpleName: 'Botesti ', village: 'Zlatna ' },
            { name: 'Budeni ', village: 'Zlatna ' },
            { name: 'Dealu Roatei ', village: 'Zlatna ' },
            { name: 'Dobrot ', village: 'Zlatna ' },
            { name: 'Dumbrava ', village: 'Zlatna ' },
            { name: 'Feneş ', simpleName: 'Fenes ', village: 'Zlatna ' },
            { name: 'Galaţi ', simpleName: 'Galati ', village: 'Zlatna ' },
            { name: 'Izvoru Ampoiului ', village: 'Zlatna ' },
            { name: 'Pirita ', village: 'Zlatna ' },
            { name: 'Podu lui Paul ', village: 'Zlatna ' },
            { name: 'Pârau Gruiului ', simpleName: 'Parau Gruiului ', village: 'Zlatna ' },
            { name: 'Pătrângeni ', simpleName: 'Patrangeni ', village: 'Zlatna ' },
            { name: 'Runc ', village: 'Zlatna ' },
            { name: 'Ruşi ', simpleName: 'Rusi ', village: 'Zlatna ' },
            { name: 'Suseni ', village: 'Zlatna ' },
            { name: 'Trâmpoiele ', simpleName: 'Trampoiele ', village: 'Zlatna ' },
            { name: 'Valea Mică ', simpleName: 'Valea Mica ', village: 'Zlatna ' },
            {
                name: 'Vâltori ', simpleName: 'Valtori ', village: 'Zlatna '
            }
        ]
    },
    {
        auto: 'AR ',
        name: 'Arad ',
        cities: [
            { name: 'Almaş ', simpleName: 'Almas ' },
            { name: 'Apateu ' },
            { name: 'Arad ' },
            { name: 'Archiş ', simpleName: 'Archis ' },
            { name: 'Bata ' },
            { name: 'Beliu ' },
            { name: 'Birchiş ', simpleName: 'Birchis ' },
            { name: 'Bocsig ' },
            { name: 'Brazii ' },
            { name: 'Buteni ' },
            { name: 'Bârsa ', simpleName: 'Barsa ' },
            { name: 'Bârzava ', simpleName: 'Barzava ' },
            { name: 'Cermei ' },
            { name: 'Chisindia ' },
            { name: 'Chişineu-Criş ', simpleName: 'Chisineu-Cris ' },
            { name: 'Conop ' },
            { name: 'Covăsinţ ', simpleName: 'Covasint ' },
            { name: 'Craiva ' },
            { name: 'Curtici ' },
            { name: 'Cărand ', simpleName: 'Carand ' },
            { name: 'Dezna ' },
            { name: 'Dieci ' },
            { name: 'Dorgoş ', simpleName: 'Dorgos ' },
            { name: 'Felnac ' },
            { name: 'Ghioroc ' },
            { name: 'Grăniceri ', simpleName: 'Graniceri ' },
            { name: 'Gurahonţ ', simpleName: 'Gurahont ' },
            { name: 'Hălmagiu ', simpleName: 'Halmagiu ' },
            { name: 'Hălmăgel ', simpleName: 'Halmagel ' },
            { name: 'Hăşmaş ', simpleName: 'Hasmas ' },
            { name: 'Igneşti ', simpleName: 'Ignesti ' },
            { name: 'Ineu ' },
            { name: 'Iratoşu ', simpleName: 'Iratosu ' },
            { name: 'Lipova ' },
            { name: 'Livada ' },
            { name: 'Macea ' },
            { name: 'Mişca ', simpleName: 'Misca ' },
            { name: 'Moneasa ' },
            { name: 'Nădlac ', simpleName: 'Nadlac ' },
            { name: 'Olari ' },
            { name: 'Pecica ' },
            { name: 'Peregu Mare ' },
            { name: 'Petriş ', simpleName: 'Petris ' },
            { name: 'Pilu ' },
            { name: 'Pleşcuţa ', simpleName: 'Plescuta ' },
            { name: 'Pâncota ', simpleName: 'Pancota ' },
            { name: 'Păuliş ', simpleName: 'Paulis ' },
            { name: 'Sebiş ', simpleName: 'Sebis ' },
            { name: 'Secusigiu ' },
            { name: 'Seleuş ', simpleName: 'Seleus ' },
            { name: 'Semlac ' },
            { name: 'Sintea Mare ' },
            { name: 'Socodor ' },
            { name: 'Sântana ', simpleName: 'Santana ' },
            { name: 'Săvârşin ', simpleName: 'Savarsin ' },
            { name: 'Tauţ ', simpleName: 'Taut ' },
            { name: 'Târnova ', simpleName: 'Tarnova ' },
            { name: 'Vinga ' },
            { name: 'Vladimirescu ' },
            { name: 'Vârfurile ', simpleName: 'Varfurile ' },
            { name: 'Vărădia de Mureş ', simpleName: 'Varadia de Mures ' },
            { name: 'Zerind ' },
            { name: 'Zimandu Nou ' },
            { name: 'Zăbrani ', simpleName: 'Zabrani ' },
            { name: 'Zărand ', simpleName: 'Zarand ' },
            { name: 'Şagu ', simpleName: 'Sagu ' },
            { name: 'Şeitin ', simpleName: 'Seitin ' },
            { name: 'Şepreuş ', simpleName: 'Sepreus ' },
            { name: 'Şicula ', simpleName: 'Sicula ' },
            { name: 'Şilindia ', simpleName: 'Silindia ' },
            { name: 'Şimand ', simpleName: 'Simand ' },
            { name: 'Şiria ', simpleName: 'Siria ' },
            { name: 'Şiştarovăţ ', simpleName: 'Sistarovat ' },
            { name: 'Şofronea ', simpleName: 'Sofronea ' },
            { name: 'Cil ', village: 'Almas ' },
            { name: 'Joia Mare ', village: 'Almas ' },
            { name: 'Rădeşti ', simpleName: 'Radesti ', village: 'Almas ' },
            { name: 'Berechiu ', village: 'Apateu ' },
            { name: 'Moţiori ', simpleName: 'Motiori ', village: 'Apateu ' },
            { name: 'Bârzeşti ', simpleName: 'Barzesti ', village: 'Archis ' },
            { name: 'Groşeni ', simpleName: 'Groseni ', village: 'Archis ' },
            { name: 'Nermiş ', simpleName: 'Nermis ', village: 'Archis ' },
            { name: 'Aldeşti ', simpleName: 'Aldesti ', village: 'Barsa ' },
            { name: 'Hodiş ', simpleName: 'Hodis ', village: 'Barsa ' },
            { name: 'Voivodeni ', village: 'Barsa ' },
            { name: 'Bătuţa ', simpleName: 'Batuta ', village: 'Barzava ' },
            { name: 'Căpruţa ', simpleName: 'Capruta ', village: 'Barzava ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Barzava ' },
            { name: 'Groşii Noi ', simpleName: 'Grosii Noi ', village: 'Barzava ' },
            { name: 'Lalaşinţ ', simpleName: 'Lalasint ', village: 'Barzava ' },
            { name: 'Monoroştia ', simpleName: 'Monorostia ', village: 'Barzava ' },
            { name: 'Slatina de Mureş ', simpleName: 'Slatina de Mures ', village: 'Barzava ' },
            { name: 'Bacău de Mijloc ', simpleName: 'Bacau de Mijloc ', village: 'Bata ' },
            { name: 'Bulci ', village: 'Bata ' },
            { name: 'Ţela ', simpleName: 'Tela ', village: 'Bata ' },
            { name: 'Beneşti ', simpleName: 'Benesti ', village: 'Beliu ' },
            { name: 'Bochia ', village: 'Beliu ' },
            { name: 'Secaci ', village: 'Beliu ' },
            { name: 'Tăgădău ', simpleName: 'Tagadau ', village: 'Beliu ' },
            { name: 'Vasile Goldiş ', simpleName: 'Vasile Goldis ', village: 'Beliu ' },
            { name: 'Căpălnaş ', simpleName: 'Capalnas ', village: 'Birchis ' },
            { name: 'Ostrov ', village: 'Birchis ' },
            { name: 'Virismort ', village: 'Birchis ' },
            { name: 'Mânerău ', simpleName: 'Manerau ', village: 'Bocsig ' },
            { name: 'Răpsig ', simpleName: 'Rapsig ', village: 'Bocsig ' },
            { name: 'Buceava-Şoimuş ', simpleName: 'Buceava-Soimus ', village: 'Brazii ' },
            { name: 'Iacobini ', village: 'Brazii ' },
            { name: 'Mădrigeşti ', simpleName: 'Madrigesti ', village: 'Brazii ' },
            { name: 'Secaş ', simpleName: 'Secas ', village: 'Brazii ' },
            { name: 'Berindia ', village: 'Buteni ' },
            { name: 'Cuied ', village: 'Buteni ' },
            { name: 'Păulian ', simpleName: 'Paulian ', village: 'Buteni ' },
            { name: 'Seliştea ', simpleName: 'Selistea ', village: 'Carand ' },
            { name: 'Avram Iancu ', village: 'Cermei ' },
            { name: 'Şomoşcheş ', simpleName: 'Somosches ', village: 'Cermei ' },
            { name: 'Păiuşeni ', simpleName: 'Paiuseni ', village: 'Chisindia ' },
            { name: 'Văsoaia ', simpleName: 'Vasoaia ', village: 'Chisindia ' },
            { name: 'Nadab ', village: 'Chisineu-Cris ' },
            { name: 'Belotinţ ', simpleName: 'Belotint ', village: 'Conop ' },
            { name: 'Chelmac ', village: 'Conop ' },
            { name: 'Milova ', village: 'Conop ' },
            { name: 'Odvoş ', simpleName: 'Odvos ', village: 'Conop ' },
            { name: 'Chişlaca ', simpleName: 'Chislaca ', village: 'Craiva ' },
            { name: 'Ciunteşti ', simpleName: 'Ciuntesti ', village: 'Craiva ' },
            { name: 'Coroi ', village: 'Craiva ' },
            { name: 'Mărăuş ', simpleName: 'Maraus ', village: 'Craiva ' },
            { name: 'Rogoz de Beliu ', village: 'Craiva ' },
            { name: 'Stoineşti ', simpleName: 'Stoinesti ', village: 'Craiva ' },
            { name: 'Susag ', village: 'Craiva ' },
            { name: 'Tălmaci ', simpleName: 'Talmaci ', village: 'Craiva ' },
            { name: 'Şiad ', simpleName: 'Siad ', village: 'Craiva ' },
            { name: 'Dorobanţi ', simpleName: 'Dorobanti ', village: 'Curtici ' },
            { name: 'Buhani ', village: 'Dezna ' },
            { name: 'Laz ', village: 'Dezna ' },
            { name: 'Neagra ', village: 'Dezna ' },
            { name: 'Slatina de Criş ', simpleName: 'Slatina de Cris ', village: 'Dezna ' },
            { name: 'Cociuba ', village: 'Dieci ' },
            { name: 'Crocna ', village: 'Dieci ' },
            { name: 'Revetiş ', simpleName: 'Revetis ', village: 'Dieci ' },
            { name: 'Roşia ', simpleName: 'Rosia ', village: 'Dieci ' },
            { name: 'Bruznic ', village: 'Dorgos ' },
            { name: 'Pătârş ', simpleName: 'Patars ', village: 'Dorgos ' },
            { name: 'Ususău ', simpleName: 'Ususau ', village: 'Dorgos ' },
            { name: 'Zăbalţ ', simpleName: 'Zabalt ', village: 'Dorgos ' },
            { name: 'Aluniş ', simpleName: 'Alunis ', village: 'Fantinele ' },
            { name: 'Frumuşeni ', simpleName: 'Frumuseni ', village: 'Fantinele ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Fantinele ' },
            { name: 'Tisa Nouă ', simpleName: 'Tisa Noua ', village: 'Fantinele ' },
            { name: 'Bodrogu Nou ', village: 'Felnac ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Felnac ' },
            { name: 'Zădăreni ', simpleName: 'Zadareni ', village: 'Felnac ' },
            { name: 'Cuvin ', village: 'Ghioroc ' },
            { name: 'Miniş ', simpleName: 'Minis ', village: 'Ghioroc ' },
            { name: 'Şiclău ', simpleName: 'Siclau ', village: 'Graniceri ' },
            { name: 'Bonţeşti ', simpleName: 'Bontesti ', village: 'Gurahont ' },
            { name: 'Dulcele ', village: 'Gurahont ' },
            { name: 'Feniş ', simpleName: 'Fenis ', village: 'Gurahont ' },
            { name: 'Honţişor ', simpleName: 'Hontisor ', village: 'Gurahont ' },
            { name: 'Iosaş ', simpleName: 'Iosas ', village: 'Gurahont ' },
            { name: 'Musteşti ', simpleName: 'Mustesti ', village: 'Gurahont ' },
            { name: 'Pescari ', village: 'Gurahont ' },
            { name: 'Valea Mare ', village: 'Gurahont ' },
            { name: 'Zimbru ', village: 'Gurahont ' },
            { name: 'Luncşoara ', simpleName: 'Luncsoara ', village: 'Halmagel ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Halmagel ' },
            { name: 'Târnăviţa ', simpleName: 'Tarnavita ', village: 'Halmagel ' },
            { name: 'Ţoheşti ', simpleName: 'Tohesti ', village: 'Halmagel ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ', village: 'Halmagiu ' },
            { name: 'Brusturi ', village: 'Halmagiu ' },
            { name: 'Băneşti ', simpleName: 'Banesti ', village: 'Halmagiu ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ', village: 'Halmagiu ' },
            { name: 'Ioneşti ', simpleName: 'Ionesti ', village: 'Halmagiu ' },
            { name: 'Leasa ', village: 'Halmagiu ' },
            { name: 'Leştioara ', simpleName: 'Lestioara ', village: 'Halmagiu ' },
            { name: 'Poienari ', village: 'Halmagiu ' },
            { name: 'Tisa ', village: 'Halmagiu ' },
            { name: 'Ţărmure ', simpleName: 'Tarmure ', village: 'Halmagiu ' },
            { name: 'Agrişu Mic ', simpleName: 'Agrisu Mic ', village: 'Hasmas ' },
            { name: 'Botfei ', village: 'Hasmas ' },
            { name: 'Clit ', village: 'Hasmas ' },
            { name: 'Comăneşti ', simpleName: 'Comanesti ', village: 'Hasmas ' },
            { name: 'Urvişu de Beliu ', simpleName: 'Urvisu de Beliu ', village: 'Hasmas ' },
            { name: 'Minead ', village: 'Ignesti ' },
            { name: 'Nădălbeşti ', simpleName: 'Nadalbesti ', village: 'Ignesti ' },
            { name: 'Susani ', village: 'Ignesti ' },
            { name: 'Mocrea ', village: 'Ineu ' },
            { name: 'Variaşu Mare ', simpleName: 'Variasu Mare ', village: 'Iratosu ' },
            { name: 'Variaşu Mic ', simpleName: 'Variasu Mic ', village: 'Iratosu ' },
            { name: 'Radna ', village: 'Lipova ' },
            { name: 'Şoimoş ', simpleName: 'Soimos ', village: 'Lipova ' },
            { name: 'Sânleani ', simpleName: 'Sanleani ', village: 'Livada ' },
            { name: 'Sânmartin ', simpleName: 'Sanmartin ', village: 'Macea ' },
            { name: 'Satu Nou ', village: 'Misca ' },
            { name: 'Vânători ', simpleName: 'Vanatori ', village: 'Misca ' },
            { name: 'Zerindu Mic ', village: 'Misca ' },
            { name: 'Rănuşa ', simpleName: 'Ranusa ', village: 'Moneasa ' },
            { name: 'Sintea Mică ', simpleName: 'Sintea Mica ', village: 'Olari ' },
            { name: 'Măderat ', simpleName: 'Maderat ', village: 'Pancota ' },
            { name: 'Baraţca ', simpleName: 'Baratca ', village: 'Paulis ' },
            { name: 'Cladova ', village: 'Paulis ' },
            { name: 'Sâmbăteni ', simpleName: 'Sambateni ', village: 'Paulis ' },
            { name: 'Bodrogu Vechi ', village: 'Pecica ' },
            { name: 'Sederhat ', village: 'Pecica ' },
            { name: 'Turnu ', village: 'Pecica ' },
            { name: 'Peregu Mic ', village: 'Peregu Mare ' },
            { name: 'Corbeşti ', simpleName: 'Corbesti ', village: 'Petris ' },
            { name: 'Ilteu ', village: 'Petris ' },
            { name: 'Obârşia ', simpleName: 'Obarsia ', village: 'Petris ' },
            { name: 'Roşia Nouă ', simpleName: 'Rosia Noua ', village: 'Petris ' },
            { name: 'Selişte ', simpleName: 'Seliste ', village: 'Petris ' },
            { name: 'Vărşand ', simpleName: 'Varsand ', village: 'Pilu ' },
            { name: 'Aciuţa ', simpleName: 'Aciuta ', village: 'Plescuta ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Plescuta ' },
            { name: 'Dumbrava ', village: 'Plescuta ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Plescuta ' },
            { name: 'Rostoci ', village: 'Plescuta ' },
            { name: 'Tălagiu ', simpleName: 'Talagiu ', village: 'Plescuta ' },
            { name: 'Cruceni ', village: 'Sagu ' },
            { name: 'Firiteaz ', village: 'Sagu ' },
            { name: 'Fiscut ', village: 'Sagu ' },
            { name: 'Hunedoara Timişană ', simpleName: 'Hunedoara Timisana ', village: 'Sagu ' },
            { name: 'Caporal Alexa ', village: 'Santana ' },
            { name: 'Cuiaş ', simpleName: 'Cuias ', village: 'Savarsin ' },
            { name: 'Căprioara ', simpleName: 'Caprioara ', village: 'Savarsin ' },
            { name: 'Hălăliş ', simpleName: 'Halalis ', village: 'Savarsin ' },
            { name: 'Pârneşti ', simpleName: 'Parnesti ', village: 'Savarsin ' },
            { name: 'Temeşeşti ', simpleName: 'Temesesti ', village: 'Savarsin ' },
            { name: 'Toc ', village: 'Savarsin ' },
            { name: 'Troaş ', simpleName: 'Troas ', village: 'Savarsin ' },
            { name: 'Valea Mare ', village: 'Savarsin ' },
            { name: 'Donceni ', village: 'Sebis ' },
            { name: 'Prunişor ', simpleName: 'Prunisor ', village: 'Sebis ' },
            { name: 'Sălăjeni ', simpleName: 'Salajeni ', village: 'Sebis ' },
            { name: 'Munar ', village: 'Secusigiu ' },
            { name: 'Satu Mare ', village: 'Secusigiu ' },
            { name: 'Sânpetru German ', simpleName: 'Sanpetru German ', village: 'Secusigiu ' },
            { name: 'Iermata ', village: 'Seleus ' },
            { name: 'Moroda ', village: 'Seleus ' },
            { name: 'Chereluş ', simpleName: 'Cherelus ', village: 'Sicula ' },
            { name: 'Gurba ', village: 'Sicula ' },
            { name: 'Camna ', village: 'Silindia ' },
            { name: 'Iercoşeni ', simpleName: 'Iercoseni ', village: 'Silindia ' },
            { name: 'Luguzău ', simpleName: 'Luguzau ', village: 'Silindia ' },
            { name: 'Satu Mic ', village: 'Silindia ' },
            { name: 'Adea ', village: 'Sintea Mare ' },
            { name: 'Ţipar ', simpleName: 'Tipar ', village: 'Sintea Mare ' },
            { name: 'Galşa ', simpleName: 'Galsa ', village: 'Siria ' },
            { name: 'Mâsca ', simpleName: 'Masca ', village: 'Siria ' },
            { name: 'Cuveşdia ', simpleName: 'Cuvesdia ', village: 'Sistarovat ' },
            { name: 'Labaşinţ ', simpleName: 'Labasint ', village: 'Sistarovat ' },
            { name: 'Varniţa ', simpleName: 'Varnita ', village: 'Sistarovat ' },
            { name: 'Sânpaul ', simpleName: 'Sanpaul ', village: 'Sofronea ' },
            { name: 'Agrişu Mare ', simpleName: 'Agrisu Mare ', village: 'Tarnova ' },
            { name: 'Arăneag ', simpleName: 'Araneag ', village: 'Tarnova ' },
            { name: 'Chier ', village: 'Tarnova ' },
            { name: 'Drauţ ', simpleName: 'Draut ', village: 'Tarnova ' },
            { name: 'Dud ', village: 'Tarnova ' },
            { name: 'Minişel ', simpleName: 'Minisel ', village: 'Taut ' },
            { name: 'Minişu de Sus ', simpleName: 'Minisu de Sus ', village: 'Taut ' },
            { name: 'Nadăş ', simpleName: 'Nadas ', village: 'Taut ' },
            { name: 'Baia ', village: 'Varadia De Mures ' },
            { name: 'Juliţa ', simpleName: 'Julita ', village: 'Varadia De Mures ' },
            { name: 'Lupeşti ', simpleName: 'Lupesti ', village: 'Varadia De Mures ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Varadia De Mures ' },
            { name: 'Stejar ', village: 'Varadia De Mures ' },
            { name: 'Avram Iancu ', village: 'Varfurile ' },
            { name: 'Groşi ', simpleName: 'Grosi ', village: 'Varfurile ' },
            { name: 'Lazuri ', village: 'Varfurile ' },
            { name: 'Mermeşti ', simpleName: 'Mermesti ', village: 'Varfurile ' },
            { name: 'Măgulicea ', simpleName: 'Magulicea ', village: 'Varfurile ' },
            { name: 'Poiana ', village: 'Varfurile ' },
            { name: 'Vidra ', village: 'Varfurile ' },
            { name: 'Mailat ', village: 'Vinga ' },
            { name: 'Mănăştur ', simpleName: 'Manastur ', village: 'Vinga ' },
            { name: 'Cicir ', village: 'Vladimirescu ' },
            { name: 'Horia ', village: 'Vladimirescu ' },
            { name: 'Mândruloc ', simpleName: 'Mandruloc ', village: 'Vladimirescu ' },
            { name: 'Chesinţ ', simpleName: 'Chesint ', village: 'Zabrani ' },
            { name: 'Neudorf ', village: 'Zabrani ' },
            { name: 'Cintei ', village: 'Zarand ' },
            { name: 'Iermata Neagră ', simpleName: 'Iermata Neagra ', village: 'Zerind ' },
            { name: 'Andrei Şaguna ', simpleName: 'Andrei Saguna ', village: 'Zimandu Nou ' },
            {
                name: 'Zimandcuz ', village: 'Zimandu Nou '
            }
        ]
    },
    {
        auto: 'AG ',
        name: 'Argeş ',
        cities: [
            { name: 'Albota ' },
            { name: 'Aninoasa ' },
            { name: 'Arefu ' },
            { name: 'Bascov ' },
            { name: 'Berevoeşti ', simpleName: 'Berevoesti ' },
            { name: 'Bogaţi ', simpleName: 'Bogati ' },
            { name: 'Boteni ' },
            { name: 'Boţeşti ', simpleName: 'Botesti ' },
            { name: 'Bradu ' },
            { name: 'Brăduleţ ', simpleName: 'Bradulet ' },
            { name: 'Bughea de Jos ' },
            { name: 'Buzoeşti ', simpleName: 'Buzoesti ' },
            { name: 'Bârla ', simpleName: 'Barla ' },
            { name: 'Băbana ', simpleName: 'Babana ' },
            { name: 'Băiculeşti ', simpleName: 'Baiculesti ' },
            { name: 'Bălileşti ', simpleName: 'Balilesti ' },
            { name: 'Cetăţeni ', simpleName: 'Cetateni ' },
            { name: 'Cicăneşti ', simpleName: 'Cicanesti ' },
            { name: 'Ciofrângeni ', simpleName: 'Ciofrangeni ' },
            { name: 'Ciomăgeşti ', simpleName: 'Ciomagesti ' },
            { name: 'Cocu ' },
            { name: 'Corbeni ' },
            { name: 'Corbi ' },
            { name: 'Costeşti ', simpleName: 'Costesti ' },
            { name: 'Cotmeana ' },
            { name: 'Coşeşti ', simpleName: 'Cosesti ' },
            { name: 'Cuca ' },
            { name: 'Curtea de Argeş ', simpleName: 'Curtea de Arges ' },
            { name: 'Câmpulung ', simpleName: 'Campulung ' },
            { name: 'Căldăraru ', simpleName: 'Caldararu ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ' },
            { name: 'Căteasca ', simpleName: 'Cateasca ' },
            { name: 'Davideşti ', simpleName: 'Davidesti ' },
            { name: 'Dobreşti ', simpleName: 'Dobresti ' },
            { name: 'Domneşti ', simpleName: 'Domnesti ' },
            { name: 'Dragoslavele ' },
            { name: 'Dâmbovicioara ', simpleName: 'Dambovicioara ' },
            { name: 'Dârmăneşti ', simpleName: 'Darmanesti ' },
            { name: 'Godeni ' },
            { name: 'Hârseşti ', simpleName: 'Harsesti ' },
            { name: 'Hârtieşti ', simpleName: 'Hartiesti ' },
            { name: 'Izvoru ' },
            { name: 'Leordeni ' },
            { name: 'Lereşti ', simpleName: 'Leresti ' },
            { name: 'Lunca Corbului ' },
            { name: 'Merişani ', simpleName: 'Merisani ' },
            { name: 'Miceşti ', simpleName: 'Micesti ' },
            { name: 'Mihăeşti ', simpleName: 'Mihaesti ' },
            { name: 'Mioveni ' },
            { name: 'Miroşi ', simpleName: 'Mirosi ' },
            { name: 'Morăreşti ', simpleName: 'Moraresti ' },
            { name: 'Mozăceni ', simpleName: 'Mozaceni ' },
            { name: 'Moşoaia ', simpleName: 'Mosoaia ' },
            { name: 'Musăţeşti ', simpleName: 'Musatesti ' },
            { name: 'Mălureni ', simpleName: 'Malureni ' },
            { name: 'Mărăcineni ', simpleName: 'Maracineni ' },
            { name: 'Negraşi ', simpleName: 'Negrasi ' },
            { name: 'Nucşoara ', simpleName: 'Nucsoara ' },
            { name: 'Oarja ' },
            { name: 'Pietroşani ', simpleName: 'Pietrosani ' },
            { name: 'Piteşti ', simpleName: 'Pitesti ' },
            { name: 'Poiana Lacului ' },
            { name: 'Popeşti ', simpleName: 'Popesti ' },
            { name: 'Priboieni ' },
            { name: 'Recea ' },
            { name: 'Rociu ' },
            { name: 'Rucăr ', simpleName: 'Rucar ' },
            { name: 'Râca ', simpleName: 'Raca ' },
            { name: 'Răteşti ', simpleName: 'Ratesti ' },
            { name: 'Schitu Goleşti ', simpleName: 'Schitu Golesti ' },
            { name: 'Slobozia ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ' },
            { name: 'Stolnici ' },
            { name: 'Stâlpeni ', simpleName: 'Stalpeni ' },
            { name: 'Suseni ' },
            { name: 'Sălătrucu ', simpleName: 'Salatrucu ' },
            { name: 'Teiu ' },
            { name: 'Tigveni ' },
            { name: 'Topoloveni ' },
            { name: 'Uda ' },
            { name: 'Ungheni ' },
            { name: 'Valea Danului ' },
            { name: 'Valea Iaşului ', simpleName: 'Valea Iasului ' },
            { name: 'Valea Mare Pravăţ ', simpleName: 'Valea Mare Pravat ' },
            { name: 'Vedea ' },
            { name: 'Vlădeşti ', simpleName: 'Vladesti ' },
            { name: 'Vultureşti ', simpleName: 'Vulturesti ' },
            { name: 'Ştefan cel Mare ', simpleName: 'Stefan cel Mare ' },
            { name: 'Ştefăneşti ', simpleName: 'Stefanesti ' },
            { name: 'Şuici ', simpleName: 'Suici ' },
            { name: 'Ţiţeşti ', simpleName: 'Titesti ' },
            { name: 'Albeştii Pamânteni ', simpleName: 'Albestii Pamanteni ', village: 'Albestii De Arges ' },
            { name: 'Albeştii Ungureni ', simpleName: 'Albestii Ungureni ', village: 'Albestii De Arges ' },
            { name: 'Brăteşti ', simpleName: 'Bratesti ', village: 'Albestii De Arges ' },
            { name: 'Doblea ', village: 'Albestii De Arges ' },
            { name: 'Dobrotu ', village: 'Albestii De Arges ' },
            { name: 'Dumireşti ', simpleName: 'Dumiresti ', village: 'Albestii De Arges ' },
            { name: 'Florieni ', village: 'Albestii De Arges ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Albestii De Muscel ' },
            { name: 'Bughea de Sus ', village: 'Albestii De Muscel ' },
            { name: 'Cândeşti ', simpleName: 'Candesti ', village: 'Albestii De Muscel ' },
            { name: 'Cerbu ', village: 'Albota ' },
            { name: 'Frăteşti ', simpleName: 'Fratesti ', village: 'Albota ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Albota ' },
            { name: 'Mareş ', simpleName: 'Mares ', village: 'Albota ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Aninoasa ' },
            { name: 'Slănic ', simpleName: 'Slanic ', village: 'Aninoasa ' },
            { name: 'Valea Siliştii ', simpleName: 'Valea Silistii ', village: 'Aninoasa ' },
            { name: 'Căpăţânenii Pământeni ', simpleName: 'Capatanenii Pamanteni ', village: 'Arefu ' },
            { name: 'Căpăţânenii Ungureni ', simpleName: 'Capatanenii Ungureni ', village: 'Arefu ' },
            { name: 'Băjăneşti ', simpleName: 'Bajanesti ', village: 'Babana ' },
            { name: 'Ciobăneşti ', simpleName: 'Ciobanesti ', village: 'Babana ' },
            { name: 'Cotmeniţa ', simpleName: 'Cotmenita ', village: 'Babana ' },
            { name: 'Groşi ', simpleName: 'Grosi ', village: 'Babana ' },
            { name: 'Lupueni ', village: 'Babana ' },
            { name: 'Slăatioarele ', simpleName: 'Slaatioarele ', village: 'Babana ' },
            { name: 'Alunişu ', simpleName: 'Alunisu ', village: 'Baiculesti ' },
            { name: 'Anghineşti ', simpleName: 'Anghinesti ', village: 'Baiculesti ' },
            { name: 'Argeşani ', simpleName: 'Argesani ', village: 'Baiculesti ' },
            { name: 'Măniceşti ', simpleName: 'Manicesti ', village: 'Baiculesti ' },
            { name: 'Stejari ', village: 'Baiculesti ' },
            { name: 'Tutana ', village: 'Baiculesti ' },
            { name: 'Valea Brazilor ', village: 'Baiculesti ' },
            { name: 'Valea lui Enache ', village: 'Baiculesti ' },
            { name: 'Zigoneni ', village: 'Baiculesti ' },
            { name: 'Băjeşti ', simpleName: 'Bajesti ', village: 'Balilesti ' },
            { name: 'Goleşti ', simpleName: 'Golesti ', village: 'Balilesti ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Balilesti ' },
            { name: 'Priboaia ', village: 'Balilesti ' },
            { name: 'Uliţa ', simpleName: 'Ulita ', village: 'Balilesti ' },
            { name: 'Valea Mare-Bratia ', village: 'Balilesti ' },
            { name: 'Afrimeşti ', simpleName: 'Afrimesti ', village: 'Barla ' },
            { name: 'Brabeţi ', simpleName: 'Brabeti ', village: 'Barla ' },
            { name: 'Bădeşti ', simpleName: 'Badesti ', village: 'Barla ' },
            { name: 'Cioceşti ', simpleName: 'Ciocesti ', village: 'Barla ' },
            { name: 'Malu ', village: 'Barla ' },
            { name: 'Mozăcenii-Vale ', simpleName: 'Mozacenii-Vale ', village: 'Barla ' },
            { name: 'Mândra ', simpleName: 'Mandra ', village: 'Barla ' },
            { name: 'Podişoru ', simpleName: 'Podisoru ', village: 'Barla ' },
            { name: 'Urlueni ', village: 'Barla ' },
            { name: 'Zuvelcaţi ', simpleName: 'Zuvelcati ', village: 'Barla ' },
            { name: 'Şelăreasca ', simpleName: 'Selareasca ', village: 'Barla ' },
            { name: 'Brăileni ', simpleName: 'Braileni ', village: 'Bascov ' },
            { name: 'Glâmbocu ', simpleName: 'Glambocu ', village: 'Bascov ' },
            { name: 'Mica ', village: 'Bascov ' },
            { name: 'Prislopu Mic ', village: 'Bascov ' },
            { name: 'Scheau ', village: 'Bascov ' },
            { name: 'Uiasca ', village: 'Bascov ' },
            { name: 'Valea Ursului ', village: 'Bascov ' },
            { name: 'Beleţi ', simpleName: 'Beleti ', village: 'Beleti-Negresti ' },
            { name: 'Lenţea ', simpleName: 'Lentea ', village: 'Beleti-Negresti ' },
            { name: 'Negreşti ', simpleName: 'Negresti ', village: 'Beleti-Negresti ' },
            { name: 'Zgripceşti ', simpleName: 'Zgripcesti ', village: 'Beleti-Negresti ' },
            { name: 'Bratia ', village: 'Berevoesti ' },
            { name: 'Gămăceşti ', simpleName: 'Gamacesti ', village: 'Berevoesti ' },
            { name: 'Oţelu ', simpleName: 'Otelu ', village: 'Berevoesti ' },
            { name: 'Bujoi ', village: 'Bogati ' },
            { name: 'Bârloi ', simpleName: 'Barloi ', village: 'Bogati ' },
            { name: 'Chiţeşti ', simpleName: 'Chitesti ', village: 'Bogati ' },
            { name: 'Dumbrava ', village: 'Bogati ' },
            { name: 'Glâmbocel ', simpleName: 'Glambocel ', village: 'Bogati ' },
            { name: 'Glâmbocelu ', simpleName: 'Glambocelu ', village: 'Bogati ' },
            { name: 'Suseni ', village: 'Bogati ' },
            { name: 'Balabani ', village: 'Boteni ' },
            { name: 'Lunca ', village: 'Boteni ' },
            { name: 'Muşcel ', simpleName: 'Muscel ', village: 'Boteni ' },
            { name: 'Moşteni-Greci ', simpleName: 'Mosteni-Greci ', village: 'Botesti ' },
            { name: 'Geamăna ', simpleName: 'Geamana ', village: 'Bradu ' },
            { name: 'Alunişu ', simpleName: 'Alunisu ', village: 'Bradulet ' },
            { name: 'Brădetu ', simpleName: 'Bradetu ', village: 'Bradulet ' },
            { name: 'Cosaci ', village: 'Bradulet ' },
            { name: 'Galeşu ', simpleName: 'Galesu ', village: 'Bradulet ' },
            { name: 'Piatra ', village: 'Bradulet ' },
            { name: 'Slămneşti ', simpleName: 'Slamnesti ', village: 'Bradulet ' },
            { name: 'Uleni ', village: 'Bradulet ' },
            { name: 'Ungureni ', village: 'Bradulet ' },
            { name: 'Budeasa Mare ', village: 'Budeasa ' },
            { name: 'Budeasa Mică ', simpleName: 'Budeasa Mica ', village: 'Budeasa ' },
            { name: 'Caloteşti ', simpleName: 'Calotesti ', village: 'Budeasa ' },
            { name: 'Gălăşeşti ', simpleName: 'Galasesti ', village: 'Budeasa ' },
            { name: 'Rogojina ', village: 'Budeasa ' },
            { name: 'Valea Mărului ', simpleName: 'Valea Marului ', village: 'Budeasa ' },
            { name: 'Bujoreni ', village: 'Buzoesti ' },
            { name: 'Cornăţel ', simpleName: 'Cornatel ', village: 'Buzoesti ' },
            { name: 'Curteanca ', village: 'Buzoesti ' },
            { name: 'Ioneşti ', simpleName: 'Ionesti ', village: 'Buzoesti ' },
            { name: 'Podeni ', village: 'Buzoesti ' },
            { name: 'Redea ', village: 'Buzoesti ' },
            { name: 'Tomşanca ', simpleName: 'Tomsanca ', village: 'Buzoesti ' },
            { name: 'Vlăduţa ', simpleName: 'Vladuta ', village: 'Buzoesti ' },
            { name: 'Vulpeşti ', simpleName: 'Vulpesti ', village: 'Buzoesti ' },
            { name: 'Şerboeni ', simpleName: 'Serboeni ', village: 'Buzoesti ' },
            { name: 'Burdea ', village: 'Caldararu ' },
            { name: 'Strâmbeni ', simpleName: 'Strambeni ', village: 'Caldararu ' },
            { name: 'Ciocăneşti ', simpleName: 'Ciocanesti ', village: 'Calinesti ' },
            { name: 'Cârstieni ', simpleName: 'Carstieni ', village: 'Calinesti ' },
            { name: 'Glodu ', village: 'Calinesti ' },
            { name: 'Gorganu ', village: 'Calinesti ' },
            { name: 'Radu Negru ', village: 'Calinesti ' },
            { name: 'Râncăciov ', simpleName: 'Rancaciov ', village: 'Calinesti ' },
            { name: 'Udeni-Zăvoi ', simpleName: 'Udeni-Zavoi ', village: 'Calinesti ' },
            { name: 'Urlucea ', village: 'Calinesti ' },
            { name: 'Valea Corbului ', village: 'Calinesti ' },
            { name: 'Vrăneşti ', simpleName: 'Vranesti ', village: 'Calinesti ' },
            { name: 'Văleni-Podgoria ', simpleName: 'Valeni-Podgoria ', village: 'Calinesti ' },
            { name: 'Valea Rumâneştilor ', simpleName: 'Valea Rumanestilor ', village: 'Campulung ' },
            { name: 'Catanele ', village: 'Cateasca ' },
            { name: 'Cireşu ', simpleName: 'Ciresu ', village: 'Cateasca ' },
            { name: 'Coşeri ', simpleName: 'Coseri ', village: 'Cateasca ' },
            { name: 'Gruiu ', village: 'Cateasca ' },
            { name: 'Recea ', village: 'Cateasca ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Cateasca ' },
            { name: 'Ceparii Pământeni ', simpleName: 'Ceparii Pamanteni ', village: 'Cepari ' },
            { name: 'Ceparii Ungureni ', village: 'Cepari ' },
            { name: 'Cărpeniş ', simpleName: 'Carpenis ', village: 'Cepari ' },
            { name: 'Morăşti ', simpleName: 'Morasti ', village: 'Cepari ' },
            { name: 'Urluieşti ', simpleName: 'Urluiesti ', village: 'Cepari ' },
            { name: 'Valea Măgurei ', simpleName: 'Valea Magurei ', village: 'Cepari ' },
            { name: 'Zamfireşti ', simpleName: 'Zamfiresti ', village: 'Cepari ' },
            { name: 'Şendruleşti ', simpleName: 'Sendrulesti ', village: 'Cepari ' },
            { name: 'Lăicăi ', simpleName: 'Laicai ', village: 'Cetateni ' },
            { name: 'Valea Cetăţuia ', simpleName: 'Valea Cetatuia ', village: 'Cetateni ' },
            { name: 'Bărăşti ', simpleName: 'Barasti ', village: 'Cicanesti ' },
            { name: 'Mioarele ', village: 'Cicanesti ' },
            { name: 'Urecheşti ', simpleName: 'Urechesti ', village: 'Cicanesti ' },
            { name: 'Burluşi ', simpleName: 'Burlusi ', village: 'Ciofrangeni ' },
            { name: 'Lacurile ', village: 'Ciofrangeni ' },
            { name: 'Piatra ', village: 'Ciofrangeni ' },
            { name: 'Schitu-Matei ', village: 'Ciofrangeni ' },
            { name: 'Beculeşti ', simpleName: 'Beculesti ', village: 'Ciomagesti ' },
            { name: 'Bratia ', village: 'Ciomagesti ' },
            { name: 'Cungrea ', village: 'Ciomagesti ' },
            { name: 'Dogari ', village: 'Ciomagesti ' },
            { name: 'Fedeleşoiu ', simpleName: 'Fedelesoiu ', village: 'Ciomagesti ' },
            { name: 'Giuclani ', village: 'Ciomagesti ' },
            { name: 'Păuneşti ', simpleName: 'Paunesti ', village: 'Ciomagesti ' },
            { name: 'Răduţeşti ', simpleName: 'Radutesti ', village: 'Ciomagesti ' },
            { name: 'Bărbăteşti ', simpleName: 'Barbatesti ', village: 'Cocu ' },
            { name: 'Crucişoara ', simpleName: 'Crucisoara ', village: 'Cocu ' },
            { name: 'Făcăleţeşti ', simpleName: 'Facaletesti ', village: 'Cocu ' },
            { name: 'Greabănu ', simpleName: 'Greabanu ', village: 'Cocu ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Cocu ' },
            { name: 'Răchiţele de Jos ', simpleName: 'Rachitele de Jos ', village: 'Cocu ' },
            { name: 'Răchiţele de Sus ', simpleName: 'Rachitele de Sus ', village: 'Cocu ' },
            { name: 'Berindeşti ', simpleName: 'Berindesti ', village: 'Corbeni ' },
            { name: 'Bucşeneşti ', simpleName: 'Bucsenesti ', village: 'Corbeni ' },
            { name: 'Oeştii Pamânteni ', simpleName: 'Oestii Pamanteni ', village: 'Corbeni ' },
            { name: 'Oeştii Ungureni ', simpleName: 'Oestii Ungureni ', village: 'Corbeni ' },
            { name: 'Poienari ', village: 'Corbeni ' },
            { name: 'Rotunda ', village: 'Corbeni ' },
            { name: 'Turburea ', village: 'Corbeni ' },
            { name: 'Corbşori ', simpleName: 'Corbsori ', village: 'Corbi ' },
            { name: 'Jgheaburi ', village: 'Corbi ' },
            { name: 'Poduri ', village: 'Corbi ' },
            { name: 'Poienărei ', simpleName: 'Poienarei ', village: 'Corbi ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ', village: 'Corbi ' },
            { name: 'Jupâneşti ', simpleName: 'Jupanesti ', village: 'Cosesti ' },
            { name: 'Leiceşti ', simpleName: 'Leicesti ', village: 'Cosesti ' },
            { name: 'Lăpuşani ', simpleName: 'Lapusani ', village: 'Cosesti ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Cosesti ' },
            { name: 'Priseaca ', village: 'Cosesti ' },
            { name: 'Păcioiu ', simpleName: 'Pacioiu ', village: 'Cosesti ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Costesti ' },
            { name: 'Lăceni ', simpleName: 'Laceni ', village: 'Costesti ' },
            { name: 'Podu Broşteni ', simpleName: 'Podu Brosteni ', village: 'Costesti ' },
            { name: 'Pârvu Roşu ', simpleName: 'Parvu Rosu ', village: 'Costesti ' },
            { name: 'Smei ', village: 'Costesti ' },
            { name: 'Stârci ', simpleName: 'Starci ', village: 'Costesti ' },
            { name: 'Bascovele ', village: 'Cotmeana ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ', village: 'Cotmeana ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Cotmeana ' },
            { name: 'Dealu Pădurii ', simpleName: 'Dealu Padurii ', village: 'Cotmeana ' },
            { name: 'Drăgoleşti ', simpleName: 'Dragolesti ', village: 'Cotmeana ' },
            { name: 'Linteşti ', simpleName: 'Lintesti ', village: 'Cotmeana ' },
            { name: 'Negeşti ', simpleName: 'Negesti ', village: 'Cotmeana ' },
            { name: 'Pieleşti ', simpleName: 'Pielesti ', village: 'Cotmeana ' },
            { name: 'Spiridoni ', village: 'Cotmeana ' },
            { name: 'Sănduleşti ', simpleName: 'Sandulesti ', village: 'Cotmeana ' },
            { name: 'Ursoaia ', village: 'Cotmeana ' },
            { name: 'Vârloveni ', simpleName: 'Varloveni ', village: 'Cotmeana ' },
            { name: 'Zamfireşti ', simpleName: 'Zamfiresti ', village: 'Cotmeana ' },
            { name: 'Bălţata ', simpleName: 'Baltata ', village: 'Cuca ' },
            { name: 'Bărbălani ', simpleName: 'Barbalani ', village: 'Cuca ' },
            { name: 'Cotu ', village: 'Cuca ' },
            { name: 'Crivăţu ', simpleName: 'Crivatu ', village: 'Cuca ' },
            { name: 'Cârceşti ', simpleName: 'Carcesti ', village: 'Cuca ' },
            { name: 'Lăunele de Sus ', simpleName: 'Launele de Sus ', village: 'Cuca ' },
            { name: 'Măcăi ', simpleName: 'Macai ', village: 'Cuca ' },
            { name: 'Măneşti ', simpleName: 'Manesti ', village: 'Cuca ' },
            { name: 'Sineşti ', simpleName: 'Sinesti ', village: 'Cuca ' },
            { name: 'Stănicei ', simpleName: 'Stanicei ', village: 'Cuca ' },
            { name: 'Teodoreşti ', simpleName: 'Teodoresti ', village: 'Cuca ' },
            { name: 'Valea Cucii ', village: 'Cuca ' },
            { name: 'Vonigeasa ', village: 'Cuca ' },
            { name: 'Noapteş ', simpleName: 'Noaptes ', village: 'Curtea De Arges ' },
            { name: 'Ciocanu ', village: 'Dambovicioara ' },
            { name: 'Podu Dâmboviţei ', simpleName: 'Podu Dambovitei ', village: 'Dambovicioara ' },
            { name: 'Negreni ', village: 'Darmanesti ' },
            { name: 'Piscani ', village: 'Darmanesti ' },
            { name: 'Valea Nandrii ', village: 'Darmanesti ' },
            { name: 'Valea Rizii ', village: 'Darmanesti ' },
            { name: 'Conţeşti ', simpleName: 'Contesti ', village: 'Davidesti ' },
            { name: 'Voroveni ', village: 'Davidesti ' },
            { name: 'Fureşti ', simpleName: 'Furesti ', village: 'Dobresti ' },
            { name: 'Băceşti ', simpleName: 'Bacesti ', village: 'Draganu ' },
            { name: 'Drăganu-Olteni ', simpleName: 'Draganu-Olteni ', village: 'Draganu ' },
            { name: 'Dumbrăveşti ', simpleName: 'Dumbravesti ', village: 'Draganu ' },
            { name: 'Prislopu Mare ', village: 'Draganu ' },
            { name: 'Valea Hotarului ', village: 'Dragoslavele ' },
            { name: 'Bordeieni ', village: 'Godeni ' },
            { name: 'Capu Piscului ', village: 'Godeni ' },
            { name: 'Coteşti ', simpleName: 'Cotesti ', village: 'Godeni ' },
            { name: 'Malu ', village: 'Godeni ' },
            { name: 'Ciobani ', village: 'Harsesti ' },
            { name: 'Martalogi ', village: 'Harsesti ' },
            { name: 'Dealu ', village: 'Hartiesti ' },
            { name: 'Lespezi ', village: 'Hartiesti ' },
            { name: 'Lucieni ', village: 'Hartiesti ' },
            { name: 'Baloteasca ', village: 'Leordeni ' },
            { name: 'Budişteni ', simpleName: 'Budisteni ', village: 'Leordeni ' },
            { name: 'Bântău ', simpleName: 'Bantau ', village: 'Leordeni ' },
            { name: 'Băila ', simpleName: 'Baila ', village: 'Leordeni ' },
            { name: 'Ciolceşti ', simpleName: 'Ciolcesti ', village: 'Leordeni ' },
            { name: 'Ciulniţa ', simpleName: 'Ciulnita ', village: 'Leordeni ' },
            { name: 'Cotu Malului ', village: 'Leordeni ' },
            { name: 'Cârciumăreşti ', simpleName: 'Carciumaresti ', village: 'Leordeni ' },
            { name: 'Glodu ', village: 'Leordeni ' },
            { name: 'Glâmbocata ', simpleName: 'Glambocata ', village: 'Leordeni ' },
            { name: 'Glâmbocata-Deal ', simpleName: 'Glambocata-Deal ', village: 'Leordeni ' },
            { name: 'Moara Mocanului ', village: 'Leordeni ' },
            { name: 'Schitu Scoiceşti ', simpleName: 'Schitu Scoicesti ', village: 'Leordeni ' },
            { name: 'Pojorâta ', simpleName: 'Pojorata ', village: 'Leresti ' },
            { name: 'Voineşti ', simpleName: 'Voinesti ', village: 'Leresti ' },
            { name: 'Bumbueni ', village: 'Lunca Corbului ' },
            { name: 'Catane ', village: 'Lunca Corbului ' },
            { name: 'Cieşti ', simpleName: 'Ciesti ', village: 'Lunca Corbului ' },
            { name: 'Lăngeşti ', simpleName: 'Langesti ', village: 'Lunca Corbului ' },
            { name: 'Mârghia de Jos ', simpleName: 'Marghia de Jos ', village: 'Lunca Corbului ' },
            { name: 'Mârghia de Sus ', simpleName: 'Marghia de Sus ', village: 'Lunca Corbului ' },
            { name: 'Pădureţi ', simpleName: 'Padureti ', village: 'Lunca Corbului ' },
            { name: 'Silişteni ', simpleName: 'Silisteni ', village: 'Lunca Corbului ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ', village: 'Malureni ' },
            { name: 'Păuleasca ', simpleName: 'Pauleasca ', village: 'Malureni ' },
            { name: 'Topliţa ', simpleName: 'Toplita ', village: 'Malureni ' },
            { name: 'Zărneşti ', simpleName: 'Zarnesti ', village: 'Malureni ' },
            { name: 'Argeşelu ', simpleName: 'Argeselu ', village: 'Maracineni ' },
            { name: 'Borleşti ', simpleName: 'Borlesti ', village: 'Merisani ' },
            { name: 'Brăteasca ', simpleName: 'Brateasca ', village: 'Merisani ' },
            { name: 'Capu Piscului ', village: 'Merisani ' },
            { name: 'Crâmpotani ', simpleName: 'Crampotani ', village: 'Merisani ' },
            { name: 'Dobrogostea ', village: 'Merisani ' },
            { name: 'Malu Vânăt ', simpleName: 'Malu Vanat ', village: 'Merisani ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Merisani ' },
            { name: 'Vărzaru ', simpleName: 'Varzaru ', village: 'Merisani ' },
            { name: 'Brânzari ', simpleName: 'Branzari ', village: 'Micesti ' },
            { name: 'Purcăreni ', simpleName: 'Purcareni ', village: 'Micesti ' },
            { name: 'Păuleasca ', simpleName: 'Pauleasca ', village: 'Micesti ' },
            { name: 'Drăghici ', simpleName: 'Draghici ', village: 'Mihaesti ' },
            { name: 'Furnicoşi ', simpleName: 'Furnicosi ', village: 'Mihaesti ' },
            { name: 'Rudeni ', village: 'Mihaesti ' },
            { name: 'Valea Bradului ', village: 'Mihaesti ' },
            { name: 'Valea Popii ', village: 'Mihaesti ' },
            { name: 'Văcarea ', simpleName: 'Vacarea ', village: 'Mihaesti ' },
            { name: 'Aluniş ', simpleName: 'Alunis ', village: 'Mioarele ' },
            { name: 'Chilii ', village: 'Mioarele ' },
            { name: 'Coceneşti ', simpleName: 'Cocenesti ', village: 'Mioarele ' },
            { name: 'Măţău ', simpleName: 'Matau ', village: 'Mioarele ' },
            { name: 'Suslăneşti ', simpleName: 'Suslanesti ', village: 'Mioarele ' },
            { name: 'Clucereasa ', village: 'Mioveni ' },
            { name: 'Colibaşi ', simpleName: 'Colibasi ', village: 'Mioveni ' },
            { name: 'Făgetu ', simpleName: 'Fagetu ', village: 'Mioveni ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ', village: 'Mioveni ' },
            { name: 'Surduleşti ', simpleName: 'Surdulesti ', village: 'Mirosi ' },
            { name: 'Dealu Obejdeanului ', village: 'Moraresti ' },
            { name: 'Deduleşti ', simpleName: 'Dedulesti ', village: 'Moraresti ' },
            { name: 'Luminile ', village: 'Moraresti ' },
            { name: 'Măncioiu ', simpleName: 'Mancioiu ', village: 'Moraresti ' },
            { name: 'Săpunari ', simpleName: 'Sapunari ', village: 'Moraresti ' },
            { name: 'Bătrâni ', simpleName: 'Batrani ', village: 'Mosoaia ' },
            { name: 'Ciocănăi ', simpleName: 'Ciocanai ', village: 'Mosoaia ' },
            { name: 'Dealu Viilor ', village: 'Mosoaia ' },
            { name: 'Hinţeşti ', simpleName: 'Hintesti ', village: 'Mosoaia ' },
            { name: 'Lăzăreşti ', simpleName: 'Lazaresti ', village: 'Mosoaia ' },
            { name: 'Smeura ', village: 'Mosoaia ' },
            { name: 'Babaroaga ', village: 'Mozaceni ' },
            { name: 'Zidurile ', village: 'Mozaceni ' },
            { name: 'Bolovăneşti ', simpleName: 'Bolovanesti ', village: 'Musatesti ' },
            { name: 'Costeşti-Vâslan ', simpleName: 'Costesti-Vaslan ', village: 'Musatesti ' },
            { name: 'Prosia ', village: 'Musatesti ' },
            { name: 'Robaia ', village: 'Musatesti ' },
            { name: 'Stroeşti ', simpleName: 'Stroesti ', village: 'Musatesti ' },
            { name: 'Valea Faurului ', village: 'Musatesti ' },
            { name: 'Valea Muscelului ', village: 'Musatesti ' },
            { name: 'Valea lui Maş ', simpleName: 'Valea lui Mas ', village: 'Musatesti ' },
            { name: 'Vâlsăneşti ', simpleName: 'Valsanesti ', village: 'Musatesti ' },
            { name: 'Buta ', village: 'Negrasi ' },
            { name: 'Bârlogu ', simpleName: 'Barlogu ', village: 'Negrasi ' },
            { name: 'Mozacu ', village: 'Negrasi ' },
            { name: 'Gruiu ', village: 'Nucsoara ' },
            { name: 'Sboghiţeşti ', simpleName: 'Sboghitesti ', village: 'Nucsoara ' },
            { name: 'Slatina ', village: 'Nucsoara ' },
            { name: 'Ceauşeşti ', simpleName: 'Ceausesti ', village: 'Oarja ' },
            { name: 'Bădeşti ', simpleName: 'Badesti ', village: 'Pietrosani ' },
            { name: 'Găneşti ', simpleName: 'Ganesti ', village: 'Pietrosani ' },
            { name: 'Retevoieşti ', simpleName: 'Retevoiesti ', village: 'Pietrosani ' },
            { name: 'Vărzăroaia ', simpleName: 'Varzaroaia ', village: 'Pietrosani ' },
            { name: 'Cepari ', village: 'Poiana Lacului ' },
            { name: 'Cătunaşi ', simpleName: 'Catunasi ', village: 'Poiana Lacului ' },
            { name: 'Dealu Oraşului ', simpleName: 'Dealu Orasului ', village: 'Poiana Lacului ' },
            { name: 'Dealu Viilor ', village: 'Poiana Lacului ' },
            { name: 'Dinculeşti ', simpleName: 'Dinculesti ', village: 'Poiana Lacului ' },
            { name: 'Gâlceşti ', simpleName: 'Galcesti ', village: 'Poiana Lacului ' },
            { name: 'Găleţeanu ', simpleName: 'Galeteanu ', village: 'Poiana Lacului ' },
            { name: 'Gărdineşti ', simpleName: 'Gardinesti ', village: 'Poiana Lacului ' },
            { name: 'Metofu ', village: 'Poiana Lacului ' },
            { name: 'Păduroiu din Deal ', simpleName: 'Paduroiu din Deal ', village: 'Poiana Lacului ' },
            { name: 'Păduroiu din Vale ', simpleName: 'Paduroiu din Vale ', village: 'Poiana Lacului ' },
            { name: 'Sămara ', simpleName: 'Samara ', village: 'Poiana Lacului ' },
            { name: 'Ceaureşti ', simpleName: 'Ceauresti ', village: 'Poienarii De Arges ' },
            { name: 'Ioaniceşti ', simpleName: 'Ioanicesti ', village: 'Poienarii De Arges ' },
            { name: 'Poienari ', village: 'Poienarii De Arges ' },
            { name: 'Tomuleşti ', simpleName: 'Tomulesti ', village: 'Poienarii De Arges ' },
            { name: 'Groşani ', simpleName: 'Grosani ', village: 'Poienarii De Muscel ' },
            { name: 'Jugur ', village: 'Poienarii De Muscel ' },
            { name: 'Poienari ', village: 'Poienarii De Muscel ' },
            { name: 'Valea Îndărăt ', simpleName: 'Valea Indarat ', village: 'Poienarii De Muscel ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Poienarii De Muscel ' },
            { name: 'Palanga ', village: 'Popesti ' },
            { name: 'Purcăreni ', simpleName: 'Purcareni ', village: 'Popesti ' },
            { name: 'Slobozia ', village: 'Popesti ' },
            { name: 'Albotele ', village: 'Priboieni ' },
            { name: 'Paraschiveşti ', simpleName: 'Paraschivesti ', village: 'Priboieni ' },
            { name: 'Pitoi ', village: 'Priboieni ' },
            { name: 'Sămăila ', simpleName: 'Samaila ', village: 'Priboieni ' },
            { name: 'Valea Mare ', village: 'Priboieni ' },
            { name: 'Valea Nenii ', village: 'Priboieni ' },
            { name: 'Valea Popii ', village: 'Priboieni ' },
            { name: 'Adunaţi ', simpleName: 'Adunati ', village: 'Raca ' },
            { name: 'Bucov ', village: 'Raca ' },
            { name: 'Ciupa-Mănciulescu ', simpleName: 'Ciupa-Manciulescu ', village: 'Ratesti ' },
            { name: 'Furdueşti ', simpleName: 'Furduesti ', village: 'Ratesti ' },
            { name: 'Mavrodolu ', village: 'Ratesti ' },
            { name: 'Nejlovelu ', village: 'Ratesti ' },
            { name: 'Pătuleni ', simpleName: 'Patuleni ', village: 'Ratesti ' },
            { name: 'Tigveni ', village: 'Ratesti ' },
            { name: 'Deagu de Jos ', village: 'Recea ' },
            { name: 'Deagu de Sus ', village: 'Recea ' },
            { name: 'Goleasca ', village: 'Recea ' },
            { name: 'Orodel ', village: 'Recea ' },
            { name: 'Gliganu de Jos ', village: 'Rociu ' },
            { name: 'Gliganu de Sus ', village: 'Rociu ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Rociu ' },
            { name: 'Sătic ', simpleName: 'Satic ', village: 'Rucar ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Salatrucu ' },
            { name: 'Bănăreşti ', simpleName: 'Banaresti ', village: 'Sapata ' },
            { name: 'Dealu Bradului ', village: 'Sapata ' },
            { name: 'Drăghiceşti ', simpleName: 'Draghicesti ', village: 'Sapata ' },
            { name: 'Găinuşa ', simpleName: 'Gainusa ', village: 'Sapata ' },
            { name: 'Lipia ', village: 'Sapata ' },
            { name: 'Mârţeşti ', simpleName: 'Martesti ', village: 'Sapata ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Sapata ' },
            { name: 'Turceşti ', simpleName: 'Turcesti ', village: 'Sapata ' },
            { name: 'Burneşti ', simpleName: 'Burnesti ', village: 'Schitu Golesti ' },
            { name: 'Costiţă ', simpleName: 'Costita ', village: 'Schitu Golesti ' },
            { name: 'Loturi ', village: 'Schitu Golesti ' },
            { name: 'Lăzăreşti ', simpleName: 'Lazaresti ', village: 'Schitu Golesti ' },
            { name: 'Valea Pechii ', village: 'Schitu Golesti ' },
            { name: 'Nigrişoara ', simpleName: 'Nigrisoara ', village: 'Slobozia ' },
            { name: 'Dealu Frumos ', village: 'Stalpeni ' },
            { name: 'Livezeni ', village: 'Stalpeni ' },
            { name: 'Ogrezea ', village: 'Stalpeni ' },
            { name: 'Opreşti ', simpleName: 'Opresti ', village: 'Stalpeni ' },
            { name: 'Piţigaia ', simpleName: 'Pitigaia ', village: 'Stalpeni ' },
            { name: 'Rădeşti ', simpleName: 'Radesti ', village: 'Stalpeni ' },
            { name: 'Glavacioc ', village: 'Stefan Cel Mare ' },
            { name: 'Enculeşti ', simpleName: 'Enculesti ', village: 'Stefanesti ' },
            { name: 'Goleşti ', simpleName: 'Golesti ', village: 'Stefanesti ' },
            { name: 'Izvorani ', village: 'Stefanesti ' },
            { name: 'Valea Mare-Podgoria ', village: 'Stefanesti ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Stefanesti ' },
            { name: 'Zăvoi ', simpleName: 'Zavoi ', village: 'Stefanesti ' },
            { name: 'Ştefăneştii Noi ', simpleName: 'Stefanestii Noi ', village: 'Stefanesti ' },
            { name: 'Bădeni ', simpleName: 'Badeni ', village: 'Stoenesti ' },
            { name: 'Coteneşti ', simpleName: 'Cotenesti ', village: 'Stoenesti ' },
            { name: 'Lunca Gârtii ', simpleName: 'Lunca Gartii ', village: 'Stoenesti ' },
            { name: 'Piatra ', village: 'Stoenesti ' },
            { name: 'Slobozia ', village: 'Stoenesti ' },
            { name: 'Valea Bădenilor ', simpleName: 'Valea Badenilor ', village: 'Stoenesti ' },
            { name: 'Cochineşti ', simpleName: 'Cochinesti ', village: 'Stolnici ' },
            { name: 'Cotmeana ', village: 'Stolnici ' },
            { name: 'Fâlfani ', simpleName: 'Falfani ', village: 'Stolnici ' },
            { name: 'Izbăşeşti ', simpleName: 'Izbasesti ', village: 'Stolnici ' },
            { name: 'Vlăşcuţa ', simpleName: 'Vlascuta ', village: 'Stolnici ' },
            { name: 'Ianculeşti ', simpleName: 'Ianculesti ', village: 'Suici ' },
            { name: 'Paltenu ', village: 'Suici ' },
            { name: 'Păuleni ', simpleName: 'Pauleni ', village: 'Suici ' },
            { name: 'Rudeni ', village: 'Suici ' },
            { name: 'Valea Calului ', village: 'Suici ' },
            { name: 'Burdeşti ', simpleName: 'Burdesti ', village: 'Suseni ' },
            { name: 'Cerşani ', simpleName: 'Cersani ', village: 'Suseni ' },
            { name: 'Chiriţeşti ', simpleName: 'Chiritesti ', village: 'Suseni ' },
            { name: 'Găleşeşti ', simpleName: 'Galesesti ', village: 'Suseni ' },
            { name: 'Odăeni ', simpleName: 'Odaeni ', village: 'Suseni ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Suseni ' },
            { name: 'Stefăneşti ', simpleName: 'Stefanesti ', village: 'Suseni ' },
            { name: 'Strâmbeni ', simpleName: 'Strambeni ', village: 'Suseni ' },
            { name: 'Ţuţuleşti ', simpleName: 'Tutulesti ', village: 'Suseni ' },
            { name: 'Leşile ', simpleName: 'Lesile ', village: 'Teiu ' },
            { name: 'Blaju ', village: 'Tigveni ' },
            { name: 'Bârseştii de Jos ', simpleName: 'Barsestii de Jos ', village: 'Tigveni ' },
            { name: 'Bârseştii de Sus ', simpleName: 'Barsestii de Sus ', village: 'Tigveni ' },
            { name: 'Bădislava ', simpleName: 'Badislava ', village: 'Tigveni ' },
            { name: 'Bălileşti ', simpleName: 'Balilesti ', village: 'Tigveni ' },
            { name: 'Bălteni ', simpleName: 'Balteni ', village: 'Tigveni ' },
            { name: 'Vlădeşti ', simpleName: 'Vladesti ', village: 'Tigveni ' },
            { name: 'Bucşeneşti-Lotaşi ', simpleName: 'Bucsenesti-Lotasi ', village: 'Titesti ' },
            { name: 'Cişmea ', simpleName: 'Cismea ', village: 'Titesti ' },
            { name: 'Valea Mănăstirii ', simpleName: 'Valea Manastirii ', village: 'Titesti ' },
            { name: 'Valea Stânii ', simpleName: 'Valea Stanii ', village: 'Titesti ' },
            { name: 'Boţârcani ', simpleName: 'Botarcani ', village: 'Topoloveni ' },
            { name: 'Crinteşti ', simpleName: 'Crintesti ', village: 'Topoloveni ' },
            { name: 'Gorăneşti ', simpleName: 'Goranesti ', village: 'Topoloveni ' },
            { name: 'Ţigăneşti ', simpleName: 'Tiganesti ', village: 'Topoloveni ' },
            { name: 'Braniştea ', simpleName: 'Branistea ', village: 'Uda ' },
            { name: 'Băduleşti ', simpleName: 'Badulesti ', village: 'Uda ' },
            { name: 'Bărăneşti ', simpleName: 'Baranesti ', village: 'Uda ' },
            { name: 'Chiriţeşti ', simpleName: 'Chiritesti ', village: 'Uda ' },
            { name: 'Cotu ', village: 'Uda ' },
            { name: 'Dealu Bisericii ', village: 'Uda ' },
            { name: 'Dealu Tolcesii ', village: 'Uda ' },
            { name: 'Diconeşti ', simpleName: 'Diconesti ', village: 'Uda ' },
            { name: 'Gorani ', village: 'Uda ' },
            { name: 'Greabăn ', simpleName: 'Greaban ', village: 'Uda ' },
            { name: 'Lunguleşti ', simpleName: 'Lungulesti ', village: 'Uda ' },
            { name: 'Miercani ', village: 'Uda ' },
            { name: 'Romana ', village: 'Uda ' },
            { name: 'Râjleţu-Govora ', simpleName: 'Rajletu-Govora ', village: 'Uda ' },
            { name: 'Săliştea ', simpleName: 'Salistea ', village: 'Uda ' },
            { name: 'Colţu ', simpleName: 'Coltu ', village: 'Ungheni ' },
            { name: 'Goia ', village: 'Ungheni ' },
            { name: 'Găujani ', simpleName: 'Gaujani ', village: 'Ungheni ' },
            { name: 'Humele ', village: 'Ungheni ' },
            { name: 'Satu Nou ', village: 'Ungheni ' },
            { name: 'Bolculeşti ', simpleName: 'Bolculesti ', village: 'Valea Danului ' },
            { name: 'Borobăneşti ', simpleName: 'Borobanesti ', village: 'Valea Danului ' },
            { name: 'Băniceşti ', simpleName: 'Banicesti ', village: 'Valea Danului ' },
            { name: 'Verneşti ', simpleName: 'Vernesti ', village: 'Valea Danului ' },
            { name: 'Borovineşti ', simpleName: 'Borovinesti ', village: 'Valea Iasului ' },
            { name: 'Bădila ', simpleName: 'Badila ', village: 'Valea Iasului ' },
            { name: 'Bărbălăteşti ', simpleName: 'Barbalatesti ', village: 'Valea Iasului ' },
            { name: 'Cerbureni ', village: 'Valea Iasului ' },
            { name: 'Mustăţeşti ', simpleName: 'Mustatesti ', village: 'Valea Iasului ' },
            { name: 'Ruginoasa ', village: 'Valea Iasului ' },
            { name: 'Ungureni ', village: 'Valea Iasului ' },
            { name: 'Valea Uleiului ', village: 'Valea Iasului ' },
            { name: 'Bilceşti ', simpleName: 'Bilcesti ', village: 'Valea Mare Pravat ' },
            { name: 'Colnic ', village: 'Valea Mare Pravat ' },
            { name: 'Fântânea ', simpleName: 'Fantanea ', village: 'Valea Mare Pravat ' },
            { name: 'Gura Pravăţ ', simpleName: 'Gura Pravat ', village: 'Valea Mare Pravat ' },
            { name: 'Nămăeşti ', simpleName: 'Namaesti ', village: 'Valea Mare Pravat ' },
            { name: 'Pietroasa ', village: 'Valea Mare Pravat ' },
            { name: 'Şelari ', simpleName: 'Selari ', village: 'Valea Mare Pravat ' },
            { name: 'Blejani ', village: 'Vedea ' },
            { name: 'Bureţeşti ', simpleName: 'Buretesti ', village: 'Vedea ' },
            { name: 'Bădicea ', simpleName: 'Badicea ', village: 'Vedea ' },
            { name: 'Chiriţeşti ', simpleName: 'Chiritesti ', village: 'Vedea ' },
            { name: 'Chiţani ', simpleName: 'Chitani ', village: 'Vedea ' },
            { name: 'Ciureşti ', simpleName: 'Ciuresti ', village: 'Vedea ' },
            { name: 'Dincani ', village: 'Vedea ' },
            { name: 'Fata ', village: 'Vedea ' },
            { name: 'Frătici ', simpleName: 'Fratici ', village: 'Vedea ' },
            { name: 'Izvoru de Jos ', village: 'Vedea ' },
            { name: 'Izvoru de Sus ', village: 'Vedea ' },
            { name: 'Lungani ', village: 'Vedea ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ', village: 'Vedea ' },
            { name: 'Prodani ', village: 'Vedea ' },
            { name: 'Răţoi ', simpleName: 'Ratoi ', village: 'Vedea ' },
            { name: 'Vaţa ', simpleName: 'Vata ', village: 'Vedea ' },
            { name: 'Veţişoara ', simpleName: 'Vetisoara ', village: 'Vedea ' },
            { name: 'Vârşeşti ', simpleName: 'Varsesti ', village: 'Vedea ' },
            { name: 'Coteasca ', village: 'Vladesti ' },
            { name: 'Drăghescu ', simpleName: 'Draghescu ', village: 'Vladesti ' },
            { name: 'Putina ', village: 'Vladesti ' },
            { name: 'Bârzeşti ', simpleName: 'Barzesti ', village: 'Vulturesti ' },
            {
                name: 'Huluba ', village: 'Vulturesti '
            }
        ]
    },
    {
        auto: 'BC ',
        name: 'Bacău ',
        cities: [
            { name: 'Agăş ', simpleName: 'Agas ' },
            { name: 'Ardeoani ' },
            { name: 'Asău ', simpleName: 'Asau ' },
            { name: 'Bacău ', simpleName: 'Bacau ' },
            { name: 'Balcani ' },
            { name: 'Bereşti-Bistriţa ', simpleName: 'Beresti-Bistrita ' },
            { name: 'Bereşti-Tazlău ', simpleName: 'Beresti-Tazlau ' },
            { name: 'Berzunţi ', simpleName: 'Berzunti ' },
            { name: 'Blăgeşti ', simpleName: 'Blagesti ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ' },
            { name: 'Brusturoasa ' },
            { name: 'Buhoci ' },
            { name: 'Buhuşi ', simpleName: 'Buhusi ' },
            { name: 'Bârsăneşti ', simpleName: 'Barsanesti ' },
            { name: 'Caşin ', simpleName: 'Casin ' },
            { name: 'Cleja ' },
            { name: 'Coloneşti ', simpleName: 'Colonesti ' },
            { name: 'Comăneşti ', simpleName: 'Comanesti ' },
            { name: 'Corbasca ' },
            { name: 'Coţofăneşti ', simpleName: 'Cotofanesti ' },
            { name: 'Căiuţi ', simpleName: 'Caiuti ' },
            { name: 'Dealu Morii ' },
            { name: 'Dofteana ' },
            { name: 'Dămieneşti ', simpleName: 'Damienesti ' },
            { name: 'Dărmăneşti ', simpleName: 'Darmanesti ' },
            { name: 'Faraoani ' },
            { name: 'Filipeni ' },
            { name: 'Filipeşti ', simpleName: 'Filipesti ' },
            { name: 'Glăvăneşti ', simpleName: 'Glavanesti ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ' },
            { name: 'Gârleni ', simpleName: 'Garleni ' },
            { name: 'Găiceana ', simpleName: 'Gaiceana ' },
            { name: 'Helegiu ' },
            { name: 'Hemeiuş ', simpleName: 'Hemeius ' },
            { name: 'Horgeşti ', simpleName: 'Horgesti ' },
            { name: 'Huruieşti ', simpleName: 'Huruiesti ' },
            { name: 'Izvoru Berheciului ' },
            { name: 'Letea Veche ' },
            { name: 'Lipova ' },
            { name: 'Livezi ' },
            { name: 'Luizi-Călugăra ', simpleName: 'Luizi-Calugara ' },
            { name: 'Moineşti ', simpleName: 'Moinesti ' },
            { name: 'Motoşeni ', simpleName: 'Motoseni ' },
            { name: 'Măgireşti ', simpleName: 'Magiresti ' },
            { name: 'Măgura ', simpleName: 'Magura ' },
            { name: 'Mănăstirea Caşin ', simpleName: 'Manastirea Casin ' },
            { name: 'Mărgineni ', simpleName: 'Margineni ' },
            { name: 'Negri ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ' },
            { name: 'Oituz ' },
            { name: 'Onceşti ', simpleName: 'Oncesti ' },
            { name: 'Oneşti ', simpleName: 'Onesti ' },
            { name: 'Orbeni ' },
            { name: 'Palanca ' },
            { name: 'Parava ' },
            { name: 'Parincea ' },
            { name: 'Plopana ' },
            { name: 'Podu Turcului ' },
            { name: 'Poduri ' },
            { name: 'Pânceşti ', simpleName: 'Pancesti ' },
            { name: 'Pârgăreşti ', simpleName: 'Pargaresti ' },
            { name: 'Pârjol ', simpleName: 'Parjol ' },
            { name: 'Racova ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ' },
            { name: 'Răchitoasa ', simpleName: 'Rachitoasa ' },
            { name: 'Răcăciuni ', simpleName: 'Racaciuni ' },
            { name: 'Sascut ' },
            { name: 'Scorţeni ', simpleName: 'Scorteni ' },
            { name: 'Secuieni ' },
            { name: 'Solonţ ', simpleName: 'Solont ' },
            { name: 'Strugari ' },
            { name: 'Stănişeşti ', simpleName: 'Stanisesti ' },
            { name: 'Sănduleni ', simpleName: 'Sanduleni ' },
            { name: 'Săuceşti ', simpleName: 'Saucesti ' },
            { name: 'Tamaşi ', simpleName: 'Tamasi ' },
            { name: 'Traian ' },
            { name: 'Târgu Ocna ', simpleName: 'Targu Ocna ' },
            { name: 'Târgu Trotuş ', simpleName: 'Targu Trotus ' },
            { name: 'Tătărăşti ', simpleName: 'Tatarasti ' },
            { name: 'Ungureni ' },
            { name: 'Urecheşti ', simpleName: 'Urechesti ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ' },
            { name: 'Vultureni ' },
            { name: 'Zemeş ', simpleName: 'Zemes ' },
            { name: 'Ştefan cel Mare ', simpleName: 'Stefan cel Mare ' },
            { name: 'Beleghet ', village: 'Agas ' },
            { name: 'Cotumba ', village: 'Agas ' },
            { name: 'Coşnea ', simpleName: 'Cosnea ', village: 'Agas ' },
            { name: 'Diaconeşti ', simpleName: 'Diaconesti ', village: 'Agas ' },
            { name: 'Goioasa ', village: 'Agas ' },
            { name: 'Preluci ', village: 'Agas ' },
            { name: 'Sulţa ', simpleName: 'Sulta ', village: 'Agas ' },
            { name: 'Leontineşti ', simpleName: 'Leontinesti ', village: 'Ardeoani ' },
            { name: 'Apa Asău ', simpleName: 'Apa Asau ', village: 'Asau ' },
            { name: 'Ciobănuş ', simpleName: 'Ciobanus ', village: 'Asau ' },
            { name: 'Lunca Asău ', simpleName: 'Lunca Asau ', village: 'Asau ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ', village: 'Asau ' },
            { name: 'Straja ', village: 'Asau ' },
            { name: 'Frumoasa ', village: 'Balcani ' },
            { name: 'Ludaşi ', simpleName: 'Ludasi ', village: 'Balcani ' },
            { name: 'Schitu Frumoasa ', village: 'Balcani ' },
            { name: 'Albele ', village: 'Barsanesti ' },
            { name: 'Brăteşti ', simpleName: 'Bratesti ', village: 'Barsanesti ' },
            { name: 'Caraclău ', simpleName: 'Caraclau ', village: 'Barsanesti ' },
            { name: 'Brad ', village: 'Beresti-Bistrita ' },
            { name: 'Ciumaşi ', simpleName: 'Ciumasi ', village: 'Beresti-Bistrita ' },
            { name: 'Climeşti ', simpleName: 'Climesti ', village: 'Beresti-Bistrita ' },
            { name: 'Dumbrava ', village: 'Beresti-Bistrita ' },
            { name: 'Făgeţel ', simpleName: 'Fagetel ', village: 'Beresti-Bistrita ' },
            { name: 'Iteşti ', simpleName: 'Itesti ', village: 'Beresti-Bistrita ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Beresti-Bistrita ' },
            { name: 'Boşoteni ', simpleName: 'Bosoteni ', village: 'Beresti-Tazlau ' },
            { name: 'Enăcheşti ', simpleName: 'Enachesti ', village: 'Beresti-Tazlau ' },
            { name: 'Prisaca ', village: 'Beresti-Tazlau ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Beresti-Tazlau ' },
            { name: 'Tescani ', village: 'Beresti-Tazlau ' },
            { name: 'Turluianu ', village: 'Beresti-Tazlau ' },
            { name: 'Buda ', village: 'Berzunti ' },
            { name: 'Dragomir ', village: 'Berzunti ' },
            { name: 'Buda ', village: 'Blagesti ' },
            { name: 'Poiana Negustorului ', village: 'Blagesti ' },
            { name: 'Valea lui Ion ', village: 'Blagesti ' },
            { name: 'Ţârdenii Mari ', simpleName: 'Tardenii Mari ', village: 'Blagesti ' },
            { name: 'Filipeşti ', simpleName: 'Filipesti ', village: 'Bogdanesti ' },
            { name: 'Buruieniş ', simpleName: 'Buruienis ', village: 'Brusturoasa ' },
            { name: 'Buruienişu de Sus ', simpleName: 'Buruienisu de Sus ', village: 'Brusturoasa ' },
            { name: 'Camenca ', village: 'Brusturoasa ' },
            { name: 'Cuchiniş ', simpleName: 'Cuchinis ', village: 'Brusturoasa ' },
            { name: 'Hângăneşti ', simpleName: 'Hanganesti ', village: 'Brusturoasa ' },
            { name: 'Bijghir ', village: 'Buhoci ' },
            { name: 'Buhocel ', village: 'Buhoci ' },
            { name: 'Coteni ', village: 'Buhoci ' },
            { name: 'Dospineşti ', simpleName: 'Dospinesti ', village: 'Buhoci ' },
            { name: 'Marginea ', village: 'Buhusi ' },
            { name: 'Runcu ', village: 'Buhusi ' },
            { name: 'Blidari ', village: 'Caiuti ' },
            { name: 'Boiştea ', simpleName: 'Boistea ', village: 'Caiuti ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Caiuti ' },
            { name: 'Heltiu ', village: 'Caiuti ' },
            { name: 'Mărceşti ', simpleName: 'Marcesti ', village: 'Caiuti ' },
            { name: 'Popeni ', village: 'Caiuti ' },
            { name: 'Pralea ', village: 'Caiuti ' },
            { name: 'Vrânceni ', simpleName: 'Vranceni ', village: 'Caiuti ' },
            { name: 'Curiţa ', simpleName: 'Curita ', village: 'Casin ' },
            { name: 'Somuşca ', simpleName: 'Somusca ', village: 'Cleja ' },
            { name: 'Valea Mică ', simpleName: 'Valea Mica ', village: 'Cleja ' },
            { name: 'Călini ', simpleName: 'Calini ', village: 'Colonesti ' },
            { name: 'Poiana ', village: 'Colonesti ' },
            { name: 'Satu Nou ', village: 'Colonesti ' },
            { name: 'Spria ', village: 'Colonesti ' },
            { name: 'Valea Mare ', village: 'Colonesti ' },
            { name: 'Zăpodia ', simpleName: 'Zapodia ', village: 'Colonesti ' },
            { name: 'Podei ', village: 'Comanesti ' },
            { name: 'Vermeşti ', simpleName: 'Vermesti ', village: 'Comanesti ' },
            { name: 'Băcioiu ', simpleName: 'Bacioiu ', village: 'Corbasca ' },
            { name: 'Marvila ', village: 'Corbasca ' },
            { name: 'Pogleţ ', simpleName: 'Poglet ', village: 'Corbasca ' },
            { name: 'Rogoaza ', village: 'Corbasca ' },
            { name: 'Scărişoara ', simpleName: 'Scarisoara ', village: 'Corbasca ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Corbasca ' },
            { name: 'Boiştea de Jos ', simpleName: 'Boistea de Jos ', village: 'Cotofanesti ' },
            { name: 'Borşani ', simpleName: 'Borsani ', village: 'Cotofanesti ' },
            { name: 'Bâlca ', simpleName: 'Balca ', village: 'Cotofanesti ' },
            { name: 'Tămăşoaia ', simpleName: 'Tamasoaia ', village: 'Cotofanesti ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Damienesti ' },
            { name: 'Drăgeşti ', simpleName: 'Dragesti ', village: 'Damienesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Damienesti ' },
            { name: 'Dărmăneasca ', simpleName: 'Darmaneasca ', village: 'Darmanesti ' },
            { name: 'Lăpoş ', simpleName: 'Lapos ', village: 'Darmanesti ' },
            { name: 'Plopu ', village: 'Darmanesti ' },
            { name: 'Păgubeni ', simpleName: 'Pagubeni ', village: 'Darmanesti ' },
            { name: 'Sălătruc ', simpleName: 'Salatruc ', village: 'Darmanesti ' },
            { name: 'Banca ', village: 'Dealu Morii ' },
            { name: 'Blaga ', village: 'Dealu Morii ' },
            { name: 'Boboş ', simpleName: 'Bobos ', village: 'Dealu Morii ' },
            { name: 'Bodeasa ', village: 'Dealu Morii ' },
            { name: 'Bostăneşti ', simpleName: 'Bostanesti ', village: 'Dealu Morii ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ', village: 'Dealu Morii ' },
            { name: 'Calapodeşti ', simpleName: 'Calapodesti ', village: 'Dealu Morii ' },
            { name: 'Căuia ', simpleName: 'Cauia ', village: 'Dealu Morii ' },
            { name: 'Dorofei ', village: 'Dealu Morii ' },
            { name: 'Ghionoaia ', village: 'Dealu Morii ' },
            { name: 'Grădeşti ', simpleName: 'Gradesti ', village: 'Dealu Morii ' },
            { name: 'Neguleşti ', simpleName: 'Negulesti ', village: 'Dealu Morii ' },
            { name: 'Tăvădăreşti ', simpleName: 'Tavadaresti ', village: 'Dealu Morii ' },
            { name: 'Bogata ', village: 'Dofteana ' },
            { name: 'Cucuieţi ', simpleName: 'Cucuieti ', village: 'Dofteana ' },
            { name: 'Hăghiac ', simpleName: 'Haghiac ', village: 'Dofteana ' },
            { name: 'Larga ', village: 'Dofteana ' },
            { name: 'Seaca ', village: 'Dofteana ' },
            { name: 'Ştefan Vodă ', simpleName: 'Stefan Voda ', village: 'Dofteana ' },
            { name: 'Brad ', village: 'Filipeni ' },
            { name: 'Bălaia ', simpleName: 'Balaia ', village: 'Filipeni ' },
            { name: 'Frunteşti ', simpleName: 'Fruntesti ', village: 'Filipeni ' },
            { name: 'Mărăşti ', simpleName: 'Marasti ', village: 'Filipeni ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Filipeni ' },
            { name: 'Slobozia ', village: 'Filipeni ' },
            { name: 'Valea Boţului ', simpleName: 'Valea Botului ', village: 'Filipeni ' },
            { name: 'Boanţa ', simpleName: 'Boanta ', village: 'Filipesti ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Filipesti ' },
            { name: 'Cotu Grosului ', village: 'Filipesti ' },
            { name: 'Cârligi ', simpleName: 'Carligi ', village: 'Filipesti ' },
            { name: 'Galbeni ', village: 'Filipesti ' },
            { name: 'Hârleşti ', simpleName: 'Harlesti ', village: 'Filipesti ' },
            { name: 'Onişcani ', simpleName: 'Oniscani ', village: 'Filipesti ' },
            { name: 'Arini ', village: 'Gaiceana ' },
            { name: 'Huţu ', simpleName: 'Hutu ', village: 'Gaiceana ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Gaiceana ' },
            { name: 'Gârlenii de Sus ', simpleName: 'Garlenii de Sus ', village: 'Garleni ' },
            { name: 'Lespezi ', village: 'Garleni ' },
            { name: 'Şurina ', simpleName: 'Surina ', village: 'Garleni ' },
            { name: 'Bolovăniş ', simpleName: 'Bolovanis ', village: 'Ghimes-Faget ' },
            { name: 'Făget ', simpleName: 'Faget ', village: 'Ghimes-Faget ' },
            { name: 'Făgetu de Sus ', simpleName: 'Fagetu de Sus ', village: 'Ghimes-Faget ' },
            { name: 'Ghimeş ', simpleName: 'Ghimes ', village: 'Ghimes-Faget ' },
            { name: 'Răchitiş ', simpleName: 'Rachitis ', village: 'Ghimes-Faget ' },
            { name: 'Tărhăuşi ', simpleName: 'Tarhausi ', village: 'Ghimes-Faget ' },
            { name: 'Frumuşelu ', simpleName: 'Frumuselu ', village: 'Glavanesti ' },
            { name: 'Muncelu ', village: 'Glavanesti ' },
            { name: 'Putredeni ', village: 'Glavanesti ' },
            { name: 'Răzeşu ', simpleName: 'Razesu ', village: 'Glavanesti ' },
            { name: 'Capăta ', simpleName: 'Capata ', village: 'Gura Vaii ' },
            { name: 'Dumbrava ', village: 'Gura Vaii ' },
            { name: 'Motoceşti ', simpleName: 'Motocesti ', village: 'Gura Vaii ' },
            { name: 'Păltinata ', simpleName: 'Paltinata ', village: 'Gura Vaii ' },
            { name: 'Temelia ', village: 'Gura Vaii ' },
            { name: 'Brătila ', simpleName: 'Bratila ', village: 'Helegiu ' },
            { name: 'Deleni ', village: 'Helegiu ' },
            { name: 'Drăgugeşti ', simpleName: 'Dragugesti ', village: 'Helegiu ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Hemeius ' },
            { name: 'Lilieci ', village: 'Hemeius ' },
            { name: 'Bazga ', village: 'Horgesti ' },
            { name: 'Galeri ', village: 'Horgesti ' },
            { name: 'Mărăscu ', simpleName: 'Marascu ', village: 'Horgesti ' },
            { name: 'Recea ', village: 'Horgesti ' },
            { name: 'Răcătău de Jos ', simpleName: 'Racatau de Jos ', village: 'Horgesti ' },
            { name: 'Răcătău-Răzeşi ', simpleName: 'Racatau-Razesi ', village: 'Horgesti ' },
            { name: 'Sohodor ', village: 'Horgesti ' },
            { name: 'Căpoteşti ', simpleName: 'Capotesti ', village: 'Huruiesti ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Huruiesti ' },
            { name: 'Fundoaia ', village: 'Huruiesti ' },
            { name: 'Ocheni ', village: 'Huruiesti ' },
            { name: 'Perchiu ', village: 'Huruiesti ' },
            { name: 'Prădaiş ', simpleName: 'Pradais ', village: 'Huruiesti ' },
            { name: 'Antoheşti ', simpleName: 'Antohesti ', village: 'Izvoru Berheciului ' },
            { name: 'Băimac ', simpleName: 'Baimac ', village: 'Izvoru Berheciului ' },
            { name: 'Făghieni ', simpleName: 'Faghieni ', village: 'Izvoru Berheciului ' },
            { name: 'Obârşia ', simpleName: 'Obarsia ', village: 'Izvoru Berheciului ' },
            { name: 'Oţeleşti ', simpleName: 'Otelesti ', village: 'Izvoru Berheciului ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Izvoru Berheciului ' },
            { name: 'Holt ', village: 'Letea Veche ' },
            { name: 'Radomireşti ', simpleName: 'Radomiresti ', village: 'Letea Veche ' },
            { name: 'Ruşi-Ciutea ', simpleName: 'Rusi-Ciutea ', village: 'Letea Veche ' },
            { name: 'Siretu ', village: 'Letea Veche ' },
            { name: 'Mâlosu ', simpleName: 'Malosu ', village: 'Lipova ' },
            { name: 'Satu Nou ', village: 'Lipova ' },
            { name: 'Valea Caselor ', village: 'Lipova ' },
            { name: 'Valea Hogei ', village: 'Lipova ' },
            { name: 'Valea Moşneagului ', simpleName: 'Valea Mosneagului ', village: 'Lipova ' },
            { name: 'Valea Mărului ', simpleName: 'Valea Marului ', village: 'Lipova ' },
            { name: 'Bălăneasa ', simpleName: 'Balaneasa ', village: 'Livezi ' },
            { name: 'Orăşa ', simpleName: 'Orasa ', village: 'Livezi ' },
            { name: 'Poiana ', village: 'Livezi ' },
            { name: 'Prajoaia ', village: 'Livezi ' },
            { name: 'Scăriga ', simpleName: 'Scariga ', village: 'Livezi ' },
            { name: 'Osebiţi ', simpleName: 'Osebiti ', village: 'Luizi-Calugara ' },
            { name: 'Prăjeşti ', simpleName: 'Prajesti ', village: 'Magiresti ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ', village: 'Magiresti ' },
            { name: 'Valea Arinilor ', village: 'Magiresti ' },
            { name: 'Şesuri ', simpleName: 'Sesuri ', village: 'Magiresti ' },
            { name: 'Crihan ', village: 'Magura ' },
            { name: 'Dealu-Mare ', village: 'Magura ' },
            { name: 'Sohodol ', village: 'Magura ' },
            { name: 'Lupeşti ', simpleName: 'Lupesti ', village: 'Manastirea Casin ' },
            { name: 'Pârvuleşti ', simpleName: 'Parvulesti ', village: 'Manastirea Casin ' },
            { name: 'Scutaru ', village: 'Manastirea Casin ' },
            { name: 'Baraţi ', simpleName: 'Barati ', village: 'Margineni ' },
            { name: 'Luncani ', village: 'Margineni ' },
            { name: 'Podiş ', simpleName: 'Podis ', village: 'Margineni ' },
            { name: 'Poiana ', village: 'Margineni ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Margineni ' },
            { name: 'Trebeş ', simpleName: 'Trebes ', village: 'Margineni ' },
            { name: 'Valea Budului ', village: 'Margineni ' },
            { name: 'Găzărie ', simpleName: 'Gazarie ', village: 'Moinesti ' },
            { name: 'Bâcleşti ', simpleName: 'Baclesti ', village: 'Motoseni ' },
            { name: 'Chetreni ', village: 'Motoseni ' },
            { name: 'Chicerea ', village: 'Motoseni ' },
            { name: 'Cociu ', village: 'Motoseni ' },
            { name: 'Cornăţelu ', simpleName: 'Cornatelu ', village: 'Motoseni ' },
            { name: 'Fundătura ', simpleName: 'Fundatura ', village: 'Motoseni ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Motoseni ' },
            { name: 'Gura Crăieşti ', simpleName: 'Gura Craiesti ', village: 'Motoseni ' },
            { name: 'Poiana ', village: 'Motoseni ' },
            { name: 'Praja ', village: 'Motoseni ' },
            { name: 'Rotăria ', simpleName: 'Rotaria ', village: 'Motoseni ' },
            { name: 'Şendreşti ', simpleName: 'Sendresti ', village: 'Motoseni ' },
            { name: 'Ţepoaia ', simpleName: 'Tepoaia ', village: 'Motoseni ' },
            { name: 'Brad ', village: 'Negri ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Negri ' },
            { name: 'Mâgla ', simpleName: 'Magla ', village: 'Negri ' },
            { name: 'Poiana ', village: 'Negri ' },
            { name: 'Ursoaia ', village: 'Negri ' },
            { name: 'Buchila ', village: 'Nicolae Balcescu ' },
            { name: 'Bălţata ', simpleName: 'Baltata ', village: 'Nicolae Balcescu ' },
            { name: 'Galbeni ', village: 'Nicolae Balcescu ' },
            { name: 'Lărguţa ', simpleName: 'Larguta ', village: 'Nicolae Balcescu ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Nicolae Balcescu ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Nicolae Balcescu ' },
            { name: 'Călcâi ', simpleName: 'Calcai ', village: 'Oituz ' },
            { name: 'Ferestrău-Oituz ', simpleName: 'Ferestrau-Oituz ', village: 'Oituz ' },
            { name: 'Hârja ', simpleName: 'Harja ', village: 'Oituz ' },
            { name: 'Marginea ', village: 'Oituz ' },
            { name: 'Poiana Sărată ', simpleName: 'Poiana Sarata ', village: 'Oituz ' },
            { name: 'Bărboasa ', simpleName: 'Barboasa ', village: 'Oncesti ' },
            { name: 'Dealu Perjului ', village: 'Oncesti ' },
            { name: 'Onceştii Vechi ', simpleName: 'Oncestii Vechi ', village: 'Oncesti ' },
            { name: 'Satu Nou ', village: 'Oncesti ' },
            { name: 'Tarniţa ', simpleName: 'Tarnita ', village: 'Oncesti ' },
            { name: 'Taula ', village: 'Oncesti ' },
            { name: 'Borzeşti ', simpleName: 'Borzesti ', village: 'Onesti ' },
            { name: 'Slobozia ', village: 'Onesti ' },
            { name: 'Scurta ', village: 'Orbeni ' },
            { name: 'Ciugheş ', simpleName: 'Ciughes ', village: 'Palanca ' },
            { name: 'Cădăreşti ', simpleName: 'Cadaresti ', village: 'Palanca ' },
            { name: 'Pajiştea ', simpleName: 'Pajistea ', village: 'Palanca ' },
            { name: 'Popoiu ', village: 'Palanca ' },
            { name: 'Chilia Benei ', village: 'Pancesti ' },
            { name: 'Dieneţ ', simpleName: 'Dienet ', village: 'Pancesti ' },
            { name: 'Fulgeriş ', simpleName: 'Fulgeris ', village: 'Pancesti ' },
            { name: 'Fundu Văii ', simpleName: 'Fundu Vaii ', village: 'Pancesti ' },
            { name: 'Motoc ', village: 'Pancesti ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Pancesti ' },
            { name: 'Soci ', village: 'Pancesti ' },
            { name: 'Drăguşani ', simpleName: 'Dragusani ', village: 'Parava ' },
            { name: 'Rădoaia ', simpleName: 'Radoaia ', village: 'Parava ' },
            { name: 'Teiuş ', simpleName: 'Teius ', village: 'Parava ' },
            { name: 'Bahna ', village: 'Pargaresti ' },
            { name: 'Nicoreşti ', simpleName: 'Nicoresti ', village: 'Pargaresti ' },
            { name: 'Pârâu Boghii ', simpleName: 'Parau Boghii ', village: 'Pargaresti ' },
            { name: 'Satu Nou ', village: 'Pargaresti ' },
            { name: 'Barna ', village: 'Parincea ' },
            { name: 'Mileştii de Jos ', simpleName: 'Milestii de Jos ', village: 'Parincea ' },
            { name: 'Mileştii de Sus ', simpleName: 'Milestii de Sus ', village: 'Parincea ' },
            { name: 'Năneşti ', simpleName: 'Nanesti ', village: 'Parincea ' },
            { name: 'Năstăseni ', simpleName: 'Nastaseni ', village: 'Parincea ' },
            { name: 'Poieni ', village: 'Parincea ' },
            { name: 'Satu Nou ', village: 'Parincea ' },
            { name: 'Vladnic ', village: 'Parincea ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Parincea ' },
            { name: 'Băhnăşeni ', simpleName: 'Bahnaseni ', village: 'Parjol ' },
            { name: 'Bărneşti ', simpleName: 'Barnesti ', village: 'Parjol ' },
            { name: 'Băsăşti ', simpleName: 'Basasti ', village: 'Parjol ' },
            { name: 'Câmpeni ', simpleName: 'Campeni ', village: 'Parjol ' },
            { name: 'Hemieni ', village: 'Parjol ' },
            { name: 'Hâineala ', simpleName: 'Haineala ', village: 'Parjol ' },
            { name: 'Pustiana ', village: 'Parjol ' },
            { name: 'Tărâţa ', simpleName: 'Tarata ', village: 'Parjol ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Plopana ' },
            { name: 'Dorneni ', village: 'Plopana ' },
            { name: 'Fundu Tutovei ', village: 'Plopana ' },
            { name: 'Iţcani ', simpleName: 'Itcani ', village: 'Plopana ' },
            { name: 'Rusenii Răzeşi ', simpleName: 'Rusenii Razesi ', village: 'Plopana ' },
            { name: 'Rusenii de Sus ', village: 'Plopana ' },
            { name: 'Străminoasa ', simpleName: 'Straminoasa ', village: 'Plopana ' },
            { name: 'Ţâgâra ', simpleName: 'Tagara ', village: 'Plopana ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ', village: 'Podu Turcului ' },
            { name: 'Căbeşti ', simpleName: 'Cabesti ', village: 'Podu Turcului ' },
            { name: 'Fichiteşti ', simpleName: 'Fichitesti ', village: 'Podu Turcului ' },
            { name: 'Giurgioana ', village: 'Podu Turcului ' },
            { name: 'Hanţa ', simpleName: 'Hanta ', village: 'Podu Turcului ' },
            { name: 'Lehancea ', village: 'Podu Turcului ' },
            { name: 'Plopu ', village: 'Podu Turcului ' },
            { name: 'Răcuşana ', simpleName: 'Racusana ', village: 'Podu Turcului ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Podu Turcului ' },
            { name: 'Bucşeşti ', simpleName: 'Bucsesti ', village: 'Poduri ' },
            { name: 'Cernu ', village: 'Poduri ' },
            { name: 'Cornet ', village: 'Poduri ' },
            { name: 'Negreni ', village: 'Poduri ' },
            { name: 'Prohozeşti ', simpleName: 'Prohozesti ', village: 'Poduri ' },
            { name: 'Valea Şoşii ', simpleName: 'Valea Sosii ', village: 'Poduri ' },
            { name: 'Ciucani ', village: 'Racaciuni ' },
            { name: 'Fundu Răcăciuni ', simpleName: 'Fundu Racaciuni ', village: 'Racaciuni ' },
            { name: 'Gheorghe Doja ', village: 'Racaciuni ' },
            { name: 'Gâşteni ', simpleName: 'Gasteni ', village: 'Racaciuni ' },
            { name: 'Răstoaca ', simpleName: 'Rastoaca ', village: 'Racaciuni ' },
            { name: 'Barcana ', village: 'Rachitoasa ' },
            { name: 'Bucşa ', simpleName: 'Bucsa ', village: 'Rachitoasa ' },
            { name: 'Buda ', village: 'Rachitoasa ' },
            { name: 'Burdusaci ', village: 'Rachitoasa ' },
            { name: 'Dumbrava ', village: 'Rachitoasa ' },
            { name: 'Dănăila ', simpleName: 'Danaila ', village: 'Rachitoasa ' },
            { name: 'Farcaşa ', simpleName: 'Farcasa ', village: 'Rachitoasa ' },
            { name: 'Fundătura Răchitoasa ', simpleName: 'Fundatura Rachitoasa ', village: 'Rachitoasa ' },
            { name: 'Hăghiac ', simpleName: 'Haghiac ', village: 'Rachitoasa ' },
            { name: 'Magazia ', village: 'Rachitoasa ' },
            { name: 'Moviliţa ', simpleName: 'Movilita ', village: 'Rachitoasa ' },
            { name: 'Oprişeşti ', simpleName: 'Oprisesti ', village: 'Rachitoasa ' },
            { name: 'Putini ', village: 'Rachitoasa ' },
            { name: 'Tochilea ', village: 'Rachitoasa ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Racova ' },
            { name: 'Hălmăcioaia ', simpleName: 'Halmacioaia ', village: 'Racova ' },
            { name: 'Ilieşi ', simpleName: 'Iliesi ', village: 'Racova ' },
            { name: 'Misihăneşti ', simpleName: 'Misihanesti ', village: 'Rosiori ' },
            { name: 'Neguşeni ', simpleName: 'Neguseni ', village: 'Rosiori ' },
            { name: 'Poieni ', village: 'Rosiori ' },
            { name: 'Valea Mare ', village: 'Rosiori ' },
            { name: 'Valea Mică ', simpleName: 'Valea Mica ', village: 'Rosiori ' },
            { name: 'Bârzuleşti ', simpleName: 'Barzulesti ', village: 'Sanduleni ' },
            { name: 'Coman ', village: 'Sanduleni ' },
            { name: 'Mateieşti ', simpleName: 'Mateiesti ', village: 'Sanduleni ' },
            { name: 'Stufu ', village: 'Sanduleni ' },
            { name: 'Tisa ', village: 'Sanduleni ' },
            { name: 'Verşeşti ', simpleName: 'Versesti ', village: 'Sanduleni ' },
            { name: 'Bereşti ', simpleName: 'Beresti ', village: 'Sascut ' },
            { name: 'Conţeşti ', simpleName: 'Contesti ', village: 'Sascut ' },
            { name: 'Pănceşti ', simpleName: 'Pancesti ', village: 'Sascut ' },
            { name: 'Sascut-Sat ', village: 'Sascut ' },
            { name: 'Schineni ', village: 'Sascut ' },
            { name: 'Valea Nacului ', village: 'Sascut ' },
            { name: 'Bogdan Vodă ', simpleName: 'Bogdan Voda ', village: 'Saucesti ' },
            { name: 'Costei ', village: 'Saucesti ' },
            { name: 'Schineni ', village: 'Saucesti ' },
            { name: 'Siretu ', village: 'Saucesti ' },
            { name: 'Şerbeşti ', simpleName: 'Serbesti ', village: 'Saucesti ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Scorteni ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Scorteni ' },
            { name: 'Grigoreni ', village: 'Scorteni ' },
            { name: 'Stejaru ', village: 'Scorteni ' },
            { name: 'Şerpeni ', simpleName: 'Serpeni ', village: 'Scorteni ' },
            { name: 'Berbinceni ', village: 'Secuieni ' },
            { name: 'Băluşa ', simpleName: 'Balusa ', village: 'Secuieni ' },
            { name: 'Chiticeni ', village: 'Secuieni ' },
            { name: 'Ciutureşti ', simpleName: 'Ciuturesti ', village: 'Secuieni ' },
            { name: 'Fundeni ', village: 'Secuieni ' },
            { name: 'Glodişoarele ', simpleName: 'Glodisoarele ', village: 'Secuieni ' },
            { name: 'Odobeşti ', simpleName: 'Odobesti ', village: 'Secuieni ' },
            { name: 'Tisa-Silvestri ', village: 'Secuieni ' },
            { name: 'Valea Fânaţului ', simpleName: 'Valea Fanatului ', village: 'Secuieni ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Secuieni ' },
            { name: 'Cerdac ', village: 'Slanic Moldova ' },
            { name: 'Cireşoaia ', simpleName: 'Ciresoaia ', village: 'Slanic Moldova ' },
            { name: 'Slănic-Moldova ', simpleName: 'Slanic-Moldova ', village: 'Slanic Moldova ' },
            { name: 'Cucuieţi ', simpleName: 'Cucuieti ', village: 'Solont ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Solont ' },
            { name: 'Baloteşti ', simpleName: 'Balotesti ', village: 'Stanisesti ' },
            { name: 'Belciuneasa ', village: 'Stanisesti ' },
            { name: 'Beneşti ', simpleName: 'Benesti ', village: 'Stanisesti ' },
            { name: 'Crăieşti ', simpleName: 'Craiesti ', village: 'Stanisesti ' },
            { name: 'Gorgheşti ', simpleName: 'Gorghesti ', village: 'Stanisesti ' },
            { name: 'Slobozia ', village: 'Stanisesti ' },
            { name: 'Slobozia Nouă ', simpleName: 'Slobozia Noua ', village: 'Stanisesti ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Stanisesti ' },
            { name: 'Bogdana ', village: 'Stefan Cel Mare ' },
            { name: 'Buciumi ', village: 'Stefan Cel Mare ' },
            { name: 'Gutinaş ', simpleName: 'Gutinas ', village: 'Stefan Cel Mare ' },
            { name: 'Negoieşti ', simpleName: 'Negoiesti ', village: 'Stefan Cel Mare ' },
            { name: 'Răcăuţi ', simpleName: 'Racauti ', village: 'Stefan Cel Mare ' },
            { name: 'Rădeana ', simpleName: 'Radeana ', village: 'Stefan Cel Mare ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Stefan Cel Mare ' },
            { name: 'Cetăţuia ', simpleName: 'Cetatuia ', village: 'Strugari ' },
            { name: 'Iaz ', village: 'Strugari ' },
            { name: 'Nadişa ', simpleName: 'Nadisa ', village: 'Strugari ' },
            { name: 'Petricica ', village: 'Strugari ' },
            { name: 'Răchitişu ', simpleName: 'Rachitisu ', village: 'Strugari ' },
            { name: 'Chetriş ', simpleName: 'Chetris ', village: 'Tamasi ' },
            { name: 'Furnicari ', village: 'Tamasi ' },
            { name: 'Gioseni ', village: 'Tamasi ' },
            { name: 'Poieni ', village: 'Targu Ocna ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Targu Ocna ' },
            { name: 'Tuta ', village: 'Targu Trotus ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Targu Trotus ' },
            { name: 'Cornii de Jos ', village: 'Tatarasti ' },
            { name: 'Cornii de Sus ', village: 'Tatarasti ' },
            { name: 'Drăgeşti ', simpleName: 'Dragesti ', village: 'Tatarasti ' },
            { name: 'Gherdana ', village: 'Tatarasti ' },
            { name: 'Giurgeni ', village: 'Tatarasti ' },
            { name: 'Ungureni ', village: 'Tatarasti ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Traian ' },
            { name: 'Hertioana de Jos ', village: 'Traian ' },
            { name: 'Hertioana-Răzeşi ', simpleName: 'Hertioana-Razesi ', village: 'Traian ' },
            { name: 'Prăjeşti ', simpleName: 'Prajesti ', village: 'Traian ' },
            { name: 'Zăpodia ', simpleName: 'Zapodia ', village: 'Traian ' },
            { name: 'Bibireşti ', simpleName: 'Bibiresti ', village: 'Ungureni ' },
            { name: 'Bota ', village: 'Ungureni ' },
            { name: 'Boteşti ', simpleName: 'Botesti ', village: 'Ungureni ' },
            { name: 'Bărtăşeşti ', simpleName: 'Bartasesti ', village: 'Ungureni ' },
            { name: 'Gârla Anei ', simpleName: 'Garla Anei ', village: 'Ungureni ' },
            { name: 'Viforeni ', village: 'Ungureni ' },
            { name: 'Zlătari ', simpleName: 'Zlatari ', village: 'Ungureni ' },
            { name: 'Cornăţel ', simpleName: 'Cornatel ', village: 'Urechesti ' },
            { name: 'Lunca Dochiei ', village: 'Urechesti ' },
            { name: 'Satu Nou ', village: 'Urechesti ' },
            { name: 'Slobozia ', village: 'Urechesti ' },
            { name: 'Cucova ', village: 'Valea Seaca ' },
            { name: 'Bosia ', village: 'Vultureni ' },
            { name: 'Dorneni ', village: 'Vultureni ' },
            { name: 'Dădeşti ', simpleName: 'Dadesti ', village: 'Vultureni ' },
            { name: 'Ghilăveşti ', simpleName: 'Ghilavesti ', village: 'Vultureni ' },
            { name: 'Godineştii de Jos ', simpleName: 'Godinestii de Jos ', village: 'Vultureni ' },
            { name: 'Godineştii de Sus ', simpleName: 'Godinestii de Sus ', village: 'Vultureni ' },
            { name: 'Lichitişeni ', simpleName: 'Lichitiseni ', village: 'Vultureni ' },
            { name: 'Medeleni ', village: 'Vultureni ' },
            { name: 'Năzărioaia ', simpleName: 'Nazarioaia ', village: 'Vultureni ' },
            { name: 'Reprivăţ ', simpleName: 'Reprivat ', village: 'Vultureni ' },
            { name: 'Tomozia ', village: 'Vultureni ' },
            { name: 'Valea Lupului ', village: 'Vultureni ' },
            { name: 'Valea Merilor ', village: 'Vultureni ' },
            { name: 'Valea Salciei ', village: 'Vultureni ' },
            { name: 'Ţigăneşti ', simpleName: 'Tiganesti ', village: 'Vultureni ' },
            {
                name: 'Bolătău ', simpleName: 'Bolatau ', village: 'Zemes '
            }
        ]
    },
    {
        auto: 'BH ',
        name: 'Bihor ',
        cities: [
            { name: 'Abram ' },
            { name: 'Abrămuţ ', simpleName: 'Abramut ' },
            { name: 'Aleşd ', simpleName: 'Alesd ' },
            { name: 'Auşeu ', simpleName: 'Auseu ' },
            { name: 'Avram Iancu ' },
            { name: 'Aştileu ', simpleName: 'Astileu ' },
            { name: 'Balc ' },
            { name: 'Batăr ', simpleName: 'Batar ' },
            { name: 'Beiuş ', simpleName: 'Beius ' },
            { name: 'Biharia ' },
            { name: 'Boianu Mare ' },
            { name: 'Borod ' },
            { name: 'Borş ', simpleName: 'Bors ' },
            { name: 'Bratca ' },
            { name: 'Brusturi ' },
            { name: 'Budureasa ' },
            { name: 'Buduslău ', simpleName: 'Buduslau ' },
            { name: 'Bulz ' },
            { name: 'Bunteşti ', simpleName: 'Buntesti ' },
            { name: 'Cefa ' },
            { name: 'Ceica ' },
            { name: 'Cetariu ' },
            { name: 'Cherechiu ' },
            { name: 'Chişlaz ', simpleName: 'Chislaz ' },
            { name: 'Ciuhoi ' },
            { name: 'Ciumeghiu ' },
            { name: 'Cociuba Mare ' },
            { name: 'Copăcel ', simpleName: 'Copacel ' },
            { name: 'Criştioru de Jos ', simpleName: 'Cristioru de Jos ' },
            { name: 'Curtuişeni ', simpleName: 'Curtuiseni ' },
            { name: 'Curăţele ', simpleName: 'Curatele ' },
            { name: 'Câmpani ', simpleName: 'Campani ' },
            { name: 'Căbeşti ', simpleName: 'Cabesti ' },
            { name: 'Căpâlna ', simpleName: 'Capalna ' },
            { name: 'Cărpinet ', simpleName: 'Carpinet ' },
            { name: 'Derna ' },
            { name: 'Diosig ' },
            { name: 'Dobreşti ', simpleName: 'Dobresti ' },
            { name: 'Drăgeşti ', simpleName: 'Dragesti ' },
            { name: 'Drăgăneşti ', simpleName: 'Draganesti ' },
            { name: 'Finiş ', simpleName: 'Finis ' },
            { name: 'Gepiu ' },
            { name: 'Girişu de Criş ', simpleName: 'Girisu de Cris ' },
            { name: 'Hidişelu de Sus ', simpleName: 'Hidiselu de Sus ' },
            { name: 'Holod ' },
            { name: 'Husasău de Tinca ', simpleName: 'Husasau de Tinca ' },
            { name: 'Ineu ' },
            { name: 'Lazuri de Beiuş ', simpleName: 'Lazuri de Beius ' },
            { name: 'Lugaşu de Jos ', simpleName: 'Lugasu de Jos ' },
            { name: 'Lunca ' },
            { name: 'Lăzăreni ', simpleName: 'Lazareni ' },
            { name: 'Marghita ' },
            { name: 'Mădăraş ', simpleName: 'Madaras ' },
            { name: 'Măgeşti ', simpleName: 'Magesti ' },
            { name: 'Nojorid ' },
            { name: 'Nucet ' },
            { name: 'Olcea ' },
            { name: 'Oradea ' },
            { name: 'Oşorhei ', simpleName: 'Osorhei ' },
            { name: 'Paleu ' },
            { name: 'Pietroasa ' },
            { name: 'Pocola ' },
            { name: 'Pomezeu ' },
            { name: 'Popeşti ', simpleName: 'Popesti ' },
            { name: 'Remetea ' },
            { name: 'Rieni ' },
            { name: 'Roşia ', simpleName: 'Rosia ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ' },
            { name: 'Răbăgani ', simpleName: 'Rabagani ' },
            { name: 'Salonta ' },
            { name: 'Spinuş ', simpleName: 'Spinus ' },
            { name: 'Suplacu de Barcău ', simpleName: 'Suplacu de Barcau ' },
            { name: 'Sâmbăta ', simpleName: 'Sambata ' },
            { name: 'Sânmartin ', simpleName: 'Sanmartin ' },
            { name: 'Sânnicolau Român ', simpleName: 'Sannicolau Roman ' },
            { name: 'Sântandrei ', simpleName: 'Santandrei ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ' },
            { name: 'Săcueni ', simpleName: 'Sacueni ' },
            { name: 'Săcădat ', simpleName: 'Sacadat ' },
            { name: 'Sălacea ', simpleName: 'Salacea ' },
            { name: 'Sălard ', simpleName: 'Salard ' },
            { name: 'Tarcea ' },
            { name: 'Tileagd ' },
            { name: 'Tinca ' },
            { name: 'Tulca ' },
            { name: 'Tămăşeu ', simpleName: 'Tamaseu ' },
            { name: 'Tărcaia ', simpleName: 'Tarcaia ' },
            { name: 'Tăuteu ', simpleName: 'Tauteu ' },
            { name: 'Uileacu de Beiuş ', simpleName: 'Uileacu de Beius ' },
            { name: 'Vadu Crişului ', simpleName: 'Vadu Crisului ' },
            { name: 'Valea lui Mihai ' },
            { name: 'Vaşcău ', simpleName: 'Vascau ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ' },
            { name: 'Vârciorog ', simpleName: 'Varciorog ' },
            { name: 'Şimian ', simpleName: 'Simian ' },
            { name: 'Şinteu ', simpleName: 'Sinteu ' },
            { name: 'Şoimi ', simpleName: 'Soimi ' },
            { name: 'Ştei ', simpleName: 'Stei ' },
            { name: 'Şuncuiuş ', simpleName: 'Suncuius ' },
            { name: 'Ţeţchea ', simpleName: 'Tetchea ' },
            { name: 'Cohani ', village: 'Abram ' },
            { name: 'Dijir ', village: 'Abram ' },
            { name: 'Iteu ', village: 'Abram ' },
            { name: 'Iteu Nou ', village: 'Abram ' },
            { name: 'Margine ', village: 'Abram ' },
            { name: 'Satu Barbă ', simpleName: 'Satu Barba ', village: 'Abram ' },
            { name: 'Suiug ', village: 'Abram ' },
            { name: 'Crestur ', village: 'Abramut ' },
            { name: 'Făncica ', simpleName: 'Fancica ', village: 'Abramut ' },
            { name: 'Petreu ', village: 'Abramut ' },
            { name: 'Peştiş ', simpleName: 'Pestis ', village: 'Alesd ' },
            { name: 'Pădurea Neagră ', simpleName: 'Padurea Neagra ', village: 'Alesd ' },
            { name: 'Tinăud ', simpleName: 'Tinaud ', village: 'Alesd ' },
            { name: 'Chistag ', village: 'Astileu ' },
            { name: 'Călăţea ', simpleName: 'Calatea ', village: 'Astileu ' },
            { name: 'Peştere ', simpleName: 'Pestere ', village: 'Astileu ' },
            { name: 'Cacuciu Vechi ', village: 'Auseu ' },
            { name: 'Codrişoru ', simpleName: 'Codrisoru ', village: 'Auseu ' },
            { name: 'Gheghie ', village: 'Auseu ' },
            { name: 'Groşi ', simpleName: 'Grosi ', village: 'Auseu ' },
            { name: 'Luncşoara ', simpleName: 'Luncsoara ', village: 'Auseu ' },
            { name: 'Ant ', village: 'Avram Iancu ' },
            { name: 'Tămaşda ', simpleName: 'Tamasda ', village: 'Avram Iancu ' },
            { name: 'Almaşu Mare ', simpleName: 'Almasu Mare ', village: 'Balc ' },
            { name: 'Almaşu Mic ', simpleName: 'Almasu Mic ', village: 'Balc ' },
            { name: 'Ghida ', village: 'Balc ' },
            { name: 'Săldăbagiu de Barcău ', simpleName: 'Saldabagiu de Barcau ', village: 'Balc ' },
            { name: 'Arpăşel ', simpleName: 'Arpasel ', village: 'Batar ' },
            { name: 'Talpoş ', simpleName: 'Talpos ', village: 'Batar ' },
            { name: 'Tăut ', simpleName: 'Taut ', village: 'Batar ' },
            { name: 'Delani ', village: 'Beius ' },
            { name: 'Cauaceu ', village: 'Biharia ' },
            { name: 'Corboaia ', village: 'Boianu Mare ' },
            { name: 'Huta ', village: 'Boianu Mare ' },
            { name: 'Păgaia ', simpleName: 'Pagaia ', village: 'Boianu Mare ' },
            { name: 'Rugea ', village: 'Boianu Mare ' },
            { name: 'Borozel ', village: 'Borod ' },
            { name: 'Cetea ', village: 'Borod ' },
            { name: 'Corniţel ', simpleName: 'Cornitel ', village: 'Borod ' },
            { name: 'Valea Mare de Criş ', simpleName: 'Valea Mare de Cris ', village: 'Borod ' },
            { name: 'Şerani ', simpleName: 'Serani ', village: 'Borod ' },
            { name: 'Santăul Mare ', simpleName: 'Santaul Mare ', village: 'Bors ' },
            { name: 'Santăul Mic ', simpleName: 'Santaul Mic ', village: 'Bors ' },
            { name: 'Sântion ', simpleName: 'Santion ', village: 'Bors ' },
            { name: 'Beznea ', village: 'Bratca ' },
            { name: 'Damiş ', simpleName: 'Damis ', village: 'Bratca ' },
            { name: 'Lorău ', simpleName: 'Lorau ', village: 'Bratca ' },
            { name: 'Ponoară ', simpleName: 'Ponoara ', village: 'Bratca ' },
            { name: 'Valea Crişului ', simpleName: 'Valea Crisului ', village: 'Bratca ' },
            { name: 'Cuieşd ', simpleName: 'Cuiesd ', village: 'Brusturi ' },
            { name: 'Loranta ', village: 'Brusturi ' },
            { name: 'Orvişele ', simpleName: 'Orvisele ', village: 'Brusturi ' },
            { name: 'Picleu ', village: 'Brusturi ' },
            { name: 'Păuleşti ', simpleName: 'Paulesti ', village: 'Brusturi ' },
            { name: 'Varasău ', simpleName: 'Varasau ', village: 'Brusturi ' },
            { name: 'Ţigăneştii de Criş ', simpleName: 'Tiganestii de Cris ', village: 'Brusturi ' },
            { name: 'Burda ', village: 'Budureasa ' },
            { name: 'Saca ', village: 'Budureasa ' },
            { name: 'Sălişte de Beiuş ', simpleName: 'Saliste de Beius ', village: 'Budureasa ' },
            { name: 'Teleac ', village: 'Budureasa ' },
            { name: 'Albiş ', simpleName: 'Albis ', village: 'Buduslau ' },
            { name: 'Munteni ', village: 'Bulz ' },
            { name: 'Remeţi ', simpleName: 'Remeti ', village: 'Bulz ' },
            { name: 'Brădet ', simpleName: 'Bradet ', village: 'Buntesti ' },
            { name: 'Dumbrăvani ', simpleName: 'Dumbravani ', village: 'Buntesti ' },
            { name: 'Ferice ', village: 'Buntesti ' },
            { name: 'Leleşti ', simpleName: 'Lelesti ', village: 'Buntesti ' },
            { name: 'Poienii de Jos ', village: 'Buntesti ' },
            { name: 'Poienii de Sus ', village: 'Buntesti ' },
            { name: 'Stânceşti ', simpleName: 'Stancesti ', village: 'Buntesti ' },
            { name: 'Săud ', simpleName: 'Saud ', village: 'Buntesti ' },
            { name: 'Goila ', village: 'Cabesti ' },
            { name: 'Gurbeşti ', simpleName: 'Gurbesti ', village: 'Cabesti ' },
            { name: 'Josani ', village: 'Cabesti ' },
            { name: 'Sohodol ', village: 'Cabesti ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Campani ' },
            { name: 'Hârseşti ', simpleName: 'Harsesti ', village: 'Campani ' },
            { name: 'Sighiştel ', simpleName: 'Sighistel ', village: 'Campani ' },
            { name: 'Valea de Sus ', village: 'Campani ' },
            { name: 'Ginta ', village: 'Capalna ' },
            { name: 'Rohani ', village: 'Capalna ' },
            { name: 'Suplacu de Tinca ', village: 'Capalna ' },
            { name: 'Săldăbagiu Mic ', simpleName: 'Saldabagiu Mic ', village: 'Capalna ' },
            { name: 'Călugări ', simpleName: 'Calugari ', village: 'Carpinet ' },
            { name: 'Izbuc ', village: 'Carpinet ' },
            { name: 'Leheceni ', village: 'Carpinet ' },
            { name: 'Ateaş ', simpleName: 'Ateas ', village: 'Cefa ' },
            { name: 'Inand ', village: 'Cefa ' },
            { name: 'Bucium ', village: 'Ceica ' },
            { name: 'Ceişoara ', simpleName: 'Ceisoara ', village: 'Ceica ' },
            { name: 'Corbeşti ', simpleName: 'Corbesti ', village: 'Ceica ' },
            { name: 'Cotiglet ', village: 'Ceica ' },
            { name: 'Duşeşti ', simpleName: 'Dusesti ', village: 'Ceica ' },
            { name: 'Inceşti ', simpleName: 'Incesti ', village: 'Ceica ' },
            { name: 'Tăutelec ', simpleName: 'Tautelec ', village: 'Cetariu ' },
            { name: 'Şişterea ', simpleName: 'Sisterea ', village: 'Cetariu ' },
            { name: 'Şuşturogi ', simpleName: 'Susturogi ', village: 'Cetariu ' },
            { name: 'Cheşereu ', simpleName: 'Chesereu ', village: 'Cherechiu ' },
            { name: 'Târguşor ', simpleName: 'Targusor ', village: 'Cherechiu ' },
            { name: 'Chiraleu ', village: 'Chislaz ' },
            { name: 'Hăuceşti ', simpleName: 'Haucesti ', village: 'Chislaz ' },
            { name: 'Mişca ', simpleName: 'Misca ', village: 'Chislaz ' },
            { name: 'Pocluşa de Barcău ', simpleName: 'Poclusa de Barcau ', village: 'Chislaz ' },
            { name: 'Sânlazăr ', simpleName: 'Sanlazar ', village: 'Chislaz ' },
            { name: 'Sărsig ', simpleName: 'Sarsig ', village: 'Chislaz ' },
            { name: 'Cenaloş ', simpleName: 'Cenalos ', village: 'Ciuhoi ' },
            { name: 'Sfârnaş ', simpleName: 'Sfarnas ', village: 'Ciuhoi ' },
            { name: 'Sâniob ', simpleName: 'Saniob ', village: 'Ciuhoi ' },
            { name: 'Boiu ', village: 'Ciumeghiu ' },
            { name: 'Ghiorac ', village: 'Ciumeghiu ' },
            { name: 'Cheşa ', simpleName: 'Chesa ', village: 'Cociuba Mare ' },
            { name: 'Cărăsău ', simpleName: 'Carasau ', village: 'Cociuba Mare ' },
            { name: 'Petid ', village: 'Cociuba Mare ' },
            { name: 'Bucuroaia ', village: 'Copacel ' },
            { name: 'Chijic ', village: 'Copacel ' },
            { name: 'Poiana Tăşad ', simpleName: 'Poiana Tasad ', village: 'Copacel ' },
            { name: 'Surduc ', village: 'Copacel ' },
            { name: 'Sărand ', simpleName: 'Sarand ', village: 'Copacel ' },
            { name: 'Bâlc ', simpleName: 'Balc ', village: 'Cristioru De Jos ' },
            { name: 'Criştioru de Sus ', simpleName: 'Cristioru de Sus ', village: 'Cristioru De Jos ' },
            { name: 'Poiana ', village: 'Cristioru De Jos ' },
            { name: 'Sălişte de Vaşcău ', simpleName: 'Saliste de Vascau ', village: 'Cristioru De Jos ' },
            { name: 'Beiuşele ', simpleName: 'Beiusele ', village: 'Curatele ' },
            { name: 'Cresuia ', village: 'Curatele ' },
            { name: 'Nimăieşti ', simpleName: 'Nimaiesti ', village: 'Curatele ' },
            { name: 'Pociovelişte ', simpleName: 'Pocioveliste ', village: 'Curatele ' },
            { name: 'Văşad ', simpleName: 'Vasad ', village: 'Curtuiseni ' },
            { name: 'Dernişoara ', simpleName: 'Dernisoara ', village: 'Derna ' },
            { name: 'Sacalasău ', simpleName: 'Sacalasau ', village: 'Derna ' },
            { name: 'Sacalasău Nou ', simpleName: 'Sacalasau Nou ', village: 'Derna ' },
            { name: 'Tria ', village: 'Derna ' },
            { name: 'Ianca ', village: 'Diosig ' },
            { name: 'Cornişeşti ', simpleName: 'Cornisesti ', village: 'Dobresti ' },
            { name: 'Crânceşti ', simpleName: 'Crancesti ', village: 'Dobresti ' },
            { name: 'Hidişel ', simpleName: 'Hidisel ', village: 'Dobresti ' },
            { name: 'Luncasprie ', village: 'Dobresti ' },
            { name: 'Răcaş ', simpleName: 'Racas ', village: 'Dobresti ' },
            { name: 'Topa de Jos ', village: 'Dobresti ' },
            { name: 'Topa de Sus ', village: 'Dobresti ' },
            { name: 'Belejeni ', village: 'Draganesti ' },
            { name: 'Grădinari ', simpleName: 'Gradinari ', village: 'Draganesti ' },
            { name: 'Livada Beiuşului ', simpleName: 'Livada Beiusului ', village: 'Draganesti ' },
            { name: 'Mizieş ', simpleName: 'Mizies ', village: 'Draganesti ' },
            { name: 'Păntăşeşti ', simpleName: 'Pantasesti ', village: 'Draganesti ' },
            { name: 'Talpe ', village: 'Draganesti ' },
            { name: 'Şebiş ', simpleName: 'Sebis ', village: 'Draganesti ' },
            { name: 'Ţigăneştii de Beiuş ', simpleName: 'Tiganestii de Beius ', village: 'Draganesti ' },
            { name: 'Dicăneşti ', simpleName: 'Dicanesti ', village: 'Dragesti ' },
            { name: 'Stracoş ', simpleName: 'Stracos ', village: 'Dragesti ' },
            { name: 'Topeşti ', simpleName: 'Topesti ', village: 'Dragesti ' },
            { name: 'Tăşad ', simpleName: 'Tasad ', village: 'Dragesti ' },
            { name: 'Păcăleşti ', simpleName: 'Pacalesti ', village: 'Drăgănești ' },
            { name: 'Brusturi ', village: 'Finis ' },
            { name: 'Fiziş ', simpleName: 'Fizis ', village: 'Finis ' },
            { name: 'Ioaniş ', simpleName: 'Ioanis ', village: 'Finis ' },
            { name: 'Şuncuiş ', simpleName: 'Suncuis ', village: 'Finis ' },
            { name: 'Bicaci ', village: 'Gepiu ' },
            { name: 'Cheresig ', village: 'Girisu De Cris ' },
            { name: 'Toboliu ', village: 'Girisu De Cris ' },
            { name: 'Tărian ', simpleName: 'Tarian ', village: 'Girisu De Cris ' },
            { name: 'Hidişelu de Jos ', simpleName: 'Hidiselu de Jos ', village: 'Hidiselu De Sus ' },
            { name: 'Mierlău ', simpleName: 'Mierlau ', village: 'Hidiselu De Sus ' },
            { name: 'Sântelec ', simpleName: 'Santelec ', village: 'Hidiselu De Sus ' },
            { name: 'Şumugiu ', simpleName: 'Sumugiu ', village: 'Hidiselu De Sus ' },
            { name: 'Dumbrava ', village: 'Holod ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Holod ' },
            { name: 'Forosig ', village: 'Holod ' },
            { name: 'Hodiş ', simpleName: 'Hodis ', village: 'Holod ' },
            { name: 'Lupoaia ', village: 'Holod ' },
            { name: 'Valea Mare de Codru ', village: 'Holod ' },
            { name: 'Vintere ', village: 'Holod ' },
            { name: 'Fonău ', simpleName: 'Fonau ', village: 'Husasau De Tinca ' },
            { name: 'Miersig ', village: 'Husasau De Tinca ' },
            { name: 'Oşand ', simpleName: 'Osand ', village: 'Husasau De Tinca ' },
            { name: 'Sititelec ', village: 'Husasau De Tinca ' },
            { name: 'Botean ', village: 'Ineu ' },
            { name: 'Husasău de Criş ', simpleName: 'Husasau de Cris ', village: 'Ineu ' },
            { name: 'Bicăcel ', simpleName: 'Bicacel ', village: 'Lazareni ' },
            { name: 'Calea Mare ', village: 'Lazareni ' },
            { name: 'Cărăndeni ', simpleName: 'Carandeni ', village: 'Lazareni ' },
            { name: 'Cărănzel ', simpleName: 'Caranzel ', village: 'Lazareni ' },
            { name: 'Gepiş ', simpleName: 'Gepis ', village: 'Lazareni ' },
            { name: 'Gruilung ', village: 'Lazareni ' },
            { name: 'Miheleu ', village: 'Lazareni ' },
            { name: 'Băleni ', simpleName: 'Baleni ', village: 'Lazuri De Beius ' },
            { name: 'Cusuiuş ', simpleName: 'Cusuius ', village: 'Lazuri De Beius ' },
            { name: 'Hinchiriş ', simpleName: 'Hinchiris ', village: 'Lazuri De Beius ' },
            { name: 'Lugaşu de Sus ', simpleName: 'Lugasu de Sus ', village: 'Lugasu De Jos ' },
            { name: 'Urvind ', village: 'Lugasu De Jos ' },
            { name: 'Briheni ', village: 'Lunca ' },
            { name: 'Hotărel ', simpleName: 'Hotarel ', village: 'Lunca ' },
            { name: 'Seghişte ', simpleName: 'Seghiste ', village: 'Lunca ' },
            { name: 'Sârbeşti ', simpleName: 'Sarbesti ', village: 'Lunca ' },
            { name: 'Şuştiu ', simpleName: 'Sustiu ', village: 'Lunca ' },
            { name: 'Homorog ', village: 'Madaras ' },
            { name: 'Ianoşda ', simpleName: 'Ianosda ', village: 'Madaras ' },
            { name: 'Marţihaz ', simpleName: 'Martihaz ', village: 'Madaras ' },
            { name: 'Butani ', village: 'Magesti ' },
            { name: 'Cacuciu Nou ', village: 'Magesti ' },
            { name: 'Dobricioneşti ', simpleName: 'Dobricionesti ', village: 'Magesti ' },
            { name: 'Gălăşeni ', simpleName: 'Galaseni ', village: 'Magesti ' },
            { name: 'Josani ', village: 'Magesti ' },
            { name: 'Ortiteag ', village: 'Magesti ' },
            { name: 'Cheţ ', simpleName: 'Chet ', village: 'Marghita ' },
            { name: 'Ghenetea ', village: 'Marghita ' },
            { name: 'Apateu ', village: 'Nojorid ' },
            { name: 'Chişirid ', simpleName: 'Chisirid ', village: 'Nojorid ' },
            { name: 'Leş ', simpleName: 'Les ', village: 'Nojorid ' },
            { name: 'Livada de Bihor ', village: 'Nojorid ' },
            { name: 'Păuşa ', simpleName: 'Pausa ', village: 'Nojorid ' },
            { name: 'Şauaieu ', simpleName: 'Sauaieu ', village: 'Nojorid ' },
            { name: 'Băiţa ', simpleName: 'Baita ', village: 'Nucet ' },
            { name: 'Băiţa-Plai ', simpleName: 'Baita-Plai ', village: 'Nucet ' },
            { name: 'Călacea ', simpleName: 'Calacea ', village: 'Olcea ' },
            { name: 'Hodişel ', simpleName: 'Hodisel ', village: 'Olcea ' },
            { name: 'Ucuriş ', simpleName: 'Ucuris ', village: 'Olcea ' },
            { name: 'Alparea ', village: 'Osorhei ' },
            { name: 'Cheriu ', village: 'Osorhei ' },
            { name: 'Felcheriu ', village: 'Osorhei ' },
            { name: 'Fughiu ', village: 'Osorhei ' },
            { name: 'Săldăbagiu de Munte ', simpleName: 'Saldabagiu de Munte ', village: 'Paleu ' },
            { name: 'Uileacu de Munte ', village: 'Paleu ' },
            { name: 'Chişcău ', simpleName: 'Chiscau ', village: 'Pietroasa ' },
            { name: 'Cociuba Mică ', simpleName: 'Cociuba Mica ', village: 'Pietroasa ' },
            { name: 'Giuleşti ', simpleName: 'Giulesti ', village: 'Pietroasa ' },
            { name: 'Gurani ', village: 'Pietroasa ' },
            { name: 'Moţeşti ', simpleName: 'Motesti ', village: 'Pietroasa ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Pietroasa ' },
            { name: 'Feneriş ', simpleName: 'Feneris ', village: 'Pocola ' },
            { name: 'Petrani ', village: 'Pocola ' },
            { name: 'Poietari ', village: 'Pocola ' },
            { name: 'Sânmartin de Beiuş ', simpleName: 'Sanmartin de Beius ', village: 'Pocola ' },
            { name: 'Coşdeni ', simpleName: 'Cosdeni ', village: 'Pomezeu ' },
            { name: 'Câmpani de Pomezeu ', simpleName: 'Campani de Pomezeu ', village: 'Pomezeu ' },
            { name: 'Hidiş ', simpleName: 'Hidis ', village: 'Pomezeu ' },
            { name: 'Lacu Sărat ', simpleName: 'Lacu Sarat ', village: 'Pomezeu ' },
            { name: 'Sitani ', village: 'Pomezeu ' },
            { name: 'Spinuş de Pomezeu ', simpleName: 'Spinus de Pomezeu ', village: 'Pomezeu ' },
            { name: 'Vălani de Pomezeu ', simpleName: 'Valani de Pomezeu ', village: 'Pomezeu ' },
            { name: 'Bistra ', village: 'Popesti ' },
            { name: 'Budoi ', village: 'Popesti ' },
            { name: 'Cuzap ', village: 'Popesti ' },
            { name: 'Varviz ', village: 'Popesti ' },
            { name: 'Voivozi ', village: 'Popesti ' },
            { name: 'Vărzari ', simpleName: 'Varzari ', village: 'Popesti ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Rabagani ' },
            { name: 'Brăteşti ', simpleName: 'Bratesti ', village: 'Rabagani ' },
            { name: 'Salişte de Pomezeu ', simpleName: 'Saliste de Pomezeu ', village: 'Rabagani ' },
            { name: 'Săucani ', simpleName: 'Saucani ', village: 'Rabagani ' },
            { name: 'Vărăşeni ', simpleName: 'Varaseni ', village: 'Rabagani ' },
            { name: 'Drăgoteni ', simpleName: 'Dragoteni ', village: 'Remetea ' },
            { name: 'Meziad ', village: 'Remetea ' },
            { name: 'Petreasa ', village: 'Remetea ' },
            { name: 'Soimuş ', simpleName: 'Soimus ', village: 'Remetea ' },
            { name: 'Cucuceni ', village: 'Rieni ' },
            { name: 'Ghighişeni ', simpleName: 'Ghighiseni ', village: 'Rieni ' },
            { name: 'Petrileni ', village: 'Rieni ' },
            { name: 'Sudrigiu ', village: 'Rieni ' },
            { name: 'Valea de Jos ', village: 'Rieni ' },
            { name: 'Lazuri ', village: 'Rosia ' },
            { name: 'Mihai Bravu ', village: 'Rosiori ' },
            { name: 'Vaida ', village: 'Rosiori ' },
            { name: 'Borşa ', simpleName: 'Borsa ', village: 'Sacadat ' },
            { name: 'Săbolciu ', simpleName: 'Sabolciu ', village: 'Sacadat ' },
            { name: 'Cadea ', village: 'Sacueni ' },
            { name: 'Ciocaia ', village: 'Sacueni ' },
            { name: 'Cubulcut ', village: 'Sacueni ' },
            { name: 'Olosig ', village: 'Sacueni ' },
            { name: 'Sânnicolau de Munte ', simpleName: 'Sannicolau de Munte ', village: 'Sacueni ' },
            { name: 'Otomani ', village: 'Salacea ' },
            { name: 'Hodoş ', simpleName: 'Hodos ', village: 'Salard ' },
            { name: 'Sântimreu ', simpleName: 'Santimreu ', village: 'Salard ' },
            { name: 'Copăceni ', simpleName: 'Copaceni ', village: 'Sambata ' },
            { name: 'Ogeşti ', simpleName: 'Ogesti ', village: 'Sambata ' },
            { name: 'Rogoz ', village: 'Sambata ' },
            { name: 'Rotăreşti ', simpleName: 'Rotaresti ', village: 'Sambata ' },
            { name: 'Zăvoiu ', simpleName: 'Zavoiu ', village: 'Sambata ' },
            { name: 'Betfia ', village: 'Sanmartin ' },
            { name: 'Băile Felix ', simpleName: 'Baile Felix ', village: 'Sanmartin ' },
            { name: 'Cihei ', village: 'Sanmartin ' },
            { name: 'Cordău ', simpleName: 'Cordau ', village: 'Sanmartin ' },
            { name: 'Haieu ', village: 'Sanmartin ' },
            { name: 'Rontău ', simpleName: 'Rontau ', village: 'Sanmartin ' },
            { name: 'Berechiu ', village: 'Sannicolau Roman ' },
            { name: 'Roit ', village: 'Sannicolau Roman ' },
            { name: 'Palota ', village: 'Santandrei ' },
            { name: 'Almaşu Mic ', simpleName: 'Almasu Mic ', village: 'Sarbi ' },
            { name: 'Burzuc ', village: 'Sarbi ' },
            { name: 'Chioag ', village: 'Sarbi ' },
            { name: 'Fegernic ', village: 'Sarbi ' },
            { name: 'Fegernicu Nou ', village: 'Sarbi ' },
            { name: 'Sarcău ', simpleName: 'Sarcau ', village: 'Sarbi ' },
            { name: 'Voivozi ', village: 'Simian ' },
            { name: 'Şilindru ', simpleName: 'Silindru ', village: 'Simian ' },
            { name: 'Huta Voivozi ', village: 'Sinteu ' },
            { name: 'Socet ', village: 'Sinteu ' },
            { name: 'Valea Târnei ', simpleName: 'Valea Tarnei ', village: 'Sinteu ' },
            { name: 'Borz ', village: 'Soimi ' },
            { name: 'Codru ', village: 'Soimi ' },
            { name: 'Dumbrăviţa de Codru ', simpleName: 'Dumbravita de Codru ', village: 'Soimi ' },
            { name: 'Pocluşa de Beiuş ', simpleName: 'Poclusa de Beius ', village: 'Soimi ' },
            { name: 'Sânnicolau de Beiuş ', simpleName: 'Sannicolau de Beius ', village: 'Soimi ' },
            { name: 'Ursad ', village: 'Soimi ' },
            { name: 'Urviş de Beiuş ', simpleName: 'Urvis de Beius ', village: 'Soimi ' },
            { name: 'Ciuleşti ', simpleName: 'Ciulesti ', village: 'Spinus ' },
            { name: 'Gurbeşti ', simpleName: 'Gurbesti ', village: 'Spinus ' },
            { name: 'Nădar ', simpleName: 'Nadar ', village: 'Spinus ' },
            { name: 'Sălişte ', simpleName: 'Saliste ', village: 'Spinus ' },
            { name: 'Bălnaca ', simpleName: 'Balnaca ', village: 'Suncuius ' },
            { name: 'Bălnaca-Groşi ', simpleName: 'Balnaca-Grosi ', village: 'Suncuius ' },
            { name: 'Zece Hotare ', village: 'Suncuius ' },
            { name: 'Borumlaca ', village: 'Suplacu De Barcau ' },
            { name: 'Dolea ', village: 'Suplacu De Barcau ' },
            { name: 'Foglaş ', simpleName: 'Foglas ', village: 'Suplacu De Barcau ' },
            { name: 'Valea Cerului ', village: 'Suplacu De Barcau ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Suplacu De Barcau ' },
            { name: 'Niuved ', village: 'Tamaseu ' },
            { name: 'Parhida ', village: 'Tamaseu ' },
            { name: 'Satu Nou ', village: 'Tamaseu ' },
            { name: 'Mierag ', village: 'Tarcaia ' },
            { name: 'Totoreni ', village: 'Tarcaia ' },
            { name: 'Tărcăiţa ', simpleName: 'Tarcaita ', village: 'Tarcaia ' },
            { name: 'Adoni ', village: 'Tarcea ' },
            { name: 'Galoşpetreu ', simpleName: 'Galospetreu ', village: 'Tarcea ' },
            { name: 'Bogei ', village: 'Tauteu ' },
            { name: 'Chiribiş ', simpleName: 'Chiribis ', village: 'Tauteu ' },
            { name: 'Ciutelec ', village: 'Tauteu ' },
            { name: 'Poiana ', village: 'Tauteu ' },
            { name: 'Hotar ', village: 'Tetchea ' },
            { name: 'Subpiatră ', simpleName: 'Subpiatra ', village: 'Tetchea ' },
            { name: 'Telechiu ', village: 'Tetchea ' },
            { name: 'Bălaia ', simpleName: 'Balaia ', village: 'Tileagd ' },
            { name: 'Călătani ', simpleName: 'Calatani ', village: 'Tileagd ' },
            { name: 'Poşoloaca ', simpleName: 'Posoloaca ', village: 'Tileagd ' },
            { name: 'Tilecuş ', simpleName: 'Tilecus ', village: 'Tileagd ' },
            { name: 'Uileacu de Criş ', simpleName: 'Uileacu de Cris ', village: 'Tileagd ' },
            { name: 'Belfir ', village: 'Tinca ' },
            { name: 'Girişu Negru ', simpleName: 'Girisu Negru ', village: 'Tinca ' },
            { name: 'Gurbediu ', village: 'Tinca ' },
            { name: 'Râpa ', simpleName: 'Rapa ', village: 'Tinca ' },
            { name: 'Căuaşd ', simpleName: 'Cauasd ', village: 'Tulca ' },
            { name: 'Forău ', simpleName: 'Forau ', village: 'Uileacu De Beius ' },
            { name: 'Prisaca ', village: 'Uileacu De Beius ' },
            { name: 'Vălanii de Beiuş ', simpleName: 'Valanii de Beius ', village: 'Uileacu De Beius ' },
            { name: 'Birtin ', village: 'Vadu Crisului ' },
            { name: 'Tomnatic ', village: 'Vadu Crisului ' },
            { name: 'Topa de Criş ', simpleName: 'Topa de Cris ', village: 'Vadu Crisului ' },
            { name: 'Fâşca ', simpleName: 'Fasca ', village: 'Varciorog ' },
            { name: 'Surducel ', village: 'Varciorog ' },
            { name: 'Şerghiş ', simpleName: 'Serghis ', village: 'Varciorog ' },
            { name: 'Coleşti ', simpleName: 'Colesti ', village: 'Vascau ' },
            { name: 'Câmp ', simpleName: 'Camp ', village: 'Vascau ' },
            { name: 'Câmp-Moţi ', simpleName: 'Camp-Moti ', village: 'Vascau ' },
            { name: 'Vărzarii de Jos ', simpleName: 'Varzarii de Jos ', village: 'Vascau ' },
            { name: 'Vărzarii de Sus ', simpleName: 'Varzarii de Sus ', village: 'Vascau ' },
            { name: 'Izvoarele ', village: 'Viisoara ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Viisoara ' },
            {
                name: 'Reghea ', village: 'Viisoara '
            }
        ]
    },
    {
        auto: 'BN ',
        name: 'Bistriţa-Năsăud ',
        cities: [
            { name: 'Beclean ' },
            { name: 'Bistriţa ', simpleName: 'Bistrita ' },
            { name: 'Bistriţa Bârgăului ', simpleName: 'Bistrita Bargaului ' },
            { name: 'Braniştea ', simpleName: 'Branistea ' },
            { name: 'Budacu de Jos ' },
            { name: 'Budeşti ', simpleName: 'Budesti ' },
            { name: 'Chiochiş ', simpleName: 'Chiochis ' },
            { name: 'Chiuza ' },
            { name: 'Ciceu-Giurgeşti ', simpleName: 'Ciceu-Giurgesti ' },
            { name: 'Coşbuc ', simpleName: 'Cosbuc ' },
            { name: 'Căianu Mic ', simpleName: 'Caianu Mic ' },
            { name: 'Dumitra ' },
            { name: 'Dumitriţa ', simpleName: 'Dumitrita ' },
            { name: 'Feldru ' },
            { name: 'Galaţii Bistriţei ', simpleName: 'Galatii Bistritei ' },
            { name: 'Ilva Mare ' },
            { name: 'Ilva Mica ' },
            { name: 'Josenii Bârgăului ', simpleName: 'Josenii Bargaului ' },
            { name: 'Lechinţa ', simpleName: 'Lechinta ' },
            { name: 'Leşu ', simpleName: 'Lesu ' },
            { name: 'Livezile ' },
            { name: 'Lunca Ilvei ' },
            { name: 'Maieru ' },
            { name: 'Matei ' },
            { name: 'Miceştii de Câmpie ', simpleName: 'Micestii de Campie ' },
            { name: 'Milaş ', simpleName: 'Milas ' },
            { name: 'Monor ' },
            { name: 'Măgura Ilvei ', simpleName: 'Magura Ilvei ' },
            { name: 'Mărişelu ', simpleName: 'Mariselu ' },
            { name: 'Negrileşti ', simpleName: 'Negrilesti ' },
            { name: 'Nuşeni ', simpleName: 'Nuseni ' },
            { name: 'Năsăud ', simpleName: 'Nasaud ' },
            { name: 'Parva ' },
            { name: 'Poiana Ilvei ' },
            { name: 'Prundu Bârgăului ', simpleName: 'Prundu Bargaului ' },
            { name: 'Rebra ' },
            { name: 'Rebrişoara ', simpleName: 'Rebrisoara ' },
            { name: 'Rodna ' },
            { name: 'Romuli ' },
            { name: 'Salva ' },
            { name: 'Spermezeu ' },
            { name: 'Sângeorz-Băi ', simpleName: 'Sangeorz-Bai ' },
            { name: 'Teaca ' },
            { name: 'Telciu ' },
            { name: 'Tiha Bârgăului ', simpleName: 'Tiha Bargaului ' },
            { name: 'Târlişua ', simpleName: 'Tarlisua ' },
            { name: 'Uriu ' },
            { name: 'Urmeniş ', simpleName: 'Urmenis ' },
            { name: 'Zagra ' },
            { name: 'Şanţ ', simpleName: 'Sant ' },
            { name: 'Şieu ', simpleName: 'Sieu ' },
            { name: 'Şieu-Măgheruş ', simpleName: 'Sieu-Magherus ' },
            { name: 'Şieu-Odorhei ', simpleName: 'Sieu-Odorhei ' },
            { name: 'Şieuţ ', simpleName: 'Sieut ' },
            { name: 'Şintereag ', simpleName: 'Sintereag ' },
            { name: 'Coldău ', simpleName: 'Coldau ', village: 'Beclean ' },
            { name: 'Figa ', village: 'Beclean ' },
            { name: 'Rusu de Jos ', village: 'Beclean ' },
            { name: 'Ghinda ', village: 'Bistrita ' },
            { name: 'Sigmir ', village: 'Bistrita ' },
            { name: 'Slătiniţa ', simpleName: 'Slatinita ', village: 'Bistrita ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Bistrita ' },
            { name: 'Unirea ', village: 'Bistrita ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Bistrita ' },
            { name: 'Colibiţa ', simpleName: 'Colibita ', village: 'Bistrita Bargaului ' },
            { name: 'Cireşoaia ', simpleName: 'Ciresoaia ', village: 'Branistea ' },
            { name: 'Măluţ ', simpleName: 'Malut ', village: 'Branistea ' },
            { name: 'Buduş ', simpleName: 'Budus ', village: 'Budacu De Jos ' },
            { name: 'Jelna ', village: 'Budacu De Jos ' },
            { name: 'Monariu ', village: 'Budacu De Jos ' },
            { name: 'Simioneşti ', simpleName: 'Simionesti ', village: 'Budacu De Jos ' },
            { name: 'Budeşti-Fânaţe ', simpleName: 'Budesti-Fanate ', village: 'Budesti ' },
            { name: 'Ţagu ', simpleName: 'Tagu ', village: 'Budesti ' },
            { name: 'Ţăgşoru ', simpleName: 'Tagsoru ', village: 'Budesti ' },
            { name: 'Ciceu-Poieni ', village: 'Caianu Mic ' },
            { name: 'Căianu Mare ', simpleName: 'Caianu Mare ', village: 'Caianu Mic ' },
            { name: 'Dobric ', village: 'Caianu Mic ' },
            { name: 'Dobricel ', village: 'Caianu Mic ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Caianu Mic ' },
            { name: 'Orheiu Bistriţei ', simpleName: 'Orheiu Bistritei ', village: 'Cetate ' },
            { name: 'Petriş ', simpleName: 'Petris ', village: 'Cetate ' },
            { name: 'Satu Nou ', village: 'Cetate ' },
            { name: 'Apatiu ', village: 'Chiochis ' },
            { name: 'Bozieş ', simpleName: 'Bozies ', village: 'Chiochis ' },
            { name: 'Buza Cătun ', simpleName: 'Buza Catun ', village: 'Chiochis ' },
            { name: 'Cheţiu ', simpleName: 'Chetiu ', village: 'Chiochis ' },
            { name: 'Jimbor ', village: 'Chiochis ' },
            { name: 'Manic ', village: 'Chiochis ' },
            { name: 'Strugureni ', village: 'Chiochis ' },
            { name: 'Sânnicoară ', simpleName: 'Sannicoara ', village: 'Chiochis ' },
            { name: 'Ţentea ', simpleName: 'Tentea ', village: 'Chiochis ' },
            { name: 'Mireş ', simpleName: 'Mires ', village: 'Chiuza ' },
            { name: 'Piatra ', village: 'Chiuza ' },
            { name: 'Săsarm ', simpleName: 'Sasarm ', village: 'Chiuza ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ', village: 'Ciceu-Giurgesti ' },
            { name: 'Bichigiu ', village: 'Cosbuc ' },
            { name: 'Cepari ', village: 'Dumitra ' },
            { name: 'Tărpiu ', simpleName: 'Tarpiu ', village: 'Dumitra ' },
            { name: 'Budacu de Sus ', village: 'Dumitrita ' },
            { name: 'Ragla ', village: 'Dumitrita ' },
            { name: 'Nepos ', village: 'Feldru ' },
            { name: 'Albeştii Bistriţei ', simpleName: 'Albestii Bistritei ', village: 'Galatii Bistritei ' },
            { name: 'Dipşa ', simpleName: 'Dipsa ', village: 'Galatii Bistritei ' },
            { name: 'Herina ', village: 'Galatii Bistritei ' },
            { name: 'Tonciu ', village: 'Galatii Bistritei ' },
            { name: 'Ivăneasa ', simpleName: 'Ivaneasa ', village: 'Ilva Mare ' },
            { name: 'Mijlocenii Bârgăului ', simpleName: 'Mijlocenii Bargaului ', village: 'Josenii Bargaului ' },
            { name: 'Rusu Bârgăului ', simpleName: 'Rusu Bargaului ', village: 'Josenii Bargaului ' },
            { name: 'Strâmba ', simpleName: 'Stramba ', village: 'Josenii Bargaului ' },
            { name: 'Bungard ', village: 'Lechinta ' },
            { name: 'Chiraleş ', simpleName: 'Chirales ', village: 'Lechinta ' },
            { name: 'Sângeorzu Nou ', simpleName: 'Sangeorzu Nou ', village: 'Lechinta ' },
            { name: 'Sâniacob ', simpleName: 'Saniacob ', village: 'Lechinta ' },
            { name: 'Vermeş ', simpleName: 'Vermes ', village: 'Lechinta ' },
            { name: 'Ţigău ', simpleName: 'Tigau ', village: 'Lechinta ' },
            { name: 'Lunca Leşului ', simpleName: 'Lunca Lesului ', village: 'Lesu ' },
            { name: 'Cuşma ', simpleName: 'Cusma ', village: 'Livezile ' },
            { name: 'Dorolea ', village: 'Livezile ' },
            { name: 'Dumbrava ', village: 'Livezile ' },
            { name: 'Valea Poenii ', village: 'Livezile ' },
            { name: 'Arşiţa ', simpleName: 'Arsita ', village: 'Magura Ilvei ' },
            { name: 'Anieş ', simpleName: 'Anies ', village: 'Maieru ' },
            { name: 'Bârla ', simpleName: 'Barla ', village: 'Mariselu ' },
            { name: 'Domneşti ', simpleName: 'Domnesti ', village: 'Mariselu ' },
            { name: 'Jeica ', village: 'Mariselu ' },
            { name: 'Măgurele ', simpleName: 'Magurele ', village: 'Mariselu ' },
            { name: 'Neţeni ', simpleName: 'Neteni ', village: 'Mariselu ' },
            { name: 'Sântioana ', simpleName: 'Santioana ', village: 'Mariselu ' },
            { name: 'Bidiu ', village: 'Matei ' },
            { name: 'Corvineşti ', simpleName: 'Corvinesti ', village: 'Matei ' },
            { name: 'Enciu ', village: 'Matei ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Matei ' },
            { name: 'Moruţ ', simpleName: 'Morut ', village: 'Matei ' },
            { name: 'Fântâniţa ', simpleName: 'Fantanita ', village: 'Micestii De Campie ' },
            { name: 'Visuia ', village: 'Micestii De Campie ' },
            { name: 'Comlod ', village: 'Milas ' },
            { name: 'Dupa Deal ', village: 'Milas ' },
            { name: 'Ghemeş ', simpleName: 'Ghemes ', village: 'Milas ' },
            { name: 'Hirean ', village: 'Milas ' },
            { name: 'Orosfaia ', village: 'Milas ' },
            { name: 'Gledin ', village: 'Monor ' },
            { name: 'Liviu Rebreanu ', village: 'Nasaud ' },
            { name: 'Luşca ', simpleName: 'Lusca ', village: 'Nasaud ' },
            { name: 'Breaza ', village: 'Negrilesti ' },
            { name: 'Purcărete ', simpleName: 'Purcarete ', village: 'Negrilesti ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Nimigea ' },
            { name: 'Mintiu ', village: 'Nimigea ' },
            { name: 'Mititei ', village: 'Nimigea ' },
            { name: 'Mocod ', village: 'Nimigea ' },
            { name: 'Mogoşeni ', simpleName: 'Mogoseni ', village: 'Nimigea ' },
            { name: 'Nimigea de Jos ', village: 'Nimigea ' },
            { name: 'Nimigea de Sus ', village: 'Nimigea ' },
            { name: 'Tăure ', simpleName: 'Taure ', village: 'Nimigea ' },
            { name: 'Beudiu ', village: 'Nuseni ' },
            { name: 'Dumbrava ', village: 'Nuseni ' },
            { name: 'Feleac ', village: 'Nuseni ' },
            { name: 'Malin ', village: 'Nuseni ' },
            { name: 'Rusu de Sus ', village: 'Nuseni ' },
            { name: 'Viţa ', simpleName: 'Vita ', village: 'Nuseni ' },
            { name: 'Baţa ', simpleName: 'Bata ', village: 'Petru Rares ' },
            { name: 'Ciceu-Corabia ', village: 'Petru Rares ' },
            { name: 'Ciceu-Mihăieşti ', simpleName: 'Ciceu-Mihaiesti ', village: 'Petru Rares ' },
            { name: 'Leleşti ', simpleName: 'Lelesti ', village: 'Petru Rares ' },
            { name: 'Reteag ', village: 'Petru Rares ' },
            { name: 'Susenii Bârgăului ', simpleName: 'Susenii Bargaului ', village: 'Prundu Bargaului ' },
            { name: 'Gersa I ', village: 'Rebrisoara ' },
            { name: 'Gersa Ii ', village: 'Rebrisoara ' },
            { name: 'Poderei ', village: 'Rebrisoara ' },
            { name: 'Valea Vinului ', village: 'Rodna ' },
            { name: 'Dealu Ştefăniţei ', simpleName: 'Dealu Stefanitei ', village: 'Romuli ' },
            { name: 'Runcu Salvei ', village: 'Salva ' },
            { name: 'Cormaia ', village: 'Sangeorz-Bai ' },
            { name: 'Valea Borcutului ', village: 'Sangeorz-Bai ' },
            { name: 'Brăteni ', simpleName: 'Brateni ', village: 'Sanmihaiu De Cimpie ' },
            { name: 'La Curte ', village: 'Sanmihaiu De Cimpie ' },
            { name: 'Stupini ', village: 'Sanmihaiu De Cimpie ' },
            { name: 'Sânmihaiu de Câmpie ', simpleName: 'Sanmihaiu de Campie ', village: 'Sanmihaiu De Cimpie ' },
            { name: 'Sălcuţa ', simpleName: 'Salcuta ', village: 'Sanmihaiu De Cimpie ' },
            { name: 'Zoreni ', village: 'Sanmihaiu De Cimpie ' },
            { name: 'Valea Mare ', village: 'Sant ' },
            { name: 'Ardan ', village: 'Sieu ' },
            { name: 'Posmuş ', simpleName: 'Posmus ', village: 'Sieu ' },
            { name: 'Şoimuş ', simpleName: 'Soimus ', village: 'Sieu ' },
            { name: 'Arcalia ', village: 'Sieu-Magherus ' },
            { name: 'Chintelnic ', village: 'Sieu-Magherus ' },
            { name: 'Crainimăt ', simpleName: 'Crainimat ', village: 'Sieu-Magherus ' },
            { name: 'Podirei ', village: 'Sieu-Magherus ' },
            { name: 'Sărăţel ', simpleName: 'Saratel ', village: 'Sieu-Magherus ' },
            { name: 'Valea Măgheruşului ', simpleName: 'Valea Magherusului ', village: 'Sieu-Magherus ' },
            { name: 'Agrişu de Jos ', simpleName: 'Agrisu de Jos ', village: 'Sieu-Odorhei ' },
            { name: 'Agrişu de Sus ', simpleName: 'Agrisu de Sus ', village: 'Sieu-Odorhei ' },
            { name: 'Bretea ', village: 'Sieu-Odorhei ' },
            { name: 'Coasta ', village: 'Sieu-Odorhei ' },
            { name: 'Cristur-Şieu ', simpleName: 'Cristur-Sieu ', village: 'Sieu-Odorhei ' },
            { name: 'Şirioara ', simpleName: 'Sirioara ', village: 'Sieu-Odorhei ' },
            { name: 'Lunca ', village: 'Sieut ' },
            { name: 'Ruştior ', simpleName: 'Rustior ', village: 'Sieut ' },
            { name: 'Sebiş ', simpleName: 'Sebis ', village: 'Sieut ' },
            { name: 'Draga ', village: 'Silivasu De Cimpie ' },
            { name: 'Fânaţele Silivaşului ', simpleName: 'Fanatele Silivasului ', village: 'Silivasu De Cimpie ' },
            { name: 'Porumbenii ', village: 'Silivasu De Cimpie ' },
            { name: 'Silivaşu de Câmpie ', simpleName: 'Silivasu de Campie ', village: 'Silivasu De Cimpie ' },
            { name: 'Blăjenii de Jos ', simpleName: 'Blajenii de Jos ', village: 'Sintereag ' },
            { name: 'Blăjenii de Sus ', simpleName: 'Blajenii de Sus ', village: 'Sintereag ' },
            { name: 'Caila ', village: 'Sintereag ' },
            { name: 'Cociu ', village: 'Sintereag ' },
            { name: 'Şieu-Sfântu ', simpleName: 'Sieu-Sfantu ', village: 'Sintereag ' },
            { name: 'Şintereag-Gară ', simpleName: 'Sintereag-Gara ', village: 'Sintereag ' },
            { name: 'Hălmăsău ', simpleName: 'Halmasau ', village: 'Spermezeu ' },
            { name: 'Lunca Borlesei ', village: 'Spermezeu ' },
            { name: 'Păltineasa ', simpleName: 'Paltineasa ', village: 'Spermezeu ' },
            { name: 'Sita ', village: 'Spermezeu ' },
            { name: 'Şeşuri Spermezeu-Vale ', simpleName: 'Sesuri Spermezeu-Vale ', village: 'Spermezeu ' },
            { name: 'Agrieş ', simpleName: 'Agries ', village: 'Tarlisua ' },
            { name: 'Agrieşel ', simpleName: 'Agriesel ', village: 'Tarlisua ' },
            { name: 'Borleasa ', village: 'Tarlisua ' },
            { name: 'Cireaşi ', simpleName: 'Cireasi ', village: 'Tarlisua ' },
            { name: 'Lunca Săteasca ', simpleName: 'Lunca Sateasca ', village: 'Tarlisua ' },
            { name: 'Molişet ', simpleName: 'Moliset ', village: 'Tarlisua ' },
            { name: 'Oarzina ', village: 'Tarlisua ' },
            { name: 'Răcăteşu ', simpleName: 'Racatesu ', village: 'Tarlisua ' },
            { name: 'Şendroaia ', simpleName: 'Sendroaia ', village: 'Tarlisua ' },
            { name: 'Archiud ', village: 'Teaca ' },
            { name: 'Budurleni ', village: 'Teaca ' },
            { name: 'Ocniţa ', simpleName: 'Ocnita ', village: 'Teaca ' },
            { name: 'Pinticu ', village: 'Teaca ' },
            { name: 'Viile Tecii ', village: 'Teaca ' },
            { name: 'Fiad ', village: 'Telciu ' },
            { name: 'Telcişor ', simpleName: 'Telcisor ', village: 'Telciu ' },
            { name: 'Ciosa ', village: 'Tiha Bargaului ' },
            { name: 'Mureşenii Bârgăului ', simpleName: 'Muresenii Bargaului ', village: 'Tiha Bargaului ' },
            { name: 'Piatra Fântânele ', simpleName: 'Piatra Fantanele ', village: 'Tiha Bargaului ' },
            { name: 'Tureac ', village: 'Tiha Bargaului ' },
            { name: 'Cristeştii Ciceului ', simpleName: 'Cristestii Ciceului ', village: 'Uriu ' },
            { name: 'Hăşmaşu Ciceului ', simpleName: 'Hasmasu Ciceului ', village: 'Uriu ' },
            { name: 'Ilişua ', simpleName: 'Ilisua ', village: 'Uriu ' },
            { name: 'Coşeriu ', simpleName: 'Coseriu ', village: 'Urmenis ' },
            { name: 'Câmp ', simpleName: 'Camp ', village: 'Urmenis ' },
            { name: 'Delureni ', village: 'Urmenis ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Urmenis ' },
            { name: 'Podenii ', village: 'Urmenis ' },
            { name: 'Scoabe ', village: 'Urmenis ' },
            { name: 'Valea ', village: 'Urmenis ' },
            { name: 'Valea Mare ', village: 'Urmenis ' },
            { name: 'Şopteriu ', simpleName: 'Sopteriu ', village: 'Urmenis ' },
            { name: 'Alunişul ', simpleName: 'Alunisul ', village: 'Zagra ' },
            { name: 'Perişor ', simpleName: 'Perisor ', village: 'Zagra ' },
            { name: 'Poienile Zagrei ', village: 'Zagra ' },
            {
                name: 'Suplai ', village: 'Zagra '
            }
        ]
    },
    {
        auto: 'BT ',
        name: 'Botoşani ',
        cities: [
            { name: 'Albeşti ', simpleName: 'Albesti ' },
            { name: 'Avrămeni ', simpleName: 'Avrameni ' },
            { name: 'Botoşani ', simpleName: 'Botosani ' },
            { name: 'Broscăuţi ', simpleName: 'Broscauti ' },
            { name: 'Brăeşti ', simpleName: 'Braesti ' },
            { name: 'Bucecea ' },
            { name: 'Băluşeni ', simpleName: 'Baluseni ' },
            { name: 'Conceşti ', simpleName: 'Concesti ' },
            { name: 'Copălău ', simpleName: 'Copalau ' },
            { name: 'Cordăreni ', simpleName: 'Cordareni ' },
            { name: 'Corlăteni ', simpleName: 'Corlateni ' },
            { name: 'Corni ' },
            { name: 'Coşula ', simpleName: 'Cosula ' },
            { name: 'Coţuşca ', simpleName: 'Cotusca ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ' },
            { name: 'Cristineşti ', simpleName: 'Cristinesti ' },
            { name: 'Curteşti ', simpleName: 'Curtesti ' },
            { name: 'Cândeşti ', simpleName: 'Candesti ' },
            { name: 'Călăraşi ', simpleName: 'Calarasi ' },
            { name: 'Darabani ' },
            { name: 'Dersca ' },
            { name: 'Dimăcheni ', simpleName: 'Dimacheni ' },
            { name: 'Dobârceni ', simpleName: 'Dobarceni ' },
            { name: 'Dorohoi ' },
            { name: 'Drăguşeni ', simpleName: 'Draguseni ' },
            { name: 'Durneşti ', simpleName: 'Durnesti ' },
            { name: 'Dângeni ', simpleName: 'Dangeni ' },
            { name: 'Flamânzi ', simpleName: 'Flamanzi ' },
            { name: 'Frumuşica ', simpleName: 'Frumusica ' },
            { name: 'George Enescu ' },
            { name: 'Gorbăneşti ', simpleName: 'Gorbanesti ' },
            { name: 'Havârna ', simpleName: 'Havarna ' },
            { name: 'Hilişeu-Horia ', simpleName: 'Hiliseu-Horia ' },
            { name: 'Hlipiceni ' },
            { name: 'Hudeşti ', simpleName: 'Hudesti ' },
            { name: 'Hăneşti ', simpleName: 'Hanesti ' },
            { name: 'Ibăneşti ', simpleName: 'Ibanesti ' },
            { name: 'Leorda ' },
            { name: 'Lozna ' },
            { name: 'Lunca ' },
            { name: 'Manoleasa ' },
            { name: 'Mihăileni ', simpleName: 'Mihaileni ' },
            { name: 'Mihălăşeni ', simpleName: 'Mihalaseni ' },
            { name: 'Mileanca ' },
            { name: 'Mitoc ' },
            { name: 'Nicşeni ', simpleName: 'Nicseni ' },
            { name: 'Pomârla ', simpleName: 'Pomarla ' },
            { name: 'Prăjeni ', simpleName: 'Prajeni ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ' },
            { name: 'Ripiceni ' },
            { name: 'Roma ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ' },
            { name: 'Răchiţi ', simpleName: 'Rachiti ' },
            { name: 'Rădăuţi-Prut ', simpleName: 'Radauti-Prut ' },
            { name: 'Răuşeni ', simpleName: 'Rauseni ' },
            { name: 'Santa Mare ' },
            { name: 'Stăuceni ', simpleName: 'Stauceni ' },
            { name: 'Suharău ', simpleName: 'Suharau ' },
            { name: 'Suliţa ', simpleName: 'Sulita ' },
            { name: 'Săveni ', simpleName: 'Saveni ' },
            { name: 'Todireni ' },
            { name: 'Truşeşti ', simpleName: 'Trusesti ' },
            { name: 'Tudora ' },
            { name: 'Ungureni ' },
            { name: 'Unţeni ', simpleName: 'Unteni ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ' },
            { name: 'Vlădeni ', simpleName: 'Vladeni ' },
            { name: 'Vlăsineşti ', simpleName: 'Vlasinesti ' },
            { name: 'Vorniceni ' },
            { name: 'Vorona ' },
            { name: 'Vârfu Câmpului ', simpleName: 'Varfu Campului ' },
            { name: 'Văculeşti ', simpleName: 'Vaculesti ' },
            { name: 'Şendriceni ', simpleName: 'Sendriceni ' },
            { name: 'Ştefăneşti ', simpleName: 'Stefanesti ' },
            { name: 'Ştiubieni ', simpleName: 'Stiubieni ' },
            { name: 'Buimăceni ', simpleName: 'Buimaceni ', village: 'Albesti ' },
            { name: 'Coştiugeni ', simpleName: 'Costiugeni ', village: 'Albesti ' },
            { name: 'Jijia ', village: 'Albesti ' },
            { name: 'Măşcăteni ', simpleName: 'Mascateni ', village: 'Albesti ' },
            { name: 'Tudor Vladimirescu ', village: 'Albesti ' },
            { name: 'Adăşeni ', simpleName: 'Adaseni ', village: 'Avrameni ' },
            { name: 'Aurel Vlaicu ', village: 'Avrameni ' },
            { name: 'Dimitrie Cantemir ', village: 'Avrameni ' },
            { name: 'Ichimeni ', village: 'Avrameni ' },
            { name: 'Panaitoaia ', village: 'Avrameni ' },
            { name: 'Timuş ', simpleName: 'Timus ', village: 'Avrameni ' },
            { name: 'Tudor Vladimirescu ', village: 'Avrameni ' },
            { name: 'Zoiţani ', simpleName: 'Zoitani ', village: 'Avrameni ' },
            { name: 'Buzeni ', village: 'Baluseni ' },
            { name: 'Băluşenii Noi ', simpleName: 'Balusenii Noi ', village: 'Baluseni ' },
            { name: 'Coşuleni ', simpleName: 'Cosuleni ', village: 'Baluseni ' },
            { name: 'Draxini ', village: 'Baluseni ' },
            { name: 'Zăiceşti ', simpleName: 'Zaicesti ', village: 'Baluseni ' },
            { name: 'Blândeşti ', simpleName: 'Blandesti ', village: 'Blindesti ' },
            { name: 'Cerchejeni ', village: 'Blindesti ' },
            { name: 'Şoldăneşti ', simpleName: 'Soldanesti ', village: 'Blindesti ' },
            { name: 'Poiana ', village: 'Braesti ' },
            { name: 'Popeni ', village: 'Braesti ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Braesti ' },
            { name: 'Slobozia ', village: 'Broscauti ' },
            { name: 'Bohoghina ', village: 'Bucecea ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Bucecea ' },
            { name: 'Libertatea ', village: 'Calarasi ' },
            { name: 'Pleşani ', simpleName: 'Plesani ', village: 'Calarasi ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Candesti ' },
            { name: 'Talpa ', village: 'Candesti ' },
            { name: 'Viţcani ', simpleName: 'Vitcani ', village: 'Candesti ' },
            { name: 'Movileni ', village: 'Concesti ' },
            { name: 'Cerbu ', village: 'Copalau ' },
            { name: 'Cotu ', village: 'Copalau ' },
            { name: 'Griviţa ', simpleName: 'Grivita ', village: 'Cordareni ' },
            { name: 'Slobozia ', village: 'Cordăreni ' },
            { name: 'Carasa ', village: 'Corlateni ' },
            { name: 'Podeni ', village: 'Corlateni ' },
            { name: 'Vlădeni ', simpleName: 'Vladeni ', village: 'Corlateni ' },
            { name: 'Balta Arsă ', simpleName: 'Balta Arsa ', village: 'Corni ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Corni ' },
            { name: 'Sarafineşti ', simpleName: 'Sarafinesti ', village: 'Corni ' },
            { name: 'Buda ', village: 'Cosula ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Cosula ' },
            { name: 'Şupitca ', simpleName: 'Supitca ', village: 'Cosula ' },
            { name: 'Avram Iancu ', village: 'Cotusca ' },
            { name: 'Cotu Miculinţi ', simpleName: 'Cotu Miculinti ', village: 'Cotusca ' },
            { name: 'Crasnaleuca ', village: 'Cotusca ' },
            { name: 'Ghireni ', village: 'Cotusca ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ', village: 'Cotusca ' },
            { name: 'Nichiteni ', village: 'Cotusca ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Cotusca ' },
            { name: 'Puţureni ', simpleName: 'Putureni ', village: 'Cotusca ' },
            { name: 'Oneaga ', village: 'Cristesti ' },
            { name: 'Schit-Orăşeni ', simpleName: 'Schit-Oraseni ', village: 'Cristesti ' },
            { name: 'Unguroaia ', village: 'Cristesti ' },
            { name: 'Baranca ', village: 'Cristinesti ' },
            { name: 'Dragalina ', village: 'Cristinesti ' },
            { name: 'Fundu Herţii ', simpleName: 'Fundu Hertii ', village: 'Cristinesti ' },
            { name: 'Poiana ', village: 'Cristinesti ' },
            { name: 'Dămileni ', simpleName: 'Damileni ', village: 'Cristinești ' },
            { name: 'Agafton ', village: 'Curtesti ' },
            { name: 'Băiceni ', simpleName: 'Baiceni ', village: 'Curtesti ' },
            { name: 'Hudum ', village: 'Curtesti ' },
            { name: 'Mănăstirea Doamnei ', simpleName: 'Manastirea Doamnei ', village: 'Curtesti ' },
            { name: 'Orăşeni-Deal ', simpleName: 'Oraseni-Deal ', village: 'Curtesti ' },
            { name: 'Orăşeni-Vale ', simpleName: 'Oraseni-Vale ', village: 'Curtesti ' },
            { name: 'Hulub ', village: 'Dangeni ' },
            { name: 'Iacobeni ', village: 'Dangeni ' },
            { name: 'Strahotin ', village: 'Dangeni ' },
            { name: 'Bajura ', village: 'Darabani ' },
            { name: 'Eşanca ', simpleName: 'Esanca ', village: 'Darabani ' },
            { name: 'Lismăniţa ', simpleName: 'Lismanita ', village: 'Darabani ' },
            { name: 'Mateieni ', village: 'Dimacheni ' },
            { name: 'Recia-Verbia ', village: 'Dimacheni ' },
            { name: 'Bivolari ', village: 'Dobarceni ' },
            { name: 'Brăteni ', simpleName: 'Brateni ', village: 'Dobarceni ' },
            { name: 'Cişmăneşti ', simpleName: 'Cismanesti ', village: 'Dobarceni ' },
            { name: 'Livada ', village: 'Dobarceni ' },
            { name: 'Murguţa ', simpleName: 'Murguta ', village: 'Dobarceni ' },
            { name: 'Dealu Mare ', village: 'Dorohoi ' },
            { name: 'Loturi Enescu ', village: 'Dorohoi ' },
            { name: 'Progresul ', village: 'Dorohoi ' },
            { name: 'Podriga ', village: 'Draguseni ' },
            { name: 'Sarata-Drăguşeni ', simpleName: 'Sarata-Draguseni ', village: 'Draguseni ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Durnesti ' },
            { name: 'Bârsăneşti ', simpleName: 'Barsanesti ', village: 'Durnesti ' },
            { name: 'Băbiceni ', simpleName: 'Babiceni ', village: 'Durnesti ' },
            { name: 'Cucuteni ', village: 'Durnesti ' },
            { name: 'Guranda ', village: 'Durnesti ' },
            { name: 'Chiţoveni ', simpleName: 'Chitoveni ', village: 'Flamanzi ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Flamanzi ' },
            { name: 'Poiana ', village: 'Flamanzi ' },
            { name: 'Prisăcani ', simpleName: 'Prisacani ', village: 'Flamanzi ' },
            { name: 'Boscoteni ', village: 'Frumusica ' },
            { name: 'Rădeni ', simpleName: 'Radeni ', village: 'Frumusica ' },
            { name: 'Storeşti ', simpleName: 'Storesti ', village: 'Frumusica ' },
            { name: 'Vlădeni-Deal ', simpleName: 'Vladeni-Deal ', village: 'Frumusica ' },
            { name: 'Şendreni ', simpleName: 'Sendreni ', village: 'Frumusica ' },
            { name: 'Arborea ', village: 'George Enescu ' },
            { name: 'Dumeni ', village: 'George Enescu ' },
            { name: 'Popeni ', village: 'George Enescu ' },
            { name: 'Stânca ', simpleName: 'Stanca ', village: 'George Enescu ' },
            { name: 'Bătrâneşti ', simpleName: 'Batranesti ', village: 'Gorbanesti ' },
            { name: 'George Coşbuc ', simpleName: 'George Cosbuc ', village: 'Gorbanesti ' },
            { name: 'Mihai Eminescu ', village: 'Gorbanesti ' },
            { name: 'Silişcani ', simpleName: 'Siliscani ', village: 'Gorbanesti ' },
            { name: 'Socrujeni ', village: 'Gorbanesti ' },
            { name: 'Viforeni ', village: 'Gorbanesti ' },
            { name: 'Vânători ', simpleName: 'Vanatori ', village: 'Gorbanesti ' },
            { name: 'Borolea ', village: 'Hanesti ' },
            { name: 'Moara Jorii ', village: 'Hanesti ' },
            { name: 'Sarata-Basarab ', village: 'Hanesti ' },
            { name: 'Slobozia Hăneşti ', simpleName: 'Slobozia Hanesti ', village: 'Hanesti ' },
            { name: 'Balinţi ', simpleName: 'Balinti ', village: 'Havarna ' },
            { name: 'Galbeni ', village: 'Havarna ' },
            { name: 'Gârbeni ', simpleName: 'Garbeni ', village: 'Havarna ' },
            { name: 'Niculcea ', village: 'Havarna ' },
            { name: 'Tătărăşeni ', simpleName: 'Tataraseni ', village: 'Havarna ' },
            { name: 'Corjăuţi ', simpleName: 'Corjauti ', village: 'Hiliseu-Horia ' },
            { name: 'Hilişeu-Cloşca ', simpleName: 'Hiliseu-Closca ', village: 'Hiliseu-Horia ' },
            { name: 'Hilişeu-Crişan ', simpleName: 'Hiliseu-Crisan ', village: 'Hiliseu-Horia ' },
            { name: 'Iezer ', village: 'Hiliseu-Horia ' },
            { name: 'Dragalina ', village: 'Hlipiceni ' },
            { name: 'Victoria ', village: 'Hlipiceni ' },
            { name: 'Alba ', village: 'Hudesti ' },
            { name: 'Baranca ', village: 'Hudesti ' },
            { name: 'Mlenăuţi ', simpleName: 'Mlenauti ', village: 'Hudesti ' },
            { name: 'Başeu ', simpleName: 'Baseu ', village: 'Hudești ' },
            { name: 'Vatra ', village: 'Hudești ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Ibanesti ' },
            { name: 'Belcea ', village: 'Leorda ' },
            { name: 'Costineşti ', simpleName: 'Costinesti ', village: 'Leorda ' },
            { name: 'Dolina ', village: 'Leorda ' },
            { name: 'Mitoc ', village: 'Leorda ' },
            { name: 'Străteni ', simpleName: 'Strateni ', village: 'Lozna ' },
            { name: 'Stroieşti ', simpleName: 'Stroiesti ', village: 'Lunca ' },
            { name: 'Zlătunoaia ', simpleName: 'Zlatunoaia ', village: 'Lunca ' },
            { name: 'Bold ', village: 'Manoleasa ' },
            { name: 'Flondora ', village: 'Manoleasa ' },
            { name: 'Iorga ', village: 'Manoleasa ' },
            { name: 'Liveni ', village: 'Manoleasa ' },
            { name: 'Loturi ', village: 'Manoleasa ' },
            { name: 'Manoleasa-Prut ', village: 'Manoleasa ' },
            { name: 'Sadoveni ', village: 'Manoleasa ' },
            { name: 'Zahoreni ', village: 'Manoleasa ' },
            { name: 'Şerpeniţa ', simpleName: 'Serpenita ', village: 'Manoleasa ' },
            { name: 'Baisa ', village: 'Mihai Eminescu ' },
            { name: 'Cerviceşti ', simpleName: 'Cervicesti ', village: 'Mihai Eminescu ' },
            { name: 'Cerviceşti-Deal ', simpleName: 'Cervicesti-Deal ', village: 'Mihai Eminescu ' },
            { name: 'Cucorăni ', simpleName: 'Cucorani ', village: 'Mihai Eminescu ' },
            { name: 'Cătămăreşti ', simpleName: 'Catamaresti ', village: 'Mihai Eminescu ' },
            { name: 'Cătămăreşti-Deal ', simpleName: 'Catamaresti-Deal ', village: 'Mihai Eminescu ' },
            { name: 'Ipoteşti ', simpleName: 'Ipotesti ', village: 'Mihai Eminescu ' },
            { name: 'Manoleşti ', simpleName: 'Manolesti ', village: 'Mihai Eminescu ' },
            { name: 'Stânceşti ', simpleName: 'Stancesti ', village: 'Mihai Eminescu ' },
            { name: 'Pârâu Negru ', simpleName: 'Parau Negru ', village: 'Mihaileni ' },
            { name: 'Rogojeşti ', simpleName: 'Rogojesti ', village: 'Mihaileni ' },
            { name: 'Caraiman ', village: 'Mihalaseni ' },
            { name: 'Negreşti ', simpleName: 'Negresti ', village: 'Mihalaseni ' },
            { name: 'Năstase ', simpleName: 'Nastase ', village: 'Mihalaseni ' },
            { name: 'Păun ', simpleName: 'Paun ', village: 'Mihalaseni ' },
            { name: 'Sarata ', village: 'Mihalaseni ' },
            { name: 'Slobozia Silişcani ', simpleName: 'Slobozia Siliscani ', village: 'Mihalaseni ' },
            { name: 'Codreni ', village: 'Mileanca ' },
            { name: 'Scutari ', village: 'Mileanca ' },
            { name: 'Seliştea ', simpleName: 'Selistea ', village: 'Mileanca ' },
            { name: 'Horia ', village: 'Mitoc ' },
            { name: 'Dacia ', village: 'Nicseni ' },
            { name: 'Dorobanţi ', simpleName: 'Dorobanti ', village: 'Nicseni ' },
            { name: 'Cuzlău ', simpleName: 'Cuzlau ', village: 'Paltinis ' },
            { name: 'Horodiştea ', simpleName: 'Horodistea ', village: 'Paltinis ' },
            { name: 'Slobozia ', village: 'Paltinis ' },
            { name: 'Hulubeşti ', simpleName: 'Hulubesti ', village: 'Pomarla ' },
            { name: 'Racovăţ ', simpleName: 'Racovat ', village: 'Pomarla ' },
            { name: 'Câmpeni ', simpleName: 'Campeni ', village: 'Prajeni ' },
            { name: 'Lupăria ', simpleName: 'Luparia ', village: 'Prajeni ' },
            { name: 'Miletin ', village: 'Prajeni ' },
            { name: 'Cişmea ', simpleName: 'Cismea ', village: 'Rachiti ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Rachiti ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Rachiti ' },
            { name: 'Miorcani ', village: 'Radauti-Prut ' },
            { name: 'Rediu ', village: 'Radauti-Prut ' },
            { name: 'Doina ', village: 'Rauseni ' },
            { name: 'Pogorăşti ', simpleName: 'Pogorasti ', village: 'Rauseni ' },
            { name: 'Rediu ', village: 'Rauseni ' },
            { name: 'Stolniceni ', village: 'Rauseni ' },
            { name: 'Cinghiniia ', village: 'Ripiceni ' },
            { name: 'Lehneşti ', simpleName: 'Lehnesti ', village: 'Ripiceni ' },
            { name: 'Movila Ruptă ', simpleName: 'Movila Rupta ', village: 'Ripiceni ' },
            { name: 'Popoaia ', village: 'Ripiceni ' },
            { name: 'Ripicenii Vechi ', village: 'Ripiceni ' },
            { name: 'Râşca ', simpleName: 'Rasca ', village: 'Ripiceni ' },
            { name: 'Cotârgaci ', simpleName: 'Cotargaci ', village: 'Roma ' },
            { name: 'Dămideni ', simpleName: 'Damideni ', village: 'Romanesti ' },
            { name: 'Româneşti-Vale ', simpleName: 'Romanesti-Vale ', village: 'Romanesti ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Romanesti ' },
            { name: 'Berza ', village: 'Santa Mare ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Santa Mare ' },
            { name: 'Bădărăi ', simpleName: 'Badarai ', village: 'Santa Mare ' },
            { name: 'Durneşti ', simpleName: 'Durnesti ', village: 'Santa Mare ' },
            { name: 'Ilişeni ', simpleName: 'Iliseni ', village: 'Santa Mare ' },
            { name: 'Rânghileşti ', simpleName: 'Ranghilesti ', village: 'Santa Mare ' },
            { name: 'Rânghileşti-Deal ', simpleName: 'Ranghilesti-Deal ', village: 'Santa Mare ' },
            { name: 'Bodeasa ', village: 'Saveni ' },
            { name: 'Bozieni ', village: 'Saveni ' },
            { name: 'Chişcăreni ', simpleName: 'Chiscareni ', village: 'Saveni ' },
            { name: 'Petricani ', village: 'Saveni ' },
            { name: 'Sat Nou ', village: 'Saveni ' },
            { name: 'Horlăceni ', simpleName: 'Horlaceni ', village: 'Sendriceni ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Sendriceni ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Stauceni ' },
            { name: 'Tocileni ', village: 'Stauceni ' },
            { name: 'Victoria ', village: 'Stauceni ' },
            { name: 'Bobuleşti ', simpleName: 'Bobulesti ', village: 'Stefanesti ' },
            { name: 'Bădiuţi ', simpleName: 'Badiuti ', village: 'Stefanesti ' },
            { name: 'Stânca ', simpleName: 'Stanca ', village: 'Stefanesti ' },
            { name: 'Ştefăneşti-Sat ', simpleName: 'Stefanesti-Sat ', village: 'Stefanesti ' },
            { name: 'Ibăneasa ', simpleName: 'Ibaneasa ', village: 'Stiubieni ' },
            { name: 'Negreni ', village: 'Stiubieni ' },
            { name: 'Izvoare ', village: 'Suharau ' },
            { name: 'Lişna ', simpleName: 'Lisna ', village: 'Suharau ' },
            { name: 'Oroftiana ', village: 'Suharau ' },
            { name: 'Plevna ', village: 'Suharau ' },
            { name: 'Smârdan ', simpleName: 'Smardan ', village: 'Suharau ' },
            { name: 'Cheliş ', simpleName: 'Chelis ', village: 'Sulita ' },
            { name: 'Dracşani ', simpleName: 'Dracsani ', village: 'Sulita ' },
            { name: 'Cerneşti ', simpleName: 'Cernesti ', village: 'Todireni ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Todireni ' },
            { name: 'Gârbeşti ', simpleName: 'Garbesti ', village: 'Todireni ' },
            { name: 'Iureşti ', simpleName: 'Iuresti ', village: 'Todireni ' },
            { name: 'Buhăceni ', simpleName: 'Buhaceni ', village: 'Trusesti ' },
            { name: 'Ciritei ', village: 'Trusesti ' },
            { name: 'Drislea ', village: 'Trusesti ' },
            { name: 'Ionăşeni ', simpleName: 'Ionaseni ', village: 'Trusesti ' },
            { name: 'Păsăteni ', simpleName: 'Pasateni ', village: 'Trusesti ' },
            { name: 'Borzeşti ', simpleName: 'Borzesti ', village: 'Ungureni ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Ungureni ' },
            { name: 'Călugărenii Noi ', simpleName: 'Calugarenii Noi ', village: 'Ungureni ' },
            { name: 'Durneşti ', simpleName: 'Durnesti ', village: 'Ungureni ' },
            { name: 'Mihai Viteazu ', village: 'Ungureni ' },
            { name: 'Mândreşti ', simpleName: 'Mandresti ', village: 'Ungureni ' },
            { name: 'Plopenii Mari ', village: 'Ungureni ' },
            { name: 'Plopenii Mici ', village: 'Ungureni ' },
            { name: 'Tăuteşti ', simpleName: 'Tautesti ', village: 'Ungureni ' },
            { name: 'Vicoleni ', village: 'Ungureni ' },
            { name: 'Burla ', village: 'Unteni ' },
            { name: 'Burleşti ', simpleName: 'Burlesti ', village: 'Unteni ' },
            { name: 'Mânăstireni ', simpleName: 'Manastireni ', village: 'Unteni ' },
            { name: 'Soroceni ', village: 'Unteni ' },
            { name: 'Valea Grajdului ', village: 'Unteni ' },
            { name: 'Vultureni ', village: 'Unteni ' },
            { name: 'Gorovei ', village: 'Vaculesti ' },
            { name: 'Sauceniţa ', simpleName: 'Saucenita ', village: 'Vaculesti ' },
            { name: 'Ionăşeni ', simpleName: 'Ionaseni ', village: 'Varfu Campului ' },
            { name: 'Lunca ', village: 'Varfu Campului ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Viisoara ' },
            { name: 'Viişoara Mică ', simpleName: 'Viisoara Mica ', village: 'Viisoara ' },
            { name: 'Brehuieşti ', simpleName: 'Brehuiesti ', village: 'Vladeni ' },
            { name: 'Hrişcani ', simpleName: 'Hriscani ', village: 'Vladeni ' },
            { name: 'Huţani ', simpleName: 'Hutani ', village: 'Vladeni ' },
            { name: 'Mândreşti ', simpleName: 'Mandresti ', village: 'Vladeni ' },
            { name: 'Miron Costin ', village: 'Vlasinesti ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Vlasinesti ' },
            { name: 'Davidoaia ', village: 'Vorniceni ' },
            { name: 'Dealu Crucii ', village: 'Vorniceni ' },
            { name: 'Icuşeni ', simpleName: 'Icuseni ', village: 'Vorona ' },
            { name: 'Joldeşti ', simpleName: 'Joldesti ', village: 'Vorona ' },
            { name: 'Poiana ', village: 'Vorona ' },
            { name: 'Vorona Mare ', village: 'Vorona ' },
            { name: 'Vorona-Teodoru ', village: 'Vorona ' },
            { name: 'Dobrinăuţi-Hapăi ', simpleName: 'Dobrinauti-Hapai ', village: 'Vârfu Câmpului ' },
            { name: 'Maghera ', village: 'Vârfu Câmpului ' },
            {
                name: 'Pustoaia ', village: 'Vârfu Câmpului '
            }
        ]
    },
    {
        auto: 'BR ',
        name: 'Brăila ',
        cities: [
            { name: 'Berteştii de Jos ', simpleName: 'Bertestii de Jos ' },
            { name: 'Bordei Verde ' },
            { name: 'Brăila ', simpleName: 'Braila ' },
            { name: 'Bărăganul ', simpleName: 'Baraganul ' },
            { name: 'Cazasu ' },
            { name: 'Chişcani ', simpleName: 'Chiscani ' },
            { name: 'Ciocile ' },
            { name: 'Cireşu ', simpleName: 'Ciresu ' },
            { name: 'Dudeşti ', simpleName: 'Dudesti ' },
            { name: 'Frecăţei ', simpleName: 'Frecatei ' },
            { name: 'Făurei ', simpleName: 'Faurei ' },
            { name: 'Galbenu ' },
            { name: 'Gemenele ' },
            { name: 'Gradiştea ', simpleName: 'Gradistea ' },
            { name: 'Gropeni ' },
            { name: 'Ianca ' },
            { name: 'Jirlău ', simpleName: 'Jirlau ' },
            { name: 'Mircea Vodă ', simpleName: 'Mircea Voda ' },
            { name: 'Movila Miresii ' },
            { name: 'Măraşu ', simpleName: 'Marasu ' },
            { name: 'Măxineni ', simpleName: 'Maxineni ' },
            { name: 'Racoviţă ', simpleName: 'Racovita ' },
            { name: 'Romanu ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ' },
            { name: 'Râmnicelu ', simpleName: 'Ramnicelu ' },
            { name: 'Salcia Tudor ' },
            { name: 'Scorţaru Nou ', simpleName: 'Scortaru Nou ' },
            { name: 'Siliştea ', simpleName: 'Silistea ' },
            { name: 'Stăncuţa ', simpleName: 'Stancuta ' },
            { name: 'Surdila-Greci ' },
            { name: 'Surdila-Găiseanca ', simpleName: 'Surdila-Gaiseanca ' },
            { name: 'Tichileşti ', simpleName: 'Tichilesti ' },
            { name: 'Traian ' },
            { name: 'Tudor Vladimirescu ' },
            { name: 'Tufeşti ', simpleName: 'Tufesti ' },
            { name: 'Ulmu ' },
            { name: 'Unirea ' },
            { name: 'Victoria ' },
            { name: 'Viziru ' },
            { name: 'Vişani ', simpleName: 'Visani ' },
            { name: 'Vădeni ', simpleName: 'Vadeni ' },
            { name: 'Zăvoaia ', simpleName: 'Zavoaia ' },
            { name: 'Însurăţei ', simpleName: 'Insuratei ' },
            { name: 'Şuţeşti ', simpleName: 'Sutesti ' },
            { name: 'Berteştii de Sus ', simpleName: 'Bertestii de Sus ', village: 'Bertestii De Jos ' },
            { name: 'Gura Călmăţui ', simpleName: 'Gura Calmatui ', village: 'Bertestii De Jos ' },
            { name: 'Gura Gârluţei ', simpleName: 'Gura Garlutei ', village: 'Bertestii De Jos ' },
            { name: 'Nicoleşti ', simpleName: 'Nicolesti ', village: 'Bertestii De Jos ' },
            { name: 'Spiru Haret ', village: 'Bertestii De Jos ' },
            { name: 'Constantin Gabrielescu ', village: 'Bordei Verde ' },
            { name: 'Filiu ', village: 'Bordei Verde ' },
            { name: 'Lişcoteanca ', simpleName: 'Liscoteanca ', village: 'Bordei Verde ' },
            { name: 'Lacu Sărat ', simpleName: 'Lacu Sarat ', village: 'Chiscani ' },
            { name: 'Vărsătura ', simpleName: 'Varsatura ', village: 'Chiscani ' },
            { name: 'Chichineţu ', simpleName: 'Chichinetu ', village: 'Ciocile ' },
            { name: 'Chioibaşeşti ', simpleName: 'Chioibasesti ', village: 'Ciocile ' },
            { name: 'Odăieni ', simpleName: 'Odaieni ', village: 'Ciocile ' },
            { name: 'Batogu ', village: 'Ciresu ' },
            { name: 'Ioneşti ', simpleName: 'Ionesti ', village: 'Ciresu ' },
            { name: 'Scărlăteşti ', simpleName: 'Scarlatesti ', village: 'Ciresu ' },
            { name: 'Vultureni ', village: 'Ciresu ' },
            { name: 'Bumbăcari ', simpleName: 'Bumbacari ', village: 'Dudesti ' },
            { name: 'Tătaru ', simpleName: 'Tataru ', village: 'Dudesti ' },
            { name: 'Agaua ', village: 'Frecatei ' },
            { name: 'Cistia ', village: 'Frecatei ' },
            { name: 'Salcia ', village: 'Frecatei ' },
            { name: 'Stoieneşti ', simpleName: 'Stoienesti ', village: 'Frecatei ' },
            { name: 'Titcov ', village: 'Frecatei ' },
            { name: 'Drogu ', village: 'Galbenu ' },
            { name: 'Pântecani ', simpleName: 'Pantecani ', village: 'Galbenu ' },
            { name: 'Sătuc ', simpleName: 'Satuc ', village: 'Galbenu ' },
            { name: 'Zamfireşti ', simpleName: 'Zamfiresti ', village: 'Galbenu ' },
            { name: 'Găvani ', simpleName: 'Gavani ', village: 'Gemenele ' },
            { name: 'Ibrianu ', village: 'Gradistea ' },
            { name: 'Maraloiu ', village: 'Gradistea ' },
            { name: 'Berleşti ', simpleName: 'Berlesti ', village: 'Ianca ' },
            { name: 'Gara Ianca ', village: 'Ianca ' },
            { name: 'Oprişeneşti ', simpleName: 'Oprisenesti ', village: 'Ianca ' },
            { name: 'Perişoru ', simpleName: 'Perisoru ', village: 'Ianca ' },
            { name: 'Plopu ', village: 'Ianca ' },
            { name: 'Târlele Filiu ', simpleName: 'Tarlele Filiu ', village: 'Ianca ' },
            { name: 'Lacu Rezii ', village: 'Insuratei ' },
            { name: 'Măru Roşu ', simpleName: 'Maru Rosu ', village: 'Insuratei ' },
            { name: 'Valea Călmăţuiului ', simpleName: 'Valea Calmatuiului ', village: 'Insuratei ' },
            { name: 'Brădeanca ', simpleName: 'Bradeanca ', village: 'Jirlau ' },
            { name: 'Băndoiu ', simpleName: 'Bandoiu ', village: 'Marasu ' },
            { name: 'Măgureni ', simpleName: 'Magureni ', village: 'Marasu ' },
            { name: 'Nedeicu ', village: 'Marasu ' },
            { name: 'Plopi ', village: 'Marasu ' },
            { name: 'Ţăcău ', simpleName: 'Tacau ', village: 'Marasu ' },
            { name: 'Corbu Nou ', village: 'Maxineni ' },
            { name: 'Corbu Vechi ', village: 'Maxineni ' },
            { name: 'Latinu ', village: 'Maxineni ' },
            { name: 'Voineşti ', simpleName: 'Voinesti ', village: 'Maxineni ' },
            { name: 'Deduleşti ', simpleName: 'Dedulesti ', village: 'Mircea Voda ' },
            { name: 'Esna ', village: 'Movila Miresii ' },
            { name: 'Ţepeş Vodă ', simpleName: 'Tepes Voda ', village: 'Movila Miresii ' },
            { name: 'Corbeni ', village: 'Racovita ' },
            { name: 'Custura ', village: 'Racovita ' },
            { name: 'Boarca ', village: 'Ramnicelu ' },
            { name: 'Constantineşti ', simpleName: 'Constantinesti ', village: 'Ramnicelu ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ', village: 'Ramnicelu ' },
            { name: 'Oancea ', village: 'Romanu ' },
            { name: 'Colţea ', simpleName: 'Coltea ', village: 'Rosiori ' },
            { name: 'Florica ', village: 'Rosiori ' },
            { name: 'Pribeagu ', village: 'Rosiori ' },
            { name: 'Ariciu ', village: 'Salcia Tudor ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Salcia Tudor ' },
            { name: 'Gulianca ', village: 'Salcia Tudor ' },
            { name: 'Olăneasca ', simpleName: 'Olaneasca ', village: 'Salcia Tudor ' },
            { name: 'Deşiraţi ', simpleName: 'Desirati ', village: 'Scortaru Nou ' },
            { name: 'Gurguieţi ', simpleName: 'Gurguieti ', village: 'Scortaru Nou ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Scortaru Nou ' },
            { name: 'Pitulaţi ', simpleName: 'Pitulati ', village: 'Scortaru Nou ' },
            { name: 'Sihleanu ', village: 'Scortaru Nou ' },
            { name: 'Cotu Lung ', village: 'Silistea ' },
            { name: 'Cotu Mihalea ', village: 'Silistea ' },
            { name: 'Muchea ', village: 'Silistea ' },
            { name: 'Mărtăceşti ', simpleName: 'Martacesti ', village: 'Silistea ' },
            { name: 'Vameşu ', simpleName: 'Vamesu ', village: 'Silistea ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Stancuta ' },
            { name: 'Polizeşti ', simpleName: 'Polizesti ', village: 'Stancuta ' },
            { name: 'Stanca ', village: 'Stancuta ' },
            { name: 'Filipeşti ', simpleName: 'Filipesti ', village: 'Surdila-Gaiseanca ' },
            { name: 'Brateşu Vechi ', simpleName: 'Bratesu Vechi ', village: 'Surdila-Greci ' },
            { name: 'Făurei Sat ', simpleName: 'Faurei Sat ', village: 'Surdila-Greci ' },
            { name: 'Horia ', village: 'Surdila-Greci ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ', village: 'Sutesti ' },
            { name: 'Albina ', village: 'Tichilesti ' },
            { name: 'Căldăruşa ', simpleName: 'Caldarusa ', village: 'Traian ' },
            { name: 'Silistraru ', village: 'Traian ' },
            { name: 'Urleasca ', village: 'Traian ' },
            { name: 'Comăneasca ', simpleName: 'Comaneasca ', village: 'Tudor Vladimirescu ' },
            { name: 'Scorţaru Vechi ', simpleName: 'Scortaru Vechi ', village: 'Tudor Vladimirescu ' },
            { name: 'Jugureanu ', village: 'Ulmu ' },
            { name: 'Moroteşti ', simpleName: 'Morotesti ', village: 'Unirea ' },
            { name: 'Valea Cânepii ', simpleName: 'Valea Canepii ', village: 'Unirea ' },
            { name: 'Baldovineşti ', simpleName: 'Baldovinesti ', village: 'Vadeni ' },
            { name: 'Pietroiu ', village: 'Vadeni ' },
            { name: 'Mihai Bravu ', village: 'Victoria ' },
            { name: 'Câineni-Băi ', simpleName: 'Caineni-Bai ', village: 'Visani ' },
            { name: 'Plăsoiu ', simpleName: 'Plasoiu ', village: 'Visani ' },
            { name: 'Lanurile ', village: 'Viziru ' },
            {
                name: 'Dudescu ', village: 'Zavoaia '
            }
        ]
    },
    {
        auto: 'BV ',
        name: 'Braşov ',
        cities: [
            { name: 'Apaţa ', simpleName: 'Apata ' },
            { name: 'Beclean ' },
            { name: 'Bod ' },
            { name: 'Bran ' },
            { name: 'Braşov ', simpleName: 'Brasov ' },
            { name: 'Budila ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ' },
            { name: 'Caţa ', simpleName: 'Cata ' },
            { name: 'Cincu ' },
            { name: 'Codlea ' },
            { name: 'Cristian ' },
            { name: 'Crizbav ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ' },
            { name: 'Feldioara ' },
            { name: 'Fundata ' },
            { name: 'Făgăraş ', simpleName: 'Fagaras ' },
            { name: 'Ghimbav ' },
            { name: 'Hoghiz ' },
            { name: 'Homorod ' },
            { name: 'Hârseni ', simpleName: 'Harseni ' },
            { name: 'Hălchiu ', simpleName: 'Halchiu ' },
            { name: 'Hărman ', simpleName: 'Harman ' },
            { name: 'Jibert ' },
            { name: 'Lisa ' },
            { name: 'Mândra ', simpleName: 'Mandra ' },
            { name: 'Măieruş ', simpleName: 'Maierus ' },
            { name: 'Ormeniş ', simpleName: 'Ormenis ' },
            { name: 'Poiana Mărului ', simpleName: 'Poiana Marului ' },
            { name: 'Predeal ' },
            { name: 'Prejmer ' },
            { name: 'Părău ', simpleName: 'Parau ' },
            { name: 'Racoş ', simpleName: 'Racos ' },
            { name: 'Recea ' },
            { name: 'Rupea ' },
            { name: 'Râşnov ', simpleName: 'Rasnov ' },
            { name: 'Sâmbăta de Sus ', simpleName: 'Sambata de Sus ' },
            { name: 'Sânpetru ', simpleName: 'Sanpetru ' },
            { name: 'Săcele ', simpleName: 'Sacele ' },
            { name: 'Teliu ' },
            { name: 'Tărlungeni ', simpleName: 'Tarlungeni ' },
            { name: 'Ungra ' },
            { name: 'Vama Buzăului ', simpleName: 'Vama Buzaului ' },
            { name: 'Victoria ' },
            { name: 'Voila ' },
            { name: 'Vulcan ' },
            { name: 'Zărneşti ', simpleName: 'Zarnesti ' },
            { name: 'Şercaia ', simpleName: 'Sercaia ' },
            { name: 'Şinca Nouă ', simpleName: 'Sinca Noua ' },
            { name: 'Şoarş ', simpleName: 'Soars ' },
            { name: 'Boholţ ', simpleName: 'Boholt ', village: 'Beclean ' },
            { name: 'Calbor ', village: 'Beclean ' },
            { name: 'Hurez ', village: 'Beclean ' },
            { name: 'Luţa ', simpleName: 'Luta ', village: 'Beclean ' },
            { name: 'Colonia Bod ', village: 'Bod ' },
            { name: 'Predeluţ ', simpleName: 'Predelut ', village: 'Bran ' },
            { name: 'Sohodol ', village: 'Bran ' },
            { name: 'Şimon ', simpleName: 'Simon ', village: 'Bran ' },
            { name: 'Poiana Braşov ', simpleName: 'Poiana Brasov ', village: 'Brasov ' },
            { name: 'Criţ ', simpleName: 'Crit ', village: 'Bunesti ' },
            { name: 'Meşendorf ', simpleName: 'Mesendorf ', village: 'Bunesti ' },
            { name: 'Roadeş ', simpleName: 'Roades ', village: 'Bunesti ' },
            { name: 'Viscri ', village: 'Bunesti ' },
            { name: 'Beia ', village: 'Cata ' },
            { name: 'Drăuşeni ', simpleName: 'Drauseni ', village: 'Cata ' },
            { name: 'Ioneşti ', simpleName: 'Ionesti ', village: 'Cata ' },
            { name: 'Paloş ', simpleName: 'Palos ', village: 'Cata ' },
            { name: 'Toarcla ', village: 'Cincu ' },
            { name: 'Comana de Jos ', village: 'Comana ' },
            { name: 'Comana de Sus ', village: 'Comana ' },
            { name: 'Crihalma ', village: 'Comana ' },
            { name: 'Ticuşu Nou ', simpleName: 'Ticusu Nou ', village: 'Comana ' },
            { name: 'Cutuş ', simpleName: 'Cutus ', village: 'Crizbav ' },
            { name: 'Vlădeni ', simpleName: 'Vladeni ', village: 'Dumbravita ' },
            { name: 'Colonia Reconstrucţia ', simpleName: 'Colonia Reconstructia ', village: 'Feldioara ' },
            { name: 'Rotbav ', village: 'Feldioara ' },
            { name: 'Fundăţica ', simpleName: 'Fundatica ', village: 'Fundata ' },
            { name: 'Şirnea ', simpleName: 'Sirnea ', village: 'Fundata ' },
            { name: 'Satu Nou ', village: 'Halchiu ' },
            { name: 'Podu Oltului ', village: 'Harman ' },
            { name: 'Copăcel ', simpleName: 'Copacel ', village: 'Harseni ' },
            { name: 'Măliniş ', simpleName: 'Malinis ', village: 'Harseni ' },
            { name: 'Mărgineni ', simpleName: 'Margineni ', village: 'Harseni ' },
            { name: 'Sebeş ', simpleName: 'Sebes ', village: 'Harseni ' },
            { name: 'Bogata Olteană ', simpleName: 'Bogata Olteana ', village: 'Hoghiz ' },
            { name: 'Cuciulata ', village: 'Hoghiz ' },
            { name: 'Dopca ', village: 'Hoghiz ' },
            { name: 'Fântâna ', simpleName: 'Fantana ', village: 'Hoghiz ' },
            { name: 'Lupşa ', simpleName: 'Lupsa ', village: 'Hoghiz ' },
            { name: 'Jimbor ', village: 'Homorod ' },
            { name: 'Mercheaşa ', simpleName: 'Mercheasa ', village: 'Homorod ' },
            { name: 'Dacia ', village: 'Jibert ' },
            { name: 'Grânari ', simpleName: 'Granari ', village: 'Jibert ' },
            { name: 'Lovnic ', village: 'Jibert ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Jibert ' },
            { name: 'Breaza ', village: 'Lisa ' },
            { name: 'Pojorta ', village: 'Lisa ' },
            { name: 'Arini ', village: 'Maierus ' },
            { name: 'Ileni ', village: 'Mandra ' },
            { name: 'Râuşor ', simpleName: 'Rausor ', village: 'Mandra ' },
            { name: 'Toderiţa ', simpleName: 'Toderita ', village: 'Mandra ' },
            { name: 'Şona ', simpleName: 'Sona ', village: 'Mandra ' },
            { name: 'Cheia ', village: 'Moieciu ' },
            { name: 'Drumul Carului ', village: 'Moieciu ' },
            { name: 'Moieciu de Jos ', village: 'Moieciu ' },
            { name: 'Moieciu de Sus ', village: 'Moieciu ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Moieciu ' },
            { name: 'Peştera ', simpleName: 'Pestera ', village: 'Moieciu ' },
            { name: 'Augustin ', village: 'Ormenis ' },
            { name: 'Grid ', village: 'Parau ' },
            { name: 'Veneţia de Jos ', simpleName: 'Venetia de Jos ', village: 'Parau ' },
            { name: 'Veneţia de Sus ', simpleName: 'Venetia de Sus ', village: 'Parau ' },
            { name: 'Pârâul Rece ', simpleName: 'Paraul Rece ', village: 'Predeal ' },
            { name: 'Timişu de Jos ', simpleName: 'Timisu de Jos ', village: 'Predeal ' },
            { name: 'Timişu de Sus ', simpleName: 'Timisu de Sus ', village: 'Predeal ' },
            { name: 'Lunca Calnicului ', village: 'Prejmer ' },
            { name: 'Stupinii Prejmerului ', village: 'Prejmer ' },
            { name: 'Mateiaş ', simpleName: 'Mateias ', village: 'Racos ' },
            { name: 'Berivoi ', village: 'Recea ' },
            { name: 'Dejani ', village: 'Recea ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Recea ' },
            { name: 'Iaşi ', simpleName: 'Iasi ', village: 'Recea ' },
            { name: 'Săsciori ', simpleName: 'Sasciori ', village: 'Recea ' },
            { name: 'Săvăstreni ', simpleName: 'Savastreni ', village: 'Recea ' },
            { name: 'Fişer ', simpleName: 'Fiser ', village: 'Rupea ' },
            { name: 'Staţiunea Climaterică Sâmbăta ', simpleName: 'Statiunea Climaterica Sambata ', village: 'Sambata De Sus ' },
            { name: 'Hălmeag ', simpleName: 'Halmeag ', village: 'Sercaia ' },
            { name: 'Vad ', village: 'Sercaia ' },
            { name: 'Bucium ', village: 'Sinca ' },
            { name: 'Ohaba ', village: 'Sinca ' },
            { name: 'Perşani ', simpleName: 'Persani ', village: 'Sinca ' },
            { name: 'Vâlcea ', simpleName: 'Valcea ', village: 'Sinca ' },
            { name: 'Şercăiţa ', simpleName: 'Sercaita ', village: 'Sinca ' },
            { name: 'Şinca Veche ', simpleName: 'Sinca Veche ', village: 'Sinca ' },
            { name: 'Paltin ', village: 'Sinca Noua ' },
            { name: 'Bărcut ', simpleName: 'Barcut ', village: 'Soars ' },
            { name: 'Felmer ', village: 'Soars ' },
            { name: 'Rodbav ', village: 'Soars ' },
            { name: 'Seliştat ', simpleName: 'Selistat ', village: 'Soars ' },
            { name: 'Cărpiniş ', simpleName: 'Carpinis ', village: 'Tarlungeni ' },
            { name: 'Purcăreni ', simpleName: 'Purcareni ', village: 'Tarlungeni ' },
            { name: 'Zizin ', village: 'Tarlungeni ' },
            { name: 'Cobor ', village: 'Ticusu ' },
            { name: 'Ticuşu Vechi ', simpleName: 'Ticusu Vechi ', village: 'Ticusu ' },
            { name: 'Corbi ', village: 'Ucea ' },
            { name: 'Feldioara ', village: 'Ucea ' },
            { name: 'Ucea de Jos ', village: 'Ucea ' },
            { name: 'Ucea de Sus ', village: 'Ucea ' },
            { name: 'Dăişoara ', simpleName: 'Daisoara ', village: 'Ungra ' },
            { name: 'Acriş ', simpleName: 'Acris ', village: 'Vama Buzaului ' },
            { name: 'Buzăiel ', simpleName: 'Buzaiel ', village: 'Vama Buzaului ' },
            { name: 'Dălghiu ', simpleName: 'Dalghiu ', village: 'Vama Buzaului ' },
            { name: 'Drăguş ', simpleName: 'Dragus ', village: 'Vistea ' },
            { name: 'Olteţ ', simpleName: 'Oltet ', village: 'Vistea ' },
            { name: 'Rucăr ', simpleName: 'Rucar ', village: 'Vistea ' },
            { name: 'Viştea de Jos ', simpleName: 'Vistea de Jos ', village: 'Vistea ' },
            { name: 'Viştea de Sus ', simpleName: 'Vistea de Sus ', village: 'Vistea ' },
            { name: 'Viştişoara ', simpleName: 'Vistisoara ', village: 'Vistea ' },
            { name: 'Cincşor ', simpleName: 'Cincsor ', village: 'Voila ' },
            { name: 'Dridif ', village: 'Voila ' },
            { name: 'Ludişor ', simpleName: 'Ludisor ', village: 'Voila ' },
            { name: 'Sâmbăta de Jos ', simpleName: 'Sambata de Jos ', village: 'Voila ' },
            { name: 'Voivodeni ', village: 'Voila ' },
            { name: 'Colonia 1 Mai ', village: 'Vulcan ' },
            { name: 'Holbav ', village: 'Vulcan ' },
            {
                name: 'Tohanu Nou ', village: 'Zarnesti '
            }
        ]
    },
    {
        auto: 'B ',
        name: 'Bucureşti ',
        cities: [
            { name: 'Sector 1 ' },
            { name: 'Sector 2 ' },
            { name: 'Sector 3 ' },
            { name: 'Sector 4 ' },
            { name: 'Sector 5 ' },
            {
                name: 'Sector 6 '
            }
        ]
    },
    {
        auto: 'BZ ',
        name: 'Buzău ',
        cities: [
            { name: 'Amaru ' },
            { name: 'Balta Albă ', simpleName: 'Balta Alba ' },
            { name: 'Beceni ' },
            { name: 'Berca ' },
            { name: 'Bisoca ' },
            { name: 'Blăjani ', simpleName: 'Blajani ' },
            { name: 'Boldu ' },
            { name: 'Bozioru ' },
            { name: 'Breaza ' },
            { name: 'Brădeanu ', simpleName: 'Bradeanu ' },
            { name: 'Brăeşti ', simpleName: 'Braesti ' },
            { name: 'Buda ' },
            { name: 'Buzău ', simpleName: 'Buzau ' },
            { name: 'Bălăceanu ', simpleName: 'Balaceanu ' },
            { name: 'C.a. Rosetti ' },
            { name: 'Calvini ' },
            { name: 'Cernăteşti ', simpleName: 'Cernatesti ' },
            { name: 'Chiliile ' },
            { name: 'Chiojdu ' },
            { name: 'Cilibia ' },
            { name: 'Cislău ', simpleName: 'Cislau ' },
            { name: 'Cochirleanca ' },
            { name: 'Colţi ', simpleName: 'Colti ' },
            { name: 'Costeşti ', simpleName: 'Costesti ' },
            { name: 'Cozieni ' },
            { name: 'Căneşti ', simpleName: 'Canesti ' },
            { name: 'Cătina ', simpleName: 'Catina ' },
            { name: 'Ghergheasa ' },
            { name: 'Gherăseni ', simpleName: 'Gheraseni ' },
            { name: 'Glodeanu Sărat ', simpleName: 'Glodeanu Sarat ' },
            { name: 'Glodeanu-Siliştea ', simpleName: 'Glodeanu-Silistea ' },
            { name: 'Grebănu ', simpleName: 'Grebanu ' },
            { name: 'Gura Teghii ' },
            { name: 'Gălbinaşi ', simpleName: 'Galbinasi ' },
            { name: 'Largu ' },
            { name: 'Lopătari ', simpleName: 'Lopatari ' },
            { name: 'Luciu ' },
            { name: 'Merei ' },
            { name: 'Mihăileşti ', simpleName: 'Mihailesti ' },
            { name: 'Movila Banului ' },
            { name: 'Murgeşti ', simpleName: 'Murgesti ' },
            { name: 'Mânzăleşti ', simpleName: 'Manzalesti ' },
            { name: 'Măgura ', simpleName: 'Magura ' },
            { name: 'Mărgăriteşti ', simpleName: 'Margaritesti ' },
            { name: 'Mărăcineni ', simpleName: 'Maracineni ' },
            { name: 'Nehoiu ' },
            { name: 'Năeni ', simpleName: 'Naeni ' },
            { name: 'Odăile ', simpleName: 'Odaile ' },
            { name: 'Padina ' },
            { name: 'Pardoşi ', simpleName: 'Pardosi ' },
            { name: 'Pietroasele ' },
            { name: 'Podgoria ' },
            { name: 'Pogoanele ' },
            { name: 'Poşta Câlnău ', simpleName: 'Posta Calnau ' },
            { name: 'Pârscov ', simpleName: 'Parscov ' },
            { name: 'Pănătău ', simpleName: 'Panatau ' },
            { name: 'Pătârlagele ', simpleName: 'Patarlagele ' },
            { name: 'Racoviţeni ', simpleName: 'Racoviteni ' },
            { name: 'Robeasca ' },
            { name: 'Ruşeţu ', simpleName: 'Rusetu ' },
            { name: 'Râmnicelu ', simpleName: 'Ramnicelu ' },
            { name: 'Râmnicu Sărat ', simpleName: 'Ramnicu Sarat ' },
            { name: 'Scorţoasa ', simpleName: 'Scortoasa ' },
            { name: 'Scutelnici ' },
            { name: 'Smeeni ' },
            { name: 'Stâlpu ', simpleName: 'Stalpu ' },
            { name: 'Săgeata ', simpleName: 'Sageata ' },
            { name: 'Săhăteni ', simpleName: 'Sahateni ' },
            { name: 'Săpoca ', simpleName: 'Sapoca ' },
            { name: 'Săruleşti ', simpleName: 'Sarulesti ' },
            { name: 'Tisău ', simpleName: 'Tisau ' },
            { name: 'Topliceni ' },
            { name: 'Ulmeni ' },
            { name: 'Vadu Paşii ', simpleName: 'Vadu Pasii ' },
            { name: 'Valea Râmnicului ', simpleName: 'Valea Ramnicului ' },
            { name: 'Valea Salciei ' },
            { name: 'Verneşti ', simpleName: 'Vernesti ' },
            { name: 'Vintilă Vodă ', simpleName: 'Vintila Voda ' },
            { name: 'Vipereşti ', simpleName: 'Viperesti ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ' },
            { name: 'Ziduri ' },
            { name: 'Zărneşti ', simpleName: 'Zarnesti ' },
            { name: 'Ţinteşti ', simpleName: 'Tintesti ' },
            { name: 'Câmpeni ', simpleName: 'Campeni ', village: 'Amaru ' },
            { name: 'Dulbanu ', village: 'Amaru ' },
            { name: 'Lacu Sinaia ', village: 'Amaru ' },
            { name: 'Lunca ', village: 'Amaru ' },
            { name: 'Scorţeanca ', simpleName: 'Scorteanca ', village: 'Amaru ' },
            { name: 'Amara ', village: 'Balta Alba ' },
            { name: 'Băile ', simpleName: 'Baile ', village: 'Balta Alba ' },
            { name: 'Stăvărăşti ', simpleName: 'Stavarasti ', village: 'Balta Alba ' },
            { name: 'Arbănaşi ', simpleName: 'Arbanasi ', village: 'Beceni ' },
            { name: 'Cărpiniştea ', simpleName: 'Carpinistea ', village: 'Beceni ' },
            { name: 'Dogari ', village: 'Beceni ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Beceni ' },
            { name: 'Gura Dimienii ', village: 'Beceni ' },
            { name: 'Izvoru Dulce ', village: 'Beceni ' },
            { name: 'Mărgăriţi ', simpleName: 'Margariti ', village: 'Beceni ' },
            { name: 'Valea Părului ', simpleName: 'Valea Parului ', village: 'Beceni ' },
            { name: 'Băceni ', simpleName: 'Baceni ', village: 'Berca ' },
            { name: 'Cojanu ', village: 'Berca ' },
            { name: 'Joseni ', village: 'Berca ' },
            { name: 'Mânăstirea Răteşti ', simpleName: 'Manastirea Ratesti ', village: 'Berca ' },
            { name: 'Pleşcoi ', simpleName: 'Plescoi ', village: 'Berca ' },
            { name: 'Pleşeşti ', simpleName: 'Plesesti ', village: 'Berca ' },
            { name: 'Pâclele ', simpleName: 'Paclele ', village: 'Berca ' },
            { name: 'Răteşti ', simpleName: 'Ratesti ', village: 'Berca ' },
            { name: 'Sătuc ', simpleName: 'Satuc ', village: 'Berca ' },
            { name: 'Tâţârligu ', simpleName: 'Tatarligu ', village: 'Berca ' },
            { name: 'Valea Nucului ', village: 'Berca ' },
            { name: 'Viforâta ', simpleName: 'Viforata ', village: 'Berca ' },
            { name: 'Băltăgari ', simpleName: 'Baltagari ', village: 'Bisoca ' },
            { name: 'Lacurile ', village: 'Bisoca ' },
            { name: 'Lopătăreasa ', simpleName: 'Lopatareasa ', village: 'Bisoca ' },
            { name: 'Pleşi ', simpleName: 'Plesi ', village: 'Bisoca ' },
            { name: 'Recea ', village: 'Bisoca ' },
            { name: 'Sările ', simpleName: 'Sarile ', village: 'Bisoca ' },
            { name: 'Şindrila ', simpleName: 'Sindrila ', village: 'Bisoca ' },
            { name: 'Soreşti ', simpleName: 'Soresti ', village: 'Blajani ' },
            { name: 'Buduile ', village: 'Bozioru ' },
            { name: 'Fişici ', simpleName: 'Fisici ', village: 'Bozioru ' },
            { name: 'Gresia ', village: 'Bozioru ' },
            { name: 'Găvanele ', simpleName: 'Gavanele ', village: 'Bozioru ' },
            { name: 'Izvoarele ', village: 'Bozioru ' },
            { name: 'Nucu ', village: 'Bozioru ' },
            { name: 'Scăeni ', simpleName: 'Scaeni ', village: 'Bozioru ' },
            { name: 'Ulmet ', village: 'Bozioru ' },
            { name: 'Văvălucile ', simpleName: 'Vavalucile ', village: 'Bozioru ' },
            { name: 'Mitropolia ', village: 'Bradeanu ' },
            { name: 'Smârdan ', simpleName: 'Smardan ', village: 'Bradeanu ' },
            { name: 'Brătileşti ', simpleName: 'Bratilesti ', village: 'Braesti ' },
            { name: 'Goideşti ', simpleName: 'Goidesti ', village: 'Braesti ' },
            { name: 'Ivăneţu ', simpleName: 'Ivanetu ', village: 'Braesti ' },
            { name: 'Pinu ', village: 'Braesti ' },
            { name: 'Pârscovelu ', simpleName: 'Parscovelu ', village: 'Braesti ' },
            { name: 'Ruginoasa ', village: 'Braesti ' },
            { name: 'Bădeni ', simpleName: 'Badeni ', village: 'Breaza ' },
            { name: 'Greceanca ', village: 'Breaza ' },
            { name: 'Vispeşti ', simpleName: 'Vispesti ', village: 'Breaza ' },
            { name: 'Văleanca-Vilăneşti ', simpleName: 'Valeanca-Vilanesti ', village: 'Breaza ' },
            { name: 'Alexandru Odobescu ', village: 'Buda ' },
            { name: 'Dănuleşti ', simpleName: 'Danulesti ', village: 'Buda ' },
            { name: 'Muceşti-Dănuleşti ', simpleName: 'Mucesti-Danulesti ', village: 'Buda ' },
            { name: 'Spidele ', village: 'Buda ' },
            { name: 'Toropăleşti ', simpleName: 'Toropalesti ', village: 'Buda ' },
            { name: 'Valea Largă ', simpleName: 'Valea Larga ', village: 'Buda ' },
            { name: 'Bâlhacu ', simpleName: 'Balhacu ', village: 'C.A. Rosetti ' },
            { name: 'Bălteni ', simpleName: 'Balteni ', village: 'C.A. Rosetti ' },
            { name: 'Cotu Ciorii ', village: 'C.A. Rosetti ' },
            { name: 'Lunca ', village: 'C.A. Rosetti ' },
            { name: 'Vizireni ', village: 'C.A. Rosetti ' },
            { name: 'Bâscenii de Jos ', simpleName: 'Bascenii de Jos ', village: 'Calvini ' },
            { name: 'Bâscenii de Sus ', simpleName: 'Bascenii de Sus ', village: 'Calvini ' },
            { name: 'Frăsinet ', simpleName: 'Frasinet ', village: 'Calvini ' },
            { name: 'Olari ', village: 'Calvini ' },
            { name: 'Gonţeşti ', simpleName: 'Gontesti ', village: 'Canesti ' },
            { name: 'Negoşina ', simpleName: 'Negosina ', village: 'Canesti ' },
            { name: 'Păcurile ', simpleName: 'Pacurile ', village: 'Canesti ' },
            { name: 'Valea Verzei ', village: 'Canesti ' },
            { name: 'Şuchea ', simpleName: 'Suchea ', village: 'Canesti ' },
            { name: 'Corbu ', village: 'Catina ' },
            { name: 'Slobozia ', village: 'Catina ' },
            { name: 'Valea Cătinei ', simpleName: 'Valea Catinei ', village: 'Catina ' },
            { name: 'Zeletin ', village: 'Catina ' },
            { name: 'Aldeni ', village: 'Cernatesti ' },
            { name: 'Băeşti ', simpleName: 'Baesti ', village: 'Cernatesti ' },
            { name: 'Căldăruşa ', simpleName: 'Caldarusa ', village: 'Cernatesti ' },
            { name: 'Fulga ', village: 'Cernatesti ' },
            { name: 'Manasia ', village: 'Cernatesti ' },
            { name: 'Vlădeni ', simpleName: 'Vladeni ', village: 'Cernatesti ' },
            { name: 'Zărneştii de Slănic ', simpleName: 'Zarnestii de Slanic ', village: 'Cernatesti ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Chiliile ' },
            { name: 'Creveleşti ', simpleName: 'Crevelesti ', village: 'Chiliile ' },
            { name: 'Ghiocari ', village: 'Chiliile ' },
            { name: 'Glodu-Petcari ', village: 'Chiliile ' },
            { name: 'Poiana Pletari ', village: 'Chiliile ' },
            { name: 'Trestioara ', village: 'Chiliile ' },
            { name: 'Bâsca Chiojdului ', simpleName: 'Basca Chiojdului ', village: 'Chiojdu ' },
            { name: 'Cătiaşu ', simpleName: 'Catiasu ', village: 'Chiojdu ' },
            { name: 'Lera ', village: 'Chiojdu ' },
            { name: 'Plescioara ', village: 'Chiojdu ' },
            { name: 'Poeniţele ', simpleName: 'Poenitele ', village: 'Chiojdu ' },
            { name: 'Gara Cilibia ', village: 'Cilibia ' },
            { name: 'Movila Oii ', village: 'Cilibia ' },
            { name: 'Mânzu ', simpleName: 'Manzu ', village: 'Cilibia ' },
            { name: 'Poşta ', simpleName: 'Posta ', village: 'Cilibia ' },
            { name: 'Buda Crăciuneşti ', simpleName: 'Buda Craciunesti ', village: 'Cislau ' },
            { name: 'Bărăşti ', simpleName: 'Barasti ', village: 'Cislau ' },
            { name: 'Gura Bâscei ', simpleName: 'Gura Bascei ', village: 'Cislau ' },
            { name: 'Scărişoara ', simpleName: 'Scarisoara ', village: 'Cislau ' },
            { name: 'Boboc ', village: 'Cochirleanca ' },
            { name: 'Gara Bobocu ', village: 'Cochirleanca ' },
            { name: 'Roşioru ', simpleName: 'Rosioru ', village: 'Cochirleanca ' },
            { name: 'Târlele ', simpleName: 'Tarlele ', village: 'Cochirleanca ' },
            { name: 'Aluniş ', simpleName: 'Alunis ', village: 'Colti ' },
            { name: 'Colţii de Jos ', simpleName: 'Coltii de Jos ', village: 'Colti ' },
            { name: 'Muscelu Cărămăneşti ', simpleName: 'Muscelu Caramanesti ', village: 'Colti ' },
            { name: 'Budişteni ', simpleName: 'Budisteni ', village: 'Costesti ' },
            { name: 'Gomoeşti ', simpleName: 'Gomoesti ', village: 'Costesti ' },
            { name: 'Groşani ', simpleName: 'Grosani ', village: 'Costesti ' },
            { name: 'Pietrosu ', village: 'Costesti ' },
            { name: 'Spătaru ', simpleName: 'Spataru ', village: 'Costesti ' },
            { name: 'Anini ', village: 'Cozieni ' },
            { name: 'Berceşti ', simpleName: 'Bercesti ', village: 'Cozieni ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ', village: 'Cozieni ' },
            { name: 'Ciocăneşti ', simpleName: 'Ciocanesti ', village: 'Cozieni ' },
            { name: 'Cocârceni ', simpleName: 'Cocarceni ', village: 'Cozieni ' },
            { name: 'Colţeni ', simpleName: 'Colteni ', village: 'Cozieni ' },
            { name: 'Faţa lui Nan ', simpleName: 'Fata lui Nan ', village: 'Cozieni ' },
            { name: 'Glodurile ', village: 'Cozieni ' },
            { name: 'Izvoru ', village: 'Cozieni ' },
            { name: 'Lungeşti ', simpleName: 'Lungesti ', village: 'Cozieni ' },
            { name: 'Nistoreşti ', simpleName: 'Nistoresti ', village: 'Cozieni ' },
            { name: 'Pietraru ', village: 'Cozieni ' },
            { name: 'Punga ', village: 'Cozieni ' },
            { name: 'Teişu ', simpleName: 'Teisu ', village: 'Cozieni ' },
            { name: 'Trestia ', village: 'Cozieni ' },
            { name: 'Tulburea ', village: 'Cozieni ' },
            { name: 'Valea Banului ', village: 'Cozieni ' },
            { name: 'Valea Roatei ', village: 'Cozieni ' },
            { name: 'Zăpodia ', simpleName: 'Zapodia ', village: 'Cozieni ' },
            { name: 'Bentu ', village: 'Galbinasi ' },
            { name: 'Tăbărăşti ', simpleName: 'Tabarasti ', village: 'Galbinasi ' },
            { name: 'Sudiţi ', simpleName: 'Suditi ', village: 'Gheraseni ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ', village: 'Ghergheasa ' },
            { name: 'Căldăruşeanca ', simpleName: 'Caldaruseanca ', village: 'Glodeanu Sarat ' },
            { name: 'Ileana ', village: 'Glodeanu Sarat ' },
            { name: 'Pitulicea ', village: 'Glodeanu Sarat ' },
            { name: 'Casota ', village: 'Glodeanu-Silistea ' },
            { name: 'Corbu ', village: 'Glodeanu-Silistea ' },
            { name: 'Cotorca ', village: 'Glodeanu-Silistea ' },
            { name: 'Cârligu Mare ', simpleName: 'Carligu Mare ', village: 'Glodeanu-Silistea ' },
            { name: 'Cârligu Mic ', simpleName: 'Carligu Mic ', village: 'Glodeanu-Silistea ' },
            { name: 'Satu Nou ', village: 'Glodeanu-Silistea ' },
            { name: 'Văcăreasca ', simpleName: 'Vacareasca ', village: 'Glodeanu-Silistea ' },
            { name: 'Homeşti ', simpleName: 'Homesti ', village: 'Grebanu ' },
            { name: 'Livada ', village: 'Grebanu ' },
            { name: 'Livada Mică ', simpleName: 'Livada Mica ', village: 'Grebanu ' },
            { name: 'Plevna ', village: 'Grebanu ' },
            { name: 'Zăplazi ', simpleName: 'Zaplazi ', village: 'Grebanu ' },
            { name: 'Furtuneşti ', simpleName: 'Furtunesti ', village: 'Gura Teghii ' },
            { name: 'Nemertea ', village: 'Gura Teghii ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ', village: 'Gura Teghii ' },
            { name: 'Secuiu ', village: 'Gura Teghii ' },
            { name: 'Vadu Oii ', village: 'Gura Teghii ' },
            { name: 'Varlaam ', village: 'Gura Teghii ' },
            { name: 'Scărlăteşti ', simpleName: 'Scarlatesti ', village: 'Largu ' },
            { name: 'Brebu ', village: 'Lopatari ' },
            { name: 'Fundata ', village: 'Lopatari ' },
            { name: 'Luncile ', village: 'Lopatari ' },
            { name: 'Pestriţu ', simpleName: 'Pestritu ', village: 'Lopatari ' },
            { name: 'Plaiu Nucului ', village: 'Lopatari ' },
            { name: 'Ploştina ', simpleName: 'Plostina ', village: 'Lopatari ' },
            { name: 'Potecu ', village: 'Lopatari ' },
            { name: 'Săreni ', simpleName: 'Sareni ', village: 'Lopatari ' },
            { name: 'Terca ', village: 'Lopatari ' },
            { name: 'Vârteju ', simpleName: 'Varteju ', village: 'Lopatari ' },
            { name: 'Caragele ', village: 'Luciu ' },
            { name: 'Ciuta ', village: 'Magura ' },
            { name: 'Unguriu ', village: 'Magura ' },
            { name: 'Beşlii ', simpleName: 'Beslii ', village: 'Manzalesti ' },
            { name: 'Buştea ', simpleName: 'Bustea ', village: 'Manzalesti ' },
            { name: 'Cireşu ', simpleName: 'Ciresu ', village: 'Manzalesti ' },
            { name: 'Ghizdita ', village: 'Manzalesti ' },
            { name: 'Gura Bădicului ', simpleName: 'Gura Badicului ', village: 'Manzalesti ' },
            { name: 'Jghiab ', village: 'Manzalesti ' },
            { name: 'Plavăţu ', simpleName: 'Plavatu ', village: 'Manzalesti ' },
            { name: 'Poiana Vâlcului ', simpleName: 'Poiana Valcului ', village: 'Manzalesti ' },
            { name: 'Satu Vechi ', village: 'Manzalesti ' },
            { name: 'Trestioara ', village: 'Manzalesti ' },
            { name: 'Valea Cotoarei ', village: 'Manzalesti ' },
            { name: 'Valea Ursului ', village: 'Manzalesti ' },
            { name: 'Căpăţâneşti ', simpleName: 'Capatanesti ', village: 'Maracineni ' },
            { name: 'Potoceni ', village: 'Maracineni ' },
            { name: 'Câmpulungeanca ', simpleName: 'Campulungeanca ', village: 'Margaritesti ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Margaritesti ' },
            { name: 'Ciobănoaia ', simpleName: 'Ciobanoaia ', village: 'Merei ' },
            { name: 'Dealul Viei ', village: 'Merei ' },
            { name: 'Dobrileşti ', simpleName: 'Dobrilesti ', village: 'Merei ' },
            { name: 'Gura Sărăţii ', simpleName: 'Gura Saratii ', village: 'Merei ' },
            { name: 'Izvoru Dulce ', village: 'Merei ' },
            { name: 'Lipia ', village: 'Merei ' },
            { name: 'Nenciuleşti ', simpleName: 'Nenciulesti ', village: 'Merei ' },
            { name: 'Ogrăzile ', simpleName: 'Ograzile ', village: 'Merei ' },
            { name: 'Sărata-Monteoru ', simpleName: 'Sarata-Monteoru ', village: 'Merei ' },
            { name: 'Valea Puţului Merei ', simpleName: 'Valea Putului Merei ', village: 'Merei ' },
            { name: 'Colţăneni ', simpleName: 'Coltaneni ', village: 'Mihailesti ' },
            { name: 'Florica ', village: 'Mihailesti ' },
            { name: 'Mărgineanu ', simpleName: 'Margineanu ', village: 'Mihailesti ' },
            { name: 'Satu Nou ', village: 'Mihailesti ' },
            { name: 'Cioranca ', village: 'Movila Banului ' },
            { name: 'Limpeziş ', simpleName: 'Limpezis ', village: 'Movila Banului ' },
            { name: 'Batogu ', village: 'Murgesti ' },
            { name: 'Valea Ratei ', village: 'Murgesti ' },
            { name: 'Finţeşti ', simpleName: 'Fintesti ', village: 'Naeni ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Naeni ' },
            { name: 'Proşca ', simpleName: 'Prosca ', village: 'Naeni ' },
            { name: 'Vârf ', simpleName: 'Varf ', village: 'Naeni ' },
            { name: 'Bâsca Rozilei ', simpleName: 'Basca Rozilei ', village: 'Nehoiu ' },
            { name: 'Chirleşti ', simpleName: 'Chirlesti ', village: 'Nehoiu ' },
            { name: 'Curmătura ', simpleName: 'Curmatura ', village: 'Nehoiu ' },
            { name: 'Lunca Priporului ', village: 'Nehoiu ' },
            { name: 'Mlăjet ', simpleName: 'Mlajet ', village: 'Nehoiu ' },
            { name: 'Nehoiaşu ', simpleName: 'Nehoiasu ', village: 'Nehoiu ' },
            { name: 'Păltineni ', simpleName: 'Paltineni ', village: 'Nehoiu ' },
            { name: 'Stănila ', simpleName: 'Stanila ', village: 'Nehoiu ' },
            { name: 'Vineţişu ', simpleName: 'Vinetisu ', village: 'Nehoiu ' },
            { name: 'Capu Satului ', village: 'Odaile ' },
            { name: 'Corneanu ', village: 'Odaile ' },
            { name: 'Gorâni ', simpleName: 'Gorani ', village: 'Odaile ' },
            { name: 'Lacu ', village: 'Odaile ' },
            { name: 'Piatra Albă ', simpleName: 'Piatra Alba ', village: 'Odaile ' },
            { name: 'Posobeşti ', simpleName: 'Posobesti ', village: 'Odaile ' },
            { name: 'Scoroşeşti ', simpleName: 'Scorosesti ', village: 'Odaile ' },
            { name: 'Valea Fântânei ', simpleName: 'Valea Fantanei ', village: 'Odaile ' },
            { name: 'Valea Ştefanului ', simpleName: 'Valea Stefanului ', village: 'Odaile ' },
            { name: 'Tătuleşti ', simpleName: 'Tatulesti ', village: 'Padina ' },
            { name: 'Begu ', village: 'Panatau ' },
            { name: 'Lacu cu Anini ', village: 'Panatau ' },
            { name: 'Măguricea ', simpleName: 'Maguricea ', village: 'Panatau ' },
            { name: 'Plăişor ', simpleName: 'Plaisor ', village: 'Panatau ' },
            { name: 'Râpile ', simpleName: 'Rapile ', village: 'Panatau ' },
            { name: 'Sibiciu de Jos ', village: 'Panatau ' },
            { name: 'Tega ', village: 'Panatau ' },
            { name: 'Zahareşti ', simpleName: 'Zaharesti ', village: 'Panatau ' },
            { name: 'Chiperu ', village: 'Pardosi ' },
            { name: 'Costomiru ', village: 'Pardosi ' },
            { name: 'Valea lui Lalu ', village: 'Pardosi ' },
            { name: 'Valea Şchiopului ', simpleName: 'Valea Schiopului ', village: 'Pardosi ' },
            { name: 'Badila ', village: 'Parscov ' },
            { name: 'Curcăneşti ', simpleName: 'Curcanesti ', village: 'Parscov ' },
            { name: 'Lunca Frumoasă ', simpleName: 'Lunca Frumoasa ', village: 'Parscov ' },
            { name: 'Oleşeşti ', simpleName: 'Olesesti ', village: 'Parscov ' },
            { name: 'Pârjoleşti ', simpleName: 'Parjolesti ', village: 'Parscov ' },
            { name: 'Robeşti ', simpleName: 'Robesti ', village: 'Parscov ' },
            { name: 'Runcu ', village: 'Parscov ' },
            { name: 'Tocileni ', village: 'Parscov ' },
            { name: 'Trestieni ', village: 'Parscov ' },
            { name: 'Târcov ', simpleName: 'Tarcov ', village: 'Parscov ' },
            { name: 'Valea Purcarului ', village: 'Parscov ' },
            { name: 'Calea Chiojdului ', village: 'Patarlagele ' },
            { name: 'Crâng ', simpleName: 'Crang ', village: 'Patarlagele ' },
            { name: 'Fundăturile ', simpleName: 'Fundaturile ', village: 'Patarlagele ' },
            { name: 'Gornet ', village: 'Patarlagele ' },
            { name: 'Lunca ', village: 'Patarlagele ' },
            { name: 'Muşcel ', simpleName: 'Muscel ', village: 'Patarlagele ' },
            { name: 'Mănăstirea ', simpleName: 'Manastirea ', village: 'Patarlagele ' },
            { name: 'Mărunţişu ', simpleName: 'Maruntisu ', village: 'Patarlagele ' },
            { name: 'Poienile ', village: 'Patarlagele ' },
            { name: 'Sibiciu de Sus ', village: 'Patarlagele ' },
            { name: 'Stroeşti ', simpleName: 'Stroesti ', village: 'Patarlagele ' },
            { name: 'Valea Lupului ', village: 'Patarlagele ' },
            { name: 'Valea Sibiciului ', village: 'Patarlagele ' },
            { name: 'Valea Viei ', village: 'Patarlagele ' },
            { name: 'Clondiru de Sus ', village: 'Pietroasele ' },
            { name: 'Câlţeşti ', simpleName: 'Caltesti ', village: 'Pietroasele ' },
            { name: 'Dara ', village: 'Pietroasele ' },
            { name: 'Pietroasa Mică ', simpleName: 'Pietroasa Mica ', village: 'Pietroasele ' },
            { name: 'Şarânga ', simpleName: 'Saranga ', village: 'Pietroasele ' },
            { name: 'Coţatcu ', simpleName: 'Cotatcu ', village: 'Podgoria ' },
            { name: 'Oratia ', village: 'Podgoria ' },
            { name: 'Pleşeşti ', simpleName: 'Plesesti ', village: 'Podgoria ' },
            { name: 'Tăbăcari ', simpleName: 'Tabacari ', village: 'Podgoria ' },
            { name: 'Căldărăşti ', simpleName: 'Caldarasti ', village: 'Pogoanele ' },
            { name: 'Aliceni ', village: 'Posta Calnau ' },
            { name: 'Coconari ', village: 'Posta Calnau ' },
            { name: 'Potârnicheşti ', simpleName: 'Potarnichesti ', village: 'Posta Calnau ' },
            { name: 'Sudiţi ', simpleName: 'Suditi ', village: 'Posta Calnau ' },
            { name: 'Zilişteanca ', simpleName: 'Zilisteanca ', village: 'Posta Calnau ' },
            { name: 'Dăscăleşti ', simpleName: 'Dascalesti ', village: 'Puiesti ' },
            { name: 'Lunca ', village: 'Puiesti ' },
            { name: 'Măcrina ', simpleName: 'Macrina ', village: 'Puiesti ' },
            { name: 'Nicoleşti ', simpleName: 'Nicolesti ', village: 'Puiesti ' },
            { name: 'Plopi ', village: 'Puiesti ' },
            { name: 'Puieştii de Jos ', simpleName: 'Puiestii de Jos ', village: 'Puiesti ' },
            { name: 'Puieştii de Sus ', simpleName: 'Puiestii de Sus ', village: 'Puiesti ' },
            { name: 'Budrea ', village: 'Racoviteni ' },
            { name: 'Petrişoru ', simpleName: 'Petrisoru ', village: 'Racoviteni ' },
            { name: 'Colibaşi ', simpleName: 'Colibasi ', village: 'Ramnicelu ' },
            { name: 'Fotin ', village: 'Ramnicelu ' },
            { name: 'Ştiubei ', simpleName: 'Stiubei ', village: 'Ramnicelu ' },
            { name: 'Moşeşti ', simpleName: 'Mosesti ', village: 'Robeasca ' },
            { name: 'Sergent Ionel Ştefan ', simpleName: 'Sergent Ionel Stefan ', village: 'Rusetu ' },
            { name: 'Baniţa ', simpleName: 'Banita ', village: 'Sageata ' },
            { name: 'Beilic ', village: 'Sageata ' },
            { name: 'Borduşani ', simpleName: 'Bordusani ', village: 'Sageata ' },
            { name: 'Dâmbroca ', simpleName: 'Dambroca ', village: 'Sageata ' },
            { name: 'Găvăneşti ', simpleName: 'Gavanesti ', village: 'Sageata ' },
            { name: 'Moviliţa ', simpleName: 'Movilita ', village: 'Sageata ' },
            { name: 'Găgeni ', simpleName: 'Gageni ', village: 'Sahateni ' },
            { name: 'Istriţa de Jos ', simpleName: 'Istrita de Jos ', village: 'Sahateni ' },
            { name: 'Vintileanca ', village: 'Sahateni ' },
            { name: 'Măteşti ', simpleName: 'Matesti ', village: 'Sapoca ' },
            { name: 'Cărătnău de Jos ', simpleName: 'Caratnau de Jos ', village: 'Sarulesti ' },
            { name: 'Cărătnău de Sus ', simpleName: 'Caratnau de Sus ', village: 'Sarulesti ' },
            { name: 'Goicelu ', village: 'Sarulesti ' },
            { name: 'Sările-Cătun ', simpleName: 'Sarile-Catun ', village: 'Sarulesti ' },
            { name: 'Valea Largă-Săruleşti ', simpleName: 'Valea Larga-Sarulesti ', village: 'Sarulesti ' },
            { name: 'Valea Stânei ', simpleName: 'Valea Stanei ', village: 'Sarulesti ' },
            { name: 'Balta Tocila ', village: 'Scortoasa ' },
            { name: 'Beciu ', village: 'Scortoasa ' },
            { name: 'Deleni ', village: 'Scortoasa ' },
            { name: 'Dâlma ', simpleName: 'Dalma ', village: 'Scortoasa ' },
            { name: 'Golu Grabicina ', village: 'Scortoasa ' },
            { name: 'Grabicina de Jos ', village: 'Scortoasa ' },
            { name: 'Grabicina de Sus ', village: 'Scortoasa ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Scortoasa ' },
            { name: 'Plopeasa ', village: 'Scortoasa ' },
            { name: 'Policiori ', village: 'Scortoasa ' },
            { name: 'Arcanu ', village: 'Scutelnici ' },
            { name: 'Brăgăreasa ', simpleName: 'Bragareasa ', village: 'Scutelnici ' },
            { name: 'Lipănescu ', simpleName: 'Lipanescu ', village: 'Scutelnici ' },
            { name: 'Caşoca ', simpleName: 'Casoca ', village: 'Siriu ' },
            { name: 'Colţu Pietrii ', simpleName: 'Coltu Pietrii ', village: 'Siriu ' },
            { name: 'Gura Siriului ', village: 'Siriu ' },
            { name: 'Lunca Jariştei ', simpleName: 'Lunca Jaristei ', village: 'Siriu ' },
            { name: 'Muşceluşa ', simpleName: 'Muscelusa ', village: 'Siriu ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Smeeni ' },
            { name: 'Bălaia ', simpleName: 'Balaia ', village: 'Smeeni ' },
            { name: 'Călţuna ', simpleName: 'Caltuna ', village: 'Smeeni ' },
            { name: 'Moisica ', village: 'Smeeni ' },
            { name: 'Udaţi-Lucieni ', simpleName: 'Udati-Lucieni ', village: 'Smeeni ' },
            { name: 'Udaţi-Mânzu ', simpleName: 'Udati-Manzu ', village: 'Smeeni ' },
            { name: 'Maxenu ', village: 'Tintesti ' },
            { name: 'Odaia Banului ', village: 'Tintesti ' },
            { name: 'Pogonele ', village: 'Tintesti ' },
            { name: 'Bărbunceşti ', simpleName: 'Barbuncesti ', village: 'Tisau ' },
            { name: 'Grăjdana ', simpleName: 'Grajdana ', village: 'Tisau ' },
            { name: 'Haleş ', simpleName: 'Hales ', village: 'Tisau ' },
            { name: 'Izvoranu ', village: 'Tisau ' },
            { name: 'Izvoru ', village: 'Tisau ' },
            { name: 'Leiculeşti ', simpleName: 'Leiculesti ', village: 'Tisau ' },
            { name: 'Pădurenii ', simpleName: 'Padurenii ', village: 'Tisau ' },
            { name: 'Salcia ', village: 'Tisau ' },
            { name: 'Strezeni ', village: 'Tisau ' },
            { name: 'Valea Sălciilor ', simpleName: 'Valea Salciilor ', village: 'Tisau ' },
            { name: 'Băbeni ', simpleName: 'Babeni ', village: 'Topliceni ' },
            { name: 'Ceairu ', village: 'Topliceni ' },
            { name: 'Deduleşti ', simpleName: 'Dedulesti ', village: 'Topliceni ' },
            { name: 'Gura Făgetului ', simpleName: 'Gura Fagetului ', village: 'Topliceni ' },
            { name: 'Poşta ', simpleName: 'Posta ', village: 'Topliceni ' },
            { name: 'Răduceşti ', simpleName: 'Raducesti ', village: 'Topliceni ' },
            { name: 'Băltăreţi ', simpleName: 'Baltareti ', village: 'Ulmeni ' },
            { name: 'Clondiru ', village: 'Ulmeni ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Ulmeni ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Ulmeni ' },
            { name: 'Ojasca ', village: 'Unguriu ' },
            { name: 'Băjani ', simpleName: 'Bajani ', village: 'Vadu Pasii ' },
            { name: 'Focşănei ', simpleName: 'Focsanei ', village: 'Vadu Pasii ' },
            { name: 'Gura Câlnăului ', simpleName: 'Gura Calnaului ', village: 'Vadu Pasii ' },
            { name: 'Scurteşti ', simpleName: 'Scurtesti ', village: 'Vadu Pasii ' },
            { name: 'Stănceşti ', simpleName: 'Stancesti ', village: 'Vadu Pasii ' },
            { name: 'Oreavul ', village: 'Valea Ramnicului ' },
            { name: 'Rubla ', village: 'Valea Ramnicului ' },
            { name: 'Modreni ', village: 'Valea Salciei ' },
            { name: 'Valea Salciei-Cătun ', simpleName: 'Valea Salciei-Catun ', village: 'Valea Salciei ' },
            { name: 'Brădeanca ', simpleName: 'Bradeanca ', village: 'Vernesti ' },
            { name: 'Cândeşti ', simpleName: 'Candesti ', village: 'Vernesti ' },
            { name: 'Cârlomăneşti ', simpleName: 'Carlomanesti ', village: 'Vernesti ' },
            { name: 'Mierea ', village: 'Vernesti ' },
            { name: 'Nenciu ', village: 'Vernesti ' },
            { name: 'Nişcov ', simpleName: 'Niscov ', village: 'Vernesti ' },
            { name: 'Săsenii Noi ', simpleName: 'Sasenii Noi ', village: 'Vernesti ' },
            { name: 'Săsenii Vechi ', simpleName: 'Sasenii Vechi ', village: 'Vernesti ' },
            { name: 'Săsenii pe Vale ', simpleName: 'Sasenii pe Vale ', village: 'Vernesti ' },
            { name: 'Zoreşti ', simpleName: 'Zoresti ', village: 'Vernesti ' },
            { name: 'Bodineşti ', simpleName: 'Bodinesti ', village: 'Vintila Voda ' },
            { name: 'Coca-Antimireşti ', simpleName: 'Coca-Antimiresti ', village: 'Vintila Voda ' },
            { name: 'Coca-Niculeşti ', simpleName: 'Coca-Niculesti ', village: 'Vintila Voda ' },
            { name: 'Niculeşti ', simpleName: 'Niculesti ', village: 'Vintila Voda ' },
            { name: 'Petrăcheşti ', simpleName: 'Petrachesti ', village: 'Vintila Voda ' },
            { name: 'Podu Muncii ', village: 'Vintila Voda ' },
            { name: 'Smeeşti ', simpleName: 'Smeesti ', village: 'Vintila Voda ' },
            { name: 'Sârbeşti ', simpleName: 'Sarbesti ', village: 'Vintila Voda ' },
            { name: 'Muscel ', village: 'Viperesti ' },
            { name: 'Pălici ', simpleName: 'Palici ', village: 'Viperesti ' },
            { name: 'Ruşavăţ ', simpleName: 'Rusavat ', village: 'Viperesti ' },
            { name: 'Tronari ', village: 'Viperesti ' },
            { name: 'Ursoaia ', village: 'Viperesti ' },
            { name: 'Comisoaia ', village: 'Zarnesti ' },
            { name: 'Fundeni ', village: 'Zarnesti ' },
            { name: 'Pruneni ', village: 'Zarnesti ' },
            { name: 'Vadu Soreşti ', simpleName: 'Vadu Soresti ', village: 'Zarnesti ' },
            { name: 'Costieni ', village: 'Ziduri ' },
            { name: 'Cuculeasa ', village: 'Ziduri ' },
            { name: 'Heliade Rădulescu ', simpleName: 'Heliade Radulescu ', village: 'Ziduri ' },
            { name: 'Lanurile ', village: 'Ziduri ' },
            {
                name: 'Zoiţa ', simpleName: 'Zoita ', village: 'Ziduri '
            }
        ]
    },
    {
        auto: 'CL ',
        name: 'Călăraşi ',
        cities: [
            { name: 'Alexandru Odobescu ' },
            { name: 'Belciugatele ' },
            { name: 'Borcea ' },
            { name: 'Budeşti ', simpleName: 'Budesti ' },
            { name: 'Chirnogi ' },
            { name: 'Chiselet ' },
            { name: 'Ciocăneşti ', simpleName: 'Ciocanesti ' },
            { name: 'Curcani ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ' },
            { name: 'Călăraşi ', simpleName: 'Calarasi ' },
            { name: 'Căscioarele ', simpleName: 'Cascioarele ' },
            { name: 'Dichiseni ' },
            { name: 'Dor Mărunt ', simpleName: 'Dor Marunt ' },
            { name: 'Dorobanţu ', simpleName: 'Dorobantu ' },
            { name: 'Dragalina ' },
            { name: 'Dragoş Vodă ', simpleName: 'Dragos Voda ' },
            { name: 'Frumuşani ', simpleName: 'Frumusani ' },
            { name: 'Frăsinet ', simpleName: 'Frasinet ' },
            { name: 'Fundeni ' },
            { name: 'Fundulea ' },
            { name: 'Grădiştea ', simpleName: 'Gradistea ' },
            { name: 'Gurbăneşti ', simpleName: 'Gurbanesti ' },
            { name: 'Ileana ' },
            { name: 'Independenţa ', simpleName: 'Independenta ' },
            { name: 'Jegălia ', simpleName: 'Jegalia ' },
            { name: 'Lehliu ' },
            { name: 'Luica ' },
            { name: 'Lupşanu ', simpleName: 'Lupsanu ' },
            { name: 'Mitreni ' },
            { name: 'Modelu ' },
            { name: 'Mânăstirea ', simpleName: 'Manastirea ' },
            { name: 'Nana ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ' },
            { name: 'Olteniţa ', simpleName: 'Oltenita ' },
            { name: 'Perişoru ', simpleName: 'Perisoru ' },
            { name: 'Plătăreşti ', simpleName: 'Plataresti ' },
            { name: 'Radovanu ' },
            { name: 'Roseţi ', simpleName: 'Roseti ' },
            { name: 'Sohatu ' },
            { name: 'Spanţov ', simpleName: 'Spantov ' },
            { name: 'Săruleşti ', simpleName: 'Sarulesti ' },
            { name: 'Tămădău Mare ', simpleName: 'Tamadau Mare ' },
            { name: 'Ulmeni ' },
            { name: 'Ulmu ' },
            { name: 'Unirea ' },
            { name: 'Valea Argovei ' },
            { name: 'Vasilaţi ', simpleName: 'Vasilati ' },
            { name: 'Vlad Ţepeş ', simpleName: 'Vlad Tepes ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ' },
            { name: 'Şoldanu ', simpleName: 'Soldanu ' },
            { name: 'Ştefan Vodă ', simpleName: 'Stefan Voda ' },
            { name: 'Ştefan cel Mare ', simpleName: 'Stefan cel Mare ' },
            { name: 'Gălăţui ', simpleName: 'Galatui ', village: 'Alexandru Odobescu ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Alexandru Odobescu ' },
            { name: 'Cojeşti ', simpleName: 'Cojesti ', village: 'Belciugatele ' },
            { name: 'Cândeasca ', simpleName: 'Candeasca ', village: 'Belciugatele ' },
            { name: 'Mataraua ', village: 'Belciugatele ' },
            { name: 'Măriuţa ', simpleName: 'Mariuta ', village: 'Belciugatele ' },
            { name: 'Aprozi ', village: 'Budesti ' },
            { name: 'Buciumeni ', village: 'Budesti ' },
            { name: 'Crivăţ ', simpleName: 'Crivat ', village: 'Budesti ' },
            { name: 'Gruiu ', village: 'Budesti ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ', village: 'Curcani ' },
            { name: 'Ceacu ', village: 'Cuza Voda ' },
            { name: 'Călăraşii Vechi ', simpleName: 'Calarasii Vechi ', village: 'Cuza Voda ' },
            { name: 'Coslogeni ', village: 'Dichiseni ' },
            { name: 'Libertatea ', village: 'Dichiseni ' },
            { name: 'Satnoeni ', village: 'Dichiseni ' },
            { name: 'Dâlga ', simpleName: 'Dalga ', village: 'Dor Marunt ' },
            { name: 'Dâlga-Gară ', simpleName: 'Dalga-Gara ', village: 'Dor Marunt ' },
            { name: 'Ogoru ', village: 'Dor Marunt ' },
            { name: 'Pelinu ', village: 'Dor Marunt ' },
            { name: 'Înfrăţirea ', simpleName: 'Infratirea ', village: 'Dor Marunt ' },
            { name: 'Boşneagu ', simpleName: 'Bosneagu ', village: 'Dorobantu ' },
            { name: 'Vărăşti ', simpleName: 'Varasti ', village: 'Dorobantu ' },
            { name: 'Constantin Brâncoveanu ', simpleName: 'Constantin Brancoveanu ', village: 'Dragalina ' },
            { name: 'Drajna Nouă ', simpleName: 'Drajna Noua ', village: 'Dragalina ' },
            { name: 'Bogdana ', village: 'Dragos Voda ' },
            { name: 'Socoalele ', village: 'Dragos Voda ' },
            { name: 'Curăteşti ', simpleName: 'Curatesti ', village: 'Frasinet ' },
            { name: 'Dăneşti ', simpleName: 'Danesti ', village: 'Frasinet ' },
            { name: 'Frăsinetu de Jos ', simpleName: 'Frasinetu de Jos ', village: 'Frasinet ' },
            { name: 'Luptători ', simpleName: 'Luptatori ', village: 'Frasinet ' },
            { name: 'Tăriceni ', simpleName: 'Tariceni ', village: 'Frasinet ' },
            { name: 'Orăşti ', simpleName: 'Orasti ', village: 'Frumusani ' },
            { name: 'Pasărea ', simpleName: 'Pasarea ', village: 'Frumusani ' },
            { name: 'Piţigaia ', simpleName: 'Pitigaia ', village: 'Frumusani ' },
            { name: 'Postăvari ', simpleName: 'Postavari ', village: 'Frumusani ' },
            { name: 'Pădurişu ', simpleName: 'Padurisu ', village: 'Frumusani ' },
            { name: 'Alexandru I. Cuza ', village: 'Fundulea ' },
            { name: 'Gostilele ', village: 'Fundulea ' },
            { name: 'Bogata ', village: 'Gradistea ' },
            { name: 'Cuneşti ', simpleName: 'Cunesti ', village: 'Gradistea ' },
            { name: 'Rasa ', village: 'Gradistea ' },
            { name: 'Codreni ', village: 'Gurbanesti ' },
            { name: 'Coţofanca ', simpleName: 'Cotofanca ', village: 'Gurbanesti ' },
            { name: 'Preasna ', village: 'Gurbanesti ' },
            { name: 'Preasna Veche ', village: 'Gurbanesti ' },
            { name: 'Valea Presnei ', village: 'Gurbanesti ' },
            { name: 'Arţari ', simpleName: 'Artari ', village: 'Ileana ' },
            { name: 'Florica ', village: 'Ileana ' },
            { name: 'Podari ', village: 'Ileana ' },
            { name: 'Răsurile ', simpleName: 'Rasurile ', village: 'Ileana ' },
            { name: 'Răzoarele ', simpleName: 'Razoarele ', village: 'Ileana ' },
            { name: 'Satu Nou ', village: 'Ileana ' },
            { name: 'Vlăiculeşti ', simpleName: 'Vlaiculesti ', village: 'Ileana ' },
            { name: 'Ştefăneşti ', simpleName: 'Stefanesti ', village: 'Ileana ' },
            { name: 'Potcoava ', village: 'Independenta ' },
            { name: 'Vişinii ', simpleName: 'Visinii ', village: 'Independenta ' },
            { name: 'Gâldău ', simpleName: 'Galdau ', village: 'Jegalia ' },
            { name: 'Iezeru ', village: 'Jegalia ' },
            { name: 'Săpunari ', simpleName: 'Sapunari ', village: 'Lehliu ' },
            { name: 'Buzoeni ', village: 'Lehliu Gara ' },
            { name: 'Lehliu-Gară ', simpleName: 'Lehliu-Gara ', village: 'Lehliu Gara ' },
            { name: 'Răzvani ', simpleName: 'Razvani ', village: 'Lehliu Gara ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Lehliu Gara ' },
            { name: 'Valea Stânii ', simpleName: 'Valea Stanii ', village: 'Luica ' },
            { name: 'Nucetu ', village: 'Lupsanu ' },
            { name: 'Plevna ', village: 'Lupsanu ' },
            { name: 'Radu Vodă ', simpleName: 'Radu Voda ', village: 'Lupsanu ' },
            { name: 'Valea Rusului ', village: 'Lupsanu ' },
            { name: 'Coconi ', village: 'Manastirea ' },
            { name: 'Sultana ', village: 'Manastirea ' },
            { name: 'Clăteşti ', simpleName: 'Clatesti ', village: 'Mitreni ' },
            { name: 'Valea Roşie ', simpleName: 'Valea Rosie ', village: 'Mitreni ' },
            { name: 'Radu Negru ', village: 'Modelu ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ', village: 'Modelu ' },
            { name: 'Tonea ', village: 'Modelu ' },
            { name: 'Fântâna Doamnei ', simpleName: 'Fantana Doamnei ', village: 'Nicolae Balcescu ' },
            { name: 'Paicu ', village: 'Nicolae Balcescu ' },
            { name: 'Mărculeşti-Gară ', simpleName: 'Marculesti-Gara ', village: 'Perisoru ' },
            { name: 'Tudor Vladimirescu ', village: 'Perisoru ' },
            { name: 'Cucuieţi ', simpleName: 'Cucuieti ', village: 'Plataresti ' },
            { name: 'Dorobanţu ', simpleName: 'Dorobantu ', village: 'Plataresti ' },
            { name: 'Podu Pitarului ', village: 'Plataresti ' },
            { name: 'Valea Popii ', village: 'Radovanu ' },
            { name: 'Măgureni ', simpleName: 'Magureni ', village: 'Sarulesti ' },
            { name: 'Polceşti ', simpleName: 'Polcesti ', village: 'Sarulesti ' },
            { name: 'Solacolu ', village: 'Sarulesti ' },
            { name: 'Sănduliţa ', simpleName: 'Sandulita ', village: 'Sarulesti ' },
            { name: 'Săruleşti-Gară ', simpleName: 'Sarulesti-Gara ', village: 'Sarulesti ' },
            { name: 'Sătucu ', simpleName: 'Satucu ', village: 'Sarulesti ' },
            { name: 'Progresu ', village: 'Sohatu ' },
            { name: 'Negoeşti ', simpleName: 'Negoesti ', village: 'Soldanu ' },
            { name: 'Cetatea Veche ', village: 'Spantov ' },
            { name: 'Stancea ', village: 'Spantov ' },
            { name: 'Călăreţi ', simpleName: 'Calareti ', village: 'Tamadau Mare ' },
            { name: 'Dârvari ', simpleName: 'Darvari ', village: 'Tamadau Mare ' },
            { name: 'Plumbuita ', village: 'Tamadau Mare ' },
            { name: 'Săcele ', simpleName: 'Sacele ', village: 'Tamadau Mare ' },
            { name: 'Tămădău Mic ', simpleName: 'Tamadau Mic ', village: 'Tamadau Mare ' },
            { name: 'Şeinoiu ', simpleName: 'Seinoiu ', village: 'Tamadau Mare ' },
            { name: 'Chirnogi ', village: 'Ulmu ' },
            { name: 'Făurei ', simpleName: 'Faurei ', village: 'Ulmu ' },
            { name: 'Zimbru ', village: 'Ulmu ' },
            { name: 'Oltina ', village: 'Unirea ' },
            { name: 'Floroaica ', village: 'Valcelele ' },
            { name: 'Lunca ', village: 'Valea Argovei ' },
            { name: 'Ostrovu ', village: 'Valea Argovei ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Valea Argovei ' },
            { name: 'Vlădiceasca ', simpleName: 'Vladiceasca ', village: 'Valea Argovei ' },
            { name: 'Gălbinaşi ', simpleName: 'Galbinasi ', village: 'Vasilati ' },
            { name: 'Nuci ', village: 'Vasilati ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Vasilati ' },
            {
                name: 'Mihai Viteazu ', village: 'Vlad Tepes '
            }
        ]
    },
    {
        auto: 'CS ',
        name: 'Caraş-Severin ',
        cities: [
            { name: 'Anina ' },
            { name: 'Armeniş ', simpleName: 'Armenis ' },
            { name: 'Berlişte ', simpleName: 'Berliste ' },
            { name: 'Berzasca ' },
            { name: 'Berzovia ' },
            { name: 'Bocşa ', simpleName: 'Bocsa ' },
            { name: 'Bolvaşniţa ', simpleName: 'Bolvasnita ' },
            { name: 'Bozovici ' },
            { name: 'Brebu ' },
            { name: 'Brebu Nou ' },
            { name: 'Buchin ' },
            { name: 'Bucoşniţa ', simpleName: 'Bucosnita ' },
            { name: 'Băile Herculane ', simpleName: 'Baile Herculane ' },
            { name: 'Bănia ', simpleName: 'Bania ' },
            { name: 'Băuţar ', simpleName: 'Bautar ' },
            { name: 'Caransebeş ', simpleName: 'Caransebes ' },
            { name: 'Caraşova ', simpleName: 'Carasova ' },
            { name: 'Ciclova Română ', simpleName: 'Ciclova Romana ' },
            { name: 'Ciuchici ' },
            { name: 'Ciudanoviţa ', simpleName: 'Ciudanovita ' },
            { name: 'Copăcele ', simpleName: 'Copacele ' },
            { name: 'Cornea ' },
            { name: 'Cornereva ' },
            { name: 'Coronini ' },
            { name: 'Cărbunari ', simpleName: 'Carbunari ' },
            { name: 'Dalboşeţ ', simpleName: 'Dalboset ' },
            { name: 'Doclin ' },
            { name: 'Dognecea ' },
            { name: 'Domaşnea ', simpleName: 'Domasnea ' },
            { name: 'Eftimie Murgu ' },
            { name: 'Ezeriş ', simpleName: 'Ezeris ' },
            { name: 'Forotic ' },
            { name: 'Fârliug ', simpleName: 'Farliug ' },
            { name: 'Glimboca ' },
            { name: 'Goruia ' },
            { name: 'Grădinari ', simpleName: 'Gradinari ' },
            { name: 'Gârnic ', simpleName: 'Garnic ' },
            { name: 'Iablaniţa ', simpleName: 'Iablanita ' },
            { name: 'Luncaviţa ', simpleName: 'Luncavita ' },
            { name: 'Lupac ' },
            { name: 'Lăpuşnicel ', simpleName: 'Lapusnicel ' },
            { name: 'Lăpuşnicu Mare ', simpleName: 'Lapusnicu Mare ' },
            { name: 'Marga ' },
            { name: 'Mehadia ' },
            { name: 'Mehadica ' },
            { name: 'Moldova Nouă ', simpleName: 'Moldova Noua ' },
            { name: 'Măureni ', simpleName: 'Maureni ' },
            { name: 'Naidăş ', simpleName: 'Naidas ' },
            { name: 'Obreja ' },
            { name: 'Ocna de Fier ' },
            { name: 'Oraviţa ', simpleName: 'Oravita ' },
            { name: 'Oţelu Roşu ', simpleName: 'Otelu Rosu ' },
            { name: 'Pojejena ' },
            { name: 'Prigor ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ' },
            { name: 'Ramna ' },
            { name: 'Reşiţa ', simpleName: 'Resita ' },
            { name: 'Rusca Montană ', simpleName: 'Rusca Montana ' },
            { name: 'Răcăşdia ', simpleName: 'Racasdia ' },
            { name: 'Sacu ' },
            { name: 'Sasca Montană ', simpleName: 'Sasca Montana ' },
            { name: 'Sicheviţa ', simpleName: 'Sichevita ' },
            { name: 'Slatina-Timiş ', simpleName: 'Slatina-Timis ' },
            { name: 'Socol ' },
            { name: 'Teregova ' },
            { name: 'Ticvaniu Mare ' },
            { name: 'Topleţ ', simpleName: 'Toplet ' },
            { name: 'Turnu Ruieni ' },
            { name: 'Târnova ', simpleName: 'Tarnova ' },
            { name: 'Vermeş ', simpleName: 'Vermes ' },
            { name: 'Vrani ' },
            { name: 'Văliug ', simpleName: 'Valiug ' },
            { name: 'Vărădia ', simpleName: 'Varadia ' },
            { name: 'Zorlenţu Mare ', simpleName: 'Zorlentu Mare ' },
            { name: 'Zăvoi ', simpleName: 'Zavoi ' },
            { name: 'Şopotu Nou ', simpleName: 'Sopotu Nou ' },
            { name: 'Steierdorf ', village: 'Anina ' },
            { name: 'Feneş ', simpleName: 'Fenes ', village: 'Armenis ' },
            { name: 'Plopu ', village: 'Armenis ' },
            { name: 'Sat Bătrân ', simpleName: 'Sat Batran ', village: 'Armenis ' },
            { name: 'Sub Margine ', village: 'Armenis ' },
            { name: 'Pecinişca ', simpleName: 'Pecinisca ', village: 'Baile Herculane ' },
            { name: 'Gârbovăţ ', simpleName: 'Garbovat ', village: 'Bania ' },
            { name: 'Bucova ', village: 'Bautar ' },
            { name: 'Cornişoru ', simpleName: 'Cornisoru ', village: 'Bautar ' },
            { name: 'Preveciori ', village: 'Bautar ' },
            { name: 'Iam ', village: 'Berliste ' },
            { name: 'Milcoveni ', village: 'Berliste ' },
            { name: 'Rusova Nouă ', simpleName: 'Rusova Noua ', village: 'Berliste ' },
            { name: 'Rusova Veche ', village: 'Berliste ' },
            { name: 'Bigăr ', simpleName: 'Bigar ', village: 'Berzasca ' },
            { name: 'Cozla ', village: 'Berzasca ' },
            { name: 'Drencova ', village: 'Berzasca ' },
            { name: 'Liubcova ', village: 'Berzasca ' },
            { name: 'Fizeş ', simpleName: 'Fizes ', village: 'Berzovia ' },
            { name: 'Gherteniş ', simpleName: 'Ghertenis ', village: 'Berzovia ' },
            { name: 'Vârciorova ', simpleName: 'Varciorova ', village: 'Bolvasnita ' },
            { name: 'Poneasca ', village: 'Bozovici ' },
            { name: 'Prilipeţ ', simpleName: 'Prilipet ', village: 'Bozovici ' },
            { name: 'Valea Minişului ', simpleName: 'Valea Minisului ', village: 'Bozovici ' },
            { name: 'Apadia ', village: 'Brebu ' },
            { name: 'Valeadeni ', village: 'Brebu ' },
            { name: 'Gărâna ', simpleName: 'Garana ', village: 'Brebu Nou ' },
            { name: 'Lindenfeld ', village: 'Buchin ' },
            { name: 'Poiana ', village: 'Buchin ' },
            { name: 'Prisian ', village: 'Buchin ' },
            { name: 'Valea Timişului ', simpleName: 'Valea Timisului ', village: 'Buchin ' },
            { name: 'Goleţ ', simpleName: 'Golet ', village: 'Bucosnita ' },
            { name: 'Petroşniţa ', simpleName: 'Petrosnita ', village: 'Bucosnita ' },
            { name: 'Vălişoara ', simpleName: 'Valisoara ', village: 'Bucosnita ' },
            { name: 'Jupa ', village: 'Caransebes ' },
            { name: 'Iabalcea ', village: 'Carasova ' },
            { name: 'Nermed ', village: 'Carasova ' },
            { name: 'Ştinăpari ', simpleName: 'Stinapari ', village: 'Carbunari ' },
            { name: 'Ilidia ', village: 'Ciclova Romana ' },
            { name: 'Socolari ', village: 'Ciclova Romana ' },
            { name: 'Macovişte ', simpleName: 'Macoviste ', village: 'Ciuchici ' },
            { name: 'Nicolinţ ', simpleName: 'Nicolint ', village: 'Ciuchici ' },
            { name: 'Petrilova ', village: 'Ciuchici ' },
            { name: 'Jitin ', village: 'Ciudanovita ' },
            { name: 'Căvăran ', simpleName: 'Cavaran ', village: 'Constantin Daicoviciu ' },
            { name: 'Maciova ', village: 'Constantin Daicoviciu ' },
            { name: 'Mâtnicu Mare ', simpleName: 'Matnicu Mare ', village: 'Constantin Daicoviciu ' },
            { name: 'Peştere ', simpleName: 'Pestere ', village: 'Constantin Daicoviciu ' },
            { name: 'Prisaca ', village: 'Constantin Daicoviciu ' },
            { name: 'Zăgujeni ', simpleName: 'Zagujeni ', village: 'Constantin Daicoviciu ' },
            { name: 'Ohaba-Mâtnic ', simpleName: 'Ohaba-Matnic ', village: 'Copacele ' },
            { name: 'Ruginosu ', village: 'Copacele ' },
            { name: 'Zorile ', village: 'Copacele ' },
            { name: 'Cruşovăţ ', simpleName: 'Crusovat ', village: 'Cornea ' },
            { name: 'Cuptoare ', village: 'Cornea ' },
            { name: 'Macovişte ', simpleName: 'Macoviste ', village: 'Cornea ' },
            { name: 'Arsuri ', village: 'Cornereva ' },
            { name: 'Bogâltin ', simpleName: 'Bogaltin ', village: 'Cornereva ' },
            { name: 'Bojia ', village: 'Cornereva ' },
            { name: 'Borugi ', village: 'Cornereva ' },
            { name: 'Camena ', village: 'Cornereva ' },
            { name: 'Cireşel ', simpleName: 'Ciresel ', village: 'Cornereva ' },
            { name: 'Costiş ', simpleName: 'Costis ', village: 'Cornereva ' },
            { name: 'Cozia ', village: 'Cornereva ' },
            { name: 'Cracu Mare ', village: 'Cornereva ' },
            { name: 'Cracu Teiului ', village: 'Cornereva ' },
            { name: 'Dobraia ', village: 'Cornereva ' },
            { name: 'Dolina ', village: 'Cornereva ' },
            { name: 'Gruni ', village: 'Cornereva ' },
            { name: 'Hora Mare ', village: 'Cornereva ' },
            { name: 'Hora Mică ', simpleName: 'Hora Mica ', village: 'Cornereva ' },
            { name: 'Ineleţ ', simpleName: 'Inelet ', village: 'Cornereva ' },
            { name: 'Izvor ', village: 'Cornereva ' },
            { name: 'Lunca Florii ', village: 'Cornereva ' },
            { name: 'Lunca Zaicii ', village: 'Cornereva ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Cornereva ' },
            { name: 'Negiudin ', village: 'Cornereva ' },
            { name: 'Obiţa ', simpleName: 'Obita ', village: 'Cornereva ' },
            { name: 'Pogara ', village: 'Cornereva ' },
            { name: 'Pogara de Sus ', village: 'Cornereva ' },
            { name: 'Poiana Lungă ', simpleName: 'Poiana Lunga ', village: 'Cornereva ' },
            { name: 'Prisacina ', village: 'Cornereva ' },
            { name: 'Prislop ', village: 'Cornereva ' },
            { name: 'Ruştin ', simpleName: 'Rustin ', village: 'Cornereva ' },
            { name: 'Scărişoara ', simpleName: 'Scarisoara ', village: 'Cornereva ' },
            { name: 'Strugasca ', village: 'Cornereva ' },
            { name: 'Studena ', village: 'Cornereva ' },
            { name: 'Sub Crâng ', simpleName: 'Sub Crang ', village: 'Cornereva ' },
            { name: 'Sub Plai ', village: 'Cornereva ' },
            { name: 'Topla ', village: 'Cornereva ' },
            { name: 'Zbegu ', village: 'Cornereva ' },
            { name: 'Zmogotin ', village: 'Cornereva ' },
            { name: 'Zoina ', village: 'Cornereva ' },
            { name: 'Zănogi ', simpleName: 'Zanogi ', village: 'Cornereva ' },
            { name: 'Ţaţu ', simpleName: 'Tatu ', village: 'Cornereva ' },
            { name: 'Sfânta Elena ', simpleName: 'Sfanta Elena ', village: 'Coronini ' },
            { name: 'Boina ', village: 'Dalboset ' },
            { name: 'Boiniţa ', simpleName: 'Boinita ', village: 'Dalboset ' },
            { name: 'Bârz ', simpleName: 'Barz ', village: 'Dalboset ' },
            { name: 'Prislop ', village: 'Dalboset ' },
            { name: 'Reşiţa Mică ', simpleName: 'Resita Mica ', village: 'Dalboset ' },
            { name: 'Şopotu Vechi ', simpleName: 'Sopotu Vechi ', village: 'Dalboset ' },
            { name: 'Biniş ', simpleName: 'Binis ', village: 'Doclin ' },
            { name: 'Tirol ', village: 'Doclin ' },
            { name: 'Calina ', village: 'Dognecea ' },
            { name: 'Cănicea ', simpleName: 'Canicea ', village: 'Domasnea ' },
            { name: 'Soceni ', village: 'Ezeris ' },
            { name: 'Dezeşti ', simpleName: 'Dezesti ', village: 'Farliug ' },
            { name: 'Duleu ', village: 'Farliug ' },
            { name: 'Remetea-Pogănici ', simpleName: 'Remetea-Poganici ', village: 'Farliug ' },
            { name: 'Scăiuş ', simpleName: 'Scaius ', village: 'Farliug ' },
            { name: 'Valea Mare ', village: 'Farliug ' },
            { name: 'Brezon ', village: 'Forotic ' },
            { name: 'Comorâşte ', simpleName: 'Comoraste ', village: 'Forotic ' },
            { name: 'Surducu Mare ', village: 'Forotic ' },
            { name: 'Padina Matei ', village: 'Garnic ' },
            { name: 'Giurgiova ', village: 'Goruia ' },
            { name: 'Gârlişte ', simpleName: 'Garliste ', village: 'Goruia ' },
            { name: 'Greoni ', village: 'Gradinari ' },
            { name: 'Globu Craiovei ', village: 'Iablanita ' },
            { name: 'Petnic ', village: 'Iablanita ' },
            { name: 'Pârvova ', simpleName: 'Parvova ', village: 'Lapusnicel ' },
            { name: 'Şumiţa ', simpleName: 'Sumita ', village: 'Lapusnicel ' },
            { name: 'Moceriş ', simpleName: 'Moceris ', village: 'Lapusnicu Mare ' },
            { name: 'Verendin ', village: 'Luncavita ' },
            { name: 'Clocotici ', village: 'Lupac ' },
            { name: 'Rafnic ', village: 'Lupac ' },
            { name: 'Vodnic ', village: 'Lupac ' },
            { name: 'Vama Marga ', village: 'Marga ' },
            { name: 'Şoşdea ', simpleName: 'Sosdea ', village: 'Maureni ' },
            { name: 'Globurău ', simpleName: 'Globurau ', village: 'Mehadia ' },
            { name: 'Plugova ', village: 'Mehadia ' },
            { name: 'Valea Bolvaşniţa ', simpleName: 'Valea Bolvasnita ', village: 'Mehadia ' },
            { name: 'Moldova Veche ', village: 'Moldova Noua ' },
            { name: 'Moldoviţa ', simpleName: 'Moldovita ', village: 'Moldova Noua ' },
            { name: 'Măceşti ', simpleName: 'Macesti ', village: 'Moldova Noua ' },
            { name: 'Lescoviţa ', simpleName: 'Lescovita ', village: 'Naidas ' },
            { name: 'Ciuta ', village: 'Obreja ' },
            { name: 'Iaz ', village: 'Obreja ' },
            { name: 'Var ', village: 'Obreja ' },
            { name: 'Agadici ', village: 'Oravita ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Oravita ' },
            { name: 'Brădişoru de Jos ', simpleName: 'Bradisoru de Jos ', village: 'Oravita ' },
            { name: 'Ciclova Montană ', simpleName: 'Ciclova Montana ', village: 'Oravita ' },
            { name: 'Marila ', village: 'Oravita ' },
            { name: 'Răchitova ', simpleName: 'Rachitova ', village: 'Oravita ' },
            { name: 'Cireşa ', simpleName: 'Ciresa ', village: 'Otelu Rosu ' },
            { name: 'Mal ', village: 'Otelu Rosu ' },
            { name: 'Cornuţel ', simpleName: 'Cornutel ', village: 'Paltinis ' },
            { name: 'Delineşti ', simpleName: 'Delinesti ', village: 'Paltinis ' },
            { name: 'Ohabiţa ', simpleName: 'Ohabita ', village: 'Paltinis ' },
            { name: 'Rugi ', village: 'Paltinis ' },
            { name: 'Belobreşca ', simpleName: 'Belobresca ', village: 'Pojejena ' },
            { name: 'Divici ', village: 'Pojejena ' },
            { name: 'Radimna ', village: 'Pojejena ' },
            { name: 'Şuşca ', simpleName: 'Susca ', village: 'Pojejena ' },
            { name: 'Borlovenii Noi ', village: 'Prigor ' },
            { name: 'Borlovenii Vechi ', village: 'Prigor ' },
            { name: 'Putna ', village: 'Prigor ' },
            { name: 'Pătaş ', simpleName: 'Patas ', village: 'Prigor ' },
            { name: 'Vrăniuţ ', simpleName: 'Vraniut ', village: 'Racasdia ' },
            { name: 'Bărbosu ', simpleName: 'Barbosu ', village: 'Ramna ' },
            { name: 'Valeapai ', village: 'Ramna ' },
            { name: 'Cuptoare ', village: 'Resita ' },
            { name: 'Câlnic ', simpleName: 'Calnic ', village: 'Resita ' },
            { name: 'Doman ', village: 'Resita ' },
            { name: 'Moniom ', village: 'Resita ' },
            { name: 'Secu ', village: 'Resita ' },
            { name: 'Ţerova ', simpleName: 'Terova ', village: 'Resita ' },
            { name: 'Ruşchiţa ', simpleName: 'Ruschita ', village: 'Rusca Montana ' },
            { name: 'Sălbăgelu Nou ', simpleName: 'Salbagelu Nou ', village: 'Sacu ' },
            { name: 'Tincova ', village: 'Sacu ' },
            { name: 'Bogodinţ ', simpleName: 'Bogodint ', village: 'Sasca Montana ' },
            { name: 'Potoc ', village: 'Sasca Montana ' },
            { name: 'Sasca Română ', simpleName: 'Sasca Romana ', village: 'Sasca Montana ' },
            { name: 'Slatina-Nera ', village: 'Sasca Montana ' },
            { name: 'Brestelnic ', village: 'Sichevita ' },
            { name: 'Cameniţa ', simpleName: 'Camenita ', village: 'Sichevita ' },
            { name: 'Cracu Almăj ', simpleName: 'Cracu Almaj ', village: 'Sichevita ' },
            { name: 'Cruşoviţa ', simpleName: 'Crusovita ', village: 'Sichevita ' },
            { name: 'Curmătura ', simpleName: 'Curmatura ', village: 'Sichevita ' },
            { name: 'Cârşie ', simpleName: 'Carsie ', village: 'Sichevita ' },
            { name: 'Frăsiniş ', simpleName: 'Frasinis ', village: 'Sichevita ' },
            { name: 'Gornea ', village: 'Sichevita ' },
            { name: 'Liborajdea ', village: 'Sichevita ' },
            { name: 'Lucacevăţ ', simpleName: 'Lucacevat ', village: 'Sichevita ' },
            { name: 'Martinovăţ ', simpleName: 'Martinovat ', village: 'Sichevita ' },
            { name: 'Ogaşu Podului ', simpleName: 'Ogasu Podului ', village: 'Sichevita ' },
            { name: 'Streneac ', village: 'Sichevita ' },
            { name: 'Valea Oreviţa ', simpleName: 'Valea Orevita ', village: 'Sichevita ' },
            { name: 'Valea Ravensca ', village: 'Sichevita ' },
            { name: 'Valea Sicheviţei ', simpleName: 'Valea Sichevitei ', village: 'Sichevita ' },
            { name: 'Zănou ', simpleName: 'Zanou ', village: 'Sichevita ' },
            { name: 'Zăsloane ', simpleName: 'Zasloane ', village: 'Sichevita ' },
            { name: 'Ilova ', village: 'Slatina-Timis ' },
            { name: 'Sadova Nouă ', simpleName: 'Sadova Noua ', village: 'Slatina-Timis ' },
            { name: 'Sadova Veche ', village: 'Slatina-Timis ' },
            { name: 'Baziaş ', simpleName: 'Bazias ', village: 'Socol ' },
            { name: 'Câmpia ', simpleName: 'Campia ', village: 'Socol ' },
            { name: 'Pârneaura ', simpleName: 'Parneaura ', village: 'Socol ' },
            { name: 'Zlatiţa ', simpleName: 'Zlatita ', village: 'Socol ' },
            { name: 'Cârşa Roşie ', simpleName: 'Carsa Rosie ', village: 'Sopotu Nou ' },
            { name: 'Driştie ', simpleName: 'Dristie ', village: 'Sopotu Nou ' },
            { name: 'Poienile Boinei ', village: 'Sopotu Nou ' },
            { name: 'Ravensca ', village: 'Sopotu Nou ' },
            { name: 'Răchita ', simpleName: 'Rachita ', village: 'Sopotu Nou ' },
            { name: 'Stăncilova ', simpleName: 'Stancilova ', village: 'Sopotu Nou ' },
            { name: 'Urcu ', village: 'Sopotu Nou ' },
            { name: 'Valea Roşie ', simpleName: 'Valea Rosie ', village: 'Sopotu Nou ' },
            { name: 'Valea Răchitei ', simpleName: 'Valea Rachitei ', village: 'Sopotu Nou ' },
            { name: 'Bratova ', village: 'Tarnova ' },
            { name: 'Rusca ', village: 'Teregova ' },
            { name: 'Cârnecea ', simpleName: 'Carnecea ', village: 'Ticvaniu Mare ' },
            { name: 'Secăşeni ', simpleName: 'Secaseni ', village: 'Ticvaniu Mare ' },
            { name: 'Ticvaniu Mic ', village: 'Ticvaniu Mare ' },
            { name: 'Bârza ', simpleName: 'Barza ', village: 'Toplet ' },
            { name: 'Borlova ', village: 'Turnu Ruieni ' },
            { name: 'Cicleni ', village: 'Turnu Ruieni ' },
            { name: 'Dalci ', village: 'Turnu Ruieni ' },
            { name: 'Zerveşti ', simpleName: 'Zervesti ', village: 'Turnu Ruieni ' },
            { name: 'Zlagna ', village: 'Turnu Ruieni ' },
            { name: 'Mercina ', village: 'Varadia ' },
            { name: 'Ersig ', village: 'Vermes ' },
            { name: 'Izgar ', village: 'Vermes ' },
            { name: 'Ciortea ', village: 'Vrani ' },
            { name: 'Iertof ', village: 'Vrani ' },
            { name: '23 August ', village: 'Zavoi ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Zavoi ' },
            { name: 'Măru ', simpleName: 'Maru ', village: 'Zavoi ' },
            { name: 'Poiana Mărului ', simpleName: 'Poiana Marului ', village: 'Zavoi ' },
            { name: 'Valea Bistrei ', village: 'Zavoi ' },
            { name: 'Voislova ', village: 'Zavoi ' },
            {
                name: 'Zorlencior ', village: 'Zorlentu Mare '
            }
        ]
    },
    {
        auto: 'CJ ',
        name: 'Cluj ',
        cities: [
            { name: 'Aghireşu ', simpleName: 'Aghiresu ' },
            { name: 'Aiton ' },
            { name: 'Aluniş ', simpleName: 'Alunis ' },
            { name: 'Apahida ' },
            { name: 'Baciu ' },
            { name: 'Beliş ', simpleName: 'Belis ' },
            { name: 'Bobâlna ', simpleName: 'Bobalna ' },
            { name: 'Bonţida ', simpleName: 'Bontida ' },
            { name: 'Borşa ', simpleName: 'Borsa ' },
            { name: 'Buza ' },
            { name: 'Băişoara ', simpleName: 'Baisoara ' },
            { name: 'Ceanu Mare ' },
            { name: 'Chinteni ' },
            { name: 'Chiuieşti ', simpleName: 'Chiuiesti ' },
            { name: 'Ciucea ' },
            { name: 'Ciurila ' },
            { name: 'Cluj-Napoca ' },
            { name: 'Cojocna ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ' },
            { name: 'Cuzdrioara ' },
            { name: 'Câmpia Turzii ', simpleName: 'Campia Turzii ' },
            { name: 'Câţcău ', simpleName: 'Catcau ' },
            { name: 'Căianu ', simpleName: 'Caianu ' },
            { name: 'Călăraşi ', simpleName: 'Calarasi ' },
            { name: 'Călăţele ', simpleName: 'Calatele ' },
            { name: 'Cămăraşu ', simpleName: 'Camarasu ' },
            { name: 'Căpuşu Mare ', simpleName: 'Capusu Mare ' },
            { name: 'Cătina ', simpleName: 'Catina ' },
            { name: 'Căşeiu ', simpleName: 'Caseiu ' },
            { name: 'Dej ' },
            { name: 'Dăbâca ', simpleName: 'Dabaca ' },
            { name: 'Feleacu ' },
            { name: 'Fizeşu Gherlii ', simpleName: 'Fizesu Gherlii ' },
            { name: 'Floreşti ', simpleName: 'Floresti ' },
            { name: 'Frata ' },
            { name: 'Geaca ' },
            { name: 'Gherla ' },
            { name: 'Gilău ', simpleName: 'Gilau ' },
            { name: 'Gârbău ', simpleName: 'Garbau ' },
            { name: 'Huedin ' },
            { name: 'Iara ' },
            { name: 'Iclod ' },
            { name: 'Izvoru Crişului ', simpleName: 'Izvoru Crisului ' },
            { name: 'Jichişu de Jos ', simpleName: 'Jichisu de Jos ' },
            { name: 'Luna ' },
            { name: 'Mica ' },
            { name: 'Mihai Viteazu ' },
            { name: 'Mintiu Gherlii ' },
            { name: 'Mociu ' },
            { name: 'Moldoveneşti ', simpleName: 'Moldovenesti ' },
            { name: 'Măguri-Răcătău ', simpleName: 'Maguri-Racatau ' },
            { name: 'Mănăstireni ', simpleName: 'Manastireni ' },
            { name: 'Mărgău ', simpleName: 'Margau ' },
            { name: 'Mărişel ', simpleName: 'Marisel ' },
            { name: 'Negreni ' },
            { name: 'Panticeu ' },
            { name: 'Petreştii de Jos ', simpleName: 'Petrestii de Jos ' },
            { name: 'Ploscoş ', simpleName: 'Ploscos ' },
            { name: 'Poieni ' },
            { name: 'Pălatca ', simpleName: 'Palatca ' },
            { name: 'Recea-Cristur ' },
            { name: 'Rişca ', simpleName: 'Risca ' },
            { name: 'Sic ' },
            { name: 'Suatu ' },
            { name: 'Sâncraiu ', simpleName: 'Sancraiu ' },
            { name: 'Sânmărtin ', simpleName: 'Sanmartin ' },
            { name: 'Sânpaul ', simpleName: 'Sanpaul ' },
            { name: 'Săcuieu ', simpleName: 'Sacuieu ' },
            { name: 'Sănduleşti ', simpleName: 'Sandulesti ' },
            { name: 'Săvădisla ', simpleName: 'Savadisla ' },
            { name: 'Tritenii de Jos ' },
            { name: 'Turda ' },
            { name: 'Tureni ' },
            { name: 'Unguraş ', simpleName: 'Unguras ' },
            { name: 'Vad ' },
            { name: 'Valea Ierii ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ' },
            { name: 'Vultureni ' },
            { name: 'Ţaga ', simpleName: 'Taga ' },
            { name: 'Aghireşu-Fabrici ', simpleName: 'Aghiresu-Fabrici ', village: 'Aghiresu ' },
            { name: 'Arghişu ', simpleName: 'Arghisu ', village: 'Aghiresu ' },
            { name: 'Băgara ', simpleName: 'Bagara ', village: 'Aghiresu ' },
            { name: 'Dorolţu ', simpleName: 'Doroltu ', village: 'Aghiresu ' },
            { name: 'Dâncu ', simpleName: 'Dancu ', village: 'Aghiresu ' },
            { name: 'Inucu ', village: 'Aghiresu ' },
            { name: 'Leghia ', village: 'Aghiresu ' },
            { name: 'Macău ', simpleName: 'Macau ', village: 'Aghiresu ' },
            { name: 'Ticu ', village: 'Aghiresu ' },
            { name: 'Ticu-Colonie ', village: 'Aghiresu ' },
            { name: 'Rediu ', village: 'Aiton ' },
            { name: 'Corneni ', village: 'Alunis ' },
            { name: 'Ghirolt ', village: 'Alunis ' },
            { name: 'Pruneni ', village: 'Alunis ' },
            { name: 'Vale ', village: 'Alunis ' },
            { name: 'Bodrog ', village: 'Apahida ' },
            { name: 'Corpadea ', village: 'Apahida ' },
            { name: 'Câmpeneşti ', simpleName: 'Campenesti ', village: 'Apahida ' },
            { name: 'Dezmir ', village: 'Apahida ' },
            { name: 'Pata ', village: 'Apahida ' },
            { name: 'Sub Coastă ', simpleName: 'Sub Coasta ', village: 'Apahida ' },
            { name: 'Sânnicoară ', simpleName: 'Sannicoara ', village: 'Apahida ' },
            { name: 'Cristorel ', village: 'Aschileu ' },
            { name: 'Dorna ', village: 'Aschileu ' },
            { name: 'Fodora ', village: 'Aschileu ' },
            { name: 'Ăşchileu Mare ', simpleName: 'Aschileu Mare ', village: 'Aschileu ' },
            { name: 'Ăşchileu Mic ', simpleName: 'Aschileu Mic ', village: 'Aschileu ' },
            { name: 'Coruşu ', simpleName: 'Corusu ', village: 'Baciu ' },
            { name: 'Mera ', village: 'Baciu ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Baciu ' },
            { name: 'Rădaia ', simpleName: 'Radaia ', village: 'Baciu ' },
            { name: 'Suceagu ', village: 'Baciu ' },
            { name: 'Săliştea Nouă ', simpleName: 'Salistea Noua ', village: 'Baciu ' },
            { name: 'Frăsinet ', simpleName: 'Frasinet ', village: 'Baisoara ' },
            { name: 'Moara de Pădure ', simpleName: 'Moara de Padure ', village: 'Baisoara ' },
            { name: 'Muntele Bocului ', village: 'Baisoara ' },
            { name: 'Muntele Băişorii ', simpleName: 'Muntele Baisorii ', village: 'Baisoara ' },
            { name: 'Muntele Cacovei ', village: 'Baisoara ' },
            { name: 'Muntele Filii ', village: 'Baisoara ' },
            { name: 'Muntele Săcelului ', simpleName: 'Muntele Sacelului ', village: 'Baisoara ' },
            { name: 'Săcel ', simpleName: 'Sacel ', village: 'Baisoara ' },
            { name: 'Bălceşti ', simpleName: 'Balcesti ', village: 'Belis ' },
            { name: 'Dealu Botii ', village: 'Belis ' },
            { name: 'Giurcuţa de Jos ', simpleName: 'Giurcuta de Jos ', village: 'Belis ' },
            { name: 'Giurcuţa de Sus ', simpleName: 'Giurcuta de Sus ', village: 'Belis ' },
            { name: 'Poiana Horea ', village: 'Belis ' },
            { name: 'Smida ', village: 'Belis ' },
            { name: 'Antăş ', simpleName: 'Antas ', village: 'Bobalna ' },
            { name: 'Blidăreşti ', simpleName: 'Blidaresti ', village: 'Bobalna ' },
            { name: 'Băbdiu ', simpleName: 'Babdiu ', village: 'Bobalna ' },
            { name: 'Cremenea ', village: 'Bobalna ' },
            { name: 'Maia ', village: 'Bobalna ' },
            { name: 'Oşorhel ', simpleName: 'Osorhel ', village: 'Bobalna ' },
            { name: 'Pruni ', village: 'Bobalna ' },
            { name: 'Răzbuneni ', simpleName: 'Razbuneni ', village: 'Bobalna ' },
            { name: 'Suarăş ', simpleName: 'Suaras ', village: 'Bobalna ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Bobalna ' },
            { name: 'Coasta ', village: 'Bontida ' },
            { name: 'Răscruci ', simpleName: 'Rascruci ', village: 'Bontida ' },
            { name: 'Tăuşeni ', simpleName: 'Tauseni ', village: 'Bontida ' },
            { name: 'Borşa-Crestaia ', simpleName: 'Borsa-Crestaia ', village: 'Borsa ' },
            { name: 'Borşa-Cătun ', simpleName: 'Borsa-Catun ', village: 'Borsa ' },
            { name: 'Ciumăfaia ', simpleName: 'Ciumafaia ', village: 'Borsa ' },
            { name: 'Giula ', village: 'Borsa ' },
            { name: 'Rotunda ', village: 'Buza ' },
            { name: 'Bărăi ', simpleName: 'Barai ', village: 'Caianu ' },
            { name: 'Căianu Mic ', simpleName: 'Caianu Mic ', village: 'Caianu ' },
            { name: 'Căianu-Vamă ', simpleName: 'Caianu-Vama ', village: 'Caianu ' },
            { name: 'Vaida-Cămăraş ', simpleName: 'Vaida-Camaras ', village: 'Caianu ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Caianu ' },
            { name: 'Bogata ', village: 'Calarasi ' },
            { name: 'Călăraşi-Gară ', simpleName: 'Calarasi-Gara ', village: 'Calarasi ' },
            { name: 'Călata ', simpleName: 'Calata ', village: 'Calatele ' },
            { name: 'Dealu Negru ', village: 'Calatele ' },
            { name: 'Finciu ', village: 'Calatele ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Calatele ' },
            { name: 'Năoiu ', simpleName: 'Naoiu ', village: 'Camarasu ' },
            { name: 'Sâmboleni ', simpleName: 'Samboleni ', village: 'Camarasu ' },
            { name: 'Agârbiciu ', simpleName: 'Agarbiciu ', village: 'Capusu Mare ' },
            { name: 'Bălceşti ', simpleName: 'Balcesti ', village: 'Capusu Mare ' },
            { name: 'Căpuşu Mic ', simpleName: 'Capusu Mic ', village: 'Capusu Mare ' },
            { name: 'Dumbrava ', village: 'Capusu Mare ' },
            { name: 'Dângău Mare ', simpleName: 'Dangau Mare ', village: 'Capusu Mare ' },
            { name: 'Dângău Mic ', simpleName: 'Dangau Mic ', village: 'Capusu Mare ' },
            { name: 'Păniceni ', simpleName: 'Paniceni ', village: 'Capusu Mare ' },
            { name: 'Straja ', village: 'Capusu Mare ' },
            { name: 'Comorâţa ', simpleName: 'Comorata ', village: 'Caseiu ' },
            { name: 'Coplean ', village: 'Caseiu ' },
            { name: 'Custura ', village: 'Caseiu ' },
            { name: 'Guga ', village: 'Caseiu ' },
            { name: 'Gârbău Dejului ', simpleName: 'Garbau Dejului ', village: 'Caseiu ' },
            { name: 'Leurda ', village: 'Caseiu ' },
            { name: 'Rugăşeşti ', simpleName: 'Rugasesti ', village: 'Caseiu ' },
            { name: 'Sălătruc ', simpleName: 'Salatruc ', village: 'Caseiu ' },
            { name: 'Urişor ', simpleName: 'Urisor ', village: 'Caseiu ' },
            { name: 'Muncel ', village: 'Catcau ' },
            { name: 'Sălişca ', simpleName: 'Salisca ', village: 'Catcau ' },
            { name: 'Copru ', village: 'Catina ' },
            { name: 'Feldioara ', village: 'Catina ' },
            { name: 'Hagău ', simpleName: 'Hagau ', village: 'Catina ' },
            { name: 'Hodaie ', village: 'Catina ' },
            { name: 'Valea Caldă ', simpleName: 'Valea Calda ', village: 'Catina ' },
            { name: 'Andici ', village: 'Ceanu Mare ' },
            { name: 'Boian ', village: 'Ceanu Mare ' },
            { name: 'Bolduţ ', simpleName: 'Boldut ', village: 'Ceanu Mare ' },
            { name: 'Ciurgău ', simpleName: 'Ciurgau ', village: 'Ceanu Mare ' },
            { name: 'Dosu Napului ', village: 'Ceanu Mare ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Ceanu Mare ' },
            { name: 'Hodăi Boian ', simpleName: 'Hodai Boian ', village: 'Ceanu Mare ' },
            { name: 'Iacobeni ', village: 'Ceanu Mare ' },
            { name: 'Morţeşti ', simpleName: 'Mortesti ', village: 'Ceanu Mare ' },
            { name: 'Strucut ', village: 'Ceanu Mare ' },
            { name: 'Stârcu ', simpleName: 'Starcu ', village: 'Ceanu Mare ' },
            { name: 'Valea lui Cati ', village: 'Ceanu Mare ' },
            { name: 'Deuşu ', simpleName: 'Deusu ', village: 'Chinteni ' },
            { name: 'Feiurdeni ', village: 'Chinteni ' },
            { name: 'Măcicaşu ', simpleName: 'Macicasu ', village: 'Chinteni ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Chinteni ' },
            { name: 'Satu Lung ', village: 'Chinteni ' },
            { name: 'Sânmărtin ', simpleName: 'Sanmartin ', village: 'Chinteni ' },
            { name: 'Săliştea Veche ', simpleName: 'Salistea Veche ', village: 'Chinteni ' },
            { name: 'Vechea ', village: 'Chinteni ' },
            { name: 'Dosu Bricii ', village: 'Chiuiesti ' },
            { name: 'Huta ', village: 'Chiuiesti ' },
            { name: 'Măgoaja ', simpleName: 'Magoaja ', village: 'Chiuiesti ' },
            { name: 'Strâmbu ', simpleName: 'Strambu ', village: 'Chiuiesti ' },
            { name: 'Valea Căşeielului ', simpleName: 'Valea Caseielului ', village: 'Chiuiesti ' },
            { name: 'Valea lui Opriş ', simpleName: 'Valea lui Opris ', village: 'Chiuiesti ' },
            { name: 'Vânători ', simpleName: 'Vanatori ', village: 'Ciucea ' },
            { name: 'Filea de Jos ', village: 'Ciurila ' },
            { name: 'Filea de Sus ', village: 'Ciurila ' },
            { name: 'Pruniş ', simpleName: 'Prunis ', village: 'Ciurila ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Ciurila ' },
            { name: 'Sălicea ', simpleName: 'Salicea ', village: 'Ciurila ' },
            { name: 'Sălişte ', simpleName: 'Saliste ', village: 'Ciurila ' },
            { name: 'Şutu ', simpleName: 'Sutu ', village: 'Ciurila ' },
            { name: 'Boj-Cătun ', simpleName: 'Boj-Catun ', village: 'Cojocna ' },
            { name: 'Boju ', village: 'Cojocna ' },
            { name: 'Cara ', village: 'Cojocna ' },
            { name: 'Huci ', village: 'Cojocna ' },
            { name: 'Iuriu de Câmpie ', simpleName: 'Iuriu de Campie ', village: 'Cojocna ' },
            { name: 'Morişti ', simpleName: 'Moristi ', village: 'Cojocna ' },
            { name: 'Straja ', village: 'Cojocna ' },
            { name: 'Bârlea ', simpleName: 'Barlea ', village: 'Cornesti ' },
            { name: 'Igriţia ', simpleName: 'Igritia ', village: 'Cornesti ' },
            { name: 'Lujerdiu ', village: 'Cornesti ' },
            { name: 'Morău ', simpleName: 'Morau ', village: 'Cornesti ' },
            { name: 'Stoiana ', village: 'Cornesti ' },
            { name: 'Tiocu de Jos ', village: 'Cornesti ' },
            { name: 'Tiocu de Sus ', village: 'Cornesti ' },
            { name: 'Tioltiur ', village: 'Cornesti ' },
            { name: 'Mănăşturel ', simpleName: 'Manasturel ', village: 'Cuzdrioara ' },
            { name: 'Valea Gârboului ', simpleName: 'Valea Garboului ', village: 'Cuzdrioara ' },
            { name: 'Luna de Jos ', village: 'Dabaca ' },
            { name: 'Pâglişa ', simpleName: 'Paglisa ', village: 'Dabaca ' },
            { name: 'Ocna Dejului ', village: 'Dej ' },
            { name: 'Peştera ', simpleName: 'Pestera ', village: 'Dej ' },
            { name: 'Pintic ', village: 'Dej ' },
            { name: 'Şomcutu Mic ', simpleName: 'Somcutu Mic ', village: 'Dej ' },
            { name: 'Casele Miceşti ', simpleName: 'Casele Micesti ', village: 'Feleacu ' },
            { name: 'Gheorghieni ', village: 'Feleacu ' },
            { name: 'Sărădiş ', simpleName: 'Saradis ', village: 'Feleacu ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Feleacu ' },
            { name: 'Bonţ ', simpleName: 'Bont ', village: 'Fizesu Gherlii ' },
            { name: 'Lunca Bonţului ', simpleName: 'Lunca Bontului ', village: 'Fizesu Gherlii ' },
            { name: 'Nicula ', village: 'Fizesu Gherlii ' },
            { name: 'Săcălaia ', simpleName: 'Sacalaia ', village: 'Fizesu Gherlii ' },
            { name: 'Luna de Sus ', village: 'Floresti ' },
            { name: 'Tăuţi ', simpleName: 'Tauti ', village: 'Floresti ' },
            { name: 'Berchieşu ', simpleName: 'Berchiesu ', village: 'Frata ' },
            { name: 'Oaş ', simpleName: 'Oas ', village: 'Frata ' },
            { name: 'Olăriu ', simpleName: 'Olariu ', village: 'Frata ' },
            { name: 'Poiana Frăţii ', simpleName: 'Poiana Fratii ', village: 'Frata ' },
            { name: 'Pădurea Iacobeni ', simpleName: 'Padurea Iacobeni ', village: 'Frata ' },
            { name: 'Răzoare ', simpleName: 'Razoare ', village: 'Frata ' },
            { name: 'Soporu de Câmpie ', simpleName: 'Soporu de Campie ', village: 'Frata ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Garbau ' },
            { name: 'Nădăşelu ', simpleName: 'Nadaselu ', village: 'Garbau ' },
            { name: 'Turea ', village: 'Garbau ' },
            { name: 'Viştea ', simpleName: 'Vistea ', village: 'Garbau ' },
            { name: 'Chiriş ', simpleName: 'Chiris ', village: 'Geaca ' },
            { name: 'Lacu ', village: 'Geaca ' },
            { name: 'Legii ', village: 'Geaca ' },
            { name: 'Puini ', village: 'Geaca ' },
            { name: 'Sucutard ', village: 'Geaca ' },
            { name: 'Băiţa ', simpleName: 'Baita ', village: 'Gherla ' },
            { name: 'Hăşdate ', simpleName: 'Hasdate ', village: 'Gherla ' },
            { name: 'Silivaş ', simpleName: 'Silivas ', village: 'Gherla ' },
            { name: 'Someşu Cald ', simpleName: 'Somesu Cald ', village: 'Gilau ' },
            { name: 'Someşu Rece ', simpleName: 'Somesu Rece ', village: 'Gilau ' },
            { name: 'Bicălatu ', simpleName: 'Bicalatu ', village: 'Huedin ' },
            { name: 'Agriş ', simpleName: 'Agris ', village: 'Iara ' },
            { name: 'Borzeşti ', simpleName: 'Borzesti ', village: 'Iara ' },
            { name: 'Buru ', village: 'Iara ' },
            { name: 'Cacova Ierii ', village: 'Iara ' },
            { name: 'Făgetu Ierii ', simpleName: 'Fagetu Ierii ', village: 'Iara ' },
            { name: 'Lungeşti ', simpleName: 'Lungesti ', village: 'Iara ' },
            { name: 'Maşca ', simpleName: 'Masca ', village: 'Iara ' },
            { name: 'Măgura Ierii ', simpleName: 'Magura Ierii ', village: 'Iara ' },
            { name: 'Ocolişel ', simpleName: 'Ocolisel ', village: 'Iara ' },
            { name: 'Surduc ', village: 'Iara ' },
            { name: 'Valea Agrişului ', simpleName: 'Valea Agrisului ', village: 'Iara ' },
            { name: 'Valea Vadului ', village: 'Iara ' },
            { name: 'Fundătura ', simpleName: 'Fundatura ', village: 'Iclod ' },
            { name: 'Iclozel ', village: 'Iclod ' },
            { name: 'Livada ', village: 'Iclod ' },
            { name: 'Orman ', village: 'Iclod ' },
            { name: 'Nadăşu ', simpleName: 'Nadasu ', village: 'Izvoru Crisului ' },
            { name: 'Nearşova ', simpleName: 'Nearsova ', village: 'Izvoru Crisului ' },
            { name: 'Şaula ', simpleName: 'Saula ', village: 'Izvoru Crisului ' },
            { name: 'Codor ', village: 'Jichisu De Jos ' },
            { name: 'Jichişu de Sus ', simpleName: 'Jichisu de Sus ', village: 'Jichisu De Jos ' },
            { name: 'Tărpiu ', simpleName: 'Tarpiu ', village: 'Jichisu De Jos ' },
            { name: 'Şigău ', simpleName: 'Sigau ', village: 'Jichisu De Jos ' },
            { name: 'Gădălin ', simpleName: 'Gadalin ', village: 'Jucu ' },
            { name: 'Juc-Herghelie ', village: 'Jucu ' },
            { name: 'Jucu de Mijloc ', village: 'Jucu ' },
            { name: 'Jucu de Sus ', village: 'Jucu ' },
            { name: 'Vişea ', simpleName: 'Visea ', village: 'Jucu ' },
            { name: 'Gligoreşti ', simpleName: 'Gligoresti ', village: 'Luna ' },
            { name: 'Luncani ', village: 'Luna ' },
            { name: 'Muntele Rece ', village: 'Maguri-Racatau ' },
            { name: 'Măguri ', simpleName: 'Maguri ', village: 'Maguri-Racatau ' },
            { name: 'Ardeova ', village: 'Manastireni ' },
            { name: 'Bedeciu ', village: 'Manastireni ' },
            { name: 'Bica ', village: 'Manastireni ' },
            { name: 'Dretea ', village: 'Manastireni ' },
            { name: 'Mănăşturu Românesc ', simpleName: 'Manasturu Romanesc ', village: 'Manastireni ' },
            { name: 'Bociu ', village: 'Margau ' },
            { name: 'Buteni ', village: 'Margau ' },
            { name: 'Ciuleni ', village: 'Margau ' },
            { name: 'Răchiţele ', simpleName: 'Rachitele ', village: 'Margau ' },
            { name: 'Scrind-Frăsinet ', simpleName: 'Scrind-Frasinet ', village: 'Margau ' },
            { name: 'Dâmbu Mare ', simpleName: 'Dambu Mare ', village: 'Mica ' },
            { name: 'Mănăstirea ', simpleName: 'Manastirea ', village: 'Mica ' },
            { name: 'Nireş ', simpleName: 'Nires ', village: 'Mica ' },
            { name: 'Sânmărghita ', simpleName: 'Sanmarghita ', village: 'Mica ' },
            { name: 'Valea Cireşoii ', simpleName: 'Valea Ciresoii ', village: 'Mica ' },
            { name: 'Valea Luncii ', village: 'Mica ' },
            { name: 'Cheia ', village: 'Mihai Viteazu ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Mihai Viteazu ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ', village: 'Mintiu Gherlii ' },
            { name: 'Nima ', village: 'Mintiu Gherlii ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Mintiu Gherlii ' },
            { name: 'Pădurenii ', simpleName: 'Padurenii ', village: 'Mintiu Gherlii ' },
            { name: 'Salatiu ', village: 'Mintiu Gherlii ' },
            { name: 'Boteni ', village: 'Mociu ' },
            { name: 'Chesău ', simpleName: 'Chesau ', village: 'Mociu ' },
            { name: 'Crişeni ', simpleName: 'Criseni ', village: 'Mociu ' },
            { name: 'Falca ', village: 'Mociu ' },
            { name: 'Ghirişu Român ', simpleName: 'Ghirisu Roman ', village: 'Mociu ' },
            { name: 'Roşieni ', simpleName: 'Rosieni ', village: 'Mociu ' },
            { name: 'Turmaşi ', simpleName: 'Turmasi ', village: 'Mociu ' },
            { name: 'Zorenii de Vale ', village: 'Mociu ' },
            { name: 'Bădeni ', simpleName: 'Badeni ', village: 'Moldovenesti ' },
            { name: 'Pietroasa ', village: 'Moldovenesti ' },
            { name: 'Plăieşti ', simpleName: 'Plaiesti ', village: 'Moldovenesti ' },
            { name: 'Podeni ', village: 'Moldovenesti ' },
            { name: 'Stejeriş ', simpleName: 'Stejeris ', village: 'Moldovenesti ' },
            { name: 'Bucea ', village: 'Negreni ' },
            { name: 'Prelucele ', village: 'Negreni ' },
            { name: 'Băgaciu ', simpleName: 'Bagaciu ', village: 'Palatca ' },
            { name: 'Mureşenii de Câmpie ', simpleName: 'Muresenii de Campie ', village: 'Palatca ' },
            { name: 'Petea ', village: 'Palatca ' },
            { name: 'Sava ', village: 'Palatca ' },
            { name: 'Cubleşu Someşan ', simpleName: 'Cublesu Somesan ', village: 'Panticeu ' },
            { name: 'Cătălina ', simpleName: 'Catalina ', village: 'Panticeu ' },
            { name: 'Dârja ', simpleName: 'Darja ', village: 'Panticeu ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Panticeu ' },
            { name: 'Crăeşti ', simpleName: 'Craesti ', village: 'Petrestii De Jos ' },
            { name: 'Deleni ', village: 'Petrestii De Jos ' },
            { name: 'Livada ', village: 'Petrestii De Jos ' },
            { name: 'Petreştii de Mijloc ', simpleName: 'Petrestii de Mijloc ', village: 'Petrestii De Jos ' },
            { name: 'Petreştii de Sus ', simpleName: 'Petrestii de Sus ', village: 'Petrestii De Jos ' },
            { name: 'Plaiuri ', village: 'Petrestii De Jos ' },
            { name: 'Crairât ', simpleName: 'Crairat ', village: 'Ploscos ' },
            { name: 'Lobodaş ', simpleName: 'Lobodas ', village: 'Ploscos ' },
            { name: 'Valea Florilor ', village: 'Ploscos ' },
            { name: 'Bologa ', village: 'Poieni ' },
            { name: 'Cerbeşti ', simpleName: 'Cerbesti ', village: 'Poieni ' },
            { name: 'Hodişu ', simpleName: 'Hodisu ', village: 'Poieni ' },
            { name: 'Lunca Vişagului ', simpleName: 'Lunca Visagului ', village: 'Poieni ' },
            { name: 'Morlaca ', village: 'Poieni ' },
            { name: 'Tranişu ', simpleName: 'Tranisu ', village: 'Poieni ' },
            { name: 'Valea Drăganului ', simpleName: 'Valea Draganului ', village: 'Poieni ' },
            { name: 'Ciubanca ', village: 'Recea-Cristur ' },
            { name: 'Ciubăncuţa ', simpleName: 'Ciubancuta ', village: 'Recea-Cristur ' },
            { name: 'Căprioara ', simpleName: 'Caprioara ', village: 'Recea-Cristur ' },
            { name: 'Elciu ', village: 'Recea-Cristur ' },
            { name: 'Escu ', village: 'Recea-Cristur ' },
            { name: 'Jurca ', village: 'Recea-Cristur ' },
            { name: 'Osoi ', village: 'Recea-Cristur ' },
            { name: 'Pustuţa ', simpleName: 'Pustuta ', village: 'Recea-Cristur ' },
            { name: 'Dealu Mare ', village: 'Risca ' },
            { name: 'Lăpuşteşti ', simpleName: 'Lapustesti ', village: 'Risca ' },
            { name: 'Mărceşti ', simpleName: 'Marcesti ', village: 'Risca ' },
            { name: 'Rogojel ', village: 'Sacuieu ' },
            { name: 'Vişagu ', simpleName: 'Visagu ', village: 'Sacuieu ' },
            { name: 'Alunişu ', simpleName: 'Alunisu ', village: 'Sancraiu ' },
            { name: 'Brăişoru ', simpleName: 'Braisoru ', village: 'Sancraiu ' },
            { name: 'Domoşu ', simpleName: 'Domosu ', village: 'Sancraiu ' },
            { name: 'Horlacea ', village: 'Sancraiu ' },
            { name: 'Copăceni ', simpleName: 'Copaceni ', village: 'Sandulesti ' },
            { name: 'Ceaba ', village: 'Sanmartin ' },
            { name: 'Cutca ', village: 'Sanmartin ' },
            { name: 'Diviciorii Mari ', village: 'Sanmartin ' },
            { name: 'Diviciorii Mici ', village: 'Sanmartin ' },
            { name: 'Măhal ', simpleName: 'Mahal ', village: 'Sanmartin ' },
            { name: 'Sâmboieni ', simpleName: 'Samboieni ', village: 'Sanmartin ' },
            { name: 'Târguşor ', simpleName: 'Targusor ', village: 'Sanmartin ' },
            { name: 'Berindu ', village: 'Sanpaul ' },
            { name: 'Mihăieşti ', simpleName: 'Mihaiesti ', village: 'Sanpaul ' },
            { name: 'Sumurducu ', village: 'Sanpaul ' },
            { name: 'Topa Mică ', simpleName: 'Topa Mica ', village: 'Sanpaul ' },
            { name: 'Şardu ', simpleName: 'Sardu ', village: 'Sanpaul ' },
            { name: 'Finişel ', simpleName: 'Finisel ', village: 'Savadisla ' },
            { name: 'Hăşdate ', simpleName: 'Hasdate ', village: 'Savadisla ' },
            { name: 'Lita ', village: 'Savadisla ' },
            { name: 'Liteni ', village: 'Savadisla ' },
            { name: 'Stolna ', village: 'Savadisla ' },
            { name: 'Vlaha ', village: 'Savadisla ' },
            { name: 'Vălişoara ', simpleName: 'Valisoara ', village: 'Savadisla ' },
            { name: 'Aruncuta ', village: 'Suatu ' },
            { name: 'Dâmburile ', simpleName: 'Damburile ', village: 'Suatu ' },
            { name: 'Năsal ', simpleName: 'Nasal ', village: 'Taga ' },
            { name: 'Sântejude ', simpleName: 'Santejude ', village: 'Taga ' },
            { name: 'Sântejude Vale ', simpleName: 'Santejude Vale ', village: 'Taga ' },
            { name: 'Sântioana ', simpleName: 'Santioana ', village: 'Taga ' },
            { name: 'Clapa ', village: 'Tritenii De Jos ' },
            { name: 'Colonia ', village: 'Tritenii De Jos ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Tritenii De Jos ' },
            { name: 'Tritenii de Sus ', village: 'Tritenii De Jos ' },
            { name: 'Tritenii-Hotar ', village: 'Tritenii De Jos ' },
            { name: 'Ceanu Mic ', village: 'Tureni ' },
            { name: 'Comşeşti ', simpleName: 'Comsesti ', village: 'Tureni ' },
            { name: 'Miceşti ', simpleName: 'Micesti ', village: 'Tureni ' },
            { name: 'Mărtineşti ', simpleName: 'Martinesti ', village: 'Tureni ' },
            { name: 'Batin ', village: 'Unguras ' },
            { name: 'Daroţ ', simpleName: 'Darot ', village: 'Unguras ' },
            { name: 'Sicfa ', village: 'Unguras ' },
            { name: 'Valea Unguraşului ', simpleName: 'Valea Ungurasului ', village: 'Unguras ' },
            { name: 'Bogata de Jos ', village: 'Vad ' },
            { name: 'Bogata de Sus ', village: 'Vad ' },
            { name: 'Calna ', village: 'Vad ' },
            { name: 'Cetan ', village: 'Vad ' },
            { name: 'Curtuiuşu Dejului ', simpleName: 'Curtuiusu Dejului ', village: 'Vad ' },
            { name: 'Valea Groşilor ', simpleName: 'Valea Grosilor ', village: 'Vad ' },
            { name: 'Cerc ', village: 'Valea Ierii ' },
            { name: 'Plopi ', village: 'Valea Ierii ' },
            { name: 'Urca ', village: 'Viisoara ' },
            { name: 'Băbuţiu ', simpleName: 'Babutiu ', village: 'Vultureni ' },
            { name: 'Bădeşti ', simpleName: 'Badesti ', village: 'Vultureni ' },
            { name: 'Chidea ', village: 'Vultureni ' },
            { name: 'Făureni ', simpleName: 'Faureni ', village: 'Vultureni ' },
            {
                name: 'Şoimeni ', simpleName: 'Soimeni ', village: 'Vultureni '
            }
        ]
    },
    {
        auto: 'CT ',
        name: 'Constanţa ',
        cities: [
            { name: '23 August ' },
            { name: 'Adamclisi ' },
            { name: 'Agigea ' },
            { name: 'Albeşti ', simpleName: 'Albesti ' },
            { name: 'Aliman ' },
            { name: 'Amzacea ' },
            { name: 'Băneasa ', simpleName: 'Baneasa ' },
            { name: 'Bărăganu ', simpleName: 'Baraganu ' },
            { name: 'Castelu ' },
            { name: 'Cerchezu ' },
            { name: 'Cernavodă ', simpleName: 'Cernavoda ' },
            { name: 'Chirnogeni ' },
            { name: 'Ciobanu ' },
            { name: 'Ciocârlia ', simpleName: 'Ciocarlia ' },
            { name: 'Cobadin ' },
            { name: 'Cogealac ' },
            { name: 'Comana ' },
            { name: 'Constanţa ', simpleName: 'Constanta ' },
            { name: 'Corbu ' },
            { name: 'Costineşti ', simpleName: 'Costinesti ' },
            { name: 'Crucea ' },
            { name: 'Cumpăna ', simpleName: 'Cumpana ' },
            { name: 'Deleni ' },
            { name: 'Dobromir ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ' },
            { name: 'Ghindăreşti ', simpleName: 'Ghindaresti ' },
            { name: 'Gârliciu ', simpleName: 'Garliciu ' },
            { name: 'Horia ' },
            { name: 'Hârşova ', simpleName: 'Harsova ' },
            { name: 'Independenţa ', simpleName: 'Independenta ' },
            { name: 'Ion Corvin ' },
            { name: 'Istria ' },
            { name: 'Limanu ' },
            { name: 'Lipniţa ', simpleName: 'Lipnita ' },
            { name: 'Lumina ' },
            { name: 'Mangalia ' },
            { name: 'Medgidia ' },
            { name: 'Mereni ' },
            { name: 'Mihai Viteazu ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ' },
            { name: 'Mircea Vodă ', simpleName: 'Mircea Voda ' },
            { name: 'Negru Vodă ', simpleName: 'Negru Voda ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ' },
            { name: 'Năvodari ', simpleName: 'Navodari ' },
            { name: 'Oltina ' },
            { name: 'Ostrov ' },
            { name: 'Ovidiu ' },
            { name: 'Pantelimon ' },
            { name: 'Pecineaga ' },
            { name: 'Peştera ', simpleName: 'Pestera ' },
            { name: 'Poarta Albă ', simpleName: 'Poarta Alba ' },
            { name: 'Rasova ' },
            { name: 'Saraiu ' },
            { name: 'Seimeni ' },
            { name: 'Siliştea ', simpleName: 'Silistea ' },
            { name: 'Săcele ', simpleName: 'Sacele ' },
            { name: 'Techirghiol ' },
            { name: 'Topalu ' },
            { name: 'Topraisar ' },
            { name: 'Tortoman ' },
            { name: 'Tuzla ' },
            { name: 'Târguşor ', simpleName: 'Targusor ' },
            { name: 'Valu lui Traian ' },
            { name: 'Vulturu ' },
            { name: 'Dulceşti ', simpleName: 'Dulcesti ', village: '23 August ' },
            { name: 'Moşneni ', simpleName: 'Mosneni ', village: '23 August ' },
            { name: 'Abrud ', village: 'Adamclisi ' },
            { name: 'Haţeg ', simpleName: 'Hateg ', village: 'Adamclisi ' },
            { name: 'Urluia ', village: 'Adamclisi ' },
            { name: 'Zorile ', village: 'Adamclisi ' },
            { name: 'Lazu ', village: 'Agigea ' },
            { name: 'Sanatoriul Agigea ', village: 'Agigea ' },
            { name: 'Staţiunea Zoologică Marină Agigea ', simpleName: 'Statiunea Zoologica Marina Agigea ', village: 'Agigea ' },
            { name: 'Arsa ', village: 'Albesti ' },
            { name: 'Coroana ', village: 'Albesti ' },
            { name: 'Cotu Văii ', simpleName: 'Cotu Vaii ', village: 'Albesti ' },
            { name: 'Vârtop ', simpleName: 'Vartop ', village: 'Albesti ' },
            { name: 'Dunăreni ', simpleName: 'Dunareni ', village: 'Aliman ' },
            { name: 'Floriile ', village: 'Aliman ' },
            { name: 'Vlahii ', village: 'Aliman ' },
            { name: 'Casicea ', village: 'Amzacea ' },
            { name: 'General Scărişoreanu ', simpleName: 'General Scarisoreanu ', village: 'Amzacea ' },
            { name: 'Făurei ', simpleName: 'Faurei ', village: 'Baneasa ' },
            { name: 'Negureni ', village: 'Baneasa ' },
            { name: 'Tudor Vladimirescu ', village: 'Baneasa ' },
            { name: 'Lanurile ', village: 'Baraganu ' },
            { name: 'Murfatlar ', village: 'Basarabi ' },
            { name: 'Siminoc ', village: 'Basarabi ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Castelu ' },
            { name: 'Nisipari ', village: 'Castelu ' },
            { name: 'Căscioarele ', simpleName: 'Cascioarele ', village: 'Cerchezu ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Cerchezu ' },
            { name: 'Viroaga ', village: 'Cerchezu ' },
            { name: 'Credinţa ', simpleName: 'Credinta ', village: 'Chirnogeni ' },
            { name: 'Plopeni ', village: 'Chirnogeni ' },
            { name: 'Mioriţa ', simpleName: 'Miorita ', village: 'Ciobanu ' },
            { name: 'Ciocârlia de Sus ', simpleName: 'Ciocarlia de Sus ', village: 'Ciocarlia ' },
            { name: 'Conacu ', village: 'Cobadin ' },
            { name: 'Curcani ', village: 'Cobadin ' },
            { name: 'Negreşti ', simpleName: 'Negresti ', village: 'Cobadin ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Cobadin ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Cogealac ' },
            { name: 'Gura Dobrogei ', village: 'Cogealac ' },
            { name: 'Râmnicu de Jos ', simpleName: 'Ramnicu de Jos ', village: 'Cogealac ' },
            { name: 'Râmnicu de Sus ', simpleName: 'Ramnicu de Sus ', village: 'Cogealac ' },
            { name: 'Tariverde ', village: 'Cogealac ' },
            { name: 'Pelinu ', village: 'Comana ' },
            { name: 'Tătaru ', simpleName: 'Tataru ', village: 'Comana ' },
            { name: 'Mamaia ', village: 'Constanta ' },
            { name: 'Palazu Mare ', village: 'Constanta ' },
            { name: 'Luminiţa ', simpleName: 'Luminita ', village: 'Corbu ' },
            { name: 'Vadu ', village: 'Corbu ' },
            { name: 'Schitu ', village: 'Costinesti ' },
            { name: 'Băltăgeşti ', simpleName: 'Baltagesti ', village: 'Crucea ' },
            { name: 'Crişan ', simpleName: 'Crisan ', village: 'Crucea ' },
            { name: 'Gălbiori ', simpleName: 'Galbiori ', village: 'Crucea ' },
            { name: 'Stupina ', village: 'Crucea ' },
            { name: 'Şiriu ', simpleName: 'Siriu ', village: 'Crucea ' },
            { name: 'Straja ', village: 'Cumpana ' },
            { name: 'Petroşani ', simpleName: 'Petrosani ', village: 'Deleni ' },
            { name: 'Pietreni ', village: 'Deleni ' },
            { name: 'Şipotele ', simpleName: 'Sipotele ', village: 'Deleni ' },
            { name: 'Cetatea ', village: 'Dobromir ' },
            { name: 'Dobromiru din Deal ', village: 'Dobromir ' },
            { name: 'Lespezi ', village: 'Dobromir ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Dobromir ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Dobromir ' },
            { name: 'Furnica ', village: 'Dumbraveni ' },
            { name: 'Eforie Nord ', village: 'Eforie ' },
            { name: 'Eforie Sud ', village: 'Eforie ' },
            { name: 'Vadu Oii ', village: 'Harsova ' },
            { name: 'Cloşca ', simpleName: 'Closca ', village: 'Horia ' },
            { name: 'Tichileşti ', simpleName: 'Tichilesti ', village: 'Horia ' },
            { name: 'Fântâna Mare ', simpleName: 'Fantana Mare ', village: 'Independenta ' },
            { name: 'Movila Verde ', village: 'Independenta ' },
            { name: 'Olteni ', village: 'Independenta ' },
            { name: 'Tufani ', village: 'Independenta ' },
            { name: 'Brebeni ', village: 'Ion Corvin ' },
            { name: 'Crângu ', simpleName: 'Crangu ', village: 'Ion Corvin ' },
            { name: 'Rariştea ', simpleName: 'Raristea ', village: 'Ion Corvin ' },
            { name: 'Viile ', village: 'Ion Corvin ' },
            { name: 'Nuntaşi ', simpleName: 'Nuntasi ', village: 'Istria ' },
            { name: '2 Mai ', village: 'Limanu ' },
            { name: 'Hagieni ', village: 'Limanu ' },
            { name: 'Vama Veche ', village: 'Limanu ' },
            { name: 'Canlia ', village: 'Lipnita ' },
            { name: 'Carvăn ', simpleName: 'Carvan ', village: 'Lipnita ' },
            { name: 'Coslugea ', village: 'Lipnita ' },
            { name: 'Cuiugiuc ', village: 'Lipnita ' },
            { name: 'Goruni ', village: 'Lipnita ' },
            { name: 'Izvoarele ', village: 'Lipnita ' },
            { name: 'Oituz ', village: 'Lumina ' },
            { name: 'Sibioara ', village: 'Lumina ' },
            { name: 'Cap Aurora ', village: 'Mangalia ' },
            { name: 'Jupiter ', village: 'Mangalia ' },
            { name: 'Neptun ', village: 'Mangalia ' },
            { name: 'Olimp ', village: 'Mangalia ' },
            { name: 'Saturn ', village: 'Mangalia ' },
            { name: 'Venus ', village: 'Mangalia ' },
            { name: 'Remus Opreanu ', village: 'Medgidia ' },
            { name: 'Valea Dacilor ', village: 'Medgidia ' },
            { name: 'Ciobăniţa ', simpleName: 'Ciobanita ', village: 'Mereni ' },
            { name: 'Miriştea ', simpleName: 'Miristea ', village: 'Mereni ' },
            { name: 'Osmancea ', village: 'Mereni ' },
            { name: 'Sinoie ', village: 'Mihai Viteazu ' },
            { name: 'Palazu Mic ', village: 'Mihail Kogalniceanu ' },
            { name: 'Piatra ', village: 'Mihail Kogalniceanu ' },
            { name: 'Făclia ', simpleName: 'Faclia ', village: 'Mircea Voda ' },
            { name: 'Gherghina ', village: 'Mircea Voda ' },
            { name: 'Saligny ', village: 'Mircea Voda ' },
            { name: 'Satu Nou ', village: 'Mircea Voda ' },
            { name: 'Ştefan cel Mare ', simpleName: 'Stefan cel Mare ', village: 'Mircea Voda ' },
            { name: 'Ţibrinu ', simpleName: 'Tibrinu ', village: 'Mircea Voda ' },
            { name: 'Mamaia-Sat ', village: 'Navodari ' },
            { name: 'Darabani ', village: 'Negru Voda ' },
            { name: 'Grăniceru ', simpleName: 'Graniceru ', village: 'Negru Voda ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Negru Voda ' },
            { name: 'Dorobanţu ', simpleName: 'Dorobantu ', village: 'Nicolae Balcescu ' },
            { name: 'Răzoarele ', simpleName: 'Razoarele ', village: 'Oltina ' },
            { name: 'Satu Nou ', village: 'Oltina ' },
            { name: 'Strunga ', village: 'Oltina ' },
            { name: 'Almălău ', simpleName: 'Almalau ', village: 'Ostrov ' },
            { name: 'Bugeac ', village: 'Ostrov ' },
            { name: 'Esechioi ', village: 'Ostrov ' },
            { name: 'Galiţa ', simpleName: 'Galita ', village: 'Ostrov ' },
            { name: 'Gârliţa ', simpleName: 'Garlita ', village: 'Ostrov ' },
            { name: 'Poiana ', village: 'Ovidiu ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Pantelimon ' },
            { name: 'Nistoreşti ', simpleName: 'Nistoresti ', village: 'Pantelimon ' },
            { name: 'Pantelimon de Jos ', village: 'Pantelimon ' },
            { name: 'Runcu ', village: 'Pantelimon ' },
            { name: 'Vânători ', simpleName: 'Vanatori ', village: 'Pecineaga ' },
            { name: 'Ivrinezu Mare ', village: 'Pestera ' },
            { name: 'Ivrinezu Mic ', village: 'Pestera ' },
            { name: 'Izvoru Mare ', village: 'Pestera ' },
            { name: 'Veteranu ', village: 'Pestera ' },
            { name: 'Nazarcea ', village: 'Poarta Alba ' },
            { name: 'Cochirleni ', village: 'Rasova ' },
            { name: 'Traian ', village: 'Sacele ' },
            { name: 'Dulgheru ', village: 'Saraiu ' },
            { name: 'Stejaru ', village: 'Saraiu ' },
            { name: 'Dunărea ', simpleName: 'Dunarea ', village: 'Seimeni ' },
            { name: 'Seimenii Mici ', village: 'Seimeni ' },
            { name: 'Tepeş Vodă ', simpleName: 'Tepes Voda ', village: 'Silistea ' },
            { name: 'Casian ', village: 'Targusor ' },
            { name: 'Cheia ', village: 'Targusor ' },
            { name: 'Grădina ', simpleName: 'Gradina ', village: 'Targusor ' },
            { name: 'Mireasa ', village: 'Targusor ' },
            { name: 'Capidava ', village: 'Topalu ' },
            { name: 'Biruinţa ', simpleName: 'Biruinta ', village: 'Topraisar ' },
            { name: 'Moviliţa ', simpleName: 'Movilita ', village: 'Topraisar ' },
            { name: 'Potârnichea ', simpleName: 'Potarnichea ', village: 'Topraisar ' },
            {
                name: 'Dropia ', village: 'Tortoman '
            }
        ]
    },
    {
        auto: 'CV ',
        name: 'Covasna ',
        cities: [
            { name: 'Aita Mare ' },
            { name: 'Baraolt ' },
            { name: 'Barcani ' },
            { name: 'Belin ' },
            { name: 'Bodoc ' },
            { name: 'Boroşneu Mare ', simpleName: 'Borosneu Mare ' },
            { name: 'Brateş ', simpleName: 'Brates ' },
            { name: 'Breţcu ', simpleName: 'Bretcu ' },
            { name: 'Brăduţ ', simpleName: 'Bradut ' },
            { name: 'Catalina ' },
            { name: 'Cernat ' },
            { name: 'Chichiş ', simpleName: 'Chichis ' },
            { name: 'Comandău ', simpleName: 'Comandau ' },
            { name: 'Covasna ' },
            { name: 'Dobârlău ', simpleName: 'Dobarlau ' },
            { name: 'Ghelinţa ', simpleName: 'Ghelinta ' },
            { name: 'Ghidfalău ', simpleName: 'Ghidfalau ' },
            { name: 'Hăghig ', simpleName: 'Haghig ' },
            { name: 'Ilieni ' },
            { name: 'Lemnia ' },
            { name: 'Malnaş ', simpleName: 'Malnas ' },
            { name: 'Moacşa ', simpleName: 'Moacsa ' },
            { name: 'Ojdula ' },
            { name: 'Ozun ' },
            { name: 'Poian ' },
            { name: 'Reci ' },
            { name: 'Sita Buzăului ', simpleName: 'Sita Buzaului ' },
            { name: 'Sânzieni ', simpleName: 'Sanzieni ' },
            { name: 'Turia ' },
            { name: 'Valea Crişului ', simpleName: 'Valea Crisului ' },
            { name: 'Valea Mare ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ' },
            { name: 'Vârghiş ', simpleName: 'Varghis ' },
            { name: 'Zagon ' },
            { name: 'Zăbala ', simpleName: 'Zabala ' },
            { name: 'Întorsura Buzăului ', simpleName: 'Intorsura Buzaului ' },
            { name: 'Aita Medie ', village: 'Aita Mare ' },
            { name: 'Biborţeni ', simpleName: 'Biborteni ', village: 'Baraolt ' },
            { name: 'Bodoş ', simpleName: 'Bodos ', village: 'Baraolt ' },
            { name: 'Căpeni ', simpleName: 'Capeni ', village: 'Baraolt ' },
            { name: 'Micloşoara ', simpleName: 'Miclosoara ', village: 'Baraolt ' },
            { name: 'Racoşul de Sus ', simpleName: 'Racosul de Sus ', village: 'Baraolt ' },
            { name: 'Lădăuţi ', simpleName: 'Ladauti ', village: 'Barcani ' },
            { name: 'Sărămăş ', simpleName: 'Saramas ', village: 'Barcani ' },
            { name: 'Aita Seacă ', simpleName: 'Aita Seaca ', village: 'Batani ' },
            { name: 'Băţanii Mari ', simpleName: 'Batanii Mari ', village: 'Batani ' },
            { name: 'Băţanii Mici ', simpleName: 'Batanii Mici ', village: 'Batani ' },
            { name: 'Herculian ', village: 'Batani ' },
            { name: 'Ozunca-Băi ', simpleName: 'Ozunca-Bai ', village: 'Batani ' },
            { name: 'Belin-Vale ', village: 'Belin ' },
            { name: 'Olteni ', village: 'Bodoc ' },
            { name: 'Zălan ', simpleName: 'Zalan ', village: 'Bodoc ' },
            { name: 'Boroşneu Mic ', simpleName: 'Borosneu Mic ', village: 'Borosneu Mare ' },
            { name: 'Dobolii de Sus ', village: 'Borosneu Mare ' },
            { name: 'Leţ ', simpleName: 'Let ', village: 'Borosneu Mare ' },
            { name: 'Valea Mică ', simpleName: 'Valea Mica ', village: 'Borosneu Mare ' },
            { name: 'Ţufalău ', simpleName: 'Tufalau ', village: 'Borosneu Mare ' },
            { name: 'Doboşeni ', simpleName: 'Doboseni ', village: 'Bradut ' },
            { name: 'Filia ', village: 'Bradut ' },
            { name: 'Tălişoara ', simpleName: 'Talisoara ', village: 'Bradut ' },
            { name: 'Pachia ', village: 'Brates ' },
            { name: 'Telechia ', village: 'Brates ' },
            { name: 'Mărtănuş ', simpleName: 'Martanus ', village: 'Bretcu ' },
            { name: 'Oituz ', village: 'Bretcu ' },
            { name: 'Hătuica ', simpleName: 'Hatuica ', village: 'Catalina ' },
            { name: 'Imeni ', village: 'Catalina ' },
            { name: 'Mărcuşa ', simpleName: 'Marcusa ', village: 'Catalina ' },
            { name: 'Mărtineni ', simpleName: 'Martineni ', village: 'Catalina ' },
            { name: 'Albiş ', simpleName: 'Albis ', village: 'Cernat ' },
            { name: 'Icafalău ', simpleName: 'Icafalau ', village: 'Cernat ' },
            { name: 'Băcel ', simpleName: 'Bacel ', village: 'Chichis ' },
            { name: 'Chiuruş ', simpleName: 'Chiurus ', village: 'Covasna ' },
            { name: 'Lunca Mărcuşului ', simpleName: 'Lunca Marcusului ', village: 'Dobarlau ' },
            { name: 'Mărcuş ', simpleName: 'Marcus ', village: 'Dobarlau ' },
            { name: 'Valea Dobârlăului ', simpleName: 'Valea Dobarlaului ', village: 'Dobarlau ' },
            { name: 'Harale ', village: 'Ghelinta ' },
            { name: 'Angheluş ', simpleName: 'Anghelus ', village: 'Ghidfalau ' },
            { name: 'Fotoş ', simpleName: 'Fotos ', village: 'Ghidfalau ' },
            { name: 'Zoltan ', village: 'Ghidfalau ' },
            { name: 'Iarăş ', simpleName: 'Iaras ', village: 'Haghig ' },
            { name: 'Dobolii de Jos ', village: 'Ilieni ' },
            { name: 'Sâncraiu ', simpleName: 'Sancraiu ', village: 'Ilieni ' },
            { name: 'Brădet ', simpleName: 'Bradet ', village: 'Intorsura Buzaului ' },
            { name: 'Floroaia ', village: 'Intorsura Buzaului ' },
            { name: 'Scrădoasa ', simpleName: 'Scradoasa ', village: 'Intorsura Buzaului ' },
            { name: 'Lutoasa ', village: 'Lemnia ' },
            { name: 'Mereni ', village: 'Lemnia ' },
            { name: 'Bixad ', village: 'Malnas ' },
            { name: 'Malnaş-Băi ', simpleName: 'Malnas-Bai ', village: 'Malnas ' },
            { name: 'Micfalău ', simpleName: 'Micfalau ', village: 'Malnas ' },
            { name: 'Valea Zălanului ', simpleName: 'Valea Zalanului ', village: 'Malnas ' },
            { name: 'Dalnic ', village: 'Moacsa ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Moacsa ' },
            { name: 'Hilib ', village: 'Ojdula ' },
            { name: 'Bicfalău ', simpleName: 'Bicfalau ', village: 'Ozun ' },
            { name: 'Lisnău ', simpleName: 'Lisnau ', village: 'Ozun ' },
            { name: 'Lisnău-Vale ', simpleName: 'Lisnau-Vale ', village: 'Ozun ' },
            { name: 'Lunca Ozunului ', village: 'Ozun ' },
            { name: 'Măgheruş ', simpleName: 'Magherus ', village: 'Ozun ' },
            { name: 'Sântionlunca ', simpleName: 'Santionlunca ', village: 'Ozun ' },
            { name: 'Belani ', village: 'Poian ' },
            { name: 'Cărpinenii ', simpleName: 'Carpinenii ', village: 'Poian ' },
            { name: 'Estelnic ', village: 'Poian ' },
            { name: 'Valea Scurtă ', simpleName: 'Valea Scurta ', village: 'Poian ' },
            { name: 'Aninoasa ', village: 'Reci ' },
            { name: 'Bita ', village: 'Reci ' },
            { name: 'Saciova ', village: 'Reci ' },
            { name: 'Caşinu Mic ', simpleName: 'Casinu Mic ', village: 'Sanzieni ' },
            { name: 'Petriceni ', village: 'Sanzieni ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Sanzieni ' },
            { name: 'Chilieni ', village: 'Sfantul Gheorghe ' },
            { name: 'Coşeni ', simpleName: 'Coseni ', village: 'Sfantul Gheorghe ' },
            { name: 'Sfântu  Gheorghe ', simpleName: 'Sfantu  Gheorghe ', village: 'Sfantul Gheorghe ' },
            { name: 'Crasna ', village: 'Sita Buzaului ' },
            { name: 'Merişor ', simpleName: 'Merisor ', village: 'Sita Buzaului ' },
            { name: 'Zăbrătău ', simpleName: 'Zabratau ', village: 'Sita Buzaului ' },
            { name: 'Lunga ', village: 'Tirgu Secuiesc ' },
            { name: 'Târgu Secuiesc ', simpleName: 'Targu Secuiesc ', village: 'Tirgu Secuiesc ' },
            { name: 'Alungeni ', village: 'Turia ' },
            { name: 'Araci ', village: 'Valcele ' },
            { name: 'Ariuşd ', simpleName: 'Ariusd ', village: 'Valcele ' },
            { name: 'Hetea ', village: 'Valcele ' },
            { name: 'Arcuş ', simpleName: 'Arcus ', village: 'Valea Crisului ' },
            { name: 'Calnic ', village: 'Valea Crisului ' },
            { name: 'Peteni ', village: 'Zabala ' },
            { name: 'Surcea ', village: 'Zabala ' },
            { name: 'Tamaşfalău ', simpleName: 'Tamasfalau ', village: 'Zabala ' },
            {
                name: 'Păpăuţi ', simpleName: 'Papauti ', village: 'Zagon '
            }
        ]
    },
    {
        auto: 'DB ',
        name: 'Dâmboviţa ',
        cities: [
            { name: 'Aninoasa ' },
            { name: 'Bezdead ' },
            { name: 'Bilciureşti ', simpleName: 'Bilciuresti ' },
            { name: 'Braniştea ', simpleName: 'Branistea ' },
            { name: 'Brezoaele ' },
            { name: 'Brăneşti ', simpleName: 'Branesti ' },
            { name: 'Buciumeni ' },
            { name: 'Bucşani ', simpleName: 'Bucsani ' },
            { name: 'Butimanu ' },
            { name: 'Bărbuleţu ', simpleName: 'Barbuletu ' },
            { name: 'Ciocăneşti ', simpleName: 'Ciocanesti ' },
            { name: 'Cojasca ' },
            { name: 'Comişani ', simpleName: 'Comisani ' },
            { name: 'Conţeşti ', simpleName: 'Contesti ' },
            { name: 'Corbii Mari ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ' },
            { name: 'Cornăţelu ', simpleName: 'Cornatelu ' },
            { name: 'Costeştii din Vale ', simpleName: 'Costestii din Vale ' },
            { name: 'Crevedia ' },
            { name: 'Dobra ' },
            { name: 'Doiceşti ', simpleName: 'Doicesti ' },
            { name: 'Dragodana ' },
            { name: 'Dragomireşti ', simpleName: 'Dragomiresti ' },
            { name: 'Dărmăneşti ', simpleName: 'Darmanesti ' },
            { name: 'Fieni ' },
            { name: 'Glodeni ' },
            { name: 'Gura Foii ' },
            { name: 'Gura Ocniţei ', simpleName: 'Gura Ocnitei ' },
            { name: 'Gura Şuţii ', simpleName: 'Gura Sutii ' },
            { name: 'Găeşti ', simpleName: 'Gaesti ' },
            { name: 'Hulubeşti ', simpleName: 'Hulubesti ' },
            { name: 'I. L. Caragiale ' },
            { name: 'Lucieni ' },
            { name: 'Ludeşti ', simpleName: 'Ludesti ' },
            { name: 'Lunguleţu ', simpleName: 'Lunguletu ' },
            { name: 'Malu cu Flori ' },
            { name: 'Mogoşani ', simpleName: 'Mogosani ' },
            { name: 'Moreni ' },
            { name: 'Moroeni ' },
            { name: 'Morteni ' },
            { name: 'Moţăieni ', simpleName: 'Motaieni ' },
            { name: 'Măneşti ', simpleName: 'Manesti ' },
            { name: 'Mătăsaru ', simpleName: 'Matasaru ' },
            { name: 'Niculeşti ', simpleName: 'Niculesti ' },
            { name: 'Nucet ' },
            { name: 'Ocniţa ', simpleName: 'Ocnita ' },
            { name: 'Odobeşti ', simpleName: 'Odobesti ' },
            { name: 'Petreşti ', simpleName: 'Petresti ' },
            { name: 'Pietroşiţa ', simpleName: 'Pietrosita ' },
            { name: 'Poiana ' },
            { name: 'Potlogi ' },
            { name: 'Produleşti ', simpleName: 'Produlesti ' },
            { name: 'Pucheni ' },
            { name: 'Pucioasa ' },
            { name: 'Runcu ' },
            { name: 'Răcari ', simpleName: 'Racari ' },
            { name: 'Răzvad ', simpleName: 'Razvad ' },
            { name: 'Slobozia Moară ', simpleName: 'Slobozia Moara ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ' },
            { name: 'Titu ' },
            { name: 'Târgovişte ', simpleName: 'Targoviste ' },
            { name: 'Tărtăşeşti ', simpleName: 'Tartasesti ' },
            { name: 'Tătărani ', simpleName: 'Tatarani ' },
            { name: 'Ulieşti ', simpleName: 'Uliesti ' },
            { name: 'Ulmi ' },
            { name: 'Valea Mare ' },
            { name: 'Vişina ', simpleName: 'Visina ' },
            { name: 'Vişineşti ', simpleName: 'Visinesti ' },
            { name: 'Vlădeni ', simpleName: 'Vladeni ' },
            { name: 'Voineşti ', simpleName: 'Voinesti ' },
            { name: 'Vulcana-Băi ', simpleName: 'Vulcana-Bai ' },
            { name: 'Vulcana-Pandele ' },
            { name: 'Văcăreşti ', simpleName: 'Vacaresti ' },
            { name: 'Şelaru ', simpleName: 'Selaru ' },
            { name: 'Şotânga ', simpleName: 'Sotanga ' },
            { name: 'Săteni ', simpleName: 'Sateni ', village: 'Aninoasa ' },
            { name: 'Viforâta ', simpleName: 'Viforata ', village: 'Aninoasa ' },
            { name: 'Băleni-Români ', simpleName: 'Baleni-Romani ', village: 'Baleni ' },
            { name: 'Băleni-Sârbi ', simpleName: 'Baleni-Sarbi ', village: 'Baleni ' },
            { name: 'Aluniş ', simpleName: 'Alunis ', village: 'Barbuletu ' },
            { name: 'Cetăţuia ', simpleName: 'Cetatuia ', village: 'Barbuletu ' },
            { name: 'După Deal ', simpleName: 'Dupa Deal ', village: 'Barbuletu ' },
            { name: 'Gura Bărbuleţului ', simpleName: 'Gura Barbuletului ', village: 'Barbuletu ' },
            { name: 'Pietrari ', village: 'Barbuletu ' },
            { name: 'Râu Alb de Jos ', simpleName: 'Rau Alb de Jos ', village: 'Barbuletu ' },
            { name: 'Râu Alb de Sus ', simpleName: 'Rau Alb de Sus ', village: 'Barbuletu ' },
            { name: 'Valea ', village: 'Barbuletu ' },
            { name: 'Şipot ', simpleName: 'Sipot ', village: 'Barbuletu ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Bezdead ' },
            { name: 'Costişata ', simpleName: 'Costisata ', village: 'Bezdead ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Bezdead ' },
            { name: 'Tunari ', village: 'Bezdead ' },
            { name: 'Valea Morii ', village: 'Bezdead ' },
            { name: 'Suseni Socetu ', village: 'Bilciuresti ' },
            { name: 'Priboiu ', village: 'Branesti ' },
            { name: 'Dâmbovicioara ', simpleName: 'Dambovicioara ', village: 'Branistea ' },
            { name: 'Săveşti ', simpleName: 'Savesti ', village: 'Branistea ' },
            { name: 'Brezoaia ', village: 'Brezoaele ' },
            { name: 'Dealu Mare ', village: 'Buciumeni ' },
            { name: 'Valea Leurzii ', village: 'Buciumeni ' },
            { name: 'Hăbeni ', simpleName: 'Habeni ', village: 'Bucsani ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ', village: 'Bucsani ' },
            { name: 'Răţoaia ', simpleName: 'Ratoaia ', village: 'Bucsani ' },
            { name: 'Bărbuceanu ', simpleName: 'Barbuceanu ', village: 'Butimanu ' },
            { name: 'Lucianca ', village: 'Butimanu ' },
            { name: 'Ungureni ', village: 'Butimanu ' },
            { name: 'Aninoşani ', simpleName: 'Aninosani ', village: 'Candesti ' },
            { name: 'Cândeşti Deal ', simpleName: 'Candesti Deal ', village: 'Candesti ' },
            { name: 'Cândeşti-Vale ', simpleName: 'Candesti-Vale ', village: 'Candesti ' },
            { name: 'Dragodăneşti ', simpleName: 'Dragodanesti ', village: 'Candesti ' },
            { name: 'Valea Mare ', village: 'Candesti ' },
            { name: 'Creţu ', simpleName: 'Cretu ', village: 'Ciocanesti ' },
            { name: 'Decindea ', village: 'Ciocanesti ' },
            { name: 'Urziceanca ', village: 'Ciocanesti ' },
            { name: 'Vizureşti ', simpleName: 'Vizuresti ', village: 'Ciocanesti ' },
            { name: 'Blidari ', village: 'Cobia ' },
            { name: 'Cobiuţa ', simpleName: 'Cobiuta ', village: 'Cobia ' },
            { name: 'Crăciuneşti ', simpleName: 'Craciunesti ', village: 'Cobia ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Cobia ' },
            { name: 'Căpşuna ', simpleName: 'Capsuna ', village: 'Cobia ' },
            { name: 'Frasin Deal ', village: 'Cobia ' },
            { name: 'Frasin Vale ', village: 'Cobia ' },
            { name: 'Gherghiţeşti ', simpleName: 'Gherghitesti ', village: 'Cobia ' },
            { name: 'Mislea ', village: 'Cobia ' },
            { name: 'Mănăstirea ', simpleName: 'Manastirea ', village: 'Cobia ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Cojasca ' },
            { name: 'Iazu ', village: 'Cojasca ' },
            { name: 'Lazuri ', village: 'Comisani ' },
            { name: 'Boteni ', village: 'Contesti ' },
            { name: 'Bălteni ', simpleName: 'Balteni ', village: 'Contesti ' },
            { name: 'Crângaşi ', simpleName: 'Crangasi ', village: 'Contesti ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Contesti ' },
            { name: 'Gămăneşti ', simpleName: 'Gamanesti ', village: 'Contesti ' },
            { name: 'Heleşteu ', simpleName: 'Helesteu ', village: 'Contesti ' },
            { name: 'Mereni ', village: 'Contesti ' },
            { name: 'Bărăceni ', simpleName: 'Baraceni ', village: 'Corbii Mari ' },
            { name: 'Grozăveşti ', simpleName: 'Grozavesti ', village: 'Corbii Mari ' },
            { name: 'Moara din Groapă ', simpleName: 'Moara din Groapa ', village: 'Corbii Mari ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Corbii Mari ' },
            { name: 'Podu Corbencii ', village: 'Corbii Mari ' },
            { name: 'Satu Nou ', village: 'Corbii Mari ' },
            { name: 'Ungureni ', village: 'Corbii Mari ' },
            { name: 'Vadu Stanchii ', village: 'Corbii Mari ' },
            { name: 'Alunişu ', simpleName: 'Alunisu ', village: 'Cornatelu ' },
            { name: 'Bolovani ', village: 'Cornatelu ' },
            { name: 'Corni ', village: 'Cornatelu ' },
            { name: 'Slobozia ', village: 'Cornatelu ' },
            { name: 'Bujoreanca ', village: 'Cornesti ' },
            { name: 'Cristeasca ', village: 'Cornesti ' },
            { name: 'Crivăţu ', simpleName: 'Crivatu ', village: 'Cornesti ' },
            { name: 'Cătunu ', simpleName: 'Catunu ', village: 'Cornesti ' },
            { name: 'Frasinu ', village: 'Cornesti ' },
            { name: 'Hodărăşti ', simpleName: 'Hodarasti ', village: 'Cornesti ' },
            { name: 'Ibrianu ', village: 'Cornesti ' },
            { name: 'Postârnacu ', simpleName: 'Postarnacu ', village: 'Cornesti ' },
            { name: 'Ungureni ', village: 'Cornesti ' },
            { name: 'Mărunţişu ', simpleName: 'Maruntisu ', village: 'Costestii Din Vale ' },
            { name: 'Tomşani ', simpleName: 'Tomsani ', village: 'Costestii Din Vale ' },
            { name: 'Băduleşti ', simpleName: 'Badulesti ', village: 'Crangurile ' },
            { name: 'Crângurile de Jos ', simpleName: 'Crangurile de Jos ', village: 'Crangurile ' },
            { name: 'Crângurile de Sus ', simpleName: 'Crangurile de Sus ', village: 'Crangurile ' },
            { name: 'Potlogeni Vale ', village: 'Crangurile ' },
            { name: 'Pătroaia Deal ', simpleName: 'Patroaia Deal ', village: 'Crangurile ' },
            { name: 'Pătroaia Vale ', simpleName: 'Patroaia Vale ', village: 'Crangurile ' },
            { name: 'Răţeşti ', simpleName: 'Ratesti ', village: 'Crangurile ' },
            { name: 'Voia ', village: 'Crangurile ' },
            { name: 'Cocani ', village: 'Crevedia ' },
            { name: 'Dârza ', simpleName: 'Darza ', village: 'Crevedia ' },
            { name: 'Mânăstirea ', simpleName: 'Manastirea ', village: 'Crevedia ' },
            { name: 'Samurcaşi ', simpleName: 'Samurcasi ', village: 'Crevedia ' },
            { name: 'Mărginenii de Sus ', simpleName: 'Marginenii de Sus ', village: 'Darmanesti ' },
            { name: 'Mărceşti ', simpleName: 'Marcesti ', village: 'Dobra ' },
            { name: 'Boboci ', village: 'Dragodana ' },
            { name: 'Burduca ', village: 'Dragodana ' },
            { name: 'Cuparu ', village: 'Dragodana ' },
            { name: 'Picior de Munte ', village: 'Dragodana ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Dragodana ' },
            { name: 'Străoşti ', simpleName: 'Straosti ', village: 'Dragodana ' },
            { name: 'Decindeni ', village: 'Dragomiresti ' },
            { name: 'Geangoeşti ', simpleName: 'Geangoesti ', village: 'Dragomiresti ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ', village: 'Dragomiresti ' },
            { name: 'Râncăciov ', simpleName: 'Rancaciov ', village: 'Dragomiresti ' },
            { name: 'Ungureni ', village: 'Dragomiresti ' },
            { name: 'Berevoeşti ', simpleName: 'Berevoesti ', village: 'Fieni ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Fieni ' },
            { name: 'Bechineşti ', simpleName: 'Bechinesti ', village: 'Finta ' },
            { name: 'Finta Mare ', village: 'Finta ' },
            { name: 'Finta Veche ', village: 'Finta ' },
            { name: 'Gheboaia ', village: 'Finta ' },
            { name: 'Guşoiu ', simpleName: 'Gusoiu ', village: 'Glodeni ' },
            { name: 'Livezile ', village: 'Glodeni ' },
            { name: 'Lăculeţe ', simpleName: 'Laculete ', village: 'Glodeni ' },
            { name: 'Malu Mierii ', village: 'Glodeni ' },
            { name: 'Schela ', village: 'Glodeni ' },
            { name: 'Bumbuia ', village: 'Gura Foii ' },
            { name: 'Catanele ', village: 'Gura Foii ' },
            { name: 'Făgetu ', simpleName: 'Fagetu ', village: 'Gura Foii ' },
            { name: 'Adânca ', simpleName: 'Adanca ', village: 'Gura Ocnitei ' },
            { name: 'Ochiuri ', village: 'Gura Ocnitei ' },
            { name: 'Săcueni ', simpleName: 'Sacueni ', village: 'Gura Ocnitei ' },
            { name: 'Sperieţeni ', simpleName: 'Sperieteni ', village: 'Gura Sutii ' },
            { name: 'Butoiu de Jos ', village: 'Hulubesti ' },
            { name: 'Butoiu de Sus ', village: 'Hulubesti ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Hulubesti ' },
            { name: 'Valea Dadei ', village: 'Hulubesti ' },
            { name: 'Ghirdoveni ', village: 'I. L. Caragiale ' },
            { name: 'Mija ', village: 'I. L. Caragiale ' },
            { name: 'Colibaşi ', simpleName: 'Colibasi ', village: 'Iedera ' },
            { name: 'Cricovu Dulce ', village: 'Iedera ' },
            { name: 'Iedera de Jos ', village: 'Iedera ' },
            { name: 'Iedera de Sus ', village: 'Iedera ' },
            { name: 'Olteni ', village: 'Lucieni ' },
            { name: 'Raciu ', village: 'Lucieni ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Lucieni ' },
            { name: 'Şuţa Seacă ', simpleName: 'Suta Seaca ', village: 'Lucieni ' },
            { name: 'Miloşari ', simpleName: 'Milosari ', village: 'Ludesti ' },
            { name: 'Potocelu ', village: 'Ludesti ' },
            { name: 'Scheiu de Jos ', village: 'Ludesti ' },
            { name: 'Scheiu de Sus ', village: 'Ludesti ' },
            { name: 'Teleşti ', simpleName: 'Telesti ', village: 'Ludesti ' },
            { name: 'Oreasca ', village: 'Lunguletu ' },
            { name: 'Serdanu ', village: 'Lunguletu ' },
            { name: 'Capu Coastei ', village: 'Malu Cu Flori ' },
            { name: 'Copăceni ', simpleName: 'Copaceni ', village: 'Malu Cu Flori ' },
            { name: 'Micloşanii Mari ', simpleName: 'Miclosanii Mari ', village: 'Malu Cu Flori ' },
            { name: 'Micloşanii Mici ', simpleName: 'Miclosanii Mici ', village: 'Malu Cu Flori ' },
            { name: 'Drăgăeşti Pământeni ', simpleName: 'Dragaesti Pamanteni ', village: 'Manesti ' },
            { name: 'Drăgăeşti Ungureni ', simpleName: 'Dragaesti Ungureni ', village: 'Manesti ' },
            { name: 'Creţuleşti ', simpleName: 'Cretulesti ', village: 'Matasaru ' },
            { name: 'Odaia Turcului ', village: 'Matasaru ' },
            { name: 'Poroinica ', village: 'Matasaru ' },
            { name: 'Puţu cu Salcie ', simpleName: 'Putu cu Salcie ', village: 'Matasaru ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ', village: 'Matasaru ' },
            { name: 'Teţcoiu ', simpleName: 'Tetcoiu ', village: 'Matasaru ' },
            { name: 'Chirca ', village: 'Mogosani ' },
            { name: 'Cojocaru ', village: 'Mogosani ' },
            { name: 'Merii ', village: 'Mogosani ' },
            { name: 'Zăvoiu ', simpleName: 'Zavoiu ', village: 'Mogosani ' },
            { name: 'Dobreşti ', simpleName: 'Dobresti ', village: 'Moroeni ' },
            { name: 'Glod ', village: 'Moroeni ' },
            { name: 'Lunca ', village: 'Moroeni ' },
            { name: 'Muşcel ', simpleName: 'Muscel ', village: 'Moroeni ' },
            { name: 'Pucheni ', village: 'Moroeni ' },
            { name: 'Neajlovu ', village: 'Morteni ' },
            { name: 'Cucuteni ', village: 'Motaieni ' },
            { name: 'Ciocănari ', simpleName: 'Ciocanari ', village: 'Niculesti ' },
            { name: 'Movila ', village: 'Niculesti ' },
            { name: 'Cazaci ', village: 'Nucet ' },
            { name: 'Ilfoveni ', village: 'Nucet ' },
            { name: 'Brâncoveanu ', simpleName: 'Brancoveanu ', village: 'Odobesti ' },
            { name: 'Crovu ', village: 'Odobesti ' },
            { name: 'Miuleşti ', simpleName: 'Miulesti ', village: 'Odobesti ' },
            { name: 'Zidurile ', village: 'Odobesti ' },
            { name: 'Coada Izvorului ', village: 'Petresti ' },
            { name: 'Ghergheşti ', simpleName: 'Gherghesti ', village: 'Petresti ' },
            { name: 'Greci ', village: 'Petresti ' },
            { name: 'Ioneşti ', simpleName: 'Ionesti ', village: 'Petresti ' },
            { name: 'Potlogeni-Deal ', village: 'Petresti ' },
            { name: 'Puntea de Greci ', village: 'Petresti ' },
            { name: 'Dealu Frumos ', village: 'Pietrosita ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Poiana ' },
            { name: 'Pitaru ', village: 'Potlogi ' },
            { name: 'Podu Cristinii ', village: 'Potlogi ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Potlogi ' },
            { name: 'Vlăsceni ', simpleName: 'Vlasceni ', village: 'Potlogi ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Produlesti ' },
            { name: 'Costeştii din Deal ', simpleName: 'Costestii din Deal ', village: 'Produlesti ' },
            { name: 'Brădăţel ', simpleName: 'Bradatel ', village: 'Pucheni ' },
            { name: 'Meişoare ', simpleName: 'Meisoare ', village: 'Pucheni ' },
            { name: 'Valea Largă ', simpleName: 'Valea Larga ', village: 'Pucheni ' },
            { name: 'Vârfureni ', simpleName: 'Varfureni ', village: 'Pucheni ' },
            { name: 'Bela ', village: 'Pucioasa ' },
            { name: 'Diaconeşti ', simpleName: 'Diaconesti ', village: 'Pucioasa ' },
            { name: 'Glodeni ', village: 'Pucioasa ' },
            { name: 'Malurile ', village: 'Pucioasa ' },
            { name: 'Miculeşti ', simpleName: 'Miculesti ', village: 'Pucioasa ' },
            { name: 'Pucioasa-Sat ', village: 'Pucioasa ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ', village: 'Racari ' },
            { name: 'Colacu ', village: 'Racari ' },
            { name: 'Ghergani ', village: 'Racari ' },
            { name: 'Ghimpaţi ', simpleName: 'Ghimpati ', village: 'Racari ' },
            { name: 'Mavrodin ', village: 'Racari ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ', village: 'Racari ' },
            { name: 'Săbieşti ', simpleName: 'Sabiesti ', village: 'Racari ' },
            { name: 'Gorgota ', village: 'Razvad ' },
            { name: 'Valea Voivozilor ', village: 'Razvad ' },
            { name: 'Brebu ', village: 'Runcu ' },
            { name: 'Bădeni ', simpleName: 'Badeni ', village: 'Runcu ' },
            { name: 'Ferestre ', village: 'Runcu ' },
            { name: 'Piatra ', village: 'Runcu ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Runcu ' },
            { name: 'Băneşti ', simpleName: 'Banesti ', village: 'Salcioara ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Salcioara ' },
            { name: 'Cătunu ', simpleName: 'Catunu ', village: 'Salcioara ' },
            { name: 'Ghineşti ', simpleName: 'Ghinesti ', village: 'Salcioara ' },
            { name: 'Mircea Vodă ', simpleName: 'Mircea Voda ', village: 'Salcioara ' },
            { name: 'Moara Nouă ', simpleName: 'Moara Noua ', village: 'Salcioara ' },
            { name: 'Movila ', village: 'Salcioara ' },
            { name: 'Podu Rizii ', village: 'Salcioara ' },
            { name: 'Fierbinţi ', simpleName: 'Fierbinti ', village: 'Selaru ' },
            { name: 'Glogoveanu ', village: 'Selaru ' },
            { name: 'Teiş ', simpleName: 'Teis ', village: 'Sotanga ' },
            { name: 'Priseaca ', village: 'Targoviste ' },
            { name: 'Bâldana ', simpleName: 'Baldana ', village: 'Tartasesti ' },
            { name: 'Gulia ', village: 'Tartasesti ' },
            { name: 'Căprioru ', simpleName: 'Caprioru ', village: 'Tatarani ' },
            { name: 'Gheboieni ', village: 'Tatarani ' },
            { name: 'Priboiu ', village: 'Tatarani ' },
            { name: 'Fusea ', village: 'Titu ' },
            { name: 'Hagioaica ', village: 'Titu ' },
            { name: 'Mereni ', village: 'Titu ' },
            { name: 'Plopu ', village: 'Titu ' },
            { name: 'Sălcuţa ', simpleName: 'Salcuta ', village: 'Titu ' },
            { name: 'Croitori ', village: 'Uliesti ' },
            { name: 'Hanu lui Pală ', simpleName: 'Hanu lui Pala ', village: 'Uliesti ' },
            { name: 'Jugureni ', village: 'Uliesti ' },
            { name: 'Mânăstioara ', simpleName: 'Manastioara ', village: 'Uliesti ' },
            { name: 'Olteni ', village: 'Uliesti ' },
            { name: 'Ragu ', village: 'Uliesti ' },
            { name: 'Stavropolia ', village: 'Uliesti ' },
            { name: 'Colanu ', village: 'Ulmi ' },
            { name: 'Dimoiu ', village: 'Ulmi ' },
            { name: 'Dumbrava ', village: 'Ulmi ' },
            { name: 'Matraca ', village: 'Ulmi ' },
            { name: 'Nisipurile ', village: 'Ulmi ' },
            { name: 'Udreşti ', simpleName: 'Udresti ', village: 'Ulmi ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Ulmi ' },
            { name: 'Brăteştii de Jos ', simpleName: 'Bratestii de Jos ', village: 'Vacaresti ' },
            { name: 'Bungetu ', village: 'Vacaresti ' },
            { name: 'Perşinari ', simpleName: 'Persinari ', village: 'Vacaresti ' },
            { name: 'Băceşti ', simpleName: 'Bacesti ', village: 'Valea Lunga ' },
            { name: 'Izvoru ', village: 'Valea Lunga ' },
            { name: 'Moşia Mică ', simpleName: 'Mosia Mica ', village: 'Valea Lunga ' },
            { name: 'Valea Lungă Gorgota ', simpleName: 'Valea Lunga Gorgota ', village: 'Valea Lunga ' },
            { name: 'Valea Lungă Ogrea ', simpleName: 'Valea Lunga Ogrea ', village: 'Valea Lunga ' },
            { name: 'Valea Lungă-Cricov ', simpleName: 'Valea Lunga-Cricov ', village: 'Valea Lunga ' },
            { name: 'Valea Mare ', village: 'Valea Lunga ' },
            { name: 'Valea lui Dan ', village: 'Valea Lunga ' },
            { name: 'Şerbăneasa ', simpleName: 'Serbaneasa ', village: 'Valea Lunga ' },
            { name: 'Ştubeie Tisa ', simpleName: 'Stubeie Tisa ', village: 'Valea Lunga ' },
            { name: 'Feţeni ', simpleName: 'Feteni ', village: 'Valea Mare ' },
            { name: 'Gârleni ', simpleName: 'Garleni ', village: 'Valea Mare ' },
            { name: 'Livezile ', village: 'Valea Mare ' },
            { name: 'Saru ', village: 'Valea Mare ' },
            { name: 'Stratoneşti ', simpleName: 'Stratonesti ', village: 'Valea Mare ' },
            { name: 'Valea Caselor ', village: 'Valea Mare ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Valeni-Dimbovita ' },
            { name: 'Văleni-Dâmboviţa ', simpleName: 'Valeni-Dambovita ', village: 'Valeni-Dimbovita ' },
            { name: 'Cojoiu ', village: 'Virfuri ' },
            { name: 'Cârlăneşti ', simpleName: 'Carlanesti ', village: 'Virfuri ' },
            { name: 'Merişoru ', simpleName: 'Merisoru ', village: 'Virfuri ' },
            { name: 'Stăteşti ', simpleName: 'Statesti ', village: 'Virfuri ' },
            { name: 'Ulmetu ', village: 'Virfuri ' },
            { name: 'Vârfuri ', simpleName: 'Varfuri ', village: 'Virfuri ' },
            { name: 'Şuviţa ', simpleName: 'Suvita ', village: 'Virfuri ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Visina ' },
            { name: 'Izvoru ', village: 'Visina ' },
            { name: 'Răscăeţi ', simpleName: 'Rascaeti ', village: 'Visina ' },
            { name: 'Vultureanca ', village: 'Visina ' },
            { name: 'Dospineşti ', simpleName: 'Dospinesti ', village: 'Visinesti ' },
            { name: 'Sultanu ', village: 'Visinesti ' },
            { name: 'Urseiu ', village: 'Visinesti ' },
            { name: 'Gemenea Brătuleşti ', simpleName: 'Gemenea Bratulesti ', village: 'Voinesti ' },
            { name: 'Izvoarele ', village: 'Voinesti ' },
            { name: 'Lunca ', village: 'Voinesti ' },
            { name: 'Manga ', village: 'Voinesti ' },
            { name: 'Mânjina ', simpleName: 'Manjina ', village: 'Voinesti ' },
            { name: 'Onceşti ', simpleName: 'Oncesti ', village: 'Voinesti ' },
            { name: 'Suduleni ', village: 'Voinesti ' },
            { name: 'Nicolaeşti ', simpleName: 'Nicolaesti ', village: 'Vulcana-Bai ' },
            { name: 'Vulcana de Sus ', village: 'Vulcana-Bai ' },
            { name: 'Gura Vulcanei ', village: 'Vulcana-Pandele ' },
            { name: 'Lăculeţe-Gară ', simpleName: 'Laculete-Gara ', village: 'Vulcana-Pandele ' },
            {
                name: 'Toculeşti ', simpleName: 'Toculesti ', village: 'Vulcana-Pandele '
            }
        ]
    },
    {
        auto: 'DJ ',
        name: 'Dolj ',
        cities: [
            { name: 'Afumaţi ', simpleName: 'Afumati ' },
            { name: 'Almăj ', simpleName: 'Almaj ' },
            { name: 'Amărăştii de Jos ', simpleName: 'Amarastii de Jos ' },
            { name: 'Amărăştii de Sus ', simpleName: 'Amarastii de Sus ' },
            { name: 'Apele Vii ' },
            { name: 'Argetoaia ' },
            { name: 'Bechet ' },
            { name: 'Bistreţ ', simpleName: 'Bistret ' },
            { name: 'Botoşeşti-Paia ', simpleName: 'Botosesti-Paia ' },
            { name: 'Brabova ' },
            { name: 'Braloştiţa ', simpleName: 'Bralostita ' },
            { name: 'Bratovoeşti ', simpleName: 'Bratovoesti ' },
            { name: 'Breasta ' },
            { name: 'Brădeşti ', simpleName: 'Bradesti ' },
            { name: 'Bucovăţ ', simpleName: 'Bucovat ' },
            { name: 'Bulzeşti ', simpleName: 'Bulzesti ' },
            { name: 'Băileşti ', simpleName: 'Bailesti ' },
            { name: 'Calafat ' },
            { name: 'Calopăr ', simpleName: 'Calopar ' },
            { name: 'Caraula ' },
            { name: 'Carpen ' },
            { name: 'Castranova ' },
            { name: 'Celaru ' },
            { name: 'Cernăteşti ', simpleName: 'Cernatesti ' },
            { name: 'Cerăt ', simpleName: 'Cerat ' },
            { name: 'Cetate ' },
            { name: 'Cioroiaşi ', simpleName: 'Cioroiasi ' },
            { name: 'Ciupercenii Noi ' },
            { name: 'Coşoveni ', simpleName: 'Cosoveni ' },
            { name: 'Coţofenii din Dos ', simpleName: 'Cotofenii din Dos ' },
            { name: 'Craiova ' },
            { name: 'Călăraşi ', simpleName: 'Calarasi ' },
            { name: 'Daneţi ', simpleName: 'Daneti ' },
            { name: 'Desa ' },
            { name: 'Dioşti ', simpleName: 'Diosti ' },
            { name: 'Dobreşti ', simpleName: 'Dobresti ' },
            { name: 'Drăgoteşti ', simpleName: 'Dragotesti ' },
            { name: 'Drănic ', simpleName: 'Dranic ' },
            { name: 'Dăbuleni ', simpleName: 'Dabuleni ' },
            { name: 'Filiaşi ', simpleName: 'Filiasi ' },
            { name: 'Fărcaş ', simpleName: 'Farcas ' },
            { name: 'Galicea Mare ' },
            { name: 'Gherceşti ', simpleName: 'Ghercesti ' },
            { name: 'Gighera ' },
            { name: 'Giubega ' },
            { name: 'Giurgiţa ', simpleName: 'Giurgita ' },
            { name: 'Gogoşu ', simpleName: 'Gogosu ' },
            { name: 'Goicea ' },
            { name: 'Goieşti ', simpleName: 'Goiesti ' },
            { name: 'Greceşti ', simpleName: 'Grecesti ' },
            { name: 'Izvoare ' },
            { name: 'Işalniţa ', simpleName: 'Isalnita ' },
            { name: 'Leu ' },
            { name: 'Lipovu ' },
            { name: 'Maglavit ' },
            { name: 'Malu Mare ' },
            { name: 'Melineşti ', simpleName: 'Melinesti ' },
            { name: 'Mischii ' },
            { name: 'Moţăţei ', simpleName: 'Motatei ' },
            { name: 'Murgaşi ', simpleName: 'Murgasi ' },
            { name: 'Mârşani ', simpleName: 'Marsani ' },
            { name: 'Măceşu de Jos ', simpleName: 'Macesu de Jos ' },
            { name: 'Măceşu de Sus ', simpleName: 'Macesu de Sus ' },
            { name: 'Negoi ' },
            { name: 'Orodel ' },
            { name: 'Ostroveni ' },
            { name: 'Perişor ', simpleName: 'Perisor ' },
            { name: 'Pieleşti ', simpleName: 'Pielesti ' },
            { name: 'Piscu Vechi ' },
            { name: 'Pleniţa ', simpleName: 'Plenita ' },
            { name: 'Podari ' },
            { name: 'Poiana Mare ' },
            { name: 'Predeşti ', simpleName: 'Predesti ' },
            { name: 'Radovan ' },
            { name: 'Rast ' },
            { name: 'Sadova ' },
            { name: 'Scăeşti ', simpleName: 'Scaesti ' },
            { name: 'Seaca de Câmp ', simpleName: 'Seaca de Camp ' },
            { name: 'Seaca de Pădure ', simpleName: 'Seaca de Padure ' },
            { name: 'Secu ' },
            { name: 'Segarcea ' },
            { name: 'Siliştea Crucii ', simpleName: 'Silistea Crucii ' },
            { name: 'Sopot ' },
            { name: 'Sălcuţa ', simpleName: 'Salcuta ' },
            { name: 'Teasc ' },
            { name: 'Terpeziţa ', simpleName: 'Terpezita ' },
            { name: 'Teslui ' },
            { name: 'Unirea ' },
            { name: 'Urzicuţa ', simpleName: 'Urzicuta ' },
            { name: 'Valea Stanciului ' },
            { name: 'Vela ' },
            { name: 'Verbiţa ', simpleName: 'Verbita ' },
            { name: 'Vârtop ', simpleName: 'Vartop ' },
            { name: 'Vârvoru de Jos ', simpleName: 'Varvoru de Jos ' },
            { name: 'Şimnicu de Sus ', simpleName: 'Simnicu de Sus ' },
            { name: 'Ţuglui ', simpleName: 'Tuglui ' },
            { name: 'Boureni ', village: 'Afumati ' },
            { name: 'Covei ', village: 'Afumati ' },
            { name: 'Beharca ', village: 'Almaj ' },
            { name: 'Bogea ', village: 'Almaj ' },
            { name: 'Coţofenii din Faţă ', simpleName: 'Cotofenii din Fata ', village: 'Almaj ' },
            { name: 'Moşneni ', simpleName: 'Mosneni ', village: 'Almaj ' },
            { name: 'Şitoaia ', simpleName: 'Sitoaia ', village: 'Almaj ' },
            { name: 'Ocolna ', village: 'Amarastii De Jos ' },
            { name: 'Prapor ', village: 'Amarastii De Jos ' },
            { name: 'Dobroteşti ', simpleName: 'Dobrotesti ', village: 'Amarastii De Sus ' },
            { name: 'Nisipuri ', village: 'Amarastii De Sus ' },
            { name: 'Zvorsca ', village: 'Amarastii De Sus ' },
            { name: 'Berbeşu ', simpleName: 'Berbesu ', village: 'Argetoaia ' },
            { name: 'Băranu ', simpleName: 'Baranu ', village: 'Argetoaia ' },
            { name: 'Iordăcheşti ', simpleName: 'Iordachesti ', village: 'Argetoaia ' },
            { name: 'Leordoasa ', village: 'Argetoaia ' },
            { name: 'Malumnic ', village: 'Argetoaia ' },
            { name: 'Novac ', village: 'Argetoaia ' },
            { name: 'Piria ', village: 'Argetoaia ' },
            { name: 'Poiana Fântânii ', simpleName: 'Poiana Fantanii ', village: 'Argetoaia ' },
            { name: 'Salcia ', village: 'Argetoaia ' },
            { name: 'Teascu din Deal ', village: 'Argetoaia ' },
            { name: 'Ursoaia ', village: 'Argetoaia ' },
            { name: 'Balasan ', village: 'Bailesti ' },
            { name: 'Bârca ', simpleName: 'Barca ', village: 'Birca ' },
            { name: 'Bistreţu Nou ', simpleName: 'Bistretu Nou ', village: 'Bistret ' },
            { name: 'Brânduşa ', simpleName: 'Brandusa ', village: 'Bistret ' },
            { name: 'Plosca ', village: 'Bistret ' },
            { name: 'Caraiman ', village: 'Brabova ' },
            { name: 'Mosna ', village: 'Brabova ' },
            { name: 'Răchita de Jos ', simpleName: 'Rachita de Jos ', village: 'Brabova ' },
            { name: 'Urdiniţa ', simpleName: 'Urdinita ', village: 'Brabova ' },
            { name: 'Voita ', village: 'Brabova ' },
            { name: 'Brădeştii Bătrâni ', simpleName: 'Bradestii Batrani ', village: 'Bradesti ' },
            { name: 'Meteu ', village: 'Bradesti ' },
            { name: 'Piscani ', village: 'Bradesti ' },
            { name: 'Răcarii de Jos ', simpleName: 'Racarii de Jos ', village: 'Bradesti ' },
            { name: 'Tatomireşti ', simpleName: 'Tatomiresti ', village: 'Bradesti ' },
            { name: 'Ciocanele ', village: 'Bralostita ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ', village: 'Bralostita ' },
            { name: 'Schitu ', village: 'Bralostita ' },
            { name: 'Sfârcea ', simpleName: 'Sfarcea ', village: 'Bralostita ' },
            { name: 'Valea Fântânilor ', simpleName: 'Valea Fantanilor ', village: 'Bralostita ' },
            { name: 'Bădoşi ', simpleName: 'Badosi ', village: 'Bratovoesti ' },
            { name: 'Georocu Mare ', village: 'Bratovoesti ' },
            { name: 'Prunet ', village: 'Bratovoesti ' },
            { name: 'Rojişte ', simpleName: 'Rojiste ', village: 'Bratovoesti ' },
            { name: 'Tâmbureşti ', simpleName: 'Tamburesti ', village: 'Bratovoesti ' },
            { name: 'Cotu ', village: 'Breasta ' },
            { name: 'Crovna ', village: 'Breasta ' },
            { name: 'Făget ', simpleName: 'Faget ', village: 'Breasta ' },
            { name: 'Obedin ', village: 'Breasta ' },
            { name: 'Roşieni ', simpleName: 'Rosieni ', village: 'Breasta ' },
            { name: 'Valea Lungului ', village: 'Breasta ' },
            { name: 'Cârligei ', simpleName: 'Carligei ', village: 'Bucovat ' },
            { name: 'Italieni ', village: 'Bucovat ' },
            { name: 'Leamna de Jos ', village: 'Bucovat ' },
            { name: 'Leamna de Sus ', village: 'Bucovat ' },
            { name: 'Palilula ', village: 'Bucovat ' },
            { name: 'Sărbătoarea ', simpleName: 'Sarbatoarea ', village: 'Bucovat ' },
            { name: 'Frăţila ', simpleName: 'Fratila ', village: 'Bulzesti ' },
            { name: 'Gura Racului ', village: 'Bulzesti ' },
            { name: 'Piscu Lung ', village: 'Bulzesti ' },
            { name: 'Poienile ', village: 'Bulzesti ' },
            { name: 'Prejoi ', village: 'Bulzesti ' },
            { name: 'Seculeşti ', simpleName: 'Seculesti ', village: 'Bulzesti ' },
            { name: 'Stoiceşti ', simpleName: 'Stoicesti ', village: 'Bulzesti ' },
            { name: 'Sălişte ', simpleName: 'Saliste ', village: 'Bulzesti ' },
            { name: 'Înfrăţirea ', simpleName: 'Infratirea ', village: 'Bulzesti ' },
            { name: 'Basarabi ', village: 'Calafat ' },
            { name: 'Ciupercenii Vechi ', village: 'Calafat ' },
            { name: 'Golenţi ', simpleName: 'Golenti ', village: 'Calafat ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Calarasi ' },
            { name: 'Belcinu ', village: 'Calopar ' },
            { name: 'Bâzdâna ', simpleName: 'Bazdana ', village: 'Calopar ' },
            { name: 'Panaghia ', village: 'Calopar ' },
            { name: 'Sălcuţa ', simpleName: 'Salcuta ', village: 'Calopar ' },
            { name: 'Cleanov ', village: 'Carpen ' },
            { name: 'Gebleşti ', simpleName: 'Geblesti ', village: 'Carpen ' },
            { name: 'Puţuri ', simpleName: 'Puturi ', village: 'Castranova ' },
            { name: 'Ghizdăveşti ', simpleName: 'Ghizdavesti ', village: 'Celaru ' },
            { name: 'Marotinu de Jos ', village: 'Celaru ' },
            { name: 'Marotinu de Sus ', village: 'Celaru ' },
            { name: 'Soreni ', village: 'Celaru ' },
            { name: 'Malaica ', village: 'Cerat ' },
            { name: 'Corniţa ', simpleName: 'Cornita ', village: 'Cernatesti ' },
            { name: 'Rasnicu Bătrân ', simpleName: 'Rasnicu Batran ', village: 'Cernatesti ' },
            { name: 'Rasnicu Oghian ', village: 'Cernatesti ' },
            { name: 'Ţiu ', simpleName: 'Tiu ', village: 'Cernatesti ' },
            { name: 'Moreni ', village: 'Cetate ' },
            { name: 'Cetăţuia ', simpleName: 'Cetatuia ', village: 'Cioroiasi ' },
            { name: 'Cioroiu Nou ', village: 'Cioroiasi ' },
            { name: 'Smârdan ', simpleName: 'Smardan ', village: 'Ciupercenii Noi ' },
            { name: 'Cârcea ', simpleName: 'Carcea ', village: 'Cosoveni ' },
            { name: 'Mihăiţa ', simpleName: 'Mihaita ', village: 'Cotofenii Din Dos ' },
            { name: 'Potmelţu ', simpleName: 'Potmeltu ', village: 'Cotofenii Din Dos ' },
            { name: 'Cernele ', village: 'Craiova ' },
            { name: 'Cernelele de Sus ', village: 'Craiova ' },
            { name: 'Făcăi ', simpleName: 'Facai ', village: 'Craiova ' },
            { name: 'Izvorul Rece ', village: 'Craiova ' },
            { name: 'Mofleni ', village: 'Craiova ' },
            { name: 'Popoveni ', village: 'Craiova ' },
            { name: 'Rovine ', village: 'Craiova ' },
            { name: 'Şimnicu de Jos ', simpleName: 'Simnicu de Jos ', village: 'Craiova ' },
            { name: 'Chiaşu ', simpleName: 'Chiasu ', village: 'Dabuleni ' },
            { name: 'Brabeţi ', simpleName: 'Brabeti ', village: 'Daneti ' },
            { name: 'Branişte ', simpleName: 'Braniste ', village: 'Daneti ' },
            { name: 'Locusteni ', village: 'Daneti ' },
            { name: 'Ciocăneşti ', simpleName: 'Ciocanesti ', village: 'Diosti ' },
            { name: 'Radomir ', village: 'Diosti ' },
            { name: 'Căciulăteşti ', simpleName: 'Caciulatesti ', village: 'Dobresti ' },
            { name: 'Georocel ', village: 'Dobresti ' },
            { name: 'Murta ', village: 'Dobresti ' },
            { name: 'Toceni ', village: 'Dobresti ' },
            { name: 'Beneşti ', simpleName: 'Benesti ', village: 'Dragotesti ' },
            { name: 'Bobeanu ', village: 'Dragotesti ' },
            { name: 'Buzduc ', village: 'Dragotesti ' },
            { name: 'Popânzăleşti ', simpleName: 'Popanzalesti ', village: 'Dragotesti ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Dragotesti ' },
            { name: 'Booveni ', village: 'Dranic ' },
            { name: 'Foişor ', simpleName: 'Foisor ', village: 'Dranic ' },
            { name: 'Padea ', village: 'Dranic ' },
            { name: 'Amărăşti ', simpleName: 'Amarasti ', village: 'Farcas ' },
            { name: 'Golumbelu ', village: 'Farcas ' },
            { name: 'Golumbu ', village: 'Farcas ' },
            { name: 'Mofleşti ', simpleName: 'Moflesti ', village: 'Farcas ' },
            { name: 'Nistoi ', village: 'Farcas ' },
            { name: 'Plopu Amărăşti ', simpleName: 'Plopu Amarasti ', village: 'Farcas ' },
            { name: 'Puţinei ', simpleName: 'Putinei ', village: 'Farcas ' },
            { name: 'Soceni ', village: 'Farcas ' },
            { name: 'Tălpaş ', simpleName: 'Talpas ', village: 'Farcas ' },
            { name: 'Almăjel ', simpleName: 'Almajel ', village: 'Filiasi ' },
            { name: 'Branişte ', simpleName: 'Braniste ', village: 'Filiasi ' },
            { name: 'Bâlta ', simpleName: 'Balta ', village: 'Filiasi ' },
            { name: 'Fratoştiţa ', simpleName: 'Fratostita ', village: 'Filiasi ' },
            { name: 'Răcarii de Sus ', simpleName: 'Racarii de Sus ', village: 'Filiasi ' },
            { name: 'Uscăci ', simpleName: 'Uscaci ', village: 'Filiasi ' },
            { name: 'Gârleşti ', simpleName: 'Garlesti ', village: 'Ghercesti ' },
            { name: 'Luncşoru ', simpleName: 'Luncsoru ', village: 'Ghercesti ' },
            { name: 'Ungureni ', village: 'Ghercesti ' },
            { name: 'Ungurenii Mici ', village: 'Ghercesti ' },
            { name: 'Nedeia ', village: 'Gighera ' },
            { name: 'Zăval ', simpleName: 'Zaval ', village: 'Gighera ' },
            { name: 'Comoşteni ', simpleName: 'Comosteni ', village: 'Gingiova ' },
            { name: 'Gângiova ', simpleName: 'Gangiova ', village: 'Gingiova ' },
            { name: 'Galiciuica ', village: 'Giubega ' },
            { name: 'Curmătura ', simpleName: 'Curmatura ', village: 'Giurgita ' },
            { name: 'Filaret ', village: 'Giurgita ' },
            { name: 'Gogoşiţa ', simpleName: 'Gogosita ', village: 'Gogosu ' },
            { name: 'Ştefănel ', simpleName: 'Stefanel ', village: 'Gogosu ' },
            { name: 'Cârna ', simpleName: 'Carna ', village: 'Goicea ' },
            { name: 'Adâncata ', simpleName: 'Adancata ', village: 'Goiesti ' },
            { name: 'Fântâni ', simpleName: 'Fantani ', village: 'Goiesti ' },
            { name: 'Gruiţa ', simpleName: 'Gruita ', village: 'Goiesti ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ', village: 'Goiesti ' },
            { name: 'Muereni ', village: 'Goiesti ' },
            { name: 'Mălăeşti ', simpleName: 'Malaesti ', village: 'Goiesti ' },
            { name: 'Pioreşti ', simpleName: 'Pioresti ', village: 'Goiesti ' },
            { name: 'Pometeşti ', simpleName: 'Pometesti ', village: 'Goiesti ' },
            { name: 'Popeasa ', village: 'Goiesti ' },
            { name: 'Vladimir ', village: 'Goiesti ' },
            { name: 'Zlătari ', simpleName: 'Zlatari ', village: 'Goiesti ' },
            { name: 'Ţandăra ', simpleName: 'Tandara ', village: 'Goiesti ' },
            { name: 'Busu ', village: 'Grecesti ' },
            { name: 'Busuleţu ', simpleName: 'Busuletu ', village: 'Grecesti ' },
            { name: 'Bărboi ', simpleName: 'Barboi ', village: 'Grecesti ' },
            { name: 'Gropanele ', village: 'Grecesti ' },
            { name: 'Grădiştea ', simpleName: 'Gradistea ', village: 'Grecesti ' },
            { name: 'Corlate ', village: 'Izvoare ' },
            { name: 'Domnu Tudor ', village: 'Izvoare ' },
            { name: 'Zănoaga ', simpleName: 'Zanoaga ', village: 'Leu ' },
            { name: 'Lipovu de Sus ', village: 'Lipovu ' },
            { name: 'Săpata ', simpleName: 'Sapata ', village: 'Macesu De Jos ' },
            { name: 'Hunia ', village: 'Maglavit ' },
            { name: 'Ghindeni ', village: 'Malu Mare ' },
            { name: 'Preajba ', village: 'Malu Mare ' },
            { name: 'Bodăieşti ', simpleName: 'Bodaiesti ', village: 'Melinesti ' },
            { name: 'Bodăieştii de Sus ', simpleName: 'Bodaiestii de Sus ', village: 'Melinesti ' },
            { name: 'Godeni ', village: 'Melinesti ' },
            { name: 'Muieruşu ', simpleName: 'Muierusu ', village: 'Melinesti ' },
            { name: 'Negoieşti ', simpleName: 'Negoiesti ', village: 'Melinesti ' },
            { name: 'Odoleni ', village: 'Melinesti ' },
            { name: 'Ohaba ', village: 'Melinesti ' },
            { name: 'Ploştina ', simpleName: 'Plostina ', village: 'Melinesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Melinesti ' },
            { name: 'Spineni ', village: 'Melinesti ' },
            { name: 'Valea Mare ', village: 'Melinesti ' },
            { name: 'Valea Muierii de Jos ', village: 'Melinesti ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Mischii ' },
            { name: 'Gogoşeşti ', simpleName: 'Gogosesti ', village: 'Mischii ' },
            { name: 'Mlecăneşti ', simpleName: 'Mlecanesti ', village: 'Mischii ' },
            { name: 'Motoci ', village: 'Mischii ' },
            { name: 'Urecheşti ', simpleName: 'Urechesti ', village: 'Mischii ' },
            { name: 'Dobridor ', village: 'Motatei ' },
            { name: 'Moţăţei Gară ', simpleName: 'Motatei Gara ', village: 'Motatei ' },
            { name: 'Balota de Jos ', village: 'Murgasi ' },
            { name: 'Balota de Sus ', village: 'Murgasi ' },
            { name: 'Buşteni ', simpleName: 'Busteni ', village: 'Murgasi ' },
            { name: 'Gaia ', village: 'Murgasi ' },
            { name: 'Picăturile ', simpleName: 'Picaturile ', village: 'Murgasi ' },
            { name: 'Rupturile ', village: 'Murgasi ' },
            { name: 'Velesti ', village: 'Murgasi ' },
            { name: 'Catane ', village: 'Negoi ' },
            { name: 'Catanele Noi ', village: 'Negoi ' },
            { name: 'Bechet ', village: 'Orodel ' },
            { name: 'Cornu ', village: 'Orodel ' },
            { name: 'Călugărei ', simpleName: 'Calugarei ', village: 'Orodel ' },
            { name: 'Teiu ', village: 'Orodel ' },
            { name: 'Lişteava ', simpleName: 'Listeava ', village: 'Ostroveni ' },
            { name: 'Mărăcinele ', simpleName: 'Maracinele ', village: 'Perisor ' },
            { name: 'Câmpeni ', simpleName: 'Campeni ', village: 'Pielesti ' },
            { name: 'Lânga ', simpleName: 'Langa ', village: 'Pielesti ' },
            { name: 'Ghidici ', village: 'Piscu Vechi ' },
            { name: 'Pisculeţ ', simpleName: 'Pisculet ', village: 'Piscu Vechi ' },
            { name: 'Castrele Traiane ', village: 'Plenita ' },
            { name: 'Balta Verde ', village: 'Podari ' },
            { name: 'Branişte ', simpleName: 'Braniste ', village: 'Podari ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Podari ' },
            { name: 'Livezi ', village: 'Podari ' },
            { name: 'Tunarii Noi ', village: 'Poiana Mare ' },
            { name: 'Tunarii Vechi ', village: 'Poiana Mare ' },
            { name: 'Bucicani ', village: 'Predesti ' },
            { name: 'Cârstovani ', simpleName: 'Carstovani ', village: 'Predesti ' },
            { name: 'Frasin ', village: 'Predesti ' },
            { name: 'Milovan ', village: 'Predesti ' },
            { name: 'Pleşoi ', simpleName: 'Plesoi ', village: 'Predesti ' },
            { name: 'Predeştii Mici ', simpleName: 'Predestii Mici ', village: 'Predesti ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Radovan ' },
            { name: 'Târnava ', simpleName: 'Tarnava ', village: 'Radovan ' },
            { name: 'Întorsura ', simpleName: 'Intorsura ', village: 'Radovan ' },
            { name: 'Bojoiu ', village: 'Robanesti ' },
            { name: 'Golfin ', village: 'Robanesti ' },
            { name: 'Lăcriţa Mare ', simpleName: 'Lacrita Mare ', village: 'Robanesti ' },
            { name: 'Lăcriţa Mică ', simpleName: 'Lacrita Mica ', village: 'Robanesti ' },
            { name: 'Robăneştii de Jos ', simpleName: 'Robanestii de Jos ', village: 'Robanesti ' },
            { name: 'Robăneştii de Sus ', simpleName: 'Robanestii de Sus ', village: 'Robanesti ' },
            { name: 'Piscu Sadovei ', village: 'Sadova ' },
            { name: 'Mârza ', simpleName: 'Marza ', village: 'Salcuta ' },
            { name: 'Plopşor ', simpleName: 'Plopsor ', village: 'Salcuta ' },
            { name: 'Tencănău ', simpleName: 'Tencanau ', village: 'Salcuta ' },
            { name: 'Valea lui Pătru ', simpleName: 'Valea lui Patru ', village: 'Scaesti ' },
            { name: 'Piscu Nou ', village: 'Seaca De Camp ' },
            { name: 'Răchita de Sus ', simpleName: 'Rachita de Sus ', village: 'Seaca De Padure ' },
            { name: 'Veleni ', village: 'Seaca De Padure ' },
            { name: 'Comănicea ', simpleName: 'Comanicea ', village: 'Secu ' },
            { name: 'Smadovicioara de Secu ', village: 'Secu ' },
            { name: 'Şumandra ', simpleName: 'Sumandra ', village: 'Secu ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Simnicu De Sus ' },
            { name: 'Cornetu ', village: 'Simnicu De Sus ' },
            { name: 'Deleni ', village: 'Simnicu De Sus ' },
            { name: 'Dudoviceşti ', simpleName: 'Dudovicesti ', village: 'Simnicu De Sus ' },
            { name: 'Duţuleşti ', simpleName: 'Dutulesti ', village: 'Simnicu De Sus ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Simnicu De Sus ' },
            { name: 'Izvor ', village: 'Simnicu De Sus ' },
            { name: 'Jieni ', village: 'Simnicu De Sus ' },
            { name: 'Leşile ', simpleName: 'Lesile ', village: 'Simnicu De Sus ' },
            { name: 'Mileşti ', simpleName: 'Milesti ', village: 'Simnicu De Sus ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Simnicu De Sus ' },
            { name: 'Başcov ', simpleName: 'Bascov ', village: 'Sopot ' },
            { name: 'Beloţ ', simpleName: 'Belot ', village: 'Sopot ' },
            { name: 'Cernat ', village: 'Sopot ' },
            { name: 'Pereni ', village: 'Sopot ' },
            { name: 'Pietroaia ', village: 'Sopot ' },
            { name: 'Sârsca ', simpleName: 'Sarsca ', village: 'Sopot ' },
            { name: 'Secui ', village: 'Teasc ' },
            { name: 'Căciulatu ', simpleName: 'Caciulatu ', village: 'Terpezita ' },
            { name: 'Căruia ', simpleName: 'Caruia ', village: 'Terpezita ' },
            { name: 'Floran ', village: 'Terpezita ' },
            { name: 'Lazu ', village: 'Terpezita ' },
            { name: 'Coşereni ', simpleName: 'Cosereni ', village: 'Teslui ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Teslui ' },
            { name: 'Preajba de Jos ', village: 'Teslui ' },
            { name: 'Preajba de Pădure ', simpleName: 'Preajba de Padure ', village: 'Teslui ' },
            { name: 'Urieni ', village: 'Teslui ' },
            { name: 'Viişoara Moşneni ', simpleName: 'Viisoara Mosneni ', village: 'Teslui ' },
            { name: 'Ţărţăl ', simpleName: 'Tartal ', village: 'Teslui ' },
            { name: 'Jiul ', village: 'Tuglui ' },
            { name: 'Urzica Mare ', village: 'Urzicuta ' },
            { name: 'Horezu Poenari ', village: 'Valea Stanciului ' },
            { name: 'Bujor ', village: 'Varvoru De Jos ' },
            { name: 'Ciutura ', village: 'Varvoru De Jos ' },
            { name: 'Criva ', village: 'Varvoru De Jos ' },
            { name: 'Dobromira ', village: 'Varvoru De Jos ' },
            { name: 'Drăgoaia ', simpleName: 'Dragoaia ', village: 'Varvoru De Jos ' },
            { name: 'Gabru ', village: 'Varvoru De Jos ' },
            { name: 'Vârvor ', simpleName: 'Varvor ', village: 'Varvoru De Jos ' },
            { name: 'Bucovicior ', village: 'Vela ' },
            { name: 'Cetăţuia ', simpleName: 'Cetatuia ', village: 'Vela ' },
            { name: 'Desnăţui ', simpleName: 'Desnatui ', village: 'Vela ' },
            { name: 'Gubaucea ', village: 'Vela ' },
            { name: 'Segleţ ', simpleName: 'Seglet ', village: 'Vela ' },
            { name: 'Suharu ', village: 'Vela ' },
            { name: 'Ştiubei ', simpleName: 'Stiubei ', village: 'Vela ' },
            {
                name: 'Verbicioara ', village: 'Verbita '
            }
        ]
    },
    {
        auto: 'GL ',
        name: 'Galaţi ',
        cities: [
            { name: 'Barcea ' },
            { name: 'Bereşti ', simpleName: 'Beresti ' },
            { name: 'Bereşti-Meria ', simpleName: 'Beresti-Meria ' },
            { name: 'Braniştea ', simpleName: 'Branistea ' },
            { name: 'Brăhăşeşti ', simpleName: 'Brahasesti ' },
            { name: 'Buciumeni ' },
            { name: 'Băleni ', simpleName: 'Baleni ' },
            { name: 'Bălăbăneşti ', simpleName: 'Balabanesti ' },
            { name: 'Bălăşeşti ', simpleName: 'Balasesti ' },
            { name: 'Băneasa ', simpleName: 'Baneasa ' },
            { name: 'Cavadineşti ', simpleName: 'Cavadinesti ' },
            { name: 'Cerţeşti ', simpleName: 'Certesti ' },
            { name: 'Corni ' },
            { name: 'Corod ' },
            { name: 'Cosmeşti ', simpleName: 'Cosmesti ' },
            { name: 'Costache Negri ' },
            { name: 'Cuca ' },
            { name: 'Cudalbi ' },
            { name: 'Drăguşeni ', simpleName: 'Draguseni ' },
            { name: 'Drăgăneşti ', simpleName: 'Draganesti ' },
            { name: 'Folteşti ', simpleName: 'Foltesti ' },
            { name: 'Frumuşiţa ', simpleName: 'Frumusita ' },
            { name: 'Fundeni ' },
            { name: 'Fârţăneşti ', simpleName: 'Fartanesti ' },
            { name: 'Galaţi ', simpleName: 'Galati ' },
            { name: 'Ghidigeni ' },
            { name: 'Gohor ' },
            { name: 'Griviţa ', simpleName: 'Grivita ' },
            { name: 'Independenţa ', simpleName: 'Independenta ' },
            { name: 'Iveşti ', simpleName: 'Ivesti ' },
            { name: 'Jorăşti ', simpleName: 'Jorasti ' },
            { name: 'Lieşti ', simpleName: 'Liesti ' },
            { name: 'Matca ' },
            { name: 'Movileni ' },
            { name: 'Munteni ' },
            { name: 'Măstăcani ', simpleName: 'Mastacani ' },
            { name: 'Nicoreşti ', simpleName: 'Nicoresti ' },
            { name: 'Nămoloasa ', simpleName: 'Namoloasa ' },
            { name: 'Oancea ' },
            { name: 'Pechea ' },
            { name: 'Piscu ' },
            { name: 'Priponeşti ', simpleName: 'Priponesti ' },
            { name: 'Rediu ' },
            { name: 'Schela ' },
            { name: 'Scânteieşti ', simpleName: 'Scanteiesti ' },
            { name: 'Slobozia Conachi ' },
            { name: 'Smulţi ', simpleName: 'Smulti ' },
            { name: 'Smârdan ', simpleName: 'Smardan ' },
            { name: 'Suceveni ' },
            { name: 'Tecuci ' },
            { name: 'Tudor Vladimirescu ' },
            { name: 'Tuluceşti ', simpleName: 'Tulucesti ' },
            { name: 'Târgu Bujor ', simpleName: 'Targu Bujor ' },
            { name: 'Umbrăreşti ', simpleName: 'Umbraresti ' },
            { name: 'Valea Mărului ', simpleName: 'Valea Marului ' },
            { name: 'Vlădeşti ', simpleName: 'Vladesti ' },
            { name: 'Vânători ', simpleName: 'Vanatori ' },
            { name: 'Vărlezi ', simpleName: 'Varlezi ' },
            { name: 'Şendreni ', simpleName: 'Sendreni ' },
            { name: 'Ţepu ', simpleName: 'Tepu ' },
            { name: 'Bursucani ', village: 'Balabanesti ' },
            { name: 'Cruceanu ', village: 'Balabanesti ' },
            { name: 'Lungeşti ', simpleName: 'Lungesti ', village: 'Balabanesti ' },
            { name: 'Rădeşti ', simpleName: 'Radesti ', village: 'Balabanesti ' },
            { name: 'Zimbru ', village: 'Balabanesti ' },
            { name: 'Ciureşti ', simpleName: 'Ciuresti ', village: 'Balasesti ' },
            { name: 'Ciureştii Noi ', simpleName: 'Ciurestii Noi ', village: 'Balasesti ' },
            { name: 'Pupezeni ', village: 'Balasesti ' },
            { name: 'Roşcani ', simpleName: 'Roscani ', village: 'Baneasa ' },
            { name: 'Podoleni ', village: 'Barcea ' },
            { name: 'Aldeşti ', simpleName: 'Aldesti ', village: 'Beresti-Meria ' },
            { name: 'Balinteşti ', simpleName: 'Balintesti ', village: 'Beresti-Meria ' },
            { name: 'Onciu ', village: 'Beresti-Meria ' },
            { name: 'Pleşa ', simpleName: 'Plesa ', village: 'Beresti-Meria ' },
            { name: 'Prodăneşti ', simpleName: 'Prodanesti ', village: 'Beresti-Meria ' },
            { name: 'Puricani ', village: 'Beresti-Meria ' },
            { name: 'Slivna ', village: 'Beresti-Meria ' },
            { name: 'Săseni ', simpleName: 'Saseni ', village: 'Beresti-Meria ' },
            { name: 'Şipote ', simpleName: 'Sipote ', village: 'Beresti-Meria ' },
            { name: 'Corcioveni ', village: 'Brahasesti ' },
            { name: 'Cosiţeni ', simpleName: 'Cositeni ', village: 'Brahasesti ' },
            { name: 'Toflea ', village: 'Brahasesti ' },
            { name: 'Lozova ', village: 'Branistea ' },
            { name: 'Traian ', village: 'Branistea ' },
            { name: 'Vasile Alecsandri ', village: 'Branistea ' },
            { name: 'Hănţeşti ', simpleName: 'Hantesti ', village: 'Buciumeni ' },
            { name: 'Tecucelu Sec ', village: 'Buciumeni ' },
            { name: 'Vizureşti ', simpleName: 'Vizuresti ', village: 'Buciumeni ' },
            { name: 'Comăneşti ', simpleName: 'Comanesti ', village: 'Cavadinesti ' },
            { name: 'Găneşti ', simpleName: 'Ganesti ', village: 'Cavadinesti ' },
            { name: 'Vădeni ', simpleName: 'Vadeni ', village: 'Cavadinesti ' },
            { name: 'Cotoroaia ', village: 'Certesti ' },
            { name: 'Cârlomăneşti ', simpleName: 'Carlomanesti ', village: 'Certesti ' },
            { name: 'Măcişeni ', simpleName: 'Maciseni ', village: 'Corni ' },
            { name: 'Urleşti ', simpleName: 'Urlesti ', village: 'Corni ' },
            { name: 'Blânzi ', simpleName: 'Blanzi ', village: 'Corod ' },
            { name: 'Brătuleşti ', simpleName: 'Bratulesti ', village: 'Corod ' },
            { name: 'Cărăpceşti ', simpleName: 'Carapcesti ', village: 'Corod ' },
            { name: 'Băltăreţi ', simpleName: 'Baltareti ', village: 'Cosmesti ' },
            { name: 'Cosmeştii-Vale ', simpleName: 'Cosmestii-Vale ', village: 'Cosmesti ' },
            { name: 'Furcenii Noi ', village: 'Cosmesti ' },
            { name: 'Furcenii Vechi ', village: 'Cosmesti ' },
            { name: 'Satu Nou ', village: 'Cosmesti ' },
            { name: 'Malu Alb ', village: 'Draganesti ' },
            { name: 'Adam ', village: 'Draguseni ' },
            { name: 'Căuieşti ', simpleName: 'Cauiesti ', village: 'Draguseni ' },
            { name: 'Fundeanu ', village: 'Draguseni ' },
            { name: 'Ghingheşti ', simpleName: 'Ghinghesti ', village: 'Draguseni ' },
            { name: 'Nicopole ', village: 'Draguseni ' },
            { name: 'Ştieţeşti ', simpleName: 'Stietesti ', village: 'Draguseni ' },
            { name: 'Viile ', village: 'Fartanesti ' },
            { name: 'Stoicani ', village: 'Foltesti ' },
            { name: 'Ijdileni ', village: 'Frumusita ' },
            { name: 'Tămăoani ', simpleName: 'Tamaoani ', village: 'Frumusita ' },
            { name: 'Fundenii Noi ', village: 'Fundeni ' },
            { name: 'Hanu Conachi ', village: 'Fundeni ' },
            { name: 'Lungoci ', village: 'Fundeni ' },
            { name: 'Gara Ghidigeni ', village: 'Ghidigeni ' },
            { name: 'Gefu ', village: 'Ghidigeni ' },
            { name: 'Gura Gârbovăţului ', simpleName: 'Gura Garbovatului ', village: 'Ghidigeni ' },
            { name: 'Gârbovăţ ', simpleName: 'Garbovat ', village: 'Ghidigeni ' },
            { name: 'Slobozia Corni ', village: 'Ghidigeni ' },
            { name: 'Tălpigi ', simpleName: 'Talpigi ', village: 'Ghidigeni ' },
            { name: 'Tăplău ', simpleName: 'Taplau ', village: 'Ghidigeni ' },
            { name: 'Gara Berheci ', village: 'Gohor ' },
            { name: 'Ireasca ', village: 'Gohor ' },
            { name: 'Nărteşti ', simpleName: 'Nartesti ', village: 'Gohor ' },
            { name: 'Poşta ', simpleName: 'Posta ', village: 'Gohor ' },
            { name: 'Călmăţui ', simpleName: 'Calmatui ', village: 'Grivita ' },
            { name: 'Buceşti ', simpleName: 'Bucesti ', village: 'Ivesti ' },
            { name: 'Lunca ', village: 'Jorasti ' },
            { name: 'Zărneşti ', simpleName: 'Zarnesti ', village: 'Jorasti ' },
            { name: 'Chiraftei ', village: 'Mastacani ' },
            { name: 'Frunzeasca ', village: 'Munteni ' },
            { name: 'Negrileşti ', simpleName: 'Negrilesti ', village: 'Munteni ' },
            { name: 'Slobozia Blăneasa ', simpleName: 'Slobozia Blaneasa ', village: 'Munteni ' },
            { name: 'Ungureni ', village: 'Munteni ' },
            { name: 'Ţigăneşti ', simpleName: 'Tiganesti ', village: 'Munteni ' },
            { name: 'Crângeni ', simpleName: 'Crangeni ', village: 'Namoloasa ' },
            { name: 'Nămoloasa Sat ', simpleName: 'Namoloasa Sat ', village: 'Namoloasa ' },
            { name: 'Brăniştea ', simpleName: 'Branistea ', village: 'Nicoresti ' },
            { name: 'Coasta Lupei ', village: 'Nicoresti ' },
            { name: 'Dobrineşti ', simpleName: 'Dobrinesti ', village: 'Nicoresti ' },
            { name: 'Fântâni ', simpleName: 'Fantani ', village: 'Nicoresti ' },
            { name: 'Grozăveşti ', simpleName: 'Grozavesti ', village: 'Nicoresti ' },
            { name: 'Ionăşeşti ', simpleName: 'Ionasesti ', village: 'Nicoresti ' },
            { name: 'Mălureni ', simpleName: 'Malureni ', village: 'Nicoresti ' },
            { name: 'Piscu Corbului ', village: 'Nicoresti ' },
            { name: 'Poiana ', village: 'Nicoresti ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Nicoresti ' },
            { name: 'Vişina ', simpleName: 'Visina ', village: 'Nicoresti ' },
            { name: 'Slobozia Oancea ', village: 'Oancea ' },
            { name: 'Lupele ', village: 'Pechea ' },
            { name: 'Vameş ', simpleName: 'Vames ', village: 'Piscu ' },
            { name: 'Ciorăşti ', simpleName: 'Ciorasti ', village: 'Priponesti ' },
            { name: 'Huştiu ', simpleName: 'Hustiu ', village: 'Priponesti ' },
            { name: 'Lieşti ', simpleName: 'Liesti ', village: 'Priponesti ' },
            { name: 'Priponeştii de Jos ', simpleName: 'Priponestii de Jos ', village: 'Priponesti ' },
            { name: 'Plevna ', village: 'Rediu ' },
            { name: 'Suhurlui ', village: 'Rediu ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Scanteiesti ' },
            { name: 'Negrea ', village: 'Schela ' },
            { name: 'Movileni ', village: 'Sendreni ' },
            { name: 'Şerbeştii Vechi ', simpleName: 'Serbestii Vechi ', village: 'Sendreni ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Slobozia Conachi ' },
            { name: 'Izvoarele ', village: 'Slobozia Conachi ' },
            { name: 'Cişmele ', simpleName: 'Cismele ', village: 'Smardan ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ', village: 'Smardan ' },
            { name: 'Rogojeni ', village: 'Suceveni ' },
            { name: 'Moscu ', village: 'Targu Bujor ' },
            { name: 'Umbrăreşti ', simpleName: 'Umbraresti ', village: 'Targu Bujor ' },
            { name: 'Ţepu de Sus ', simpleName: 'Tepu de Sus ', village: 'Tepu ' },
            { name: 'Tătarca ', simpleName: 'Tatarca ', village: 'Tulucesti ' },
            { name: 'Şiviţa ', simpleName: 'Sivita ', village: 'Tulucesti ' },
            { name: 'Condrea ', village: 'Umbraresti ' },
            { name: 'Salcia ', village: 'Umbraresti ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Umbraresti ' },
            { name: 'Torceşti ', simpleName: 'Torcesti ', village: 'Umbraresti ' },
            { name: 'Umbrăreşti-Deal ', simpleName: 'Umbraresti-Deal ', village: 'Umbraresti ' },
            { name: 'Mândreşti ', simpleName: 'Mandresti ', village: 'Valea Marului ' },
            { name: 'Costi ', village: 'Vanatori ' },
            { name: 'Odaia Manolache ', village: 'Vanatori ' },
            { name: 'Crăieşti ', simpleName: 'Craiesti ', village: 'Varlezi ' },
            {
                name: 'Brăneşti ', simpleName: 'Branesti ', village: 'Vladesti '
            }
        ]
    },
    {
        auto: 'GR ',
        name: 'Giurgiu ',
        cities: [
            { name: 'Adunaţii-Copăceni ', simpleName: 'Adunatii-Copaceni ' },
            { name: 'Bolintin-Deal ' },
            { name: 'Bolintin-Vale ' },
            { name: 'Bucşani ', simpleName: 'Bucsani ' },
            { name: 'Bulbucata ' },
            { name: 'Buturugeni ' },
            { name: 'Băneasa ', simpleName: 'Baneasa ' },
            { name: 'Clejani ' },
            { name: 'Colibaşi ', simpleName: 'Colibasi ' },
            { name: 'Comana ' },
            { name: 'Crevedia Mare ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ' },
            { name: 'Daia ' },
            { name: 'Frăteşti ', simpleName: 'Fratesti ' },
            { name: 'Ghimpaţi ', simpleName: 'Ghimpati ' },
            { name: 'Giurgiu ' },
            { name: 'Gogoşari ', simpleName: 'Gogosari ' },
            { name: 'Gostinari ' },
            { name: 'Gostinu ' },
            { name: 'Greaca ' },
            { name: 'Grădinari ', simpleName: 'Gradinari ' },
            { name: 'Găiseni ', simpleName: 'Gaiseni ' },
            { name: 'Găujani ', simpleName: 'Gaujani ' },
            { name: 'Hotarele ' },
            { name: 'Iepureşti ', simpleName: 'Iepuresti ' },
            { name: 'Izvoarele ' },
            { name: 'Joiţa ', simpleName: 'Joita ' },
            { name: 'Letca Nouă ', simpleName: 'Letca Noua ' },
            { name: 'Malu ' },
            { name: 'Mihai Bravu ' },
            { name: 'Mihăileşti ', simpleName: 'Mihailesti ' },
            { name: 'Mârşa ', simpleName: 'Marsa ' },
            { name: 'Ogrezeni ' },
            { name: 'Oinacu ' },
            { name: 'Prundu ' },
            { name: 'Putineiu ' },
            { name: 'Roata de Jos ' },
            { name: 'Răsuceni ', simpleName: 'Rasuceni ' },
            { name: 'Schitu ' },
            { name: 'Singureni ' },
            { name: 'Slobozia ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ' },
            { name: 'Toporu ' },
            { name: 'Ulmi ' },
            { name: 'Valea Dragului ' },
            { name: 'Vedea ' },
            { name: 'Vânătorii Mici ', simpleName: 'Vanatorii Mici ' },
            { name: 'Vărăşti ', simpleName: 'Varasti ' },
            { name: 'Dărăşti-Vlaşca ', simpleName: 'Darasti-Vlasca ', village: 'Adunatii-Copaceni ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ', village: 'Adunatii-Copaceni ' },
            { name: 'Varlaam ', village: 'Adunatii-Copaceni ' },
            { name: 'Frasinu ', village: 'Baneasa ' },
            { name: 'Pietrele ', village: 'Baneasa ' },
            { name: 'Sfântu Gheorghe ', simpleName: 'Sfantu Gheorghe ', village: 'Baneasa ' },
            { name: 'Mihai Vodă ', simpleName: 'Mihai Voda ', village: 'Bolintin-Deal ' },
            { name: 'Crivina ', village: 'Bolintin-Vale ' },
            { name: 'Malu Spart ', village: 'Bolintin-Vale ' },
            { name: 'Suseni ', village: 'Bolintin-Vale ' },
            { name: 'Angheleşti ', simpleName: 'Anghelesti ', village: 'Bucsani ' },
            { name: 'Goleasca ', village: 'Bucsani ' },
            { name: 'Obedeni ', village: 'Bucsani ' },
            { name: 'Podişor ', simpleName: 'Podisor ', village: 'Bucsani ' },
            { name: 'Uieşti ', simpleName: 'Uiesti ', village: 'Bucsani ' },
            { name: 'Vadu Lat ', village: 'Bucsani ' },
            { name: 'Coteni ', village: 'Bulbucata ' },
            { name: 'Făcău ', simpleName: 'Facau ', village: 'Bulbucata ' },
            { name: 'Teişori ', simpleName: 'Teisori ', village: 'Bulbucata ' },
            { name: 'Podu Ilfovăţului ', simpleName: 'Podu Ilfovatului ', village: 'Buturugeni ' },
            { name: 'Poşta ', simpleName: 'Posta ', village: 'Buturugeni ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Buturugeni ' },
            { name: 'Brăniştari ', simpleName: 'Branistari ', village: 'Calugareni ' },
            { name: 'Crucea de Piatră ', simpleName: 'Crucea de Piatra ', village: 'Calugareni ' },
            { name: 'Hulubeşti ', simpleName: 'Hulubesti ', village: 'Calugareni ' },
            { name: 'Uzunu ', village: 'Calugareni ' },
            { name: 'Neajlov ', village: 'Clejani ' },
            { name: 'Podu Doamnei ', village: 'Clejani ' },
            { name: 'Sterea ', village: 'Clejani ' },
            { name: 'Câmpurelu ', simpleName: 'Campurelu ', village: 'Colibasi ' },
            { name: 'Budeni ', village: 'Comana ' },
            { name: 'Falaştoaca ', simpleName: 'Falastoaca ', village: 'Comana ' },
            { name: 'Grădiştea ', simpleName: 'Gradistea ', village: 'Comana ' },
            { name: 'Vlad Ţepeş ', simpleName: 'Vlad Tepes ', village: 'Comana ' },
            { name: 'Crevedia Mică ', simpleName: 'Crevedia Mica ', village: 'Crevedia Mare ' },
            { name: 'Dealu ', village: 'Crevedia Mare ' },
            { name: 'Găiseanca ', simpleName: 'Gaiseanca ', village: 'Crevedia Mare ' },
            { name: 'Priboiu ', village: 'Crevedia Mare ' },
            { name: 'Sfântu Gheorghe ', simpleName: 'Sfantu Gheorghe ', village: 'Crevedia Mare ' },
            { name: 'Plopşoru ', simpleName: 'Plopsoru ', village: 'Daia ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Floresti-Stoenesti ' },
            { name: 'Palanca ', village: 'Floresti-Stoenesti ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ', village: 'Floresti-Stoenesti ' },
            { name: 'Cetatea ', village: 'Fratesti ' },
            { name: 'Remuş ', simpleName: 'Remus ', village: 'Fratesti ' },
            { name: 'Cărpenişu ', simpleName: 'Carpenisu ', village: 'Gaiseni ' },
            { name: 'Căscioarele ', simpleName: 'Cascioarele ', village: 'Gaiseni ' },
            { name: 'Podu Popa Nae ', village: 'Gaiseni ' },
            { name: 'Cetăţuia ', simpleName: 'Cetatuia ', village: 'Gaujani ' },
            { name: 'Pietrişu ', simpleName: 'Pietrisu ', village: 'Gaujani ' },
            { name: 'Copaciu ', village: 'Ghimpati ' },
            { name: 'Naipu ', village: 'Ghimpati ' },
            { name: 'Valea Plopilor ', village: 'Ghimpati ' },
            { name: 'Drăghiceanu ', simpleName: 'Draghiceanu ', village: 'Gogosari ' },
            { name: 'Izvoru ', village: 'Gogosari ' },
            { name: 'Răleşti ', simpleName: 'Ralesti ', village: 'Gogosari ' },
            { name: 'Mironeşti ', simpleName: 'Mironesti ', village: 'Gostinari ' },
            { name: 'Tântava ', simpleName: 'Tantava ', village: 'Gradinari ' },
            { name: 'Zorile ', village: 'Gradinari ' },
            { name: 'Puţu Greci ', simpleName: 'Putu Greci ', village: 'Greaca ' },
            { name: 'Zboiu ', village: 'Greaca ' },
            { name: 'Herăşti ', simpleName: 'Herasti ', village: 'Hotarele ' },
            { name: 'Isvoarele ', village: 'Hotarele ' },
            { name: 'Miloşeşti ', simpleName: 'Milosesti ', village: 'Hotarele ' },
            { name: 'Teiuşu ', simpleName: 'Teiusu ', village: 'Hotarele ' },
            { name: 'Băneşti ', simpleName: 'Banesti ', village: 'Iepuresti ' },
            { name: 'Chirculeşti ', simpleName: 'Chirculesti ', village: 'Iepuresti ' },
            { name: 'Gorneni ', village: 'Iepuresti ' },
            { name: 'Stâlpu ', simpleName: 'Stalpu ', village: 'Iepuresti ' },
            { name: 'Valter Mărăcineanu ', simpleName: 'Valter Maracineanu ', village: 'Iepuresti ' },
            { name: 'Chiriacu ', village: 'Izvoarele ' },
            { name: 'Dimitrie Cantemir ', village: 'Izvoarele ' },
            { name: 'Petru Rareş ', simpleName: 'Petru Rares ', village: 'Izvoarele ' },
            { name: 'Radu Vodă ', simpleName: 'Radu Voda ', village: 'Izvoarele ' },
            { name: 'Valea Bujorului ', village: 'Izvoarele ' },
            { name: 'Bâcu ', simpleName: 'Bacu ', village: 'Joita ' },
            { name: 'Cosoba ', village: 'Joita ' },
            { name: 'Săbăreni ', simpleName: 'Sabareni ', village: 'Joita ' },
            { name: 'Letca Veche ', village: 'Letca Noua ' },
            { name: 'Milcovăţu ', simpleName: 'Milcovatu ', village: 'Letca Noua ' },
            { name: 'Drăgănescu ', simpleName: 'Draganescu ', village: 'Mihailesti ' },
            { name: 'Novaci ', village: 'Mihailesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Mihailesti ' },
            { name: 'Hobaia ', village: 'Ogrezeni ' },
            { name: 'Braniştea ', simpleName: 'Branistea ', village: 'Oinacu ' },
            { name: 'Comasca ', village: 'Oinacu ' },
            { name: 'Puieni ', village: 'Prundu ' },
            { name: 'Hodivoaia ', village: 'Putineiu ' },
            { name: 'Vieru ', village: 'Putineiu ' },
            { name: 'Carapancea ', village: 'Rasuceni ' },
            { name: 'Cucuruzu ', village: 'Rasuceni ' },
            { name: 'Satu Nou ', village: 'Rasuceni ' },
            { name: 'Cartojani ', village: 'Roata De Jos ' },
            { name: 'Roata Mică ', simpleName: 'Roata Mica ', village: 'Roata De Jos ' },
            { name: 'Sadina ', village: 'Roata De Jos ' },
            { name: 'Bila ', village: 'Schitu ' },
            { name: 'Cămineasca ', simpleName: 'Camineasca ', village: 'Schitu ' },
            { name: 'Vlaşin ', simpleName: 'Vlasin ', village: 'Schitu ' },
            { name: 'Crânguri ', simpleName: 'Cranguri ', village: 'Singureni ' },
            { name: 'Stejaru ', village: 'Singureni ' },
            { name: 'Bălanu ', simpleName: 'Balanu ', village: 'Stanesti ' },
            { name: 'Ghizdaru ', village: 'Stanesti ' },
            { name: 'Onceşti ', simpleName: 'Oncesti ', village: 'Stanesti ' },
            { name: 'Ianculeşti ', simpleName: 'Ianculesti ', village: 'Stoenesti ' },
            { name: 'Mirău ', simpleName: 'Mirau ', village: 'Stoenesti ' },
            { name: 'Tomuleşti ', simpleName: 'Tomulesti ', village: 'Toporu ' },
            { name: 'Căscioarele ', simpleName: 'Cascioarele ', village: 'Ulmi ' },
            { name: 'Drăgăneasca ', simpleName: 'Draganeasca ', village: 'Ulmi ' },
            { name: 'Ghionea ', village: 'Ulmi ' },
            { name: 'Icoana ', village: 'Ulmi ' },
            { name: 'Moşteni ', simpleName: 'Mosteni ', village: 'Ulmi ' },
            { name: 'Poenari ', village: 'Ulmi ' },
            { name: 'Trestieni ', village: 'Ulmi ' },
            { name: 'Corbeanca ', village: 'Vanatorii Mici ' },
            { name: 'Cupele ', village: 'Vanatorii Mici ' },
            { name: 'Izvoru ', village: 'Vanatorii Mici ' },
            { name: 'Poiana lui Stângă ', simpleName: 'Poiana lui Stanga ', village: 'Vanatorii Mici ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Vanatorii Mici ' },
            { name: 'Vânătorii Mari ', simpleName: 'Vanatorii Mari ', village: 'Vanatorii Mici ' },
            { name: 'Zădăriciu ', simpleName: 'Zadariciu ', village: 'Vanatorii Mici ' },
            {
                name: 'Dobreni ', village: 'Varasti '
            }
        ]
    },
    {
        auto: 'GJ ',
        name: 'Gorj ',
        cities: [
            { name: 'Albeni ' },
            { name: 'Alimpeşti ', simpleName: 'Alimpesti ' },
            { name: 'Aninoasa ' },
            { name: 'Arcani ' },
            { name: 'Baia de Fier ' },
            { name: 'Berleşti ', simpleName: 'Berlesti ' },
            { name: 'Bolboşi ', simpleName: 'Bolbosi ' },
            { name: 'Borăscu ', simpleName: 'Borascu ' },
            { name: 'Brăneşti ', simpleName: 'Branesti ' },
            { name: 'Bumbeşti-Jiu ', simpleName: 'Bumbesti-Jiu ' },
            { name: 'Bumbeşti-Piţic ', simpleName: 'Bumbesti-Pitic ' },
            { name: 'Bustuchin ' },
            { name: 'Bâlteni ', simpleName: 'Balteni ' },
            { name: 'Băleşti ', simpleName: 'Balesti ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ' },
            { name: 'Bărbăteşti ', simpleName: 'Barbatesti ' },
            { name: 'Ciuperceni ' },
            { name: 'Crasna ' },
            { name: 'Cruşet ', simpleName: 'Cruset ' },
            { name: 'Câlnic ', simpleName: 'Calnic ' },
            { name: 'Căpreni ', simpleName: 'Capreni ' },
            { name: 'Cătunele ', simpleName: 'Catunele ' },
            { name: 'Drăgoteşti ', simpleName: 'Dragotesti ' },
            { name: 'Drăguţeşti ', simpleName: 'Dragutesti ' },
            { name: 'Dănciuleşti ', simpleName: 'Danciulesti ' },
            { name: 'Dăneşti ', simpleName: 'Danesti ' },
            { name: 'Fărcăşeşti ', simpleName: 'Farcasesti ' },
            { name: 'Glogova ' },
            { name: 'Godineşti ', simpleName: 'Godinesti ' },
            { name: 'Hurezani ' },
            { name: 'Ioneşti ', simpleName: 'Ionesti ' },
            { name: 'Jupâneşti ', simpleName: 'Jupanesti ' },
            { name: 'Leleşti ', simpleName: 'Lelesti ' },
            { name: 'Licurici ' },
            { name: 'Motru ' },
            { name: 'Muşeteşti ', simpleName: 'Musetesti ' },
            { name: 'Mătăsari ', simpleName: 'Matasari ' },
            { name: 'Negomir ' },
            { name: 'Novaci ' },
            { name: 'Padeş ', simpleName: 'Pades ' },
            { name: 'Peştişani ', simpleName: 'Pestisani ' },
            { name: 'Plopşoru ', simpleName: 'Plopsoru ' },
            { name: 'Polovragi ' },
            { name: 'Prigoria ' },
            { name: 'Rovinari ' },
            { name: 'Roşia de Amaradia ', simpleName: 'Rosia de Amaradia ' },
            { name: 'Runcu ' },
            { name: 'Samarineşti ', simpleName: 'Samarinesti ' },
            { name: 'Schela ' },
            { name: 'Scoarţa ', simpleName: 'Scoarta ' },
            { name: 'Slivileşti ', simpleName: 'Slivilesti ' },
            { name: 'Stejari ' },
            { name: 'Stoina ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ' },
            { name: 'Săcelu ', simpleName: 'Sacelu ' },
            { name: 'Săuleşti ', simpleName: 'Saulesti ' },
            { name: 'Teleşti ', simpleName: 'Telesti ' },
            { name: 'Tismana ' },
            { name: 'Turburea ' },
            { name: 'Turceni ' },
            { name: 'Turcineşti ', simpleName: 'Turcinesti ' },
            { name: 'Târgu Jiu ', simpleName: 'Targu Jiu ' },
            { name: 'Urdari ' },
            { name: 'Vladimir ' },
            { name: 'Văgiuleşti ', simpleName: 'Vagiulesti ' },
            { name: 'Ţicleni ', simpleName: 'Ticleni ' },
            { name: 'Ţânţăreni ', simpleName: 'Tantareni ' },
            { name: 'Bolboceşti ', simpleName: 'Bolbocesti ', village: 'Albeni ' },
            { name: 'Bârzeiu de Gilort ', simpleName: 'Barzeiu de Gilort ', village: 'Albeni ' },
            { name: 'Doseni ', village: 'Albeni ' },
            { name: 'Mirosloveni ', village: 'Albeni ' },
            { name: 'Pruneşti ', simpleName: 'Prunesti ', village: 'Albeni ' },
            { name: 'Ciupercenii de Olteţ ', simpleName: 'Ciupercenii de Oltet ', village: 'Alimpesti ' },
            { name: 'Corşoru ', simpleName: 'Corsoru ', village: 'Alimpesti ' },
            { name: 'Nistoreşti ', simpleName: 'Nistoresti ', village: 'Alimpesti ' },
            { name: 'Sârbeşti ', simpleName: 'Sarbesti ', village: 'Alimpesti ' },
            { name: 'Bobaia ', village: 'Aninoasa ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Aninoasa ' },
            { name: 'Groşerea ', simpleName: 'Groserea ', village: 'Aninoasa ' },
            { name: 'Sterpoaia ', village: 'Aninoasa ' },
            { name: 'Câmpofeni ', simpleName: 'Campofeni ', village: 'Arcani ' },
            { name: 'Stroieşti ', simpleName: 'Stroiesti ', village: 'Arcani ' },
            { name: 'Sănăteşti ', simpleName: 'Sanatesti ', village: 'Arcani ' },
            { name: 'Cernădia ', simpleName: 'Cernadia ', village: 'Baia De Fier ' },
            { name: 'Blidari ', village: 'Balanesti ' },
            { name: 'Cânepeşti ', simpleName: 'Canepesti ', village: 'Balanesti ' },
            { name: 'Glodeni ', village: 'Balanesti ' },
            { name: 'Ohaba ', village: 'Balanesti ' },
            { name: 'Voiteştii de Vale ', simpleName: 'Voitestii de Vale ', village: 'Balanesti ' },
            { name: 'Voiteştii din Deal ', simpleName: 'Voitestii din Deal ', village: 'Balanesti ' },
            { name: 'Ceauru ', village: 'Balesti ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Balesti ' },
            { name: 'Găvăneşti ', simpleName: 'Gavanesti ', village: 'Balesti ' },
            { name: 'Rasova ', village: 'Balesti ' },
            { name: 'Stolojani ', village: 'Balesti ' },
            { name: 'Tălpăşeşti ', simpleName: 'Talpasesti ', village: 'Balesti ' },
            { name: 'Tămăşeşti ', simpleName: 'Tamasesti ', village: 'Balesti ' },
            { name: 'Voinigeşti ', simpleName: 'Voinigesti ', village: 'Balesti ' },
            { name: 'Cocoreni ', village: 'Balteni ' },
            { name: 'Moi ', village: 'Balteni ' },
            { name: 'Peşteana Jiu ', simpleName: 'Pesteana Jiu ', village: 'Balteni ' },
            { name: 'Vlăduleni ', simpleName: 'Vladuleni ', village: 'Balteni ' },
            { name: 'Musculeşti ', simpleName: 'Musculesti ', village: 'Barbatesti ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Barbatesti ' },
            { name: 'Socu ', village: 'Barbatesti ' },
            { name: 'Bengeşti ', simpleName: 'Bengesti ', village: 'Bengesti-Ciocadia ' },
            { name: 'Bircii ', village: 'Bengesti-Ciocadia ' },
            { name: 'Bălceşti ', simpleName: 'Balcesti ', village: 'Bengesti-Ciocadia ' },
            { name: 'Ciocadia ', village: 'Bengesti-Ciocadia ' },
            { name: 'Bârzeiu ', simpleName: 'Barzeiu ', village: 'Berlesti ' },
            { name: 'Gâlceşti ', simpleName: 'Galcesti ', village: 'Berlesti ' },
            { name: 'Lihuleşti ', simpleName: 'Lihulesti ', village: 'Berlesti ' },
            { name: 'Pârâu Viu ', simpleName: 'Parau Viu ', village: 'Berlesti ' },
            { name: 'Scrada ', village: 'Berlesti ' },
            { name: 'Scurtu ', village: 'Berlesti ' },
            { name: 'Bolboasa ', village: 'Bolbosi ' },
            { name: 'Bălăceşti ', simpleName: 'Balacesti ', village: 'Bolbosi ' },
            { name: 'Igirosu ', village: 'Bolbosi ' },
            { name: 'Miclosu ', village: 'Bolbosi ' },
            { name: 'Ohaba Jiu ', village: 'Bolbosi ' },
            { name: 'Valea ', village: 'Bolbosi ' },
            { name: 'Baniu ', village: 'Borascu ' },
            { name: 'Calapăru ', simpleName: 'Calaparu ', village: 'Borascu ' },
            { name: 'Gura Menţi ', simpleName: 'Gura Menti ', village: 'Borascu ' },
            { name: 'Menţii din Dos ', simpleName: 'Mentii din Dos ', village: 'Borascu ' },
            { name: 'Miluta ', village: 'Borascu ' },
            { name: 'Scoruşu ', simpleName: 'Scorusu ', village: 'Borascu ' },
            { name: 'Brebenei ', village: 'Branesti ' },
            { name: 'Bădeşti ', simpleName: 'Badesti ', village: 'Branesti ' },
            { name: 'Capu Dealului ', village: 'Branesti ' },
            { name: 'Gilortu ', village: 'Branesti ' },
            { name: 'Pârâu ', simpleName: 'Parau ', village: 'Branesti ' },
            { name: 'Curtişoara ', simpleName: 'Curtisoara ', village: 'Bumbesti-Jiu ' },
            { name: 'Lăzăreşti ', simpleName: 'Lazaresti ', village: 'Bumbesti-Jiu ' },
            { name: 'Pleşa ', simpleName: 'Plesa ', village: 'Bumbesti-Jiu ' },
            { name: 'Tetila ', village: 'Bumbesti-Jiu ' },
            { name: 'Cârligei ', simpleName: 'Carligei ', village: 'Bumbesti-Pitic ' },
            { name: 'Poienari ', village: 'Bumbesti-Pitic ' },
            { name: 'Cionţi ', simpleName: 'Cionti ', village: 'Bustuchin ' },
            { name: 'Motorgi ', village: 'Bustuchin ' },
            { name: 'Nămete ', simpleName: 'Namete ', village: 'Bustuchin ' },
            { name: 'Poiana Seciuri ', village: 'Bustuchin ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Bustuchin ' },
            { name: 'Pojaru ', village: 'Bustuchin ' },
            { name: 'Valea Pojarului ', village: 'Bustuchin ' },
            { name: 'Câlnicu de Sus ', simpleName: 'Calnicu de Sus ', village: 'Calnic ' },
            { name: 'Didileşti ', simpleName: 'Didilesti ', village: 'Calnic ' },
            { name: 'Găleşoaia ', simpleName: 'Galesoaia ', village: 'Calnic ' },
            { name: 'Hodoreasca ', village: 'Calnic ' },
            { name: 'Pieptani ', village: 'Calnic ' },
            { name: 'Pinoasa ', village: 'Calnic ' },
            { name: 'Stejerei ', village: 'Calnic ' },
            { name: 'Vâlceaua ', simpleName: 'Valceaua ', village: 'Calnic ' },
            { name: 'Aluniş ', simpleName: 'Alunis ', village: 'Capreni ' },
            { name: 'Brăteşti ', simpleName: 'Bratesti ', village: 'Capreni ' },
            { name: 'Bulbuceni ', village: 'Capreni ' },
            { name: 'Cetatea ', village: 'Capreni ' },
            { name: 'Cornetu ', village: 'Capreni ' },
            { name: 'Dealu Spirei ', village: 'Capreni ' },
            { name: 'Satu Nou ', village: 'Capreni ' },
            { name: 'Dealu Viilor ', village: 'Catunele ' },
            { name: 'Lupoaia ', village: 'Catunele ' },
            { name: 'Steic ', village: 'Catunele ' },
            { name: 'Valea Mânăstirii ', simpleName: 'Valea Manastirii ', village: 'Catunele ' },
            { name: 'Valea Perilor ', village: 'Catunele ' },
            { name: 'Boboieşti ', simpleName: 'Boboiesti ', village: 'Ciuperceni ' },
            { name: 'Peşteana Vulcan ', simpleName: 'Pesteana Vulcan ', village: 'Ciuperceni ' },
            { name: 'Priporu ', village: 'Ciuperceni ' },
            { name: 'Strâmba Vulcan ', simpleName: 'Stramba Vulcan ', village: 'Ciuperceni ' },
            { name: 'Vârtopu ', simpleName: 'Vartopu ', village: 'Ciuperceni ' },
            { name: 'Zorzila ', village: 'Ciuperceni ' },
            { name: 'Aninişu din Deal ', simpleName: 'Aninisu din Deal ', village: 'Crasna ' },
            { name: 'Aninişu din Vale ', simpleName: 'Aninisu din Vale ', village: 'Crasna ' },
            { name: 'Buzeşti ', simpleName: 'Buzesti ', village: 'Crasna ' },
            { name: 'Crasna din Deal ', village: 'Crasna ' },
            { name: 'Cărpiniş ', simpleName: 'Carpinis ', village: 'Crasna ' },
            { name: 'Drăgoieşti ', simpleName: 'Dragoiesti ', village: 'Crasna ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ', village: 'Crasna ' },
            { name: 'Radoşi ', simpleName: 'Radosi ', village: 'Crasna ' },
            { name: 'Bojinu ', village: 'Cruset ' },
            { name: 'Marineşti ', simpleName: 'Marinesti ', village: 'Cruset ' },
            { name: 'Mierea ', village: 'Cruset ' },
            { name: 'Miericeaua ', village: 'Cruset ' },
            { name: 'Măiag ', simpleName: 'Maiag ', village: 'Cruset ' },
            { name: 'Slămneşti ', simpleName: 'Slamnesti ', village: 'Cruset ' },
            { name: 'Slăvuţa ', simpleName: 'Slavuta ', village: 'Cruset ' },
            { name: 'Urda de Jos ', village: 'Cruset ' },
            { name: 'Văluţa ', simpleName: 'Valuta ', village: 'Cruset ' },
            { name: 'Bibuleşti ', simpleName: 'Bibulesti ', village: 'Danciulesti ' },
            { name: 'Hălăngeşti ', simpleName: 'Halangesti ', village: 'Danciulesti ' },
            { name: 'Obârşia ', simpleName: 'Obarsia ', village: 'Danciulesti ' },
            { name: 'Petrăchei ', simpleName: 'Petrachei ', village: 'Danciulesti ' },
            { name: 'Rădineşti ', simpleName: 'Radinesti ', village: 'Danciulesti ' },
            { name: 'Zăicoiu ', simpleName: 'Zaicoiu ', village: 'Danciulesti ' },
            { name: 'Barza ', village: 'Danesti ' },
            { name: 'Botorogi ', village: 'Danesti ' },
            { name: 'Brătuia ', simpleName: 'Bratuia ', village: 'Danesti ' },
            { name: 'Bucureasa ', village: 'Danesti ' },
            { name: 'Merfuleşti ', simpleName: 'Merfulesti ', village: 'Danesti ' },
            { name: 'Trocani ', village: 'Danesti ' },
            { name: 'Ungureni ', village: 'Danesti ' },
            { name: 'Văcarea ', simpleName: 'Vacarea ', village: 'Danesti ' },
            { name: 'Şasa ', simpleName: 'Sasa ', village: 'Danesti ' },
            { name: 'Ţârculeşti ', simpleName: 'Tarculesti ', village: 'Danesti ' },
            { name: 'Corobăi ', simpleName: 'Corobai ', village: 'Dragotesti ' },
            { name: 'Trestioara ', village: 'Dragotesti ' },
            { name: 'Cârbeşti ', simpleName: 'Carbesti ', village: 'Dragutesti ' },
            { name: 'Dâmbova ', simpleName: 'Dambova ', village: 'Dragutesti ' },
            { name: 'Iaşi-Gorj ', simpleName: 'Iasi-Gorj ', village: 'Dragutesti ' },
            { name: 'Tâlveşti ', simpleName: 'Talvesti ', village: 'Dragutesti ' },
            { name: 'Urecheşti ', simpleName: 'Urechesti ', village: 'Dragutesti ' },
            { name: 'Fărcăşeşti Moşneni ', simpleName: 'Farcasesti Mosneni ', village: 'Farcasesti ' },
            { name: 'Peşteana de Jos ', simpleName: 'Pesteana de Jos ', village: 'Farcasesti ' },
            { name: 'Rogojel ', village: 'Farcasesti ' },
            { name: 'Roşia Jiu ', simpleName: 'Rosia Jiu ', village: 'Farcasesti ' },
            { name: 'Timişeni ', simpleName: 'Timiseni ', village: 'Farcasesti ' },
            { name: 'Valea cu Apă ', simpleName: 'Valea cu Apa ', village: 'Farcasesti ' },
            { name: 'Cleşneşti ', simpleName: 'Clesnesti ', village: 'Glogova ' },
            { name: 'Cămuieşti ', simpleName: 'Camuiesti ', village: 'Glogova ' },
            { name: 'Iormăneşti ', simpleName: 'Iormanesti ', village: 'Glogova ' },
            { name: 'Olteanu ', village: 'Glogova ' },
            { name: 'Arjoci ', village: 'Godinesti ' },
            { name: 'Chiliu ', village: 'Godinesti ' },
            { name: 'Câlceşti ', simpleName: 'Calcesti ', village: 'Godinesti ' },
            { name: 'Pârâu de Pripor ', simpleName: 'Parau de Pripor ', village: 'Godinesti ' },
            { name: 'Pârâu de Vale ', simpleName: 'Parau de Vale ', village: 'Godinesti ' },
            { name: 'Rătez ', simpleName: 'Ratez ', village: 'Godinesti ' },
            { name: 'Busuioci ', village: 'Hurezani ' },
            { name: 'Pegeni ', village: 'Hurezani ' },
            { name: 'Plopu ', village: 'Hurezani ' },
            { name: 'Totea de Hurezani ', village: 'Hurezani ' },
            { name: 'Gura Şuşiţei ', simpleName: 'Gura Susitei ', village: 'Ionesti ' },
            { name: 'Ilieşti ', simpleName: 'Iliesti ', village: 'Ionesti ' },
            { name: 'Picu ', village: 'Ionesti ' },
            { name: 'Boia ', village: 'Jupanesti ' },
            { name: 'Pârâu Boia ', simpleName: 'Parau Boia ', village: 'Jupanesti ' },
            { name: 'Vidin ', village: 'Jupanesti ' },
            { name: 'Vierşani ', simpleName: 'Viersani ', village: 'Jupanesti ' },
            { name: 'Frăteşti ', simpleName: 'Fratesti ', village: 'Lelesti ' },
            { name: 'Rasoviţa ', simpleName: 'Rasovita ', village: 'Lelesti ' },
            { name: 'Frumuşei ', simpleName: 'Frumusei ', village: 'Licurici ' },
            { name: 'Negreni ', village: 'Licurici ' },
            { name: 'Totea ', village: 'Licurici ' },
            { name: 'Colţeşti ', simpleName: 'Coltesti ', village: 'Logresti ' },
            { name: 'Frunza ', village: 'Logresti ' },
            { name: 'Logreşti Moşteni ', simpleName: 'Logresti Mosteni ', village: 'Logresti ' },
            { name: 'Măru ', simpleName: 'Maru ', village: 'Logresti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Logresti ' },
            { name: 'Seaca ', village: 'Logresti ' },
            { name: 'Târgu Logreşti ', simpleName: 'Targu Logresti ', village: 'Logresti ' },
            { name: 'Brădet ', simpleName: 'Bradet ', village: 'Matasari ' },
            { name: 'Brădeţel ', simpleName: 'Bradetel ', village: 'Matasari ' },
            { name: 'Croici ', village: 'Matasari ' },
            { name: 'Runcurel ', village: 'Matasari ' },
            { name: 'Dealu Pomilor ', village: 'Motru ' },
            { name: 'Horăşti ', simpleName: 'Horasti ', village: 'Motru ' },
            { name: 'Leurda ', village: 'Motru ' },
            { name: 'Lupoiţa ', simpleName: 'Lupoita ', village: 'Motru ' },
            { name: 'Ploştina ', simpleName: 'Plostina ', village: 'Motru ' },
            { name: 'Roşiuţa ', simpleName: 'Rosiuta ', village: 'Motru ' },
            { name: 'Râpa ', simpleName: 'Rapa ', village: 'Motru ' },
            { name: 'Însurăţei ', simpleName: 'Insuratei ', village: 'Motru ' },
            { name: 'Arşeni ', simpleName: 'Arseni ', village: 'Musetesti ' },
            { name: 'Bârcaciu ', simpleName: 'Barcaciu ', village: 'Musetesti ' },
            { name: 'Grui ', village: 'Musetesti ' },
            { name: 'Gămani ', simpleName: 'Gamani ', village: 'Musetesti ' },
            { name: 'Stănceşti ', simpleName: 'Stancesti ', village: 'Musetesti ' },
            { name: 'Stănceşti Larga ', simpleName: 'Stancesti Larga ', village: 'Musetesti ' },
            { name: 'Artanu ', village: 'Negomir ' },
            { name: 'Bohorel ', village: 'Negomir ' },
            { name: 'Condeieşti ', simpleName: 'Condeiesti ', village: 'Negomir ' },
            { name: 'Nucetu ', village: 'Negomir ' },
            { name: 'Orzu ', village: 'Negomir ' },
            { name: 'Paltinu ', village: 'Negomir ' },
            { name: 'Raci ', village: 'Negomir ' },
            { name: 'Ursoaia ', village: 'Negomir ' },
            { name: 'Valea Racilor ', village: 'Negomir ' },
            { name: 'Berceşti ', simpleName: 'Bercesti ', village: 'Novaci ' },
            { name: 'Hirişeşti ', simpleName: 'Hirisesti ', village: 'Novaci ' },
            { name: 'Pociovaliştea ', simpleName: 'Pociovalistea ', village: 'Novaci ' },
            { name: 'Siteşti ', simpleName: 'Sitesti ', village: 'Novaci ' },
            { name: 'Apa Neagră ', simpleName: 'Apa Neagra ', village: 'Pades ' },
            { name: 'Cerna-Sat ', village: 'Pades ' },
            { name: 'Cloşani ', simpleName: 'Closani ', village: 'Pades ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Pades ' },
            { name: 'Motru Sec ', village: 'Pades ' },
            { name: 'Orzeşti ', simpleName: 'Orzesti ', village: 'Pades ' },
            { name: 'Văieni ', simpleName: 'Vaieni ', village: 'Pades ' },
            { name: 'Boroşteni ', simpleName: 'Borosteni ', village: 'Pestisani ' },
            { name: 'Brădiceni ', simpleName: 'Bradiceni ', village: 'Pestisani ' },
            { name: 'Frânceşti ', simpleName: 'Francesti ', village: 'Pestisani ' },
            { name: 'Gureni ', village: 'Pestisani ' },
            { name: 'Hobiţa ', simpleName: 'Hobita ', village: 'Pestisani ' },
            { name: 'Seuca ', village: 'Pestisani ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Plopsoru ' },
            { name: 'Broştenii de Sus ', simpleName: 'Brostenii de Sus ', village: 'Plopsoru ' },
            { name: 'Ceplea ', village: 'Plopsoru ' },
            { name: 'Cursaru ', village: 'Plopsoru ' },
            { name: 'Deleni ', village: 'Plopsoru ' },
            { name: 'Izvoarele ', village: 'Plopsoru ' },
            { name: 'Olari ', village: 'Plopsoru ' },
            { name: 'Piscuri ', village: 'Plopsoru ' },
            { name: 'Sărdăneşti ', simpleName: 'Sardanesti ', village: 'Plopsoru ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Plopsoru ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ', village: 'Polovragi ' },
            { name: 'Bucşana ', simpleName: 'Bucsana ', village: 'Prigoria ' },
            { name: 'Burlani ', village: 'Prigoria ' },
            { name: 'Călugăreasa ', simpleName: 'Calugareasa ', village: 'Prigoria ' },
            { name: 'Dobrana ', village: 'Prigoria ' },
            { name: 'Negoieşti ', simpleName: 'Negoiesti ', village: 'Prigoria ' },
            { name: 'Zorleşti ', simpleName: 'Zorlesti ', village: 'Prigoria ' },
            { name: 'Becheni ', village: 'Rosia De Amaradia ' },
            { name: 'Dealu Viei ', village: 'Rosia De Amaradia ' },
            { name: 'Ruget ', village: 'Rosia De Amaradia ' },
            { name: 'Seciurile ', village: 'Rosia De Amaradia ' },
            { name: 'Stejaru ', village: 'Rosia De Amaradia ' },
            { name: 'Şitoaia ', simpleName: 'Sitoaia ', village: 'Rosia De Amaradia ' },
            { name: 'Vârt ', simpleName: 'Vart ', village: 'Rovinari ' },
            { name: 'Bâlta ', simpleName: 'Balta ', village: 'Runcu ' },
            { name: 'Bâltişoara ', simpleName: 'Baltisoara ', village: 'Runcu ' },
            { name: 'Dobriţa ', simpleName: 'Dobrita ', village: 'Runcu ' },
            { name: 'Răchiţi ', simpleName: 'Rachiti ', village: 'Runcu ' },
            { name: 'Suseni ', village: 'Runcu ' },
            { name: 'Valea Mare ', village: 'Runcu ' },
            { name: 'Blahniţa de Sus ', simpleName: 'Blahnita de Sus ', village: 'Sacelu ' },
            { name: 'Hăieşti ', simpleName: 'Haiesti ', village: 'Sacelu ' },
            { name: 'Jeriştea ', simpleName: 'Jeristea ', village: 'Sacelu ' },
            { name: 'Maghereşti ', simpleName: 'Magheresti ', village: 'Sacelu ' },
            { name: 'Boca ', village: 'Samarinesti ' },
            { name: 'Băzăvani ', simpleName: 'Bazavani ', village: 'Samarinesti ' },
            { name: 'Duculeşti ', simpleName: 'Duculesti ', village: 'Samarinesti ' },
            { name: 'Larga ', village: 'Samarinesti ' },
            { name: 'Valea Bisericii ', village: 'Samarinesti ' },
            { name: 'Valea Mică ', simpleName: 'Valea Mica ', village: 'Samarinesti ' },
            { name: 'Valea Poienii ', village: 'Samarinesti ' },
            { name: 'Ţirioi ', simpleName: 'Tirioi ', village: 'Samarinesti ' },
            { name: 'Bibeşti ', simpleName: 'Bibesti ', village: 'Saulesti ' },
            { name: 'Dolceşti ', simpleName: 'Dolcesti ', village: 'Saulesti ' },
            { name: 'Purcaru ', village: 'Saulesti ' },
            { name: 'Arsuri ', village: 'Schela ' },
            { name: 'Gornăcel ', simpleName: 'Gornacel ', village: 'Schela ' },
            { name: 'Păjiştele ', simpleName: 'Pajistele ', village: 'Schela ' },
            { name: 'Sâmbotin ', simpleName: 'Sambotin ', village: 'Schela ' },
            { name: 'Bobu ', village: 'Scoarta ' },
            { name: 'Budieni ', village: 'Scoarta ' },
            { name: 'Cerătu de Copăcioasa ', simpleName: 'Ceratu de Copacioasa ', village: 'Scoarta ' },
            { name: 'Colibaşi ', simpleName: 'Colibasi ', village: 'Scoarta ' },
            { name: 'Copăcioasa ', simpleName: 'Copacioasa ', village: 'Scoarta ' },
            { name: 'Câmpu Mare ', simpleName: 'Campu Mare ', village: 'Scoarta ' },
            { name: 'Lazuri ', village: 'Scoarta ' },
            { name: 'Lintea ', village: 'Scoarta ' },
            { name: 'Mogoşani ', simpleName: 'Mogosani ', village: 'Scoarta ' },
            { name: 'Pişteştii din Deal ', simpleName: 'Pistestii din Deal ', village: 'Scoarta ' },
            { name: 'Cojmăneşti ', simpleName: 'Cojmanesti ', village: 'Slivilesti ' },
            { name: 'Miculeşti ', simpleName: 'Miculesti ', village: 'Slivilesti ' },
            { name: 'Strâmtu ', simpleName: 'Stramtu ', village: 'Slivilesti ' },
            { name: 'Sura ', village: 'Slivilesti ' },
            { name: 'Tehomir ', village: 'Slivilesti ' },
            { name: 'Şiacu ', simpleName: 'Siacu ', village: 'Slivilesti ' },
            { name: 'Ştiucani ', simpleName: 'Stiucani ', village: 'Slivilesti ' },
            { name: 'Alexeni ', village: 'Stanesti ' },
            { name: 'Bălani ', simpleName: 'Balani ', village: 'Stanesti ' },
            { name: 'Curpen ', village: 'Stanesti ' },
            { name: 'Căleşti ', simpleName: 'Calesti ', village: 'Stanesti ' },
            { name: 'Măzăroi ', simpleName: 'Mazaroi ', village: 'Stanesti ' },
            { name: 'Obreja ', village: 'Stanesti ' },
            { name: 'Pârvuleşti ', simpleName: 'Parvulesti ', village: 'Stanesti ' },
            { name: 'Vaidei ', village: 'Stanesti ' },
            { name: 'Vălari ', simpleName: 'Valari ', village: 'Stanesti ' },
            { name: 'Baloşani ', simpleName: 'Balosani ', village: 'Stejari ' },
            { name: 'Băceşti ', simpleName: 'Bacesti ', village: 'Stejari ' },
            { name: 'Dealu Leului ', village: 'Stejari ' },
            { name: 'Piscoiu ', village: 'Stejari ' },
            { name: 'Popeşti Stejari ', simpleName: 'Popesti Stejari ', village: 'Stejari ' },
            { name: 'Ciorari ', village: 'Stoina ' },
            { name: 'Mieluşei ', simpleName: 'Mielusei ', village: 'Stoina ' },
            { name: 'Păişani ', simpleName: 'Paisani ', village: 'Stoina ' },
            { name: 'Toiaga ', village: 'Stoina ' },
            { name: 'Ulmet ', village: 'Stoina ' },
            { name: 'Urda de Sus ', village: 'Stoina ' },
            { name: 'Arpadia ', village: 'Tantareni ' },
            { name: 'Chiciora ', village: 'Tantareni ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Tantareni ' },
            { name: 'Bârseşti ', simpleName: 'Barsesti ', village: 'Targu Jiu ' },
            { name: 'Drăgoeni ', simpleName: 'Dragoeni ', village: 'Targu Jiu ' },
            { name: 'Iezureni ', village: 'Targu Jiu ' },
            { name: 'Polata ', village: 'Targu Jiu ' },
            { name: 'Preajba Mare ', village: 'Targu Jiu ' },
            { name: 'Romaneşti ', simpleName: 'Romanesti ', village: 'Targu Jiu ' },
            { name: 'Slobozia ', village: 'Targu Jiu ' },
            { name: 'Ursaţi ', simpleName: 'Ursati ', village: 'Targu Jiu ' },
            { name: 'Buduhala ', village: 'Telesti ' },
            { name: 'Şomăneşti ', simpleName: 'Somanesti ', village: 'Telesti ' },
            { name: 'Blahniţa de Jos ', simpleName: 'Blahnita de Jos ', village: 'Tirgu Carbunesti ' },
            { name: 'Cojani ', village: 'Tirgu Carbunesti ' },
            { name: 'Creţeşti ', simpleName: 'Cretesti ', village: 'Tirgu Carbunesti ' },
            { name: 'Curteana ', village: 'Tirgu Carbunesti ' },
            { name: 'Cărbuneşti-Sat ', simpleName: 'Carbunesti-Sat ', village: 'Tirgu Carbunesti ' },
            { name: 'Floreşteni ', simpleName: 'Floresteni ', village: 'Tirgu Carbunesti ' },
            { name: 'Măceşu ', simpleName: 'Macesu ', village: 'Tirgu Carbunesti ' },
            { name: 'Pojogeni ', village: 'Tirgu Carbunesti ' },
            { name: 'Rogojeni ', village: 'Tirgu Carbunesti ' },
            { name: 'Târgu Cărbuneşti ', simpleName: 'Targu Carbunesti ', village: 'Tirgu Carbunesti ' },
            { name: 'Ştefăneşti ', simpleName: 'Stefanesti ', village: 'Tirgu Carbunesti ' },
            { name: 'Celei ', village: 'Tismana ' },
            { name: 'Costeni ', village: 'Tismana ' },
            { name: 'Gornoviţa ', simpleName: 'Gornovita ', village: 'Tismana ' },
            { name: 'Isvarna ', village: 'Tismana ' },
            { name: 'Pocruia ', village: 'Tismana ' },
            { name: 'Racoţi ', simpleName: 'Racoti ', village: 'Tismana ' },
            { name: 'Sohodol ', village: 'Tismana ' },
            { name: 'Topeşti ', simpleName: 'Topesti ', village: 'Tismana ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Tismana ' },
            { name: 'Vânata ', simpleName: 'Vanata ', village: 'Tismana ' },
            { name: 'Cocorova ', village: 'Turburea ' },
            { name: 'Poiana ', village: 'Turburea ' },
            { name: 'Spahii ', village: 'Turburea ' },
            { name: 'Şipotu ', simpleName: 'Sipotu ', village: 'Turburea ' },
            { name: 'Gârbovu ', simpleName: 'Garbovu ', village: 'Turceni ' },
            { name: 'Jilţu ', simpleName: 'Jiltu ', village: 'Turceni ' },
            { name: 'Murgeşti ', simpleName: 'Murgesti ', village: 'Turceni ' },
            { name: 'Strâmba Jiu ', simpleName: 'Stramba Jiu ', village: 'Turceni ' },
            { name: 'Valea Viei ', village: 'Turceni ' },
            { name: 'Cartiu ', village: 'Turcinesti ' },
            { name: 'Horezu ', village: 'Turcinesti ' },
            { name: 'Rugi ', village: 'Turcinesti ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Urdari ' },
            { name: 'Hotăroasa ', simpleName: 'Hotaroasa ', village: 'Urdari ' },
            { name: 'Covrigi ', village: 'Vagiulesti ' },
            { name: 'Cârciu ', simpleName: 'Carciu ', village: 'Vagiulesti ' },
            { name: 'Murgileşti ', simpleName: 'Murgilesti ', village: 'Vagiulesti ' },
            { name: 'Valea Motrului ', village: 'Vagiulesti ' },
            { name: 'Andreeşti ', simpleName: 'Andreesti ', village: 'Vladimir ' },
            { name: 'Frasin ', village: 'Vladimir ' },
            {
                name: 'Valea Deşului ', simpleName: 'Valea Desului ', village: 'Vladimir '
            }
        ]
    },
    {
        auto: 'HR ',
        name: 'Harghita ',
        cities: [
            { name: 'Atid ' },
            { name: 'Avrămeşti ', simpleName: 'Avramesti ' },
            { name: 'Bilbor ' },
            { name: 'Borsec ' },
            { name: 'Brădeşti ', simpleName: 'Bradesti ' },
            { name: 'Băile Tuşnad ', simpleName: 'Baile Tusnad ' },
            { name: 'Bălan ', simpleName: 'Balan ' },
            { name: 'Ciucsângeorgiu ', simpleName: 'Ciucsangeorgiu ' },
            { name: 'Ciumani ' },
            { name: 'Corbu ' },
            { name: 'Corund ' },
            { name: 'Cozmeni ' },
            { name: 'Cristuru Secuiesc ' },
            { name: 'Cârţa ', simpleName: 'Carta ' },
            { name: 'Căpâlniţa ', simpleName: 'Capalnita ' },
            { name: 'Dealu ' },
            { name: 'Ditrău ', simpleName: 'Ditrau ' },
            { name: 'Dârjiu ', simpleName: 'Darjiu ' },
            { name: 'Dăneşti ', simpleName: 'Danesti ' },
            { name: 'Frumoasa ' },
            { name: 'Gheorgheni ' },
            { name: 'Gălăuţaş ', simpleName: 'Galautas ' },
            { name: 'Joseni ' },
            { name: 'Lueta ' },
            { name: 'Lunca de Jos ' },
            { name: 'Lunca de Sus ' },
            { name: 'Lupeni ' },
            { name: 'Lăzarea ', simpleName: 'Lazarea ' },
            { name: 'Mereşti ', simpleName: 'Meresti ' },
            { name: 'Miercurea Ciuc ' },
            { name: 'Mihăileni ', simpleName: 'Mihaileni ' },
            { name: 'Mugeni ' },
            { name: 'Mădăraş ', simpleName: 'Madaras ' },
            { name: 'Mărtiniş ', simpleName: 'Martinis ' },
            { name: 'Ocland ' },
            { name: 'Odorheiu Secuiesc ' },
            { name: 'Plăieşii de Jos ', simpleName: 'Plaiesii de Jos ' },
            { name: 'Praid ' },
            { name: 'Păuleni-Ciuc ', simpleName: 'Pauleni-Ciuc ' },
            { name: 'Remetea ' },
            { name: 'Secuieni ' },
            { name: 'Siculeni ' },
            { name: 'Subcetate ' },
            { name: 'Suseni ' },
            { name: 'Sâncrăieni ', simpleName: 'Sancraieni ' },
            { name: 'Sândominic ', simpleName: 'Sandominic ' },
            { name: 'Sânmartin ', simpleName: 'Sanmartin ' },
            { name: 'Sânsimion ', simpleName: 'Sansimion ' },
            { name: 'Săcel ', simpleName: 'Sacel ' },
            { name: 'Sărmaş ', simpleName: 'Sarmas ' },
            { name: 'Tomeşti ', simpleName: 'Tomesti ' },
            { name: 'Topliţa ', simpleName: 'Toplita ' },
            { name: 'Tulgheş ', simpleName: 'Tulghes ' },
            { name: 'Tuşnad ', simpleName: 'Tusnad ' },
            { name: 'Ulieş ', simpleName: 'Ulies ' },
            { name: 'Vlăhiţa ', simpleName: 'Vlahita ' },
            { name: 'Voşlăbeni ', simpleName: 'Voslabeni ' },
            { name: 'Vărşag ', simpleName: 'Varsag ' },
            { name: 'Zetea ' },
            { name: 'Şimoneşti ', simpleName: 'Simonesti ' },
            { name: 'Crişeni ', simpleName: 'Criseni ', village: 'Atid ' },
            { name: 'Cuşmed ', simpleName: 'Cusmed ', village: 'Atid ' },
            { name: 'Inlăceni ', simpleName: 'Inlaceni ', village: 'Atid ' },
            { name: 'Şiclod ', simpleName: 'Siclod ', village: 'Atid ' },
            { name: 'Andreeni ', village: 'Avramesti ' },
            { name: 'Cecheşti ', simpleName: 'Cechesti ', village: 'Avramesti ' },
            { name: 'Firtănuş ', simpleName: 'Firtanus ', village: 'Avramesti ' },
            { name: 'Goagiu ', village: 'Avramesti ' },
            { name: 'Laz-Firtănuş ', simpleName: 'Laz-Firtanus ', village: 'Avramesti ' },
            { name: 'Laz-Şoimuş ', simpleName: 'Laz-Soimus ', village: 'Avramesti ' },
            { name: 'Medişoru Mic ', simpleName: 'Medisoru Mic ', village: 'Avramesti ' },
            { name: 'Carpitus ', village: 'Baile Tusnad ' },
            { name: 'Răchitiş ', simpleName: 'Rachitis ', village: 'Bilbor ' },
            { name: 'Satu Mare ', village: 'Bradesti ' },
            { name: 'Târnoviţa ', simpleName: 'Tarnovita ', village: 'Bradesti ' },
            { name: 'Ineu ', village: 'Carta ' },
            { name: 'Armăşeni ', simpleName: 'Armaseni ', village: 'Ciucsangeorgiu ' },
            { name: 'Armăşenii Noi ', simpleName: 'Armasenii Noi ', village: 'Ciucsangeorgiu ' },
            { name: 'Bancu ', village: 'Ciucsangeorgiu ' },
            { name: 'Ciobăniş ', simpleName: 'Ciobanis ', village: 'Ciucsangeorgiu ' },
            { name: 'Cotormani ', village: 'Ciucsangeorgiu ' },
            { name: 'Eghersec ', village: 'Ciucsangeorgiu ' },
            { name: 'Ghiurche ', village: 'Ciucsangeorgiu ' },
            { name: 'Potiond ', village: 'Ciucsangeorgiu ' },
            { name: 'Capu Corbului ', village: 'Corbu ' },
            { name: 'Atia ', village: 'Corund ' },
            { name: 'Calonda ', village: 'Corund ' },
            { name: 'Fântâna Brazilor ', simpleName: 'Fantana Brazilor ', village: 'Corund ' },
            { name: 'Valea lui Pavel ', village: 'Corund ' },
            { name: 'Lăzăreşti ', simpleName: 'Lazaresti ', village: 'Cozmeni ' },
            { name: 'Filiaş ', simpleName: 'Filias ', village: 'Cristuru Secuiesc ' },
            { name: 'Mujna ', village: 'Darjiu ' },
            { name: 'Fâncel ', simpleName: 'Fancel ', village: 'Dealu ' },
            { name: 'Sâncrai ', simpleName: 'Sancrai ', village: 'Dealu ' },
            { name: 'Tibod ', village: 'Dealu ' },
            { name: 'Tămaşu ', simpleName: 'Tamasu ', village: 'Dealu ' },
            { name: 'Ulcani ', village: 'Dealu ' },
            { name: 'Valea Rotundă ', simpleName: 'Valea Rotunda ', village: 'Dealu ' },
            { name: 'Jolotca ', village: 'Ditrau ' },
            { name: 'Ţengheler ', simpleName: 'Tengheler ', village: 'Ditrau ' },
            { name: 'Alexandriţa ', simpleName: 'Alexandrita ', village: 'Felicieni ' },
            { name: 'Arvăţeni ', simpleName: 'Arvateni ', village: 'Felicieni ' },
            { name: 'Cireşeni ', simpleName: 'Cireseni ', village: 'Felicieni ' },
            { name: 'Feliceni ', village: 'Felicieni ' },
            { name: 'Forţeni ', simpleName: 'Forteni ', village: 'Felicieni ' },
            { name: 'Hoghia ', village: 'Felicieni ' },
            { name: 'Oţeni ', simpleName: 'Oteni ', village: 'Felicieni ' },
            { name: 'Poloniţa ', simpleName: 'Polonita ', village: 'Felicieni ' },
            { name: 'Teleac ', village: 'Felicieni ' },
            { name: 'Tăureni ', simpleName: 'Taureni ', village: 'Felicieni ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Felicieni ' },
            { name: 'Bârzava ', simpleName: 'Barzava ', village: 'Frumoasa ' },
            { name: 'Făgeţel ', simpleName: 'Fagetel ', village: 'Frumoasa ' },
            { name: 'Nicoleşti ', simpleName: 'Nicolesti ', village: 'Frumoasa ' },
            { name: 'Dealu Armanului ', village: 'Galautas ' },
            { name: 'Gălăuţaş-Pârău ', simpleName: 'Galautas-Parau ', village: 'Galautas ' },
            { name: 'Nuţeni ', simpleName: 'Nuteni ', village: 'Galautas ' },
            { name: 'Plopiş ', simpleName: 'Plopis ', village: 'Galautas ' },
            { name: 'Preluca ', village: 'Galautas ' },
            { name: 'Toleşeni ', simpleName: 'Toleseni ', village: 'Galautas ' },
            { name: 'Zăpodea ', simpleName: 'Zapodea ', village: 'Galautas ' },
            { name: 'Covacipeter ', village: 'Gheorgheni ' },
            { name: 'Lacu Roşu ', simpleName: 'Lacu Rosu ', village: 'Gheorgheni ' },
            { name: 'Vargatac ', village: 'Gheorgheni ' },
            { name: 'Visafolio ', village: 'Gheorgheni ' },
            { name: 'Borzont ', village: 'Joseni ' },
            { name: 'Bucin ', village: 'Joseni ' },
            { name: 'Ghiduţ ', simpleName: 'Ghidut ', village: 'Lazarea ' },
            { name: 'Băile Chirui ', simpleName: 'Baile Chirui ', village: 'Lueta ' },
            { name: 'Baraţcoş ', simpleName: 'Baratcos ', village: 'Lunca De Jos ' },
            { name: 'Poiana Fagului ', village: 'Lunca De Jos ' },
            { name: 'Puntea Lupului ', village: 'Lunca De Jos ' },
            { name: 'Valea Boroş ', simpleName: 'Valea Boros ', village: 'Lunca De Jos ' },
            { name: 'Valea Capelei ', village: 'Lunca De Jos ' },
            { name: 'Valea Rece ', village: 'Lunca De Jos ' },
            { name: 'Valea lui Antaloc ', village: 'Lunca De Jos ' },
            { name: 'Valea Întunecoasă ', simpleName: 'Valea Intunecoasa ', village: 'Lunca De Jos ' },
            { name: 'Comiat ', village: 'Lunca De Sus ' },
            { name: 'Izvorul Trotuşului ', simpleName: 'Izvorul Trotusului ', village: 'Lunca De Sus ' },
            { name: 'Păltiniş-Ciuc ', simpleName: 'Paltinis-Ciuc ', village: 'Lunca De Sus ' },
            { name: 'Valea Gârbea ', simpleName: 'Valea Garbea ', village: 'Lunca De Sus ' },
            { name: 'Valea Ugra ', village: 'Lunca De Sus ' },
            { name: 'Bisericani ', village: 'Lupeni ' },
            { name: 'Bulgăreni ', simpleName: 'Bulgareni ', village: 'Lupeni ' },
            { name: 'Firtuşu ', simpleName: 'Firtusu ', village: 'Lupeni ' },
            { name: 'Morăreni ', simpleName: 'Morareni ', village: 'Lupeni ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ', village: 'Lupeni ' },
            { name: 'Păuleni ', simpleName: 'Pauleni ', village: 'Lupeni ' },
            { name: 'Satu Mic ', village: 'Lupeni ' },
            { name: 'Sâncel ', simpleName: 'Sancel ', village: 'Lupeni ' },
            { name: 'Aldea ', village: 'Martinis ' },
            { name: 'Bădeni ', simpleName: 'Badeni ', village: 'Martinis ' },
            { name: 'Chinuşu ', simpleName: 'Chinusu ', village: 'Martinis ' },
            { name: 'Comăneşti ', simpleName: 'Comanesti ', village: 'Martinis ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Martinis ' },
            { name: 'Ghipeş ', simpleName: 'Ghipes ', village: 'Martinis ' },
            { name: 'Locodeni ', village: 'Martinis ' },
            { name: 'Orăşeni ', simpleName: 'Oraseni ', village: 'Martinis ' },
            { name: 'Petreni ', village: 'Martinis ' },
            { name: 'Rareş ', simpleName: 'Rares ', village: 'Martinis ' },
            { name: 'Sânpaul ', simpleName: 'Sanpaul ', village: 'Martinis ' },
            { name: 'Ciba ', village: 'Miercurea Ciuc ' },
            { name: 'Harghita-Băi ', simpleName: 'Harghita-Bai ', village: 'Miercurea Ciuc ' },
            { name: 'Jigodin-Băi ', simpleName: 'Jigodin-Bai ', village: 'Miercurea Ciuc ' },
            { name: 'Livezi ', village: 'Mihaileni ' },
            { name: 'Nădejdea ', simpleName: 'Nadejdea ', village: 'Mihaileni ' },
            { name: 'Văcăreşti ', simpleName: 'Vacaresti ', village: 'Mihaileni ' },
            { name: 'Aluniş ', simpleName: 'Alunis ', village: 'Mugeni ' },
            { name: 'Beta ', village: 'Mugeni ' },
            { name: 'Beteşti ', simpleName: 'Betesti ', village: 'Mugeni ' },
            { name: 'Dejuţiu ', simpleName: 'Dejutiu ', village: 'Mugeni ' },
            { name: 'Dobeni ', village: 'Mugeni ' },
            { name: 'Lutiţa ', simpleName: 'Lutita ', village: 'Mugeni ' },
            { name: 'Mătişeni ', simpleName: 'Matiseni ', village: 'Mugeni ' },
            { name: 'Porumbenii Mari ', village: 'Mugeni ' },
            { name: 'Porumbenii Mici ', village: 'Mugeni ' },
            { name: 'Tăietura ', simpleName: 'Taietura ', village: 'Mugeni ' },
            { name: 'Crăciunel ', simpleName: 'Craciunel ', village: 'Ocland ' },
            { name: 'Satu Nou ', village: 'Ocland ' },
            { name: 'Delniţa ', simpleName: 'Delnita ', village: 'Pauleni-Ciuc ' },
            { name: 'Şoimeni ', simpleName: 'Soimeni ', village: 'Pauleni-Ciuc ' },
            { name: 'Casinu Nou ', village: 'Plaiesii De Jos ' },
            { name: 'Iacobeni ', village: 'Plaiesii De Jos ' },
            { name: 'Imper ', village: 'Plaiesii De Jos ' },
            { name: 'Plăieşii de Sus ', simpleName: 'Plaiesii de Sus ', village: 'Plaiesii De Jos ' },
            { name: 'Becaş ', simpleName: 'Becas ', village: 'Praid ' },
            { name: 'Bucin ', village: 'Praid ' },
            { name: 'Ocna de Jos ', village: 'Praid ' },
            { name: 'Ocna de Sus ', village: 'Praid ' },
            { name: 'Şaşvereş ', simpleName: 'Sasveres ', village: 'Praid ' },
            { name: 'Făgeţel ', simpleName: 'Fagetel ', village: 'Remetea ' },
            { name: 'Martonca ', village: 'Remetea ' },
            { name: 'Sineu ', village: 'Remetea ' },
            { name: 'Uilac ', village: 'Sacel ' },
            { name: 'Vidacut ', village: 'Sacel ' },
            { name: 'Şoimuşu Mare ', simpleName: 'Soimusu Mare ', village: 'Sacel ' },
            { name: 'Şoimuşu Mic ', simpleName: 'Soimusu Mic ', village: 'Sacel ' },
            { name: 'Fitod ', village: 'Sancraieni ' },
            { name: 'Hosasău ', simpleName: 'Hosasau ', village: 'Sancraieni ' },
            { name: 'Leliceni ', village: 'Sancraieni ' },
            { name: 'Misentea ', village: 'Sancraieni ' },
            { name: 'Sântimbru ', simpleName: 'Santimbru ', village: 'Sancraieni ' },
            { name: 'Sântimbru-Băi ', simpleName: 'Santimbru-Bai ', village: 'Sancraieni ' },
            { name: 'Ciucani ', village: 'Sanmartin ' },
            { name: 'Valea Uzului ', village: 'Sanmartin ' },
            { name: 'Cetăţuia ', simpleName: 'Cetatuia ', village: 'Sansimion ' },
            { name: 'Fundoaia ', village: 'Sarmas ' },
            { name: 'Hodoşa ', simpleName: 'Hodosa ', village: 'Sarmas ' },
            { name: 'Platoneşti ', simpleName: 'Platonesti ', village: 'Sarmas ' },
            { name: 'Runc ', village: 'Sarmas ' },
            { name: 'Bodogaia ', village: 'Secuieni ' },
            { name: 'Eliseni ', village: 'Secuieni ' },
            { name: 'Ciaracio ', village: 'Siculeni ' },
            { name: 'Ciceu ', village: 'Siculeni ' },
            { name: 'Racu ', village: 'Siculeni ' },
            { name: 'Satu Nou ', village: 'Siculeni ' },
            { name: 'Benţid ', simpleName: 'Bentid ', village: 'Simonesti ' },
            { name: 'Ceheţel ', simpleName: 'Cehetel ', village: 'Simonesti ' },
            { name: 'Chedia Mare ', village: 'Simonesti ' },
            { name: 'Chedia Mică ', simpleName: 'Chedia Mica ', village: 'Simonesti ' },
            { name: 'Cobăteşti ', simpleName: 'Cobatesti ', village: 'Simonesti ' },
            { name: 'Cădaciu Mare ', simpleName: 'Cadaciu Mare ', village: 'Simonesti ' },
            { name: 'Cădaciu Mic ', simpleName: 'Cadaciu Mic ', village: 'Simonesti ' },
            { name: 'Medişoru Mare ', simpleName: 'Medisoru Mare ', village: 'Simonesti ' },
            { name: 'Mihăileni ', simpleName: 'Mihaileni ', village: 'Simonesti ' },
            { name: 'Nicoleni ', village: 'Simonesti ' },
            { name: 'Rugăneşti ', simpleName: 'Ruganesti ', village: 'Simonesti ' },
            { name: 'Turdeni ', village: 'Simonesti ' },
            { name: 'Tărceşti ', simpleName: 'Tarcesti ', village: 'Simonesti ' },
            { name: 'Călnaci ', simpleName: 'Calnaci ', village: 'Subcetate ' },
            { name: 'Duda ', village: 'Subcetate ' },
            { name: 'Filpea ', village: 'Subcetate ' },
            { name: 'Chileni ', village: 'Suseni ' },
            { name: 'Liban ', village: 'Suseni ' },
            { name: 'Senetea ', village: 'Suseni ' },
            { name: 'Valea Strâmbă ', simpleName: 'Valea Stramba ', village: 'Suseni ' },
            { name: 'Călimănel ', simpleName: 'Calimanel ', village: 'Toplita ' },
            { name: 'Luncani ', village: 'Toplita ' },
            { name: 'Moglăneşti ', simpleName: 'Moglanesti ', village: 'Toplita ' },
            { name: 'Măgheruş ', simpleName: 'Magherus ', village: 'Toplita ' },
            { name: 'Secu ', village: 'Toplita ' },
            { name: 'Vale ', village: 'Toplita ' },
            { name: 'Vâgani ', simpleName: 'Vagani ', village: 'Toplita ' },
            { name: 'Zencani ', village: 'Toplita ' },
            { name: 'Hagota ', village: 'Tulghes ' },
            { name: 'Pintic ', village: 'Tulghes ' },
            { name: 'Recea ', village: 'Tulghes ' },
            { name: 'Tuşnadu Nou ', simpleName: 'Tusnadu Nou ', village: 'Tusnad ' },
            { name: 'Vrabia ', village: 'Tusnad ' },
            { name: 'Daia ', village: 'Ulies ' },
            { name: 'Iaşu ', simpleName: 'Iasu ', village: 'Ulies ' },
            { name: 'Ighiu ', village: 'Ulies ' },
            { name: 'Nicoleşti ', simpleName: 'Nicolesti ', village: 'Ulies ' },
            { name: 'Obrăneşti ', simpleName: 'Obranesti ', village: 'Ulies ' },
            { name: 'Petecu ', village: 'Ulies ' },
            { name: 'Vasileni ', village: 'Ulies ' },
            { name: 'Băile Homorod ', simpleName: 'Baile Homorod ', village: 'Vlahita ' },
            { name: 'Minele Lueta ', village: 'Vlahita ' },
            { name: 'Izvoru Mureşului ', simpleName: 'Izvoru Muresului ', village: 'Voslabeni ' },
            { name: 'Desag ', village: 'Zetea ' },
            { name: 'Izvoare ', village: 'Zetea ' },
            { name: 'Poiana Târnavei ', simpleName: 'Poiana Tarnavei ', village: 'Zetea ' },
            { name: 'Sub Cetate ', village: 'Zetea ' },
            {
                name: 'Şicasău ', simpleName: 'Sicasau ', village: 'Zetea '
            }
        ]
    },
    {
        auto: 'HD ',
        name: 'Hunedoara ',
        cities: [
            { name: 'Aninoasa ' },
            { name: 'Baia de Criş ', simpleName: 'Baia de Cris ' },
            { name: 'Balşa ', simpleName: 'Balsa ' },
            { name: 'Baru ' },
            { name: 'Beriu ' },
            { name: 'Blăjeni ', simpleName: 'Blajeni ' },
            { name: 'Boşorod ', simpleName: 'Bosorod ' },
            { name: 'Brad ' },
            { name: 'Bretea Română ', simpleName: 'Bretea Romana ' },
            { name: 'Brănişca ', simpleName: 'Branisca ' },
            { name: 'Buceş ', simpleName: 'Buces ' },
            { name: 'Bucureşci ', simpleName: 'Bucuresci ' },
            { name: 'Bulzeştii de Sus ', simpleName: 'Bulzestii de Sus ' },
            { name: 'Bunila ' },
            { name: 'Burjuc ' },
            { name: 'Băcia ', simpleName: 'Bacia ' },
            { name: 'Băiţa ', simpleName: 'Baita ' },
            { name: 'Băniţa ', simpleName: 'Banita ' },
            { name: 'Bătrâna ', simpleName: 'Batrana ' },
            { name: 'Cerbăl ', simpleName: 'Cerbal ' },
            { name: 'Certeju de Sus ' },
            { name: 'Crişcior ', simpleName: 'Criscior ' },
            { name: 'Cârjiţi ', simpleName: 'Carjiti ' },
            { name: 'Călan ', simpleName: 'Calan ' },
            { name: 'Densuş ', simpleName: 'Densus ' },
            { name: 'Deva ' },
            { name: 'Dobra ' },
            { name: 'General Berthelot ' },
            { name: 'Geoagiu ' },
            { name: 'Ghelari ' },
            { name: 'Gurasada ' },
            { name: 'Haţeg ', simpleName: 'Hateg ' },
            { name: 'Hunedoara ' },
            { name: 'Hărău ', simpleName: 'Harau ' },
            { name: 'Ilia ' },
            { name: 'Lelese ' },
            { name: 'Lunca Cernii de Jos ' },
            { name: 'Luncoiu de Jos ' },
            { name: 'Lupeni ' },
            { name: 'Lăpugiu de Jos ', simpleName: 'Lapugiu de Jos ' },
            { name: 'Mărtineşti ', simpleName: 'Martinesti ' },
            { name: 'Orăştie ', simpleName: 'Orastie ' },
            { name: 'Orăştioara de Sus ', simpleName: 'Orastioara de Sus ' },
            { name: 'Pestişu Mic ', simpleName: 'Pestisu Mic ' },
            { name: 'Petrila ' },
            { name: 'Petroşani ', simpleName: 'Petrosani ' },
            { name: 'Pui ' },
            { name: 'Rapoltu Mare ' },
            { name: 'Ribiţa ', simpleName: 'Ribita ' },
            { name: 'Romos ' },
            { name: 'Râu de Mori ', simpleName: 'Rau de Mori ' },
            { name: 'Răchitova ', simpleName: 'Rachitova ' },
            { name: 'Sarmizegetusa ' },
            { name: 'Simeria ' },
            { name: 'Sântămăria-Orlea ', simpleName: 'Santamaria-Orlea ' },
            { name: 'Sălaşu de Sus ', simpleName: 'Salasu de Sus ' },
            { name: 'Teliucu Inferior ' },
            { name: 'Tomeşti ', simpleName: 'Tomesti ' },
            { name: 'Topliţa ', simpleName: 'Toplita ' },
            { name: 'Toteşti ', simpleName: 'Totesti ' },
            { name: 'Turdaş ', simpleName: 'Turdas ' },
            { name: 'Uricani ' },
            { name: 'Vaţa de Jos ', simpleName: 'Vata de Jos ' },
            { name: 'Veţel ', simpleName: 'Vetel ' },
            { name: 'Vorţa ', simpleName: 'Vorta ' },
            { name: 'Vulcan ' },
            { name: 'Vălişoara ', simpleName: 'Valisoara ' },
            { name: 'Zam ' },
            { name: 'Şoimuş ', simpleName: 'Soimus ' },
            { name: 'Iscroni ', village: 'Aninoasa ' },
            { name: 'Petreni ', village: 'Bacia ' },
            { name: 'Totia ', village: 'Bacia ' },
            { name: 'Tâmpa ', simpleName: 'Tampa ', village: 'Bacia ' },
            { name: 'Baldovin ', village: 'Baia De Cris ' },
            { name: 'Căraci ', simpleName: 'Caraci ', village: 'Baia De Cris ' },
            { name: 'Cărăstău ', simpleName: 'Carastau ', village: 'Baia De Cris ' },
            { name: 'Lunca ', village: 'Baia De Cris ' },
            { name: 'Rişca ', simpleName: 'Risca ', village: 'Baia De Cris ' },
            { name: 'Rişculiţa ', simpleName: 'Risculita ', village: 'Baia De Cris ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Baia De Cris ' },
            { name: 'Ţebea ', simpleName: 'Tebea ', village: 'Baia De Cris ' },
            { name: 'Barbura ', village: 'Baita ' },
            { name: 'Crăciuneşti ', simpleName: 'Craciunesti ', village: 'Baita ' },
            { name: 'Căinelu de Sus ', simpleName: 'Cainelu de Sus ', village: 'Baita ' },
            { name: 'Fizeş ', simpleName: 'Fizes ', village: 'Baita ' },
            { name: 'Hărţăgani ', simpleName: 'Hartagani ', village: 'Baita ' },
            { name: 'Lunca ', village: 'Baita ' },
            { name: 'Ormindea ', village: 'Baita ' },
            { name: 'Peştera ', simpleName: 'Pestera ', village: 'Baita ' },
            { name: 'Sălişte ', simpleName: 'Saliste ', village: 'Baita ' },
            { name: 'Trestia ', village: 'Baita ' },
            { name: 'Almaşu Mic de Munte ', simpleName: 'Almasu Mic de Munte ', village: 'Balsa ' },
            { name: 'Ardeu ', village: 'Balsa ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ', village: 'Balsa ' },
            { name: 'Galbina ', village: 'Balsa ' },
            { name: 'Mada ', village: 'Balsa ' },
            { name: 'Oprişeşti ', simpleName: 'Oprisesti ', village: 'Balsa ' },
            { name: 'Poiana ', village: 'Balsa ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Balsa ' },
            { name: 'Roşia ', simpleName: 'Rosia ', village: 'Balsa ' },
            { name: 'Stăuini ', simpleName: 'Stauini ', village: 'Balsa ' },
            { name: 'Techereu ', village: 'Balsa ' },
            { name: 'Voia ', village: 'Balsa ' },
            { name: 'Vălişoara ', simpleName: 'Valisoara ', village: 'Balsa ' },
            { name: 'Crivadia ', village: 'Banita ' },
            { name: 'Merişor ', simpleName: 'Merisor ', village: 'Banita ' },
            { name: 'Livadia ', village: 'Baru ' },
            { name: 'Petros ', village: 'Baru ' },
            { name: 'Valea Lupului ', village: 'Baru ' },
            { name: 'Faţa Roşie ', simpleName: 'Fata Rosie ', village: 'Batrana ' },
            { name: 'Piatra ', village: 'Batrana ' },
            { name: 'Răchiţaua ', simpleName: 'Rachitaua ', village: 'Batrana ' },
            { name: 'Cucuiş ', simpleName: 'Cucuis ', village: 'Beriu ' },
            { name: 'Căstău ', simpleName: 'Castau ', village: 'Beriu ' },
            { name: 'Măgureni ', simpleName: 'Magureni ', village: 'Beriu ' },
            { name: 'Orăştioara de Jos ', simpleName: 'Orastioara de Jos ', village: 'Beriu ' },
            { name: 'Poieni ', village: 'Beriu ' },
            { name: 'Sereca ', village: 'Beriu ' },
            { name: 'Sibişel ', simpleName: 'Sibisel ', village: 'Beriu ' },
            { name: 'Blăjeni-Vulcan ', simpleName: 'Blajeni-Vulcan ', village: 'Blajeni ' },
            { name: 'Criş ', simpleName: 'Cris ', village: 'Blajeni ' },
            { name: 'Dragu-Brad ', village: 'Blajeni ' },
            { name: 'Groşuri ', simpleName: 'Grosuri ', village: 'Blajeni ' },
            { name: 'Plai ', village: 'Blajeni ' },
            { name: 'Reţ ', simpleName: 'Ret ', village: 'Blajeni ' },
            { name: 'Sălătruc ', simpleName: 'Salatruc ', village: 'Blajeni ' },
            { name: 'Alun ', village: 'Bosorod ' },
            { name: 'Bobaia ', village: 'Bosorod ' },
            { name: 'Chitid ', village: 'Bosorod ' },
            { name: 'Cioclovina ', village: 'Bosorod ' },
            { name: 'Luncani ', village: 'Bosorod ' },
            { name: 'Prihodişte ', simpleName: 'Prihodiste ', village: 'Bosorod ' },
            { name: 'Târsa ', simpleName: 'Tarsa ', village: 'Bosorod ' },
            { name: 'Ursici ', village: 'Bosorod ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Brad ' },
            { name: 'Potingani ', village: 'Brad ' },
            { name: 'Ruda-Brad ', village: 'Brad ' },
            { name: 'Valea Bradului ', village: 'Brad ' },
            { name: 'Ţărăţel ', simpleName: 'Taratel ', village: 'Brad ' },
            { name: 'Boz ', village: 'Branisca ' },
            { name: 'Bărăştii Iliei ', simpleName: 'Barastii Iliei ', village: 'Branisca ' },
            { name: 'Căbeşti ', simpleName: 'Cabesti ', village: 'Branisca ' },
            { name: 'Furcşoara ', simpleName: 'Furcsoara ', village: 'Branisca ' },
            { name: 'Gialacuta ', village: 'Branisca ' },
            { name: 'Rovina ', village: 'Branisca ' },
            { name: 'Târnava ', simpleName: 'Tarnava ', village: 'Branisca ' },
            { name: 'Târnăviţa ', simpleName: 'Tarnavita ', village: 'Branisca ' },
            { name: 'Bercu ', village: 'Bretea Romana ' },
            { name: 'Bretea Streiului ', village: 'Bretea Romana ' },
            { name: 'Băţălar ', simpleName: 'Batalar ', village: 'Bretea Romana ' },
            { name: 'Covragiu ', village: 'Bretea Romana ' },
            { name: 'Gânţaga ', simpleName: 'Gantaga ', village: 'Bretea Romana ' },
            { name: 'Măceu ', simpleName: 'Maceu ', village: 'Bretea Romana ' },
            { name: 'Ocolişu Mare ', simpleName: 'Ocolisu Mare ', village: 'Bretea Romana ' },
            { name: 'Plopi ', village: 'Bretea Romana ' },
            { name: 'Ruşi ', simpleName: 'Rusi ', village: 'Bretea Romana ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Bretea Romana ' },
            { name: 'Vâlcelele Bune ', simpleName: 'Valcelele Bune ', village: 'Bretea Romana ' },
            { name: 'Vâlceluţa ', simpleName: 'Valceluta ', village: 'Bretea Romana ' },
            { name: 'Buceş-Vulcan ', simpleName: 'Buces-Vulcan ', village: 'Buces ' },
            { name: 'Dupăpiatră ', simpleName: 'Dupapiatra ', village: 'Buces ' },
            { name: 'Grohoţele ', simpleName: 'Grohotele ', village: 'Buces ' },
            { name: 'Mihăileni ', simpleName: 'Mihaileni ', village: 'Buces ' },
            { name: 'Stănija ', simpleName: 'Stanija ', village: 'Buces ' },
            { name: 'Tarniţa ', simpleName: 'Tarnita ', village: 'Buces ' },
            { name: 'Curechiu ', village: 'Bucuresci ' },
            { name: 'Merişor ', simpleName: 'Merisor ', village: 'Bucuresci ' },
            { name: 'Rovina ', village: 'Bucuresci ' },
            { name: 'Şesuri ', simpleName: 'Sesuri ', village: 'Bucuresci ' },
            { name: 'Bulzeştii de Jos ', simpleName: 'Bulzestii de Jos ', village: 'Bulzestii De Sus ' },
            { name: 'Giurgeşti ', simpleName: 'Giurgesti ', village: 'Bulzestii De Sus ' },
            { name: 'Grohot ', village: 'Bulzestii De Sus ' },
            { name: 'Păuleşti ', simpleName: 'Paulesti ', village: 'Bulzestii De Sus ' },
            { name: 'Ruseşti ', simpleName: 'Rusesti ', village: 'Bulzestii De Sus ' },
            { name: 'Stănculeşti ', simpleName: 'Stanculesti ', village: 'Bulzestii De Sus ' },
            { name: 'Ticera ', village: 'Bulzestii De Sus ' },
            { name: 'Tomnatec ', village: 'Bulzestii De Sus ' },
            { name: 'Alun ', village: 'Bunila ' },
            { name: 'Cernişoara Florese ', simpleName: 'Cernisoara Florese ', village: 'Bunila ' },
            { name: 'Poieniţa Voinii ', simpleName: 'Poienita Voinii ', village: 'Bunila ' },
            { name: 'Vadu Dobrii ', village: 'Bunila ' },
            { name: 'Brădăţel ', simpleName: 'Bradatel ', village: 'Burjuc ' },
            { name: 'Glodghileşti ', simpleName: 'Glodghilesti ', village: 'Burjuc ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Burjuc ' },
            { name: 'Tisa ', village: 'Burjuc ' },
            { name: 'Tătărăşti ', simpleName: 'Tatarasti ', village: 'Burjuc ' },
            { name: 'Batiz ', village: 'Calan ' },
            { name: 'Călanu Mic ', simpleName: 'Calanu Mic ', village: 'Calan ' },
            { name: 'Grid ', village: 'Calan ' },
            { name: 'Nădăştia de Jos ', simpleName: 'Nadastia de Jos ', village: 'Calan ' },
            { name: 'Nădăştia de Sus ', simpleName: 'Nadastia de Sus ', village: 'Calan ' },
            { name: 'Ohaba Streiului ', village: 'Calan ' },
            { name: 'Strei ', village: 'Calan ' },
            { name: 'Strei-Săcel ', simpleName: 'Strei-Sacel ', village: 'Calan ' },
            { name: 'Streisângeorgiu ', simpleName: 'Streisangeorgiu ', village: 'Calan ' },
            { name: 'Sâncrai ', simpleName: 'Sancrai ', village: 'Calan ' },
            { name: 'Sântămăria de Piatră ', simpleName: 'Santamaria de Piatra ', village: 'Calan ' },
            { name: 'Valea Sângeorgiului ', simpleName: 'Valea Sangeorgiului ', village: 'Calan ' },
            { name: 'Almaşu Sec ', simpleName: 'Almasu Sec ', village: 'Carjiti ' },
            { name: 'Chergheş ', simpleName: 'Cherghes ', village: 'Carjiti ' },
            { name: 'Cozia ', village: 'Carjiti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Carjiti ' },
            { name: 'Arănieş ', simpleName: 'Aranies ', village: 'Cerbal ' },
            { name: 'Feregi ', village: 'Cerbal ' },
            { name: 'Merişoru de Munte ', simpleName: 'Merisoru de Munte ', village: 'Cerbal ' },
            { name: 'Poiana Răchiţelii ', simpleName: 'Poiana Rachitelii ', village: 'Cerbal ' },
            { name: 'Poieniţa Tomii ', simpleName: 'Poienita Tomii ', village: 'Cerbal ' },
            { name: 'Socet ', village: 'Cerbal ' },
            { name: 'Ulm ', village: 'Cerbal ' },
            { name: 'Bocşa Mare ', simpleName: 'Bocsa Mare ', village: 'Certeju De Sus ' },
            { name: 'Bocşa Mică ', simpleName: 'Bocsa Mica ', village: 'Certeju De Sus ' },
            { name: 'Hondol ', village: 'Certeju De Sus ' },
            { name: 'Măgura-Topliţa ', simpleName: 'Magura-Toplita ', village: 'Certeju De Sus ' },
            { name: 'Nojag ', village: 'Certeju De Sus ' },
            { name: 'Săcărâmb ', simpleName: 'Sacaramb ', village: 'Certeju De Sus ' },
            { name: 'Topliţa Mureşului ', simpleName: 'Toplita Muresului ', village: 'Certeju De Sus ' },
            { name: 'Vărmaga ', simpleName: 'Varmaga ', village: 'Certeju De Sus ' },
            { name: 'Barza ', village: 'Criscior ' },
            { name: 'Valea Arsului ', village: 'Criscior ' },
            { name: 'Zdrapţi ', simpleName: 'Zdrapti ', village: 'Criscior ' },
            { name: 'Criva ', village: 'Densus ' },
            { name: 'Hăţăgel ', simpleName: 'Hatagel ', village: 'Densus ' },
            { name: 'Peşteana ', simpleName: 'Pesteana ', village: 'Densus ' },
            { name: 'Peşteniţa ', simpleName: 'Pestenita ', village: 'Densus ' },
            { name: 'Poieni ', village: 'Densus ' },
            { name: 'Ştei ', simpleName: 'Stei ', village: 'Densus ' },
            { name: 'Archia ', village: 'Deva ' },
            { name: 'Bârcea Mică ', simpleName: 'Barcea Mica ', village: 'Deva ' },
            { name: 'Cristur ', village: 'Deva ' },
            { name: 'Sântuhalm ', simpleName: 'Santuhalm ', village: 'Deva ' },
            { name: 'Abucea ', village: 'Dobra ' },
            { name: 'Bujoru ', village: 'Dobra ' },
            { name: 'Făgeţel ', simpleName: 'Fagetel ', village: 'Dobra ' },
            { name: 'Lăpuşnic ', simpleName: 'Lapusnic ', village: 'Dobra ' },
            { name: 'Mihăieşti ', simpleName: 'Mihaiesti ', village: 'Dobra ' },
            { name: 'Panc ', village: 'Dobra ' },
            { name: 'Panc-Sălişte ', simpleName: 'Panc-Saliste ', village: 'Dobra ' },
            { name: 'Roşcani ', simpleName: 'Roscani ', village: 'Dobra ' },
            { name: 'Răduleşti ', simpleName: 'Radulesti ', village: 'Dobra ' },
            { name: 'Stretea ', village: 'Dobra ' },
            { name: 'Stânceşti ', simpleName: 'Stancesti ', village: 'Dobra ' },
            { name: 'Stânceşti-Ohaba ', simpleName: 'Stancesti-Ohaba ', village: 'Dobra ' },
            { name: 'Crăguiş ', simpleName: 'Craguis ', village: 'General Berthelot ' },
            { name: 'Fărcădin ', simpleName: 'Farcadin ', village: 'General Berthelot ' },
            { name: 'Livezi ', village: 'General Berthelot ' },
            { name: 'Tuştea ', simpleName: 'Tustea ', village: 'General Berthelot ' },
            { name: 'Aurel Vlaicu ', village: 'Geoagiu ' },
            { name: 'Bacâia ', simpleName: 'Bacaia ', village: 'Geoagiu ' },
            { name: 'Bozeş ', simpleName: 'Bozes ', village: 'Geoagiu ' },
            { name: 'Cigmău ', simpleName: 'Cigmau ', village: 'Geoagiu ' },
            { name: 'Gelmar ', village: 'Geoagiu ' },
            { name: 'Geoagiu-Băi ', simpleName: 'Geoagiu-Bai ', village: 'Geoagiu ' },
            { name: 'Homorod ', village: 'Geoagiu ' },
            { name: 'Mermezeu-Văleni ', simpleName: 'Mermezeu-Valeni ', village: 'Geoagiu ' },
            { name: 'Renghet ', village: 'Geoagiu ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Geoagiu ' },
            { name: 'Govăjdia ', simpleName: 'Govajdia ', village: 'Ghelari ' },
            { name: 'Plop ', village: 'Ghelari ' },
            { name: 'Ruda ', village: 'Ghelari ' },
            { name: 'Boiu de Jos ', village: 'Gurasada ' },
            { name: 'Boiu de Sus ', village: 'Gurasada ' },
            { name: 'Câmpuri de Sus ', simpleName: 'Campuri de Sus ', village: 'Gurasada ' },
            { name: 'Câmpuri-Surduc ', simpleName: 'Campuri-Surduc ', village: 'Gurasada ' },
            { name: 'Cărmăzăneşti ', simpleName: 'Carmazanesti ', village: 'Gurasada ' },
            { name: 'Dănuleşti ', simpleName: 'Danulesti ', village: 'Gurasada ' },
            { name: 'Gothatea ', village: 'Gurasada ' },
            { name: 'Runcşor ', simpleName: 'Runcsor ', village: 'Gurasada ' },
            { name: 'Ulieş ', simpleName: 'Ulies ', village: 'Gurasada ' },
            { name: 'Vica ', village: 'Gurasada ' },
            { name: 'Banpotoc ', village: 'Harau ' },
            { name: 'Bârsău ', simpleName: 'Barsau ', village: 'Harau ' },
            { name: 'Chimindia ', village: 'Harau ' },
            { name: 'Nălaţvad ', simpleName: 'Nalatvad ', village: 'Hateg ' },
            { name: 'Silvaşu de Jos ', simpleName: 'Silvasu de Jos ', village: 'Hateg ' },
            { name: 'Silvaşu de Sus ', simpleName: 'Silvasu de Sus ', village: 'Hateg ' },
            { name: 'Boş ', simpleName: 'Bos ', village: 'Hunedoara ' },
            { name: 'Groş ', simpleName: 'Gros ', village: 'Hunedoara ' },
            { name: 'Hăşdat ', simpleName: 'Hasdat ', village: 'Hunedoara ' },
            { name: 'Peştişu Mare ', simpleName: 'Pestisu Mare ', village: 'Hunedoara ' },
            { name: 'Răcăştia ', simpleName: 'Racastia ', village: 'Hunedoara ' },
            { name: 'Bacea ', village: 'Ilia ' },
            { name: 'Bretea Mureşană ', simpleName: 'Bretea Muresana ', village: 'Ilia ' },
            { name: 'Brâznic ', simpleName: 'Braznic ', village: 'Ilia ' },
            { name: 'Cuieş ', simpleName: 'Cuies ', village: 'Ilia ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Ilia ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Ilia ' },
            { name: 'Săcămaş ', simpleName: 'Sacamas ', village: 'Ilia ' },
            { name: 'Valea Lungă ', simpleName: 'Valea Lunga ', village: 'Ilia ' },
            { name: 'Baştea ', simpleName: 'Bastea ', village: 'Lapugiu De Jos ' },
            { name: 'Coseşti ', simpleName: 'Cosesti ', village: 'Lapugiu De Jos ' },
            { name: 'Fintoag ', village: 'Lapugiu De Jos ' },
            { name: 'Grind ', village: 'Lapugiu De Jos ' },
            { name: 'Holdea ', village: 'Lapugiu De Jos ' },
            { name: 'Lăpugiu de Sus ', simpleName: 'Lapugiu de Sus ', village: 'Lapugiu De Jos ' },
            { name: 'Lăsău ', simpleName: 'Lasau ', village: 'Lapugiu De Jos ' },
            { name: 'Ohaba ', village: 'Lapugiu De Jos ' },
            { name: 'Teiu ', village: 'Lapugiu De Jos ' },
            { name: 'Cerişor ', simpleName: 'Cerisor ', village: 'Lelese ' },
            { name: 'Runcu Mare ', village: 'Lelese ' },
            { name: 'Sohodol ', village: 'Lelese ' },
            { name: 'Ciumiţa ', simpleName: 'Ciumita ', village: 'Lunca Cernii De Jos ' },
            { name: 'Fântâna ', simpleName: 'Fantana ', village: 'Lunca Cernii De Jos ' },
            { name: 'Gura Bordului ', village: 'Lunca Cernii De Jos ' },
            { name: 'Lunca Cernii de Sus ', village: 'Lunca Cernii De Jos ' },
            { name: 'Meria ', village: 'Lunca Cernii De Jos ' },
            { name: 'Negoiu ', village: 'Lunca Cernii De Jos ' },
            { name: 'Valea Babii ', village: 'Lunca Cernii De Jos ' },
            { name: 'Dudeşti ', simpleName: 'Dudesti ', village: 'Luncoiu De Jos ' },
            { name: 'Luncoiu de Sus ', village: 'Luncoiu De Jos ' },
            { name: 'Podele ', village: 'Luncoiu De Jos ' },
            { name: 'Stejărel ', simpleName: 'Stejarel ', village: 'Luncoiu De Jos ' },
            { name: 'Dâncu Mare ', simpleName: 'Dancu Mare ', village: 'Martinesti ' },
            { name: 'Dâncu Mic ', simpleName: 'Dancu Mic ', village: 'Martinesti ' },
            { name: 'Jeledinţi ', simpleName: 'Jeledinti ', village: 'Martinesti ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Martinesti ' },
            { name: 'Turmaş ', simpleName: 'Turmas ', village: 'Martinesti ' },
            { name: 'Tămăşasa ', simpleName: 'Tamasasa ', village: 'Martinesti ' },
            { name: 'Bucium ', village: 'Orastioara De Sus ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Orastioara De Sus ' },
            { name: 'Costeşti-Deal ', simpleName: 'Costesti-Deal ', village: 'Orastioara De Sus ' },
            { name: 'Grădiştea de Munte ', simpleName: 'Gradistea de Munte ', village: 'Orastioara De Sus ' },
            { name: 'Ludeştii de Jos ', simpleName: 'Ludestii de Jos ', village: 'Orastioara De Sus ' },
            { name: 'Ludeştii de Sus ', simpleName: 'Ludestii de Sus ', village: 'Orastioara De Sus ' },
            { name: 'Ocolişu Mic ', simpleName: 'Ocolisu Mic ', village: 'Orastioara De Sus ' },
            { name: 'Almaşu Mic ', simpleName: 'Almasu Mic ', village: 'Pestisu Mic ' },
            { name: 'Ciulpăz ', simpleName: 'Ciulpaz ', village: 'Pestisu Mic ' },
            { name: 'Cutin ', village: 'Pestisu Mic ' },
            { name: 'Dumbrava ', village: 'Pestisu Mic ' },
            { name: 'Josani ', village: 'Pestisu Mic ' },
            { name: 'Mănerău ', simpleName: 'Manerau ', village: 'Pestisu Mic ' },
            { name: 'Nandru ', village: 'Pestisu Mic ' },
            { name: 'Valea Nandrului ', village: 'Pestisu Mic ' },
            { name: 'Cimpa ', village: 'Petrila ' },
            { name: 'Jieţ ', simpleName: 'Jiet ', village: 'Petrila ' },
            { name: 'Răscoala ', simpleName: 'Rascoala ', village: 'Petrila ' },
            { name: 'Tirici ', village: 'Petrila ' },
            { name: 'Dâlja Mare ', simpleName: 'Dalja Mare ', village: 'Petrosani ' },
            { name: 'Dâlja Mică ', simpleName: 'Dalja Mica ', village: 'Petrosani ' },
            { name: 'Peştera ', simpleName: 'Pestera ', village: 'Petrosani ' },
            { name: 'Slătinioara ', simpleName: 'Slatinioara ', village: 'Petrosani ' },
            { name: 'Băieşti ', simpleName: 'Baiesti ', village: 'Pui ' },
            { name: 'Federi ', village: 'Pui ' },
            { name: 'Fizeşti ', simpleName: 'Fizesti ', village: 'Pui ' },
            { name: 'Galaţi ', simpleName: 'Galati ', village: 'Pui ' },
            { name: 'Hobiţa ', simpleName: 'Hobita ', village: 'Pui ' },
            { name: 'Ohaba-Ponor ', village: 'Pui ' },
            { name: 'Ponor ', village: 'Pui ' },
            { name: 'Ruşor ', simpleName: 'Rusor ', village: 'Pui ' },
            { name: 'Râu Bărbat ', simpleName: 'Rau Barbat ', village: 'Pui ' },
            { name: 'Uric ', village: 'Pui ' },
            { name: 'Şerel ', simpleName: 'Serel ', village: 'Pui ' },
            { name: 'Boiţa ', simpleName: 'Boita ', village: 'Rachitova ' },
            { name: 'Ciula Mare ', village: 'Rachitova ' },
            { name: 'Ciula Mică ', simpleName: 'Ciula Mica ', village: 'Rachitova ' },
            { name: 'Goteşti ', simpleName: 'Gotesti ', village: 'Rachitova ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Rachitova ' },
            { name: 'Vălioara ', simpleName: 'Valioara ', village: 'Rachitova ' },
            { name: 'Bobâlna ', simpleName: 'Bobalna ', village: 'Rapoltu Mare ' },
            { name: 'Boiu ', village: 'Rapoltu Mare ' },
            { name: 'Folt ', village: 'Rapoltu Mare ' },
            { name: 'Rapolţel ', simpleName: 'Rapoltel ', village: 'Rapoltu Mare ' },
            { name: 'Brazi ', village: 'Rau De Mori ' },
            { name: 'Clopotiva ', village: 'Rau De Mori ' },
            { name: 'Ohaba-Sibişel ', simpleName: 'Ohaba-Sibisel ', village: 'Rau De Mori ' },
            { name: 'Ostrov ', village: 'Rau De Mori ' },
            { name: 'Ostrovel ', village: 'Rau De Mori ' },
            { name: 'Ostrovu Mic ', village: 'Rau De Mori ' },
            { name: 'Sibişel ', simpleName: 'Sibisel ', village: 'Rau De Mori ' },
            { name: 'Suseni ', village: 'Rau De Mori ' },
            { name: 'Unciuc ', village: 'Rau De Mori ' },
            { name: 'Valea Dâljii ', simpleName: 'Valea Daljii ', village: 'Rau De Mori ' },
            { name: 'Crişan ', simpleName: 'Crisan ', village: 'Ribita ' },
            { name: 'Dumbrava de Jos ', village: 'Ribita ' },
            { name: 'Dumbrava de Sus ', village: 'Ribita ' },
            { name: 'Ribicioara ', village: 'Ribita ' },
            { name: 'Uibăreşti ', simpleName: 'Uibaresti ', village: 'Ribita ' },
            { name: 'Ciungu Mare ', village: 'Romos ' },
            { name: 'Pişchinţi ', simpleName: 'Pischinti ', village: 'Romos ' },
            { name: 'Romoşel ', simpleName: 'Romosel ', village: 'Romos ' },
            { name: 'Vaidei ', village: 'Romos ' },
            { name: 'Coroieşti ', simpleName: 'Coroiesti ', village: 'Salasu De Sus ' },
            { name: 'Mălăieşti ', simpleName: 'Malaiesti ', village: 'Salasu De Sus ' },
            { name: 'Nucşoara ', simpleName: 'Nucsoara ', village: 'Salasu De Sus ' },
            { name: 'Ohaba de Sub Piatră ', simpleName: 'Ohaba de Sub Piatra ', village: 'Salasu De Sus ' },
            { name: 'Paroş ', simpleName: 'Paros ', village: 'Salasu De Sus ' },
            { name: 'Peştera ', simpleName: 'Pestera ', village: 'Salasu De Sus ' },
            { name: 'Râu Alb ', simpleName: 'Rau Alb ', village: 'Salasu De Sus ' },
            { name: 'Râu Mic ', simpleName: 'Rau Mic ', village: 'Salasu De Sus ' },
            { name: 'Sălaşu de Jos ', simpleName: 'Salasu de Jos ', village: 'Salasu De Sus ' },
            { name: 'Zăvoi ', simpleName: 'Zavoi ', village: 'Salasu De Sus ' },
            { name: 'Balomir ', village: 'Santamaria-Orlea ' },
            { name: 'Bucium-Orlea ', village: 'Santamaria-Orlea ' },
            { name: 'Bărăştii Haţegului ', simpleName: 'Barastii Hategului ', village: 'Santamaria-Orlea ' },
            { name: 'Ciopeia ', village: 'Santamaria-Orlea ' },
            { name: 'Subcetate ', village: 'Santamaria-Orlea ' },
            { name: 'Sânpetru ', simpleName: 'Sanpetru ', village: 'Santamaria-Orlea ' },
            { name: 'Săcel ', simpleName: 'Sacel ', village: 'Santamaria-Orlea ' },
            { name: 'Vadu ', village: 'Santamaria-Orlea ' },
            { name: 'Breazova ', village: 'Sarmizegetusa ' },
            { name: 'Hobiţa-Grădişte ', simpleName: 'Hobita-Gradiste ', village: 'Sarmizegetusa ' },
            { name: 'Păucineşti ', simpleName: 'Paucinesti ', village: 'Sarmizegetusa ' },
            { name: 'Zeicani ', village: 'Sarmizegetusa ' },
            { name: 'Bârcea Mare ', simpleName: 'Barcea Mare ', village: 'Simeria ' },
            { name: 'Cărpiniş ', simpleName: 'Carpinis ', village: 'Simeria ' },
            { name: 'Simeria Veche ', village: 'Simeria ' },
            { name: 'Sântandrei ', simpleName: 'Santandrei ', village: 'Simeria ' },
            { name: 'Uroi ', village: 'Simeria ' },
            { name: 'Şăuleşti ', simpleName: 'Saulesti ', village: 'Simeria ' },
            { name: 'Bejan ', village: 'Soimus ' },
            { name: 'Bejan-Târnăviţa ', simpleName: 'Bejan-Tarnavita ', village: 'Soimus ' },
            { name: 'Boholţ ', simpleName: 'Boholt ', village: 'Soimus ' },
            { name: 'Bălata ', simpleName: 'Balata ', village: 'Soimus ' },
            { name: 'Chişcădaga ', simpleName: 'Chiscadaga ', village: 'Soimus ' },
            { name: 'Căinelu de Jos ', simpleName: 'Cainelu de Jos ', village: 'Soimus ' },
            { name: 'Fornădia ', simpleName: 'Fornadia ', village: 'Soimus ' },
            { name: 'Păuliş ', simpleName: 'Paulis ', village: 'Soimus ' },
            { name: 'Sulighete ', village: 'Soimus ' },
            { name: 'Cinciş-Cerna ', simpleName: 'Cincis-Cerna ', village: 'Teliucu Inferior ' },
            { name: 'Izvoarele ', village: 'Teliucu Inferior ' },
            { name: 'Teliucu Superior ', village: 'Teliucu Inferior ' },
            { name: 'Dobroţ ', simpleName: 'Dobrot ', village: 'Tomesti ' },
            { name: 'Leauţ ', simpleName: 'Leaut ', village: 'Tomesti ' },
            { name: 'Livada ', village: 'Tomesti ' },
            { name: 'Obârşa ', simpleName: 'Obarsa ', village: 'Tomesti ' },
            { name: 'Steia ', village: 'Tomesti ' },
            { name: 'Tiuleşti ', simpleName: 'Tiulesti ', village: 'Tomesti ' },
            { name: 'Valea Mare de Criş ', simpleName: 'Valea Mare de Cris ', village: 'Tomesti ' },
            { name: 'Curpenii Silvaşului ', simpleName: 'Curpenii Silvasului ', village: 'Toplita ' },
            { name: 'Dealu Mic ', village: 'Toplita ' },
            { name: 'Dăbâca ', simpleName: 'Dabaca ', village: 'Toplita ' },
            { name: 'Goleş ', simpleName: 'Goles ', village: 'Toplita ' },
            { name: 'Hăşdău ', simpleName: 'Hasdau ', village: 'Toplita ' },
            { name: 'Mosoru ', village: 'Toplita ' },
            { name: 'Vălari ', simpleName: 'Valari ', village: 'Toplita ' },
            { name: 'Copaci ', village: 'Totesti ' },
            { name: 'Cârneşti ', simpleName: 'Carnesti ', village: 'Totesti ' },
            { name: 'Păclişa ', simpleName: 'Paclisa ', village: 'Totesti ' },
            { name: 'Reea ', village: 'Totesti ' },
            { name: 'Pricaz ', village: 'Turdas ' },
            { name: 'Râpaş ', simpleName: 'Rapas ', village: 'Turdas ' },
            { name: 'Spini ', village: 'Turdas ' },
            { name: 'Câmpu lui Neag ', simpleName: 'Campu lui Neag ', village: 'Uricani ' },
            { name: 'Valea de Brazi ', village: 'Uricani ' },
            { name: 'Dealu Mare ', village: 'Valisoara ' },
            { name: 'Stoieneasa ', village: 'Valisoara ' },
            { name: 'Săliştioara ', simpleName: 'Salistioara ', village: 'Valisoara ' },
            { name: 'Basarabasa ', village: 'Vata De Jos ' },
            { name: 'Birtin ', village: 'Vata De Jos ' },
            { name: 'Brotuna ', village: 'Vata De Jos ' },
            { name: 'Ciungani ', village: 'Vata De Jos ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ', village: 'Vata De Jos ' },
            { name: 'Ociu ', village: 'Vata De Jos ' },
            { name: 'Ocişor ', simpleName: 'Ocisor ', village: 'Vata De Jos ' },
            { name: 'Prihodişte ', simpleName: 'Prihodiste ', village: 'Vata De Jos ' },
            { name: 'Prăvăleni ', simpleName: 'Pravaleni ', village: 'Vata De Jos ' },
            { name: 'Târnava de Criş ', simpleName: 'Tarnava de Cris ', village: 'Vata De Jos ' },
            { name: 'Tătărăştii de Criş ', simpleName: 'Tatarastii de Cris ', village: 'Vata De Jos ' },
            { name: 'Vaţa de Sus ', simpleName: 'Vata de Sus ', village: 'Vata De Jos ' },
            { name: 'Boia Bârzii ', simpleName: 'Boia Barzii ', village: 'Vetel ' },
            { name: 'Bretelin ', village: 'Vetel ' },
            { name: 'Căoi ', simpleName: 'Caoi ', village: 'Vetel ' },
            { name: 'Herepeia ', village: 'Vetel ' },
            { name: 'Leşnic ', simpleName: 'Lesnic ', village: 'Vetel ' },
            { name: 'Mintia ', village: 'Vetel ' },
            { name: 'Muncelu Mare ', village: 'Vetel ' },
            { name: 'Muncelu Mic ', village: 'Vetel ' },
            { name: 'Runcu Mic ', village: 'Vetel ' },
            { name: 'Certeju de Jos ', village: 'Vorta ' },
            { name: 'Coaja ', village: 'Vorta ' },
            { name: 'Dumeşti ', simpleName: 'Dumesti ', village: 'Vorta ' },
            { name: 'Luncşoara ', simpleName: 'Luncsoara ', village: 'Vorta ' },
            { name: 'Valea Poienii ', village: 'Vorta ' },
            { name: 'Visca ', village: 'Vorta ' },
            { name: 'Dealu Babii ', village: 'Vulcan ' },
            { name: 'Jiu-Paroşeni ', simpleName: 'Jiu-Paroseni ', village: 'Vulcan ' },
            { name: 'Almaş-Sălişte ', simpleName: 'Almas-Saliste ', village: 'Zam ' },
            { name: 'Almăşel ', simpleName: 'Almasel ', village: 'Zam ' },
            { name: 'Brăşeu ', simpleName: 'Braseu ', village: 'Zam ' },
            { name: 'Cerbia ', village: 'Zam ' },
            { name: 'Deleni ', village: 'Zam ' },
            { name: 'Godineşti ', simpleName: 'Godinesti ', village: 'Zam ' },
            { name: 'Micăneşti ', simpleName: 'Micanesti ', village: 'Zam ' },
            { name: 'Pogăneşti ', simpleName: 'Poganesti ', village: 'Zam ' },
            { name: 'Pojoga ', village: 'Zam ' },
            { name: 'Sălciva ', simpleName: 'Salciva ', village: 'Zam ' },
            { name: 'Tămăşeşti ', simpleName: 'Tamasesti ', village: 'Zam ' },
            {
                name: 'Valea ', village: 'Zam '
            }
        ]
    },
    {
        auto: 'IL ',
        name: 'Ialomiţa ',
        cities: [
            { name: 'Adâncata ', simpleName: 'Adancata ' },
            { name: 'Albeşti ', simpleName: 'Albesti ' },
            { name: 'Alexeni ' },
            { name: 'Amara ' },
            { name: 'Andrăşeşti ', simpleName: 'Andrasesti ' },
            { name: 'Armăşeşti ', simpleName: 'Armasesti ' },
            { name: 'Axintele ' },
            { name: 'Balaciu ' },
            { name: 'Borduşani ', simpleName: 'Bordusani ' },
            { name: 'Brazii ' },
            { name: 'Bucu ' },
            { name: 'Bărcăneşti ', simpleName: 'Barcanesti ' },
            { name: 'Ciochina ' },
            { name: 'Ciocârlia ', simpleName: 'Ciocarlia ' },
            { name: 'Ciulniţa ', simpleName: 'Ciulnita ' },
            { name: 'Cocora ' },
            { name: 'Cosâmbeşti ', simpleName: 'Cosambesti ' },
            { name: 'Coşereni ', simpleName: 'Cosereni ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ' },
            { name: 'Dridu ' },
            { name: 'Drăgoeşti ', simpleName: 'Dragoesti ' },
            { name: 'Feteşti ', simpleName: 'Fetesti ' },
            { name: 'Fierbinţi-Târg ', simpleName: 'Fierbinti-Targ ' },
            { name: 'Făcăeni ', simpleName: 'Facaeni ' },
            { name: 'Gheorghe Doja ' },
            { name: 'Gheorghe Lazăr ', simpleName: 'Gheorghe Lazar ' },
            { name: 'Giurgeni ' },
            { name: 'Grindu ' },
            { name: 'Griviţa ', simpleName: 'Grivita ' },
            { name: 'Gârbovi ', simpleName: 'Garbovi ' },
            { name: 'Ion Roată ', simpleName: 'Ion Roata ' },
            { name: 'Jilavele ' },
            { name: 'Manasia ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ' },
            { name: 'Miloşeşti ', simpleName: 'Milosesti ' },
            { name: 'Movila ' },
            { name: 'Moviliţa ', simpleName: 'Movilita ' },
            { name: 'Munteni-Buzău ', simpleName: 'Munteni-Buzau ' },
            { name: 'Perieţi ', simpleName: 'Perieti ' },
            { name: 'Reviga ' },
            { name: 'Scânteia ', simpleName: 'Scanteia ' },
            { name: 'Sfântu Gheorghe ', simpleName: 'Sfantu Gheorghe ' },
            { name: 'Sineşti ', simpleName: 'Sinesti ' },
            { name: 'Slobozia ' },
            { name: 'Stelnica ' },
            { name: 'Sudiţi ', simpleName: 'Suditi ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ' },
            { name: 'Săveni ', simpleName: 'Saveni ' },
            { name: 'Traian ' },
            { name: 'Urziceni ' },
            { name: 'Valea Ciorii ' },
            { name: 'Valea Măcrişului ', simpleName: 'Valea Macrisului ' },
            { name: 'Vlădeni ', simpleName: 'Vladeni ' },
            { name: 'Ţăndărei ', simpleName: 'Tandarei ' },
            { name: 'Patru Fraţi ', simpleName: 'Patru Frati ', village: 'Adancata ' },
            { name: 'Bataluri ', village: 'Albesti ' },
            { name: 'Bueşti ', simpleName: 'Buesti ', village: 'Albesti ' },
            { name: 'Marsilieni ', village: 'Albesti ' },
            { name: 'Amara Nouă ', simpleName: 'Amara Noua ', village: 'Amara ' },
            { name: 'Orboeşti ', simpleName: 'Orboesti ', village: 'Andrasesti ' },
            { name: 'Bărbuleşti ', simpleName: 'Barbulesti ', village: 'Armasesti ' },
            { name: 'Malu Roşu ', simpleName: 'Malu Rosu ', village: 'Armasesti ' },
            { name: 'Nenişori ', simpleName: 'Nenisori ', village: 'Armasesti ' },
            { name: 'Bărbătescu ', simpleName: 'Barbatescu ', village: 'Axintele ' },
            { name: 'Horia ', village: 'Axintele ' },
            { name: 'Copuzu ', village: 'Balaciu ' },
            { name: 'Crăsanii de Jos ', simpleName: 'Crasanii de Jos ', village: 'Balaciu ' },
            { name: 'Crăsanii de Sus ', simpleName: 'Crasanii de Sus ', village: 'Balaciu ' },
            { name: 'Sărăţeni ', simpleName: 'Sarateni ', village: 'Balaciu ' },
            { name: 'Condeeşti ', simpleName: 'Condeesti ', village: 'Barcanesti ' },
            { name: 'Cegani ', village: 'Bordusani ' },
            { name: 'Maia ', village: 'Brazii ' },
            { name: 'Movileanca ', village: 'Brazii ' },
            { name: 'Răsimnicea ', simpleName: 'Rasimnicea ', village: 'Brazii ' },
            { name: 'Ograda ', village: 'Bucu ' },
            { name: 'Cotorca ', village: 'Ciocarlia ' },
            { name: 'Borduşelu ', simpleName: 'Borduselu ', village: 'Ciochina ' },
            { name: 'Orezu ', village: 'Ciochina ' },
            { name: 'Piersica ', village: 'Ciochina ' },
            { name: 'Ion Ghica ', village: 'Ciulnita ' },
            { name: 'Ivăneşti ', simpleName: 'Ivanesti ', village: 'Ciulnita ' },
            { name: 'Poiana ', village: 'Ciulnita ' },
            { name: 'Colelia ', village: 'Cocora ' },
            { name: 'Gimbăşani ', simpleName: 'Gimbasani ', village: 'Cosambesti ' },
            { name: 'Mărculeşti ', simpleName: 'Marculesti ', village: 'Cosambesti ' },
            { name: 'Borăneşti ', simpleName: 'Boranesti ', village: 'Cosereni ' },
            { name: 'Sinteşti ', simpleName: 'Sintesti ', village: 'Cosereni ' },
            { name: 'Chiroiu-Pamânteni ', simpleName: 'Chiroiu-Pamanteni ', village: 'Dragoesti ' },
            { name: 'Chiroiu-Satu Nou ', village: 'Dragoesti ' },
            { name: 'Chiroiu-Ungureni ', village: 'Dragoesti ' },
            { name: 'Valea Bisericii ', village: 'Dragoesti ' },
            { name: 'Dridu-Snagov ', village: 'Dridu ' },
            { name: 'Moldoveni ', village: 'Dridu ' },
            { name: 'Progresu ', village: 'Facaeni ' },
            { name: 'Buliga ', village: 'Fetesti ' },
            { name: 'Feteşti-Gară ', simpleName: 'Fetesti-Gara ', village: 'Fetesti ' },
            { name: 'Vlaşca ', simpleName: 'Vlasca ', village: 'Fetesti ' },
            { name: 'Fierbinţii de Jos ', simpleName: 'Fierbintii de Jos ', village: 'Fierbinti-Targ ' },
            { name: 'Fierbinţii de Sus ', simpleName: 'Fierbintii de Sus ', village: 'Fierbinti-Targ ' },
            { name: 'Grecii de Jos ', village: 'Fierbinti-Targ ' },
            { name: 'Smirna ', village: 'Grivita ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Ion Roata ' },
            { name: 'Slătioarele ', simpleName: 'Slatioarele ', village: 'Jilavele ' },
            { name: 'Gura Ialomiţei ', simpleName: 'Gura Ialomitei ', village: 'Mihail Kogalniceanu ' },
            { name: 'Hagieni ', village: 'Mihail Kogalniceanu ' },
            { name: 'Luciu ', village: 'Mihail Kogalniceanu ' },
            { name: 'Nicoleşti ', simpleName: 'Nicolesti ', village: 'Milosesti ' },
            { name: 'Tovărăşia ', simpleName: 'Tovarasia ', village: 'Milosesti ' },
            { name: 'Biţina-Pământeni ', simpleName: 'Bitina-Pamanteni ', village: 'Movilita ' },
            { name: 'Biţina-Ungureni ', simpleName: 'Bitina-Ungureni ', village: 'Movilita ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Movilita ' },
            { name: 'Fundata ', village: 'Perieti ' },
            { name: 'Misleanu ', village: 'Perieti ' },
            { name: 'Păltinişu ', simpleName: 'Paltinisu ', village: 'Perieti ' },
            { name: 'Stejaru ', village: 'Perieti ' },
            { name: 'Crunţi ', simpleName: 'Crunti ', village: 'Reviga ' },
            { name: 'Mircea cel Bătrân ', simpleName: 'Mircea cel Batran ', village: 'Reviga ' },
            { name: 'Rovine ', village: 'Reviga ' },
            { name: 'Raşi ', simpleName: 'Rasi ', village: 'Salcioara ' },
            { name: 'Frăţileşti ', simpleName: 'Fratilesti ', village: 'Saveni ' },
            { name: 'Lăcusteni ', simpleName: 'Lacusteni ', village: 'Saveni ' },
            { name: 'Platoneşti ', simpleName: 'Platonesti ', village: 'Saveni ' },
            { name: 'Iazu ', village: 'Scanteia ' },
            { name: 'Butoiu ', village: 'Sfantu Gheorghe ' },
            { name: 'Malu ', village: 'Sfantu Gheorghe ' },
            { name: 'Boteni ', village: 'Sinesti ' },
            { name: 'Cătruneşti ', simpleName: 'Catrunesti ', village: 'Sinesti ' },
            { name: 'Hagieşti ', simpleName: 'Hagiesti ', village: 'Sinesti ' },
            { name: 'Lilieci ', village: 'Sinesti ' },
            { name: 'Livedea ', village: 'Sinesti ' },
            { name: 'Bora ', village: 'Slobozia ' },
            { name: 'Slobozia Nouă ', simpleName: 'Slobozia Noua ', village: 'Slobozia ' },
            { name: 'Maltezi ', village: 'Stelnica ' },
            { name: 'Retezatu ', village: 'Stelnica ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Suditi ' },
            { name: 'Bucşa ', simpleName: 'Bucsa ', village: 'Valea Ciorii ' },
            { name: 'Dumitreşti ', simpleName: 'Dumitresti ', village: 'Valea Ciorii ' },
            { name: 'Murgeanca ', village: 'Valea Ciorii ' },
            {
                name: 'Grindaşi ', simpleName: 'Grindasi ', village: 'Valea Macrisului '
            }
        ]
    },
    {
        auto: 'IS ',
        name: 'Iaşi ',
        cities: [
            { name: 'Alexandru I. Cuza ' },
            { name: 'Andrieşeni ', simpleName: 'Andrieseni ' },
            { name: 'Aroneanu ' },
            { name: 'Balş ', simpleName: 'Bals ' },
            { name: 'Belceşti ', simpleName: 'Belcesti ' },
            { name: 'Bivolari ' },
            { name: 'Brăeşti ', simpleName: 'Braesti ' },
            { name: 'Butea ' },
            { name: 'Bârnova ', simpleName: 'Barnova ' },
            { name: 'Bălţaţi ', simpleName: 'Baltati ' },
            { name: 'Cepleniţa ', simpleName: 'Ceplenita ' },
            { name: 'Ciorteşti ', simpleName: 'Ciortesti ' },
            { name: 'Ciurea ' },
            { name: 'Coarnele Caprei ' },
            { name: 'Comarna ' },
            { name: 'Costeşti ', simpleName: 'Costesti ' },
            { name: 'Costuleni ' },
            { name: 'Cotnari ' },
            { name: 'Cozmeşti ', simpleName: 'Cozmesti ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ' },
            { name: 'Cucuteni ' },
            { name: 'Dagâţa ', simpleName: 'Dagata ' },
            { name: 'Deleni ' },
            { name: 'Dobrovăţ ', simpleName: 'Dobrovat ' },
            { name: 'Dolheşti ', simpleName: 'Dolhesti ' },
            { name: 'Drăguşeni ', simpleName: 'Draguseni ' },
            { name: 'Dumeşti ', simpleName: 'Dumesti ' },
            { name: 'Erbiceni ' },
            { name: 'Focuri ' },
            { name: 'Golăieşti ', simpleName: 'Golaiesti ' },
            { name: 'Gorban ' },
            { name: 'Grajduri ' },
            { name: 'Gropniţa ', simpleName: 'Gropnita ' },
            { name: 'Grozeşti ', simpleName: 'Grozesti ' },
            { name: 'Heleşteni ', simpleName: 'Helesteni ' },
            { name: 'Holboca ' },
            { name: 'Horleşti ', simpleName: 'Horlesti ' },
            { name: 'Hârlău ', simpleName: 'Harlau ' },
            { name: 'Hălăuceşti ', simpleName: 'Halaucesti ' },
            { name: 'Iaşi ', simpleName: 'Iasi ' },
            { name: 'Ipatele ' },
            { name: 'Lespezi ' },
            { name: 'Leţcani ', simpleName: 'Letcani ' },
            { name: 'Lungani ' },
            { name: 'Mirceşti ', simpleName: 'Mircesti ' },
            { name: 'Mironeasa ' },
            { name: 'Miroslava ' },
            { name: 'Mirosloveşti ', simpleName: 'Miroslovesti ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ' },
            { name: 'Mogoşeşti-Siret ', simpleName: 'Mogosesti-Siret ' },
            { name: 'Movileni ' },
            { name: 'Moşna ', simpleName: 'Mosna ' },
            { name: 'Moţca ', simpleName: 'Motca ' },
            { name: 'Mădârjac ', simpleName: 'Madarjac ' },
            { name: 'Oţeleni ', simpleName: 'Oteleni ' },
            { name: 'Paşcani ', simpleName: 'Pascani ' },
            { name: 'Plugari ' },
            { name: 'Podu Iloaiei ' },
            { name: 'Popeşti ', simpleName: 'Popesti ' },
            { name: 'Popricani ' },
            { name: 'Prisăcani ', simpleName: 'Prisacani ' },
            { name: 'Probota ' },
            { name: 'Rediu ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ' },
            { name: 'Ruginoasa ' },
            { name: 'Răchiteni ', simpleName: 'Rachiteni ' },
            { name: 'Răducăneni ', simpleName: 'Raducaneni ' },
            { name: 'Schitu Duca ' },
            { name: 'Scobinţi ', simpleName: 'Scobinti ' },
            { name: 'Scânteia ', simpleName: 'Scanteia ' },
            { name: 'Sineşti ', simpleName: 'Sinesti ' },
            { name: 'Sireţel ', simpleName: 'Siretel ' },
            { name: 'Stolniceni-Prăjescu ', simpleName: 'Stolniceni-Prajescu ' },
            { name: 'Strunga ' },
            { name: 'Tansa ' },
            { name: 'Todireşti ', simpleName: 'Todiresti ' },
            { name: 'Tomeşti ', simpleName: 'Tomesti ' },
            { name: 'Trifeşti ', simpleName: 'Trifesti ' },
            { name: 'Târgu Frumos ', simpleName: 'Targu Frumos ' },
            { name: 'Tătăruşi ', simpleName: 'Tatarusi ' },
            { name: 'Ungheni ' },
            { name: 'Valea Lupului ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ' },
            { name: 'Victoria ' },
            { name: 'Vlădeni ', simpleName: 'Vladeni ' },
            { name: 'Voineşti ', simpleName: 'Voinesti ' },
            { name: 'Vânători ', simpleName: 'Vanatori ' },
            { name: 'Şcheia ', simpleName: 'Scheia ' },
            { name: 'Şipote ', simpleName: 'Sipote ' },
            { name: 'Ţibana ', simpleName: 'Tibana ' },
            { name: 'Ţibăneşti ', simpleName: 'Tibanesti ' },
            { name: 'Ţigănăşi ', simpleName: 'Tiganasi ' },
            { name: 'Ţuţora ', simpleName: 'Tutora ' },
            { name: 'Kogălniceni ', simpleName: 'Kogalniceni ', village: 'Alexandru I. Cuza ' },
            { name: 'Scheia ', village: 'Alexandru I. Cuza ' },
            { name: 'Volintireşti ', simpleName: 'Volintiresti ', village: 'Alexandru I. Cuza ' },
            { name: 'Buhăeni ', simpleName: 'Buhaeni ', village: 'Andrieseni ' },
            { name: 'Drăgăneşti ', simpleName: 'Draganesti ', village: 'Andrieseni ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Andrieseni ' },
            { name: 'Glăvăneşti ', simpleName: 'Glavanesti ', village: 'Andrieseni ' },
            { name: 'Iepureni ', village: 'Andrieseni ' },
            { name: 'Spineni ', village: 'Andrieseni ' },
            { name: 'Dorobanţ ', simpleName: 'Dorobant ', village: 'Aroneanu ' },
            { name: 'Rediu Aldei ', village: 'Aroneanu ' },
            { name: 'Şorogani ', simpleName: 'Sorogani ', village: 'Aroneanu ' },
            { name: 'Boureni ', village: 'Bals ' },
            { name: 'Cotârgaci ', simpleName: 'Cotargaci ', village: 'Baltati ' },
            { name: 'Filiaşi ', simpleName: 'Filiasi ', village: 'Baltati ' },
            { name: 'Mădârjeşti ', simpleName: 'Madarjesti ', village: 'Baltati ' },
            { name: 'Podişu ', simpleName: 'Podisu ', village: 'Baltati ' },
            { name: 'Sârca ', simpleName: 'Sarca ', village: 'Baltati ' },
            { name: 'Valea Oilor ', village: 'Baltati ' },
            { name: 'Cercu ', village: 'Barnova ' },
            { name: 'Pietrăria ', simpleName: 'Pietraria ', village: 'Barnova ' },
            { name: 'Păun ', simpleName: 'Paun ', village: 'Barnova ' },
            { name: 'Todirel ', village: 'Barnova ' },
            { name: 'Vişan ', simpleName: 'Visan ', village: 'Barnova ' },
            { name: 'Liteni ', village: 'Belcesti ' },
            { name: 'Munteni ', village: 'Belcesti ' },
            { name: 'Satu Nou ', village: 'Belcesti ' },
            { name: 'Tansa ', village: 'Belcesti ' },
            { name: 'Ulmi ', village: 'Belcesti ' },
            { name: 'Buruieneşti ', simpleName: 'Buruienesti ', village: 'Bivolari ' },
            { name: 'Soloneţ ', simpleName: 'Solonet ', village: 'Bivolari ' },
            { name: 'Tabăra ', simpleName: 'Tabara ', village: 'Bivolari ' },
            { name: 'Traian ', village: 'Bivolari ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Braesti ' },
            { name: 'Buda ', village: 'Braesti ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ', village: 'Braesti ' },
            { name: 'Rediu ', village: 'Braesti ' },
            { name: 'Miclăuşeni ', simpleName: 'Miclauseni ', village: 'Butea ' },
            { name: 'Buhalniţa ', simpleName: 'Buhalnita ', village: 'Ceplenita ' },
            { name: 'Poiana Mărului ', simpleName: 'Poiana Marului ', village: 'Ceplenita ' },
            { name: 'Zlodica ', village: 'Ceplenita ' },
            { name: 'Coropceni ', village: 'Ciortesti ' },
            { name: 'Deleni ', village: 'Ciortesti ' },
            { name: 'Rotăria ', simpleName: 'Rotaria ', village: 'Ciortesti ' },
            { name: 'Şerbeşti ', simpleName: 'Serbesti ', village: 'Ciortesti ' },
            { name: 'Curături ', simpleName: 'Curaturi ', village: 'Ciurea ' },
            { name: 'Dumbrava ', village: 'Ciurea ' },
            { name: 'Hlincea ', village: 'Ciurea ' },
            { name: 'Lunca Cetăţuii ', simpleName: 'Lunca Cetatuii ', village: 'Ciurea ' },
            { name: 'Picioru Lupului ', village: 'Ciurea ' },
            { name: 'Slobozia ', village: 'Ciurea ' },
            { name: 'Arama ', village: 'Coarnele Caprei ' },
            { name: 'Petroşica ', simpleName: 'Petrosica ', village: 'Coarnele Caprei ' },
            { name: 'Curagău ', simpleName: 'Curagau ', village: 'Comarna ' },
            { name: 'Osoi ', village: 'Comarna ' },
            { name: 'Stânca ', simpleName: 'Stanca ', village: 'Comarna ' },
            { name: 'Covasna ', village: 'Costuleni ' },
            { name: 'Cozia ', village: 'Costuleni ' },
            { name: 'Hiliţa ', simpleName: 'Hilita ', village: 'Costuleni ' },
            { name: 'Bahluiu ', village: 'Cotnari ' },
            { name: 'Cireşeni ', simpleName: 'Cireseni ', village: 'Cotnari ' },
            { name: 'Cârjoaia ', simpleName: 'Carjoaia ', village: 'Cotnari ' },
            { name: 'Făgăt ', simpleName: 'Fagat ', village: 'Cotnari ' },
            { name: 'Hodora ', village: 'Cotnari ' },
            { name: 'Horodiştea ', simpleName: 'Horodistea ', village: 'Cotnari ' },
            { name: 'Iosupeni ', village: 'Cotnari ' },
            { name: 'Lupăria ', simpleName: 'Luparia ', village: 'Cotnari ' },
            { name: 'Valea Racului ', village: 'Cotnari ' },
            { name: 'Zbereni ', village: 'Cotnari ' },
            { name: 'Podolenii de Jos ', village: 'Cozmesti ' },
            { name: 'Podolenii de Sus ', village: 'Cozmesti ' },
            { name: 'Homiţa ', simpleName: 'Homita ', village: 'Cristesti ' },
            { name: 'Băiceni ', simpleName: 'Baiceni ', village: 'Cucuteni ' },
            { name: 'Bărbăteşti ', simpleName: 'Barbatesti ', village: 'Cucuteni ' },
            { name: 'Săcăreşti ', simpleName: 'Sacaresti ', village: 'Cucuteni ' },
            { name: 'Boatca ', village: 'Dagata ' },
            { name: 'Buzdug ', village: 'Dagata ' },
            { name: 'Băluşeşti ', simpleName: 'Balusesti ', village: 'Dagata ' },
            { name: 'Mănăstirea ', simpleName: 'Manastirea ', village: 'Dagata ' },
            { name: 'Piscu Rusului ', village: 'Dagata ' },
            { name: 'Poienile ', village: 'Dagata ' },
            { name: 'Tarniţa ', simpleName: 'Tarnita ', village: 'Dagata ' },
            { name: 'Zece Prăjini ', simpleName: 'Zece Prajini ', village: 'Dagata ' },
            { name: 'Feredeni ', village: 'Deleni ' },
            { name: 'Leahu-Nacu ', village: 'Deleni ' },
            { name: 'Maxut ', village: 'Deleni ' },
            { name: 'Poiana ', village: 'Deleni ' },
            { name: 'Slobozia ', village: 'Deleni ' },
            { name: 'Brădiceşti ', simpleName: 'Bradicesti ', village: 'Dolhesti ' },
            { name: 'Pietriş ', simpleName: 'Pietris ', village: 'Dolhesti ' },
            { name: 'Banu ', village: 'Dumesti ' },
            { name: 'Chilişoaia ', simpleName: 'Chilisoaia ', village: 'Dumesti ' },
            { name: 'Hoiseşti ', simpleName: 'Hoisesti ', village: 'Dumesti ' },
            { name: 'Păuşeşti ', simpleName: 'Pausesti ', village: 'Dumesti ' },
            { name: 'Bârleşti ', simpleName: 'Barlesti ', village: 'Erbiceni ' },
            { name: 'Spinoasa ', village: 'Erbiceni ' },
            { name: 'Sprânceana ', simpleName: 'Spranceana ', village: 'Erbiceni ' },
            { name: 'Totoeşti ', simpleName: 'Totoesti ', village: 'Erbiceni ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Fintinele ' },
            { name: 'Bran ', village: 'Golaiesti ' },
            { name: 'Cilibiu ', village: 'Golaiesti ' },
            { name: 'Cotu lui Ivan ', village: 'Golaiesti ' },
            { name: 'Grădinari ', simpleName: 'Gradinari ', village: 'Golaiesti ' },
            { name: 'Medeleni ', village: 'Golaiesti ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Golaiesti ' },
            { name: 'Podu Jijiei ', village: 'Golaiesti ' },
            { name: 'Gura Bohotin ', village: 'Gorban ' },
            { name: 'Podu Hagiului ', village: 'Gorban ' },
            { name: 'Scoposeni ', village: 'Gorban ' },
            { name: 'Zberoaia ', village: 'Gorban ' },
            { name: 'Corcodel ', village: 'Grajduri ' },
            { name: 'Cărbunari ', simpleName: 'Carbunari ', village: 'Grajduri ' },
            { name: 'Lunca ', village: 'Grajduri ' },
            { name: 'Poiana cu Cetate ', village: 'Grajduri ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Grajduri ' },
            { name: 'Valea Satului ', village: 'Grajduri ' },
            { name: 'Bulbucani ', village: 'Gropnita ' },
            { name: 'Forăşti ', simpleName: 'Forasti ', village: 'Gropnita ' },
            { name: 'Mălăeşti ', simpleName: 'Malaesti ', village: 'Gropnita ' },
            { name: 'Sângeri ', simpleName: 'Sangeri ', village: 'Gropnita ' },
            { name: 'Săveni ', simpleName: 'Saveni ', village: 'Gropnita ' },
            { name: 'Colţu Cornii ', simpleName: 'Coltu Cornii ', village: 'Grozesti ' },
            { name: 'Sălăgeni ', simpleName: 'Salageni ', village: 'Grozesti ' },
            { name: 'Luncaşi ', simpleName: 'Luncasi ', village: 'Halaucesti ' },
            { name: 'Pârcovaci ', simpleName: 'Parcovaci ', village: 'Harlau ' },
            { name: 'Hărmăneasa ', simpleName: 'Harmaneasa ', village: 'Helesteni ' },
            { name: 'Movileni ', village: 'Helesteni ' },
            { name: 'Oboroceni ', village: 'Helesteni ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ', village: 'Holboca ' },
            { name: 'Dancu ', village: 'Holboca ' },
            { name: 'Orzeni ', village: 'Holboca ' },
            { name: 'Rusenii Noi ', village: 'Holboca ' },
            { name: 'Rusenii Vechi ', village: 'Holboca ' },
            { name: 'Valea Lungă ', simpleName: 'Valea Lunga ', village: 'Holboca ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Horlesti ' },
            { name: 'Scoposeni ', village: 'Horlesti ' },
            { name: 'Buznea ', village: 'Ion Neculce ' },
            { name: 'Prigoreni ', village: 'Ion Neculce ' },
            { name: 'Războieni ', simpleName: 'Razboieni ', village: 'Ion Neculce ' },
            { name: 'Alexeşti ', simpleName: 'Alexesti ', village: 'Ipatele ' },
            { name: 'Bâcu ', simpleName: 'Bacu ', village: 'Ipatele ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Ipatele ' },
            { name: 'Buda ', village: 'Lespezi ' },
            { name: 'Bursuc-Deal ', village: 'Lespezi ' },
            { name: 'Bursuc-Vale ', village: 'Lespezi ' },
            { name: 'Dumbrava ', village: 'Lespezi ' },
            { name: 'Heci ', village: 'Lespezi ' },
            { name: 'Bogonos ', village: 'Letcani ' },
            { name: 'Cogeasca ', village: 'Letcani ' },
            { name: 'Cucuteni ', village: 'Letcani ' },
            { name: 'Crucea ', village: 'Lungani ' },
            { name: 'Goeşti ', simpleName: 'Goesti ', village: 'Lungani ' },
            { name: 'Zmeu ', village: 'Lungani ' },
            { name: 'Bojila ', village: 'Madarjac ' },
            { name: 'Frumuşica ', simpleName: 'Frumusica ', village: 'Madarjac ' },
            { name: 'Iugani ', village: 'Mircesti ' },
            { name: 'Ursăreşti ', simpleName: 'Ursaresti ', village: 'Mircesti ' },
            { name: 'Schitu Hadâmbului ', simpleName: 'Schitu Hadambului ', village: 'Mironeasa ' },
            { name: 'Urşiţa ', simpleName: 'Ursita ', village: 'Mironeasa ' },
            { name: 'Balciu ', village: 'Miroslava ' },
            { name: 'Brătuleni ', simpleName: 'Bratuleni ', village: 'Miroslava ' },
            { name: 'Ciurbeşti ', simpleName: 'Ciurbesti ', village: 'Miroslava ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Miroslava ' },
            { name: 'Dancaş ', simpleName: 'Dancas ', village: 'Miroslava ' },
            { name: 'Găureni ', simpleName: 'Gaureni ', village: 'Miroslava ' },
            { name: 'Horpaz ', village: 'Miroslava ' },
            { name: 'Proselnici ', village: 'Miroslava ' },
            { name: 'Uricani ', village: 'Miroslava ' },
            { name: 'Valea Adâncă ', simpleName: 'Valea Adanca ', village: 'Miroslava ' },
            { name: 'Valea Ursului ', village: 'Miroslava ' },
            { name: 'Voroveşti ', simpleName: 'Vorovesti ', village: 'Miroslava ' },
            { name: 'Ciohorăni ', simpleName: 'Ciohorani ', village: 'Miroslovesti ' },
            { name: 'Miteşti ', simpleName: 'Mitesti ', village: 'Miroslovesti ' },
            { name: 'Soci ', village: 'Miroslovesti ' },
            { name: 'Verşeni ', simpleName: 'Verseni ', village: 'Miroslovesti ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Mogosesti ' },
            { name: 'Hadâmbu ', simpleName: 'Hadambu ', village: 'Mogosesti ' },
            { name: 'Mânjeşti ', simpleName: 'Manjesti ', village: 'Mogosesti ' },
            { name: 'Muncelu de Sus ', village: 'Mogosesti-Siret ' },
            { name: 'Tudor Vladimirescu ', village: 'Mogosesti-Siret ' },
            { name: 'Boureni ', village: 'Motca ' },
            { name: 'Iepureni ', village: 'Movileni ' },
            { name: 'Larga-Jijia ', village: 'Movileni ' },
            { name: 'Potângeni ', simpleName: 'Potangeni ', village: 'Movileni ' },
            { name: 'Hândreşti ', simpleName: 'Handresti ', village: 'Oteleni ' },
            { name: 'Blăgeşti ', simpleName: 'Blagesti ', village: 'Pascani ' },
            { name: 'Boşteni ', simpleName: 'Bosteni ', village: 'Pascani ' },
            { name: 'Gâşteşti ', simpleName: 'Gastesti ', village: 'Pascani ' },
            { name: 'Lunca ', village: 'Pascani ' },
            { name: 'Sodomeni ', village: 'Pascani ' },
            { name: 'Borosoaia ', village: 'Plugari ' },
            { name: 'Oneşti ', simpleName: 'Onesti ', village: 'Plugari ' },
            { name: 'Budăi ', simpleName: 'Budai ', village: 'Podu Iloaiei ' },
            { name: 'Cosiţeni ', simpleName: 'Cositeni ', village: 'Podu Iloaiei ' },
            { name: 'Holm ', village: 'Podu Iloaiei ' },
            { name: 'Scobâlţeni ', simpleName: 'Scobalteni ', village: 'Podu Iloaiei ' },
            { name: 'Doroşcani ', simpleName: 'Doroscani ', village: 'Popesti ' },
            { name: 'Hărpăşeşti ', simpleName: 'Harpasesti ', village: 'Popesti ' },
            { name: 'Obrijeni ', village: 'Popesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Popesti ' },
            { name: 'Vama ', village: 'Popesti ' },
            { name: 'Cotu Morii ', village: 'Popricani ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Popricani ' },
            { name: 'Cârlig ', simpleName: 'Carlig ', village: 'Popricani ' },
            { name: 'Moimeşti ', simpleName: 'Moimesti ', village: 'Popricani ' },
            { name: 'Rediu Mitropoliei ', village: 'Popricani ' },
            { name: 'Vulturi ', village: 'Popricani ' },
            { name: 'Vânători ', simpleName: 'Vanatori ', village: 'Popricani ' },
            { name: 'Ţipileşti ', simpleName: 'Tipilesti ', village: 'Popricani ' },
            { name: 'Moreni ', village: 'Prisacani ' },
            { name: 'Măcăreşti ', simpleName: 'Macaresti ', village: 'Prisacani ' },
            { name: 'Bălteni ', simpleName: 'Balteni ', village: 'Probota ' },
            { name: 'Perieni ', village: 'Probota ' },
            { name: 'Izvoarele ', village: 'Rachiteni ' },
            { name: 'Bohotin ', village: 'Raducaneni ' },
            { name: 'Isaiia ', village: 'Raducaneni ' },
            { name: 'Roşu ', simpleName: 'Rosu ', village: 'Raducaneni ' },
            { name: 'Breazu ', village: 'Rediu ' },
            { name: 'Horleşti ', simpleName: 'Horlesti ', village: 'Rediu ' },
            { name: 'Tăuteşti ', simpleName: 'Tautesti ', village: 'Rediu ' },
            { name: 'Avântu ', simpleName: 'Avantu ', village: 'Romanesti ' },
            { name: 'Ursoaia ', village: 'Romanesti ' },
            { name: 'Rădeni ', simpleName: 'Radeni ', village: 'Roscani ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Ruginoasa ' },
            { name: 'Rediu ', village: 'Ruginoasa ' },
            { name: 'Vascani ', village: 'Ruginoasa ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ', village: 'Scanteia ' },
            { name: 'Boroşeşti ', simpleName: 'Borosesti ', village: 'Scanteia ' },
            { name: 'Ciocârleşti ', simpleName: 'Ciocarlesti ', village: 'Scanteia ' },
            { name: 'Lunca Rateş ', simpleName: 'Lunca Rates ', village: 'Scanteia ' },
            { name: 'Rediu ', village: 'Scanteia ' },
            { name: 'Tufeştii de Sus ', simpleName: 'Tufestii de Sus ', village: 'Scanteia ' },
            { name: 'Cioca-Boca ', village: 'Scheia ' },
            { name: 'Căueşti ', simpleName: 'Cauesti ', village: 'Scheia ' },
            { name: 'Frenciugi ', village: 'Scheia ' },
            { name: 'Poiana Şcheii ', simpleName: 'Poiana Scheii ', village: 'Scheia ' },
            { name: 'Satu Nou ', village: 'Scheia ' },
            { name: 'Blaga ', village: 'Schitu Duca ' },
            { name: 'Dumitreştii Gălăţii ', simpleName: 'Dumitrestii Galatii ', village: 'Schitu Duca ' },
            { name: 'Pocreaca ', village: 'Schitu Duca ' },
            { name: 'Poiana ', village: 'Schitu Duca ' },
            { name: 'Poieni ', village: 'Schitu Duca ' },
            { name: 'Satu Nou ', village: 'Schitu Duca ' },
            { name: 'Slobozia ', village: 'Schitu Duca ' },
            { name: 'Bădeni ', simpleName: 'Badeni ', village: 'Scobinti ' },
            { name: 'Feteşti ', simpleName: 'Fetesti ', village: 'Scobinti ' },
            { name: 'Sticlăria ', simpleName: 'Sticlaria ', village: 'Scobinti ' },
            { name: 'Zagavia ', village: 'Scobinti ' },
            { name: 'Bocniţa ', simpleName: 'Bocnita ', village: 'Sinesti ' },
            { name: 'Osoi ', village: 'Sinesti ' },
            { name: 'Storneşti ', simpleName: 'Stornesti ', village: 'Sinesti ' },
            { name: 'Chişcăreni ', simpleName: 'Chiscareni ', village: 'Sipote ' },
            { name: 'Hălceni ', simpleName: 'Halceni ', village: 'Sipote ' },
            { name: 'Iazu Nou ', village: 'Sipote ' },
            { name: 'Iazu Vechi ', village: 'Sipote ' },
            { name: 'Mitoc ', village: 'Sipote ' },
            { name: 'Berezlogi ', village: 'Siretel ' },
            { name: 'Humosu ', village: 'Siretel ' },
            { name: 'Satu Nou ', village: 'Siretel ' },
            { name: 'Slobozia ', village: 'Siretel ' },
            { name: 'Brăteşti ', simpleName: 'Bratesti ', village: 'Stolniceni-Prajescu ' },
            { name: 'Cozmeşti ', simpleName: 'Cozmesti ', village: 'Stolniceni-Prajescu ' },
            { name: 'Brătuleşti ', simpleName: 'Bratulesti ', village: 'Strunga ' },
            { name: 'Criveşti ', simpleName: 'Crivesti ', village: 'Strunga ' },
            { name: 'Cucova ', village: 'Strunga ' },
            { name: 'Fedeleşeni ', simpleName: 'Fedeleseni ', village: 'Strunga ' },
            { name: 'Fărcăşeni ', simpleName: 'Farcaseni ', village: 'Strunga ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Strunga ' },
            { name: 'Hăbăşeşti ', simpleName: 'Habasesti ', village: 'Strunga ' },
            { name: 'Suhuleţ ', simpleName: 'Suhulet ', village: 'Tansa ' },
            { name: 'Coasta Măgurii ', simpleName: 'Coasta Magurii ', village: 'Targu Frumos ' },
            { name: 'Dădeşti ', simpleName: 'Dadesti ', village: 'Targu Frumos ' },
            { name: 'Giurgeşti ', simpleName: 'Giurgesti ', village: 'Targu Frumos ' },
            { name: 'Găneşti ', simpleName: 'Ganesti ', village: 'Targu Frumos ' },
            { name: 'Ion Neculce ', village: 'Targu Frumos ' },
            { name: 'Iorcani ', village: 'Tatarusi ' },
            { name: 'Pietrosu ', village: 'Tatarusi ' },
            { name: 'Uda ', village: 'Tatarusi ' },
            { name: 'Vâlcica ', simpleName: 'Valcica ', village: 'Tatarusi ' },
            { name: 'Alexeni ', village: 'Tibana ' },
            { name: 'Domniţa ', simpleName: 'Domnita ', village: 'Tibana ' },
            { name: 'Gârbeşti ', simpleName: 'Garbesti ', village: 'Tibana ' },
            { name: 'Moara Ciornei ', village: 'Tibana ' },
            { name: 'Oproaia ', village: 'Tibana ' },
            { name: 'Poiana Mănăstirii ', simpleName: 'Poiana Manastirii ', village: 'Tibana ' },
            { name: 'Poiana de Sus ', village: 'Tibana ' },
            { name: 'Runcu ', village: 'Tibana ' },
            { name: 'Vadu Vejei ', village: 'Tibana ' },
            { name: 'Glodenii Gândului ', simpleName: 'Glodenii Gandului ', village: 'Tibanesti ' },
            { name: 'Grieşti ', simpleName: 'Griesti ', village: 'Tibanesti ' },
            { name: 'Jigoreni ', village: 'Tibanesti ' },
            { name: 'Recea ', village: 'Tibanesti ' },
            { name: 'Răsboieni ', simpleName: 'Rasboieni ', village: 'Tibanesti ' },
            { name: 'Tungujei ', village: 'Tibanesti ' },
            { name: 'Vălenii ', simpleName: 'Valenii ', village: 'Tibanesti ' },
            { name: 'Cârniceni ', simpleName: 'Carniceni ', village: 'Tiganasi ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ', village: 'Tiganasi ' },
            { name: 'Stejarii ', village: 'Tiganasi ' },
            { name: 'Boldeşti ', simpleName: 'Boldesti ', village: 'Todiresti ' },
            { name: 'Băiceni ', simpleName: 'Baiceni ', village: 'Todiresti ' },
            { name: 'Hărmăneştii Noi ', simpleName: 'Harmanestii Noi ', village: 'Todiresti ' },
            { name: 'Hărmăneştii Vechi ', simpleName: 'Harmanestii Vechi ', village: 'Todiresti ' },
            { name: 'Stroeşti ', simpleName: 'Stroesti ', village: 'Todiresti ' },
            { name: 'Chicerea ', village: 'Tomesti ' },
            { name: 'Goruni ', village: 'Tomesti ' },
            { name: 'Vlădiceni ', simpleName: 'Vladiceni ', village: 'Tomesti ' },
            { name: 'Hermeziu ', village: 'Trifesti ' },
            { name: 'Roşcani ', simpleName: 'Roscani ', village: 'Trifesti ' },
            { name: 'Vladomira ', village: 'Trifesti ' },
            { name: 'Zaboloteni ', village: 'Trifesti ' },
            { name: 'Chipereşti ', simpleName: 'Chiperesti ', village: 'Tutora ' },
            { name: 'Oprişeni ', simpleName: 'Opriseni ', village: 'Tutora ' },
            { name: 'Bosia ', village: 'Ungheni ' },
            { name: 'Coada Stâncii ', simpleName: 'Coada Stancii ', village: 'Ungheni ' },
            { name: 'Mânzăteşti ', simpleName: 'Manzatesti ', village: 'Ungheni ' },
            { name: 'Conţeşti ', simpleName: 'Contesti ', village: 'Valea Seaca ' },
            { name: 'Topile ', village: 'Valea Seaca ' },
            { name: 'Criveşti ', simpleName: 'Crivesti ', village: 'Vanatori ' },
            { name: 'Gura Bâdiliţei ', simpleName: 'Gura Badilitei ', village: 'Vanatori ' },
            { name: 'Hârtoape ', simpleName: 'Hartoape ', village: 'Vanatori ' },
            { name: 'Vlădnicuţ ', simpleName: 'Vladnicut ', village: 'Vanatori ' },
            { name: 'Frăsuleni ', simpleName: 'Frasuleni ', village: 'Victoria ' },
            { name: 'Icuşeni ', simpleName: 'Icuseni ', village: 'Victoria ' },
            { name: 'Luceni ', village: 'Victoria ' },
            { name: 'Sculeni ', village: 'Victoria ' },
            { name: 'Stânca ', simpleName: 'Stanca ', village: 'Victoria ' },
            { name: 'Şendreni ', simpleName: 'Sendreni ', village: 'Victoria ' },
            { name: 'Alexandru cel Bun ', village: 'Vladeni ' },
            { name: 'Borşa ', simpleName: 'Borsa ', village: 'Vladeni ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Vladeni ' },
            { name: 'Iacobeni ', village: 'Vladeni ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Vladeni ' },
            { name: 'Lungani ', village: 'Voinesti ' },
            { name: 'Schitu Stavnic ', village: 'Voinesti ' },
            { name: 'Slobozia ', village: 'Voinesti ' },
            {
                name: 'Vocoteşti ', simpleName: 'Vocotesti ', village: 'Voinesti '
            }
        ]
    },
    {
        auto: 'IF ',
        name: 'Ilfov ',
        cities: [
            { name: '1 Decembrie ' },
            { name: 'Afumaţi ', simpleName: 'Afumati ' },
            { name: 'Baloteşti ', simpleName: 'Balotesti ' },
            { name: 'Berceni ' },
            { name: 'Bragadiru ' },
            { name: 'Brăneşti ', simpleName: 'Branesti ' },
            { name: 'Buftea ' },
            { name: 'Cernica ' },
            { name: 'Chiajna ' },
            { name: 'Chitila ' },
            { name: 'Ciolpani ' },
            { name: 'Ciorogârla ', simpleName: 'Ciorogarla ' },
            { name: 'Clinceni ' },
            { name: 'Corbeanca ' },
            { name: 'Cornetu ' },
            { name: 'Dascălu ', simpleName: 'Dascalu ' },
            { name: 'Domneşti ', simpleName: 'Domnesti ' },
            { name: 'Dragomireşti-Vale ', simpleName: 'Dragomiresti-Vale ' },
            { name: 'Dărăşti-Ilfov ', simpleName: 'Darasti-Ilfov ' },
            { name: 'Glina ' },
            { name: 'Gruiu ' },
            { name: 'Grădiştea ', simpleName: 'Gradistea ' },
            { name: 'Găneasa ', simpleName: 'Ganeasa ' },
            { name: 'Jilava ' },
            { name: 'Moara Vlăsiei ', simpleName: 'Moara Vlasiei ' },
            { name: 'Mogoşoaia ', simpleName: 'Mogosoaia ' },
            { name: 'Măgurele ', simpleName: 'Magurele ' },
            { name: 'Nuci ' },
            { name: 'Otopeni ' },
            { name: 'Pantelimon ' },
            { name: 'Periş ', simpleName: 'Peris ' },
            { name: 'Petrăchioaia ', simpleName: 'Petrachioaia ' },
            { name: 'Popeşti Leordeni ', simpleName: 'Popesti Leordeni ' },
            { name: 'Snagov ' },
            { name: 'Tunari ' },
            { name: 'Vidra ' },
            { name: 'Voluntari ' },
            { name: 'Ştefăneştii de Jos ', simpleName: 'Stefanestii de Jos ' },
            { name: 'Copăceni ', simpleName: 'Copaceni ', village: '1 Decembrie ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ', village: 'Balotesti ' },
            { name: 'Săftica ', simpleName: 'Saftica ', village: 'Balotesti ' },
            { name: 'Islaz ', village: 'Branesti ' },
            { name: 'Pasărea ', simpleName: 'Pasarea ', village: 'Branesti ' },
            { name: 'Vadu Anei ', village: 'Branesti ' },
            { name: 'Buciumeni ', village: 'Buftea ' },
            { name: 'Bălăceanca ', simpleName: 'Balaceanca ', village: 'Cernica ' },
            { name: 'Căldăraru ', simpleName: 'Caldararu ', village: 'Cernica ' },
            { name: 'Poşta ', simpleName: 'Posta ', village: 'Cernica ' },
            { name: 'Tânganu ', simpleName: 'Tanganu ', village: 'Cernica ' },
            { name: 'Dudu ', village: 'Chiajna ' },
            { name: 'Roşu ', simpleName: 'Rosu ', village: 'Chiajna ' },
            { name: 'Rudeni ', village: 'Chitila ' },
            { name: 'Izvorani ', village: 'Ciolpani ' },
            { name: 'Lupăria ', simpleName: 'Luparia ', village: 'Ciolpani ' },
            { name: 'Piscu ', village: 'Ciolpani ' },
            { name: 'Dârvari ', simpleName: 'Darvari ', village: 'Ciorogarla ' },
            { name: 'Olteni ', village: 'Clinceni ' },
            { name: 'Ordoreanu ', village: 'Clinceni ' },
            { name: 'Ostratu ', village: 'Corbeanca ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Corbeanca ' },
            { name: 'Tamaşi ', simpleName: 'Tamasi ', village: 'Corbeanca ' },
            { name: 'Buda ', village: 'Cornetu ' },
            { name: 'Creaţa ', simpleName: 'Creata ', village: 'Dascalu ' },
            { name: 'Gagu ', village: 'Dascalu ' },
            { name: 'Runcu ', village: 'Dascalu ' },
            { name: 'Dobroeşti ', simpleName: 'Dobroesti ', village: 'Dobroiesti ' },
            { name: 'Fundeni ', village: 'Dobroiesti ' },
            { name: 'Ţegheş ', simpleName: 'Teghes ', village: 'Domnesti ' },
            { name: 'Dragomireşti-Deal ', simpleName: 'Dragomiresti-Deal ', village: 'Dragomiresti-Vale ' },
            { name: 'Zurbaua ', village: 'Dragomiresti-Vale ' },
            { name: 'Cozieni ', village: 'Ganeasa ' },
            { name: 'Moara Domnească ', simpleName: 'Moara Domneasca ', village: 'Ganeasa ' },
            { name: 'Piteasca ', village: 'Ganeasa ' },
            { name: 'Şindriliţa ', simpleName: 'Sindrilita ', village: 'Ganeasa ' },
            { name: 'Căţelu ', simpleName: 'Catelu ', village: 'Glina ' },
            { name: 'Manolache ', village: 'Glina ' },
            { name: 'Sitaru ', village: 'Gradistea ' },
            { name: 'Lipia ', village: 'Gruiu ' },
            { name: 'Siliştea Snagovului ', simpleName: 'Silistea Snagovului ', village: 'Gruiu ' },
            { name: 'Şanţu Floreşti ', simpleName: 'Santu Floresti ', village: 'Gruiu ' },
            { name: 'Alunişu ', simpleName: 'Alunisu ', village: 'Magurele ' },
            { name: 'Dumitrana ', village: 'Magurele ' },
            { name: 'Pruni ', village: 'Magurele ' },
            { name: 'Vârteju ', simpleName: 'Varteju ', village: 'Magurele ' },
            { name: 'Căciulaţi ', simpleName: 'Caciulati ', village: 'Moara Vlasiei ' },
            { name: 'Balta Neagră ', simpleName: 'Balta Neagra ', village: 'Nuci ' },
            { name: 'Merii Petchii ', village: 'Nuci ' },
            { name: 'Micşuneştii Mari ', simpleName: 'Micsunestii Mari ', village: 'Nuci ' },
            { name: 'Micşuneştii-Moară ', simpleName: 'Micsunestii-Moara ', village: 'Nuci ' },
            { name: 'Odăile ', simpleName: 'Odaile ', village: 'Otopeni ' },
            { name: 'Buriaş ', simpleName: 'Burias ', village: 'Peris ' },
            { name: 'Bălteni ', simpleName: 'Balteni ', village: 'Peris ' },
            { name: 'Măineasca ', simpleName: 'Maineasca ', village: 'Petrachioaia ' },
            { name: 'Surlari ', village: 'Petrachioaia ' },
            { name: 'Vânători ', simpleName: 'Vanatori ', village: 'Petrachioaia ' },
            { name: 'Cioflinceni ', village: 'Snagov ' },
            { name: 'Ghermăneşti ', simpleName: 'Ghermanesti ', village: 'Snagov ' },
            { name: 'Tâncăbeşti ', simpleName: 'Tancabesti ', village: 'Snagov ' },
            { name: 'Vlădiceasca ', simpleName: 'Vladiceasca ', village: 'Snagov ' },
            { name: 'Creţuleasca ', simpleName: 'Cretuleasca ', village: 'Stefanestii De Jos ' },
            { name: 'Ştefăneştii de Sus ', simpleName: 'Stefanestii de Sus ', village: 'Stefanestii De Jos ' },
            { name: 'Dimieni ', village: 'Tunari ' },
            { name: 'Creţeşti ', simpleName: 'Cretesti ', village: 'Vidra ' },
            {
                name: 'Sinteşti ', simpleName: 'Sintesti ', village: 'Vidra '
            }
        ]
    },
    {
        auto: 'MM ',
        name: 'Maramureş ',
        cities: [
            { name: 'Ardusat ' },
            { name: 'Ariniş ', simpleName: 'Arinis ' },
            { name: 'Asuaju de Sus ' },
            { name: 'Baia Mare ' },
            { name: 'Baia Sprie ' },
            { name: 'Bicaz ' },
            { name: 'Bistra ' },
            { name: 'Bocicoiu Mare ' },
            { name: 'Bogdan Vodă ', simpleName: 'Bogdan Voda ' },
            { name: 'Boiu Mare ' },
            { name: 'Borşa ', simpleName: 'Borsa ' },
            { name: 'Botiza ' },
            { name: 'Budeşti ', simpleName: 'Budesti ' },
            { name: 'Bârsana ', simpleName: 'Barsana ' },
            { name: 'Băiuţ ', simpleName: 'Baiut ' },
            { name: 'Băiţa de Sub Codru ', simpleName: 'Baita de Sub Codru ' },
            { name: 'Băseşti ', simpleName: 'Basesti ' },
            { name: 'Cavnic ' },
            { name: 'Cerneşti ', simpleName: 'Cernesti ' },
            { name: 'Cicârlău ', simpleName: 'Cicarlau ' },
            { name: 'Copalnic-Mănăştur ', simpleName: 'Copalnic-Manastur ' },
            { name: 'Coroieni ' },
            { name: 'Cupşeni ', simpleName: 'Cupseni ' },
            { name: 'Câmpulung la Tisa ', simpleName: 'Campulung la Tisa ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ' },
            { name: 'Deseşti ', simpleName: 'Desesti ' },
            { name: 'Dragomireşti ', simpleName: 'Dragomiresti ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ' },
            { name: 'Fărcaşa ', simpleName: 'Farcasa ' },
            { name: 'Giuleşti ', simpleName: 'Giulesti ' },
            { name: 'Groşi ', simpleName: 'Grosi ' },
            { name: 'Groşii Ţibleşului ', simpleName: 'Grosii Tiblesului ' },
            { name: 'Ieud ' },
            { name: 'Leordina ' },
            { name: 'Lăpuş ', simpleName: 'Lapus ' },
            { name: 'Mireşu Mare ', simpleName: 'Miresu Mare ' },
            { name: 'Moisei ' },
            { name: 'Oarţa de Jos ', simpleName: 'Oarta de Jos ' },
            { name: 'Ocna Şugatag ', simpleName: 'Ocna Sugatag ' },
            { name: 'Petrova ' },
            { name: 'Poienile Izei ' },
            { name: 'Poienile de Sub Munte ' },
            { name: 'Recea ' },
            { name: 'Remetea Chioarului ' },
            { name: 'Remeţi ', simpleName: 'Remeti ' },
            { name: 'Repedea ' },
            { name: 'Rona de Jos ' },
            { name: 'Rona de Sus ' },
            { name: 'Rozavlea ' },
            { name: 'Ruscova ' },
            { name: 'Sarasău ', simpleName: 'Sarasau ' },
            { name: 'Satulung ' },
            { name: 'Seini ' },
            { name: 'Sighetu Marmaţiei ', simpleName: 'Sighetu Marmatiei ' },
            { name: 'Strâmtura ', simpleName: 'Stramtura ' },
            { name: 'Suciu de Sus ' },
            { name: 'Săcel ', simpleName: 'Sacel ' },
            { name: 'Săcălăşeni ', simpleName: 'Sacalaseni ' },
            { name: 'Săliştea de Sus ', simpleName: 'Salistea de Sus ' },
            { name: 'Sălsig ', simpleName: 'Salsig ' },
            { name: 'Săpânţa ', simpleName: 'Sapanta ' },
            { name: 'Târgu Lăpuş ', simpleName: 'Targu Lapus ' },
            { name: 'Tăuţii-Măgherăuş ', simpleName: 'Tautii-Magheraus ' },
            { name: 'Ulmeni ' },
            { name: 'Vadu Izei ' },
            { name: 'Valea Chioarului ' },
            { name: 'Vima Mică ', simpleName: 'Vima Mica ' },
            { name: 'Vişeu de Jos ', simpleName: 'Viseu de Jos ' },
            { name: 'Vişeu de Sus ', simpleName: 'Viseu de Sus ' },
            { name: 'Şieu ', simpleName: 'Sieu ' },
            { name: 'Şiseşti ', simpleName: 'Sisesti ' },
            { name: 'Şomcuţa Mare ', simpleName: 'Somcuta Mare ' },
            { name: 'Arieşu de Câmp ', simpleName: 'Ariesu de Camp ', village: 'Ardusat ' },
            { name: 'Colţirea ', simpleName: 'Coltirea ', village: 'Ardusat ' },
            { name: 'Rodina ', village: 'Arinis ' },
            { name: 'Tămăşeşti ', simpleName: 'Tamasesti ', village: 'Arinis ' },
            { name: 'Asuaju de Jos ', village: 'Asuaju De Sus ' },
            { name: 'Blidari ', village: 'Baia Mare ' },
            { name: 'Firiza ', village: 'Baia Mare ' },
            { name: 'Valea Neagră ', simpleName: 'Valea Neagra ', village: 'Baia Mare ' },
            { name: 'Chiuzbaia ', village: 'Baia Sprie ' },
            { name: 'Satu Nou de Sus ', village: 'Baia Sprie ' },
            { name: 'Tăuţii de Sus ', simpleName: 'Tautii de Sus ', village: 'Baia Sprie ' },
            { name: 'Urmeniş ', simpleName: 'Urmenis ', village: 'Baita De Sub Codru ' },
            { name: 'Poiana Botizii ', village: 'Baiut ' },
            { name: 'Strâmbu-Băiuţ ', simpleName: 'Strambu-Baiut ', village: 'Baiut ' },
            { name: 'Năneşti ', simpleName: 'Nanesti ', village: 'Barsana ' },
            { name: 'Onceşti ', simpleName: 'Oncesti ', village: 'Barsana ' },
            { name: 'Odeşti ', simpleName: 'Odesti ', village: 'Basesti ' },
            { name: 'Stremţ ', simpleName: 'Stremt ', village: 'Basesti ' },
            { name: 'Sălişte ', simpleName: 'Saliste ', village: 'Basesti ' },
            { name: 'Ciuta ', village: 'Bicaz ' },
            { name: 'Corni ', village: 'Bicaz ' },
            { name: 'Crasna Vişeului ', simpleName: 'Crasna Viseului ', village: 'Bistra ' },
            { name: 'Valea Vişeului ', simpleName: 'Valea Viseului ', village: 'Bistra ' },
            { name: 'Crăciuneşti ', simpleName: 'Craciunesti ', village: 'Bocicoiu Mare ' },
            { name: 'Lunca la Tisa ', village: 'Bocicoiu Mare ' },
            { name: 'Tisa ', village: 'Bocicoiu Mare ' },
            { name: 'Bocicoel ', village: 'Bogdan Voda ' },
            { name: 'Frâncenii Boiului ', simpleName: 'Francenii Boiului ', village: 'Boiu Mare ' },
            { name: 'Prislop ', village: 'Boiu Mare ' },
            { name: 'Romăneşti ', simpleName: 'Romanesti ', village: 'Boiu Mare ' },
            { name: 'Băile Borşa ', simpleName: 'Baile Borsa ', village: 'Borsa ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Budesti ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Calinesti ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Calinesti ' },
            { name: 'Brebeni ', village: 'Cernesti ' },
            { name: 'Ciocotiş ', simpleName: 'Ciocotis ', village: 'Cernesti ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Cernesti ' },
            { name: 'Izvoarele ', village: 'Cernesti ' },
            { name: 'Măgureni ', simpleName: 'Magureni ', village: 'Cernesti ' },
            { name: 'Trestia ', village: 'Cernesti ' },
            { name: 'Bârgău ', simpleName: 'Bargau ', village: 'Cicarlau ' },
            { name: 'Handalu Ilbei ', village: 'Cicarlau ' },
            { name: 'Ilba ', village: 'Cicarlau ' },
            { name: 'Berinţa ', simpleName: 'Berinta ', village: 'Copalnic-Manastur ' },
            { name: 'Copalnic ', village: 'Copalnic-Manastur ' },
            { name: 'Copalnic-Deal ', village: 'Copalnic-Manastur ' },
            { name: 'Curtuiuşu Mic ', simpleName: 'Curtuiusu Mic ', village: 'Copalnic-Manastur ' },
            { name: 'Cărpiniş ', simpleName: 'Carpinis ', village: 'Copalnic-Manastur ' },
            { name: 'Făureşti ', simpleName: 'Fauresti ', village: 'Copalnic-Manastur ' },
            { name: 'Lăschia ', simpleName: 'Laschia ', village: 'Copalnic-Manastur ' },
            { name: 'Preluca Nouă ', simpleName: 'Preluca Noua ', village: 'Copalnic-Manastur ' },
            { name: 'Preluca Veche ', village: 'Copalnic-Manastur ' },
            { name: 'Ruşor ', simpleName: 'Rusor ', village: 'Copalnic-Manastur ' },
            { name: 'Vad ', village: 'Copalnic-Manastur ' },
            { name: 'Baba ', village: 'Coroieni ' },
            { name: 'Dealu Mare ', village: 'Coroieni ' },
            { name: 'Drăghia ', simpleName: 'Draghia ', village: 'Coroieni ' },
            { name: 'Vălenii Lăpuşului ', simpleName: 'Valenii Lapusului ', village: 'Coroieni ' },
            { name: 'Costeni ', village: 'Cupseni ' },
            { name: 'Libotin ', village: 'Cupseni ' },
            { name: 'Ungureni ', village: 'Cupseni ' },
            { name: 'Hărniceşti ', simpleName: 'Harnicesti ', village: 'Desesti ' },
            { name: 'Mara ', village: 'Desesti ' },
            { name: 'Chechiş ', simpleName: 'Chechis ', village: 'Dumbravita ' },
            { name: 'Cărbunari ', simpleName: 'Carbunari ', village: 'Dumbravita ' },
            { name: 'Rus ', village: 'Dumbravita ' },
            { name: 'Unguraş ', simpleName: 'Unguras ', village: 'Dumbravita ' },
            { name: 'Şindreşti ', simpleName: 'Sindresti ', village: 'Dumbravita ' },
            { name: 'Buzeşti ', simpleName: 'Buzesti ', village: 'Farcasa ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Farcasa ' },
            { name: 'Tămaia ', simpleName: 'Tamaia ', village: 'Farcasa ' },
            { name: 'Berbeşti ', simpleName: 'Berbesti ', village: 'Giulesti ' },
            { name: 'Fereşti ', simpleName: 'Feresti ', village: 'Giulesti ' },
            { name: 'Mănăstirea ', simpleName: 'Manastirea ', village: 'Giulesti ' },
            { name: 'Ocoliş ', simpleName: 'Ocolis ', village: 'Grosi ' },
            { name: 'Satu Nou de Jos ', village: 'Grosi ' },
            { name: 'Dăneştii Chioarului ', simpleName: 'Danestii Chioarului ', village: 'Miresu Mare ' },
            { name: 'Iadăra ', simpleName: 'Iadara ', village: 'Miresu Mare ' },
            { name: 'Lucăceşti ', simpleName: 'Lucacesti ', village: 'Miresu Mare ' },
            { name: 'Remeţi pe Someş ', simpleName: 'Remeti pe Somes ', village: 'Miresu Mare ' },
            { name: 'Stejera ', village: 'Miresu Mare ' },
            { name: 'Tulghieş ', simpleName: 'Tulghies ', village: 'Miresu Mare ' },
            { name: 'Oarţa de Sus ', simpleName: 'Oarta de Sus ', village: 'Oarta De Jos ' },
            { name: 'Orţiţa ', simpleName: 'Ortita ', village: 'Oarta De Jos ' },
            { name: 'Breb ', village: 'Ocna Sugatag ' },
            { name: 'Hoteni ', village: 'Ocna Sugatag ' },
            { name: 'Sat-Şugatag ', simpleName: 'Sat-Sugatag ', village: 'Ocna Sugatag ' },
            { name: 'Bozânta Mică ', simpleName: 'Bozanta Mica ', village: 'Recea ' },
            { name: 'Lăpuşel ', simpleName: 'Lapusel ', village: 'Recea ' },
            { name: 'Mocira ', village: 'Recea ' },
            { name: 'Săsar ', simpleName: 'Sasar ', village: 'Recea ' },
            { name: 'Berchez ', village: 'Remetea Chioarului ' },
            { name: 'Berchezoaia ', village: 'Remetea Chioarului ' },
            { name: 'Posta ', village: 'Remetea Chioarului ' },
            { name: 'Remecioara ', village: 'Remetea Chioarului ' },
            { name: 'Piatra ', village: 'Remeti ' },
            { name: 'Teceu Mic ', village: 'Remeti ' },
            { name: 'Coştiui ', simpleName: 'Costiui ', village: 'Rona De Sus ' },
            { name: 'Salta ', village: 'Rozavlea ' },
            { name: 'Coaş ', simpleName: 'Coas ', village: 'Sacalaseni ' },
            { name: 'Coltău ', simpleName: 'Coltau ', village: 'Sacalaseni ' },
            { name: 'Coruia ', village: 'Sacalaseni ' },
            { name: 'Culcea ', village: 'Sacalaseni ' },
            { name: 'Cătălina ', simpleName: 'Catalina ', village: 'Sacalaseni ' },
            { name: 'Întrerâuri ', simpleName: 'Intrerauri ', village: 'Sacalaseni ' },
            { name: 'Gârdani ', simpleName: 'Gardani ', village: 'Salsig ' },
            { name: 'Arieşu de Pădure ', simpleName: 'Ariesu de Padure ', village: 'Satulung ' },
            { name: 'Fersig ', village: 'Satulung ' },
            { name: 'Finteuşu Mic ', simpleName: 'Finteusu Mic ', village: 'Satulung ' },
            { name: 'Hideaga ', village: 'Satulung ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ', village: 'Satulung ' },
            { name: 'Pribileşti ', simpleName: 'Pribilesti ', village: 'Satulung ' },
            { name: 'Săbişa ', simpleName: 'Sabisa ', village: 'Seini ' },
            { name: 'Viile Apei ', village: 'Seini ' },
            { name: 'Iapa ', village: 'Sighetu Marmatiei ' },
            { name: 'Lazu Baciului ', village: 'Sighetu Marmatiei ' },
            { name: 'Valea Cufundoasă ', simpleName: 'Valea Cufundoasa ', village: 'Sighetu Marmatiei ' },
            { name: 'Valea Hotarului ', village: 'Sighetu Marmatiei ' },
            { name: 'Şugău ', simpleName: 'Sugau ', village: 'Sighetu Marmatiei ' },
            { name: 'Bontăieni ', simpleName: 'Bontaieni ', village: 'Sisesti ' },
            { name: 'Cetăţele ', simpleName: 'Cetatele ', village: 'Sisesti ' },
            { name: 'Dăneşti ', simpleName: 'Danesti ', village: 'Sisesti ' },
            { name: 'Negreia ', village: 'Sisesti ' },
            { name: 'Plopiş ', simpleName: 'Plopis ', village: 'Sisesti ' },
            { name: 'Şurdeşti ', simpleName: 'Surdesti ', village: 'Sisesti ' },
            { name: 'Buciumi ', village: 'Somcuta Mare ' },
            { name: 'Buteasa ', village: 'Somcuta Mare ' },
            { name: 'Ciolt ', village: 'Somcuta Mare ' },
            { name: 'Codru Butesii ', village: 'Somcuta Mare ' },
            { name: 'Finteuşu Mare ', simpleName: 'Finteusu Mare ', village: 'Somcuta Mare ' },
            { name: 'Hovrila ', village: 'Somcuta Mare ' },
            { name: 'Vălenii Şomcutei ', simpleName: 'Valenii Somcutei ', village: 'Somcuta Mare ' },
            { name: 'Glod ', village: 'Stramtura ' },
            { name: 'Slătioara ', simpleName: 'Slatioara ', village: 'Stramtura ' },
            { name: 'Larga ', village: 'Suciu De Sus ' },
            { name: 'Suciu de Jos ', village: 'Suciu De Sus ' },
            { name: 'Boiereni ', village: 'Targu Lapus ' },
            { name: 'Borcut ', village: 'Targu Lapus ' },
            { name: 'Cufoaia ', village: 'Targu Lapus ' },
            { name: 'Dobricu Lăpuşului ', simpleName: 'Dobricu Lapusului ', village: 'Targu Lapus ' },
            { name: 'Dumbrava ', village: 'Targu Lapus ' },
            { name: 'Dămăcuşeni ', simpleName: 'Damacuseni ', village: 'Targu Lapus ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Targu Lapus ' },
            { name: 'Groape ', village: 'Targu Lapus ' },
            { name: 'Inău ', simpleName: 'Inau ', village: 'Targu Lapus ' },
            { name: 'Rogoz ', village: 'Targu Lapus ' },
            { name: 'Rohia ', village: 'Targu Lapus ' },
            { name: 'Răzoare ', simpleName: 'Razoare ', village: 'Targu Lapus ' },
            { name: 'Stoiceni ', village: 'Targu Lapus ' },
            { name: 'Bozânta Mare ', simpleName: 'Bozanta Mare ', village: 'Tautii-Magheraus ' },
            { name: 'Buşag ', simpleName: 'Busag ', village: 'Tautii-Magheraus ' },
            { name: 'Băiţa ', simpleName: 'Baita ', village: 'Tautii-Magheraus ' },
            { name: 'Merişor ', simpleName: 'Merisor ', village: 'Tautii-Magheraus ' },
            { name: 'Nistru ', village: 'Tautii-Magheraus ' },
            { name: 'Ulmoasa ', village: 'Tautii-Magheraus ' },
            { name: 'Arduzel ', village: 'Ulmeni ' },
            { name: 'Chelinţa ', simpleName: 'Chelinta ', village: 'Ulmeni ' },
            { name: 'Mânău ', simpleName: 'Manau ', village: 'Ulmeni ' },
            { name: 'Someş-Uileac ', simpleName: 'Somes-Uileac ', village: 'Ulmeni ' },
            { name: 'Tohat ', village: 'Ulmeni ' },
            { name: 'Vicea ', village: 'Ulmeni ' },
            { name: 'Ţicău ', simpleName: 'Ticau ', village: 'Ulmeni ' },
            { name: 'Valea Stejarului ', village: 'Vadu Izei ' },
            { name: 'Curtuiuşu Mare ', simpleName: 'Curtuiusu Mare ', village: 'Valea Chioarului ' },
            { name: 'Duruşa ', simpleName: 'Durusa ', village: 'Valea Chioarului ' },
            { name: 'Fericea ', village: 'Valea Chioarului ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Valea Chioarului ' },
            { name: 'Vărai ', simpleName: 'Varai ', village: 'Valea Chioarului ' },
            { name: 'Aspra ', village: 'Vima Mica ' },
            { name: 'Dealu Corbului ', village: 'Vima Mica ' },
            { name: 'Jugăstreni ', simpleName: 'Jugastreni ', village: 'Vima Mica ' },
            { name: 'Peteritea ', village: 'Vima Mica ' },
            { name: 'Sălniţa ', simpleName: 'Salnita ', village: 'Vima Mica ' },
            { name: 'Vima Mare ', village: 'Vima Mica ' },
            {
                name: 'Vişeu de Mijloc ', simpleName: 'Viseu de Mijloc ', village: 'Viseu De Sus '
            }
        ]
    },
    {
        auto: 'MH ',
        name: 'Mehedinţi ',
        cities: [
            { name: 'Baia de Aramă ', simpleName: 'Baia de Arama ' },
            { name: 'Bala ' },
            { name: 'Balta ' },
            { name: 'Brezniţa-Motru ', simpleName: 'Breznita-Motru ' },
            { name: 'Brezniţa-Ocol ', simpleName: 'Breznita-Ocol ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ' },
            { name: 'Burila Mare ' },
            { name: 'Butoieşti ', simpleName: 'Butoiesti ' },
            { name: 'Bâcleş ', simpleName: 'Bacles ' },
            { name: 'Bâlvăneşti ', simpleName: 'Balvanesti ' },
            { name: 'Bălăciţa ', simpleName: 'Balacita ' },
            { name: 'Cireşu ', simpleName: 'Ciresu ' },
            { name: 'Corcova ' },
            { name: 'Corlăţel ', simpleName: 'Corlatel ' },
            { name: 'Cujmir ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ' },
            { name: 'Devesel ' },
            { name: 'Drobeta-Turnu Severin ' },
            { name: 'Dubova ' },
            { name: 'Dârvari ', simpleName: 'Darvari ' },
            { name: 'Eşelniţa ', simpleName: 'Eselnita ' },
            { name: 'Floreşti ', simpleName: 'Floresti ' },
            { name: 'Godeanu ' },
            { name: 'Gogoşu ', simpleName: 'Gogosu ' },
            { name: 'Greci ' },
            { name: 'Grozeşti ', simpleName: 'Grozesti ' },
            { name: 'Gruia ' },
            { name: 'Gârla Mare ', simpleName: 'Garla Mare ' },
            { name: 'Hinova ' },
            { name: 'Husnicioara ' },
            { name: 'Iloviţa ', simpleName: 'Ilovita ' },
            { name: 'Ilovăţ ', simpleName: 'Ilovat ' },
            { name: 'Isverna ' },
            { name: 'Izvoru Bârzii ', simpleName: 'Izvoru Barzii ' },
            { name: 'Jiana ' },
            { name: 'Livezile ' },
            { name: 'Malovăţ ', simpleName: 'Malovat ' },
            { name: 'Obârşia de Câmp ', simpleName: 'Obarsia de Camp ' },
            { name: 'Obârşia-Cloşani ', simpleName: 'Obarsia-Closani ' },
            { name: 'Oprişor ', simpleName: 'Oprisor ' },
            { name: 'Orşova ', simpleName: 'Orsova ' },
            { name: 'Podeni ' },
            { name: 'Ponoarele ' },
            { name: 'Poroina Mare ' },
            { name: 'Pristol ' },
            { name: 'Prunişor ', simpleName: 'Prunisor ' },
            { name: 'Punghina ' },
            { name: 'Pătulele ', simpleName: 'Patulele ' },
            { name: 'Rogova ' },
            { name: 'Salcia ' },
            { name: 'Strehaia ' },
            { name: 'Stângăceaua ', simpleName: 'Stangaceaua ' },
            { name: 'Sviniţa ', simpleName: 'Svinita ' },
            { name: 'Tâmna ', simpleName: 'Tamna ' },
            { name: 'Vlădaia ', simpleName: 'Vladaia ' },
            { name: 'Voloiac ' },
            { name: 'Vânju Mare ', simpleName: 'Vanju Mare ' },
            { name: 'Vânjuleţ ', simpleName: 'Vanjulet ' },
            { name: 'Vânători ', simpleName: 'Vanatori ' },
            { name: 'Şimian ', simpleName: 'Simian ' },
            { name: 'Şişeşti ', simpleName: 'Sisesti ' },
            { name: 'Şovarna ', simpleName: 'Sovarna ' },
            { name: 'Corzu ', village: 'Bacles ' },
            { name: 'Giura ', village: 'Bacles ' },
            { name: 'Petra ', village: 'Bacles ' },
            { name: 'Podu Grosului ', village: 'Bacles ' },
            { name: 'Seliştiuţa ', simpleName: 'Selistiuta ', village: 'Bacles ' },
            { name: 'Smadoviţa ', simpleName: 'Smadovita ', village: 'Bacles ' },
            { name: 'Bratilovu ', village: 'Baia De Arama ' },
            { name: 'Brebina ', village: 'Baia De Arama ' },
            { name: 'Dealu Mare ', village: 'Baia De Arama ' },
            { name: 'Mărăşeşti ', simpleName: 'Marasesti ', village: 'Baia De Arama ' },
            { name: 'Negoeşti ', simpleName: 'Negoesti ', village: 'Baia De Arama ' },
            { name: 'Pistriţa ', simpleName: 'Pistrita ', village: 'Baia De Arama ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ', village: 'Baia De Arama ' },
            { name: 'Titerleşti ', simpleName: 'Titerlesti ', village: 'Baia De Arama ' },
            { name: 'Bala de Sus ', village: 'Bala ' },
            { name: 'Brateşul ', simpleName: 'Bratesul ', village: 'Bala ' },
            { name: 'Brativoeşti ', simpleName: 'Brativoesti ', village: 'Bala ' },
            { name: 'Comăneşti ', simpleName: 'Comanesti ', village: 'Bala ' },
            { name: 'Crainici ', village: 'Bala ' },
            { name: 'Câmpu Mare ', simpleName: 'Campu Mare ', village: 'Bala ' },
            { name: 'Cârşu ', simpleName: 'Carsu ', village: 'Bala ' },
            { name: 'Dâlma ', simpleName: 'Dalma ', village: 'Bala ' },
            { name: 'Iupca ', village: 'Bala ' },
            { name: 'Molani ', village: 'Bala ' },
            { name: 'Rudina ', village: 'Bala ' },
            { name: 'Runcuşoru ', simpleName: 'Runcusoru ', village: 'Bala ' },
            { name: 'Sărdăneşti ', simpleName: 'Sardanesti ', village: 'Bala ' },
            { name: 'Vidimireşti ', simpleName: 'Vidimiresti ', village: 'Bala ' },
            { name: 'Dobra ', village: 'Balacita ' },
            { name: 'Gvardiniţa ', simpleName: 'Gvardinita ', village: 'Balacita ' },
            { name: 'Coada Cornetului ', village: 'Balta ' },
            { name: 'Costeşti ', simpleName: 'Costesti ', village: 'Balta ' },
            { name: 'Gornoviţa ', simpleName: 'Gornovita ', village: 'Balta ' },
            { name: 'Nevăţu ', simpleName: 'Nevatu ', village: 'Balta ' },
            { name: 'Prejna ', village: 'Balta ' },
            { name: 'Sfodea ', village: 'Balta ' },
            { name: 'Bâlvăneştii de Jos ', simpleName: 'Balvanestii de Jos ', village: 'Balvanesti ' },
            { name: 'Călineştii de Jos ', simpleName: 'Calinestii de Jos ', village: 'Balvanesti ' },
            { name: 'Călineştii de Sus ', simpleName: 'Calinestii de Sus ', village: 'Balvanesti ' },
            { name: 'Pârlagele ', simpleName: 'Parlagele ', village: 'Balvanesti ' },
            { name: 'Cosovăţ ', simpleName: 'Cosovat ', village: 'Breznita-Motru ' },
            { name: 'Deleni ', village: 'Breznita-Motru ' },
            { name: 'Făuroaia ', simpleName: 'Fauroaia ', village: 'Breznita-Motru ' },
            { name: 'Plai ', village: 'Breznita-Motru ' },
            { name: 'Tălăpanu ', simpleName: 'Talapanu ', village: 'Breznita-Motru ' },
            { name: 'Valea Teiului ', village: 'Breznita-Motru ' },
            { name: 'Jidoştiţa ', simpleName: 'Jidostita ', village: 'Breznita-Ocol ' },
            { name: 'Magheru ', village: 'Breznita-Ocol ' },
            { name: 'Şuşiţa ', simpleName: 'Susita ', village: 'Breznita-Ocol ' },
            { name: 'Căpăţâneşti ', simpleName: 'Capatanesti ', village: 'Brosteni ' },
            { name: 'Luncşoara ', simpleName: 'Luncsoara ', village: 'Brosteni ' },
            { name: 'Lupşa de Jos ', simpleName: 'Lupsa de Jos ', village: 'Brosteni ' },
            { name: 'Lupşa de Sus ', simpleName: 'Lupsa de Sus ', village: 'Brosteni ' },
            { name: 'Meriş ', simpleName: 'Meris ', village: 'Brosteni ' },
            { name: 'Crivina ', village: 'Burila Mare ' },
            { name: 'Izvoru Frumos ', village: 'Burila Mare ' },
            { name: 'Vrancea ', village: 'Burila Mare ' },
            { name: 'Ţigănaşi ', simpleName: 'Tiganasi ', village: 'Burila Mare ' },
            { name: 'Argineşti ', simpleName: 'Arginesti ', village: 'Butoiesti ' },
            { name: 'Buiceşti ', simpleName: 'Buicesti ', village: 'Butoiesti ' },
            { name: 'Gura Motrului ', village: 'Butoiesti ' },
            { name: 'Jugastru ', village: 'Butoiesti ' },
            { name: 'Pluta ', village: 'Butoiesti ' },
            { name: 'Răduţeşti ', simpleName: 'Radutesti ', village: 'Butoiesti ' },
            { name: 'Tânţaru ', simpleName: 'Tantaru ', village: 'Butoiesti ' },
            { name: 'Ercea ', village: 'Cazanesti ' },
            { name: 'Govodarva ', village: 'Cazanesti ' },
            { name: 'Gârbovăţu de Sus ', simpleName: 'Garbovatu de Sus ', village: 'Cazanesti ' },
            { name: 'Ilovu ', village: 'Cazanesti ' },
            { name: 'Jigniţa ', simpleName: 'Jignita ', village: 'Cazanesti ' },
            { name: 'Poiana ', village: 'Cazanesti ' },
            { name: 'Păltinişu ', simpleName: 'Paltinisu ', village: 'Cazanesti ' },
            { name: 'Roşia ', simpleName: 'Rosia ', village: 'Cazanesti ' },
            { name: 'Severineşti ', simpleName: 'Severinesti ', village: 'Cazanesti ' },
            { name: 'Suharu ', village: 'Cazanesti ' },
            { name: 'Valea Coşuştei ', simpleName: 'Valea Cosustei ', village: 'Cazanesti ' },
            { name: 'Bunoaica ', village: 'Ciresu ' },
            { name: 'Jupâneşti ', simpleName: 'Jupanesti ', village: 'Ciresu ' },
            { name: 'Negruşa ', simpleName: 'Negrusa ', village: 'Ciresu ' },
            { name: 'Breţa ', simpleName: 'Breta ', village: 'Corcova ' },
            { name: 'Cernaia ', village: 'Corcova ' },
            { name: 'Cordun ', village: 'Corcova ' },
            { name: 'Croica ', village: 'Corcova ' },
            { name: 'Gârbovăţu de Jos ', simpleName: 'Garbovatu de Jos ', village: 'Corcova ' },
            { name: 'Imoasa ', village: 'Corcova ' },
            { name: 'Jirov ', village: 'Corcova ' },
            { name: 'Măru Roşu ', simpleName: 'Maru Rosu ', village: 'Corcova ' },
            { name: 'Puşcaşu ', simpleName: 'Puscasu ', village: 'Corcova ' },
            { name: 'Pârvuleşti ', simpleName: 'Parvulesti ', village: 'Corcova ' },
            { name: 'Stejaru ', village: 'Corcova ' },
            { name: 'Vlădăşeşti ', simpleName: 'Vladasesti ', village: 'Corcova ' },
            { name: 'Valea Anilor ', village: 'Corlatel ' },
            { name: 'Aurora ', village: 'Cujmir ' },
            { name: 'Cujmiru Mic ', village: 'Cujmir ' },
            { name: 'Gemeni ', village: 'Darvari ' },
            { name: 'Batoţi ', simpleName: 'Batoti ', village: 'Devesel ' },
            { name: 'Bistreţu ', simpleName: 'Bistretu ', village: 'Devesel ' },
            { name: 'Dunărea Mică ', simpleName: 'Dunarea Mica ', village: 'Devesel ' },
            { name: 'Scăpău ', simpleName: 'Scapau ', village: 'Devesel ' },
            { name: 'Tismana ', village: 'Devesel ' },
            { name: 'Dudaşu Schelei ', simpleName: 'Dudasu Schelei ', village: 'Drobeta-Turnu Severin ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Drobeta-Turnu Severin ' },
            { name: 'Schela Cladovei ', village: 'Drobeta-Turnu Severin ' },
            { name: 'Baia Nouă ', simpleName: 'Baia Noua ', village: 'Dubova ' },
            { name: 'Eibenthal ', village: 'Dubova ' },
            { name: 'Albuleşti ', simpleName: 'Albulesti ', village: 'Dumbrava ' },
            { name: 'Brâgleasa ', simpleName: 'Bragleasa ', village: 'Dumbrava ' },
            { name: 'Dumbrava de Jos ', village: 'Dumbrava ' },
            { name: 'Dumbrava de Mijloc ', village: 'Dumbrava ' },
            { name: 'Dumbrava de Sus ', village: 'Dumbrava ' },
            { name: 'Golineasa ', village: 'Dumbrava ' },
            { name: 'Higiu ', village: 'Dumbrava ' },
            { name: 'Rocşoreni ', simpleName: 'Rocsoreni ', village: 'Dumbrava ' },
            { name: 'Valea Marcului ', village: 'Dumbrava ' },
            { name: 'Varodia ', village: 'Dumbrava ' },
            { name: 'Vlădica ', simpleName: 'Vladica ', village: 'Dumbrava ' },
            { name: 'Copăcioasa ', simpleName: 'Copacioasa ', village: 'Floresti ' },
            { name: 'Gârdoaia ', simpleName: 'Gardoaia ', village: 'Floresti ' },
            { name: 'Livezi ', village: 'Floresti ' },
            { name: 'Moşneni ', simpleName: 'Mosneni ', village: 'Floresti ' },
            { name: 'Peşteana ', simpleName: 'Pesteana ', village: 'Floresti ' },
            { name: 'Peştenuţa ', simpleName: 'Pestenuta ', village: 'Floresti ' },
            { name: 'Stroeşti ', simpleName: 'Stroesti ', village: 'Floresti ' },
            { name: 'Zegujani ', village: 'Floresti ' },
            { name: 'Vrata ', village: 'Garla Mare ' },
            { name: 'Marga ', village: 'Godeanu ' },
            { name: 'Păuneşti ', simpleName: 'Paunesti ', village: 'Godeanu ' },
            { name: 'Şiroca ', simpleName: 'Siroca ', village: 'Godeanu ' },
            { name: 'Balta Verde ', village: 'Gogosu ' },
            { name: 'Burila Mică ', simpleName: 'Burila Mica ', village: 'Gogosu ' },
            { name: 'Ostrovu Mare ', village: 'Gogosu ' },
            { name: 'Blidaru ', village: 'Greci ' },
            { name: 'Bâltanele ', simpleName: 'Baltanele ', village: 'Greci ' },
            { name: 'Sălătruc ', simpleName: 'Salatruc ', village: 'Greci ' },
            { name: 'Valea Petrii ', village: 'Greci ' },
            { name: 'Vişina ', simpleName: 'Visina ', village: 'Greci ' },
            { name: 'Cârceni ', simpleName: 'Carceni ', village: 'Grozesti ' },
            { name: 'Păsărani ', simpleName: 'Pasarani ', village: 'Grozesti ' },
            { name: 'Şuşiţa ', simpleName: 'Susita ', village: 'Grozesti ' },
            { name: 'Izvoarele ', village: 'Gruia ' },
            { name: 'Poiana Gruii ', village: 'Gruia ' },
            { name: 'Bistriţa ', simpleName: 'Bistrita ', village: 'Hinova ' },
            { name: 'Cârjei ', simpleName: 'Carjei ', village: 'Hinova ' },
            { name: 'Ostrovu Corbului ', village: 'Hinova ' },
            { name: 'Alunişul ', simpleName: 'Alunisul ', village: 'Husnicioara ' },
            { name: 'Borogea ', village: 'Husnicioara ' },
            { name: 'Bădiţeşti ', simpleName: 'Baditesti ', village: 'Husnicioara ' },
            { name: 'Celnata ', village: 'Husnicioara ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Husnicioara ' },
            { name: 'Marmanu ', village: 'Husnicioara ' },
            { name: 'Oprăneşti ', simpleName: 'Opranesti ', village: 'Husnicioara ' },
            { name: 'Peri ', village: 'Husnicioara ' },
            { name: 'Priboieşti ', simpleName: 'Priboiesti ', village: 'Husnicioara ' },
            { name: 'Selişteni ', simpleName: 'Selisteni ', village: 'Husnicioara ' },
            { name: 'Budăneşti ', simpleName: 'Budanesti ', village: 'Ilovat ' },
            { name: 'Cracu Lung ', village: 'Ilovat ' },
            { name: 'Dâlbociţa ', simpleName: 'Dalbocita ', village: 'Ilovat ' },
            { name: 'Firizu ', village: 'Ilovat ' },
            { name: 'Racova ', village: 'Ilovat ' },
            { name: 'Bahna ', village: 'Ilovita ' },
            { name: 'Moiseşti ', simpleName: 'Moisesti ', village: 'Ilovita ' },
            { name: 'Buseşti ', simpleName: 'Busesti ', village: 'Isverna ' },
            { name: 'Cerna-Vârf ', simpleName: 'Cerna-Varf ', village: 'Isverna ' },
            { name: 'Drăgheşti ', simpleName: 'Draghesti ', village: 'Isverna ' },
            { name: 'Giurgiani ', village: 'Isverna ' },
            { name: 'Nadanova ', village: 'Isverna ' },
            { name: 'Seliştea ', simpleName: 'Selistea ', village: 'Isverna ' },
            { name: 'Turtaba ', village: 'Isverna ' },
            { name: 'Baloteşti ', simpleName: 'Balotesti ', village: 'Izvoru Barzii ' },
            { name: 'Halânga ', simpleName: 'Halanga ', village: 'Izvoru Barzii ' },
            { name: 'Puţinei ', simpleName: 'Putinei ', village: 'Izvoru Barzii ' },
            { name: 'Răscoleşti ', simpleName: 'Rascolesti ', village: 'Izvoru Barzii ' },
            { name: 'Schinteieşti ', simpleName: 'Schinteiesti ', village: 'Izvoru Barzii ' },
            { name: 'Schitu Topolniţei ', simpleName: 'Schitu Topolnitei ', village: 'Izvoru Barzii ' },
            { name: 'Cioroboreni ', village: 'Jiana ' },
            { name: 'Dănceu ', simpleName: 'Danceu ', village: 'Jiana ' },
            { name: 'Jiana Mare ', village: 'Jiana ' },
            { name: 'Jiana Veche ', village: 'Jiana ' },
            { name: 'Izvoru Aneştilor ', simpleName: 'Izvoru Anestilor ', village: 'Livezile ' },
            { name: 'Izvorălu de Jos ', simpleName: 'Izvoralu de Jos ', village: 'Livezile ' },
            { name: 'Petriş ', simpleName: 'Petris ', village: 'Livezile ' },
            { name: 'Ştefan Odobleja ', simpleName: 'Stefan Odobleja ', village: 'Livezile ' },
            { name: '23 August ', village: 'Malovat ' },
            { name: 'Bobaiţa ', simpleName: 'Bobaita ', village: 'Malovat ' },
            { name: 'Bârda ', simpleName: 'Barda ', village: 'Malovat ' },
            { name: 'Colibaşi ', simpleName: 'Colibasi ', village: 'Malovat ' },
            { name: 'Lazu ', village: 'Malovat ' },
            { name: 'Negreşti ', simpleName: 'Negresti ', village: 'Malovat ' },
            { name: 'Izimşa ', simpleName: 'Izimsa ', village: 'Obarsia De Camp ' },
            { name: 'Godeanu ', village: 'Obarsia-Closani ' },
            { name: 'Prisăceaua ', simpleName: 'Prisaceaua ', village: 'Oprisor ' },
            { name: 'Biban ', village: 'Padina ' },
            { name: 'Iablaniţa ', simpleName: 'Iablanita ', village: 'Padina ' },
            { name: 'Olteanca ', village: 'Padina ' },
            { name: 'Pădina Mare ', simpleName: 'Padina Mare ', village: 'Padina ' },
            { name: 'Pădina Mică ', simpleName: 'Padina Mica ', village: 'Padina ' },
            { name: 'Slaşoma ', simpleName: 'Slasoma ', village: 'Padina ' },
            { name: 'Viaşu ', simpleName: 'Viasu ', village: 'Patulele ' },
            { name: 'Gornenţi ', simpleName: 'Gornenti ', village: 'Podeni ' },
            { name: 'Mălărişca ', simpleName: 'Malarisca ', village: 'Podeni ' },
            { name: 'Brânzeni ', simpleName: 'Branzeni ', village: 'Ponoarele ' },
            { name: 'Buicani ', village: 'Ponoarele ' },
            { name: 'Bârâiacu ', simpleName: 'Baraiacu ', village: 'Ponoarele ' },
            { name: 'Băluţa ', simpleName: 'Baluta ', village: 'Ponoarele ' },
            { name: 'Ceptureni ', village: 'Ponoarele ' },
            { name: 'Cracu Muntelui ', village: 'Ponoarele ' },
            { name: 'Delureni ', village: 'Ponoarele ' },
            { name: 'Gheorgheşti ', simpleName: 'Gheorghesti ', village: 'Ponoarele ' },
            { name: 'Gărdăneasa ', simpleName: 'Gardaneasa ', village: 'Ponoarele ' },
            { name: 'Ludu ', village: 'Ponoarele ' },
            { name: 'Proiteşti ', simpleName: 'Proitesti ', village: 'Ponoarele ' },
            { name: 'Răiculeşti ', simpleName: 'Raiculesti ', village: 'Ponoarele ' },
            { name: 'Valea Ursului ', village: 'Ponoarele ' },
            { name: 'Şipotu ', simpleName: 'Sipotu ', village: 'Ponoarele ' },
            { name: 'Fântânile Negre ', simpleName: 'Fantanile Negre ', village: 'Poroina Mare ' },
            { name: 'Stigniţa ', simpleName: 'Stignita ', village: 'Poroina Mare ' },
            { name: 'Şipotu ', simpleName: 'Sipotu ', village: 'Poroina Mare ' },
            { name: 'Cozia ', village: 'Pristol ' },
            { name: 'Arvăteşti ', simpleName: 'Arvatesti ', village: 'Prunisor ' },
            { name: 'Balota ', village: 'Prunisor ' },
            { name: 'Bâltanele ', simpleName: 'Baltanele ', village: 'Prunisor ' },
            { name: 'Cerveniţa ', simpleName: 'Cervenita ', village: 'Prunisor ' },
            { name: 'Dragoteşti ', simpleName: 'Dragotesti ', village: 'Prunisor ' },
            { name: 'Fântâna Domnească ', simpleName: 'Fantana Domneasca ', village: 'Prunisor ' },
            { name: 'Ghelmegioaia ', village: 'Prunisor ' },
            { name: 'Gutu ', village: 'Prunisor ' },
            { name: 'Gârniţa ', simpleName: 'Garnita ', village: 'Prunisor ' },
            { name: 'Igiroasa ', village: 'Prunisor ' },
            { name: 'Lumnic ', village: 'Prunisor ' },
            { name: 'Mijarca ', village: 'Prunisor ' },
            { name: 'Prunaru ', village: 'Prunisor ' },
            { name: 'Zegaia ', village: 'Prunisor ' },
            { name: 'Cearângu ', simpleName: 'Cearangu ', village: 'Punghina ' },
            { name: 'Drincea ', village: 'Punghina ' },
            { name: 'Măgurele ', simpleName: 'Magurele ', village: 'Punghina ' },
            { name: 'Recea ', village: 'Punghina ' },
            { name: 'Satu Nou ', village: 'Punghina ' },
            { name: 'Poroiniţa ', simpleName: 'Poroinita ', village: 'Rogova ' },
            { name: 'Cerneţi ', simpleName: 'Cerneti ', village: 'Simian ' },
            { name: 'Dedoviţa Nouă ', simpleName: 'Dedovita Noua ', village: 'Simian ' },
            { name: 'Dedoviţa Veche ', simpleName: 'Dedovita Veche ', village: 'Simian ' },
            { name: 'Dudaşu ', simpleName: 'Dudasu ', village: 'Simian ' },
            { name: 'Ergheviţa ', simpleName: 'Erghevita ', village: 'Simian ' },
            { name: 'Poroina ', village: 'Simian ' },
            { name: 'Valea Copcii ', village: 'Simian ' },
            { name: 'Ciovârnăşani ', simpleName: 'Ciovarnasani ', village: 'Sisesti ' },
            { name: 'Cocorova ', village: 'Sisesti ' },
            { name: 'Crăgueşti ', simpleName: 'Craguesti ', village: 'Sisesti ' },
            { name: 'Cărămidaru ', simpleName: 'Caramidaru ', village: 'Sisesti ' },
            { name: 'Noapteşa ', simpleName: 'Noaptesa ', village: 'Sisesti ' },
            { name: 'Ohaba ', village: 'Sovarna ' },
            { name: 'Studina ', village: 'Sovarna ' },
            { name: 'Breznicioara ', village: 'Stangaceaua ' },
            { name: 'Bârlogeni ', simpleName: 'Barlogeni ', village: 'Stangaceaua ' },
            { name: 'Cerânganul ', simpleName: 'Ceranganul ', village: 'Stangaceaua ' },
            { name: 'Faţa Motrului ', simpleName: 'Fata Motrului ', village: 'Stangaceaua ' },
            { name: 'Poşta Veche ', simpleName: 'Posta Veche ', village: 'Stangaceaua ' },
            { name: 'Satu Mare ', village: 'Stangaceaua ' },
            { name: 'Târsa ', simpleName: 'Tarsa ', village: 'Stangaceaua ' },
            { name: 'Ciochiuţa ', simpleName: 'Ciochiuta ', village: 'Strehaia ' },
            { name: 'Comanda ', village: 'Strehaia ' },
            { name: 'Hurduceşti ', simpleName: 'Hurducesti ', village: 'Strehaia ' },
            { name: 'Lunca Banului ', village: 'Strehaia ' },
            { name: 'Menţi ', simpleName: 'Menti ', village: 'Strehaia ' },
            { name: 'Motruleni ', village: 'Strehaia ' },
            { name: 'Slătinicu Mare ', simpleName: 'Slatinicu Mare ', village: 'Strehaia ' },
            { name: 'Slătinicu Mic ', simpleName: 'Slatinicu Mic ', village: 'Strehaia ' },
            { name: 'Stănceşti ', simpleName: 'Stancesti ', village: 'Strehaia ' },
            { name: 'Adunaţii Teiului ', simpleName: 'Adunatii Teiului ', village: 'Tamna ' },
            { name: 'Boceni ', village: 'Tamna ' },
            { name: 'Colareţ ', simpleName: 'Colaret ', village: 'Tamna ' },
            { name: 'Cremenea ', village: 'Tamna ' },
            { name: 'Faţa Cremenii ', simpleName: 'Fata Cremenii ', village: 'Tamna ' },
            { name: 'Izvorălu ', simpleName: 'Izvoralu ', village: 'Tamna ' },
            { name: 'Manu ', village: 'Tamna ' },
            { name: 'Pavăţ ', simpleName: 'Pavat ', village: 'Tamna ' },
            { name: 'Plopi ', village: 'Tamna ' },
            { name: 'Valea Ursului ', village: 'Tamna ' },
            { name: 'Braniştea ', simpleName: 'Branistea ', village: 'Vanatori ' },
            { name: 'Goanţa ', simpleName: 'Goanta ', village: 'Vanatori ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Vanatori ' },
            { name: 'Bucura ', village: 'Vanju Mare ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Vanju Mare ' },
            { name: 'Oreviţa Mare ', simpleName: 'Orevita Mare ', village: 'Vanju Mare ' },
            { name: 'Traian ', village: 'Vanju Mare ' },
            { name: 'Hotărani ', simpleName: 'Hotarani ', village: 'Vanjulet ' },
            { name: 'Almăjel ', simpleName: 'Almajel ', village: 'Vladaia ' },
            { name: 'Scorila ', village: 'Vladaia ' },
            { name: 'Ştircoviţa ', simpleName: 'Stircovita ', village: 'Vladaia ' },
            { name: 'Cotoroaia ', village: 'Voloiac ' },
            { name: 'Lac ', village: 'Voloiac ' },
            { name: 'Ruptura ', village: 'Voloiac ' },
            { name: 'Sperleşti ', simpleName: 'Sperlesti ', village: 'Voloiac ' },
            { name: 'Valea Bună ', simpleName: 'Valea Buna ', village: 'Voloiac ' },
            { name: 'Voloicel ', village: 'Voloiac ' },
            {
                name: 'Ţiţirigi ', simpleName: 'Titirigi ', village: 'Voloiac '
            }
        ]
    },
    {
        auto: 'MS ',
        name: 'Mureş ',
        cities: [
            { name: 'Acăţari ', simpleName: 'Acatari ' },
            { name: 'Adămuş ', simpleName: 'Adamus ' },
            { name: 'Albeşti ', simpleName: 'Albesti ' },
            { name: 'Aluniş ', simpleName: 'Alunis ' },
            { name: 'Apold ' },
            { name: 'Aţintiş ', simpleName: 'Atintis ' },
            { name: 'Bahnea ' },
            { name: 'Band ' },
            { name: 'Batoş ', simpleName: 'Batos ' },
            { name: 'Beica de Jos ' },
            { name: 'Bichiş ', simpleName: 'Bichis ' },
            { name: 'Bogata ' },
            { name: 'Breaza ' },
            { name: 'Brâncoveneşti ', simpleName: 'Brancovenesti ' },
            { name: 'Băgaciu ', simpleName: 'Bagaciu ' },
            { name: 'Băla ', simpleName: 'Bala ' },
            { name: 'Bălăuşeri ', simpleName: 'Balauseri ' },
            { name: 'Ceuaşu de Câmpie ', simpleName: 'Ceuasu de Campie ' },
            { name: 'Cheţani ', simpleName: 'Chetani ' },
            { name: 'Chibed ' },
            { name: 'Chiheru de Jos ' },
            { name: 'Coroisânmărtin ', simpleName: 'Coroisanmartin ' },
            { name: 'Cozma ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ' },
            { name: 'Crăciuneşti ', simpleName: 'Craciunesti ' },
            { name: 'Crăieşti ', simpleName: 'Craiesti ' },
            { name: 'Cucerdea ' },
            { name: 'Cuci ' },
            { name: 'Daneş ', simpleName: 'Danes ' },
            { name: 'Deda ' },
            { name: 'Eremitu ' },
            { name: 'Ernei ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ' },
            { name: 'Fărăgău ', simpleName: 'Faragau ' },
            { name: 'Gheorghe Doja ' },
            { name: 'Ghindari ' },
            { name: 'Glodeni ' },
            { name: 'Gorneşti ', simpleName: 'Gornesti ' },
            { name: 'Grebenişu de Câmpie ', simpleName: 'Grebenisu de Campie ' },
            { name: 'Gurghiu ' },
            { name: 'Găleşti ', simpleName: 'Galesti ' },
            { name: 'Găneşti ', simpleName: 'Ganesti ' },
            { name: 'Hodac ' },
            { name: 'Hodoşa ', simpleName: 'Hodosa ' },
            { name: 'Ibăneşti ', simpleName: 'Ibanesti ' },
            { name: 'Iclănzel ', simpleName: 'Iclanzel ' },
            { name: 'Ideciu de Jos ' },
            { name: 'Iernut ' },
            { name: 'Livezeni ' },
            { name: 'Luduş ', simpleName: 'Ludus ' },
            { name: 'Lunca ' },
            { name: 'Lunca Bradului ' },
            { name: 'Mica ' },
            { name: 'Miercurea Nirajului ' },
            { name: 'Miheşu de Câmpie ', simpleName: 'Mihesu de Campie ' },
            { name: 'Măgherani ', simpleName: 'Magherani ' },
            { name: 'Nadeş ', simpleName: 'Nades ' },
            { name: 'Neaua ' },
            { name: 'Ogra ' },
            { name: 'Papiu Ilarian ' },
            { name: 'Petelea ' },
            { name: 'Pogăceaua ', simpleName: 'Pogaceaua ' },
            { name: 'Pănet ', simpleName: 'Panet ' },
            { name: 'Păsăreni ', simpleName: 'Pasareni ' },
            { name: 'Reghin ' },
            { name: 'Ruşii-Munţi ', simpleName: 'Rusii-Munti ' },
            { name: 'Răstoliţa ', simpleName: 'Rastolita ' },
            { name: 'Sarmaşu ', simpleName: 'Sarmasu ' },
            { name: 'Saschiz ' },
            { name: 'Sighişoara ', simpleName: 'Sighisoara ' },
            { name: 'Solovăstru ', simpleName: 'Solovastru ' },
            { name: 'Sovata ' },
            { name: 'Stânceni ', simpleName: 'Stanceni ' },
            { name: 'Suplac ' },
            { name: 'Suseni ' },
            { name: 'Sâncraiu de Mureş ', simpleName: 'Sancraiu de Mures ' },
            { name: 'Sângeorgiu de Mureş ', simpleName: 'Sangeorgiu de Mures ' },
            { name: 'Sângeorgiu de Pădure ', simpleName: 'Sangeorgiu de Padure ' },
            { name: 'Sânger ', simpleName: 'Sanger ' },
            { name: 'Sânpaul ', simpleName: 'Sanpaul ' },
            { name: 'Sânpetru de Câmpie ', simpleName: 'Sanpetru de Campie ' },
            { name: 'Sântana de Mureş ', simpleName: 'Santana de Mures ' },
            { name: 'Târgu Mureş ', simpleName: 'Targu Mures ' },
            { name: 'Târnăveni ', simpleName: 'Tarnaveni ' },
            { name: 'Tăureni ', simpleName: 'Taureni ' },
            { name: 'Ungheni ' },
            { name: 'Valea Largă ', simpleName: 'Valea Larga ' },
            { name: 'Veţca ', simpleName: 'Vetca ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ' },
            { name: 'Voivodeni ' },
            { name: 'Vânători ', simpleName: 'Vanatori ' },
            { name: 'Vărgata ', simpleName: 'Vargata ' },
            { name: 'Vătava ', simpleName: 'Vatava ' },
            { name: 'Zagăr ', simpleName: 'Zagar ' },
            { name: 'Zau de Câmpie ', simpleName: 'Zau de Campie ' },
            { name: 'Şincai ', simpleName: 'Sincai ' },
            { name: 'Şăulia ', simpleName: 'Saulia ' },
            { name: 'Corbeşti ', simpleName: 'Corbesti ', village: 'Acatari ' },
            { name: 'Gruişor ', simpleName: 'Gruisor ', village: 'Acatari ' },
            { name: 'Găieşti ', simpleName: 'Gaiesti ', village: 'Acatari ' },
            { name: 'Murgeşti ', simpleName: 'Murgesti ', village: 'Acatari ' },
            { name: 'Roteni ', village: 'Acatari ' },
            { name: 'Stejeriş ', simpleName: 'Stejeris ', village: 'Acatari ' },
            { name: 'Suveica ', village: 'Acatari ' },
            { name: 'Vălenii ', simpleName: 'Valenii ', village: 'Acatari ' },
            { name: 'Chinciuş ', simpleName: 'Chincius ', village: 'Adamus ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Adamus ' },
            { name: 'Crăieşti ', simpleName: 'Craiesti ', village: 'Adamus ' },
            { name: 'Dâmbău ', simpleName: 'Dambau ', village: 'Adamus ' },
            { name: 'Herepea ', village: 'Adamus ' },
            { name: 'Boiu ', village: 'Albesti ' },
            { name: 'Bârlibăşoaia ', simpleName: 'Barlibasoaia ', village: 'Albesti ' },
            { name: 'Jacu ', village: 'Albesti ' },
            { name: 'Valea Albeştiului ', simpleName: 'Valea Albestiului ', village: 'Albesti ' },
            { name: 'Valea Dăii ', simpleName: 'Valea Daii ', village: 'Albesti ' },
            { name: 'Valea Şapartocului ', simpleName: 'Valea Sapartocului ', village: 'Albesti ' },
            { name: 'Şapartoc ', simpleName: 'Sapartoc ', village: 'Albesti ' },
            { name: 'Ţopa ', simpleName: 'Topa ', village: 'Albesti ' },
            { name: 'Fiţcău ', simpleName: 'Fitcau ', village: 'Alunis ' },
            { name: 'Lunca Mureşului ', simpleName: 'Lunca Muresului ', village: 'Alunis ' },
            { name: 'Daia ', village: 'Apold ' },
            { name: 'Vulcan ', village: 'Apold ' },
            { name: 'Şaeş ', simpleName: 'Saes ', village: 'Apold ' },
            { name: 'Botez ', village: 'Atintis ' },
            { name: 'Cecălaca ', simpleName: 'Cecalaca ', village: 'Atintis ' },
            { name: 'Iştihaza ', simpleName: 'Istihaza ', village: 'Atintis ' },
            { name: 'Maldaoci ', village: 'Atintis ' },
            { name: 'Sâniacob ', simpleName: 'Saniacob ', village: 'Atintis ' },
            { name: 'Delenii ', village: 'Bagaciu ' },
            { name: 'Bernadea ', village: 'Bahnea ' },
            { name: 'Cund ', village: 'Bahnea ' },
            { name: 'Daia ', village: 'Bahnea ' },
            { name: 'Gogan ', village: 'Bahnea ' },
            { name: 'Idiciu ', village: 'Bahnea ' },
            { name: 'Lepindea ', village: 'Bahnea ' },
            { name: 'Ercea ', village: 'Bala ' },
            { name: 'Agrişteu ', simpleName: 'Agristeu ', village: 'Balauseri ' },
            { name: 'Chendu ', village: 'Balauseri ' },
            { name: 'Dumitreni ', village: 'Balauseri ' },
            { name: 'Filitelnic ', village: 'Balauseri ' },
            { name: 'Senereuş ', simpleName: 'Senereus ', village: 'Balauseri ' },
            { name: 'Drăculea Bandului ', simpleName: 'Draculea Bandului ', village: 'Band ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Band ' },
            { name: 'Fânaţele Mădăraşului ', simpleName: 'Fanatele Madarasului ', village: 'Band ' },
            { name: 'Iştan-Tău ', simpleName: 'Istan-Tau ', village: 'Band ' },
            { name: 'Mădăraş ', simpleName: 'Madaras ', village: 'Band ' },
            { name: 'Mărăşeşti ', simpleName: 'Marasesti ', village: 'Band ' },
            { name: 'Negrenii de Câmpie ', simpleName: 'Negrenii de Campie ', village: 'Band ' },
            { name: 'Oroiu ', village: 'Band ' },
            { name: 'Petea ', village: 'Band ' },
            { name: 'Valea Mare ', village: 'Band ' },
            { name: 'Valea Rece ', village: 'Band ' },
            { name: 'Ţiptelnic ', simpleName: 'Tiptelnic ', village: 'Band ' },
            { name: 'Dedrad ', village: 'Batos ' },
            { name: 'Goreni ', village: 'Batos ' },
            { name: 'Uila ', village: 'Batos ' },
            { name: 'Beica de Sus ', village: 'Beica De Jos ' },
            { name: 'Căcuciu ', simpleName: 'Cacuciu ', village: 'Beica De Jos ' },
            { name: 'Nadăşa ', simpleName: 'Nadasa ', village: 'Beica De Jos ' },
            { name: 'Sânmihai de Pădure ', simpleName: 'Sanmihai de Padure ', village: 'Beica De Jos ' },
            { name: 'Şerbeni ', simpleName: 'Serbeni ', village: 'Beica De Jos ' },
            { name: 'Gâmbuţ ', simpleName: 'Gambut ', village: 'Bichis ' },
            { name: 'Nandra ', village: 'Bichis ' },
            { name: 'Ozd ', village: 'Bichis ' },
            { name: 'Ranta ', village: 'Bogata ' },
            { name: 'Idicel ', village: 'Brancovenesti ' },
            { name: 'Idicel-Pădure ', simpleName: 'Idicel-Padure ', village: 'Brancovenesti ' },
            { name: 'Săcalu de Pădure ', simpleName: 'Sacalu de Padure ', village: 'Brancovenesti ' },
            { name: 'Vălenii de Mureş ', simpleName: 'Valenii de Mures ', village: 'Brancovenesti ' },
            { name: 'Filpişu Mare ', simpleName: 'Filpisu Mare ', village: 'Breaza ' },
            { name: 'Filpişu Mic ', simpleName: 'Filpisu Mic ', village: 'Breaza ' },
            { name: 'Bozed ', village: 'Ceuasu De Campie ' },
            { name: 'Culpiu ', village: 'Ceuasu De Campie ' },
            { name: 'Câmpeniţa ', simpleName: 'Campenita ', village: 'Ceuasu De Campie ' },
            { name: 'Herghelia ', village: 'Ceuasu De Campie ' },
            { name: 'Porumbeni ', village: 'Ceuasu De Campie ' },
            { name: 'Săbed ', simpleName: 'Sabed ', village: 'Ceuasu De Campie ' },
            { name: 'Voiniceni ', village: 'Ceuasu De Campie ' },
            { name: 'Coasta Grindului ', village: 'Chetani ' },
            { name: 'Cordoş ', simpleName: 'Cordos ', village: 'Chetani ' },
            { name: 'Giurgiş ', simpleName: 'Giurgis ', village: 'Chetani ' },
            { name: 'Grindeni ', village: 'Chetani ' },
            { name: 'Hădăreni ', simpleName: 'Hadareni ', village: 'Chetani ' },
            { name: 'Linţ ', simpleName: 'Lint ', village: 'Chetani ' },
            { name: 'Chiheru de Sus ', village: 'Chiheru De Jos ' },
            { name: 'Urisiu de Jos ', village: 'Chiheru De Jos ' },
            { name: 'Urisiu de Sus ', village: 'Chiheru De Jos ' },
            { name: 'Coroi ', village: 'Coroisanmartin ' },
            { name: 'Odrihei ', village: 'Coroisanmartin ' },
            { name: 'Şoimuş ', simpleName: 'Soimus ', village: 'Coroisanmartin ' },
            { name: 'Fânaţele Socolului ', simpleName: 'Fanatele Socolului ', village: 'Cozma ' },
            { name: 'Socolu de Câmpie ', simpleName: 'Socolu de Campie ', village: 'Cozma ' },
            { name: 'Valea Sasului ', village: 'Cozma ' },
            { name: 'Valea Ungurului ', village: 'Cozma ' },
            { name: 'Budiu Mic ', village: 'Craciunesti ' },
            { name: 'Cinta ', village: 'Craciunesti ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Craciunesti ' },
            { name: 'Tirimioara ', village: 'Craciunesti ' },
            { name: 'Lefaia ', village: 'Craiesti ' },
            { name: 'Milăşel ', simpleName: 'Milasel ', village: 'Craiesti ' },
            { name: 'Nima Milăşelului ', simpleName: 'Nima Milaselului ', village: 'Craiesti ' },
            { name: 'Vălureni ', simpleName: 'Valureni ', village: 'Cristesti ' },
            { name: 'Ciba ', village: 'Crăciunești ' },
            { name: 'Foi ', village: 'Crăciunești ' },
            { name: 'Nicoleşti ', simpleName: 'Nicolesti ', village: 'Crăciunești ' },
            { name: 'Bord ', village: 'Cucerdea ' },
            { name: 'Şeulia de Mureş ', simpleName: 'Seulia de Mures ', village: 'Cucerdea ' },
            { name: 'După Deal ', simpleName: 'Dupa Deal ', village: 'Cuci ' },
            { name: 'Dătăşeni ', simpleName: 'Dataseni ', village: 'Cuci ' },
            { name: 'Orosia ', village: 'Cuci ' },
            { name: 'Petrilaca ', village: 'Cuci ' },
            { name: 'Criş ', simpleName: 'Cris ', village: 'Danes ' },
            { name: 'Seleuş ', simpleName: 'Seleus ', village: 'Danes ' },
            { name: 'Stejărenii ', simpleName: 'Stejarenii ', village: 'Danes ' },
            { name: 'Bistra Mureşului ', simpleName: 'Bistra Muresului ', village: 'Deda ' },
            { name: 'Filea ', village: 'Deda ' },
            { name: 'Pietriş ', simpleName: 'Pietris ', village: 'Deda ' },
            { name: 'Câmpu Cetăţii ', simpleName: 'Campu Cetatii ', village: 'Eremitu ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Eremitu ' },
            { name: 'Dămieni ', simpleName: 'Damieni ', village: 'Eremitu ' },
            { name: 'Mătrici ', simpleName: 'Matrici ', village: 'Eremitu ' },
            { name: 'Căluşeri ', simpleName: 'Caluseri ', village: 'Ernei ' },
            { name: 'Dumbrăvioara ', simpleName: 'Dumbravioara ', village: 'Ernei ' },
            { name: 'Icland ', village: 'Ernei ' },
            { name: 'Sângeru de Pădure ', simpleName: 'Sangeru de Padure ', village: 'Ernei ' },
            { name: 'Săcăreni ', simpleName: 'Sacareni ', village: 'Ernei ' },
            { name: 'Bordoşiu ', simpleName: 'Bordosiu ', village: 'Fantanele ' },
            { name: 'Cibu ', village: 'Fantanele ' },
            { name: 'Călimăneşti ', simpleName: 'Calimanesti ', village: 'Fantanele ' },
            { name: 'Roua ', village: 'Fantanele ' },
            { name: 'Viforoasa ', village: 'Fantanele ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Faragau ' },
            { name: 'Hodaia ', village: 'Faragau ' },
            { name: 'Onuca ', village: 'Faragau ' },
            { name: 'Poarta ', village: 'Faragau ' },
            { name: 'Tonciu ', village: 'Faragau ' },
            { name: 'Adrianu Mare ', village: 'Galesti ' },
            { name: 'Adrianu Mic ', village: 'Galesti ' },
            { name: 'Bedeni ', village: 'Galesti ' },
            { name: 'Maiad ', village: 'Galesti ' },
            { name: 'Troiţa ', simpleName: 'Troita ', village: 'Galesti ' },
            { name: 'Păucişoara ', simpleName: 'Paucisoara ', village: 'Ganesti ' },
            { name: 'Sub Pădure ', simpleName: 'Sub Padure ', village: 'Ganesti ' },
            { name: 'Ilieni ', village: 'Gheorghe Doja ' },
            { name: 'Leordeni ', village: 'Gheorghe Doja ' },
            { name: 'Satu Nou ', village: 'Gheorghe Doja ' },
            { name: 'Tirimia ', village: 'Gheorghe Doja ' },
            { name: 'Abud ', village: 'Ghindari ' },
            { name: 'Ceie ', village: 'Ghindari ' },
            { name: 'Solocma ', village: 'Ghindari ' },
            { name: 'Trei Sate ', village: 'Ghindari ' },
            { name: 'Merişor ', simpleName: 'Merisor ', village: 'Glodeni ' },
            { name: 'Moişa ', simpleName: 'Moisa ', village: 'Glodeni ' },
            { name: 'Păcureni ', simpleName: 'Pacureni ', village: 'Glodeni ' },
            { name: 'Păingeni ', simpleName: 'Paingeni ', village: 'Glodeni ' },
            { name: 'Iara de Mureş ', simpleName: 'Iara de Mures ', village: 'Gornesti ' },
            { name: 'Ilioara ', village: 'Gornesti ' },
            { name: 'Mura Mare ', village: 'Gornesti ' },
            { name: 'Mura Mică ', simpleName: 'Mura Mica ', village: 'Gornesti ' },
            { name: 'Periş ', simpleName: 'Peris ', village: 'Gornesti ' },
            { name: 'Petrilaca de Mureş ', simpleName: 'Petrilaca de Mures ', village: 'Gornesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Gornesti ' },
            { name: 'Teleac ', village: 'Gornesti ' },
            { name: 'Leorinţa ', simpleName: 'Leorinta ', village: 'Grebenisu De Campie ' },
            { name: 'Valea Sânpetrului ', simpleName: 'Valea Sanpetrului ', village: 'Grebenisu De Campie ' },
            { name: 'Adrian ', village: 'Gurghiu ' },
            { name: 'Caşva ', simpleName: 'Casva ', village: 'Gurghiu ' },
            { name: 'Comori ', village: 'Gurghiu ' },
            { name: 'Fundoaia ', village: 'Gurghiu ' },
            { name: 'Glăjărie ', simpleName: 'Glajarie ', village: 'Gurghiu ' },
            { name: 'Larga ', village: 'Gurghiu ' },
            { name: 'Orşova ', simpleName: 'Orsova ', village: 'Gurghiu ' },
            { name: 'Orşova-Pădure ', simpleName: 'Orsova-Padure ', village: 'Gurghiu ' },
            { name: 'Păuloaia ', simpleName: 'Pauloaia ', village: 'Gurghiu ' },
            { name: 'Sânvăsii ', simpleName: 'Sanvasii ', village: 'Gălești ' },
            { name: 'Seuca ', village: 'Gănești ' },
            { name: 'Arşiţa ', simpleName: 'Arsita ', village: 'Hodac ' },
            { name: 'Bicaşu ', simpleName: 'Bicasu ', village: 'Hodac ' },
            { name: 'Dubiştea de Pădure ', simpleName: 'Dubistea de Padure ', village: 'Hodac ' },
            { name: 'Mirigioaia ', village: 'Hodac ' },
            { name: 'Toaca ', village: 'Hodac ' },
            { name: 'Uricea ', village: 'Hodac ' },
            { name: 'Ihod ', village: 'Hodosa ' },
            { name: 'Isla ', village: 'Hodosa ' },
            { name: 'Sâmbriaş ', simpleName: 'Sambrias ', village: 'Hodosa ' },
            { name: 'Blidireasa ', village: 'Ibanesti ' },
            { name: 'Brădeţelu ', simpleName: 'Bradetelu ', village: 'Ibanesti ' },
            { name: 'Dulcea ', village: 'Ibanesti ' },
            { name: 'Ibăneşti-Pădure ', simpleName: 'Ibanesti-Padure ', village: 'Ibanesti ' },
            { name: 'Lăpuşna ', simpleName: 'Lapusna ', village: 'Ibanesti ' },
            { name: 'Pârâu Mare ', simpleName: 'Parau Mare ', village: 'Ibanesti ' },
            { name: 'Tireu ', village: 'Ibanesti ' },
            { name: 'Tisieu ', village: 'Ibanesti ' },
            { name: 'Zimţi ', simpleName: 'Zimti ', village: 'Ibanesti ' },
            { name: 'Chisăliţa ', simpleName: 'Chisalita ', village: 'Iclanzel ' },
            { name: 'Căpuşu de Câmpie ', simpleName: 'Capusu de Campie ', village: 'Iclanzel ' },
            { name: 'După Deal ', simpleName: 'Dupa Deal ', village: 'Iclanzel ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Iclanzel ' },
            { name: 'Fânaţele Căpuşului ', simpleName: 'Fanatele Capusului ', village: 'Iclanzel ' },
            { name: 'Ghidaşteu ', simpleName: 'Ghidasteu ', village: 'Iclanzel ' },
            { name: 'Iclandu Mare ', village: 'Iclanzel ' },
            { name: 'Mădărăşeni ', simpleName: 'Madaraseni ', village: 'Iclanzel ' },
            { name: 'Tăblăşeni ', simpleName: 'Tablaseni ', village: 'Iclanzel ' },
            { name: 'Valea Iclandului ', village: 'Iclanzel ' },
            { name: 'Deleni ', village: 'Ideciu De Jos ' },
            { name: 'Ideciu de Sus ', village: 'Ideciu De Jos ' },
            { name: 'Cipău ', simpleName: 'Cipau ', village: 'Iernut ' },
            { name: 'Deag ', village: 'Iernut ' },
            { name: 'Lechinţa ', simpleName: 'Lechinta ', village: 'Iernut ' },
            { name: 'Oarba de Mureş ', simpleName: 'Oarba de Mures ', village: 'Iernut ' },
            { name: 'Porumbac ', village: 'Iernut ' },
            { name: 'Racameţ ', simpleName: 'Racamet ', village: 'Iernut ' },
            { name: 'Sfântu Gheorghe ', simpleName: 'Sfantu Gheorghe ', village: 'Iernut ' },
            { name: 'Sălcud ', simpleName: 'Salcud ', village: 'Iernut ' },
            { name: 'Bozeni ', village: 'Livezeni ' },
            { name: 'Corunca ', village: 'Livezeni ' },
            { name: 'Ivăneşti ', simpleName: 'Ivanesti ', village: 'Livezeni ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Livezeni ' },
            { name: 'Sânişor ', simpleName: 'Sanisor ', village: 'Livezeni ' },
            { name: 'Avrămeşti ', simpleName: 'Avramesti ', village: 'Ludus ' },
            { name: 'Cioarga ', village: 'Ludus ' },
            { name: 'Ciurgău ', simpleName: 'Ciurgau ', village: 'Ludus ' },
            { name: 'Fundătura ', simpleName: 'Fundatura ', village: 'Ludus ' },
            { name: 'Gheja ', village: 'Ludus ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Ludus ' },
            { name: 'Băiţa ', simpleName: 'Baita ', village: 'Lunca ' },
            { name: 'Frunzeni ', village: 'Lunca ' },
            { name: 'Logig ', village: 'Lunca ' },
            { name: 'Sântu ', simpleName: 'Santu ', village: 'Lunca ' },
            { name: 'Neagra ', village: 'Lunca Bradului ' },
            { name: 'Sălard ', simpleName: 'Salard ', village: 'Lunca Bradului ' },
            { name: 'Bereni ', village: 'Magherani ' },
            { name: 'Bâra ', simpleName: 'Bara ', village: 'Magherani ' },
            { name: 'Cându ', simpleName: 'Candu ', village: 'Magherani ' },
            { name: 'Drojdii ', village: 'Magherani ' },
            { name: 'Eremieni ', village: 'Magherani ' },
            { name: 'Maia ', village: 'Magherani ' },
            { name: 'Mărculeni ', simpleName: 'Marculeni ', village: 'Magherani ' },
            { name: 'Torba ', village: 'Magherani ' },
            { name: 'Şilea Nirajului ', simpleName: 'Silea Nirajului ', village: 'Magherani ' },
            { name: 'Abuş ', simpleName: 'Abus ', village: 'Mica ' },
            { name: 'Ceuaş ', simpleName: 'Ceuas ', village: 'Mica ' },
            { name: 'Căpâlna de Sus ', simpleName: 'Capalna de Sus ', village: 'Mica ' },
            { name: 'Deaj ', village: 'Mica ' },
            { name: 'Hărănglab ', simpleName: 'Haranglab ', village: 'Mica ' },
            { name: 'Şomoştelnic ', simpleName: 'Somostelnic ', village: 'Mica ' },
            { name: 'Beu ', village: 'Miercurea Nirajului ' },
            { name: 'Dumitreştii ', simpleName: 'Dumitrestii ', village: 'Miercurea Nirajului ' },
            { name: 'Lăureni ', simpleName: 'Laureni ', village: 'Miercurea Nirajului ' },
            { name: 'Moşuni ', simpleName: 'Mosuni ', village: 'Miercurea Nirajului ' },
            { name: 'Tâmpa ', simpleName: 'Tampa ', village: 'Miercurea Nirajului ' },
            { name: 'Veţa ', simpleName: 'Veta ', village: 'Miercurea Nirajului ' },
            { name: 'Şardu Nirajului ', simpleName: 'Sardu Nirajului ', village: 'Miercurea Nirajului ' },
            { name: 'Bujor ', village: 'Mihesu De Campie ' },
            { name: 'Cirhagău ', simpleName: 'Cirhagau ', village: 'Mihesu De Campie ' },
            { name: 'Groapa Rădăii ', simpleName: 'Groapa Radaii ', village: 'Mihesu De Campie ' },
            { name: 'Mogoaia ', village: 'Mihesu De Campie ' },
            { name: 'Răzoare ', simpleName: 'Razoare ', village: 'Mihesu De Campie ' },
            { name: 'Ştefanca ', simpleName: 'Stefanca ', village: 'Mihesu De Campie ' },
            { name: 'Şăuliţa ', simpleName: 'Saulita ', village: 'Mihesu De Campie ' },
            { name: 'Măgheruş ', simpleName: 'Magherus ', village: 'Nades ' },
            { name: 'Pipea ', village: 'Nades ' },
            { name: 'Ţigmandru ', simpleName: 'Tigmandru ', village: 'Nades ' },
            { name: 'Ghineşti ', simpleName: 'Ghinesti ', village: 'Neaua ' },
            { name: 'Rigmani ', village: 'Neaua ' },
            { name: 'Sânsimion ', simpleName: 'Sansimion ', village: 'Neaua ' },
            { name: 'Vădaş ', simpleName: 'Vadas ', village: 'Neaua ' },
            { name: 'Dileu Vechi ', village: 'Ogra ' },
            { name: 'Giuluş ', simpleName: 'Giulus ', village: 'Ogra ' },
            { name: 'Lăscud ', simpleName: 'Lascud ', village: 'Ogra ' },
            { name: 'Vaideiu ', village: 'Ogra ' },
            { name: 'Berghia ', village: 'Panet ' },
            { name: 'Cuieşd ', simpleName: 'Cuiesd ', village: 'Panet ' },
            { name: 'Hărţău ', simpleName: 'Hartau ', village: 'Panet ' },
            { name: 'Sântioana de Mureş ', simpleName: 'Santioana de Mures ', village: 'Panet ' },
            { name: 'Dobra ', village: 'Papiu Ilarian ' },
            { name: 'Merişoru ', simpleName: 'Merisoru ', village: 'Papiu Ilarian ' },
            { name: 'Ursoaia ', village: 'Papiu Ilarian ' },
            { name: 'Şandru ', simpleName: 'Sandru ', village: 'Papiu Ilarian ' },
            { name: 'Bolintineni ', village: 'Pasareni ' },
            { name: 'Gălăţeni ', simpleName: 'Galateni ', village: 'Pasareni ' },
            { name: 'Habic ', village: 'Petelea ' },
            { name: 'Bologaia ', village: 'Pogaceaua ' },
            { name: 'Ciulea ', village: 'Pogaceaua ' },
            { name: 'Deleni ', village: 'Pogaceaua ' },
            { name: 'Fântâna Babii ', simpleName: 'Fantana Babii ', village: 'Pogaceaua ' },
            { name: 'Pârâu Crucii ', simpleName: 'Parau Crucii ', village: 'Pogaceaua ' },
            { name: 'Scurta ', village: 'Pogaceaua ' },
            { name: 'Sicele ', village: 'Pogaceaua ' },
            { name: 'Valea Sânpetrului ', simpleName: 'Valea Sanpetrului ', village: 'Pogaceaua ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Pogaceaua ' },
            { name: 'Andreneasa ', village: 'Rastolita ' },
            { name: 'Borzia ', village: 'Rastolita ' },
            { name: 'Gălăoaia ', simpleName: 'Galaoaia ', village: 'Rastolita ' },
            { name: 'Iod ', village: 'Rastolita ' },
            { name: 'Apalina ', village: 'Reghin ' },
            { name: 'Iernuţeni ', simpleName: 'Iernuteni ', village: 'Reghin ' },
            { name: 'Coasta Mare ', village: 'Riciu ' },
            { name: 'Cotorinău ', simpleName: 'Cotorinau ', village: 'Riciu ' },
            { name: 'Cureţe ', simpleName: 'Curete ', village: 'Riciu ' },
            { name: 'Căciulata ', simpleName: 'Caciulata ', village: 'Riciu ' },
            { name: 'Hagău ', simpleName: 'Hagau ', village: 'Riciu ' },
            { name: 'Leniş ', simpleName: 'Lenis ', village: 'Riciu ' },
            { name: 'Nima Râciului ', simpleName: 'Nima Raciului ', village: 'Riciu ' },
            { name: 'Obârşie ', simpleName: 'Obarsie ', village: 'Riciu ' },
            { name: 'Pârâu Crucii ', simpleName: 'Parau Crucii ', village: 'Riciu ' },
            { name: 'Râciu ', simpleName: 'Raciu ', village: 'Riciu ' },
            { name: 'Sânmărtinu de Câmpie ', simpleName: 'Sanmartinu de Campie ', village: 'Riciu ' },
            { name: 'Ulieş ', simpleName: 'Ulies ', village: 'Riciu ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Riciu ' },
            { name: 'Valea Sânmărtinului ', simpleName: 'Valea Sanmartinului ', village: 'Riciu ' },
            { name: 'Valea Ulieşului ', simpleName: 'Valea Uliesului ', village: 'Riciu ' },
            { name: 'Maioreşti ', simpleName: 'Maioresti ', village: 'Rusii-Munti ' },
            { name: 'Morăreni ', simpleName: 'Morareni ', village: 'Rusii-Munti ' },
            { name: 'Sebeş ', simpleName: 'Sebes ', village: 'Rusii-Munti ' },
            { name: 'Nazna ', village: 'Sancraiu De Mures ' },
            { name: 'Cotuş ', simpleName: 'Cotus ', village: 'Sangeorgiu De Mures ' },
            { name: 'Tofalău ', simpleName: 'Tofalau ', village: 'Sangeorgiu De Mures ' },
            { name: 'Bezid ', village: 'Sangeorgiu De Padure ' },
            { name: 'Bezidu Nou ', village: 'Sangeorgiu De Padure ' },
            { name: 'Loţu ', simpleName: 'Lotu ', village: 'Sangeorgiu De Padure ' },
            { name: 'Bârza ', simpleName: 'Barza ', village: 'Sanger ' },
            { name: 'Cipăieni ', simpleName: 'Cipaieni ', village: 'Sanger ' },
            { name: 'Dalu ', village: 'Sanger ' },
            { name: 'Pripoare ', village: 'Sanger ' },
            { name: 'Vălişoara ', simpleName: 'Valisoara ', village: 'Sanger ' },
            { name: 'Zăpodea ', simpleName: 'Zapodea ', village: 'Sanger ' },
            { name: 'Chirileu ', village: 'Sanpaul ' },
            { name: 'Dileu Nou ', village: 'Sanpaul ' },
            { name: 'Sânmărghita ', simpleName: 'Sanmarghita ', village: 'Sanpaul ' },
            { name: 'Valea Izvoarelor ', village: 'Sanpaul ' },
            { name: 'Bârlibaş ', simpleName: 'Barlibas ', village: 'Sanpetru De Campie ' },
            { name: 'Dâmbu ', simpleName: 'Dambu ', village: 'Sanpetru De Campie ' },
            { name: 'Satu Nou ', village: 'Sanpetru De Campie ' },
            { name: 'Sângeorgiu de Câmpie ', simpleName: 'Sangeorgiu de Campie ', village: 'Sanpetru De Campie ' },
            { name: 'Tuşinu ', simpleName: 'Tusinu ', village: 'Sanpetru De Campie ' },
            { name: 'Bărdeşti ', simpleName: 'Bardesti ', village: 'Santana De Mures ' },
            { name: 'Chinari ', village: 'Santana De Mures ' },
            { name: 'Curteni ', village: 'Santana De Mures ' },
            { name: 'Balda ', village: 'Sarmasu ' },
            { name: 'Larga ', village: 'Sarmasu ' },
            { name: 'Moruţ ', simpleName: 'Morut ', village: 'Sarmasu ' },
            { name: 'Sărmăşel ', simpleName: 'Sarmasel ', village: 'Sarmasu ' },
            { name: 'Sărmăşel-Gară ', simpleName: 'Sarmasel-Gara ', village: 'Sarmasu ' },
            { name: 'Titiana ', village: 'Sarmasu ' },
            { name: 'Vişinelu ', simpleName: 'Visinelu ', village: 'Sarmasu ' },
            { name: 'Cloaşterf ', simpleName: 'Cloasterf ', village: 'Saschiz ' },
            { name: 'Mihai Viteazu ', village: 'Saschiz ' },
            { name: 'Leorinţa-Şăulia ', simpleName: 'Leorinta-Saulia ', village: 'Saulia ' },
            { name: 'Măcicăşeşti ', simpleName: 'Macicasesti ', village: 'Saulia ' },
            { name: 'Pădurea ', simpleName: 'Padurea ', village: 'Saulia ' },
            { name: 'Angofa ', village: 'Sighisoara ' },
            { name: 'Aurel Vlaicu ', village: 'Sighisoara ' },
            { name: 'Hetiur ', village: 'Sighisoara ' },
            { name: 'Rora ', village: 'Sighisoara ' },
            { name: 'Venchi ', village: 'Sighisoara ' },
            { name: 'Viilor ', village: 'Sighisoara ' },
            { name: 'Şoromiclea ', simpleName: 'Soromiclea ', village: 'Sighisoara ' },
            { name: 'Lechincioara ', village: 'Sincai ' },
            { name: 'Pusta ', village: 'Sincai ' },
            { name: 'Şincai-Fânaţe ', simpleName: 'Sincai-Fanate ', village: 'Sincai ' },
            { name: 'Jabeniţa ', simpleName: 'Jabenita ', village: 'Solovastru ' },
            { name: 'Căpeţi ', simpleName: 'Capeti ', village: 'Sovata ' },
            { name: 'Ilieşi ', simpleName: 'Iliesi ', village: 'Sovata ' },
            { name: 'Săcădat ', simpleName: 'Sacadat ', village: 'Sovata ' },
            { name: 'Sărăţeni ', simpleName: 'Sarateni ', village: 'Sovata ' },
            { name: 'Ciobotani ', village: 'Stanceni ' },
            { name: 'Meştera ', simpleName: 'Mestera ', village: 'Stanceni ' },
            { name: 'Idrifaia ', village: 'Suplac ' },
            { name: 'Laslău Mare ', simpleName: 'Laslau Mare ', village: 'Suplac ' },
            { name: 'Laslău Mic ', simpleName: 'Laslau Mic ', village: 'Suplac ' },
            { name: 'Vaidacuţa ', simpleName: 'Vaidacuta ', village: 'Suplac ' },
            { name: 'Luieriu ', village: 'Suseni ' },
            { name: 'Mureşeni ', simpleName: 'Mureseni ', village: 'Targu Mures ' },
            { name: 'Remetea ', village: 'Targu Mures ' },
            { name: 'Bobohalma ', village: 'Tarnaveni ' },
            { name: 'Botorca ', village: 'Tarnaveni ' },
            { name: 'Cuştelnic ', simpleName: 'Custelnic ', village: 'Tarnaveni ' },
            { name: 'Fânaţe ', simpleName: 'Fanate ', village: 'Taureni ' },
            { name: 'Moara de Jos ', village: 'Taureni ' },
            { name: 'Cerghid ', village: 'Ungheni ' },
            { name: 'Cerghizel ', village: 'Ungheni ' },
            { name: 'Moreşti ', simpleName: 'Moresti ', village: 'Ungheni ' },
            { name: 'Recea ', village: 'Ungheni ' },
            { name: 'Vidrasău ', simpleName: 'Vidrasau ', village: 'Ungheni ' },
            { name: 'Şăuşa ', simpleName: 'Sausa ', village: 'Ungheni ' },
            { name: 'Grădini ', simpleName: 'Gradini ', village: 'Valea Larga ' },
            { name: 'Mălăeşti ', simpleName: 'Malaesti ', village: 'Valea Larga ' },
            { name: 'Poduri ', village: 'Valea Larga ' },
            { name: 'Valea Frăţiei ', simpleName: 'Valea Fratiei ', village: 'Valea Larga ' },
            { name: 'Valea Glodului ', village: 'Valea Larga ' },
            { name: 'Valea Pădurii ', simpleName: 'Valea Padurii ', village: 'Valea Larga ' },
            { name: 'Valea Urieşului ', simpleName: 'Valea Uriesului ', village: 'Valea Larga ' },
            { name: 'Valea Şurii ', simpleName: 'Valea Surii ', village: 'Valea Larga ' },
            { name: 'Archita ', village: 'Vanatori ' },
            { name: 'Feleag ', village: 'Vanatori ' },
            { name: 'Mureni ', village: 'Vanatori ' },
            { name: 'Şoard ', simpleName: 'Soard ', village: 'Vanatori ' },
            { name: 'Grâuşorul ', simpleName: 'Grausorul ', village: 'Vargata ' },
            { name: 'Mitreşti ', simpleName: 'Mitresti ', village: 'Vargata ' },
            { name: 'Vadu ', village: 'Vargata ' },
            { name: 'Valea ', village: 'Vargata ' },
            { name: 'Dumbrava ', village: 'Vatava ' },
            { name: 'Râpa de Jos ', simpleName: 'Rapa de Jos ', village: 'Vatava ' },
            { name: 'Jacodu ', village: 'Vetca ' },
            { name: 'Sălaşuri ', simpleName: 'Salasuri ', village: 'Vetca ' },
            { name: 'Ormeniş ', simpleName: 'Ormenis ', village: 'Viisoara ' },
            { name: 'Sântioana ', simpleName: 'Santioana ', village: 'Viisoara ' },
            { name: 'Toldal ', village: 'Voivodeni ' },
            { name: 'Seleuş ', simpleName: 'Seleus ', village: 'Zagar ' },
            { name: 'Botei ', village: 'Zau De Campie ' },
            { name: 'Bujor-Hodaie ', village: 'Zau De Campie ' },
            { name: 'Bărboşi ', simpleName: 'Barbosi ', village: 'Zau De Campie ' },
            { name: 'Ciretea ', village: 'Zau De Campie ' },
            { name: 'Gaura Sângerului ', simpleName: 'Gaura Sangerului ', village: 'Zau De Campie ' },
            { name: 'Malea ', village: 'Zau De Campie ' },
            { name: 'Tău ', simpleName: 'Tau ', village: 'Zau De Campie ' },
            {
                name: 'Ştefăneaca ', simpleName: 'Stefaneaca ', village: 'Zau De Campie '
            }
        ]
    },
    {
        auto: 'NT ',
        name: 'Neamţ ',
        cities: [
            { name: 'Agapia ' },
            { name: 'Bahna ' },
            { name: 'Bicaz ' },
            { name: 'Bicaz-Chei ' },
            { name: 'Bicazu Ardelean ' },
            { name: 'Bira ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ' },
            { name: 'Borca ' },
            { name: 'Borleşti ', simpleName: 'Borlesti ' },
            { name: 'Boteşti ', simpleName: 'Botesti ' },
            { name: 'Bozieni ' },
            { name: 'Bârgauani ', simpleName: 'Bargauani ' },
            { name: 'Bălţăteşti ', simpleName: 'Baltatesti ' },
            { name: 'Ceahlău ', simpleName: 'Ceahlau ' },
            { name: 'Cordun ' },
            { name: 'Costişa ', simpleName: 'Costisa ' },
            { name: 'Crăcăoani ', simpleName: 'Cracaoani ' },
            { name: 'Cândesti ', simpleName: 'Candesti ' },
            { name: 'Dobreni ' },
            { name: 'Dochia ' },
            { name: 'Doljeşti ', simpleName: 'Doljesti ' },
            { name: 'Dragomireşti ', simpleName: 'Dragomiresti ' },
            { name: 'Dulceşti ', simpleName: 'Dulcesti ' },
            { name: 'Dumbrava Roşie ', simpleName: 'Dumbrava Rosie ' },
            { name: 'Dămuc ', simpleName: 'Damuc ' },
            { name: 'Farcaşa ', simpleName: 'Farcasa ' },
            { name: 'Făurei ', simpleName: 'Faurei ' },
            { name: 'Gherăeşti ', simpleName: 'Gheraesti ' },
            { name: 'Ghindăoani ', simpleName: 'Ghindaoani ' },
            { name: 'Girov ' },
            { name: 'Grinţieş ', simpleName: 'Grinties ' },
            { name: 'Grumăzeşti ', simpleName: 'Grumazesti ' },
            { name: 'Gârcina ', simpleName: 'Garcina ' },
            { name: 'Hangu ' },
            { name: 'Horia ' },
            { name: 'Icuşeşti ', simpleName: 'Icusesti ' },
            { name: 'Ion Creangă ', simpleName: 'Ion Creanga ' },
            { name: 'Moldoveni ' },
            { name: 'Mărgineni ', simpleName: 'Margineni ' },
            { name: 'Oniceni ' },
            { name: 'Petricani ' },
            { name: 'Piatra Şoimului ', simpleName: 'Piatra Soimului ' },
            { name: 'Pipirig ' },
            { name: 'Podoleni ' },
            { name: 'Poiana Teiului ' },
            { name: 'Poienari ' },
            { name: 'Pângaraţi ', simpleName: 'Pangarati ' },
            { name: 'Păstrăveni ', simpleName: 'Pastraveni ' },
            { name: 'Rediu ' },
            { name: 'Roman ' },
            { name: 'Români ', simpleName: 'Romani ' },
            { name: 'Roznov ' },
            { name: 'Ruginoasa ' },
            { name: 'Răuceşti ', simpleName: 'Raucesti ' },
            { name: 'Războieni ', simpleName: 'Razboieni ' },
            { name: 'Sagna ' },
            { name: 'Secuieni ' },
            { name: 'Stăniţa ', simpleName: 'Stanita ' },
            { name: 'Săbăoani ', simpleName: 'Sabaoani ' },
            { name: 'Săvineşti ', simpleName: 'Savinesti ' },
            { name: 'Tarcău ', simpleName: 'Tarcau ' },
            { name: 'Tazlău ', simpleName: 'Tazlau ' },
            { name: 'Taşca ', simpleName: 'Tasca ' },
            { name: 'Timişeşti ', simpleName: 'Timisesti ' },
            { name: 'Trifeşti ', simpleName: 'Trifesti ' },
            { name: 'Tupilaţi ', simpleName: 'Tupilati ' },
            { name: 'Târgu Neamţ ', simpleName: 'Targu Neamt ' },
            { name: 'Tămăşeni ', simpleName: 'Tamaseni ' },
            { name: 'Urecheni ' },
            { name: 'Valea Ursului ' },
            { name: 'Vânători-Neamţ ', simpleName: 'Vanatori-Neamt ' },
            { name: 'Zăneşti ', simpleName: 'Zanesti ' },
            { name: 'Ştefan cel Mare ', simpleName: 'Stefan cel Mare ' },
            { name: 'Ţibucani ', simpleName: 'Tibucani ' },
            { name: 'Filioara ', village: 'Agapia ' },
            { name: 'Săcăluşeşti ', simpleName: 'Sacalusesti ', village: 'Agapia ' },
            { name: 'Varatec ', village: 'Agapia ' },
            { name: 'Agârcia ', simpleName: 'Agarcia ', village: 'Alexandru Cel Bun ' },
            { name: 'Bisericani ', village: 'Alexandru Cel Bun ' },
            { name: 'Bistriţa ', simpleName: 'Bistrita ', village: 'Alexandru Cel Bun ' },
            { name: 'Scăricica ', simpleName: 'Scaricica ', village: 'Alexandru Cel Bun ' },
            { name: 'Vaduri ', village: 'Alexandru Cel Bun ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Alexandru Cel Bun ' },
            { name: 'Vădurele ', simpleName: 'Vadurele ', village: 'Alexandru Cel Bun ' },
            { name: 'Arămeşti ', simpleName: 'Aramesti ', village: 'Bahna ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Bahna ' },
            { name: 'Băhnişoara ', simpleName: 'Bahnisoara ', village: 'Bahna ' },
            { name: 'Izvoare ', village: 'Bahna ' },
            { name: 'Liliac ', village: 'Bahna ' },
            { name: 'Ţuţcanii din Deal ', simpleName: 'Tutcanii din Deal ', village: 'Bahna ' },
            { name: 'Ţuţcanii din Vale ', simpleName: 'Tutcanii din Vale ', village: 'Bahna ' },
            { name: 'Valea Arini ', village: 'Baltatesti ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Baltatesti ' },
            { name: 'Bahna Mare ', village: 'Bargauani ' },
            { name: 'Baratca ', village: 'Bargauani ' },
            { name: 'Breaza ', village: 'Bargauani ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ', village: 'Bargauani ' },
            { name: 'Certieni ', village: 'Bargauani ' },
            { name: 'Chilia ', village: 'Bargauani ' },
            { name: 'Dârloaia ', simpleName: 'Darloaia ', village: 'Bargauani ' },
            { name: 'Ghelăieşti ', simpleName: 'Ghelaiesti ', village: 'Bargauani ' },
            { name: 'Homiceni ', village: 'Bargauani ' },
            { name: 'Hârtop ', simpleName: 'Hartop ', village: 'Bargauani ' },
            { name: 'Talpa ', village: 'Bargauani ' },
            { name: 'Vlădiceni ', simpleName: 'Vladiceni ', village: 'Bargauani ' },
            { name: 'Capşa ', simpleName: 'Capsa ', village: 'Bicaz ' },
            { name: 'Dodeni ', village: 'Bicaz ' },
            { name: 'Izvoru Muntelui ', village: 'Bicaz ' },
            { name: 'Izvorul Alb ', village: 'Bicaz ' },
            { name: 'Potoci ', village: 'Bicaz ' },
            { name: 'Secu ', village: 'Bicaz ' },
            { name: 'Bârnadu ', simpleName: 'Barnadu ', village: 'Bicaz-Chei ' },
            { name: 'Gherman ', village: 'Bicaz-Chei ' },
            { name: 'Ivaneş ', simpleName: 'Ivanes ', village: 'Bicaz-Chei ' },
            { name: 'Telec ', village: 'Bicazu Ardelean ' },
            { name: 'Ticoş ', simpleName: 'Ticos ', village: 'Bicazu Ardelean ' },
            { name: 'Boghicea ', village: 'Bira ' },
            { name: 'Căuşeni ', simpleName: 'Causeni ', village: 'Bira ' },
            { name: 'Negreşti ', simpleName: 'Negresti ', village: 'Bira ' },
            { name: 'Nistria ', village: 'Bira ' },
            { name: 'Rediu ', village: 'Bira ' },
            { name: 'Slobozia ', village: 'Bira ' },
            { name: 'Bodeştii de Jos ', simpleName: 'Bodestii de Jos ', village: 'Bodesti ' },
            { name: 'Corni ', village: 'Bodesti ' },
            { name: 'Oşlobeni ', simpleName: 'Oslobeni ', village: 'Bodesti ' },
            { name: 'Lunca ', village: 'Borca ' },
            { name: 'Mădei ', simpleName: 'Madei ', village: 'Borca ' },
            { name: 'Pârâul Cârjei ', simpleName: 'Paraul Carjei ', village: 'Borca ' },
            { name: 'Pârâul Pântei ', simpleName: 'Paraul Pantei ', village: 'Borca ' },
            { name: 'Sabasa ', village: 'Borca ' },
            { name: 'Soci ', village: 'Borca ' },
            { name: 'Mastacăn ', simpleName: 'Mastacan ', village: 'Borlesti ' },
            { name: 'Nechit ', village: 'Borlesti ' },
            { name: 'Ruseni ', village: 'Borlesti ' },
            { name: 'Şovoaia ', simpleName: 'Sovoaia ', village: 'Borlesti ' },
            { name: 'Barticeşti ', simpleName: 'Barticesti ', village: 'Botesti ' },
            { name: 'David ', village: 'Botesti ' },
            { name: 'Moreni ', village: 'Botesti ' },
            { name: 'Munteni ', village: 'Botesti ' },
            { name: 'Nisiporeşti ', simpleName: 'Nisiporesti ', village: 'Botesti ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Botesti ' },
            { name: 'Băneasa ', simpleName: 'Baneasa ', village: 'Bozieni ' },
            { name: 'Crăieşti ', simpleName: 'Craiesti ', village: 'Bozieni ' },
            { name: 'Cuci ', village: 'Bozieni ' },
            { name: 'Iucşa ', simpleName: 'Iucsa ', village: 'Bozieni ' },
            { name: 'Brusturi ', village: 'Brusturi-Draganesti ' },
            { name: 'Drăgăneşti ', simpleName: 'Draganesti ', village: 'Brusturi-Draganesti ' },
            { name: 'Groşi ', simpleName: 'Grosi ', village: 'Brusturi-Draganesti ' },
            { name: 'Orţăşti ', simpleName: 'Ortasti ', village: 'Brusturi-Draganesti ' },
            { name: 'Poiana ', village: 'Brusturi-Draganesti ' },
            { name: 'Râşca ', simpleName: 'Rasca ', village: 'Brusturi-Draganesti ' },
            { name: 'Târzia ', simpleName: 'Tarzia ', village: 'Brusturi-Draganesti ' },
            { name: 'Şoimăreşti ', simpleName: 'Soimaresti ', village: 'Brusturi-Draganesti ' },
            { name: 'Bărcăneşti ', simpleName: 'Barcanesti ', village: 'Candesti ' },
            { name: 'Dragova ', village: 'Candesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Candesti ' },
            { name: 'Vădurele ', simpleName: 'Vadurele ', village: 'Candesti ' },
            { name: 'Ţârdenii Mici ', simpleName: 'Tardenii Mici ', village: 'Candesti ' },
            { name: 'Bistricioara ', village: 'Ceahlau ' },
            { name: 'Pârâul Mare ', simpleName: 'Paraul Mare ', village: 'Ceahlau ' },
            { name: 'Pildeşti ', simpleName: 'Pildesti ', village: 'Cordun ' },
            { name: 'Simioneşti ', simpleName: 'Simionesti ', village: 'Cordun ' },
            { name: 'Dorneşti ', simpleName: 'Dornesti ', village: 'Costisa ' },
            { name: 'Frunzeni ', village: 'Costisa ' },
            { name: 'Mănoaia ', simpleName: 'Manoaia ', village: 'Costisa ' },
            { name: 'Cracăul Negru ', simpleName: 'Cracaul Negru ', village: 'Cracaoani ' },
            { name: 'Magazia ', village: 'Cracaoani ' },
            { name: 'Mitocu Bălan ', simpleName: 'Mitocu Balan ', village: 'Cracaoani ' },
            { name: 'Poiana Crăcăoani ', simpleName: 'Poiana Cracaoani ', village: 'Cracaoani ' },
            { name: 'Huisurez ', village: 'Damuc ' },
            { name: 'Trei Fântâni ', simpleName: 'Trei Fantani ', village: 'Damuc ' },
            { name: 'Căşăria ', simpleName: 'Casaria ', village: 'Dobreni ' },
            { name: 'Negreşti ', simpleName: 'Negresti ', village: 'Dobreni ' },
            { name: 'Poiana ', village: 'Dobreni ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Dobreni ' },
            { name: 'Băluşeşti ', simpleName: 'Balusesti ', village: 'Dochia ' },
            { name: 'Buhonca ', village: 'Doljesti ' },
            { name: 'Buruieneşti ', simpleName: 'Buruienesti ', village: 'Doljesti ' },
            { name: 'Rotunda ', village: 'Doljesti ' },
            { name: 'Borniş ', simpleName: 'Bornis ', village: 'Dragomiresti ' },
            { name: 'Hlăpeşti ', simpleName: 'Hlapesti ', village: 'Dragomiresti ' },
            { name: 'Mastacăn ', simpleName: 'Mastacan ', village: 'Dragomiresti ' },
            { name: 'Unghi ', village: 'Dragomiresti ' },
            { name: 'Vad ', village: 'Dragomiresti ' },
            { name: 'Briţcani ', simpleName: 'Britcani ', village: 'Dulcesti ' },
            { name: 'Corhana ', village: 'Dulcesti ' },
            { name: 'Cârlig ', simpleName: 'Carlig ', village: 'Dulcesti ' },
            { name: 'Poiana ', village: 'Dulcesti ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Dulcesti ' },
            { name: 'Brăşăuţi ', simpleName: 'Brasauti ', village: 'Dumbrava Rosie ' },
            { name: 'Cut ', village: 'Dumbrava Rosie ' },
            { name: 'Izvoare ', village: 'Dumbrava Rosie ' },
            { name: 'Buşmei ', simpleName: 'Busmei ', village: 'Farcasa ' },
            { name: 'Frumosu ', village: 'Farcasa ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Farcasa ' },
            { name: 'Stejaru ', village: 'Farcasa ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Faurei ' },
            { name: 'Climeşti ', simpleName: 'Climesti ', village: 'Faurei ' },
            { name: 'Micşuneşti ', simpleName: 'Micsunesti ', village: 'Faurei ' },
            { name: 'Almaş ', simpleName: 'Almas ', village: 'Garcina ' },
            { name: 'Cuejdiu ', village: 'Garcina ' },
            { name: 'Gherăeştii Noi ', simpleName: 'Gheraestii Noi ', village: 'Gheraesti ' },
            { name: 'Teţcani ', simpleName: 'Tetcani ', village: 'Gheraesti ' },
            { name: 'Boţeşti ', simpleName: 'Botesti ', village: 'Girov ' },
            { name: 'Căciuleşti ', simpleName: 'Caciulesti ', village: 'Girov ' },
            { name: 'Doina ', village: 'Girov ' },
            { name: 'Dăneşti ', simpleName: 'Danesti ', village: 'Girov ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Girov ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Girov ' },
            { name: 'Turtureşti ', simpleName: 'Turturesti ', village: 'Girov ' },
            { name: 'Verşeşti ', simpleName: 'Versesti ', village: 'Girov ' },
            { name: 'Bradu ', village: 'Grinties ' },
            { name: 'Poiana ', village: 'Grinties ' },
            { name: 'Curechiştea ', simpleName: 'Curechistea ', village: 'Grumazesti ' },
            { name: 'Netezi ', village: 'Grumazesti ' },
            { name: 'Topoliţa ', simpleName: 'Topolita ', village: 'Grumazesti ' },
            { name: 'Buhalniţa ', simpleName: 'Buhalnita ', village: 'Hangu ' },
            { name: 'Chiriţeni ', simpleName: 'Chiriteni ', village: 'Hangu ' },
            { name: 'Grozăveşti ', simpleName: 'Grozavesti ', village: 'Hangu ' },
            { name: 'Rugineşti ', simpleName: 'Ruginesti ', village: 'Hangu ' },
            { name: 'Cotu Vameş ', simpleName: 'Cotu Vames ', village: 'Horia ' },
            { name: 'Băluşeşti ', simpleName: 'Balusesti ', village: 'Icusesti ' },
            { name: 'Bătrâneşti ', simpleName: 'Batranesti ', village: 'Icusesti ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Icusesti ' },
            { name: 'Rocna ', village: 'Icusesti ' },
            { name: 'Spiridoneşti ', simpleName: 'Spiridonesti ', village: 'Icusesti ' },
            { name: 'Tabăra ', simpleName: 'Tabara ', village: 'Icusesti ' },
            { name: 'Avereşti ', simpleName: 'Averesti ', village: 'Ion Creanga ' },
            { name: 'Izvoru ', village: 'Ion Creanga ' },
            { name: 'Muncelu ', village: 'Ion Creanga ' },
            { name: 'Recea ', village: 'Ion Creanga ' },
            { name: 'Stejaru ', village: 'Ion Creanga ' },
            { name: 'Hoiseşti ', simpleName: 'Hoisesti ', village: 'Margineni ' },
            { name: 'Hârţeşti ', simpleName: 'Hartesti ', village: 'Margineni ' },
            { name: 'Itrineşti ', simpleName: 'Itrinesti ', village: 'Margineni ' },
            { name: 'Hociungi ', village: 'Moldoveni ' },
            { name: 'Ciornei ', village: 'Oniceni ' },
            { name: 'Gorun ', village: 'Oniceni ' },
            { name: 'Linseşti ', simpleName: 'Linsesti ', village: 'Oniceni ' },
            { name: 'Lunca ', village: 'Oniceni ' },
            { name: 'Mărmureni ', simpleName: 'Marmureni ', village: 'Oniceni ' },
            { name: 'Pietrosu ', village: 'Oniceni ' },
            { name: 'Poiana Humei ', village: 'Oniceni ' },
            { name: 'Pustieta ', village: 'Oniceni ' },
            { name: 'Solca ', village: 'Oniceni ' },
            { name: 'Valea Enei ', village: 'Oniceni ' },
            { name: 'Oanţu ', simpleName: 'Oantu ', village: 'Pangarati ' },
            { name: 'Poiana ', village: 'Pangarati ' },
            { name: 'Preluca ', village: 'Pangarati ' },
            { name: 'Pângărăcior ', simpleName: 'Pangaracior ', village: 'Pangarati ' },
            { name: 'Stejaru ', village: 'Pangarati ' },
            { name: 'Lunca Moldovei ', village: 'Pastraveni ' },
            { name: 'Rădeni ', simpleName: 'Radeni ', village: 'Pastraveni ' },
            { name: 'Spieşti ', simpleName: 'Spiesti ', village: 'Pastraveni ' },
            { name: 'Boiştea ', simpleName: 'Boistea ', village: 'Petricani ' },
            { name: 'Târpeşti ', simpleName: 'Tarpesti ', village: 'Petricani ' },
            { name: 'Ţolici ', simpleName: 'Tolici ', village: 'Petricani ' },
            { name: 'Ciritei ', village: 'Piatra Neamt ' },
            { name: 'Doamna ', village: 'Piatra Neamt ' },
            { name: 'Piatra-Neamţ ', simpleName: 'Piatra-Neamt ', village: 'Piatra Neamt ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Piatra Neamt ' },
            { name: 'Luminiş ', simpleName: 'Luminis ', village: 'Piatra Soimului ' },
            { name: 'Neguleşti ', simpleName: 'Negulesti ', village: 'Piatra Soimului ' },
            { name: 'Poieni ', village: 'Piatra Soimului ' },
            { name: 'Boboieşti ', simpleName: 'Boboiesti ', village: 'Pipirig ' },
            { name: 'Dolheşti ', simpleName: 'Dolhesti ', village: 'Pipirig ' },
            { name: 'Leghin ', village: 'Pipirig ' },
            { name: 'Pluton ', village: 'Pipirig ' },
            { name: 'Pâţâligeni ', simpleName: 'Pataligeni ', village: 'Pipirig ' },
            { name: 'Stânca ', simpleName: 'Stanca ', village: 'Pipirig ' },
            { name: 'Negriteşti ', simpleName: 'Negritesti ', village: 'Podoleni ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Poiana Teiului ' },
            { name: 'Dreptu ', village: 'Poiana Teiului ' },
            { name: 'Galu ', village: 'Poiana Teiului ' },
            { name: 'Petru Vodă ', simpleName: 'Petru Voda ', village: 'Poiana Teiului ' },
            { name: 'Poiana Largului ', village: 'Poiana Teiului ' },
            { name: 'Pârâul Fagului ', simpleName: 'Paraul Fagului ', village: 'Poiana Teiului ' },
            { name: 'Roşeni ', simpleName: 'Roseni ', village: 'Poiana Teiului ' },
            { name: 'Ruseni ', village: 'Poiana Teiului ' },
            { name: 'Săvineşti ', simpleName: 'Savinesti ', village: 'Poiana Teiului ' },
            { name: 'Topoliceni ', village: 'Poiana Teiului ' },
            { name: 'Bunghi ', village: 'Poienari ' },
            { name: 'Ciurea ', village: 'Poienari ' },
            { name: 'Holm ', village: 'Poienari ' },
            { name: 'Patricheni ', village: 'Poienari ' },
            { name: 'Pânceşti ', simpleName: 'Pancesti ', village: 'Poienari ' },
            { name: 'Săcăleni ', simpleName: 'Sacaleni ', village: 'Poienari ' },
            { name: 'Tălpălăi ', simpleName: 'Talpalai ', village: 'Poienari ' },
            { name: 'Oglinzi ', village: 'Raucesti ' },
            { name: 'Săveşti ', simpleName: 'Savesti ', village: 'Raucesti ' },
            { name: 'Ungheni ', village: 'Raucesti ' },
            { name: 'Borşeni ', simpleName: 'Borseni ', village: 'Razboieni ' },
            { name: 'Războienii de Jos ', simpleName: 'Razboienii de Jos ', village: 'Razboieni ' },
            { name: 'Valea Albă ', simpleName: 'Valea Alba ', village: 'Razboieni ' },
            { name: 'Valea Mare ', village: 'Razboieni ' },
            { name: 'Beţeşti ', simpleName: 'Betesti ', village: 'Rediu ' },
            { name: 'Poloboc ', village: 'Rediu ' },
            { name: 'Socea ', village: 'Rediu ' },
            { name: 'Goşmani ', simpleName: 'Gosmani ', village: 'Romani ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Romani ' },
            { name: 'Chintinici ', village: 'Roznov ' },
            { name: 'Slobozia ', village: 'Roznov ' },
            { name: 'Bozienii de Sus ', village: 'Ruginoasa ' },
            { name: 'Traian ', village: 'Sabaoani ' },
            { name: 'Gâdinţi ', simpleName: 'Gadinti ', village: 'Sagna ' },
            { name: 'Luţca ', simpleName: 'Lutca ', village: 'Sagna ' },
            { name: 'Vulpăşeşti ', simpleName: 'Vulpasesti ', village: 'Sagna ' },
            { name: 'Dumbrava Deal ', village: 'Savinesti ' },
            { name: 'Başta ', simpleName: 'Basta ', village: 'Secuieni ' },
            { name: 'Bogzeşti ', simpleName: 'Bogzesti ', village: 'Secuieni ' },
            { name: 'Butnăreşti ', simpleName: 'Butnaresti ', village: 'Secuieni ' },
            { name: 'Bârjoveni ', simpleName: 'Barjoveni ', village: 'Secuieni ' },
            { name: 'Giuleşti ', simpleName: 'Giulesti ', village: 'Secuieni ' },
            { name: 'Prăjeşti ', simpleName: 'Prajesti ', village: 'Secuieni ' },
            { name: 'Secuienii Noi ', village: 'Secuieni ' },
            { name: 'Unceşti ', simpleName: 'Uncesti ', village: 'Secuieni ' },
            { name: 'Chicerea ', village: 'Stanita ' },
            { name: 'Ghidion ', village: 'Stanita ' },
            { name: 'Poienile Oancei ', village: 'Stanita ' },
            { name: 'Todireni ', village: 'Stanita ' },
            { name: 'Veja ', village: 'Stanita ' },
            { name: 'Vlădnicele ', simpleName: 'Vladnicele ', village: 'Stanita ' },
            { name: 'Bordea ', village: 'Stefan Cel Mare ' },
            { name: 'Cârligi ', simpleName: 'Carligi ', village: 'Stefan Cel Mare ' },
            { name: 'Deleni ', village: 'Stefan Cel Mare ' },
            { name: 'Duşeşti ', simpleName: 'Dusesti ', village: 'Stefan Cel Mare ' },
            { name: 'Ghigoieşti ', simpleName: 'Ghigoiesti ', village: 'Stefan Cel Mare ' },
            { name: 'Soci ', village: 'Stefan Cel Mare ' },
            { name: 'Adjudeni ', village: 'Tamaseni ' },
            { name: 'Ardeluţa ', simpleName: 'Ardeluta ', village: 'Tarcau ' },
            { name: 'Brateş ', simpleName: 'Brates ', village: 'Tarcau ' },
            { name: 'Cazaci ', village: 'Tarcau ' },
            { name: 'Schitu Tarcău ', simpleName: 'Schitu Tarcau ', village: 'Tarcau ' },
            { name: 'Straja ', village: 'Tarcau ' },
            { name: 'Blebea ', village: 'Targu Neamt ' },
            { name: 'Humuleşti ', simpleName: 'Humulesti ', village: 'Targu Neamt ' },
            { name: 'Humuleştii Noi ', simpleName: 'Humulestii Noi ', village: 'Targu Neamt ' },
            { name: 'Hamzoaia ', village: 'Tasca ' },
            { name: 'Neagra ', village: 'Tasca ' },
            { name: 'Ticoş-Floarea ', simpleName: 'Ticos-Floarea ', village: 'Tasca ' },
            { name: 'Davideni ', village: 'Tibucani ' },
            { name: 'Ţibucanii de Jos ', simpleName: 'Tibucanii de Jos ', village: 'Tibucani ' },
            { name: 'Dumbrava ', village: 'Timisesti ' },
            { name: 'Plăieşu ', simpleName: 'Plaiesu ', village: 'Timisesti ' },
            { name: 'Preuteşti ', simpleName: 'Preutesti ', village: 'Timisesti ' },
            { name: 'Zvorăneşti ', simpleName: 'Zvoranesti ', village: 'Timisesti ' },
            { name: 'Miron Costin ', village: 'Trifesti ' },
            { name: 'Arămoaia ', simpleName: 'Aramoaia ', village: 'Tupilati ' },
            { name: 'Hanul Ancuţei ', simpleName: 'Hanul Ancutei ', village: 'Tupilati ' },
            { name: 'Totoieşti ', simpleName: 'Totoiesti ', village: 'Tupilati ' },
            { name: 'Ingăreşti ', simpleName: 'Ingaresti ', village: 'Urecheni ' },
            { name: 'Plugari ', village: 'Urecheni ' },
            { name: 'Bucium ', village: 'Valea Ursului ' },
            { name: 'Chilii ', village: 'Valea Ursului ' },
            { name: 'Giurgeni ', village: 'Valea Ursului ' },
            { name: 'Muncelu de Jos ', village: 'Valea Ursului ' },
            { name: 'Lunca ', village: 'Vanatori-Neamt ' },
            { name: 'Mânăstirea Neamţ ', simpleName: 'Manastirea Neamt ', village: 'Vanatori-Neamt ' },
            { name: 'Nemţişor ', simpleName: 'Nemtisor ', village: 'Vanatori-Neamt ' },
            {
                name: 'Traian ', village: 'Zanesti '
            }
        ]
    },
    {
        auto: 'OT ',
        name: 'Olt ',
        cities: [
            { name: 'Baldovineşti ', simpleName: 'Baldovinesti ' },
            { name: 'Balş ', simpleName: 'Bals ' },
            { name: 'Bobiceşti ', simpleName: 'Bobicesti ' },
            { name: 'Brastavăţu ', simpleName: 'Brastavatu ' },
            { name: 'Brebeni ' },
            { name: 'Brâncoveni ', simpleName: 'Brancoveni ' },
            { name: 'Bucinişu ', simpleName: 'Bucinisu ' },
            { name: 'Bârza ', simpleName: 'Barza ' },
            { name: 'Băbiciu ', simpleName: 'Babiciu ' },
            { name: 'Caracal ' },
            { name: 'Cezieni ' },
            { name: 'Cilieni ' },
            { name: 'Coloneşti ', simpleName: 'Colonesti ' },
            { name: 'Corabia ' },
            { name: 'Corbu ' },
            { name: 'Coteana ' },
            { name: 'Crâmpoia ', simpleName: 'Crampoia ' },
            { name: 'Cungrea ' },
            { name: 'Curtişoara ', simpleName: 'Curtisoara ' },
            { name: 'Cârlogani ', simpleName: 'Carlogani ' },
            { name: 'Deveselu ' },
            { name: 'Dobreţu ', simpleName: 'Dobretu ' },
            { name: 'Dobrosloveni ' },
            { name: 'Dobroteasa ' },
            { name: 'Dobrun ' },
            { name: 'Drăghiceni ', simpleName: 'Draghiceni ' },
            { name: 'Drăgăneşti-Olt ', simpleName: 'Draganesti-Olt ' },
            { name: 'Dăneasa ', simpleName: 'Daneasa ' },
            { name: 'Făgeţelu ', simpleName: 'Fagetelu ' },
            { name: 'Fălcoiu ', simpleName: 'Falcoiu ' },
            { name: 'Fărcaşele ', simpleName: 'Farcasele ' },
            { name: 'Giuvărăşti ', simpleName: 'Giuvarasti ' },
            { name: 'Gostavăţu ', simpleName: 'Gostavatu ' },
            { name: 'Grojdibodu ' },
            { name: 'Grădinari ', simpleName: 'Gradinari ' },
            { name: 'Gârcov ', simpleName: 'Garcov ' },
            { name: 'Găneasa ', simpleName: 'Ganeasa ' },
            { name: 'Ianca ' },
            { name: 'Iancu Jianu ' },
            { name: 'Icoana ' },
            { name: 'Izbiceni ' },
            { name: 'Izvoarele ' },
            { name: 'Leleasca ' },
            { name: 'Mihăeşti ', simpleName: 'Mihaesti ' },
            { name: 'Morunglav ' },
            { name: 'Movileni ' },
            { name: 'Mărunţei ', simpleName: 'Maruntei ' },
            { name: 'Nicolae Titulescu ' },
            { name: 'Oboga ' },
            { name: 'Obârşia ', simpleName: 'Obarsia ' },
            { name: 'Oporelu ' },
            { name: 'Orlea ' },
            { name: 'Osica de Sus ' },
            { name: 'Perieţi ', simpleName: 'Perieti ' },
            { name: 'Piatra-Olt ' },
            { name: 'Pleşoiu ', simpleName: 'Plesoiu ' },
            { name: 'Poboru ' },
            { name: 'Potcoava ' },
            { name: 'Priseaca ' },
            { name: 'Radomireşti ', simpleName: 'Radomiresti ' },
            { name: 'Redea ' },
            { name: 'Rotunda ' },
            { name: 'Rusăneşti ', simpleName: 'Rusanesti ' },
            { name: 'Schitu ' },
            { name: 'Scorniceşti ', simpleName: 'Scornicesti ' },
            { name: 'Scărişoara ', simpleName: 'Scarisoara ' },
            { name: 'Seaca ' },
            { name: 'Slatina ' },
            { name: 'Slătioara ', simpleName: 'Slatioara ' },
            { name: 'Spineni ' },
            { name: 'Sprâncenata ', simpleName: 'Sprancenata ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ' },
            { name: 'Stoicăneşti ', simpleName: 'Stoicanesti ' },
            { name: 'Strejeşti ', simpleName: 'Strejesti ' },
            { name: 'Studina ' },
            { name: 'Sâmbureşti ', simpleName: 'Samburesti ' },
            { name: 'Teslui ' },
            { name: 'Tia Mare ' },
            { name: 'Topana ' },
            { name: 'Traian ' },
            { name: 'Tufeni ' },
            { name: 'Tătuleşti ', simpleName: 'Tatulesti ' },
            { name: 'Urzica ' },
            { name: 'Valea Mare ' },
            { name: 'Verguleasa ' },
            { name: 'Vitomireşti ', simpleName: 'Vitomiresti ' },
            { name: 'Vişina ', simpleName: 'Visina ' },
            { name: 'Vlădila ', simpleName: 'Vladila ' },
            { name: 'Voineasa ' },
            { name: 'Vulpeni ' },
            { name: 'Vultureşti ', simpleName: 'Vulturesti ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ' },
            { name: 'Vădastra ', simpleName: 'Vadastra ' },
            { name: 'Vădăstriţa ', simpleName: 'Vadastrita ' },
            { name: 'Văleni ', simpleName: 'Valeni ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ' },
            { name: 'Ştefan cel Mare ', simpleName: 'Stefan cel Mare ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Baldovinesti ' },
            { name: 'Băleasa ', simpleName: 'Baleasa ', village: 'Baldovinesti ' },
            { name: 'Dâmburile ', simpleName: 'Damburile ', village: 'Baldovinesti ' },
            { name: 'Gubandru ', village: 'Baldovinesti ' },
            { name: 'Găvăneşti ', simpleName: 'Gavanesti ', village: 'Baldovinesti ' },
            { name: 'Pietriş ', simpleName: 'Pietris ', village: 'Baldovinesti ' },
            { name: 'Corbeni ', village: 'Bals ' },
            { name: 'Româna ', simpleName: 'Romana ', village: 'Bals ' },
            { name: 'Teiş ', simpleName: 'Teis ', village: 'Bals ' },
            { name: 'Boroeşti ', simpleName: 'Boroesti ', village: 'Barasti ' },
            { name: 'Bărăştii de Cepturi ', simpleName: 'Barastii de Cepturi ', village: 'Barasti ' },
            { name: 'Bărăştii de Vede ', simpleName: 'Barastii de Vede ', village: 'Barasti ' },
            { name: 'Ciocăneşti ', simpleName: 'Ciocanesti ', village: 'Barasti ' },
            { name: 'Lăzăreşti ', simpleName: 'Lazaresti ', village: 'Barasti ' },
            { name: 'Mereni ', village: 'Barasti ' },
            { name: 'Moţoeşti ', simpleName: 'Motoesti ', village: 'Barasti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Barasti ' },
            { name: 'Braneţ ', simpleName: 'Branet ', village: 'Barza ' },
            { name: 'Bechet ', village: 'Bobicesti ' },
            { name: 'Belgun ', village: 'Bobicesti ' },
            { name: 'Chinteşti ', simpleName: 'Chintesti ', village: 'Bobicesti ' },
            { name: 'Comăneşti ', simpleName: 'Comanesti ', village: 'Bobicesti ' },
            { name: 'Govora ', village: 'Bobicesti ' },
            { name: 'Leoteşti ', simpleName: 'Leotesti ', village: 'Bobicesti ' },
            { name: 'Mirila ', village: 'Bobicesti ' },
            { name: 'Mărgheni ', simpleName: 'Margheni ', village: 'Brancoveni ' },
            { name: 'Ociogi ', village: 'Brancoveni ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Brancoveni ' },
            { name: 'Cruşovu ', simpleName: 'Crusovu ', village: 'Brastavatu ' },
            { name: 'Teiuşu ', simpleName: 'Teiusu ', village: 'Brebeni ' },
            { name: 'Bucinişu Mic ', simpleName: 'Bucinisu Mic ', village: 'Bucinisu ' },
            { name: 'Beculeşti ', simpleName: 'Beculesti ', village: 'Carlogani ' },
            { name: 'Cepari ', village: 'Carlogani ' },
            { name: 'Scorbura ', village: 'Carlogani ' },
            { name: 'Stupina ', village: 'Carlogani ' },
            { name: 'Bondrea ', village: 'Cezieni ' },
            { name: 'Corlăteşti ', simpleName: 'Corlatesti ', village: 'Cezieni ' },
            { name: 'Bărăşti ', simpleName: 'Barasti ', village: 'Colonesti ' },
            { name: 'Bătăreni ', simpleName: 'Batareni ', village: 'Colonesti ' },
            { name: 'Chelbeşti ', simpleName: 'Chelbesti ', village: 'Colonesti ' },
            { name: 'Cârstani ', simpleName: 'Carstani ', village: 'Colonesti ' },
            { name: 'Gueşti ', simpleName: 'Guesti ', village: 'Colonesti ' },
            { name: 'Mărunţei ', simpleName: 'Maruntei ', village: 'Colonesti ' },
            { name: 'Năvârgeni ', simpleName: 'Navargeni ', village: 'Colonesti ' },
            { name: 'Vlaici ', village: 'Colonesti ' },
            { name: 'Tudor Vladimirescu ', village: 'Corabia ' },
            { name: 'Vârtopu ', simpleName: 'Vartopu ', village: 'Corabia ' },
            { name: 'Burduleşti ', simpleName: 'Burdulesti ', village: 'Corbu ' },
            { name: 'Buzeşti ', simpleName: 'Buzesti ', village: 'Corbu ' },
            { name: 'Ciureşti ', simpleName: 'Ciuresti ', village: 'Corbu ' },
            { name: 'Milcoveni ', village: 'Corbu ' },
            { name: 'Buta ', village: 'Crampoia ' },
            { name: 'Cepeşti ', simpleName: 'Cepesti ', village: 'Cungrea ' },
            { name: 'Ibăneşti ', simpleName: 'Ibanesti ', village: 'Cungrea ' },
            { name: 'Mieşti ', simpleName: 'Miesti ', village: 'Cungrea ' },
            { name: 'Oteştii de Jos ', simpleName: 'Otestii de Jos ', village: 'Cungrea ' },
            { name: 'Oteştii de Sus ', simpleName: 'Otestii de Sus ', village: 'Cungrea ' },
            { name: 'Spătaru ', simpleName: 'Spataru ', village: 'Cungrea ' },
            { name: 'Dobrotinet ', village: 'Curtisoara ' },
            { name: 'Linia din Vale ', village: 'Curtisoara ' },
            { name: 'Pietrişu ', simpleName: 'Pietrisu ', village: 'Curtisoara ' },
            { name: 'Proaspeţi ', simpleName: 'Proaspeti ', village: 'Curtisoara ' },
            { name: 'Raiţiu ', simpleName: 'Raitiu ', village: 'Curtisoara ' },
            { name: 'Berindei ', village: 'Daneasa ' },
            { name: 'Cioflanu ', village: 'Daneasa ' },
            { name: 'Pestra ', village: 'Daneasa ' },
            { name: 'Zănoaga ', simpleName: 'Zanoaga ', village: 'Daneasa ' },
            { name: 'Comanca ', village: 'Deveselu ' },
            { name: 'Curtişoara ', simpleName: 'Curtisoara ', village: 'Dobretu ' },
            { name: 'Horezu ', village: 'Dobretu ' },
            { name: 'Frăsinetu ', simpleName: 'Frasinetu ', village: 'Dobrosloveni ' },
            { name: 'Potopinu ', village: 'Dobrosloveni ' },
            { name: 'Reşca ', simpleName: 'Resca ', village: 'Dobrosloveni ' },
            { name: 'Reşcuţa ', simpleName: 'Rescuta ', village: 'Dobrosloveni ' },
            { name: 'Batia ', village: 'Dobroteasa ' },
            { name: 'Câmpu Mare ', simpleName: 'Campu Mare ', village: 'Dobroteasa ' },
            { name: 'Vulpeşti ', simpleName: 'Vulpesti ', village: 'Dobroteasa ' },
            { name: 'Bobu ', village: 'Dobrun ' },
            { name: 'Chilii ', village: 'Dobrun ' },
            { name: 'Osica de Jos ', village: 'Dobrun ' },
            { name: 'Roşienii Mari ', simpleName: 'Rosienii Mari ', village: 'Dobrun ' },
            { name: 'Roşienii Mici ', simpleName: 'Rosienii Mici ', village: 'Dobrun ' },
            { name: 'Ulmet ', village: 'Dobrun ' },
            { name: 'Comani ', village: 'Draganesti-Olt ' },
            { name: 'Grozăveşti ', simpleName: 'Grozavesti ', village: 'Draghiceni ' },
            { name: 'Liiceni ', village: 'Draghiceni ' },
            { name: 'Bâgeşti ', simpleName: 'Bagesti ', village: 'Fagetelu ' },
            { name: 'Chilia ', village: 'Fagetelu ' },
            { name: 'Gruiu ', village: 'Fagetelu ' },
            { name: 'Isaci ', village: 'Fagetelu ' },
            { name: 'Pielcani ', village: 'Fagetelu ' },
            { name: 'Cioroiaşu ', simpleName: 'Cioroiasu ', village: 'Falcoiu ' },
            { name: 'Cioroiu ', village: 'Falcoiu ' },
            { name: 'Fărcaşu de Jos ', simpleName: 'Farcasu de Jos ', village: 'Farcasele ' },
            { name: 'Ghimpaţi ', simpleName: 'Ghimpati ', village: 'Farcasele ' },
            { name: 'Hotărani ', simpleName: 'Hotarani ', village: 'Farcasele ' },
            { name: 'Dranovăţu ', simpleName: 'Dranovatu ', village: 'Ganeasa ' },
            { name: 'Grădiştea ', simpleName: 'Gradistea ', village: 'Ganeasa ' },
            { name: 'Izvoru ', village: 'Ganeasa ' },
            { name: 'Oltişoru ', simpleName: 'Oltisoru ', village: 'Ganeasa ' },
            { name: 'Ursa ', village: 'Garcov ' },
            { name: 'Slăveni ', simpleName: 'Slaveni ', village: 'Gostavatu ' },
            { name: 'Petculeşti ', simpleName: 'Petculesti ', village: 'Gradinari ' },
            { name: 'Runcu Mare ', village: 'Gradinari ' },
            { name: 'Satu Nou ', village: 'Gradinari ' },
            { name: 'Hotaru ', village: 'Grojdibodu ' },
            { name: 'Potelu ', village: 'Ianca ' },
            { name: 'Dobriceni ', village: 'Iancu Jianu ' },
            { name: 'Preoteşti ', simpleName: 'Preotesti ', village: 'Iancu Jianu ' },
            { name: 'Floru ', village: 'Icoana ' },
            { name: 'Ursoaia ', village: 'Icoana ' },
            { name: 'Alimăneşti ', simpleName: 'Alimanesti ', village: 'Izvoarele ' },
            { name: 'Afumaţi ', simpleName: 'Afumati ', village: 'Leleasca ' },
            { name: 'Greereşti ', simpleName: 'Greeresti ', village: 'Leleasca ' },
            { name: 'Mierliceşti ', simpleName: 'Mierlicesti ', village: 'Leleasca ' },
            { name: 'Toneşti ', simpleName: 'Tonesti ', village: 'Leleasca ' },
            { name: 'Tufaru ', village: 'Leleasca ' },
            { name: 'Urşi ', simpleName: 'Ursi ', village: 'Leleasca ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ', village: 'Maruntei ' },
            { name: 'Malu Roşu ', simpleName: 'Malu Rosu ', village: 'Maruntei ' },
            { name: 'Buşca ', simpleName: 'Busca ', village: 'Mihaesti ' },
            { name: 'Ipoteşti ', simpleName: 'Ipotesti ', village: 'Milcov ' },
            { name: 'Milcovu din Deal ', village: 'Milcov ' },
            { name: 'Milcovu din Vale ', village: 'Milcov ' },
            { name: 'Stejaru ', village: 'Milcov ' },
            { name: 'Ulmi ', village: 'Milcov ' },
            { name: 'Bărăşti ', simpleName: 'Barasti ', village: 'Morunglav ' },
            { name: 'Ghioşani ', simpleName: 'Ghiosani ', village: 'Morunglav ' },
            { name: 'Moruneşti ', simpleName: 'Morunesti ', village: 'Morunglav ' },
            { name: 'Poiana Mare ', village: 'Morunglav ' },
            { name: 'Bacea ', village: 'Movileni ' },
            { name: 'Ghimpeţeni ', simpleName: 'Ghimpeteni ', village: 'Nicolae Titulescu ' },
            { name: 'Ghimpeţenii Noi ', simpleName: 'Ghimpetenii Noi ', village: 'Nicolae Titulescu ' },
            { name: 'Coteni ', village: 'Obarsia ' },
            { name: 'Câmpu Părului ', simpleName: 'Campu Parului ', village: 'Obarsia ' },
            { name: 'Obârşia Nouă ', simpleName: 'Obarsia Noua ', village: 'Obarsia ' },
            { name: 'Tabonu ', village: 'Obarsia ' },
            { name: 'Călui ', simpleName: 'Calui ', village: 'Oboga ' },
            { name: 'Gura Căluiu ', simpleName: 'Gura Caluiu ', village: 'Oboga ' },
            { name: 'Beria de Jos ', village: 'Oporelu ' },
            { name: 'Beria de Sus ', village: 'Oporelu ' },
            { name: 'Rădeşti ', simpleName: 'Radesti ', village: 'Oporelu ' },
            { name: 'Optaşi ', simpleName: 'Optasi ', village: 'Optasi-Magura ' },
            { name: 'Vităneşti ', simpleName: 'Vitanesti ', village: 'Optasi-Magura ' },
            { name: 'Gura Padinii ', village: 'Orlea ' },
            { name: 'Orlea Nouă ', simpleName: 'Orlea Noua ', village: 'Orlea ' },
            { name: 'Satu Nou ', village: 'Orlea ' },
            { name: 'Greci ', village: 'Osica De Sus ' },
            { name: 'Ostrov ', village: 'Osica De Sus ' },
            { name: 'Peretu ', village: 'Osica De Sus ' },
            { name: 'Tomeni ', village: 'Osica De Sus ' },
            { name: 'Vlăduleni ', simpleName: 'Vladuleni ', village: 'Osica De Sus ' },
            { name: 'Butoi ', village: 'Parscoveni ' },
            { name: 'Olari ', village: 'Parscoveni ' },
            { name: 'Pîrşcoveni ', simpleName: 'Parscoveni ', village: 'Parscoveni ' },
            { name: 'Şopârliţa ', simpleName: 'Soparlita ', village: 'Parscoveni ' },
            { name: 'Bălteni ', simpleName: 'Balteni ', village: 'Perieti ' },
            { name: 'Mierleştii de Sus ', simpleName: 'Mierlestii de Sus ', village: 'Perieti ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Perieti ' },
            { name: 'Bistriţa Nouă ', simpleName: 'Bistrita Noua ', village: 'Piatra-Olt ' },
            { name: 'Criva de Jos ', village: 'Piatra-Olt ' },
            { name: 'Criva de Sus ', village: 'Piatra-Olt ' },
            { name: 'Enoşeşti ', simpleName: 'Enosesti ', village: 'Piatra-Olt ' },
            { name: 'Piatra ', village: 'Piatra-Olt ' },
            { name: 'Arceşti ', simpleName: 'Arcesti ', village: 'Plesoiu ' },
            { name: 'Arceşti Cot ', simpleName: 'Arcesti Cot ', village: 'Plesoiu ' },
            { name: 'Cocorăşti ', simpleName: 'Cocorasti ', village: 'Plesoiu ' },
            { name: 'Doba ', village: 'Plesoiu ' },
            { name: 'Schitu din Deal ', village: 'Plesoiu ' },
            { name: 'Schitu din Vale ', village: 'Plesoiu ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Poboru ' },
            { name: 'Cornăţelu ', simpleName: 'Cornatelu ', village: 'Poboru ' },
            { name: 'Creţi ', simpleName: 'Creti ', village: 'Poboru ' },
            { name: 'Seaca ', village: 'Poboru ' },
            { name: 'Surpeni ', village: 'Poboru ' },
            { name: 'Potcoava Fălcoeni ', simpleName: 'Potcoava Falcoeni ', village: 'Potcoava ' },
            { name: 'Sineşti ', simpleName: 'Sinesti ', village: 'Potcoava ' },
            { name: 'Trufineşti ', simpleName: 'Trufinesti ', village: 'Potcoava ' },
            { name: 'Valea Merilor ', village: 'Potcoava ' },
            { name: 'Buiceşti ', simpleName: 'Buicesti ', village: 'Priseaca ' },
            { name: 'Săltăneşti ', simpleName: 'Saltanesti ', village: 'Priseaca ' },
            { name: 'Crăciunei ', simpleName: 'Craciunei ', village: 'Radomiresti ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Radomiresti ' },
            { name: 'Poiana ', village: 'Radomiresti ' },
            { name: 'Redişoara ', simpleName: 'Redisoara ', village: 'Redea ' },
            { name: 'Valea Soarelui ', village: 'Redea ' },
            { name: 'Jieni ', village: 'Rusanesti ' },
            { name: 'Cerbeni ', village: 'Samburesti ' },
            { name: 'Ioniceşti ', simpleName: 'Ionicesti ', village: 'Samburesti ' },
            { name: 'Lăunele ', simpleName: 'Launele ', village: 'Samburesti ' },
            { name: 'Mănuleşti ', simpleName: 'Manulesti ', village: 'Samburesti ' },
            { name: 'Stănuleasa ', simpleName: 'Stanuleasa ', village: 'Samburesti ' },
            { name: 'Toneşti ', simpleName: 'Tonesti ', village: 'Samburesti ' },
            { name: 'Plăviceni ', simpleName: 'Plaviceni ', village: 'Scarisoara ' },
            { name: 'Rudari ', village: 'Scarisoara ' },
            { name: 'Catanele ', village: 'Schitu ' },
            { name: 'Greci ', village: 'Schitu ' },
            { name: 'Lisa ', village: 'Schitu ' },
            { name: 'Moşteni ', simpleName: 'Mosteni ', village: 'Schitu ' },
            { name: 'Bircii ', village: 'Scornicesti ' },
            { name: 'Bălţaţi ', simpleName: 'Baltati ', village: 'Scornicesti ' },
            { name: 'Chiţeasca ', simpleName: 'Chiteasca ', village: 'Scornicesti ' },
            { name: 'Constantineşti ', simpleName: 'Constantinesti ', village: 'Scornicesti ' },
            { name: 'Jitaru ', village: 'Scornicesti ' },
            { name: 'Mihăileşti Popeşti ', simpleName: 'Mihailesti Popesti ', village: 'Scornicesti ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ', village: 'Scornicesti ' },
            { name: 'Mărgineni Slobozia ', simpleName: 'Margineni Slobozia ', village: 'Scornicesti ' },
            { name: 'Negreni ', village: 'Scornicesti ' },
            { name: 'Piscani ', village: 'Scornicesti ' },
            { name: 'Rusciori ', village: 'Scornicesti ' },
            { name: 'Teiuş ', simpleName: 'Teius ', village: 'Scornicesti ' },
            { name: 'Şuica ', simpleName: 'Suica ', village: 'Scornicesti ' },
            { name: 'Strugurelu ', village: 'Serbanesti ' },
            { name: 'Şerbăneştii de Sus ', simpleName: 'Serbanestii de Sus ', village: 'Serbanesti ' },
            { name: 'Cireaşov ', simpleName: 'Cireasov ', village: 'Slatina ' },
            { name: 'Salcia ', village: 'Slatioara ' },
            { name: 'Alunişu ', simpleName: 'Alunisu ', village: 'Spineni ' },
            { name: 'Cuza Vodă ', simpleName: 'Cuza Voda ', village: 'Spineni ' },
            { name: 'Davideşti ', simpleName: 'Davidesti ', village: 'Spineni ' },
            { name: 'Optăşani ', simpleName: 'Optasani ', village: 'Spineni ' },
            { name: 'Profa ', village: 'Spineni ' },
            { name: 'Vineţi ', simpleName: 'Vineti ', village: 'Spineni ' },
            { name: 'Bârseştii de Sus ', simpleName: 'Barsestii de Sus ', village: 'Sprancenata ' },
            { name: 'Frunzaru ', village: 'Sprancenata ' },
            { name: 'Uria ', village: 'Sprancenata ' },
            { name: 'Ianca Nouă ', simpleName: 'Ianca Noua ', village: 'Stefan Cel Mare ' },
            { name: 'Colibaşi ', simpleName: 'Colibasi ', village: 'Strejesti ' },
            { name: 'Mamura ', village: 'Strejesti ' },
            { name: 'Strejeştii de Sus ', simpleName: 'Strejestii de Sus ', village: 'Strejesti ' },
            { name: 'Arvăteasca ', simpleName: 'Arvateasca ', village: 'Studina ' },
            { name: 'Grădinile ', simpleName: 'Gradinile ', village: 'Studina ' },
            { name: 'Plăviceanca ', simpleName: 'Plaviceanca ', village: 'Studina ' },
            { name: 'Studiniţa ', simpleName: 'Studinita ', village: 'Studina ' },
            { name: 'Bărbălăi ', simpleName: 'Barbalai ', village: 'Tatulesti ' },
            { name: 'Lunca ', village: 'Tatulesti ' },
            { name: 'Mirceşti ', simpleName: 'Mircesti ', village: 'Tatulesti ' },
            { name: 'Momaiu ', village: 'Tatulesti ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Tatulesti ' },
            { name: 'Cherleştii Moşteni ', simpleName: 'Cherlestii Mosteni ', village: 'Teslui ' },
            { name: 'Cherleştii din Deal ', simpleName: 'Cherlestii din Deal ', village: 'Teslui ' },
            { name: 'Comăniţa ', simpleName: 'Comanita ', village: 'Teslui ' },
            { name: 'Corbu ', village: 'Teslui ' },
            { name: 'Deleni ', village: 'Teslui ' },
            { name: 'Schitu Deleni ', village: 'Teslui ' },
            { name: 'Doanca ', village: 'Tia Mare ' },
            { name: 'Potlogeni ', village: 'Tia Mare ' },
            { name: 'Ciorâca ', simpleName: 'Cioraca ', village: 'Topana ' },
            { name: 'Cojgărei ', simpleName: 'Cojgarei ', village: 'Topana ' },
            { name: 'Cândeleşti ', simpleName: 'Candelesti ', village: 'Topana ' },
            { name: 'Ungureni ', village: 'Topana ' },
            { name: 'Barza ', village: 'Tufeni ' },
            { name: 'Stoborăşti ', simpleName: 'Stoborasti ', village: 'Tufeni ' },
            { name: 'Stăvaru ', simpleName: 'Stavaru ', village: 'Urzica ' },
            { name: 'Vişina Nouă ', simpleName: 'Visina Noua ', village: 'Vadastra ' },
            { name: 'Bărcăneşti ', simpleName: 'Barcanesti ', village: 'Valcele ' },
            { name: 'Vâlcelele de Sus ', simpleName: 'Valcelele de Sus ', village: 'Valcele ' },
            { name: 'Bârca ', simpleName: 'Barca ', village: 'Valea Mare ' },
            { name: 'Recea ', village: 'Valea Mare ' },
            { name: 'Turia ', village: 'Valea Mare ' },
            { name: 'Zorleasca ', village: 'Valea Mare ' },
            { name: 'Mandra ', village: 'Valeni ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Valeni ' },
            { name: 'Tirişneag ', simpleName: 'Tirisneag ', village: 'Valeni ' },
            { name: 'Cucueţi ', simpleName: 'Cucueti ', village: 'Verguleasa ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ', village: 'Verguleasa ' },
            { name: 'Dumitreşti ', simpleName: 'Dumitresti ', village: 'Verguleasa ' },
            { name: 'Poganu ', village: 'Verguleasa ' },
            { name: 'Valea Fetei ', village: 'Verguleasa ' },
            { name: 'Vâneşti ', simpleName: 'Vanesti ', village: 'Verguleasa ' },
            { name: 'Bulimanu ', village: 'Vitomiresti ' },
            { name: 'Dejeşti ', simpleName: 'Dejesti ', village: 'Vitomiresti ' },
            { name: 'Doneşti ', simpleName: 'Donesti ', village: 'Vitomiresti ' },
            { name: 'Stănuleasa ', simpleName: 'Stanuleasa ', village: 'Vitomiresti ' },
            { name: 'Trepteni ', village: 'Vitomiresti ' },
            { name: 'Frăsinet Gară ', simpleName: 'Frasinet Gara ', village: 'Vladila ' },
            { name: 'Vlădila Nouă ', simpleName: 'Vladila Noua ', village: 'Vladila ' },
            { name: 'Blaj ', village: 'Voineasa ' },
            { name: 'Mărgăriteşti ', simpleName: 'Margaritesti ', village: 'Voineasa ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ', village: 'Voineasa ' },
            { name: 'Rusăneştii de Sus ', simpleName: 'Rusanestii de Sus ', village: 'Voineasa ' },
            { name: 'Cotorbeşti ', simpleName: 'Cotorbesti ', village: 'Vulpeni ' },
            { name: 'Gropşani ', simpleName: 'Gropsani ', village: 'Vulpeni ' },
            { name: 'Mardale ', village: 'Vulpeni ' },
            { name: 'Pescăreşti ', simpleName: 'Pescaresti ', village: 'Vulpeni ' },
            { name: 'Plopşorelu ', simpleName: 'Plopsorelu ', village: 'Vulpeni ' },
            { name: 'Prisaca ', village: 'Vulpeni ' },
            { name: 'Simniceni ', village: 'Vulpeni ' },
            { name: 'Tabaci ', village: 'Vulpeni ' },
            { name: 'Valea Satului ', village: 'Vulpeni ' },
            { name: 'Dienci ', village: 'Vulturesti ' },
            { name: 'Valea lui Alb ', village: 'Vulturesti ' },
            {
                name: 'Vlăngăreşti ', simpleName: 'Vlangaresti ', village: 'Vulturesti '
            }
        ]
    },
    {
        auto: 'PH ',
        name: 'Prahova ',
        cities: [
            { name: 'Adunaţi ', simpleName: 'Adunati ' },
            { name: 'Albeşti-Paleologu ', simpleName: 'Albesti-Paleologu ' },
            { name: 'Aluniş ', simpleName: 'Alunis ' },
            { name: 'Apostolache ' },
            { name: 'Ariceştii Rahtivani ', simpleName: 'Aricestii Rahtivani ' },
            { name: 'Ariceştii Zeletin ', simpleName: 'Aricestii Zeletin ' },
            { name: 'Azuga ' },
            { name: 'Baba Ana ' },
            { name: 'Balta Doamnei ' },
            { name: 'Berceni ' },
            { name: 'Bertea ' },
            { name: 'Blejoi ' },
            { name: 'Boldeşti-Scăeni ', simpleName: 'Boldesti-Scaeni ' },
            { name: 'Bucov ' },
            { name: 'Buşteni ', simpleName: 'Busteni ' },
            { name: 'Băicoi ', simpleName: 'Baicoi ' },
            { name: 'Bălţeşti ', simpleName: 'Baltesti ' },
            { name: 'Băneşti ', simpleName: 'Banesti ' },
            { name: 'Bărcăneşti ', simpleName: 'Barcanesti ' },
            { name: 'Ceraşu ', simpleName: 'Cerasu ' },
            { name: 'Chiojdeanca ' },
            { name: 'Cocorăştii Mislii ', simpleName: 'Cocorastii Mislii ' },
            { name: 'Colceag ' },
            { name: 'Comarnic ' },
            { name: 'Câmpina ', simpleName: 'Campina ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ' },
            { name: 'Cărbuneşti ', simpleName: 'Carbunesti ' },
            { name: 'Drăgăneşti ', simpleName: 'Draganesti ' },
            { name: 'Dumbrava ' },
            { name: 'Dumbrăveşti ', simpleName: 'Dumbravesti ' },
            { name: 'Filipeştii de Pădure ', simpleName: 'Filipestii de Padure ' },
            { name: 'Filipeştii de Târg ', simpleName: 'Filipestii de Targ ' },
            { name: 'Floreşti ', simpleName: 'Floresti ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ' },
            { name: 'Gherghiţa ', simpleName: 'Gherghita ' },
            { name: 'Gorgota ' },
            { name: 'Gornet ' },
            { name: 'Gornet-Cricov ' },
            { name: 'Gura Vadului ' },
            { name: 'Gura Vitioarei ' },
            { name: 'Iordăcheanu ', simpleName: 'Iordacheanu ' },
            { name: 'Izvoarele ' },
            { name: 'Jugureni ' },
            { name: 'Lapoş ', simpleName: 'Lapos ' },
            { name: 'Lipăneşti ', simpleName: 'Lipanesti ' },
            { name: 'Mizil ' },
            { name: 'Măgurele ', simpleName: 'Magurele ' },
            { name: 'Măgureni ', simpleName: 'Magureni ' },
            { name: 'Măneşti ', simpleName: 'Manesti ' },
            { name: 'Ploieşti ', simpleName: 'Ploiesti ' },
            { name: 'Plopeni ' },
            { name: 'Plopu ' },
            { name: 'Podenii Noi ' },
            { name: 'Poiana Câmpina ', simpleName: 'Poiana Campina ' },
            { name: 'Poienarii Burchii ' },
            { name: 'Proviţa de Jos ', simpleName: 'Provita de Jos ' },
            { name: 'Proviţa de Sus ', simpleName: 'Provita de Sus ' },
            { name: 'Puchenii Mari ' },
            { name: 'Păcureţi ', simpleName: 'Pacureti ' },
            { name: 'Păuleşti ', simpleName: 'Paulesti ' },
            { name: 'Râfov ', simpleName: 'Rafov ' },
            { name: 'Salcia ' },
            { name: 'Scorţeni ', simpleName: 'Scorteni ' },
            { name: 'Secăria ', simpleName: 'Secaria ' },
            { name: 'Sinaia ' },
            { name: 'Slănic ', simpleName: 'Slanic ' },
            { name: 'Starchiojd ' },
            { name: 'Surani ' },
            { name: 'Sângeru ', simpleName: 'Sangeru ' },
            { name: 'Sălciile ', simpleName: 'Salciile ' },
            { name: 'Talea ' },
            { name: 'Teişani ', simpleName: 'Teisani ' },
            { name: 'Telega ' },
            { name: 'Tinosu ' },
            { name: 'Tomşani ', simpleName: 'Tomsani ' },
            { name: 'Târgşoru Vechi ', simpleName: 'Targsoru Vechi ' },
            { name: 'Tătaru ', simpleName: 'Tataru ' },
            { name: 'Urlaţi ', simpleName: 'Urlati ' },
            { name: 'Valea Călugărească ', simpleName: 'Valea Calugareasca ' },
            { name: 'Vâlcăneşti ', simpleName: 'Valcanesti ' },
            { name: 'Vălenii de Munte ', simpleName: 'Valenii de Munte ' },
            { name: 'Vărbilău ', simpleName: 'Varbilau ' },
            { name: 'Şirna ', simpleName: 'Sirna ' },
            { name: 'Şoimari ', simpleName: 'Soimari ' },
            { name: 'Şotrile ', simpleName: 'Sotrile ' },
            { name: 'Ştefeşti ', simpleName: 'Stefesti ' },
            { name: 'Ocina de Jos ', village: 'Adunati ' },
            { name: 'Ocina de Sus ', village: 'Adunati ' },
            { name: 'Albeşti-Muru ', simpleName: 'Albesti-Muru ', village: 'Albesti-Paleologu ' },
            { name: 'Cioceni ', village: 'Albesti-Paleologu ' },
            { name: 'Vadu Părului ', simpleName: 'Vadu Parului ', village: 'Albesti-Paleologu ' },
            { name: 'Ostrovu ', village: 'Alunis ' },
            { name: 'Buzota ', village: 'Apostolache ' },
            { name: 'Mârlogea ', simpleName: 'Marlogea ', village: 'Apostolache ' },
            { name: 'Udreşti ', simpleName: 'Udresti ', village: 'Apostolache ' },
            { name: 'Valea Cricovului ', village: 'Apostolache ' },
            { name: 'Buda ', village: 'Aricestii Rahtivani ' },
            { name: 'Nedelea ', village: 'Aricestii Rahtivani ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ', village: 'Aricestii Rahtivani ' },
            { name: 'Târgşoru Nou ', simpleName: 'Targsoru Nou ', village: 'Aricestii Rahtivani ' },
            { name: 'Albinari ', village: 'Aricestii Zeletin ' },
            { name: 'Cireşanu ', simpleName: 'Ciresanu ', village: 'Baba Ana ' },
            { name: 'Conduratu ', village: 'Baba Ana ' },
            { name: 'Crângurile ', simpleName: 'Crangurile ', village: 'Baba Ana ' },
            { name: 'Satu Nou ', village: 'Baba Ana ' },
            { name: 'Dâmbu ', simpleName: 'Dambu ', village: 'Baicoi ' },
            { name: 'Lilieşti ', simpleName: 'Liliesti ', village: 'Baicoi ' },
            { name: 'Schela ', village: 'Baicoi ' },
            { name: 'Tufeni ', village: 'Baicoi ' },
            { name: 'Ţintea ', simpleName: 'Tintea ', village: 'Baicoi ' },
            { name: 'Bâra ', simpleName: 'Bara ', village: 'Balta Doamnei ' },
            { name: 'Curcubeu ', village: 'Balta Doamnei ' },
            { name: 'Lacu Turcului ', village: 'Balta Doamnei ' },
            { name: 'Izeşti ', simpleName: 'Izesti ', village: 'Baltesti ' },
            { name: 'Podenii Vechi ', village: 'Baltesti ' },
            { name: 'Urleta ', village: 'Banesti ' },
            { name: 'Ghighiu ', village: 'Barcanesti ' },
            { name: 'Puşcaşi ', simpleName: 'Puscasi ', village: 'Barcanesti ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Barcanesti ' },
            { name: 'Tătărani ', simpleName: 'Tatarani ', village: 'Barcanesti ' },
            { name: 'Cartierul Dâmbu ', simpleName: 'Cartierul Dambu ', village: 'Berceni ' },
            { name: 'Corlăteşti ', simpleName: 'Corlatesti ', village: 'Berceni ' },
            { name: 'Cătunu ', simpleName: 'Catunu ', village: 'Berceni ' },
            { name: 'Moara Nouă ', simpleName: 'Moara Noua ', village: 'Berceni ' },
            { name: 'Lutu Roşu ', simpleName: 'Lutu Rosu ', village: 'Bertea ' },
            { name: 'Ploieştiori ', simpleName: 'Ploiestiori ', village: 'Blejoi ' },
            { name: 'Ţânţăreni ', simpleName: 'Tantareni ', village: 'Blejoi ' },
            { name: 'Boldeşti ', simpleName: 'Boldesti ', village: 'Boldesti-Gradistea ' },
            { name: 'Gradiştea ', simpleName: 'Gradistea ', village: 'Boldesti-Gradistea ' },
            { name: 'Seciu ', village: 'Boldesti-Scaeni ' },
            { name: 'Brazii de Jos ', village: 'Brazi ' },
            { name: 'Brazii de Sus ', village: 'Brazi ' },
            { name: 'Băteşti ', simpleName: 'Batesti ', village: 'Brazi ' },
            { name: 'Negoieşti ', simpleName: 'Negoiesti ', village: 'Brazi ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Brazi ' },
            { name: 'Stejaru ', village: 'Brazi ' },
            { name: 'Breaza de Jos ', village: 'Breaza ' },
            { name: 'Breaza de Sus ', village: 'Breaza ' },
            { name: 'Frăsinet ', simpleName: 'Frasinet ', village: 'Breaza ' },
            { name: 'Gura Beliei ', village: 'Breaza ' },
            { name: 'Irimeşti ', simpleName: 'Irimesti ', village: 'Breaza ' },
            { name: 'Nistoreşti ', simpleName: 'Nistoresti ', village: 'Breaza ' },
            { name: 'Podu Corbului ', village: 'Breaza ' },
            { name: 'Podu Vadului ', village: 'Breaza ' },
            { name: 'Surdeşti ', simpleName: 'Surdesti ', village: 'Breaza ' },
            { name: 'Valea Târsei ', simpleName: 'Valea Tarsei ', village: 'Breaza ' },
            { name: 'Brebu Megieşesc ', simpleName: 'Brebu Megiesesc ', village: 'Brebu ' },
            { name: 'Brebu Mânăstirei ', simpleName: 'Brebu Manastirei ', village: 'Brebu ' },
            { name: 'Pietriceaua ', village: 'Brebu ' },
            { name: 'Podu Cheii ', village: 'Brebu ' },
            { name: 'Bighilin ', village: 'Bucov ' },
            { name: 'Chiţorani ', simpleName: 'Chitorani ', village: 'Bucov ' },
            { name: 'Pleaşa ', simpleName: 'Pleasa ', village: 'Bucov ' },
            { name: 'Valea Orlei ', village: 'Bucov ' },
            { name: 'Poiana Ţapului ', simpleName: 'Poiana Tapului ', village: 'Busteni ' },
            { name: 'Valea Scheilor ', village: 'Calugareni ' },
            { name: 'Gogeasca ', village: 'Carbunesti ' },
            { name: 'Ceptura de Jos ', village: 'Ceptura ' },
            { name: 'Ceptura de Sus ', village: 'Ceptura ' },
            { name: 'Malu Roşu ', simpleName: 'Malu Rosu ', village: 'Ceptura ' },
            { name: 'Rotari ', village: 'Ceptura ' },
            { name: 'Şoimeşti ', simpleName: 'Soimesti ', village: 'Ceptura ' },
            { name: 'Slon ', village: 'Cerasu ' },
            { name: 'Valea Borului ', village: 'Cerasu ' },
            { name: 'Valea Brădetului ', simpleName: 'Valea Bradetului ', village: 'Cerasu ' },
            { name: 'Valea Lespezii ', village: 'Cerasu ' },
            { name: 'Valea Tocii ', village: 'Cerasu ' },
            { name: 'Nucet ', village: 'Chiojdeanca ' },
            { name: 'Trenu ', village: 'Chiojdeanca ' },
            { name: 'Cioranii de Jos ', village: 'Ciorani ' },
            { name: 'Cioranii de Sus ', village: 'Ciorani ' },
            { name: 'Goruna ', village: 'Cocorastii Mislii ' },
            { name: 'Ţipăreşti ', simpleName: 'Tiparesti ', village: 'Cocorastii Mislii ' },
            { name: 'Inoteşti ', simpleName: 'Inotesti ', village: 'Colceag ' },
            { name: 'Parepa-Ruşani ', simpleName: 'Parepa-Rusani ', village: 'Colceag ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Colceag ' },
            { name: 'Ghioşeşti ', simpleName: 'Ghiosesti ', village: 'Comarnic ' },
            { name: 'Podu Lung ', village: 'Comarnic ' },
            { name: 'Poiana ', village: 'Comarnic ' },
            { name: 'Posada ', village: 'Comarnic ' },
            { name: 'Cornu de Jos ', village: 'Cornu ' },
            { name: 'Cornu de Sus ', village: 'Cornu ' },
            { name: 'Valea Oprii ', village: 'Cornu ' },
            { name: 'Cosmina de Jos ', village: 'Cosminele ' },
            { name: 'Cosmina de Sus ', village: 'Cosminele ' },
            { name: 'Drăghiceşti ', simpleName: 'Draghicesti ', village: 'Cosminele ' },
            { name: 'Poiana Trestiei ', village: 'Cosminele ' },
            { name: 'Belciug ', village: 'Draganesti ' },
            { name: 'Bărăitaru ', simpleName: 'Baraitaru ', village: 'Draganesti ' },
            { name: 'Cornu de Jos ', village: 'Draganesti ' },
            { name: 'Hătcărău ', simpleName: 'Hatcarau ', village: 'Draganesti ' },
            { name: 'Meri ', village: 'Draganesti ' },
            { name: 'Tufani ', village: 'Draganesti ' },
            { name: 'Ciocrac ', village: 'Drajna ' },
            { name: 'Cătunu ', simpleName: 'Catunu ', village: 'Drajna ' },
            { name: 'Drajna de Jos ', village: 'Drajna ' },
            { name: 'Drajna de Sus ', village: 'Drajna ' },
            { name: 'Făget ', simpleName: 'Faget ', village: 'Drajna ' },
            { name: 'Ogretin ', village: 'Drajna ' },
            { name: 'Piatra ', village: 'Drajna ' },
            { name: 'Piţigoi ', simpleName: 'Pitigoi ', village: 'Drajna ' },
            { name: 'Plai ', village: 'Drajna ' },
            { name: 'Podurile ', village: 'Drajna ' },
            { name: 'Poiana Mierlei ', village: 'Drajna ' },
            { name: 'Ciupelniţa ', simpleName: 'Ciupelnita ', village: 'Dumbrava ' },
            { name: 'Cornu de Sus ', village: 'Dumbrava ' },
            { name: 'Trestienii de Jos ', village: 'Dumbrava ' },
            { name: 'Trestienii de Sus ', village: 'Dumbrava ' },
            { name: 'Zănoaga ', simpleName: 'Zanoaga ', village: 'Dumbrava ' },
            { name: 'Găvănel ', simpleName: 'Gavanel ', village: 'Dumbravesti ' },
            { name: 'Mălăeştii de Jos ', simpleName: 'Malaestii de Jos ', village: 'Dumbravesti ' },
            { name: 'Mălăeştii de Sus ', simpleName: 'Malaestii de Sus ', village: 'Dumbravesti ' },
            { name: 'Plopeni ', village: 'Dumbravesti ' },
            { name: 'Sfârleanca ', simpleName: 'Sfarleanca ', village: 'Dumbravesti ' },
            { name: 'Bozieni ', village: 'Fantanele ' },
            { name: 'Ghinoaica ', village: 'Fantanele ' },
            { name: 'Ungureni ', village: 'Fantanele ' },
            { name: 'Vadu Săpat ', simpleName: 'Vadu Sapat ', village: 'Fantanele ' },
            { name: 'Diţeşti ', simpleName: 'Ditesti ', village: 'Filipestii De Padure ' },
            { name: 'Minieri ', village: 'Filipestii De Padure ' },
            { name: 'Siliştea Dealului ', simpleName: 'Silistea Dealului ', village: 'Filipestii De Padure ' },
            { name: 'Brătăşanca ', simpleName: 'Bratasanca ', village: 'Filipestii De Targ ' },
            { name: 'Ezeni ', village: 'Filipestii De Targ ' },
            { name: 'Mărginenii de Jos ', simpleName: 'Marginenii de Jos ', village: 'Filipestii De Targ ' },
            { name: 'Ungureni ', village: 'Filipestii De Targ ' },
            { name: 'Cap Roşu ', simpleName: 'Cap Rosu ', village: 'Floresti ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Floresti ' },
            { name: 'Cătina ', simpleName: 'Catina ', village: 'Floresti ' },
            { name: 'Novăceşti ', simpleName: 'Novacesti ', village: 'Floresti ' },
            { name: 'Fulga de Jos ', village: 'Fulga ' },
            { name: 'Fulga de Sus ', village: 'Fulga ' },
            { name: 'Fânari ', simpleName: 'Fanari ', village: 'Gherghita ' },
            { name: 'Independenţa ', simpleName: 'Independenta ', village: 'Gherghita ' },
            { name: 'Malamuc ', village: 'Gherghita ' },
            { name: 'Olari ', village: 'Gherghita ' },
            { name: 'Olarii Vechi ', village: 'Gherghita ' },
            { name: 'Ungureni ', village: 'Gherghita ' },
            { name: 'Crivina ', village: 'Gorgota ' },
            { name: 'Fânari ', simpleName: 'Fanari ', village: 'Gorgota ' },
            { name: 'Poienarii Apostoli ', village: 'Gorgota ' },
            { name: 'Potigrafu ', village: 'Gorgota ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Gornet ' },
            { name: 'Cuib ', village: 'Gornet ' },
            { name: 'Nucet ', village: 'Gornet ' },
            { name: 'Coşerele ', simpleName: 'Coserele ', village: 'Gornet-Cricov ' },
            { name: 'Dobrota ', village: 'Gornet-Cricov ' },
            { name: 'Priseaca ', village: 'Gornet-Cricov ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Gornet-Cricov ' },
            { name: 'Ţărculeşti ', simpleName: 'Tarculesti ', village: 'Gornet-Cricov ' },
            { name: 'Perşunari ', simpleName: 'Persunari ', village: 'Gura Vadului ' },
            { name: 'Tohani ', village: 'Gura Vadului ' },
            { name: 'Bughea de Jos ', village: 'Gura Vitioarei ' },
            { name: 'Fundeni ', village: 'Gura Vitioarei ' },
            { name: 'Făgetu ', simpleName: 'Fagetu ', village: 'Gura Vitioarei ' },
            { name: 'Poiana Copăceni ', simpleName: 'Poiana Copaceni ', village: 'Gura Vitioarei ' },
            { name: 'Moceşti ', simpleName: 'Mocesti ', village: 'Iordacheanu ' },
            { name: 'Plavia ', village: 'Iordacheanu ' },
            { name: 'Străoşti ', simpleName: 'Straosti ', village: 'Iordacheanu ' },
            { name: 'Valea Cucului ', village: 'Iordacheanu ' },
            { name: 'Vărbila ', simpleName: 'Varbila ', village: 'Iordacheanu ' },
            { name: 'Cerneşti ', simpleName: 'Cernesti ', village: 'Izvoarele ' },
            { name: 'Chiriţeşti ', simpleName: 'Chiritesti ', village: 'Izvoarele ' },
            { name: 'Homorâciu ', simpleName: 'Homoraciu ', village: 'Izvoarele ' },
            { name: 'Malu Vânăt ', simpleName: 'Malu Vanat ', village: 'Izvoarele ' },
            { name: 'Schiuleşti ', simpleName: 'Schiulesti ', village: 'Izvoarele ' },
            { name: 'Boboci ', village: 'Jugureni ' },
            { name: 'Marginea Pădurii ', simpleName: 'Marginea Padurii ', village: 'Jugureni ' },
            { name: 'Valea Unghiului ', village: 'Jugureni ' },
            { name: 'Glod ', village: 'Lapos ' },
            { name: 'Lăpoşel ', simpleName: 'Laposel ', village: 'Lapos ' },
            { name: 'Pietricica ', village: 'Lapos ' },
            { name: 'Satu Nou ', village: 'Lipanesti ' },
            { name: 'Zamfira ', village: 'Lipanesti ' },
            { name: 'Şipotu ', simpleName: 'Sipotu ', village: 'Lipanesti ' },
            { name: 'Coada Malului ', village: 'Magurele ' },
            { name: 'Iazu ', village: 'Magurele ' },
            { name: 'Cocorăştii Caplii ', simpleName: 'Cocorastii Caplii ', village: 'Magureni ' },
            { name: 'Lunca Prahovei ', village: 'Magureni ' },
            { name: 'Cheia ', village: 'Maneciu ' },
            { name: 'Chiciureni ', village: 'Maneciu ' },
            { name: 'Costeni ', village: 'Maneciu ' },
            { name: 'Făcăieni ', simpleName: 'Facaieni ', village: 'Maneciu ' },
            { name: 'Gheaba ', village: 'Maneciu ' },
            { name: 'Mânăstirea Suzana ', simpleName: 'Manastirea Suzana ', village: 'Maneciu ' },
            { name: 'Măneciu-Pământeni ', simpleName: 'Maneciu-Pamanteni ', village: 'Maneciu ' },
            { name: 'Măneciu-Ungureni ', simpleName: 'Maneciu-Ungureni ', village: 'Maneciu ' },
            { name: 'Plăieţu ', simpleName: 'Plaietu ', village: 'Maneciu ' },
            { name: 'Băltiţa ', simpleName: 'Baltita ', village: 'Manesti ' },
            { name: 'Cheşnoiu ', simpleName: 'Chesnoiu ', village: 'Manesti ' },
            { name: 'Coada Izvorului ', village: 'Manesti ' },
            { name: 'Cocorăştii Colţ ', simpleName: 'Cocorastii Colt ', village: 'Manesti ' },
            { name: 'Cocorăştii Grind ', simpleName: 'Cocorastii Grind ', village: 'Manesti ' },
            { name: 'Colţu de Jos ', simpleName: 'Coltu de Jos ', village: 'Manesti ' },
            { name: 'Ghioldum ', village: 'Manesti ' },
            { name: 'Gura Crivăţului ', simpleName: 'Gura Crivatului ', village: 'Manesti ' },
            { name: 'Perşunari ', simpleName: 'Persunari ', village: 'Manesti ' },
            { name: 'Piatra ', village: 'Manesti ' },
            { name: 'Satu de Sus ', village: 'Manesti ' },
            { name: 'Zalhanaua ', village: 'Manesti ' },
            { name: 'Fefelei ', village: 'Mizil ' },
            { name: 'Bărzila ', simpleName: 'Barzila ', village: 'Pacureti ' },
            { name: 'Curmătura ', simpleName: 'Curmatura ', village: 'Pacureti ' },
            { name: 'Matiţa ', simpleName: 'Matita ', village: 'Pacureti ' },
            { name: 'Slavu ', village: 'Pacureti ' },
            { name: 'Cocoşeşti ', simpleName: 'Cocosesti ', village: 'Paulesti ' },
            { name: 'Găgeni ', simpleName: 'Gageni ', village: 'Paulesti ' },
            { name: 'Păuleştii Noi ', simpleName: 'Paulestii Noi ', village: 'Paulesti ' },
            { name: 'Gâlmeia ', simpleName: 'Galmeia ', village: 'Plopu ' },
            { name: 'Hârşa ', simpleName: 'Harsa ', village: 'Plopu ' },
            { name: 'Nisipoasa ', village: 'Plopu ' },
            { name: 'Ghiocel ', village: 'Podenii Noi ' },
            { name: 'Mehedinţa ', simpleName: 'Mehedinta ', village: 'Podenii Noi ' },
            { name: 'Nevesteasca ', village: 'Podenii Noi ' },
            { name: 'Podu lui Galben ', village: 'Podenii Noi ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Podenii Noi ' },
            { name: 'Rahova ', village: 'Podenii Noi ' },
            { name: 'Sfăcăru ', simpleName: 'Sfacaru ', village: 'Podenii Noi ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ', village: 'Podenii Noi ' },
            { name: 'Valea Dulce ', village: 'Podenii Noi ' },
            { name: 'Bobolia ', village: 'Poiana Campina ' },
            { name: 'Pietrişu ', simpleName: 'Pietrisu ', village: 'Poiana Campina ' },
            { name: 'Răgman ', simpleName: 'Ragman ', village: 'Poiana Campina ' },
            { name: 'Cărbunari ', simpleName: 'Carbunari ', village: 'Poienarii Burchii ' },
            { name: 'Ologeni ', village: 'Poienarii Burchii ' },
            { name: 'Pioreşti ', simpleName: 'Pioresti ', village: 'Poienarii Burchii ' },
            { name: 'Podu Văleni ', simpleName: 'Podu Valeni ', village: 'Poienarii Burchii ' },
            { name: 'Poienarii Rali ', village: 'Poienarii Burchii ' },
            { name: 'Poienarii Vechi ', village: 'Poienarii Burchii ' },
            { name: 'Tătărăi ', simpleName: 'Tatarai ', village: 'Poienarii Burchii ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ', village: 'Posesti ' },
            { name: 'Merdeala ', village: 'Posesti ' },
            { name: 'Nucşoara de Jos ', simpleName: 'Nucsoara de Jos ', village: 'Posesti ' },
            { name: 'Nucşoara de Sus ', simpleName: 'Nucsoara de Sus ', village: 'Posesti ' },
            { name: 'Poseştii Ungureni ', simpleName: 'Posestii Ungureni ', village: 'Posesti ' },
            { name: 'Poseştii-Pământeni ', simpleName: 'Posestii-Pamanteni ', village: 'Posesti ' },
            { name: 'Târleşti ', simpleName: 'Tarlesti ', village: 'Posesti ' },
            { name: 'Valea Plopului ', village: 'Posesti ' },
            { name: 'Valea Screzii ', village: 'Posesti ' },
            { name: 'Valea Stupinii ', village: 'Posesti ' },
            { name: 'Bobiceşti ', simpleName: 'Bobicesti ', village: 'Predeal-Sarari ' },
            { name: 'Poienile ', village: 'Predeal-Sarari ' },
            { name: 'Predeal ', village: 'Predeal-Sarari ' },
            { name: 'Sărari ', simpleName: 'Sarari ', village: 'Predeal-Sarari ' },
            { name: 'Sărăţel ', simpleName: 'Saratel ', village: 'Predeal-Sarari ' },
            { name: 'Tulburea ', village: 'Predeal-Sarari ' },
            { name: 'Tulburea Văleni ', simpleName: 'Tulburea Valeni ', village: 'Predeal-Sarari ' },
            { name: 'Vitioara de Sus ', village: 'Predeal-Sarari ' },
            { name: 'Zâmbroaia ', simpleName: 'Zambroaia ', village: 'Predeal-Sarari ' },
            { name: 'Drăgăneasa ', simpleName: 'Draganeasa ', village: 'Provita De Jos ' },
            { name: 'Piatra ', village: 'Provita De Jos ' },
            { name: 'Izvoru ', village: 'Provita De Sus ' },
            { name: 'Plaiu ', village: 'Provita De Sus ' },
            { name: 'Valea Bradului ', village: 'Provita De Sus ' },
            { name: 'Miroslăveşti ', simpleName: 'Miroslavesti ', village: 'Puchenii Mari ' },
            { name: 'Moara ', village: 'Puchenii Mari ' },
            { name: 'Odăile ', simpleName: 'Odaile ', village: 'Puchenii Mari ' },
            { name: 'Pietroşani ', simpleName: 'Pietrosani ', village: 'Puchenii Mari ' },
            { name: 'Puchenii Mici ', village: 'Puchenii Mari ' },
            { name: 'Puchenii Moşneni ', simpleName: 'Puchenii Mosneni ', village: 'Puchenii Mari ' },
            { name: 'Antofiloaia ', village: 'Rafov ' },
            { name: 'Buchilaşi ', simpleName: 'Buchilasi ', village: 'Rafov ' },
            { name: 'Buda ', village: 'Rafov ' },
            { name: 'Goga ', village: 'Rafov ' },
            { name: 'Moara Domnească ', simpleName: 'Moara Domneasca ', village: 'Rafov ' },
            { name: 'Mălăieşti ', simpleName: 'Malaiesti ', village: 'Rafov ' },
            { name: 'Palanca ', village: 'Rafov ' },
            { name: 'Sicrita ', village: 'Rafov ' },
            { name: 'Butuci ', village: 'Sangeru ' },
            { name: 'Mireşu Mare ', simpleName: 'Miresu Mare ', village: 'Sangeru ' },
            { name: 'Mireşu Mic ', simpleName: 'Miresu Mic ', village: 'Sangeru ' },
            { name: 'Piatra Mică ', simpleName: 'Piatra Mica ', village: 'Sangeru ' },
            { name: 'Tisa ', village: 'Sangeru ' },
            { name: 'Bordenii Mari ', village: 'Scorteni ' },
            { name: 'Bordenii Mici ', village: 'Scorteni ' },
            { name: 'Mislea ', village: 'Scorteni ' },
            { name: 'Sârca ', simpleName: 'Sarca ', village: 'Scorteni ' },
            { name: 'Brăteşti ', simpleName: 'Bratesti ', village: 'Sirna ' },
            { name: 'Coceana ', village: 'Sirna ' },
            { name: 'Hăbud ', simpleName: 'Habud ', village: 'Sirna ' },
            { name: 'Tăriceni ', simpleName: 'Tariceni ', village: 'Sirna ' },
            { name: 'Varniţa ', simpleName: 'Varnita ', village: 'Sirna ' },
            { name: 'Groşani ', simpleName: 'Grosani ', village: 'Slanic ' },
            { name: 'Prăjani ', simpleName: 'Prajani ', village: 'Slanic ' },
            { name: 'Lopatniţa ', simpleName: 'Lopatnita ', village: 'Soimari ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Soimari ' },
            { name: 'Lunca Mare ', village: 'Sotrile ' },
            { name: 'Plaiu Cornului ', village: 'Sotrile ' },
            { name: 'Plaiu Câmpinei ', simpleName: 'Plaiu Campinei ', village: 'Sotrile ' },
            { name: 'Seciuri ', village: 'Sotrile ' },
            { name: 'Vistieru ', village: 'Sotrile ' },
            { name: 'Brădet ', simpleName: 'Bradet ', village: 'Starchiojd ' },
            { name: 'Bătrâni ', simpleName: 'Batrani ', village: 'Starchiojd ' },
            { name: 'Gresia ', village: 'Starchiojd ' },
            { name: 'Poiana Mare ', village: 'Starchiojd ' },
            { name: 'Rotarea ', village: 'Starchiojd ' },
            { name: 'Valea Anei ', village: 'Starchiojd ' },
            { name: 'Zmeuret ', village: 'Starchiojd ' },
            { name: 'Scurteşti ', simpleName: 'Scurtesti ', village: 'Stefesti ' },
            { name: 'Târşoreni ', simpleName: 'Tarsoreni ', village: 'Stefesti ' },
            { name: 'Păcuri ', simpleName: 'Pacuri ', village: 'Surani ' },
            { name: 'Plaiu ', village: 'Talea ' },
            { name: 'Strejnicu ', village: 'Targsoru Vechi ' },
            { name: 'Stănceşti ', simpleName: 'Stancesti ', village: 'Targsoru Vechi ' },
            { name: 'Zahanaua ', village: 'Targsoru Vechi ' },
            { name: 'Podgoria ', village: 'Tataru ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Tataru ' },
            { name: 'Bugnea de Sus ', village: 'Teisani ' },
            { name: 'Olteni ', village: 'Teisani ' },
            { name: 'Valea Stâlpului ', simpleName: 'Valea Stalpului ', village: 'Teisani ' },
            { name: 'Ştubeiu ', simpleName: 'Stubeiu ', village: 'Teisani ' },
            { name: 'Boşilceşti ', simpleName: 'Bosilcesti ', village: 'Telega ' },
            { name: 'Buştenari ', simpleName: 'Bustenari ', village: 'Telega ' },
            { name: 'Doftana ', village: 'Telega ' },
            { name: 'Meliceşti ', simpleName: 'Melicesti ', village: 'Telega ' },
            { name: 'Ţonţeşti ', simpleName: 'Tontesti ', village: 'Telega ' },
            { name: 'Pisculeşti ', simpleName: 'Pisculesti ', village: 'Tinosu ' },
            { name: 'Predeşti ', simpleName: 'Predesti ', village: 'Tinosu ' },
            { name: 'Loloiasca ', village: 'Tomsani ' },
            { name: 'Magula ', village: 'Tomsani ' },
            { name: 'Sătucu ', simpleName: 'Satucu ', village: 'Tomsani ' },
            { name: 'Arioneştii Noi ', simpleName: 'Arionestii Noi ', village: 'Urlati ' },
            { name: 'Arioneştii Vechi ', simpleName: 'Arionestii Vechi ', village: 'Urlati ' },
            { name: 'Cherba ', village: 'Urlati ' },
            { name: 'Jercălăi ', simpleName: 'Jercalai ', village: 'Urlati ' },
            { name: 'Mărunţiş ', simpleName: 'Maruntis ', village: 'Urlati ' },
            { name: 'Orzoaia de Jos ', village: 'Urlati ' },
            { name: 'Orzoaia de Sus ', village: 'Urlati ' },
            { name: 'Schiau ', village: 'Urlati ' },
            { name: 'Ulmi ', village: 'Urlati ' },
            { name: 'Valea Bobului ', village: 'Urlati ' },
            { name: 'Valea Crângului ', simpleName: 'Valea Crangului ', village: 'Urlati ' },
            { name: 'Valea Mieilor ', village: 'Urlati ' },
            { name: 'Valea Nucetului ', village: 'Urlati ' },
            { name: 'Valea Pietrei ', village: 'Urlati ' },
            { name: 'Valea Seman ', village: 'Urlati ' },
            { name: 'Valea Urloii ', village: 'Urlati ' },
            { name: 'Cârjari ', simpleName: 'Carjari ', village: 'Valcanesti ' },
            { name: 'Trestioara ', village: 'Valcanesti ' },
            { name: 'Arva ', village: 'Valea Calugareasca ' },
            { name: 'Coslegi ', village: 'Valea Calugareasca ' },
            { name: 'Dârvari ', simpleName: 'Darvari ', village: 'Valea Calugareasca ' },
            { name: 'Pantazi ', village: 'Valea Calugareasca ' },
            { name: 'Rachieri ', village: 'Valea Calugareasca ' },
            { name: 'Radila ', village: 'Valea Calugareasca ' },
            { name: 'Schiau ', village: 'Valea Calugareasca ' },
            { name: 'Valea Largă ', simpleName: 'Valea Larga ', village: 'Valea Calugareasca ' },
            { name: 'Valea Mantei ', village: 'Valea Calugareasca ' },
            { name: 'Valea Nicovani ', village: 'Valea Calugareasca ' },
            { name: 'Valea Poienii ', village: 'Valea Calugareasca ' },
            { name: 'Valea Popii ', village: 'Valea Calugareasca ' },
            { name: 'Valea Ursoii ', village: 'Valea Calugareasca ' },
            { name: 'Vârfurile ', simpleName: 'Varfurile ', village: 'Valea Calugareasca ' },
            { name: 'Teşila ', simpleName: 'Tesila ', village: 'Valea Doftanei ' },
            { name: 'Trăisteni ', simpleName: 'Traisteni ', village: 'Valea Doftanei ' },
            { name: 'Coţofeneşti ', simpleName: 'Cotofenesti ', village: 'Varbilau ' },
            { name: 'Livadea ', village: 'Varbilau ' },
            { name: 'Podu Ursului ', village: 'Varbilau ' },
            {
                name: 'Poiana Vărbilău ', simpleName: 'Poiana Varbilau ', village: 'Varbilau '
            }
        ]
    },
    {
        auto: 'SJ ',
        name: 'Sălaj ',
        cities: [
            { name: 'Agrij ' },
            { name: 'Almaşu ', simpleName: 'Almasu ' },
            { name: 'Benesat ' },
            { name: 'Bobota ' },
            { name: 'Bocşa ', simpleName: 'Bocsa ' },
            { name: 'Buciumi ' },
            { name: 'Băbeni ', simpleName: 'Babeni ' },
            { name: 'Bălan ', simpleName: 'Balan ' },
            { name: 'Bănişor ', simpleName: 'Banisor ' },
            { name: 'Camăr ', simpleName: 'Camar ' },
            { name: 'Carastelec ' },
            { name: 'Cehu Silvaniei ' },
            { name: 'Chieşd ', simpleName: 'Chiesd ' },
            { name: 'Cizer ' },
            { name: 'Coşeiu ', simpleName: 'Coseiu ' },
            { name: 'Crasna ' },
            { name: 'Creaca ' },
            { name: 'Cristolţ ', simpleName: 'Cristolt ' },
            { name: 'Crişeni ', simpleName: 'Criseni ' },
            { name: 'Cuzăplac ', simpleName: 'Cuzaplac ' },
            { name: 'Dobrin ' },
            { name: 'Dragu ' },
            { name: 'Fildu de Jos ' },
            { name: 'Gâlgău ', simpleName: 'Galgau ' },
            { name: 'Gârbou ', simpleName: 'Garbou ' },
            { name: 'Halmăşd ', simpleName: 'Halmasd ' },
            { name: 'Hereclean ' },
            { name: 'Hida ' },
            { name: 'Horoatu Crasnei ' },
            { name: 'Ileanda ' },
            { name: 'Ip ' },
            { name: 'Jibou ' },
            { name: 'Letca ' },
            { name: 'Lozna ' },
            { name: 'Marca ' },
            { name: 'Meseşenii de Jos ', simpleName: 'Mesesenii de Jos ' },
            { name: 'Mirşid ', simpleName: 'Mirsid ' },
            { name: 'Măerişte ', simpleName: 'Maeriste ' },
            { name: 'Nuşfalău ', simpleName: 'Nusfalau ' },
            { name: 'Năpradea ', simpleName: 'Napradea ' },
            { name: 'Pericei ' },
            { name: 'Plopiş ', simpleName: 'Plopis ' },
            { name: 'Poiana Blenchii ' },
            { name: 'Românaşi ', simpleName: 'Romanasi ' },
            { name: 'Rus ' },
            { name: 'Someş-Odorhei ', simpleName: 'Somes-Odorhei ' },
            { name: 'Surduc ' },
            { name: 'Sâg ', simpleName: 'Sag ' },
            { name: 'Sânmihaiu Almaşului ', simpleName: 'Sanmihaiu Almasului ' },
            { name: 'Sălăţig ', simpleName: 'Salatig ' },
            { name: 'Treznea ' },
            { name: 'Valcău de Jos ', simpleName: 'Valcau de Jos ' },
            { name: 'Vârşolţ ', simpleName: 'Varsolt ' },
            { name: 'Zalha ' },
            { name: 'Zalău ', simpleName: 'Zalau ' },
            { name: 'Zimbor ' },
            { name: 'Şamşud ', simpleName: 'Samsud ' },
            { name: 'Şimişna ', simpleName: 'Simisna ' },
            { name: 'Şimleu Silvaniei ', simpleName: 'Simleu Silvaniei ' },
            { name: 'Şărmăşag ', simpleName: 'Sarmasag ' },
            { name: 'Răstolţu Deşert ', simpleName: 'Rastoltu Desert ', village: 'Agrij ' },
            { name: 'Băbiu ', simpleName: 'Babiu ', village: 'Almasu ' },
            { name: 'Cutiş ', simpleName: 'Cutis ', village: 'Almasu ' },
            { name: 'Jebucu ', village: 'Almasu ' },
            { name: 'Mesteacănu ', simpleName: 'Mesteacanu ', village: 'Almasu ' },
            { name: 'Petrinzel ', village: 'Almasu ' },
            { name: 'Sfăraş ', simpleName: 'Sfaras ', village: 'Almasu ' },
            { name: 'Stana ', village: 'Almasu ' },
            { name: 'Ţăudu ', simpleName: 'Taudu ', village: 'Almasu ' },
            { name: 'Ciocmani ', village: 'Babeni ' },
            { name: 'Cliţ ', simpleName: 'Clit ', village: 'Babeni ' },
            { name: 'Piroşa ', simpleName: 'Pirosa ', village: 'Babeni ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Babeni ' },
            { name: 'Chechiş ', simpleName: 'Chechis ', village: 'Balan ' },
            { name: 'Chendrea ', village: 'Balan ' },
            { name: 'Gâlgău Almaşului ', simpleName: 'Galgau Almasului ', village: 'Balan ' },
            { name: 'Gălpâia ', simpleName: 'Galpaia ', village: 'Balan ' },
            { name: 'Ban ', village: 'Banisor ' },
            { name: 'Peceiu ', village: 'Banisor ' },
            { name: 'Aluniş ', simpleName: 'Alunis ', village: 'Benesat ' },
            { name: 'Biuşa ', simpleName: 'Biusa ', village: 'Benesat ' },
            { name: 'Derşida ', simpleName: 'Dersida ', village: 'Bobota ' },
            { name: 'Zalnoc ', village: 'Bobota ' },
            { name: 'Borla ', village: 'Bocsa ' },
            { name: 'Câmpia ', simpleName: 'Campia ', village: 'Bocsa ' },
            { name: 'Sălăjeni ', simpleName: 'Salajeni ', village: 'Bocsa ' },
            { name: 'Bodia ', village: 'Buciumi ' },
            { name: 'Bogdana ', village: 'Buciumi ' },
            { name: 'Huta ', village: 'Buciumi ' },
            { name: 'Răstolţ ', simpleName: 'Rastolt ', village: 'Buciumi ' },
            { name: 'Sângeorgiu de Meseş ', simpleName: 'Sangeorgiu de Meses ', village: 'Buciumi ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Camar ' },
            { name: 'Dumuslău ', simpleName: 'Dumuslau ', village: 'Carastelec ' },
            { name: 'Horoatu Cehului ', village: 'Cehu Silvaniei ' },
            { name: 'Motiş ', simpleName: 'Motis ', village: 'Cehu Silvaniei ' },
            { name: 'Nadiş ', simpleName: 'Nadis ', village: 'Cehu Silvaniei ' },
            { name: 'Ulciug ', village: 'Cehu Silvaniei ' },
            { name: 'Colonia Sighetu Silvaniei ', village: 'Chiesd ' },
            { name: 'Sighetu Silvaniei ', village: 'Chiesd ' },
            { name: 'Plesca ', village: 'Cizer ' },
            { name: 'Pria ', village: 'Cizer ' },
            { name: 'Archid ', village: 'Coseiu ' },
            { name: 'Chilioara ', village: 'Coseiu ' },
            { name: 'Huseni ', village: 'Crasna ' },
            { name: 'Marin ', village: 'Crasna ' },
            { name: 'Ratin ', village: 'Crasna ' },
            { name: 'Borza ', village: 'Creaca ' },
            { name: 'Brebi ', village: 'Creaca ' },
            { name: 'Brusturi ', village: 'Creaca ' },
            { name: 'Ciglean ', village: 'Creaca ' },
            { name: 'Jac ', village: 'Creaca ' },
            { name: 'Lupoaia ', village: 'Creaca ' },
            { name: 'Prodăneşti ', simpleName: 'Prodanesti ', village: 'Creaca ' },
            { name: 'Viile Jacului ', village: 'Creaca ' },
            { name: 'Cristur Crişeni ', simpleName: 'Cristur Criseni ', village: 'Criseni ' },
            { name: 'Gârceiu ', simpleName: 'Garceiu ', village: 'Criseni ' },
            { name: 'Muncel ', village: 'Cristolt ' },
            { name: 'Poiana Onţii ', simpleName: 'Poiana Ontii ', village: 'Cristolt ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Cristolt ' },
            { name: 'Cubleşu ', simpleName: 'Cublesu ', village: 'Cuzaplac ' },
            { name: 'Gălăşeni ', simpleName: 'Galaseni ', village: 'Cuzaplac ' },
            { name: 'Mierţa ', simpleName: 'Mierta ', village: 'Cuzaplac ' },
            { name: 'Petrindu ', village: 'Cuzaplac ' },
            { name: 'Ruginoasa ', village: 'Cuzaplac ' },
            { name: 'Stoboru ', village: 'Cuzaplac ' },
            { name: 'Tămasa ', simpleName: 'Tamasa ', village: 'Cuzaplac ' },
            { name: 'Deleni ', village: 'Dobrin ' },
            { name: 'Doba ', village: 'Dobrin ' },
            { name: 'Naimon ', village: 'Dobrin ' },
            { name: 'Sâncraiu Silvaniei ', simpleName: 'Sancraiu Silvaniei ', village: 'Dobrin ' },
            { name: 'Verveghiu ', village: 'Dobrin ' },
            { name: 'Adalin ', village: 'Dragu ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Dragu ' },
            { name: 'Ugruţiu ', simpleName: 'Ugrutiu ', village: 'Dragu ' },
            { name: 'Voivodeni ', village: 'Dragu ' },
            { name: 'Fildu de Mijloc ', village: 'Fildu De Jos ' },
            { name: 'Fildu de Sus ', village: 'Fildu De Jos ' },
            { name: 'Tetişu ', simpleName: 'Tetisu ', village: 'Fildu De Jos ' },
            { name: 'Bârsău Mare ', simpleName: 'Barsau Mare ', village: 'Galgau ' },
            { name: 'Chizeni ', village: 'Galgau ' },
            { name: 'Căpâlna ', simpleName: 'Capalna ', village: 'Galgau ' },
            { name: 'Dobrocina ', village: 'Galgau ' },
            { name: 'Fodora ', village: 'Galgau ' },
            { name: 'Frâncenii de Piatră ', simpleName: 'Francenii de Piatra ', village: 'Galgau ' },
            { name: 'Glod ', village: 'Galgau ' },
            { name: 'Gura Vlădesei ', simpleName: 'Gura Vladesei ', village: 'Galgau ' },
            { name: 'Bezded ', village: 'Garbou ' },
            { name: 'Cernuc ', village: 'Garbou ' },
            { name: 'Călacea ', simpleName: 'Calacea ', village: 'Garbou ' },
            { name: 'Fabrica ', village: 'Garbou ' },
            { name: 'Popteleac ', village: 'Garbou ' },
            { name: 'Solomon ', village: 'Garbou ' },
            { name: 'Aleuş ', simpleName: 'Aleus ', village: 'Halmasd ' },
            { name: 'Cerişa ', simpleName: 'Cerisa ', village: 'Halmasd ' },
            { name: 'Drighiu ', village: 'Halmasd ' },
            { name: 'Fufez ', village: 'Halmasd ' },
            { name: 'Badon ', village: 'Hereclean ' },
            { name: 'Bocşiţa ', simpleName: 'Bocsita ', village: 'Hereclean ' },
            { name: 'Dioşod ', simpleName: 'Diosod ', village: 'Hereclean ' },
            { name: 'Guruslău ', simpleName: 'Guruslau ', village: 'Hereclean ' },
            { name: 'Panic ', village: 'Hereclean ' },
            { name: 'Baica ', village: 'Hida ' },
            { name: 'Miluani ', village: 'Hida ' },
            { name: 'Păduriş ', simpleName: 'Paduris ', village: 'Hida ' },
            { name: 'Racâş ', simpleName: 'Racas ', village: 'Hida ' },
            { name: 'Stupini ', village: 'Hida ' },
            { name: 'Sânpetru Almaşului ', simpleName: 'Sanpetru Almasului ', village: 'Hida ' },
            { name: 'Trestia ', village: 'Hida ' },
            { name: 'Hurez ', village: 'Horoatu Crasnei ' },
            { name: 'Stârciu ', simpleName: 'Starciu ', village: 'Horoatu Crasnei ' },
            { name: 'Şeredeiu ', simpleName: 'Seredeiu ', village: 'Horoatu Crasnei ' },
            { name: 'Bizuşa Băi ', simpleName: 'Bizusa Bai ', village: 'Ileanda ' },
            { name: 'Bârsăuţa ', simpleName: 'Barsauta ', village: 'Ileanda ' },
            { name: 'Dolheni ', village: 'Ileanda ' },
            { name: 'Dăbâceni ', simpleName: 'Dabaceni ', village: 'Ileanda ' },
            { name: 'Luminişu ', simpleName: 'Luminisu ', village: 'Ileanda ' },
            { name: 'Măleni ', simpleName: 'Maleni ', village: 'Ileanda ' },
            { name: 'Negreni ', village: 'Ileanda ' },
            { name: 'Perii Vadului ', village: 'Ileanda ' },
            { name: 'Podişu ', simpleName: 'Podisu ', village: 'Ileanda ' },
            { name: 'Rogna ', village: 'Ileanda ' },
            { name: 'Răstoci ', simpleName: 'Rastoci ', village: 'Ileanda ' },
            { name: 'Şasa ', simpleName: 'Sasa ', village: 'Ileanda ' },
            { name: 'Cosniciu de Jos ', village: 'Ip ' },
            { name: 'Cosniciu de Sus ', village: 'Ip ' },
            { name: 'Zăuan ', simpleName: 'Zauan ', village: 'Ip ' },
            { name: 'Zăuan Băi ', simpleName: 'Zauan Bai ', village: 'Ip ' },
            { name: 'Cuceu ', village: 'Jibou ' },
            { name: 'Husia ', village: 'Jibou ' },
            { name: 'Rona ', village: 'Jibou ' },
            { name: 'Var ', village: 'Jibou ' },
            { name: 'Ciula ', village: 'Letca ' },
            { name: 'Cozla ', village: 'Letca ' },
            { name: 'Cuciulat ', village: 'Letca ' },
            { name: 'Lemniu ', village: 'Letca ' },
            { name: 'Purcăreţ ', simpleName: 'Purcaret ', village: 'Letca ' },
            { name: 'Topliţa ', simpleName: 'Toplita ', village: 'Letca ' },
            { name: 'Vălişoara ', simpleName: 'Valisoara ', village: 'Letca ' },
            { name: 'Şoimuşeni ', simpleName: 'Soimuseni ', village: 'Letca ' },
            { name: 'Cormeniş ', simpleName: 'Cormenis ', village: 'Lozna ' },
            { name: 'Preluci ', village: 'Lozna ' },
            { name: 'Valea Leşului ', simpleName: 'Valea Lesului ', village: 'Lozna ' },
            { name: 'Valea Loznei ', village: 'Lozna ' },
            { name: 'Criştelec ', simpleName: 'Cristelec ', village: 'Maeriste ' },
            { name: 'Doh ', village: 'Maeriste ' },
            { name: 'Giurtelecu Şimleului ', simpleName: 'Giurtelecu Simleului ', village: 'Maeriste ' },
            { name: 'Mălădia ', simpleName: 'Maladia ', village: 'Maeriste ' },
            { name: 'Uileacu Şimleului ', simpleName: 'Uileacu Simleului ', village: 'Maeriste ' },
            { name: 'Leşmir ', simpleName: 'Lesmir ', village: 'Marca ' },
            { name: 'Marca Huta ', village: 'Marca ' },
            { name: 'Porţ ', simpleName: 'Port ', village: 'Marca ' },
            { name: 'Şumal ', simpleName: 'Sumal ', village: 'Marca ' },
            { name: 'Aghireş ', simpleName: 'Aghires ', village: 'Mesesenii De Jos ' },
            { name: 'Fetindia ', village: 'Mesesenii De Jos ' },
            { name: 'Meseşenii de Sus ', simpleName: 'Mesesenii de Sus ', village: 'Mesesenii De Jos ' },
            { name: 'Firminiş ', simpleName: 'Firminis ', village: 'Mirsid ' },
            { name: 'Moigrad-Porolissum ', village: 'Mirsid ' },
            { name: 'Popeni ', village: 'Mirsid ' },
            { name: 'Cheud ', village: 'Napradea ' },
            { name: 'Someş Guruslău ', simpleName: 'Somes Guruslau ', village: 'Napradea ' },
            { name: 'Traniş ', simpleName: 'Tranis ', village: 'Napradea ' },
            { name: 'Vădurele ', simpleName: 'Vadurele ', village: 'Napradea ' },
            { name: 'Bilghez ', village: 'Nusfalau ' },
            { name: 'Boghiş ', simpleName: 'Boghis ', village: 'Nusfalau ' },
            { name: 'Bozieş ', simpleName: 'Bozies ', village: 'Nusfalau ' },
            { name: 'Bădăcin ', simpleName: 'Badacin ', village: 'Pericei ' },
            { name: 'Periceiu Mic ', village: 'Pericei ' },
            { name: 'Sici ', village: 'Pericei ' },
            { name: 'Făgetu ', simpleName: 'Fagetu ', village: 'Plopis ' },
            { name: 'Iaz ', village: 'Plopis ' },
            { name: 'Fălcuşa ', simpleName: 'Falcusa ', village: 'Poiana Blenchii ' },
            { name: 'Gostila ', village: 'Poiana Blenchii ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Poiana Blenchii ' },
            { name: 'Chichişa ', simpleName: 'Chichisa ', village: 'Romanasi ' },
            { name: 'Ciumărna ', simpleName: 'Ciumarna ', village: 'Romanasi ' },
            { name: 'Poarta Sălajului ', simpleName: 'Poarta Salajului ', village: 'Romanasi ' },
            { name: 'Păuşa ', simpleName: 'Pausa ', village: 'Romanasi ' },
            { name: 'Romita ', village: 'Romanasi ' },
            { name: 'Buzaş ', simpleName: 'Buzas ', village: 'Rus ' },
            { name: 'Fântânele Rus ', simpleName: 'Fantanele Rus ', village: 'Rus ' },
            { name: 'Fizeş ', simpleName: 'Fizes ', village: 'Sag ' },
            { name: 'Mal ', village: 'Sag ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Sag ' },
            { name: 'Tusa ', village: 'Sag ' },
            { name: 'Bulgari ', village: 'Salatig ' },
            { name: 'Deja ', village: 'Salatig ' },
            { name: 'Mineu ', village: 'Salatig ' },
            { name: 'Noţig ', simpleName: 'Notig ', village: 'Salatig ' },
            { name: 'Valea Pomilor ', village: 'Samsud ' },
            { name: 'Bercea ', village: 'Sanmihaiu Almasului ' },
            { name: 'Sântă Măria ', simpleName: 'Santa Maria ', village: 'Sanmihaiu Almasului ' },
            { name: 'Ilişua ', simpleName: 'Ilisua ', village: 'Sarmasag ' },
            { name: 'Lompirt ', village: 'Sarmasag ' },
            { name: 'Moiad ', village: 'Sarmasag ' },
            { name: 'Poiana Măgura ', simpleName: 'Poiana Magura ', village: 'Sarmasag ' },
            { name: 'Ţărmure ', simpleName: 'Tarmure ', village: 'Sarmasag ' },
            { name: 'Hăşmaş ', simpleName: 'Hasmas ', village: 'Simisna ' },
            { name: 'Bic ', village: 'Simleu Silvaniei ' },
            { name: 'Cehei ', village: 'Simleu Silvaniei ' },
            { name: 'Pusta ', village: 'Simleu Silvaniei ' },
            { name: 'Bârsa ', simpleName: 'Barsa ', village: 'Somes-Odorhei ' },
            { name: 'Domnin ', village: 'Somes-Odorhei ' },
            { name: 'Inău ', simpleName: 'Inau ', village: 'Somes-Odorhei ' },
            { name: 'Şoimuş ', simpleName: 'Soimus ', village: 'Somes-Odorhei ' },
            { name: 'Brâglez ', simpleName: 'Braglez ', village: 'Surduc ' },
            { name: 'Cristolţel ', simpleName: 'Cristoltel ', village: 'Surduc ' },
            { name: 'Solona ', village: 'Surduc ' },
            { name: 'Teştioara ', simpleName: 'Testioara ', village: 'Surduc ' },
            { name: 'Tihău ', simpleName: 'Tihau ', village: 'Surduc ' },
            { name: 'Turbuţa ', simpleName: 'Turbuta ', village: 'Surduc ' },
            { name: 'Bozna ', village: 'Treznea ' },
            { name: 'Lazuri ', village: 'Valcau De Jos ' },
            { name: 'Preoteasa ', village: 'Valcau De Jos ' },
            { name: 'Ratovei ', village: 'Valcau De Jos ' },
            { name: 'Sub Cetate ', village: 'Valcau De Jos ' },
            { name: 'Valcău de Sus ', simpleName: 'Valcau de Sus ', village: 'Valcau De Jos ' },
            { name: 'Recea ', village: 'Varsolt ' },
            { name: 'Recea Mică ', simpleName: 'Recea Mica ', village: 'Varsolt ' },
            { name: 'Stâna ', simpleName: 'Stana ', village: 'Zalau ' },
            { name: 'Ceaca ', village: 'Zalha ' },
            { name: 'Ciureni ', village: 'Zalha ' },
            { name: 'Valea Ciurenilor ', village: 'Zalha ' },
            { name: 'Valea Hranei ', village: 'Zalha ' },
            { name: 'Valea Lungă ', simpleName: 'Valea Lunga ', village: 'Zalha ' },
            { name: 'Vârteşca ', simpleName: 'Vartesca ', village: 'Zalha ' },
            { name: 'Chendremal ', village: 'Zimbor ' },
            { name: 'Dolu ', village: 'Zimbor ' },
            { name: 'Sutoru ', village: 'Zimbor ' },
            {
                name: 'Sâncraiu Almaşului ', simpleName: 'Sancraiu Almasului ', village: 'Zimbor '
            }
        ]
    },
    {
        auto: 'SM ',
        name: 'Satu Mare ',
        cities: [
            { name: 'Andrid ' },
            { name: 'Apa ' },
            { name: 'Ardud ' },
            { name: 'Beltiug ' },
            { name: 'Berveni ' },
            { name: 'Bixad ' },
            { name: 'Bogdand ' },
            { name: 'Botiz ' },
            { name: 'Bătarci ', simpleName: 'Batarci ' },
            { name: 'Carei ' },
            { name: 'Cehal ' },
            { name: 'Certeze ' },
            { name: 'Craidorolţ ', simpleName: 'Craidorolt ' },
            { name: 'Crucişor ', simpleName: 'Crucisor ' },
            { name: 'Călineşti-Oaş ', simpleName: 'Calinesti-Oas ' },
            { name: 'Cămin ', simpleName: 'Camin ' },
            { name: 'Cămărzana ', simpleName: 'Camarzana ' },
            { name: 'Căpleni ', simpleName: 'Capleni ' },
            { name: 'Căuaş ', simpleName: 'Cauas ' },
            { name: 'Doba ' },
            { name: 'Dorolţ ', simpleName: 'Dorolt ' },
            { name: 'Foieni ' },
            { name: 'Gherţa Mică ', simpleName: 'Gherta Mica ' },
            { name: 'Halmeu ' },
            { name: 'Hodod ' },
            { name: 'Lazuri ' },
            { name: 'Livada ' },
            { name: 'Medieşu Aurit ', simpleName: 'Mediesu Aurit ' },
            { name: 'Micula ' },
            { name: 'Negreşti-Oaş ', simpleName: 'Negresti-Oas ' },
            { name: 'Odoreu ' },
            { name: 'Oraşu Nou ', simpleName: 'Orasu Nou ' },
            { name: 'Petreşti ', simpleName: 'Petresti ' },
            { name: 'Pir ' },
            { name: 'Pişcolt ', simpleName: 'Piscolt ' },
            { name: 'Pomi ' },
            { name: 'Păuleşti ', simpleName: 'Paulesti ' },
            { name: 'Sanislău ', simpleName: 'Sanislau ' },
            { name: 'Santău ', simpleName: 'Santau ' },
            { name: 'Satu Mare ' },
            { name: 'Socond ' },
            { name: 'Săcăşeni ', simpleName: 'Sacaseni ' },
            { name: 'Săuca ', simpleName: 'Sauca ' },
            { name: 'Tarna Mare ' },
            { name: 'Terebeşti ', simpleName: 'Terebesti ' },
            { name: 'Tiream ' },
            { name: 'Turulung ' },
            { name: 'Turţ ', simpleName: 'Turt ' },
            { name: 'Târşolţ ', simpleName: 'Tarsolt ' },
            { name: 'Tăşnad ', simpleName: 'Tasnad ' },
            { name: 'Urziceni ' },
            { name: 'Valea Vinului ' },
            { name: 'Vama ' },
            { name: 'Vetiş ', simpleName: 'Vetis ' },
            { name: 'Viile Satu Mare ' },
            { name: 'Acâş ', simpleName: 'Acas ', village: 'Acis ' },
            { name: 'Ganaş ', simpleName: 'Ganas ', village: 'Acis ' },
            { name: 'Mihăieni ', simpleName: 'Mihaieni ', village: 'Acis ' },
            { name: 'Unimăt ', simpleName: 'Unimat ', village: 'Acis ' },
            { name: 'Dindeşti ', simpleName: 'Dindesti ', village: 'Andrid ' },
            { name: 'Irina ', village: 'Andrid ' },
            { name: 'Lunca Apei ', village: 'Apa ' },
            { name: 'Someşeni ', simpleName: 'Someseni ', village: 'Apa ' },
            { name: 'Ardud-Vii ', village: 'Ardud ' },
            { name: 'Baba Novac ', village: 'Ardud ' },
            { name: 'Gerăuşa ', simpleName: 'Gerausa ', village: 'Ardud ' },
            { name: 'Mădăras ', simpleName: 'Madaras ', village: 'Ardud ' },
            { name: 'Sărătura ', simpleName: 'Saratura ', village: 'Ardud ' },
            { name: 'Bârsău de Jos ', simpleName: 'Barsau de Jos ', village: 'Barsau ' },
            { name: 'Bârsău de Sus ', simpleName: 'Barsau de Sus ', village: 'Barsau ' },
            { name: 'Comlăuşa ', simpleName: 'Comlausa ', village: 'Batarci ' },
            { name: 'Tămăşeni ', simpleName: 'Tamaseni ', village: 'Batarci ' },
            { name: 'Şirlău ', simpleName: 'Sirlau ', village: 'Batarci ' },
            { name: 'Bolda ', village: 'Beltiug ' },
            { name: 'Ghirişa ', simpleName: 'Ghirisa ', village: 'Beltiug ' },
            { name: 'Giungi ', village: 'Beltiug ' },
            { name: 'Răteşti ', simpleName: 'Ratesti ', village: 'Beltiug ' },
            { name: 'Şandra ', simpleName: 'Sandra ', village: 'Beltiug ' },
            { name: 'Lucăceni ', simpleName: 'Lucaceni ', village: 'Berveni ' },
            { name: 'Boineşti ', simpleName: 'Boinesti ', village: 'Bixad ' },
            { name: 'Trip ', village: 'Bixad ' },
            { name: 'Babţa ', simpleName: 'Babta ', village: 'Bogdand ' },
            { name: 'Corund ', village: 'Bogdand ' },
            { name: 'Ser ', village: 'Bogdand ' },
            { name: 'Agriş ', simpleName: 'Agris ', village: 'Botiz ' },
            { name: 'Ciuperceni ', village: 'Botiz ' },
            { name: 'Coca ', village: 'Calinesti-Oas ' },
            { name: 'Lechinţa ', simpleName: 'Lechinta ', village: 'Calinesti-Oas ' },
            { name: 'Păşunea Mare ', simpleName: 'Pasunea Mare ', village: 'Calinesti-Oas ' },
            { name: 'Ianculeşti ', simpleName: 'Ianculesti ', village: 'Carei ' },
            { name: 'Ady Endre ', village: 'Cauas ' },
            { name: 'Ghenci ', village: 'Cauas ' },
            { name: 'Ghileşti ', simpleName: 'Ghilesti ', village: 'Cauas ' },
            { name: 'Hotoan ', village: 'Cauas ' },
            { name: 'Răduleşti ', simpleName: 'Radulesti ', village: 'Cauas ' },
            { name: 'Cehăluţ ', simpleName: 'Cehalut ', village: 'Cehal ' },
            { name: 'Orbău ', simpleName: 'Orbau ', village: 'Cehal ' },
            { name: 'Huta Certeze ', village: 'Certeze ' },
            { name: 'Moişeni ', simpleName: 'Moiseni ', village: 'Certeze ' },
            { name: 'Crişeni ', simpleName: 'Criseni ', village: 'Craidorolt ' },
            { name: 'Eriu Sâncrai ', simpleName: 'Eriu Sancrai ', village: 'Craidorolt ' },
            { name: 'Satu Mic ', village: 'Craidorolt ' },
            { name: 'Ţeghea ', simpleName: 'Teghea ', village: 'Craidorolt ' },
            { name: 'Iegherişte ', simpleName: 'Iegheriste ', village: 'Crucisor ' },
            { name: 'Poiana Codrului ', village: 'Crucisor ' },
            { name: 'Apateu ', village: 'Culciu ' },
            { name: 'Corod ', village: 'Culciu ' },
            { name: 'Culciu Mare ', village: 'Culciu ' },
            { name: 'Culciu Mic ', village: 'Culciu ' },
            { name: 'Cărăşeu ', simpleName: 'Caraseu ', village: 'Culciu ' },
            { name: 'Lipău ', simpleName: 'Lipau ', village: 'Culciu ' },
            { name: 'Boghiş ', simpleName: 'Boghis ', village: 'Doba ' },
            { name: 'Dacia ', village: 'Doba ' },
            { name: 'Paulian ', village: 'Doba ' },
            { name: 'Traian ', village: 'Doba ' },
            { name: 'Atea ', village: 'Dorolt ' },
            { name: 'Dara ', village: 'Dorolt ' },
            { name: 'Petea ', village: 'Dorolt ' },
            { name: 'Băbeşti ', simpleName: 'Babesti ', village: 'Halmeu ' },
            { name: 'Cidreag ', village: 'Halmeu ' },
            { name: 'Dobolţ ', simpleName: 'Dobolt ', village: 'Halmeu ' },
            { name: 'Halmeu Vii ', village: 'Halmeu ' },
            { name: 'Mesteacăn ', simpleName: 'Mesteacan ', village: 'Halmeu ' },
            { name: 'Porumbeşti ', simpleName: 'Porumbesti ', village: 'Halmeu ' },
            { name: 'Giurtelecu Hododului ', village: 'Hodod ' },
            { name: 'Lelei ', village: 'Hodod ' },
            { name: 'Nadişu Hododului ', simpleName: 'Nadisu Hododului ', village: 'Hodod ' },
            { name: 'Chilia ', village: 'Homoroade ' },
            { name: 'Homorodu de Jos ', village: 'Homoroade ' },
            { name: 'Homorodu de Mijloc ', village: 'Homoroade ' },
            { name: 'Homorodu de Sus ', village: 'Homoroade ' },
            { name: 'Necopoi ', village: 'Homoroade ' },
            { name: 'Solduba ', village: 'Homoroade ' },
            { name: 'Bercu ', village: 'Lazuri ' },
            { name: 'Nisipeni ', village: 'Lazuri ' },
            { name: 'Noroieni ', village: 'Lazuri ' },
            { name: 'Peleş ', simpleName: 'Peles ', village: 'Lazuri ' },
            { name: 'Pelişor ', simpleName: 'Pelisor ', village: 'Lazuri ' },
            { name: 'Adrian ', village: 'Livada ' },
            { name: 'Dumbrava ', village: 'Livada ' },
            { name: 'Livada Mică ', simpleName: 'Livada Mica ', village: 'Livada ' },
            { name: 'Băbăşeşti ', simpleName: 'Babasesti ', village: 'Mediesu Aurit ' },
            { name: 'Iojib ', village: 'Mediesu Aurit ' },
            { name: 'Medieş Râturi ', simpleName: 'Medies Raturi ', village: 'Mediesu Aurit ' },
            { name: 'Medieş Vii ', simpleName: 'Medies Vii ', village: 'Mediesu Aurit ' },
            { name: 'Potău ', simpleName: 'Potau ', village: 'Mediesu Aurit ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Mediesu Aurit ' },
            { name: 'Bercu Nou ', village: 'Micula ' },
            { name: 'Micula Nouă ', simpleName: 'Micula Noua ', village: 'Micula ' },
            { name: 'Domăneşti ', simpleName: 'Domanesti ', village: 'Moftin ' },
            { name: 'Ghilvaci ', village: 'Moftin ' },
            { name: 'Ghirolt ', village: 'Moftin ' },
            { name: 'Istrău ', simpleName: 'Istrau ', village: 'Moftin ' },
            { name: 'Moftinu Mare ', village: 'Moftin ' },
            { name: 'Moftinu Mic ', village: 'Moftin ' },
            { name: 'Sânmiclăuş ', simpleName: 'Sanmiclaus ', village: 'Moftin ' },
            { name: 'Luna ', village: 'Negresti-Oas ' },
            { name: 'Tur ', village: 'Negresti-Oas ' },
            { name: 'Berindan ', village: 'Odoreu ' },
            { name: 'Cucu ', village: 'Odoreu ' },
            { name: 'Eteni ', village: 'Odoreu ' },
            { name: 'Mărtineşti ', simpleName: 'Martinesti ', village: 'Odoreu ' },
            { name: 'Vânătoreşti ', simpleName: 'Vanatoresti ', village: 'Odoreu ' },
            { name: 'Oraşu Nou-Vii ', simpleName: 'Orasu Nou-Vii ', village: 'Orasu Nou ' },
            { name: 'Prilog ', village: 'Orasu Nou ' },
            { name: 'Prilog Vii ', village: 'Orasu Nou ' },
            { name: 'Racşa ', simpleName: 'Racsa ', village: 'Orasu Nou ' },
            { name: 'Racşa Vii ', simpleName: 'Racsa Vii ', village: 'Orasu Nou ' },
            { name: 'Remetea Oaşului ', simpleName: 'Remetea Oasului ', village: 'Orasu Nou ' },
            { name: 'Amaţi ', simpleName: 'Amati ', village: 'Paulesti ' },
            { name: 'Ambud ', village: 'Paulesti ' },
            { name: 'Hrip ', village: 'Paulesti ' },
            { name: 'Petin ', village: 'Paulesti ' },
            { name: 'Ruşeni ', simpleName: 'Ruseni ', village: 'Paulesti ' },
            { name: 'Dindeştiu Mic ', simpleName: 'Dindestiu Mic ', village: 'Petresti ' },
            { name: 'Piru Nou ', village: 'Pir ' },
            { name: 'Sărvăzel ', simpleName: 'Sarvazel ', village: 'Pir ' },
            { name: 'Resighea ', village: 'Piscolt ' },
            { name: 'Scărişoara Nouă ', simpleName: 'Scarisoara Noua ', village: 'Piscolt ' },
            { name: 'Aciua ', village: 'Pomi ' },
            { name: 'Bicău ', simpleName: 'Bicau ', village: 'Pomi ' },
            { name: 'Borleşti ', simpleName: 'Borlesti ', village: 'Pomi ' },
            { name: 'Chegea ', village: 'Sacaseni ' },
            { name: 'Berea ', village: 'Sanislau ' },
            { name: 'Ciumeşti ', simpleName: 'Ciumesti ', village: 'Sanislau ' },
            { name: 'Horea ', village: 'Sanislau ' },
            { name: 'Marna Nouă ', simpleName: 'Marna Noua ', village: 'Sanislau ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Sanislau ' },
            { name: 'Chereuşa ', simpleName: 'Chereusa ', village: 'Santau ' },
            { name: 'Sudurău ', simpleName: 'Sudurau ', village: 'Santau ' },
            { name: 'Sătmărel ', simpleName: 'Satmarel ', village: 'Satu Mare ' },
            { name: 'Becheni ', village: 'Sauca ' },
            { name: 'Cean ', village: 'Sauca ' },
            { name: 'Chisău ', simpleName: 'Chisau ', village: 'Sauca ' },
            { name: 'Silvaş ', simpleName: 'Silvas ', village: 'Sauca ' },
            { name: 'Cuţa ', simpleName: 'Cuta ', village: 'Socond ' },
            { name: 'Hodişa ', simpleName: 'Hodisa ', village: 'Socond ' },
            { name: 'Soconzel ', village: 'Socond ' },
            { name: 'Stâna ', simpleName: 'Stana ', village: 'Socond ' },
            { name: 'Dobra ', village: 'Supur ' },
            { name: 'Giorocuta ', village: 'Supur ' },
            { name: 'Hurezu Mare ', village: 'Supur ' },
            { name: 'Racova ', village: 'Supur ' },
            { name: 'Sechereşa ', simpleName: 'Secheresa ', village: 'Supur ' },
            { name: 'Supuru de Jos ', village: 'Supur ' },
            { name: 'Supuru de Sus ', village: 'Supur ' },
            { name: 'Bocicău ', simpleName: 'Bocicau ', village: 'Tarna Mare ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Tarna Mare ' },
            { name: 'Văgaş ', simpleName: 'Vagas ', village: 'Tarna Mare ' },
            { name: 'Aliceni ', village: 'Tarsolt ' },
            { name: 'Blaja ', village: 'Tasnad ' },
            { name: 'Cig ', village: 'Tasnad ' },
            { name: 'Raţiu ', simpleName: 'Ratiu ', village: 'Tasnad ' },
            { name: 'Sărăuad ', simpleName: 'Sarauad ', village: 'Tasnad ' },
            { name: 'Valea Morii ', village: 'Tasnad ' },
            { name: 'Aliza ', village: 'Terebesti ' },
            { name: 'Gelu ', village: 'Terebesti ' },
            { name: 'Pişcari ', simpleName: 'Piscari ', village: 'Terebesti ' },
            { name: 'Portiţa ', simpleName: 'Portita ', village: 'Tiream ' },
            { name: 'Vezendiu ', village: 'Tiream ' },
            { name: 'Gherţa Mare ', simpleName: 'Gherta Mare ', village: 'Turt ' },
            { name: 'Turţ Băi ', simpleName: 'Turt Bai ', village: 'Turt ' },
            { name: 'Drăguşeni ', simpleName: 'Draguseni ', village: 'Turulung ' },
            { name: 'Turulung Vii ', village: 'Turulung ' },
            { name: 'Urziceni Pădure ', simpleName: 'Urziceni Padure ', village: 'Urziceni ' },
            { name: 'Măriuş ', simpleName: 'Marius ', village: 'Valea Vinului ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Valea Vinului ' },
            { name: 'Sâi ', simpleName: 'Sai ', village: 'Valea Vinului ' },
            { name: 'Decebal ', village: 'Vetis ' },
            { name: 'Oar ', village: 'Vetis ' },
            { name: 'Cioncheşti ', simpleName: 'Cionchesti ', village: 'Viile Satu Mare ' },
            { name: 'Medişa ', simpleName: 'Medisa ', village: 'Viile Satu Mare ' },
            { name: 'Tireac ', village: 'Viile Satu Mare ' },
            {
                name: 'Tătăreşti ', simpleName: 'Tataresti ', village: 'Viile Satu Mare '
            }
        ]
    },
    {
        auto: 'SB ',
        name: 'Sibiu ',
        cities: [
            { name: 'Agnita ' },
            { name: 'Alţina ', simpleName: 'Altina ' },
            { name: 'Apoldu de Jos ' },
            { name: 'Arpaşu de Jos ', simpleName: 'Arpasu de Jos ' },
            { name: 'Avrig ' },
            { name: 'Axente Sever ' },
            { name: 'Aţel ', simpleName: 'Atel ' },
            { name: 'Bazna ' },
            { name: 'Biertan ' },
            { name: 'Blăjel ', simpleName: 'Blajel ' },
            { name: 'Brateiu ' },
            { name: 'Bruiu ' },
            { name: 'Brădeni ', simpleName: 'Bradeni ' },
            { name: 'Chirpăr ', simpleName: 'Chirpar ' },
            { name: 'Cisnădie ', simpleName: 'Cisnadie ' },
            { name: 'Copşa Mică ', simpleName: 'Copsa Mica ' },
            { name: 'Cristian ' },
            { name: 'Cârţa ', simpleName: 'Carta ' },
            { name: 'Cârţişoara ', simpleName: 'Cartisoara ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ' },
            { name: 'Dârlos ', simpleName: 'Darlos ' },
            { name: 'Gura Râului ', simpleName: 'Gura Raului ' },
            { name: 'Hoghilag ' },
            { name: 'Iacobeni ' },
            { name: 'Jina ' },
            { name: 'Laslea ' },
            { name: 'Loamneş ', simpleName: 'Loamnes ' },
            { name: 'Ludoş ', simpleName: 'Ludos ' },
            { name: 'Marpod ' },
            { name: 'Mediaş ', simpleName: 'Medias ' },
            { name: 'Merghindeal ' },
            { name: 'Micăsasa ', simpleName: 'Micasasa ' },
            { name: 'Miercurea Sibiului ' },
            { name: 'Mihăileni ', simpleName: 'Mihaileni ' },
            { name: 'Moşna ', simpleName: 'Mosna ' },
            { name: 'Nocrich ' },
            { name: 'Ocna Sibiului ' },
            { name: 'Orlat ' },
            { name: 'Poiana Sibiului ' },
            { name: 'Poplaca ' },
            { name: 'Porumbacu de Jos ' },
            { name: 'Păuca ', simpleName: 'Pauca ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ' },
            { name: 'Roşia ', simpleName: 'Rosia ' },
            { name: 'Râu Sadului ', simpleName: 'Rau Sadului ' },
            { name: 'Răşinari ', simpleName: 'Rasinari ' },
            { name: 'Sadu ' },
            { name: 'Sibiu ' },
            { name: 'Slimnic ' },
            { name: 'Sălişte ', simpleName: 'Saliste ' },
            { name: 'Tilişca ', simpleName: 'Tilisca ' },
            { name: 'Turnu Roşu ', simpleName: 'Turnu Rosu ' },
            { name: 'Târnava ', simpleName: 'Tarnava ' },
            { name: 'Tălmaciu ', simpleName: 'Talmaciu ' },
            { name: 'Valea Viilor ' },
            { name: 'Vurpăr ', simpleName: 'Vurpar ' },
            { name: 'Şeica Mare ', simpleName: 'Seica Mare ' },
            { name: 'Şeica Mică ', simpleName: 'Seica Mica ' },
            { name: 'Şelimbăr ', simpleName: 'Selimbar ' },
            { name: 'Şura Mare ', simpleName: 'Sura Mare ' },
            { name: 'Şura Mică ', simpleName: 'Sura Mica ' },
            { name: 'Coveş ', simpleName: 'Coves ', village: 'Agnita ' },
            { name: 'Ruja ', village: 'Agnita ' },
            { name: 'Beneşti ', simpleName: 'Benesti ', village: 'Altina ' },
            { name: 'Ghijasa de Sus ', village: 'Altina ' },
            { name: 'Sângătin ', simpleName: 'Sangatin ', village: 'Apoldu De Jos ' },
            { name: 'Arpaşu de Sus ', simpleName: 'Arpasu de Sus ', village: 'Arpasu De Jos ' },
            { name: 'Noul Român ', simpleName: 'Noul Roman ', village: 'Arpasu De Jos ' },
            { name: 'Alma ', village: 'Atel ' },
            { name: 'Dupuş ', simpleName: 'Dupus ', village: 'Atel ' },
            { name: 'Giacăş ', simpleName: 'Giacas ', village: 'Atel ' },
            { name: 'Şmig ', simpleName: 'Smig ', village: 'Atel ' },
            { name: 'Bradu ', village: 'Avrig ' },
            { name: 'Glâmboaca ', simpleName: 'Glamboaca ', village: 'Avrig ' },
            { name: 'Mârşa ', simpleName: 'Marsa ', village: 'Avrig ' },
            { name: 'Săcădate ', simpleName: 'Sacadate ', village: 'Avrig ' },
            { name: 'Agârbiciu ', simpleName: 'Agarbiciu ', village: 'Axente Sever ' },
            { name: 'Şoala ', simpleName: 'Soala ', village: 'Axente Sever ' },
            { name: 'Boian ', village: 'Bazna ' },
            { name: 'Velţ ', simpleName: 'Velt ', village: 'Bazna ' },
            { name: 'Copşa Mare ', simpleName: 'Copsa Mare ', village: 'Biertan ' },
            { name: 'Richiş ', simpleName: 'Richis ', village: 'Biertan ' },
            { name: 'Apoş ', simpleName: 'Apos ', village: 'Birghis ' },
            { name: 'Bârghiş ', simpleName: 'Barghis ', village: 'Birghis ' },
            { name: 'Ighişu Vechi ', simpleName: 'Ighisu Vechi ', village: 'Birghis ' },
            { name: 'Pelişor ', simpleName: 'Pelisor ', village: 'Birghis ' },
            { name: 'Vecerd ', village: 'Birghis ' },
            { name: 'Zlagna ', village: 'Birghis ' },
            { name: 'Păucea ', simpleName: 'Paucea ', village: 'Blajel ' },
            { name: 'Romaneşti ', simpleName: 'Romanesti ', village: 'Blajel ' },
            { name: 'Retiş ', simpleName: 'Retis ', village: 'Bradeni ' },
            { name: 'Ţeline ', simpleName: 'Teline ', village: 'Bradeni ' },
            { name: 'Buzd ', village: 'Brateiu ' },
            { name: 'Gherdeal ', village: 'Bruiu ' },
            { name: 'Şomartin ', simpleName: 'Somartin ', village: 'Bruiu ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Carta ' },
            { name: 'Săsăuş ', simpleName: 'Sasaus ', village: 'Chirpar ' },
            { name: 'Veseud ', village: 'Chirpar ' },
            { name: 'Vărd ', simpleName: 'Vard ', village: 'Chirpar ' },
            { name: 'Cisnădioara ', simpleName: 'Cisnadioara ', village: 'Cisnadie ' },
            { name: 'Curciu ', village: 'Darlos ' },
            { name: 'Valea Lungă ', simpleName: 'Valea Lunga ', village: 'Darlos ' },
            { name: 'Ernea ', village: 'Dumbraveni ' },
            { name: 'Şaroş pe Târnave ', simpleName: 'Saros pe Tarnave ', village: 'Dumbraveni ' },
            { name: 'Prod ', village: 'Hoghilag ' },
            { name: 'Valchid ', village: 'Hoghilag ' },
            { name: 'Movile ', village: 'Iacobeni ' },
            { name: 'Netuş ', simpleName: 'Netus ', village: 'Iacobeni ' },
            { name: 'Noiştat ', simpleName: 'Noistat ', village: 'Iacobeni ' },
            { name: 'Stejărişu ', simpleName: 'Stejarisu ', village: 'Iacobeni ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Laslea ' },
            { name: 'Mălâncrav ', simpleName: 'Malancrav ', village: 'Laslea ' },
            { name: 'Nou Săsesc ', simpleName: 'Nou Sasesc ', village: 'Laslea ' },
            { name: 'Roandola ', village: 'Laslea ' },
            { name: 'Alămor ', simpleName: 'Alamor ', village: 'Loamnes ' },
            { name: 'Armeni ', village: 'Loamnes ' },
            { name: 'Haşag ', simpleName: 'Hasag ', village: 'Loamnes ' },
            { name: 'Mândra ', simpleName: 'Mandra ', village: 'Loamnes ' },
            { name: 'Sădinca ', simpleName: 'Sadinca ', village: 'Loamnes ' },
            { name: 'Gusu ', village: 'Ludos ' },
            { name: 'Ilimbav ', village: 'Marpod ' },
            { name: 'Ighişu Nou ', simpleName: 'Ighisu Nou ', village: 'Medias ' },
            { name: 'Dealu Frumos ', village: 'Merghindeal ' },
            { name: 'Chesler ', village: 'Micasasa ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Micasasa ' },
            { name: 'Ţapu ', simpleName: 'Tapu ', village: 'Micasasa ' },
            { name: 'Apoldu de Sus ', village: 'Miercurea Sibiului ' },
            { name: 'Dobârca ', simpleName: 'Dobarca ', village: 'Miercurea Sibiului ' },
            { name: 'Metiş ', simpleName: 'Metis ', village: 'Mihaileni ' },
            { name: 'Moardăş ', simpleName: 'Moardas ', village: 'Mihaileni ' },
            { name: 'Răvăşel ', simpleName: 'Ravasel ', village: 'Mihaileni ' },
            { name: 'Şalcău ', simpleName: 'Salcau ', village: 'Mihaileni ' },
            { name: 'Alma Vii ', village: 'Mosna ' },
            { name: 'Nemşa ', simpleName: 'Nemsa ', village: 'Mosna ' },
            { name: 'Fofeldea ', village: 'Nocrich ' },
            { name: 'Ghijasa de Jos ', village: 'Nocrich ' },
            { name: 'Hosman ', village: 'Nocrich ' },
            { name: 'Ţichindeal ', simpleName: 'Tichindeal ', village: 'Nocrich ' },
            { name: 'Topârcea ', simpleName: 'Toparcea ', village: 'Ocna Sibiului ' },
            { name: 'Bogatu Român ', simpleName: 'Bogatu Roman ', village: 'Pauca ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Pauca ' },
            { name: 'Presaca ', village: 'Pauca ' },
            { name: 'Colun ', village: 'Porumbacu De Jos ' },
            { name: 'Porumbacu de Sus ', village: 'Porumbacu De Jos ' },
            { name: 'Scoreiu ', village: 'Porumbacu De Jos ' },
            { name: 'Sărata ', simpleName: 'Sarata ', village: 'Porumbacu De Jos ' },
            { name: 'Sebeşu de Sus ', simpleName: 'Sebesu de Sus ', village: 'Racovita ' },
            { name: 'Prislop ', village: 'Rasinari ' },
            { name: 'Caşolţ ', simpleName: 'Casolt ', village: 'Rosia ' },
            { name: 'Cornăţel ', simpleName: 'Cornatel ', village: 'Rosia ' },
            { name: 'Daia ', village: 'Rosia ' },
            { name: 'Nou ', village: 'Rosia ' },
            { name: 'Nucet ', village: 'Rosia ' },
            { name: 'Aciliu ', village: 'Saliste ' },
            { name: 'Amnaş ', simpleName: 'Amnas ', village: 'Saliste ' },
            { name: 'Crinţ ', simpleName: 'Crint ', village: 'Saliste ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Saliste ' },
            { name: 'Galeş ', simpleName: 'Gales ', village: 'Saliste ' },
            { name: 'Mag ', village: 'Saliste ' },
            { name: 'Sibiel ', village: 'Saliste ' },
            { name: 'Săcel ', simpleName: 'Sacel ', village: 'Saliste ' },
            { name: 'Vale ', village: 'Saliste ' },
            { name: 'Boarta ', village: 'Seica Mare ' },
            { name: 'Buia ', village: 'Seica Mare ' },
            { name: 'Mighindoala ', village: 'Seica Mare ' },
            { name: 'Petiş ', simpleName: 'Petis ', village: 'Seica Mare ' },
            { name: 'Ştenea ', simpleName: 'Stenea ', village: 'Seica Mare ' },
            { name: 'Soroştin ', simpleName: 'Sorostin ', village: 'Seica Mica ' },
            { name: 'Bungard ', village: 'Selimbar ' },
            { name: 'Mohu ', village: 'Selimbar ' },
            { name: 'Veştem ', simpleName: 'Vestem ', village: 'Selimbar ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ', village: 'Sibiu ' },
            { name: 'Albi ', village: 'Slimnic ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Slimnic ' },
            { name: 'Ruşi ', simpleName: 'Rusi ', village: 'Slimnic ' },
            { name: 'Veseud ', village: 'Slimnic ' },
            { name: 'Hamba ', village: 'Sura Mare ' },
            { name: 'Rusciori ', village: 'Sura Mica ' },
            { name: 'Boiţa ', simpleName: 'Boita ', village: 'Talmaciu ' },
            { name: 'Colonia Tălmaciu ', simpleName: 'Colonia Talmaciu ', village: 'Talmaciu ' },
            { name: 'Lazaret ', village: 'Talmaciu ' },
            { name: 'Lotrioara ', village: 'Talmaciu ' },
            { name: 'Paltin ', village: 'Talmaciu ' },
            { name: 'Tălmăcel ', simpleName: 'Talmacel ', village: 'Talmaciu ' },
            { name: 'Colonia Târnava ', simpleName: 'Colonia Tarnava ', village: 'Tarnava ' },
            { name: 'Rod ', village: 'Tilisca ' },
            { name: 'Sebeşu de Jos ', simpleName: 'Sebesu de Jos ', village: 'Turnu Rosu ' },
            {
                name: 'Motiş ', simpleName: 'Motis ', village: 'Valea Viilor '
            }
        ]
    },
    {
        auto: 'SV ',
        name: 'Suceava ',
        cities: [
            { name: 'Adâncata ', simpleName: 'Adancata ' },
            { name: 'Arbore ' },
            { name: 'Baia ' },
            { name: 'Bilca ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ' },
            { name: 'Boroaia ' },
            { name: 'Bosanci ' },
            { name: 'Botoşana ', simpleName: 'Botosana ' },
            { name: 'Breaza ' },
            { name: 'Brodina ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ' },
            { name: 'Bălcăuţi ', simpleName: 'Balcauti ' },
            { name: 'Cacica ' },
            { name: 'Cajvana ' },
            { name: 'Calafindeşti ', simpleName: 'Calafindesti ' },
            { name: 'Capu Câmpului ', simpleName: 'Capu Campului ' },
            { name: 'Ciocăneşti ', simpleName: 'Ciocanesti ' },
            { name: 'Ciprian Porumbescu ' },
            { name: 'Comăneşti ', simpleName: 'Comanesti ' },
            { name: 'Cornu Luncii ' },
            { name: 'Coşna ', simpleName: 'Cosna ' },
            { name: 'Crucea ' },
            { name: 'Câmpulung Moldovenesc ', simpleName: 'Campulung Moldovenesc ' },
            { name: 'Cârlibaba ', simpleName: 'Carlibaba ' },
            { name: 'Dolhasca ' },
            { name: 'Dorna Candrenilor ' },
            { name: 'Dorna-Arini ' },
            { name: 'Dorneşti ', simpleName: 'Dornesti ' },
            { name: 'Drăgoieşti ', simpleName: 'Dragoiesti ' },
            { name: 'Drăguşeni ', simpleName: 'Draguseni ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ' },
            { name: 'Dărmăneşti ', simpleName: 'Darmanesti ' },
            { name: 'Forăşti ', simpleName: 'Forasti ' },
            { name: 'Frasin ' },
            { name: 'Frumosu ' },
            { name: 'Frătăuţii Noi ', simpleName: 'Fratautii Noi ' },
            { name: 'Frătăuţii Vechi ', simpleName: 'Fratautii Vechi ' },
            { name: 'Fundu Moldovei ' },
            { name: 'Fântâna Mare ', simpleName: 'Fantana Mare ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ' },
            { name: 'Fălticeni ', simpleName: 'Falticeni ' },
            { name: 'Grămeşti ', simpleName: 'Gramesti ' },
            { name: 'Grăniceşti ', simpleName: 'Granicesti ' },
            { name: 'Gura Humorului ' },
            { name: 'Gălăneşti ', simpleName: 'Galanesti ' },
            { name: 'Horodnic de Sus ' },
            { name: 'Horodniceni ' },
            { name: 'Hănţeşti ', simpleName: 'Hantesti ' },
            { name: 'Iacobeni ' },
            { name: 'Iaslovăţ ', simpleName: 'Iaslovat ' },
            { name: 'Ipoteşti ', simpleName: 'Ipotesti ' },
            { name: 'Izvoarele Sucevei ' },
            { name: 'Liteni ' },
            { name: 'Marginea ' },
            { name: 'Milişăuţi ', simpleName: 'Milisauti ' },
            { name: 'Mitocu Dragomirnei ' },
            { name: 'Moldova-Suliţa ', simpleName: 'Moldova-Sulita ' },
            { name: 'Moldoviţa ', simpleName: 'Moldovita ' },
            { name: 'Muşeniţa ', simpleName: 'Musenita ' },
            { name: 'Mălini ', simpleName: 'Malini ' },
            { name: 'Mănăstirea Humorului ', simpleName: 'Manastirea Humorului ' },
            { name: 'Ostra ' },
            { name: 'Panaci ' },
            { name: 'Poiana Stampei ' },
            { name: 'Pojorâta ', simpleName: 'Pojorata ' },
            { name: 'Preuteşti ', simpleName: 'Preutesti ' },
            { name: 'Putna ' },
            { name: 'Pârteştii de Jos ', simpleName: 'Partestii de Jos ' },
            { name: 'Păltinoasa ', simpleName: 'Paltinoasa ' },
            { name: 'Pătrăuţi ', simpleName: 'Patrauti ' },
            { name: 'Râşca ', simpleName: 'Rasca ' },
            { name: 'Rădăuţi ', simpleName: 'Radauti ' },
            { name: 'Rădăşeni ', simpleName: 'Radaseni ' },
            { name: 'Sadova ' },
            { name: 'Salcea ' },
            { name: 'Satu Mare ' },
            { name: 'Siminicea ' },
            { name: 'Siret ' },
            { name: 'Slatina ' },
            { name: 'Solca ' },
            { name: 'Straja ' },
            { name: 'Stroieşti ', simpleName: 'Stroiesti ' },
            { name: 'Stulpicani ' },
            { name: 'Suceava ' },
            { name: 'Suceviţa ', simpleName: 'Sucevita ' },
            { name: 'Todireşti ', simpleName: 'Todiresti ' },
            { name: 'Udeşti ', simpleName: 'Udesti ' },
            { name: 'Ulma ' },
            { name: 'Vadu Moldovei ' },
            { name: 'Valea Moldovei ' },
            { name: 'Vama ' },
            { name: 'Vatra Dornei ' },
            { name: 'Vatra Moldoviţei ', simpleName: 'Vatra Moldovitei ' },
            { name: 'Vereşti ', simpleName: 'Veresti ' },
            { name: 'Vicovu de Jos ' },
            { name: 'Vicovu de Sus ' },
            { name: 'Volovăţ ', simpleName: 'Volovat ' },
            { name: 'Vultureşti ', simpleName: 'Vulturesti ' },
            { name: 'Zamostea ' },
            { name: 'Zvoriştea ', simpleName: 'Zvoristea ' },
            { name: 'Şcheia ', simpleName: 'Scheia ' },
            { name: 'Şerbăuţi ', simpleName: 'Serbauti ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Adancata ' },
            { name: 'Feteşti ', simpleName: 'Fetesti ', village: 'Adancata ' },
            { name: 'Bodnăreni ', simpleName: 'Bodnareni ', village: 'Arbore ' },
            { name: 'Clit ', village: 'Arbore ' },
            { name: 'Bogata ', village: 'Baia ' },
            { name: 'Gropeni ', village: 'Balcauti ' },
            { name: 'Negostina ', village: 'Balcauti ' },
            { name: 'Bărăşti ', simpleName: 'Barasti ', village: 'Boroaia ' },
            { name: 'Giuleşti ', simpleName: 'Giulesti ', village: 'Boroaia ' },
            { name: 'Moişa ', simpleName: 'Moisa ', village: 'Boroaia ' },
            { name: 'Săcuţa ', simpleName: 'Sacuta ', village: 'Boroaia ' },
            { name: 'Cumpărătura ', simpleName: 'Cumparatura ', village: 'Bosanci ' },
            { name: 'Breaza de Sus ', village: 'Breaza ' },
            { name: 'Pârâu Negrei ', simpleName: 'Parau Negrei ', village: 'Breaza ' },
            { name: 'Brodina de Jos ', village: 'Brodina ' },
            { name: 'Cununschi ', village: 'Brodina ' },
            { name: 'Dubiuşca ', simpleName: 'Dubiusca ', village: 'Brodina ' },
            { name: 'Ehreşte ', simpleName: 'Ehreste ', village: 'Brodina ' },
            { name: 'Falcău ', simpleName: 'Falcau ', village: 'Brodina ' },
            { name: 'Norocu ', village: 'Brodina ' },
            { name: 'Paltin ', village: 'Brodina ' },
            { name: 'Sadău ', simpleName: 'Sadau ', village: 'Brodina ' },
            { name: 'Zalomestra ', village: 'Brodina ' },
            { name: 'Cotârgaşi ', simpleName: 'Cotargasi ', village: 'Brosteni ' },
            { name: 'Dârmoxa ', simpleName: 'Darmoxa ', village: 'Brosteni ' },
            { name: 'Frasin ', village: 'Brosteni ' },
            { name: 'Holda ', village: 'Brosteni ' },
            { name: 'Holdiţa ', simpleName: 'Holdita ', village: 'Brosteni ' },
            { name: 'Hăleasa ', simpleName: 'Haleasa ', village: 'Brosteni ' },
            { name: 'Lungeni ', village: 'Brosteni ' },
            { name: 'Neagra ', village: 'Brosteni ' },
            { name: 'Pietroasa ', village: 'Brosteni ' },
            { name: 'Petia ', village: 'Bunesti ' },
            { name: 'Podeni ', village: 'Bunesti ' },
            { name: 'Unceşti ', simpleName: 'Uncesti ', village: 'Bunesti ' },
            { name: 'Şes ', simpleName: 'Ses ', village: 'Bunesti ' },
            { name: 'Maidan ', village: 'Cacica ' },
            { name: 'Pârteştii de Sus ', simpleName: 'Partestii de Sus ', village: 'Cacica ' },
            { name: 'Runcu ', village: 'Cacica ' },
            { name: 'Soloneţu Nou ', simpleName: 'Solonetu Nou ', village: 'Cacica ' },
            { name: 'Codru ', village: 'Cajvana ' },
            { name: 'Botoşaniţa Mare ', simpleName: 'Botosanita Mare ', village: 'Calafindesti ' },
            { name: 'Cârlibaba Nouă ', simpleName: 'Carlibaba Noua ', village: 'Carlibaba ' },
            { name: 'Iedu ', village: 'Carlibaba ' },
            { name: 'Valea Stânei ', simpleName: 'Valea Stanei ', village: 'Carlibaba ' },
            { name: 'Şesuri ', simpleName: 'Sesuri ', village: 'Carlibaba ' },
            { name: 'Ţibău ', simpleName: 'Tibau ', village: 'Carlibaba ' },
            { name: 'Botoş ', simpleName: 'Botos ', village: 'Ciocanesti ' },
            { name: 'Braşca ', simpleName: 'Brasca ', village: 'Ciprian Porumbescu ' },
            { name: 'Bălăceana ', simpleName: 'Balaceana ', village: 'Ciprian Porumbescu ' },
            { name: 'Ilişeşti ', simpleName: 'Ilisesti ', village: 'Ciprian Porumbescu ' },
            { name: 'Humoreni ', village: 'Comanesti ' },
            { name: 'Brăieşti ', simpleName: 'Braiesti ', village: 'Cornu Luncii ' },
            { name: 'Băişeşti ', simpleName: 'Baisesti ', village: 'Cornu Luncii ' },
            { name: 'Dumbrava ', village: 'Cornu Luncii ' },
            { name: 'Păiseni ', simpleName: 'Paiseni ', village: 'Cornu Luncii ' },
            { name: 'Sasca Mare ', village: 'Cornu Luncii ' },
            { name: 'Sasca Mică ', simpleName: 'Sasca Mica ', village: 'Cornu Luncii ' },
            { name: 'Sasca Nouă ', simpleName: 'Sasca Noua ', village: 'Cornu Luncii ' },
            { name: 'Şinca ', simpleName: 'Sinca ', village: 'Cornu Luncii ' },
            { name: 'Podu Coşnei ', simpleName: 'Podu Cosnei ', village: 'Cosna ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Cosna ' },
            { name: 'Teşna ', simpleName: 'Tesna ', village: 'Cosna ' },
            { name: 'Valea Bancului ', village: 'Cosna ' },
            { name: 'Chiril ', village: 'Crucea ' },
            { name: 'Cojoci ', village: 'Crucea ' },
            { name: 'Satu Mare ', village: 'Crucea ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Darmanesti ' },
            { name: 'Călineşti-Vasilache ', simpleName: 'Calinesti-Vasilache ', village: 'Darmanesti ' },
            { name: 'Dănila ', simpleName: 'Danila ', village: 'Darmanesti ' },
            { name: 'Măriţei ', simpleName: 'Maritei ', village: 'Darmanesti ' },
            { name: 'Măriţeia Mică ', simpleName: 'Mariteia Mica ', village: 'Darmanesti ' },
            { name: 'Budeni ', village: 'Dolhasca ' },
            { name: 'Gulia ', village: 'Dolhasca ' },
            { name: 'Poiana ', village: 'Dolhasca ' },
            { name: 'Poienari ', village: 'Dolhasca ' },
            { name: 'Probota ', village: 'Dolhasca ' },
            { name: 'Siliştea Nouă ', simpleName: 'Silistea Noua ', village: 'Dolhasca ' },
            { name: 'Valea Poienei ', village: 'Dolhasca ' },
            { name: 'Dolheştii Mici ', simpleName: 'Dolhestii Mici ', village: 'Dolhesti ' },
            { name: 'Dolheştii-Mari ', simpleName: 'Dolhestii-Mari ', village: 'Dolhesti ' },
            { name: 'Valea Bourei ', village: 'Dolhesti ' },
            { name: 'Dealu Floreni ', village: 'Dorna Candrenilor ' },
            { name: 'Poiana Negrii ', village: 'Dorna Candrenilor ' },
            { name: 'Cozăneşti ', simpleName: 'Cozanesti ', village: 'Dorna-Arini ' },
            { name: 'Gheorghiţeni ', simpleName: 'Gheorghiteni ', village: 'Dorna-Arini ' },
            { name: 'Ortoaia ', village: 'Dorna-Arini ' },
            { name: 'Rusca ', village: 'Dorna-Arini ' },
            { name: 'Sunători ', simpleName: 'Sunatori ', village: 'Dorna-Arini ' },
            { name: 'Iaz ', village: 'Dornesti ' },
            { name: 'Berchişeşti ', simpleName: 'Berchisesti ', village: 'Dragoiesti ' },
            { name: 'Corlata ', village: 'Dragoiesti ' },
            { name: 'Lucăceşti ', simpleName: 'Lucacesti ', village: 'Dragoiesti ' },
            { name: 'Măzănăeşti ', simpleName: 'Mazanaesti ', village: 'Dragoiesti ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Draguseni ' },
            { name: 'Gara Leu ', village: 'Draguseni ' },
            { name: 'Sălăgeni ', simpleName: 'Salageni ', village: 'Dumbraveni ' },
            { name: 'Şoldăneşti ', simpleName: 'Soldanesti ', village: 'Falticeni ' },
            { name: 'Ţarna Mare ', simpleName: 'Tarna Mare ', village: 'Falticeni ' },
            { name: 'Cotu Băii ', simpleName: 'Cotu Baii ', village: 'Fantana Mare ' },
            { name: 'Praxia ', village: 'Fantana Mare ' },
            { name: 'Spătăreşti ', simpleName: 'Spataresti ', village: 'Fantana Mare ' },
            { name: 'Băneşti ', simpleName: 'Banesti ', village: 'Fantanele ' },
            { name: 'Cotu Dobei ', village: 'Fantanele ' },
            { name: 'Slobozia ', village: 'Fantanele ' },
            { name: 'Stamate ', village: 'Fantanele ' },
            { name: 'Antoceni ', village: 'Forasti ' },
            { name: 'Boura ', village: 'Forasti ' },
            { name: 'Manolea ', village: 'Forasti ' },
            { name: 'Oniceni ', village: 'Forasti ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Forasti ' },
            { name: 'Ruşi ', simpleName: 'Rusi ', village: 'Forasti ' },
            { name: 'Uideşti ', simpleName: 'Uidesti ', village: 'Forasti ' },
            { name: 'Ţoleşti ', simpleName: 'Tolesti ', village: 'Forasti ' },
            { name: 'Bucşoaia ', simpleName: 'Bucsoaia ', village: 'Frasin ' },
            { name: 'Doroteia ', village: 'Frasin ' },
            { name: 'Plutoniţa ', simpleName: 'Plutonita ', village: 'Frasin ' },
            { name: 'Costişa ', simpleName: 'Costisa ', village: 'Fratautii Noi ' },
            { name: 'Măneuţi ', simpleName: 'Maneuti ', village: 'Fratautii Vechi ' },
            { name: 'Deia ', village: 'Frumosu ' },
            { name: 'Dragoşa ', simpleName: 'Dragosa ', village: 'Frumosu ' },
            { name: 'Botuş ', simpleName: 'Botus ', village: 'Fundu Moldovei ' },
            { name: 'Botuşel ', simpleName: 'Botusel ', village: 'Fundu Moldovei ' },
            { name: 'Braniştea ', simpleName: 'Branistea ', village: 'Fundu Moldovei ' },
            { name: 'Colacu ', village: 'Fundu Moldovei ' },
            { name: 'Delniţa ', simpleName: 'Delnita ', village: 'Fundu Moldovei ' },
            { name: 'Deluţ ', simpleName: 'Delut ', village: 'Fundu Moldovei ' },
            { name: 'Obcina ', village: 'Fundu Moldovei ' },
            { name: 'Plai ', village: 'Fundu Moldovei ' },
            { name: 'Smida Ungurenilor ', village: 'Fundu Moldovei ' },
            { name: 'Hurjuieni ', village: 'Galanesti ' },
            { name: 'Voitinel ', village: 'Galanesti ' },
            { name: 'Botoşaniţa Mică ', simpleName: 'Botosanita Mica ', village: 'Gramesti ' },
            { name: 'Bălineşti ', simpleName: 'Balinesti ', village: 'Gramesti ' },
            { name: 'Rudeşti ', simpleName: 'Rudesti ', village: 'Gramesti ' },
            { name: 'Verbia ', village: 'Gramesti ' },
            { name: 'Dumbrava ', village: 'Granicesti ' },
            { name: 'Gura Solcii ', village: 'Granicesti ' },
            { name: 'Iacobeşti ', simpleName: 'Iacobesti ', village: 'Granicesti ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Granicesti ' },
            { name: 'Slobozia Sucevei ', village: 'Granicesti ' },
            { name: 'Voroneţ ', simpleName: 'Voronet ', village: 'Gura Humorului ' },
            { name: 'Arţari ', simpleName: 'Artari ', village: 'Hantesti ' },
            { name: 'Bereşti ', simpleName: 'Beresti ', village: 'Hantesti ' },
            { name: 'Horodnic de Jos ', village: 'Horodnic ' },
            { name: 'Boteşti ', simpleName: 'Botesti ', village: 'Horodniceni ' },
            { name: 'Brădăţel ', simpleName: 'Bradatel ', village: 'Horodniceni ' },
            { name: 'Mihăieşti ', simpleName: 'Mihaiesti ', village: 'Horodniceni ' },
            { name: 'Rotopăneşti ', simpleName: 'Rotopanesti ', village: 'Horodniceni ' },
            { name: 'Mestecăniş ', simpleName: 'Mestecanis ', village: 'Iacobeni ' },
            { name: 'Lisaura ', village: 'Ipotesti ' },
            { name: 'Tişăuţi ', simpleName: 'Tisauti ', village: 'Ipotesti ' },
            { name: 'Bobeica ', village: 'Izvoarele Sucevei ' },
            { name: 'Brodina ', village: 'Izvoarele Sucevei ' },
            { name: 'Corni ', village: 'Liteni ' },
            { name: 'Rotunda ', village: 'Liteni ' },
            { name: 'Roşcani ', simpleName: 'Roscani ', village: 'Liteni ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Liteni ' },
            { name: 'Vercicani ', village: 'Liteni ' },
            { name: 'Iesle ', village: 'Malini ' },
            { name: 'Poiana Mărului ', simpleName: 'Poiana Marului ', village: 'Malini ' },
            { name: 'Pâraie ', simpleName: 'Paraie ', village: 'Malini ' },
            { name: 'Văleni-Stânişoara ', simpleName: 'Valeni-Stanisoara ', village: 'Malini ' },
            { name: 'Pleşa ', simpleName: 'Plesa ', village: 'Manastirea Humorului ' },
            { name: 'Poiana Micului ', village: 'Manastirea Humorului ' },
            { name: 'Bădeuţi ', simpleName: 'Badeuti ', village: 'Milisauti ' },
            { name: 'Gara ', village: 'Milisauti ' },
            { name: 'Lunca ', village: 'Milisauti ' },
            { name: 'Dragomirna ', village: 'Mitocu Dragomirnei ' },
            { name: 'Lipoveni ', village: 'Mitocu Dragomirnei ' },
            { name: 'Mitocaşi ', simpleName: 'Mitocasi ', village: 'Mitocu Dragomirnei ' },
            { name: 'Bulai ', village: 'Moara ' },
            { name: 'Frumoasa ', village: 'Moara ' },
            { name: 'Groapa Vlădichii ', simpleName: 'Groapa Vladichii ', village: 'Moara ' },
            { name: 'Liteni ', village: 'Moara ' },
            { name: 'Moara Carp ', village: 'Moara ' },
            { name: 'Moara Nica ', village: 'Moara ' },
            { name: 'Vornicenii Mari ', village: 'Moara ' },
            { name: 'Vornicenii Mici ', village: 'Moara ' },
            { name: 'Benia ', village: 'Moldova-Sulita ' },
            { name: 'Argel ', village: 'Moldovita ' },
            { name: 'Demacuşa ', simpleName: 'Demacusa ', village: 'Moldovita ' },
            { name: 'Raşca ', simpleName: 'Rasca ', village: 'Moldovita ' },
            { name: 'Baineţ ', simpleName: 'Bainet ', village: 'Musenita ' },
            { name: 'Bănceşti ', simpleName: 'Bancesti ', village: 'Musenita ' },
            { name: 'Climăuţi ', simpleName: 'Climauti ', village: 'Musenita ' },
            { name: 'Vicşani ', simpleName: 'Vicsani ', village: 'Musenita ' },
            { name: 'Văşcăuţi ', simpleName: 'Vascauti ', village: 'Musenita ' },
            { name: 'Tărnicioara ', simpleName: 'Tarnicioara ', village: 'Ostra ' },
            { name: 'Capu Codrului ', village: 'Paltinoasa ' },
            { name: 'Catrinari ', village: 'Panaci ' },
            { name: 'Coverca ', village: 'Panaci ' },
            { name: 'Drăgoiasa ', simpleName: 'Dragoiasa ', village: 'Panaci ' },
            { name: 'Glodu ', village: 'Panaci ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ', village: 'Panaci ' },
            { name: 'Deleni ', village: 'Partestii De Jos ' },
            { name: 'Varvata ', village: 'Partestii De Jos ' },
            { name: 'Vârfu Dealului ', simpleName: 'Varfu Dealului ', village: 'Partestii De Jos ' },
            { name: 'Căsoi ', simpleName: 'Casoi ', village: 'Poiana Stampei ' },
            { name: 'Dornişoara ', simpleName: 'Dornisoara ', village: 'Poiana Stampei ' },
            { name: 'Pilugani ', village: 'Poiana Stampei ' },
            { name: 'Prăleni ', simpleName: 'Praleni ', village: 'Poiana Stampei ' },
            { name: 'Teşna ', simpleName: 'Tesna ', village: 'Poiana Stampei ' },
            { name: 'Tătaru ', simpleName: 'Tataru ', village: 'Poiana Stampei ' },
            { name: 'Valea Putnei ', village: 'Pojorata ' },
            { name: 'Arghira ', village: 'Preutesti ' },
            { name: 'Bahna Arin ', village: 'Preutesti ' },
            { name: 'Basarabi ', village: 'Preutesti ' },
            { name: 'Huşi ', simpleName: 'Husi ', village: 'Preutesti ' },
            { name: 'Hârtop ', simpleName: 'Hartop ', village: 'Preutesti ' },
            { name: 'Leucuşeşti ', simpleName: 'Leucusesti ', village: 'Preutesti ' },
            { name: 'Gura Putnei ', village: 'Putna ' },
            { name: 'Lămăşeni ', simpleName: 'Lamaseni ', village: 'Radaseni ' },
            { name: 'Pocoleni ', village: 'Radaseni ' },
            { name: 'Buda ', village: 'Rasca ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ', village: 'Rasca ' },
            { name: 'Jahalia ', village: 'Rasca ' },
            { name: 'Slătioara ', simpleName: 'Slatioara ', village: 'Rasca ' },
            { name: 'Mereni ', village: 'Salcea ' },
            { name: 'Plopeni ', village: 'Salcea ' },
            { name: 'Prelipca ', village: 'Salcea ' },
            { name: 'Văratec ', simpleName: 'Varatec ', village: 'Salcea ' },
            { name: 'Gura Haitii ', village: 'Saru Dornei ' },
            { name: 'Neagra Şarului ', simpleName: 'Neagra Sarului ', village: 'Saru Dornei ' },
            { name: 'Plaiu Şarului ', simpleName: 'Plaiu Sarului ', village: 'Saru Dornei ' },
            { name: 'Sărişor ', simpleName: 'Sarisor ', village: 'Saru Dornei ' },
            { name: 'Sărişoru Mare ', simpleName: 'Sarisoru Mare ', village: 'Saru Dornei ' },
            { name: 'Şaru Bucovinei ', simpleName: 'Saru Bucovinei ', village: 'Saru Dornei ' },
            { name: 'Şarul Dornei ', simpleName: 'Sarul Dornei ', village: 'Saru Dornei ' },
            { name: 'Ţibeni ', simpleName: 'Tibeni ', village: 'Satu Mare ' },
            { name: 'Florinta ', village: 'Scheia ' },
            { name: 'Mihoveni ', village: 'Scheia ' },
            { name: 'Sfântu Ilie ', simpleName: 'Sfantu Ilie ', village: 'Scheia ' },
            { name: 'Trei Movile ', village: 'Scheia ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Serbauti ' },
            { name: 'Grigoreşti ', simpleName: 'Grigoresti ', village: 'Siminicea ' },
            { name: 'Mănăstioara ', simpleName: 'Manastioara ', village: 'Siret ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Siret ' },
            { name: 'Găineşti ', simpleName: 'Gainesti ', village: 'Slatina ' },
            { name: 'Herla ', village: 'Slatina ' },
            { name: 'Poieni-Solca ', village: 'Solca ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Stroiesti ' },
            { name: 'Zahareşti ', simpleName: 'Zaharesti ', village: 'Stroiesti ' },
            { name: 'Gemenea ', village: 'Stulpicani ' },
            { name: 'Negrileasa ', village: 'Stulpicani ' },
            { name: 'Slătioara ', simpleName: 'Slatioara ', village: 'Stulpicani ' },
            { name: 'Vadu Negrilesei ', village: 'Stulpicani ' },
            { name: 'Voievodeasa ', village: 'Sucevita ' },
            { name: 'Costâna ', simpleName: 'Costana ', village: 'Todiresti ' },
            { name: 'Părhăuţi ', simpleName: 'Parhauti ', village: 'Todiresti ' },
            { name: 'Soloneţ ', simpleName: 'Solonet ', village: 'Todiresti ' },
            { name: 'Sârghieşti ', simpleName: 'Sarghiesti ', village: 'Todiresti ' },
            { name: 'Chilişeni ', simpleName: 'Chiliseni ', village: 'Udesti ' },
            { name: 'Luncuşoara ', simpleName: 'Luncusoara ', village: 'Udesti ' },
            { name: 'Mănăstioara ', simpleName: 'Manastioara ', village: 'Udesti ' },
            { name: 'Plăvălari ', simpleName: 'Plavalari ', village: 'Udesti ' },
            { name: 'Poieni-Suceava ', village: 'Udesti ' },
            { name: 'Racova ', village: 'Udesti ' },
            { name: 'Reuseni ', village: 'Udesti ' },
            { name: 'Ruşii-Mănăstioara ', simpleName: 'Rusii-Manastioara ', village: 'Udesti ' },
            { name: 'Securiceni ', village: 'Udesti ' },
            { name: 'Ştirbăţ ', simpleName: 'Stirbat ', village: 'Udesti ' },
            { name: 'Costileva ', village: 'Ulma ' },
            { name: 'Lupcina ', village: 'Ulma ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Ulma ' },
            { name: 'Nisipitu ', village: 'Ulma ' },
            { name: 'Ciumuleşti ', simpleName: 'Ciumulesti ', village: 'Vadu Moldovei ' },
            { name: 'Cămârzani ', simpleName: 'Camarzani ', village: 'Vadu Moldovei ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ', village: 'Vadu Moldovei ' },
            { name: 'Ioneasa ', village: 'Vadu Moldovei ' },
            { name: 'Mesteceni ', village: 'Vadu Moldovei ' },
            { name: 'Movileni ', village: 'Vadu Moldovei ' },
            { name: 'Nigoteşti ', simpleName: 'Nigotesti ', village: 'Vadu Moldovei ' },
            { name: 'Mironu ', village: 'Valea Moldovei ' },
            { name: 'Molid ', village: 'Vama ' },
            { name: 'Prisaca Dornei ', village: 'Vama ' },
            { name: 'Strâmtura ', simpleName: 'Stramtura ', village: 'Vama ' },
            { name: 'Argestru ', village: 'Vatra Dornei ' },
            { name: 'Roşu ', simpleName: 'Rosu ', village: 'Vatra Dornei ' },
            { name: 'Todireni ', village: 'Vatra Dornei ' },
            { name: 'Ciumârna ', simpleName: 'Ciumarna ', village: 'Vatra Moldovitei ' },
            { name: 'Paltinu ', village: 'Vatra Moldovitei ' },
            { name: 'Bursuceni ', village: 'Veresti ' },
            { name: 'Corocăieşti ', simpleName: 'Corocaiesti ', village: 'Veresti ' },
            { name: 'Hancea ', village: 'Veresti ' },
            { name: 'Bivolăria ', simpleName: 'Bivolaria ', village: 'Vicovu De Sus ' },
            { name: 'Burla ', village: 'Volovat ' },
            { name: 'Giurgeşti ', simpleName: 'Giurgesti ', village: 'Vulturesti ' },
            { name: 'Hreaţca ', simpleName: 'Hreatca ', village: 'Vulturesti ' },
            { name: 'Jacota ', village: 'Vulturesti ' },
            { name: 'Mereşti ', simpleName: 'Meresti ', village: 'Vulturesti ' },
            { name: 'Osoi ', village: 'Vulturesti ' },
            { name: 'Pleşeşti ', simpleName: 'Plesesti ', village: 'Vulturesti ' },
            { name: 'Valea Glodului ', village: 'Vulturesti ' },
            { name: 'Badragi ', village: 'Zamostea ' },
            { name: 'Ciomârtan ', simpleName: 'Ciomartan ', village: 'Zamostea ' },
            { name: 'Cojocăreni ', simpleName: 'Cojocareni ', village: 'Zamostea ' },
            { name: 'Corpaci ', village: 'Zamostea ' },
            { name: 'Lunca ', village: 'Zamostea ' },
            { name: 'Nicani ', village: 'Zamostea ' },
            { name: 'Răuţeni ', simpleName: 'Rauteni ', village: 'Zamostea ' },
            { name: 'Tăuteşti ', simpleName: 'Tautesti ', village: 'Zamostea ' },
            { name: 'Buda ', village: 'Zvoristea ' },
            { name: 'Dealu ', village: 'Zvoristea ' },
            { name: 'Poiana ', village: 'Zvoristea ' },
            { name: 'Slobozia ', village: 'Zvoristea ' },
            { name: 'Stânca ', simpleName: 'Stanca ', village: 'Zvoristea ' },
            { name: 'Stâncuţa ', simpleName: 'Stancuta ', village: 'Zvoristea ' },
            {
                name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Zvoristea '
            }
        ]
    },
    {
        auto: 'TR ',
        name: 'Teleorman ',
        cities: [
            { name: 'Alexandria ' },
            { name: 'Balaci ' },
            { name: 'Blejeşti ', simpleName: 'Blejesti ' },
            { name: 'Bogdana ' },
            { name: 'Botoroaga ' },
            { name: 'Bragadiru ' },
            { name: 'Brânceni ', simpleName: 'Branceni ' },
            { name: 'Bujoreni ' },
            { name: 'Bujoru ' },
            { name: 'Buzescu ' },
            { name: 'Băbăiţa ', simpleName: 'Babaita ' },
            { name: 'Cervenia ' },
            { name: 'Ciuperceni ' },
            { name: 'Conţeşti ', simpleName: 'Contesti ' },
            { name: 'Cosmeşti ', simpleName: 'Cosmesti ' },
            { name: 'Crevenicu ' },
            { name: 'Crângeni ', simpleName: 'Crangeni ' },
            { name: 'Crângu ', simpleName: 'Crangu ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ' },
            { name: 'Călmăţuiu ', simpleName: 'Calmatuiu ' },
            { name: 'Călmăţuiu de Sus ', simpleName: 'Calmatuiu de Sus ' },
            { name: 'Dideşti ', simpleName: 'Didesti ' },
            { name: 'Dobroteşti ', simpleName: 'Dobrotesti ' },
            { name: 'Drăcşenei ', simpleName: 'Dracsenei ' },
            { name: 'Drăgăneşti de Vede ', simpleName: 'Draganesti de Vede ' },
            { name: 'Drăgăneşti-Vlaşca ', simpleName: 'Draganesti-Vlasca ' },
            { name: 'Frumoasa ' },
            { name: 'Furculeşti ', simpleName: 'Furculesti ' },
            { name: 'Gratia ' },
            { name: 'Gălăteni ', simpleName: 'Galateni ' },
            { name: 'Islaz ' },
            { name: 'Izvoarele ' },
            { name: 'Lisa ' },
            { name: 'Liţa ', simpleName: 'Lita ' },
            { name: 'Lunca ' },
            { name: 'Mavrodin ' },
            { name: 'Moşteni ', simpleName: 'Mosteni ' },
            { name: 'Mârzăneşti ', simpleName: 'Marzanesti ' },
            { name: 'Măgura ', simpleName: 'Magura ' },
            { name: 'Măldăeni ', simpleName: 'Maldaeni ' },
            { name: 'Nanov ' },
            { name: 'Necşeşti ', simpleName: 'Necsesti ' },
            { name: 'Nenciuleşti ', simpleName: 'Nenciulesti ' },
            { name: 'Năsturelu ', simpleName: 'Nasturelu ' },
            { name: 'Olteni ' },
            { name: 'Peretu ' },
            { name: 'Piatra ' },
            { name: 'Pietroşani ', simpleName: 'Pietrosani ' },
            { name: 'Plopii-Slăviteşti ', simpleName: 'Plopii-Slavitesti ' },
            { name: 'Plosca ' },
            { name: 'Poeni ' },
            { name: 'Poroschia ' },
            { name: 'Putineiu ' },
            { name: 'Rosiori de Vede ' },
            { name: 'Răsmireşti ', simpleName: 'Rasmiresti ' },
            { name: 'Salcia ' },
            { name: 'Scrioaştea ', simpleName: 'Scrioastea ' },
            { name: 'Scurtu Mare ' },
            { name: 'Seaca ' },
            { name: 'Segarcea-Vale ' },
            { name: 'Sfinţeşti ', simpleName: 'Sfintesti ' },
            { name: 'Siliştea ', simpleName: 'Silistea ' },
            { name: 'Siliştea-Gumeşti ', simpleName: 'Silistea-Gumesti ' },
            { name: 'Slobozia Mândra ', simpleName: 'Slobozia Mandra ' },
            { name: 'Smârdioasa ', simpleName: 'Smardioasa ' },
            { name: 'Stejaru ' },
            { name: 'Suhaia ' },
            { name: 'Sârbeni ', simpleName: 'Sarbeni ' },
            { name: 'Săceni ', simpleName: 'Saceni ' },
            { name: 'Traian ' },
            { name: 'Trivalea-Moşteni ', simpleName: 'Trivalea-Mosteni ' },
            { name: 'Troianul ' },
            { name: 'Turnu Măgurele ', simpleName: 'Turnu Magurele ' },
            { name: 'Tătărăştii de Jos ', simpleName: 'Tatarastii de Jos ' },
            { name: 'Tătărăştii de Sus ', simpleName: 'Tatarastii de Sus ' },
            { name: 'Vedea ' },
            { name: 'Videle ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ' },
            { name: 'Vitanesti ' },
            { name: 'Zimnicea ' },
            { name: 'Zâmbreasca ', simpleName: 'Zambreasca ' },
            { name: 'Ştorobăneasa ', simpleName: 'Storobaneasa ' },
            { name: 'Ţigăneşti ', simpleName: 'Tiganesti ' },
            { name: 'Clăniţa ', simpleName: 'Clanita ', village: 'Babaita ' },
            { name: 'Frăsinet ', simpleName: 'Frasinet ', village: 'Babaita ' },
            { name: 'Merişani ', simpleName: 'Merisani ', village: 'Babaita ' },
            { name: 'Burdeni ', village: 'Balaci ' },
            { name: 'Tecuci ', village: 'Balaci ' },
            { name: 'Baciu ', village: 'Blejesti ' },
            { name: 'Sericu ', village: 'Blejesti ' },
            { name: 'Broşteanca ', simpleName: 'Brosteanca ', village: 'Bogdana ' },
            { name: 'Ulmeni ', village: 'Bogdana ' },
            { name: 'Urluiu ', village: 'Bogdana ' },
            { name: 'Călugaru ', simpleName: 'Calugaru ', village: 'Botoroaga ' },
            { name: 'Tunari ', village: 'Botoroaga ' },
            { name: 'Târnava ', simpleName: 'Tarnava ', village: 'Botoroaga ' },
            { name: 'Valea Cireşului ', simpleName: 'Valea Ciresului ', village: 'Botoroaga ' },
            { name: 'Dărvaş ', simpleName: 'Darvas ', village: 'Bujoreni ' },
            { name: 'Prunaru ', village: 'Bujoreni ' },
            { name: 'Antoneşti ', simpleName: 'Antonesti ', village: 'Calinesti ' },
            { name: 'Copăceanca ', simpleName: 'Copaceanca ', village: 'Calinesti ' },
            { name: 'Licuriciu ', village: 'Calinesti ' },
            { name: 'Mariţa ', simpleName: 'Marita ', village: 'Calinesti ' },
            { name: 'Bujoru ', village: 'Calmatuiu ' },
            { name: 'Caravaneţi ', simpleName: 'Caravaneti ', village: 'Calmatuiu ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Calmatuiu ' },
            { name: 'Băcăleşti ', simpleName: 'Bacalesti ', village: 'Calmatuiu De Sus ' },
            { name: 'Ionaşcu ', simpleName: 'Ionascu ', village: 'Calmatuiu De Sus ' },
            { name: 'Baldovineşti ', simpleName: 'Baldovinesti ', village: 'Ciolanesti ' },
            { name: 'Ciolăneştii din Deal ', simpleName: 'Ciolanestii din Deal ', village: 'Ciolanesti ' },
            { name: 'Ciolăneştii din Vale ', simpleName: 'Ciolanestii din Vale ', village: 'Ciolanesti ' },
            { name: 'Poiana ', village: 'Ciuperceni ' },
            { name: 'Ciuperceni ', village: 'Cosmesti ' },
            { name: 'Balta Sărată ', simpleName: 'Balta Sarata ', village: 'Crangeni ' },
            { name: 'Dorobanţu ', simpleName: 'Dorobantu ', village: 'Crangeni ' },
            { name: 'Stejaru ', village: 'Crangeni ' },
            { name: 'Dracea ', village: 'Crangu ' },
            { name: 'Florica ', village: 'Crangu ' },
            { name: 'Secara ', village: 'Crangu ' },
            { name: 'Zlata ', village: 'Crangu ' },
            { name: 'Răduleşti ', simpleName: 'Radulesti ', village: 'Crevenicu ' },
            { name: 'Satu Nou ', village: 'Didesti ' },
            { name: 'Însurăţei ', simpleName: 'Insuratei ', village: 'Didesti ' },
            { name: 'Merişani ', simpleName: 'Merisani ', village: 'Dobrotesti ' },
            { name: 'Beuca ', village: 'Dracsenei ' },
            { name: 'Drăcşani ', simpleName: 'Dracsani ', village: 'Dracsenei ' },
            { name: 'Odobeasca ', village: 'Dracsenei ' },
            { name: 'Plopi ', village: 'Dracsenei ' },
            { name: 'Satul Vechi ', village: 'Dracsenei ' },
            { name: 'Măgura cu Liliac ', simpleName: 'Magura cu Liliac ', village: 'Draganesti De Vede ' },
            { name: 'Văcăreşti ', simpleName: 'Vacaresti ', village: 'Draganesti De Vede ' },
            { name: 'Comoara ', village: 'Draganesti-Vlasca ' },
            { name: 'Văceni ', simpleName: 'Vaceni ', village: 'Draganesti-Vlasca ' },
            { name: 'Păuleasca ', simpleName: 'Pauleasca ', village: 'Frumoasa ' },
            { name: 'Moşteni ', simpleName: 'Mosteni ', village: 'Furculesti ' },
            { name: 'Spătărei ', simpleName: 'Spatarei ', village: 'Furculesti ' },
            { name: 'Voievoda ', village: 'Furculesti ' },
            { name: 'Bâscoveni ', simpleName: 'Bascoveni ', village: 'Galateni ' },
            { name: 'Grădişteanca ', simpleName: 'Gradisteanca ', village: 'Galateni ' },
            { name: 'Ciurari-Deal ', village: 'Gratia ' },
            { name: 'Drăghineşti ', simpleName: 'Draghinesti ', village: 'Gratia ' },
            { name: 'Moldoveni ', village: 'Islaz ' },
            { name: 'Vânători ', simpleName: 'Vanatori ', village: 'Lisa ' },
            { name: 'Pleaşov ', simpleName: 'Pleasov ', village: 'Lunca ' },
            { name: 'Prundu ', village: 'Lunca ' },
            { name: 'Saelele ', village: 'Lunca ' },
            { name: 'Guruieni ', village: 'Magura ' },
            { name: 'Cernetu ', village: 'Marzanesti ' },
            { name: 'Teleormanu ', village: 'Marzanesti ' },
            { name: 'Valea Părului ', simpleName: 'Valea Parului ', village: 'Marzanesti ' },
            { name: 'Merenii de Jos ', village: 'Mereni ' },
            { name: 'Merenii de Sus ', village: 'Mereni ' },
            { name: 'Ştefeni ', simpleName: 'Stefeni ', village: 'Mereni ' },
            { name: 'Zimnicele ', village: 'Nasturelu ' },
            { name: 'Belciug ', village: 'Necsesti ' },
            { name: 'Gârdeşti ', simpleName: 'Gardesti ', village: 'Necsesti ' },
            { name: 'Păru Rotund ', simpleName: 'Paru Rotund ', village: 'Nenciulesti ' },
            { name: 'Perii Broşteni ', simpleName: 'Perii Brosteni ', village: 'Olteni ' },
            { name: 'Lăceni ', simpleName: 'Laceni ', village: 'Orbeasca ' },
            { name: 'Orbeasca de Jos ', village: 'Orbeasca ' },
            { name: 'Orbeasca de Sus ', village: 'Orbeasca ' },
            { name: 'Beciu ', village: 'Plopii-Slavitesti ' },
            { name: 'Brâncoveanca ', simpleName: 'Brancoveanca ', village: 'Plopii-Slavitesti ' },
            { name: 'Bârseştii de Jos ', simpleName: 'Barsestii de Jos ', village: 'Plopii-Slavitesti ' },
            { name: 'Dudu ', village: 'Plopii-Slavitesti ' },
            { name: 'Smârdan ', simpleName: 'Smardan ', village: 'Plopii-Slavitesti ' },
            { name: 'Banov ', village: 'Poeni ' },
            { name: 'Brăteşti ', simpleName: 'Bratesti ', village: 'Poeni ' },
            { name: 'Cătunu ', simpleName: 'Catunu ', village: 'Poeni ' },
            { name: 'Preajba ', village: 'Poeni ' },
            { name: 'Vătaşi ', simpleName: 'Vatasi ', village: 'Poeni ' },
            { name: 'Ţăvârlău ', simpleName: 'Tavarlau ', village: 'Poeni ' },
            { name: 'Calomfireşti ', simpleName: 'Calomfiresti ', village: 'Poroschia ' },
            { name: 'Băduleasa ', simpleName: 'Baduleasa ', village: 'Putineiu ' },
            { name: 'Cârlomanu ', simpleName: 'Carlomanu ', village: 'Putineiu ' },
            { name: 'Cetatea ', village: 'Radoiesti ' },
            { name: 'Rădoieşti-Deal ', simpleName: 'Radoiesti-Deal ', village: 'Radoiesti ' },
            { name: 'Rădoieşti-Vale ', simpleName: 'Radoiesti-Vale ', village: 'Radoiesti ' },
            { name: 'Ludăneasca ', simpleName: 'Ludaneasca ', village: 'Rasmiresti ' },
            { name: 'Butculeşti ', simpleName: 'Butculesti ', village: 'Saceni ' },
            { name: 'Ciurari ', village: 'Saceni ' },
            { name: 'Băneasa ', simpleName: 'Baneasa ', village: 'Salcia ' },
            { name: 'Tudor Vladimirescu ', village: 'Salcia ' },
            { name: 'Sârbenii de Jos ', simpleName: 'Sarbenii de Jos ', village: 'Sarbeni ' },
            { name: 'Udeni ', village: 'Sarbeni ' },
            { name: 'Brebina ', village: 'Scrioastea ' },
            { name: 'Cucueţi ', simpleName: 'Cucueti ', village: 'Scrioastea ' },
            { name: 'Viile ', village: 'Scrioastea ' },
            { name: 'Albeni ', village: 'Scurtu Mare ' },
            { name: 'Drăceşti ', simpleName: 'Dracesti ', village: 'Scurtu Mare ' },
            { name: 'Negrileşti ', simpleName: 'Negrilesti ', village: 'Scurtu Mare ' },
            { name: 'Scurtu-Slăveşti ', simpleName: 'Scurtu-Slavesti ', village: 'Scurtu Mare ' },
            { name: 'Valea Poştei ', simpleName: 'Valea Postei ', village: 'Scurtu Mare ' },
            { name: 'Năvodari ', simpleName: 'Navodari ', village: 'Seaca ' },
            { name: 'Olteanca ', village: 'Segarcea-Vale ' },
            { name: 'Segarcea-Deal ', village: 'Segarcea-Vale ' },
            { name: 'Buteşti ', simpleName: 'Butesti ', village: 'Silistea ' },
            { name: 'Purani ', village: 'Silistea ' },
            { name: 'Puranii de Sus ', village: 'Silistea ' },
            { name: 'Siliştea Mică ', simpleName: 'Silistea Mica ', village: 'Silistea ' },
            { name: 'Uda-Clocociov ', village: 'Slobozia Mandra ' },
            { name: 'Uda-Paciurea ', village: 'Slobozia Mandra ' },
            { name: 'Şoimu ', simpleName: 'Soimu ', village: 'Smardioasa ' },
            { name: 'Bratcovu ', village: 'Stejaru ' },
            { name: 'Gresia ', village: 'Stejaru ' },
            { name: 'Socetu ', village: 'Stejaru ' },
            { name: 'Beiu ', village: 'Storobaneasa ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Suhaia ' },
            { name: 'Linia Costii ', village: 'Talpa ' },
            { name: 'Rotăreşti ', simpleName: 'Rotaresti ', village: 'Talpa ' },
            { name: 'Talpa Poştei ', simpleName: 'Talpa Postei ', village: 'Talpa ' },
            { name: 'Talpa-Bâscoveni ', simpleName: 'Talpa-Bascoveni ', village: 'Talpa ' },
            { name: 'Talpa-Ogrăzile ', simpleName: 'Talpa-Ograzile ', village: 'Talpa ' },
            { name: 'Lada ', village: 'Tatarastii De Jos ' },
            { name: 'Negreni ', village: 'Tatarastii De Jos ' },
            { name: 'Negrenii de Sus ', village: 'Tatarastii De Jos ' },
            { name: 'Negrenii-Osebiţi ', simpleName: 'Negrenii-Osebiti ', village: 'Tatarastii De Jos ' },
            { name: 'Obârtu ', simpleName: 'Obartu ', village: 'Tatarastii De Jos ' },
            { name: 'Slăveşti ', simpleName: 'Slavesti ', village: 'Tatarastii De Jos ' },
            { name: 'Dobreni ', village: 'Tatarastii De Sus ' },
            { name: 'Udupu ', village: 'Tatarastii De Sus ' },
            { name: 'Brătăşani ', simpleName: 'Bratasani ', village: 'Trivalea-Mosteni ' },
            { name: 'Deparaţi ', simpleName: 'Deparati ', village: 'Trivalea-Mosteni ' },
            { name: 'Dulceni ', village: 'Troianul ' },
            { name: 'Vatra ', village: 'Troianul ' },
            { name: 'Gărăgău ', simpleName: 'Garagau ', village: 'Vartoape ' },
            { name: 'Vârtoapele de Jos ', simpleName: 'Vartoapele de Jos ', village: 'Vartoape ' },
            { name: 'Vârtoapele de Sus ', simpleName: 'Vartoapele de Sus ', village: 'Vartoape ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Vedea ' },
            { name: 'Coşoteni ', simpleName: 'Cosoteni ', village: 'Vedea ' },
            { name: 'Dulceanca ', village: 'Vedea ' },
            { name: 'Meri ', village: 'Vedea ' },
            { name: 'Coşoaia ', simpleName: 'Cosoaia ', village: 'Videle ' },
            { name: 'Purani ', village: 'Vitanesti ' },
            { name: 'Schitu Poienari ', village: 'Vitanesti ' },
            {
                name: 'Siliştea ', simpleName: 'Silistea ', village: 'Vitanesti '
            }
        ]
    },
    {
        auto: 'TM ',
        name: 'Timiş ',
        cities: [
            { name: 'Balinţ ', simpleName: 'Balint ' },
            { name: 'Banloc ' },
            { name: 'Bara ' },
            { name: 'Beba Veche ' },
            { name: 'Becicherecu Mic ' },
            { name: 'Belinţ ', simpleName: 'Belint ' },
            { name: 'Bethausen ' },
            { name: 'Biled ' },
            { name: 'Bogda ' },
            { name: 'Boldur ' },
            { name: 'Brestovăţ ', simpleName: 'Brestovat ' },
            { name: 'Buziaş ', simpleName: 'Buzias ' },
            { name: 'Bârna ', simpleName: 'Barna ' },
            { name: 'Cenad ' },
            { name: 'Cenei ' },
            { name: 'Chevereşu Mare ', simpleName: 'Cheveresu Mare ' },
            { name: 'Ciacova ' },
            { name: 'Comloşu Mare ', simpleName: 'Comlosu Mare ' },
            { name: 'Coşteiu ', simpleName: 'Costeiu ' },
            { name: 'Criciova ' },
            { name: 'Curtea ' },
            { name: 'Cărpiniş ', simpleName: 'Carpinis ' },
            { name: 'Darova ' },
            { name: 'Denta ' },
            { name: 'Deta ' },
            { name: 'Dudeştii Vechi ', simpleName: 'Dudestii Vechi ' },
            { name: 'Dumbrava ' },
            { name: 'Dumbrăviţa ', simpleName: 'Dumbravita ' },
            { name: 'Foeni ' },
            { name: 'Fârdea ', simpleName: 'Fardea ' },
            { name: 'Făget ', simpleName: 'Faget ' },
            { name: 'Gavojdia ' },
            { name: 'Ghiroda ' },
            { name: 'Ghizela ' },
            { name: 'Giarmata ' },
            { name: 'Giera ' },
            { name: 'Giroc ' },
            { name: 'Giulvăz ', simpleName: 'Giulvaz ' },
            { name: 'Gătaia ', simpleName: 'Gataia ' },
            { name: 'Jamu Mare ' },
            { name: 'Jebel ' },
            { name: 'Jimbolia ' },
            { name: 'Lenauheim ' },
            { name: 'Liebling ' },
            { name: 'Lovrin ' },
            { name: 'Lugoj ' },
            { name: 'Margina ' },
            { name: 'Maşloc ', simpleName: 'Masloc ' },
            { name: 'Moraviţa ', simpleName: 'Moravita ' },
            { name: 'Moşniţa Nouă ', simpleName: 'Mosnita Noua ' },
            { name: 'Mănăştiur ', simpleName: 'Manastiur ' },
            { name: 'Niţchidorf ', simpleName: 'Nitchidorf ' },
            { name: 'Nădrag ', simpleName: 'Nadrag ' },
            { name: 'Ohaba Lungă ', simpleName: 'Ohaba Lunga ' },
            { name: 'Orţişoara ', simpleName: 'Ortisoara ' },
            { name: 'Peciu Nou ' },
            { name: 'Periam ' },
            { name: 'Pietroasa ' },
            { name: 'Pişchia ', simpleName: 'Pischia ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ' },
            { name: 'Recaş ', simpleName: 'Recas ' },
            { name: 'Remetea Mare ' },
            { name: 'Sacoşu Turcesc ', simpleName: 'Sacosu Turcesc ' },
            { name: 'Satchinez ' },
            { name: 'Secaş ', simpleName: 'Secas ' },
            { name: 'Sânandrei ', simpleName: 'Sanandrei ' },
            { name: 'Sânmihaiu Român ', simpleName: 'Sanmihaiu Roman ' },
            { name: 'Sânnicolau Mare ', simpleName: 'Sannicolau Mare ' },
            { name: 'Sânpetru Mare ', simpleName: 'Sanpetru Mare ' },
            { name: 'Săcălaz ', simpleName: 'Sacalaz ' },
            { name: 'Teremia Mare ' },
            { name: 'Timişoara ', simpleName: 'Timisoara ' },
            { name: 'Tomeşti ', simpleName: 'Tomesti ' },
            { name: 'Topolovăţu Mare ', simpleName: 'Topolovatu Mare ' },
            { name: 'Tormac ' },
            { name: 'Traian Vuia ' },
            { name: 'Uivar ' },
            { name: 'Variaş ', simpleName: 'Varias ' },
            { name: 'Victor Vlad Delamarina ' },
            { name: 'Voiteg ' },
            { name: 'Şag ', simpleName: 'Sag ' },
            { name: 'Ştiuca ', simpleName: 'Stiuca ' },
            { name: 'Bodo ', village: 'Balint ' },
            { name: 'Fădimac ', simpleName: 'Fadimac ', village: 'Balint ' },
            { name: 'Târgovişte ', simpleName: 'Targoviste ', village: 'Balint ' },
            { name: 'Dolaţ ', simpleName: 'Dolat ', village: 'Banloc ' },
            { name: 'Livezile ', village: 'Banloc ' },
            { name: 'Ofseniţa ', simpleName: 'Ofsenita ', village: 'Banloc ' },
            { name: 'Partoş ', simpleName: 'Partos ', village: 'Banloc ' },
            { name: 'Soca ', village: 'Banloc ' },
            { name: 'Dobreşti ', simpleName: 'Dobresti ', village: 'Bara ' },
            { name: 'Lăpuşnic ', simpleName: 'Lapusnic ', village: 'Bara ' },
            { name: 'Rădmăneşti ', simpleName: 'Radmanesti ', village: 'Bara ' },
            { name: 'Spata ', village: 'Bara ' },
            { name: 'Boteşti ', simpleName: 'Botesti ', village: 'Barna ' },
            { name: 'Botineşti ', simpleName: 'Botinesti ', village: 'Barna ' },
            { name: 'Drinova ', village: 'Barna ' },
            { name: 'Jureşti ', simpleName: 'Juresti ', village: 'Barna ' },
            { name: 'Pogăneşti ', simpleName: 'Poganesti ', village: 'Barna ' },
            { name: 'Sărăzani ', simpleName: 'Sarazani ', village: 'Barna ' },
            { name: 'Cherestur ', village: 'Beba Veche ' },
            { name: 'Pordeanu ', village: 'Beba Veche ' },
            { name: 'Dudeştii Noi ', simpleName: 'Dudestii Noi ', village: 'Becicherecu Mic ' },
            { name: 'Babşa ', simpleName: 'Babsa ', village: 'Belint ' },
            { name: 'Chizătău ', simpleName: 'Chizatau ', village: 'Belint ' },
            { name: 'Gruni ', village: 'Belint ' },
            { name: 'Cladova ', village: 'Bethausen ' },
            { name: 'Cliciova ', village: 'Bethausen ' },
            { name: 'Cutina ', village: 'Bethausen ' },
            { name: 'Leucuşeşti ', simpleName: 'Leucusesti ', village: 'Bethausen ' },
            { name: 'Nevrincea ', village: 'Bethausen ' },
            { name: 'Uihei ', village: 'Biled ' },
            { name: 'Şandra ', simpleName: 'Sandra ', village: 'Biled ' },
            { name: 'Altringen ', village: 'Bogda ' },
            { name: 'Buzad ', village: 'Bogda ' },
            { name: 'Charlotenburg ', village: 'Bogda ' },
            { name: 'Comeat ', village: 'Bogda ' },
            { name: 'Sintar ', village: 'Bogda ' },
            { name: 'Jabăr ', simpleName: 'Jabar ', village: 'Boldur ' },
            { name: 'Ohaba-Forgaci ', village: 'Boldur ' },
            { name: 'Sinersig ', village: 'Boldur ' },
            { name: 'Coşarii ', simpleName: 'Cosarii ', village: 'Brestovat ' },
            { name: 'Hodoş ', simpleName: 'Hodos ', village: 'Brestovat ' },
            { name: 'Lucareţ ', simpleName: 'Lucaret ', village: 'Brestovat ' },
            { name: 'Teş ', simpleName: 'Tes ', village: 'Brestovat ' },
            { name: 'Bacova ', village: 'Buzias ' },
            { name: 'Silagiu ', village: 'Buzias ' },
            { name: 'Iecea Mare ', village: 'Carpinis ' },
            { name: 'Iecea Mică ', simpleName: 'Iecea Mica ', village: 'Carpinis ' },
            { name: 'Bobda ', village: 'Cenei ' },
            { name: 'Checea ', village: 'Cenei ' },
            { name: 'Dragşina ', simpleName: 'Dragsina ', village: 'Cheveresu Mare ' },
            { name: 'Vucova ', village: 'Cheveresu Mare ' },
            { name: 'Cebza ', village: 'Ciacova ' },
            { name: 'Gad ', village: 'Ciacova ' },
            { name: 'Ghilad ', village: 'Ciacova ' },
            { name: 'Macedonia ', village: 'Ciacova ' },
            { name: 'Obad ', village: 'Ciacova ' },
            { name: 'Petroman ', village: 'Ciacova ' },
            { name: 'Comloşu Mic ', simpleName: 'Comlosu Mic ', village: 'Comlosu Mare ' },
            { name: 'Lunga ', village: 'Comlosu Mare ' },
            { name: 'Hezeriş ', simpleName: 'Hezeris ', village: 'Costeiu ' },
            { name: 'Păru ', simpleName: 'Paru ', village: 'Costeiu ' },
            { name: 'Valea Lungă Română ', simpleName: 'Valea Lunga Romana ', village: 'Costeiu ' },
            { name: 'Ţipari ', simpleName: 'Tipari ', village: 'Costeiu ' },
            { name: 'Cireşu ', simpleName: 'Ciresu ', village: 'Criciova ' },
            { name: 'Cireşu Mic ', simpleName: 'Ciresu Mic ', village: 'Criciova ' },
            { name: 'Jdioara ', village: 'Criciova ' },
            { name: 'Coşava ', simpleName: 'Cosava ', village: 'Curtea ' },
            { name: 'Homojdia ', village: 'Curtea ' },
            { name: 'Hodoş ', simpleName: 'Hodos ', village: 'Darova ' },
            { name: 'Sacoşu Mare ', simpleName: 'Sacosu Mare ', village: 'Darova ' },
            { name: 'Breştea ', simpleName: 'Brestea ', village: 'Denta ' },
            { name: 'Roviniţa Mare ', simpleName: 'Rovinita Mare ', village: 'Denta ' },
            { name: 'Roviniţa Mică ', simpleName: 'Rovinita Mica ', village: 'Denta ' },
            { name: 'Opatiţa ', simpleName: 'Opatita ', village: 'Deta ' },
            { name: 'Cheglevici ', village: 'Dudestii Vechi ' },
            { name: 'Colonia Bulgară ', simpleName: 'Colonia Bulgara ', village: 'Dudestii Vechi ' },
            { name: 'Vălcani ', simpleName: 'Valcani ', village: 'Dudestii Vechi ' },
            { name: 'Bucovăţ ', simpleName: 'Bucovat ', village: 'Dumbrava ' },
            { name: 'Răchita ', simpleName: 'Rachita ', village: 'Dumbrava ' },
            { name: 'Begheiu Mic ', village: 'Faget ' },
            { name: 'Bichigi ', village: 'Faget ' },
            { name: 'Brăneşti ', simpleName: 'Branesti ', village: 'Faget ' },
            { name: 'Bunea Mare ', village: 'Faget ' },
            { name: 'Bunea Mică ', simpleName: 'Bunea Mica ', village: 'Faget ' },
            { name: 'Băteşti ', simpleName: 'Batesti ', village: 'Faget ' },
            { name: 'Colonia Mică ', simpleName: 'Colonia Mica ', village: 'Faget ' },
            { name: 'Jupâneşti ', simpleName: 'Jupanesti ', village: 'Faget ' },
            { name: 'Povârgina ', simpleName: 'Povargina ', village: 'Faget ' },
            { name: 'Temereşti ', simpleName: 'Temeresti ', village: 'Faget ' },
            { name: 'Drăgsineşti ', simpleName: 'Dragsinesti ', village: 'Fardea ' },
            { name: 'Gladna Montană ', simpleName: 'Gladna Montana ', village: 'Fardea ' },
            { name: 'Gladna Româna ', simpleName: 'Gladna Romana ', village: 'Fardea ' },
            { name: 'Hăuzeşti ', simpleName: 'Hauzesti ', village: 'Fardea ' },
            { name: 'Mâtnicu Mic ', simpleName: 'Matnicu Mic ', village: 'Fardea ' },
            { name: 'Zolt ', village: 'Fardea ' },
            { name: 'Cruceni ', village: 'Foeni ' },
            { name: 'Berecuţa ', simpleName: 'Berecuta ', village: 'Gataia ' },
            { name: 'Birda ', village: 'Gataia ' },
            { name: 'Butin ', village: 'Gataia ' },
            { name: 'Mânăstire ', simpleName: 'Manastire ', village: 'Gataia ' },
            { name: 'Percosova ', village: 'Gataia ' },
            { name: 'Sculia ', village: 'Gataia ' },
            { name: 'Sângeorge ', simpleName: 'Sangeorge ', village: 'Gataia ' },
            { name: 'Şemlacu Mare ', simpleName: 'Semlacu Mare ', village: 'Gataia ' },
            { name: 'Şemlacu Mic ', simpleName: 'Semlacu Mic ', village: 'Gataia ' },
            { name: 'Jena ', village: 'Gavojdia ' },
            { name: 'Lugojel ', village: 'Gavojdia ' },
            { name: 'Sălbăgel ', simpleName: 'Salbagel ', village: 'Gavojdia ' },
            { name: 'Giarmata-Vii ', village: 'Ghiroda ' },
            { name: 'Hisiaş ', simpleName: 'Hisias ', village: 'Ghizela ' },
            { name: 'Paniova ', village: 'Ghizela ' },
            { name: 'Şanoviţa ', simpleName: 'Sanovita ', village: 'Ghizela ' },
            { name: 'Cerneteaz ', village: 'Giarmata ' },
            { name: 'Grănicerii ', simpleName: 'Granicerii ', village: 'Giera ' },
            { name: 'Toager ', village: 'Giera ' },
            { name: 'Chişoda ', simpleName: 'Chisoda ', village: 'Giroc ' },
            { name: 'Crai Nou ', village: 'Giulvaz ' },
            { name: 'Ivanda ', village: 'Giulvaz ' },
            { name: 'Rudna ', village: 'Giulvaz ' },
            { name: 'Clopodia ', village: 'Jamu Mare ' },
            { name: 'Ferendia ', village: 'Jamu Mare ' },
            { name: 'Gherman ', village: 'Jamu Mare ' },
            { name: 'Lăţunaş ', simpleName: 'Latunas ', village: 'Jamu Mare ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Jebel ' },
            { name: 'Bulgăruş ', simpleName: 'Bulgarus ', village: 'Lenauheim ' },
            { name: 'Grabăţ ', simpleName: 'Grabat ', village: 'Lenauheim ' },
            { name: 'Cerna ', village: 'Liebling ' },
            { name: 'Iosif ', village: 'Liebling ' },
            { name: 'Gottlob ', village: 'Lovrin ' },
            { name: 'Tomnatic ', village: 'Lovrin ' },
            { name: 'Vizejdia ', village: 'Lovrin ' },
            { name: 'Măguri ', simpleName: 'Maguri ', village: 'Lugoj ' },
            { name: 'Tapia ', village: 'Lugoj ' },
            { name: 'Pădurani ', simpleName: 'Padurani ', village: 'Manastiur ' },
            { name: 'Remetea-Luncă ', simpleName: 'Remetea-Lunca ', village: 'Manastiur ' },
            { name: 'Topla ', village: 'Manastiur ' },
            { name: 'Breazova ', village: 'Margina ' },
            { name: 'Bulza ', village: 'Margina ' },
            { name: 'Coşeviţa ', simpleName: 'Cosevita ', village: 'Margina ' },
            { name: 'Coşteiu de Sus ', simpleName: 'Costeiu de Sus ', village: 'Margina ' },
            { name: 'Groşi ', simpleName: 'Grosi ', village: 'Margina ' },
            { name: 'Nemeşeşti ', simpleName: 'Nemesesti ', village: 'Margina ' },
            { name: 'Sinteşti ', simpleName: 'Sintesti ', village: 'Margina ' },
            { name: 'Zorani ', village: 'Margina ' },
            { name: 'Alioş ', simpleName: 'Alios ', village: 'Masloc ' },
            { name: 'Fibiş ', simpleName: 'Fibis ', village: 'Masloc ' },
            { name: 'Remetea Mică ', simpleName: 'Remetea Mica ', village: 'Masloc ' },
            { name: 'Dejan ', village: 'Moravita ' },
            { name: 'Gaiu Mic ', village: 'Moravita ' },
            { name: 'Stamora Germană ', simpleName: 'Stamora Germana ', village: 'Moravita ' },
            { name: 'Albina ', village: 'Mosnita Noua ' },
            { name: 'Moşniţa Veche ', simpleName: 'Mosnita Veche ', village: 'Mosnita Noua ' },
            { name: 'Rudicica ', village: 'Mosnita Noua ' },
            { name: 'Urseni ', village: 'Mosnita Noua ' },
            { name: 'Crivina ', village: 'Nadrag ' },
            { name: 'Blajova ', village: 'Nitchidorf ' },
            { name: 'Duboz ', village: 'Nitchidorf ' },
            { name: 'Dubeşti ', simpleName: 'Dubesti ', village: 'Ohaba Lunga ' },
            { name: 'Ierşnic ', simpleName: 'Iersnic ', village: 'Ohaba Lunga ' },
            { name: 'Ohaba Română ', simpleName: 'Ohaba Romana ', village: 'Ohaba Lunga ' },
            { name: 'Corneşti ', simpleName: 'Cornesti ', village: 'Ortisoara ' },
            { name: 'Călacea ', simpleName: 'Calacea ', village: 'Ortisoara ' },
            { name: 'Seceani ', village: 'Ortisoara ' },
            { name: 'Diniaş ', simpleName: 'Dinias ', village: 'Peciu Nou ' },
            { name: 'Sânmartinu Sârbesc ', simpleName: 'Sanmartinu Sarbesc ', village: 'Peciu Nou ' },
            { name: 'Pesac ', village: 'Periam ' },
            { name: 'Crivina de Sus ', village: 'Pietroasa ' },
            { name: 'Fărăşeşti ', simpleName: 'Farasesti ', village: 'Pietroasa ' },
            { name: 'Poieni ', village: 'Pietroasa ' },
            { name: 'Bencecu de Jos ', village: 'Pischia ' },
            { name: 'Bencecu de Sus ', village: 'Pischia ' },
            { name: 'Murani ', village: 'Pischia ' },
            { name: 'Sălciua Nouă ', simpleName: 'Salciua Noua ', village: 'Pischia ' },
            { name: 'Căpăt ', simpleName: 'Capat ', village: 'Racovita ' },
            { name: 'Drăgoieşti ', simpleName: 'Dragoiesti ', village: 'Racovita ' },
            { name: 'Ficătar ', simpleName: 'Ficatar ', village: 'Racovita ' },
            { name: 'Hitiaş ', simpleName: 'Hitias ', village: 'Racovita ' },
            { name: 'Sârbova ', simpleName: 'Sarbova ', village: 'Racovita ' },
            { name: 'Bazoş ', simpleName: 'Bazos ', village: 'Recas ' },
            { name: 'Herneacova ', village: 'Recas ' },
            { name: 'Izvin ', village: 'Recas ' },
            { name: 'Nadăş ', simpleName: 'Nadas ', village: 'Recas ' },
            { name: 'Petrovaselo ', village: 'Recas ' },
            { name: 'Stanciova ', village: 'Recas ' },
            { name: 'Bazoşu Nou ', simpleName: 'Bazosu Nou ', village: 'Remetea Mare ' },
            { name: 'Bucovăţ ', simpleName: 'Bucovat ', village: 'Remetea Mare ' },
            { name: 'Ianova ', village: 'Remetea Mare ' },
            { name: 'Beregsău Mare ', simpleName: 'Beregsau Mare ', village: 'Sacalaz ' },
            { name: 'Beregsău Mic ', simpleName: 'Beregsau Mic ', village: 'Sacalaz ' },
            { name: 'Berini ', village: 'Sacosu Turcesc ' },
            { name: 'Icloda ', village: 'Sacosu Turcesc ' },
            { name: 'Otveşti ', simpleName: 'Otvesti ', village: 'Sacosu Turcesc ' },
            { name: 'Stamora Română ', simpleName: 'Stamora Romana ', village: 'Sacosu Turcesc ' },
            { name: 'Uliuc ', village: 'Sacosu Turcesc ' },
            { name: 'Unip ', village: 'Sacosu Turcesc ' },
            { name: 'Parţa ', simpleName: 'Parta ', village: 'Sag ' },
            { name: 'Carani ', village: 'Sanandrei ' },
            { name: 'Covaci ', village: 'Sanandrei ' },
            { name: 'Sânmihaiu German ', simpleName: 'Sanmihaiu German ', village: 'Sanmihaiu Roman ' },
            { name: 'Utvin ', village: 'Sanmihaiu Roman ' },
            { name: 'Igriş ', simpleName: 'Igris ', village: 'Sanpetru Mare ' },
            { name: 'Saravale ', village: 'Sanpetru Mare ' },
            { name: 'Bărăteaz ', simpleName: 'Barateaz ', village: 'Satchinez ' },
            { name: 'Hodoni ', village: 'Satchinez ' },
            { name: 'Checheş ', simpleName: 'Cheches ', village: 'Secas ' },
            { name: 'Crivobara ', village: 'Secas ' },
            { name: 'Vizma ', village: 'Secas ' },
            { name: 'Dragomireşti ', simpleName: 'Dragomiresti ', village: 'Stiuca ' },
            { name: 'Oloşag ', simpleName: 'Olosag ', village: 'Stiuca ' },
            { name: 'Zgribeşti ', simpleName: 'Zgribesti ', village: 'Stiuca ' },
            { name: 'Nerău ', simpleName: 'Nerau ', village: 'Teremia Mare ' },
            { name: 'Teremia Mică ', simpleName: 'Teremia Mica ', village: 'Teremia Mare ' },
            { name: 'Baloşeşti ', simpleName: 'Balosesti ', village: 'Tomesti ' },
            { name: 'Colonia Fabricii ', village: 'Tomesti ' },
            { name: 'Luncanii de Jos ', village: 'Tomesti ' },
            { name: 'Luncanii de Sus ', village: 'Tomesti ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Tomesti ' },
            { name: 'Cralovăţ ', simpleName: 'Cralovat ', village: 'Topolovatu Mare ' },
            { name: 'Ictar-Budinţi ', simpleName: 'Ictar-Budinti ', village: 'Topolovatu Mare ' },
            { name: 'Iosifalău ', simpleName: 'Iosifalau ', village: 'Topolovatu Mare ' },
            { name: 'Suştra ', simpleName: 'Sustra ', village: 'Topolovatu Mare ' },
            { name: 'Topolovăţu Mic ', simpleName: 'Topolovatu Mic ', village: 'Topolovatu Mare ' },
            { name: 'Cadar ', village: 'Tormac ' },
            { name: 'Şipet ', simpleName: 'Sipet ', village: 'Tormac ' },
            { name: 'Jupani ', village: 'Traian Vuia ' },
            { name: 'Sudriaş ', simpleName: 'Sudrias ', village: 'Traian Vuia ' },
            { name: 'Surducu Mic ', village: 'Traian Vuia ' },
            { name: 'Susani ', village: 'Traian Vuia ' },
            { name: 'Săceni ', simpleName: 'Saceni ', village: 'Traian Vuia ' },
            { name: 'Iohanisfeld ', village: 'Uivar ' },
            { name: 'Otelec ', village: 'Uivar ' },
            { name: 'Pustiniş ', simpleName: 'Pustinis ', village: 'Uivar ' },
            { name: 'Răuţi ', simpleName: 'Rauti ', village: 'Uivar ' },
            { name: 'Sânmartinu Maghiar ', simpleName: 'Sanmartinu Maghiar ', village: 'Uivar ' },
            { name: 'Gelu ', village: 'Varias ' },
            { name: 'Sânpetru Mic ', simpleName: 'Sanpetru Mic ', village: 'Varias ' },
            { name: 'Herendeşti ', simpleName: 'Herendesti ', village: 'Victor Vlad Delamarina ' },
            { name: 'Honorici ', village: 'Victor Vlad Delamarina ' },
            { name: 'Petroasa Mare ', village: 'Victor Vlad Delamarina ' },
            { name: 'Pini ', village: 'Victor Vlad Delamarina ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Victor Vlad Delamarina ' },
            { name: 'Vişag ', simpleName: 'Visag ', village: 'Victor Vlad Delamarina ' },
            {
                name: 'Folea ', village: 'Voiteg '
            }
        ]
    },
    {
        auto: 'TL ',
        name: 'Tulcea ',
        cities: [
            { name: 'Babadag ' },
            { name: 'Baia ' },
            { name: 'Beidaud ' },
            { name: 'C.a. Rosetti ' },
            { name: 'Carcaliu ' },
            { name: 'Casimcea ' },
            { name: 'Ceamurlia de Jos ' },
            { name: 'Ceatalchioi ' },
            { name: 'Cerna ' },
            { name: 'Chilia Veche ' },
            { name: 'Ciucurova ' },
            { name: 'Crişan ', simpleName: 'Crisan ' },
            { name: 'Dorobanţu ', simpleName: 'Dorobantu ' },
            { name: 'Dăeni ', simpleName: 'Daeni ' },
            { name: 'Frecăţei ', simpleName: 'Frecatei ' },
            { name: 'Greci ' },
            { name: 'Grindu ' },
            { name: 'Hamcearca ' },
            { name: 'Horia ' },
            { name: 'I.c.brătianu ', simpleName: 'I.c.bratianu ' },
            { name: 'Isaccea ' },
            { name: 'Izvoarele ' },
            { name: 'Jijila ' },
            { name: 'Jurilovca ' },
            { name: 'Luncaviţa ', simpleName: 'Luncavita ' },
            { name: 'Mahmudia ' },
            { name: 'Maliuc ' },
            { name: 'Mihai Bravu ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ' },
            { name: 'Murighiol ' },
            { name: 'Măcin ', simpleName: 'Macin ' },
            { name: 'Nalbant ' },
            { name: 'Niculiţel ', simpleName: 'Niculitel ' },
            { name: 'Nufăru ', simpleName: 'Nufaru ' },
            { name: 'Ostrov ' },
            { name: 'Pardina ' },
            { name: 'Peceneaga ' },
            { name: 'Sarichioi ' },
            { name: 'Sfântu Gheorghe ', simpleName: 'Sfantu Gheorghe ' },
            { name: 'Slava Cercheză ', simpleName: 'Slava Cercheza ' },
            { name: 'Smârdan ', simpleName: 'Smardan ' },
            { name: 'Somova ' },
            { name: 'Stejaru ' },
            { name: 'Sulina ' },
            { name: 'Topolog ' },
            { name: 'Tulcea ' },
            { name: 'Turcoaia ' },
            { name: 'Valea Nucarilor ' },
            { name: 'Văcăreni ', simpleName: 'Vacareni ' },
            { name: 'Camena ', village: 'Baia ' },
            { name: 'Caugagia ', village: 'Baia ' },
            { name: 'Ceamurlia de Sus ', village: 'Baia ' },
            { name: 'Panduru ', village: 'Baia ' },
            { name: 'Neatârnarea ', simpleName: 'Neatarnarea ', village: 'Beidaud ' },
            { name: 'Sarighiol de Deal ', village: 'Beidaud ' },
            { name: 'Cardon ', village: 'C.A. Rosetti ' },
            { name: 'Letea ', village: 'C.A. Rosetti ' },
            { name: 'Periprava ', village: 'C.A. Rosetti ' },
            { name: 'Sfiştofca ', simpleName: 'Sfistofca ', village: 'C.A. Rosetti ' },
            { name: 'Cişmeaua Nouă ', simpleName: 'Cismeaua Noua ', village: 'Casimcea ' },
            { name: 'Corugea ', village: 'Casimcea ' },
            { name: 'Haidar ', village: 'Casimcea ' },
            { name: 'Rahman ', village: 'Casimcea ' },
            { name: 'Războieni ', simpleName: 'Razboieni ', village: 'Casimcea ' },
            { name: 'Stânca ', simpleName: 'Stanca ', village: 'Casimcea ' },
            { name: 'Lunca ', village: 'Ceamurlia De Jos ' },
            { name: 'Plauru ', village: 'Ceatalchioi ' },
            { name: 'Pătlăgeanca ', simpleName: 'Patlageanca ', village: 'Ceatalchioi ' },
            { name: 'Sălceni ', simpleName: 'Salceni ', village: 'Ceatalchioi ' },
            { name: 'General Praporgescu ', village: 'Cerna ' },
            { name: 'Mircea Vodă ', simpleName: 'Mircea Voda ', village: 'Cerna ' },
            { name: 'Traian ', village: 'Cerna ' },
            { name: 'Câşliţa ', simpleName: 'Caslita ', village: 'Chilia Veche ' },
            { name: 'Ostrovu Tătaru ', simpleName: 'Ostrovu Tataru ', village: 'Chilia Veche ' },
            { name: 'Tatanir ', village: 'Chilia Veche ' },
            { name: 'Atmagea ', village: 'Ciucurova ' },
            { name: 'Fântâna Mare ', simpleName: 'Fantana Mare ', village: 'Ciucurova ' },
            { name: 'Caraorman ', village: 'Crisan ' },
            { name: 'Mila 23 ', village: 'Crisan ' },
            { name: 'Ardealu ', village: 'Dorobantu ' },
            { name: 'Cârjelari ', simpleName: 'Carjelari ', village: 'Dorobantu ' },
            { name: 'Fântâna Oilor ', simpleName: 'Fantana Oilor ', village: 'Dorobantu ' },
            { name: 'Meşteru ', simpleName: 'Mesteru ', village: 'Dorobantu ' },
            { name: 'Cataloi ', village: 'Frecatei ' },
            { name: 'Poşta ', simpleName: 'Posta ', village: 'Frecatei ' },
            { name: 'Teliţa ', simpleName: 'Telita ', village: 'Frecatei ' },
            { name: 'Balabancea ', village: 'Hamcearca ' },
            { name: 'Căprioara ', simpleName: 'Caprioara ', village: 'Hamcearca ' },
            { name: 'Nifon ', village: 'Hamcearca ' },
            { name: 'Cloşca ', simpleName: 'Closca ', village: 'Horia ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Horia ' },
            { name: 'Revărsarea ', simpleName: 'Revarsarea ', village: 'Isaccea ' },
            { name: 'Tichileşti ', simpleName: 'Tichilesti ', village: 'Isaccea ' },
            { name: 'Alba ', village: 'Izvoarele ' },
            { name: 'Iulia ', village: 'Izvoarele ' },
            { name: 'Valea Teilor ', village: 'Izvoarele ' },
            { name: 'Garvăn ', simpleName: 'Garvan ', village: 'Jijila ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ', village: 'Jurilovca ' },
            { name: 'Vişina ', simpleName: 'Visina ', village: 'Jurilovca ' },
            { name: 'Rachelu ', village: 'Luncavita ' },
            { name: 'Beştepe ', simpleName: 'Bestepe ', village: 'Mahmudia ' },
            { name: 'Băltenii de Jos ', simpleName: 'Baltenii de Jos ', village: 'Mahmudia ' },
            { name: 'Băltenii de Sus ', simpleName: 'Baltenii de Sus ', village: 'Mahmudia ' },
            { name: 'Gorgova ', village: 'Maliuc ' },
            { name: 'Ilganii de Sus ', village: 'Maliuc ' },
            { name: 'Partizani ', village: 'Maliuc ' },
            { name: 'Vulturu ', village: 'Maliuc ' },
            { name: 'Satu Nou ', village: 'Mihai Bravu ' },
            { name: 'Turda ', village: 'Mihai Bravu ' },
            { name: 'Lăstuni ', simpleName: 'Lastuni ', village: 'Mihail Kogalniceanu ' },
            { name: 'Rândunica ', simpleName: 'Randunica ', village: 'Mihail Kogalniceanu ' },
            { name: 'Colina ', village: 'Murighiol ' },
            { name: 'Dunavăţu de Jos ', simpleName: 'Dunavatu de Jos ', village: 'Murighiol ' },
            { name: 'Dunavăţu de Sus ', simpleName: 'Dunavatu de Sus ', village: 'Murighiol ' },
            { name: 'Plopul ', village: 'Murighiol ' },
            { name: 'Sarinasuf ', village: 'Murighiol ' },
            { name: 'Uzlina ', village: 'Murighiol ' },
            { name: 'Nicolae Bălcescu ', simpleName: 'Nicolae Balcescu ', village: 'Nalbant ' },
            { name: 'Trestenic ', village: 'Nalbant ' },
            { name: 'Ilganii de Jos ', village: 'Nufaru ' },
            { name: 'Malcoci ', village: 'Nufaru ' },
            { name: 'Victoria ', village: 'Nufaru ' },
            { name: 'Piatra ', village: 'Ostrov ' },
            { name: 'Enisala ', village: 'Sarichioi ' },
            { name: 'Sabangia ', village: 'Sarichioi ' },
            { name: 'Visterna ', village: 'Sarichioi ' },
            { name: 'Zebil ', village: 'Sarichioi ' },
            { name: 'Slava Rusă ', simpleName: 'Slava Rusa ', village: 'Slava Cercheza ' },
            { name: 'Mineri ', village: 'Somova ' },
            { name: 'Parcheş ', simpleName: 'Parches ', village: 'Somova ' },
            { name: 'Mina Altân Tepe ', simpleName: 'Mina Altan Tepe ', village: 'Stejaru ' },
            { name: 'Vasile Alecsandri ', village: 'Stejaru ' },
            { name: 'Calfa ', village: 'Topolog ' },
            { name: 'Cerbu ', village: 'Topolog ' },
            { name: 'Făgăraşu Nou ', simpleName: 'Fagarasu Nou ', village: 'Topolog ' },
            { name: 'Luminiţa ', simpleName: 'Luminita ', village: 'Topolog ' },
            { name: 'Măgurele ', simpleName: 'Magurele ', village: 'Topolog ' },
            { name: 'Sâmbăta Nouă ', simpleName: 'Sambata Noua ', village: 'Topolog ' },
            { name: 'Tudor Vladimirescu ', village: 'Tulcea ' },
            { name: 'Agighiol ', village: 'Valea Nucarilor ' },
            {
                name: 'Iazurile ', village: 'Valea Nucarilor '
            }
        ]
    },
    {
        auto: 'VL ',
        name: 'Vâlcea ',
        cities: [
            { name: 'Alunu ' },
            { name: 'Amărăşti ', simpleName: 'Amarasti ' },
            { name: 'Berbeşti ', simpleName: 'Berbesti ' },
            { name: 'Berislăveşti ', simpleName: 'Berislavesti ' },
            { name: 'Boişoara ', simpleName: 'Boisoara ' },
            { name: 'Brezoi ' },
            { name: 'Budeşti ', simpleName: 'Budesti ' },
            { name: 'Bujoreni ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ' },
            { name: 'Băbeni ', simpleName: 'Babeni ' },
            { name: 'Bălceşti ', simpleName: 'Balcesti ' },
            { name: 'Bărbăteşti ', simpleName: 'Barbatesti ' },
            { name: 'Cernişoara ', simpleName: 'Cernisoara ' },
            { name: 'Copăceni ', simpleName: 'Copaceni ' },
            { name: 'Costeşti ', simpleName: 'Costesti ' },
            { name: 'Creţeni ', simpleName: 'Creteni ' },
            { name: 'Călimăneşti ', simpleName: 'Calimanesti ' },
            { name: 'Drăgoeşti ', simpleName: 'Dragoesti ' },
            { name: 'Drăgăşani ', simpleName: 'Dragasani ' },
            { name: 'Dăeşti ', simpleName: 'Daesti ' },
            { name: 'Frânceşti ', simpleName: 'Francesti ' },
            { name: 'Fârtăţeşti ', simpleName: 'Fartatesti ' },
            { name: 'Făureşti ', simpleName: 'Fauresti ' },
            { name: 'Galicea ' },
            { name: 'Ghioroiu ' },
            { name: 'Glăvile ', simpleName: 'Glavile ' },
            { name: 'Grădiştea ', simpleName: 'Gradistea ' },
            { name: 'Guşoeni ', simpleName: 'Gusoeni ' },
            { name: 'Horezu ' },
            { name: 'Ioneşti ', simpleName: 'Ionesti ' },
            { name: 'Laloşu ', simpleName: 'Lalosu ' },
            { name: 'Livezi ' },
            { name: 'Lungeşti ', simpleName: 'Lungesti ' },
            { name: 'Lădeşti ', simpleName: 'Ladesti ' },
            { name: 'Malaia ' },
            { name: 'Mateeşti ', simpleName: 'Mateesti ' },
            { name: 'Mihăeşti ', simpleName: 'Mihaesti ' },
            { name: 'Milcoiu ' },
            { name: 'Muereasca ' },
            { name: 'Mădulari ', simpleName: 'Madulari ' },
            { name: 'Măldăreşti ', simpleName: 'Maldaresti ' },
            { name: 'Olanu ' },
            { name: 'Orleşti ', simpleName: 'Orlesti ' },
            { name: 'Oteşani ', simpleName: 'Otesani ' },
            { name: 'Perişani ', simpleName: 'Perisani ' },
            { name: 'Pesceana ' },
            { name: 'Pietrari ' },
            { name: 'Popeşti ', simpleName: 'Popesti ' },
            { name: 'Prundeni ' },
            { name: 'Păuşeşti ', simpleName: 'Pausesti ' },
            { name: 'Păuşeşti-Măglaşi ', simpleName: 'Pausesti-Maglasi ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ' },
            { name: 'Roeşti ', simpleName: 'Roesti ' },
            { name: 'Roşiile ', simpleName: 'Rosiile ' },
            { name: 'Runcu ' },
            { name: 'Râmnicu Vâlcea ', simpleName: 'Ramnicu Valcea ' },
            { name: 'Scundu ' },
            { name: 'Sineşti ', simpleName: 'Sinesti ' },
            { name: 'Slătioara ', simpleName: 'Slatioara ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ' },
            { name: 'Stoileşti ', simpleName: 'Stoilesti ' },
            { name: 'Stroeşti ', simpleName: 'Stroesti ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ' },
            { name: 'Suteşti ', simpleName: 'Sutesti ' },
            { name: 'Sălătrucel ', simpleName: 'Salatrucel ' },
            { name: 'Tetoiu ' },
            { name: 'Titeşti ', simpleName: 'Titesti ' },
            { name: 'Tomşani ', simpleName: 'Tomsani ' },
            { name: 'Vaideeni ' },
            { name: 'Valea Mare ' },
            { name: 'Vlădeşti ', simpleName: 'Vladesti ' },
            { name: 'Voiceşti ', simpleName: 'Voicesti ' },
            { name: 'Voineasa ' },
            { name: 'Zătreni ', simpleName: 'Zatreni ' },
            { name: 'Şirineasa ', simpleName: 'Sirineasa ' },
            { name: 'Ştefăneşti ', simpleName: 'Stefanesti ' },
            { name: 'Şuşani ', simpleName: 'Susani ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ', village: 'Alunu ' },
            { name: 'Colteşti ', simpleName: 'Coltesti ', village: 'Alunu ' },
            { name: 'Igoiu ', village: 'Alunu ' },
            { name: 'Ilaciu ', village: 'Alunu ' },
            { name: 'Ocracu ', village: 'Alunu ' },
            { name: 'Roşia ', simpleName: 'Rosia ', village: 'Alunu ' },
            { name: 'Mereşeşti ', simpleName: 'Meresesti ', village: 'Amarasti ' },
            { name: 'Nemoiu ', village: 'Amarasti ' },
            { name: 'Padina ', village: 'Amarasti ' },
            { name: 'Palanga ', village: 'Amarasti ' },
            { name: 'Teiul ', village: 'Amarasti ' },
            { name: 'Bonciu ', village: 'Babeni ' },
            { name: 'Capu Dealului ', village: 'Babeni ' },
            { name: 'Pădureţu ', simpleName: 'Paduretu ', village: 'Babeni ' },
            { name: 'Români ', simpleName: 'Romani ', village: 'Babeni ' },
            { name: 'Tătărani ', simpleName: 'Tatarani ', village: 'Babeni ' },
            { name: 'Valea Mare ', village: 'Babeni ' },
            { name: 'Curăturile ', simpleName: 'Curaturile ', village: 'Baile Govora ' },
            { name: 'Gătejeşti ', simpleName: 'Gatejesti ', village: 'Baile Govora ' },
            { name: 'Prajila ', village: 'Baile Govora ' },
            { name: 'Cheia ', village: 'Baile Olanesti ' },
            { name: 'Comanca ', village: 'Baile Olanesti ' },
            { name: 'Gurguiata ', village: 'Baile Olanesti ' },
            { name: 'Livadia ', village: 'Baile Olanesti ' },
            { name: 'Moşoroaşa ', simpleName: 'Mosoroasa ', village: 'Baile Olanesti ' },
            { name: 'Olăneşti ', simpleName: 'Olanesti ', village: 'Baile Olanesti ' },
            { name: 'Pietrişu ', simpleName: 'Pietrisu ', village: 'Baile Olanesti ' },
            { name: 'Tisa ', village: 'Baile Olanesti ' },
            { name: 'Beneşti ', simpleName: 'Benesti ', village: 'Balcesti ' },
            { name: 'Chirculeşti ', simpleName: 'Chirculesti ', village: 'Balcesti ' },
            { name: 'Cârlogani ', simpleName: 'Carlogani ', village: 'Balcesti ' },
            { name: 'Goruneşti ', simpleName: 'Gorunesti ', village: 'Balcesti ' },
            { name: 'Irimeşti ', simpleName: 'Irimesti ', village: 'Balcesti ' },
            { name: 'Otetelişu ', simpleName: 'Otetelisu ', village: 'Balcesti ' },
            { name: 'Preoteşti ', simpleName: 'Preotesti ', village: 'Balcesti ' },
            { name: 'Satu Poieni ', village: 'Balcesti ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ', village: 'Barbatesti ' },
            { name: 'Bârzeşti ', simpleName: 'Barzesti ', village: 'Barbatesti ' },
            { name: 'Negruleşti ', simpleName: 'Negrulesti ', village: 'Barbatesti ' },
            { name: 'Dealu Aluniş ', simpleName: 'Dealu Alunis ', village: 'Berbesti ' },
            { name: 'Dămţeni ', simpleName: 'Damteni ', village: 'Berbesti ' },
            { name: 'Roşioara ', simpleName: 'Rosioara ', village: 'Berbesti ' },
            { name: 'Târgu Gânguleşti ', simpleName: 'Targu Gangulesti ', village: 'Berbesti ' },
            { name: 'Valea Mare ', village: 'Berbesti ' },
            { name: 'Brădişor ', simpleName: 'Bradisor ', village: 'Berislavesti ' },
            { name: 'Dângeşti ', simpleName: 'Dangesti ', village: 'Berislavesti ' },
            { name: 'Robaia ', village: 'Berislavesti ' },
            { name: 'Rădăcineşti ', simpleName: 'Radacinesti ', village: 'Berislavesti ' },
            { name: 'Scăueni ', simpleName: 'Scaueni ', village: 'Berislavesti ' },
            { name: 'Stoeneşti ', simpleName: 'Stoenesti ', village: 'Berislavesti ' },
            { name: 'Bumbueşti ', simpleName: 'Bumbuesti ', village: 'Boisoara ' },
            { name: 'Găujani ', simpleName: 'Gaujani ', village: 'Boisoara ' },
            { name: 'Corbu ', village: 'Brezoi ' },
            { name: 'Călineşti ', simpleName: 'Calinesti ', village: 'Brezoi ' },
            { name: 'Drăgăneşti ', simpleName: 'Draganesti ', village: 'Brezoi ' },
            { name: 'Golotreni ', village: 'Brezoi ' },
            { name: 'Proieni ', village: 'Brezoi ' },
            { name: 'Păscoaia ', simpleName: 'Pascoaia ', village: 'Brezoi ' },
            { name: 'Valea lui Stan ', village: 'Brezoi ' },
            { name: 'Văratica ', simpleName: 'Varatica ', village: 'Brezoi ' },
            { name: 'Barza ', village: 'Budesti ' },
            { name: 'Bercioiu ', village: 'Budesti ' },
            { name: 'Bârseşti ', simpleName: 'Barsesti ', village: 'Budesti ' },
            { name: 'Linia ', village: 'Budesti ' },
            { name: 'Piscu Pietrei ', village: 'Budesti ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ', village: 'Budesti ' },
            { name: 'Ruda ', village: 'Budesti ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Bujoreni ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Bujoreni ' },
            { name: 'Lunca ', village: 'Bujoreni ' },
            { name: 'Malu Alb ', village: 'Bujoreni ' },
            { name: 'Malu Vârtop ', simpleName: 'Malu Vartop ', village: 'Bujoreni ' },
            { name: 'Olteni ', village: 'Bujoreni ' },
            { name: 'Coasta Mare ', village: 'Bunesti ' },
            { name: 'Fireşti ', simpleName: 'Firesti ', village: 'Bunesti ' },
            { name: 'Râpăneşti ', simpleName: 'Rapanesti ', village: 'Bunesti ' },
            { name: 'Teiuşu ', simpleName: 'Teiusu ', village: 'Bunesti ' },
            { name: 'Titireci ', village: 'Bunesti ' },
            { name: 'Câinenii Mari ', simpleName: 'Cainenii Mari ', village: 'Caineni ' },
            { name: 'Câinenii Mici ', simpleName: 'Cainenii Mici ', village: 'Caineni ' },
            { name: 'Grebleşti ', simpleName: 'Greblesti ', village: 'Caineni ' },
            { name: 'Priloage ', village: 'Caineni ' },
            { name: 'Robeşti ', simpleName: 'Robesti ', village: 'Caineni ' },
            { name: 'Râu Vadului ', simpleName: 'Rau Vadului ', village: 'Caineni ' },
            { name: 'Căciulata ', simpleName: 'Caciulata ', village: 'Calimanesti ' },
            { name: 'Jiblea Nouă ', simpleName: 'Jiblea Noua ', village: 'Calimanesti ' },
            { name: 'Jiblea Veche ', village: 'Calimanesti ' },
            { name: 'Păuşa ', simpleName: 'Pausa ', village: 'Calimanesti ' },
            { name: 'Seaca ', village: 'Calimanesti ' },
            { name: 'Armăşeşti ', simpleName: 'Armasesti ', village: 'Cernisoara ' },
            { name: 'Groşi ', simpleName: 'Grosi ', village: 'Cernisoara ' },
            { name: 'Modoia ', village: 'Cernisoara ' },
            { name: 'Mădulari ', simpleName: 'Madulari ', village: 'Cernisoara ' },
            { name: 'Obârşia ', simpleName: 'Obarsia ', village: 'Cernisoara ' },
            { name: 'Sărsăneşti ', simpleName: 'Sarsanesti ', village: 'Cernisoara ' },
            { name: 'Bondoci ', village: 'Copaceni ' },
            { name: 'Bălteni ', simpleName: 'Balteni ', village: 'Copaceni ' },
            { name: 'Hotărasa ', simpleName: 'Hotarasa ', village: 'Copaceni ' },
            { name: 'Ulmetu ', village: 'Copaceni ' },
            { name: 'Veţelu ', simpleName: 'Vetelu ', village: 'Copaceni ' },
            { name: 'Bistriţa ', simpleName: 'Bistrita ', village: 'Costesti ' },
            { name: 'Pietreni ', village: 'Costesti ' },
            { name: 'Văratici ', simpleName: 'Varatici ', village: 'Costesti ' },
            { name: 'Izvoru ', village: 'Creteni ' },
            { name: 'Mreneşti ', simpleName: 'Mrenesti ', village: 'Creteni ' },
            { name: 'Streminoasa ', village: 'Creteni ' },
            { name: 'Băbueşti ', simpleName: 'Babuesti ', village: 'Daesti ' },
            { name: 'Fedeleşoiu ', simpleName: 'Fedelesoiu ', village: 'Daesti ' },
            { name: 'Sânbotin ', simpleName: 'Sanbotin ', village: 'Daesti ' },
            { name: 'Bădeni ', simpleName: 'Badeni ', village: 'Danicei ' },
            { name: 'Ceretu ', village: 'Danicei ' },
            { name: 'Cireşul ', simpleName: 'Ciresul ', village: 'Danicei ' },
            { name: 'Dealu Lăunele ', simpleName: 'Dealu Launele ', village: 'Danicei ' },
            { name: 'Dealu Scheiului ', village: 'Danicei ' },
            { name: 'Dobreşti ', simpleName: 'Dobresti ', village: 'Danicei ' },
            { name: 'Drăguleşti ', simpleName: 'Dragulesti ', village: 'Danicei ' },
            { name: 'Glodu ', village: 'Danicei ' },
            { name: 'Gura Crucilor ', village: 'Danicei ' },
            { name: 'Linia pe Vale ', village: 'Danicei ' },
            { name: 'Lăunele de Jos ', simpleName: 'Launele de Jos ', village: 'Danicei ' },
            { name: 'Udreşti ', simpleName: 'Udresti ', village: 'Danicei ' },
            { name: 'Valea Scheiului ', village: 'Danicei ' },
            { name: 'Valea Caselor ', village: 'Dragasani ' },
            { name: 'Zlătărei ', simpleName: 'Zlatarei ', village: 'Dragasani ' },
            { name: 'Zărneni ', simpleName: 'Zarneni ', village: 'Dragasani ' },
            { name: 'Buciumeni ', village: 'Dragoesti ' },
            { name: 'Geamăna ', simpleName: 'Geamana ', village: 'Dragoesti ' },
            { name: 'Afânata ', simpleName: 'Afanata ', village: 'Fartatesti ' },
            { name: 'Becşani ', simpleName: 'Becsani ', village: 'Fartatesti ' },
            { name: 'Cuci ', village: 'Fartatesti ' },
            { name: 'Căţetu ', simpleName: 'Catetu ', village: 'Fartatesti ' },
            { name: 'Dejoi ', village: 'Fartatesti ' },
            { name: 'Dozeşti ', simpleName: 'Dozesti ', village: 'Fartatesti ' },
            { name: 'Dăncăi ', simpleName: 'Dancai ', village: 'Fartatesti ' },
            { name: 'Giuleşti ', simpleName: 'Giulesti ', village: 'Fartatesti ' },
            { name: 'Giuleştii de Sus ', simpleName: 'Giulestii de Sus ', village: 'Fartatesti ' },
            { name: 'Gârnicet ', simpleName: 'Garnicet ', village: 'Fartatesti ' },
            { name: 'Măriceşti ', simpleName: 'Maricesti ', village: 'Fartatesti ' },
            { name: 'Nisipi ', village: 'Fartatesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Fartatesti ' },
            { name: 'Rusăneşti ', simpleName: 'Rusanesti ', village: 'Fartatesti ' },
            { name: 'Seciu ', village: 'Fartatesti ' },
            { name: 'Stănculeşti ', simpleName: 'Stanculesti ', village: 'Fartatesti ' },
            { name: 'Tanislavi ', village: 'Fartatesti ' },
            { name: 'Valea Ursului ', village: 'Fartatesti ' },
            { name: 'Şotani ', simpleName: 'Sotani ', village: 'Fartatesti ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Fauresti ' },
            { name: 'Bungeţani ', simpleName: 'Bungetani ', village: 'Fauresti ' },
            { name: 'Băbeni-Olteţu ', simpleName: 'Babeni-Oltetu ', village: 'Fauresti ' },
            { name: 'Colelia ', village: 'Fauresti ' },
            { name: 'Diculeşti ', simpleName: 'Diculesti ', village: 'Fauresti ' },
            { name: 'Găineşti ', simpleName: 'Gainesti ', village: 'Fauresti ' },
            { name: 'Mileşti ', simpleName: 'Milesti ', village: 'Fauresti ' },
            { name: 'Mărcuşu ', simpleName: 'Marcusu ', village: 'Fauresti ' },
            { name: 'Băluţoaia ', simpleName: 'Balutoaia ', village: 'Francesti ' },
            { name: 'Coşani ', simpleName: 'Cosani ', village: 'Francesti ' },
            { name: 'Dezrobiţi ', simpleName: 'Dezrobiti ', village: 'Francesti ' },
            { name: 'Genuneni ', village: 'Francesti ' },
            { name: 'Moşteni ', simpleName: 'Mosteni ', village: 'Francesti ' },
            { name: 'Mănăileşti ', simpleName: 'Manailesti ', village: 'Francesti ' },
            { name: 'Surpatele ', village: 'Francesti ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Francesti ' },
            { name: 'Bratia din Deal ', village: 'Galicea ' },
            { name: 'Bratia din Vale ', village: 'Galicea ' },
            { name: 'Cocoru ', village: 'Galicea ' },
            { name: 'Cremenari ', village: 'Galicea ' },
            { name: 'Dealu Mare ', village: 'Galicea ' },
            { name: 'Ostroveni ', village: 'Galicea ' },
            { name: 'Teiu ', village: 'Galicea ' },
            { name: 'Valea Râului ', simpleName: 'Valea Raului ', village: 'Galicea ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ', village: 'Ghioroiu ' },
            { name: 'Herăşti ', simpleName: 'Herasti ', village: 'Ghioroiu ' },
            { name: 'Mierea ', village: 'Ghioroiu ' },
            { name: 'Poienari ', village: 'Ghioroiu ' },
            { name: 'Ştirbeşti ', simpleName: 'Stirbesti ', village: 'Ghioroiu ' },
            { name: 'Aninoasa ', village: 'Glavile ' },
            { name: 'Jaroştea ', simpleName: 'Jarostea ', village: 'Glavile ' },
            { name: 'Olteanca ', village: 'Glavile ' },
            { name: 'Voiculeasa ', village: 'Glavile ' },
            { name: 'Aldeşti ', simpleName: 'Aldesti ', village: 'Golesti ' },
            { name: 'Blidari ', village: 'Golesti ' },
            { name: 'Coasta ', village: 'Golesti ' },
            { name: 'Drăgăneşti ', simpleName: 'Draganesti ', village: 'Golesti ' },
            { name: 'Gibeşti ', simpleName: 'Gibesti ', village: 'Golesti ' },
            { name: 'Giurgiuveni ', village: 'Golesti ' },
            { name: 'Opăteşti ', simpleName: 'Opatesti ', village: 'Golesti ' },
            { name: 'Poeniţa ', simpleName: 'Poenita ', village: 'Golesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Golesti ' },
            { name: 'Tulei-Câmpeni ', simpleName: 'Tulei-Campeni ', village: 'Golesti ' },
            { name: 'Vătăşeşti ', simpleName: 'Vatasesti ', village: 'Golesti ' },
            { name: 'Diaconeşti ', simpleName: 'Diaconesti ', village: 'Gradistea ' },
            { name: 'Dobricea ', village: 'Gradistea ' },
            { name: 'Linia ', village: 'Gradistea ' },
            { name: 'Obislavu ', village: 'Gradistea ' },
            { name: 'Străchineşti ', simpleName: 'Strachinesti ', village: 'Gradistea ' },
            { name: 'Turburea ', village: 'Gradistea ' },
            { name: 'Valea Grădiştei ', simpleName: 'Valea Gradistei ', village: 'Gradistea ' },
            { name: 'Ţuţuru ', simpleName: 'Tuturu ', village: 'Gradistea ' },
            { name: 'Burdăleşti ', simpleName: 'Burdalesti ', village: 'Gusoeni ' },
            { name: 'Dealu Mare ', village: 'Gusoeni ' },
            { name: 'Guşoianca ', simpleName: 'Gusoianca ', village: 'Gusoeni ' },
            { name: 'Măgureni ', simpleName: 'Magureni ', village: 'Gusoeni ' },
            { name: 'Spârleni ', simpleName: 'Sparleni ', village: 'Gusoeni ' },
            { name: 'Ifrimeşti ', simpleName: 'Ifrimesti ', village: 'Horezu ' },
            { name: 'Romanii de Jos ', village: 'Horezu ' },
            { name: 'Romanii de Sus ', village: 'Horezu ' },
            { name: 'Râmeşti ', simpleName: 'Ramesti ', village: 'Horezu ' },
            { name: 'Tănăseşti ', simpleName: 'Tanasesti ', village: 'Horezu ' },
            { name: 'Urşani ', simpleName: 'Ursani ', village: 'Horezu ' },
            { name: 'Bucşani ', simpleName: 'Bucsani ', village: 'Ionesti ' },
            { name: 'Dealu Mare ', village: 'Ionesti ' },
            { name: 'Delureni ', village: 'Ionesti ' },
            { name: 'Fişcălia ', simpleName: 'Fiscalia ', village: 'Ionesti ' },
            { name: 'Foteşti ', simpleName: 'Fotesti ', village: 'Ionesti ' },
            { name: 'Guguianca ', village: 'Ionesti ' },
            { name: 'Marcea ', village: 'Ionesti ' },
            { name: 'Prodăneşti ', simpleName: 'Prodanesti ', village: 'Ionesti ' },
            { name: 'Cermegeşti ', simpleName: 'Cermegesti ', village: 'Ladesti ' },
            { name: 'Chiriceşti ', simpleName: 'Chiricesti ', village: 'Ladesti ' },
            { name: 'Ciumagi ', village: 'Ladesti ' },
            { name: 'Dealu Corni ', village: 'Ladesti ' },
            { name: 'Găgeni ', simpleName: 'Gageni ', village: 'Ladesti ' },
            { name: 'Măldăreşti ', simpleName: 'Maldaresti ', village: 'Ladesti ' },
            { name: 'Olteanca ', village: 'Ladesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Ladesti ' },
            { name: 'Păsculeşti ', simpleName: 'Pasculesti ', village: 'Ladesti ' },
            { name: 'Berbeşti ', simpleName: 'Berbesti ', village: 'Lalosu ' },
            { name: 'Ghindari ', village: 'Lalosu ' },
            { name: 'Mologeşti ', simpleName: 'Mologesti ', village: 'Lalosu ' },
            { name: 'Olteţani ', simpleName: 'Oltetani ', village: 'Lalosu ' },
            { name: 'Portăreşti ', simpleName: 'Portaresti ', village: 'Lalosu ' },
            { name: 'Bereşti ', simpleName: 'Beresti ', village: 'Lapusata ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Lapusata ' },
            { name: 'Mijaţi ', simpleName: 'Mijati ', village: 'Lapusata ' },
            { name: 'Scoruşu ', simpleName: 'Scorusu ', village: 'Lapusata ' },
            { name: 'Săruleşti ', simpleName: 'Sarulesti ', village: 'Lapusata ' },
            { name: 'Zărneşti ', simpleName: 'Zarnesti ', village: 'Lapusata ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Lapusata ' },
            { name: 'Pleşoiu ', simpleName: 'Plesoiu ', village: 'Livezi ' },
            { name: 'Pârâienii de Jos ', simpleName: 'Paraienii de Jos ', village: 'Livezi ' },
            { name: 'Pârâienii de Mijloc ', simpleName: 'Paraienii de Mijloc ', village: 'Livezi ' },
            { name: 'Pârâienii de Sus ', simpleName: 'Paraienii de Sus ', village: 'Livezi ' },
            { name: 'Părăuşani ', simpleName: 'Parausani ', village: 'Livezi ' },
            { name: 'Tina ', village: 'Livezi ' },
            { name: 'Cărcădieşti ', simpleName: 'Carcadiesti ', village: 'Lungesti ' },
            { name: 'Dumbrava ', village: 'Lungesti ' },
            { name: 'Fumureni ', village: 'Lungesti ' },
            { name: 'Gănţulei ', simpleName: 'Gantulei ', village: 'Lungesti ' },
            { name: 'Stăneşti-Lunca ', simpleName: 'Stanesti-Lunca ', village: 'Lungesti ' },
            { name: 'Bocşa ', simpleName: 'Bocsa ', village: 'Maciuca ' },
            { name: 'Botorani ', village: 'Maciuca ' },
            { name: 'Ciocănari ', simpleName: 'Ciocanari ', village: 'Maciuca ' },
            { name: 'Măciuceni ', simpleName: 'Maciuceni ', village: 'Maciuca ' },
            { name: 'Măldăreşti ', simpleName: 'Maldaresti ', village: 'Maciuca ' },
            { name: 'Oveselu ', village: 'Maciuca ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Maciuca ' },
            { name: 'Zăvoieni ', simpleName: 'Zavoieni ', village: 'Maciuca ' },
            { name: 'Ştefăneşti ', simpleName: 'Stefanesti ', village: 'Maciuca ' },
            { name: 'Bălşoara ', simpleName: 'Balsoara ', village: 'Madulari ' },
            { name: 'Bănţeşti ', simpleName: 'Bantesti ', village: 'Madulari ' },
            { name: 'Dimuleşti ', simpleName: 'Dimulesti ', village: 'Madulari ' },
            { name: 'Iacovile ', village: 'Madulari ' },
            { name: 'Mamu ', village: 'Madulari ' },
            { name: 'Ciungetu ', village: 'Malaia ' },
            { name: 'Săliştea ', simpleName: 'Salistea ', village: 'Malaia ' },
            { name: 'Măldăreştii de Jos ', simpleName: 'Maldarestii de Jos ', village: 'Maldaresti ' },
            { name: 'Roşoveni ', simpleName: 'Rosoveni ', village: 'Maldaresti ' },
            { name: 'Telecheşti ', simpleName: 'Telechesti ', village: 'Maldaresti ' },
            { name: 'Greci ', village: 'Mateesti ' },
            { name: 'Turceşti ', simpleName: 'Turcesti ', village: 'Mateesti ' },
            { name: 'Arsanca ', village: 'Mihaesti ' },
            { name: 'Buleta ', village: 'Mihaesti ' },
            { name: 'Bârseşti ', simpleName: 'Barsesti ', village: 'Mihaesti ' },
            { name: 'Govora ', village: 'Mihaesti ' },
            { name: 'Gurişoara ', simpleName: 'Gurisoara ', village: 'Mihaesti ' },
            { name: 'Munteni ', village: 'Mihaesti ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Mihaesti ' },
            { name: 'Negreni ', village: 'Mihaesti ' },
            { name: 'Rugetu ', village: 'Mihaesti ' },
            { name: 'Scărişoara ', simpleName: 'Scarisoara ', village: 'Mihaesti ' },
            { name: 'Stupărei ', simpleName: 'Stuparei ', village: 'Mihaesti ' },
            { name: 'Vulpueşti ', simpleName: 'Vulpuesti ', village: 'Mihaesti ' },
            { name: 'Ciuteşti ', simpleName: 'Ciutesti ', village: 'Milcoiu ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ', village: 'Milcoiu ' },
            { name: 'Izbăseşti ', simpleName: 'Izbasesti ', village: 'Milcoiu ' },
            { name: 'Tepşenari ', simpleName: 'Tepsenari ', village: 'Milcoiu ' },
            { name: 'Şuricaru ', simpleName: 'Suricaru ', village: 'Milcoiu ' },
            { name: 'Andreieşti ', simpleName: 'Andreiesti ', village: 'Muereasca ' },
            { name: 'Frânceşti-Coasta ', simpleName: 'Francesti-Coasta ', village: 'Muereasca ' },
            { name: 'Găvăneşti ', simpleName: 'Gavanesti ', village: 'Muereasca ' },
            { name: 'Hotarele ', village: 'Muereasca ' },
            { name: 'Muereasca de Sus ', village: 'Muereasca ' },
            { name: 'Pripoara ', village: 'Muereasca ' },
            { name: 'Şuta ', simpleName: 'Suta ', village: 'Muereasca ' },
            { name: 'Băneşti ', simpleName: 'Banesti ', village: 'Nicolae Balcescu ' },
            { name: 'Corbii din Vale ', village: 'Nicolae Balcescu ' },
            { name: 'Dosu Râului ', simpleName: 'Dosu Raului ', village: 'Nicolae Balcescu ' },
            { name: 'Ginerica ', village: 'Nicolae Balcescu ' },
            { name: 'Gâltofani ', simpleName: 'Galtofani ', village: 'Nicolae Balcescu ' },
            { name: 'Linia Hanului ', village: 'Nicolae Balcescu ' },
            { name: 'Mângureni ', simpleName: 'Mangureni ', village: 'Nicolae Balcescu ' },
            { name: 'Măzăraru ', simpleName: 'Mazararu ', village: 'Nicolae Balcescu ' },
            { name: 'Pleşoiu ', simpleName: 'Plesoiu ', village: 'Nicolae Balcescu ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Nicolae Balcescu ' },
            { name: 'Predeşti ', simpleName: 'Predesti ', village: 'Nicolae Balcescu ' },
            { name: 'Rotărăşti ', simpleName: 'Rotarasti ', village: 'Nicolae Balcescu ' },
            { name: 'Schitu ', village: 'Nicolae Balcescu ' },
            { name: 'Tufanii ', village: 'Nicolae Balcescu ' },
            { name: 'Valea Bălcească ', simpleName: 'Valea Balceasca ', village: 'Nicolae Balcescu ' },
            { name: 'Valea Viei ', village: 'Nicolae Balcescu ' },
            { name: 'Şerbăneasa ', simpleName: 'Serbaneasa ', village: 'Nicolae Balcescu ' },
            { name: 'Buda ', village: 'Ocnele Mari ' },
            { name: 'Cosota ', village: 'Ocnele Mari ' },
            { name: 'Făcăi ', simpleName: 'Facai ', village: 'Ocnele Mari ' },
            { name: 'Gura Suhaşului ', simpleName: 'Gura Suhasului ', village: 'Ocnele Mari ' },
            { name: 'Lunca ', village: 'Ocnele Mari ' },
            { name: 'Ocniţa ', simpleName: 'Ocnita ', village: 'Ocnele Mari ' },
            { name: 'Slătioarele ', simpleName: 'Slatioarele ', village: 'Ocnele Mari ' },
            { name: 'Ţeica ', simpleName: 'Teica ', village: 'Ocnele Mari ' },
            { name: 'Casa Veche ', village: 'Olanu ' },
            { name: 'Cioboţi ', simpleName: 'Cioboti ', village: 'Olanu ' },
            { name: 'Drăgioiu ', simpleName: 'Dragioiu ', village: 'Olanu ' },
            { name: 'Nicoleşti ', simpleName: 'Nicolesti ', village: 'Olanu ' },
            { name: 'Stoicăneşti ', simpleName: 'Stoicanesti ', village: 'Olanu ' },
            { name: 'Aureşti ', simpleName: 'Auresti ', village: 'Orlesti ' },
            { name: 'Procopoaia ', village: 'Orlesti ' },
            { name: 'Scaioşi ', simpleName: 'Scaiosi ', village: 'Orlesti ' },
            { name: 'Silea ', village: 'Orlesti ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Otesani ' },
            { name: 'Cuceşti ', simpleName: 'Cucesti ', village: 'Otesani ' },
            { name: 'Cârstăneşti ', simpleName: 'Carstanesti ', village: 'Otesani ' },
            { name: 'Sub Deal ', village: 'Otesani ' },
            { name: 'Barcanele ', village: 'Pausesti ' },
            { name: 'Buzdugan ', village: 'Pausesti ' },
            { name: 'Cernelele ', village: 'Pausesti ' },
            { name: 'Păuseşti-Otăsău ', simpleName: 'Pausesti-Otasau ', village: 'Pausesti ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Pausesti ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Pausesti ' },
            { name: 'Şoliceşti ', simpleName: 'Solicesti ', village: 'Pausesti ' },
            { name: 'Coasta ', village: 'Pausesti-Maglasi ' },
            { name: 'Pietrari ', village: 'Pausesti-Maglasi ' },
            { name: 'Ulmeţel ', simpleName: 'Ulmetel ', village: 'Pausesti-Maglasi ' },
            { name: 'Valea Cheii ', village: 'Pausesti-Maglasi ' },
            { name: 'Vlăduceni ', simpleName: 'Vladuceni ', village: 'Pausesti-Maglasi ' },
            { name: 'Băiaşu ', simpleName: 'Baiasu ', village: 'Perisani ' },
            { name: 'Mlăceni ', simpleName: 'Mlaceni ', village: 'Perisani ' },
            { name: 'Podeni ', village: 'Perisani ' },
            { name: 'Poiana ', village: 'Perisani ' },
            { name: 'Pripoare ', village: 'Perisani ' },
            { name: 'Spinu ', village: 'Perisani ' },
            { name: 'Surdoiu ', village: 'Perisani ' },
            { name: 'Cermegeşti ', simpleName: 'Cermegesti ', village: 'Pesceana ' },
            { name: 'Lupoaia ', village: 'Pesceana ' },
            { name: 'Negraia ', village: 'Pesceana ' },
            { name: 'Roeşti ', simpleName: 'Roesti ', village: 'Pesceana ' },
            { name: 'Ursoaia ', village: 'Pesceana ' },
            { name: 'Pietrarii de Sus ', village: 'Pietrari ' },
            { name: 'Curtea ', village: 'Popesti ' },
            { name: 'Dăeşti ', simpleName: 'Daesti ', village: 'Popesti ' },
            { name: 'Firijba ', village: 'Popesti ' },
            { name: 'Meieni ', village: 'Popesti ' },
            { name: 'Urşi ', simpleName: 'Ursi ', village: 'Popesti ' },
            { name: 'Valea Caselor ', village: 'Popesti ' },
            { name: 'Bărbuceni ', simpleName: 'Barbuceni ', village: 'Prundeni ' },
            { name: 'Călina ', simpleName: 'Calina ', village: 'Prundeni ' },
            { name: 'Zăvideni ', simpleName: 'Zavideni ', village: 'Prundeni ' },
            { name: 'Balota ', village: 'Racovita ' },
            { name: 'Blănoiu ', simpleName: 'Blanoiu ', village: 'Racovita ' },
            { name: 'Bradu-Clocotici ', village: 'Racovita ' },
            { name: 'Copăceni ', simpleName: 'Copaceni ', village: 'Racovita ' },
            { name: 'Gruiu Lupului ', village: 'Racovita ' },
            { name: 'Tuţuleşti ', simpleName: 'Tutulesti ', village: 'Racovita ' },
            { name: 'Aranghel ', village: 'Ramnicu Valcea ' },
            { name: 'Copăcelu ', simpleName: 'Copacelu ', village: 'Ramnicu Valcea ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ', village: 'Ramnicu Valcea ' },
            { name: 'Dealu Malului ', village: 'Ramnicu Valcea ' },
            { name: 'Feţeni ', simpleName: 'Feteni ', village: 'Ramnicu Valcea ' },
            { name: 'Goranu ', village: 'Ramnicu Valcea ' },
            { name: 'Lespezi ', village: 'Ramnicu Valcea ' },
            { name: 'Poenari ', village: 'Ramnicu Valcea ' },
            { name: 'Priba ', village: 'Ramnicu Valcea ' },
            { name: 'Râureni ', simpleName: 'Raureni ', village: 'Ramnicu Valcea ' },
            { name: 'Stolniceni ', village: 'Ramnicu Valcea ' },
            { name: 'Săliştea ', simpleName: 'Salistea ', village: 'Ramnicu Valcea ' },
            { name: 'Troian ', village: 'Ramnicu Valcea ' },
            { name: 'Băiaşa ', simpleName: 'Baiasa ', village: 'Roesti ' },
            { name: 'Băjenari ', simpleName: 'Bajenari ', village: 'Roesti ' },
            { name: 'Bărbărigeni ', simpleName: 'Barbarigeni ', village: 'Roesti ' },
            { name: 'Ciocâltei ', simpleName: 'Ciocaltei ', village: 'Roesti ' },
            { name: 'Cueni ', village: 'Roesti ' },
            { name: 'Frăsina ', simpleName: 'Frasina ', village: 'Roesti ' },
            { name: 'Piscu Scoarţei ', simpleName: 'Piscu Scoartei ', village: 'Roesti ' },
            { name: 'Râpa Cărămizii ', simpleName: 'Rapa Caramizii ', village: 'Roesti ' },
            { name: 'Saioci ', village: 'Roesti ' },
            { name: 'Balaciu ', village: 'Rosiile ' },
            { name: 'Cherăşti ', simpleName: 'Cherasti ', village: 'Rosiile ' },
            { name: 'Hotăroaia ', simpleName: 'Hotaroaia ', village: 'Rosiile ' },
            { name: 'Lupuieşti ', simpleName: 'Lupuiesti ', village: 'Rosiile ' },
            { name: 'Perteşti ', simpleName: 'Pertesti ', village: 'Rosiile ' },
            { name: 'Pleseşti ', simpleName: 'Plesesti ', village: 'Rosiile ' },
            { name: 'Păsărei ', simpleName: 'Pasarei ', village: 'Rosiile ' },
            { name: 'Româneşti ', simpleName: 'Romanesti ', village: 'Rosiile ' },
            { name: 'Răţăleşti ', simpleName: 'Ratalesti ', village: 'Rosiile ' },
            { name: 'Zgubea ', village: 'Rosiile ' },
            { name: 'Căligi ', simpleName: 'Caligi ', village: 'Runcu ' },
            { name: 'Gropeni ', village: 'Runcu ' },
            { name: 'Snamăna ', simpleName: 'Snamana ', village: 'Runcu ' },
            { name: 'Surpaţi ', simpleName: 'Surpati ', village: 'Runcu ' },
            { name: 'Valea Babei ', village: 'Runcu ' },
            { name: 'Vărateci ', simpleName: 'Varateci ', village: 'Runcu ' },
            { name: 'Păteşti ', simpleName: 'Patesti ', village: 'Salatrucel ' },
            { name: 'Seaca ', village: 'Salatrucel ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Salatrucel ' },
            { name: 'Avrămeşti ', simpleName: 'Avramesti ', village: 'Scundu ' },
            { name: 'Blejani ', village: 'Scundu ' },
            { name: 'Crângu ', simpleName: 'Crangu ', village: 'Scundu ' },
            { name: 'Ciucheţi ', simpleName: 'Ciucheti ', village: 'Sinesti ' },
            { name: 'Dealu Bisericii ', village: 'Sinesti ' },
            { name: 'Mijlocu ', village: 'Sinesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Sinesti ' },
            { name: 'Urzica ', village: 'Sinesti ' },
            { name: 'Aricioaia ', village: 'Sirineasa ' },
            { name: 'Ciorăşti ', simpleName: 'Ciorasti ', village: 'Sirineasa ' },
            { name: 'Slăviteşti ', simpleName: 'Slavitesti ', village: 'Sirineasa ' },
            { name: 'Valea Alunişului ', simpleName: 'Valea Alunisului ', village: 'Sirineasa ' },
            { name: 'Coasta Cerbului ', village: 'Slatioara ' },
            { name: 'Goruneşti ', simpleName: 'Gorunesti ', village: 'Slatioara ' },
            { name: 'Milostea ', village: 'Slatioara ' },
            { name: 'Mogeşti ', simpleName: 'Mogesti ', village: 'Slatioara ' },
            { name: 'Rugetu ', village: 'Slatioara ' },
            { name: 'Bărcăneşti ', simpleName: 'Barcanesti ', village: 'Stanesti ' },
            { name: 'Cioponeşti ', simpleName: 'Cioponesti ', village: 'Stanesti ' },
            { name: 'Cuculeşti ', simpleName: 'Cuculesti ', village: 'Stanesti ' },
            { name: 'Gârnicetu ', simpleName: 'Garnicetu ', village: 'Stanesti ' },
            { name: 'Linia Dealului ', village: 'Stanesti ' },
            { name: 'Suieşti ', simpleName: 'Suiesti ', village: 'Stanesti ' },
            { name: 'Valea Lungă ', simpleName: 'Valea Lunga ', village: 'Stanesti ' },
            { name: 'Vârleni ', simpleName: 'Varleni ', village: 'Stanesti ' },
            { name: 'Condoieşti ', simpleName: 'Condoiesti ', village: 'Stefanesti ' },
            { name: 'Dobruşa ', simpleName: 'Dobrusa ', village: 'Stefanesti ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Stefanesti ' },
            { name: 'Budurăşti ', simpleName: 'Budurasti ', village: 'Stoenesti ' },
            { name: 'Bârlogu ', simpleName: 'Barlogu ', village: 'Stoenesti ' },
            { name: 'Deleni ', village: 'Stoenesti ' },
            { name: 'Dobriceni ', village: 'Stoenesti ' },
            { name: 'Gruieri ', village: 'Stoenesti ' },
            { name: 'Gruiu ', village: 'Stoenesti ' },
            { name: 'Mogoşeşti ', simpleName: 'Mogosesti ', village: 'Stoenesti ' },
            { name: 'Neghineşti ', simpleName: 'Neghinesti ', village: 'Stoenesti ' },
            { name: 'Piscu Mare ', village: 'Stoenesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Stoenesti ' },
            { name: 'Suseni ', village: 'Stoenesti ' },
            { name: 'Zmeurătu ', simpleName: 'Zmeuratu ', village: 'Stoenesti ' },
            { name: 'Balomireasa ', village: 'Stoilesti ' },
            { name: 'Bulagei ', village: 'Stoilesti ' },
            { name: 'Bârsoiu ', simpleName: 'Barsoiu ', village: 'Stoilesti ' },
            { name: 'Delureni ', village: 'Stoilesti ' },
            { name: 'Geamăna ', simpleName: 'Geamana ', village: 'Stoilesti ' },
            { name: 'Ghiobeşti ', simpleName: 'Ghiobesti ', village: 'Stoilesti ' },
            { name: 'Giuroiu ', village: 'Stoilesti ' },
            { name: 'Izvoru Rece ', village: 'Stoilesti ' },
            { name: 'Malu ', village: 'Stoilesti ' },
            { name: 'Neţeşti ', simpleName: 'Netesti ', village: 'Stoilesti ' },
            { name: 'Obogeni ', village: 'Stoilesti ' },
            { name: 'Stăneşti ', simpleName: 'Stanesti ', village: 'Stoilesti ' },
            { name: 'Urşi ', simpleName: 'Ursi ', village: 'Stoilesti ' },
            { name: 'Vlăduleşti ', simpleName: 'Vladulesti ', village: 'Stoilesti ' },
            { name: 'Cireşu ', simpleName: 'Ciresu ', village: 'Stroesti ' },
            { name: 'Dianu ', village: 'Stroesti ' },
            { name: 'Obroceşti ', simpleName: 'Obrocesti ', village: 'Stroesti ' },
            { name: 'Pojogi-Cerna ', village: 'Stroesti ' },
            { name: 'Râmeşti ', simpleName: 'Ramesti ', village: 'Susani ' },
            { name: 'Stoiculeşti ', simpleName: 'Stoiculesti ', village: 'Susani ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Susani ' },
            { name: 'Uşurei ', simpleName: 'Usurei ', village: 'Susani ' },
            { name: 'Boroşeşti ', simpleName: 'Borosesti ', village: 'Sutesti ' },
            { name: 'Cetăţeaua ', simpleName: 'Cetateaua ', village: 'Sutesti ' },
            { name: 'Izvoraşu ', simpleName: 'Izvorasu ', village: 'Sutesti ' },
            { name: 'Mitrofani ', village: 'Sutesti ' },
            { name: 'Măzili ', simpleName: 'Mazili ', village: 'Sutesti ' },
            { name: 'Racu ', village: 'Sutesti ' },
            { name: 'Verdea ', village: 'Sutesti ' },
            { name: 'Budele ', village: 'Tetoiu ' },
            { name: 'Băroiu ', simpleName: 'Baroiu ', village: 'Tetoiu ' },
            { name: 'Măneasa ', simpleName: 'Maneasa ', village: 'Tetoiu ' },
            { name: 'Nenciuleşti ', simpleName: 'Nenciulesti ', village: 'Tetoiu ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Tetoiu ' },
            { name: 'Ţepeşti ', simpleName: 'Tepesti ', village: 'Tetoiu ' },
            { name: 'Bratoveşti ', simpleName: 'Bratovesti ', village: 'Titesti ' },
            { name: 'Cucoiu ', village: 'Titesti ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Tomsani ' },
            { name: 'Băltăţeni ', simpleName: 'Baltateni ', village: 'Tomsani ' },
            { name: 'Chiceni ', village: 'Tomsani ' },
            { name: 'Dumbrăveşti ', simpleName: 'Dumbravesti ', village: 'Tomsani ' },
            { name: 'Foleştii de Jos ', simpleName: 'Folestii de Jos ', village: 'Tomsani ' },
            { name: 'Foleştii de Sus ', simpleName: 'Folestii de Sus ', village: 'Tomsani ' },
            { name: 'Mireşti ', simpleName: 'Miresti ', village: 'Tomsani ' },
            { name: 'Cerna ', village: 'Vaideeni ' },
            { name: 'Cornet ', village: 'Vaideeni ' },
            { name: 'Izvoru Rece ', village: 'Vaideeni ' },
            { name: 'Mariţa ', simpleName: 'Marita ', village: 'Vaideeni ' },
            { name: 'Bătăşani ', simpleName: 'Batasani ', village: 'Valea Mare ' },
            { name: 'Delureni ', village: 'Valea Mare ' },
            { name: 'Drăganu ', simpleName: 'Draganu ', village: 'Valea Mare ' },
            { name: 'Mărgineni ', simpleName: 'Margineni ', village: 'Valea Mare ' },
            { name: 'Pietroasa ', village: 'Valea Mare ' },
            { name: 'Fundătura ', simpleName: 'Fundatura ', village: 'Vladesti ' },
            { name: 'Pleaşa ', simpleName: 'Pleasa ', village: 'Vladesti ' },
            { name: 'Priporu ', village: 'Vladesti ' },
            { name: 'Trundin ', village: 'Vladesti ' },
            { name: 'Tighina ', village: 'Voicesti ' },
            { name: 'Voiceştii din Vale ', simpleName: 'Voicestii din Vale ', village: 'Voicesti ' },
            { name: 'Valea Măceşului ', simpleName: 'Valea Macesului ', village: 'Voineasa ' },
            { name: 'Voineşiţa ', simpleName: 'Voinesita ', village: 'Voineasa ' },
            { name: 'Butanu ', village: 'Zatreni ' },
            { name: 'Ciorteşti ', simpleName: 'Ciortesti ', village: 'Zatreni ' },
            { name: 'Contea ', village: 'Zatreni ' },
            { name: 'Dealu Glămeia ', simpleName: 'Dealu Glameia ', village: 'Zatreni ' },
            { name: 'Dealu Văleni ', simpleName: 'Dealu Valeni ', village: 'Zatreni ' },
            { name: 'Făureşti ', simpleName: 'Fauresti ', village: 'Zatreni ' },
            { name: 'Găneşti ', simpleName: 'Ganesti ', village: 'Zatreni ' },
            { name: 'Lăcusteni ', simpleName: 'Lacusteni ', village: 'Zatreni ' },
            { name: 'Lăcustenii de Jos ', simpleName: 'Lacustenii de Jos ', village: 'Zatreni ' },
            { name: 'Lăcustenii de Sus ', simpleName: 'Lacustenii de Sus ', village: 'Zatreni ' },
            { name: 'Mecea ', village: 'Zatreni ' },
            { name: 'Mănicea ', simpleName: 'Manicea ', village: 'Zatreni ' },
            { name: 'Olteţu ', simpleName: 'Oltetu ', village: 'Zatreni ' },
            { name: 'Stanomiru ', village: 'Zatreni ' },
            { name: 'Săşcioara ', simpleName: 'Sascioara ', village: 'Zatreni ' },
            { name: 'Valea Văleni ', simpleName: 'Valea Valeni ', village: 'Zatreni ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Zatreni ' },
            {
                name: 'Zătrenii de Sus ', simpleName: 'Zatrenii de Sus ', village: 'Zatreni '
            }
        ]
    },
    {
        auto: 'VS ',
        name: 'Vaslui ',
        cities: [
            { name: 'Albeşti ', simpleName: 'Albesti ' },
            { name: 'Alexandru Vlahuţă ', simpleName: 'Alexandru Vlahuta ' },
            { name: 'Arsura ' },
            { name: 'Banca ' },
            { name: 'Berezeni ' },
            { name: 'Blăgeşti ', simpleName: 'Blagesti ' },
            { name: 'Bogdana ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ' },
            { name: 'Bogdăniţa ', simpleName: 'Bogdanita ' },
            { name: 'Boţeşti ', simpleName: 'Botesti ' },
            { name: 'Băcani ', simpleName: 'Bacani ' },
            { name: 'Băceşti ', simpleName: 'Bacesti ' },
            { name: 'Bălteni ', simpleName: 'Balteni ' },
            { name: 'Codăeşti ', simpleName: 'Codaesti ' },
            { name: 'Coroieşti ', simpleName: 'Coroiesti ' },
            { name: 'Costeşti ', simpleName: 'Costesti ' },
            { name: 'Creţeşti ', simpleName: 'Cretesti ' },
            { name: 'Deleni ' },
            { name: 'Deleşti ', simpleName: 'Delesti ' },
            { name: 'Dragomireşti ', simpleName: 'Dragomiresti ' },
            { name: 'Drânceni ', simpleName: 'Dranceni ' },
            { name: 'Dumeşti ', simpleName: 'Dumesti ' },
            { name: 'Dăneşti ', simpleName: 'Danesti ' },
            { name: 'Epureni ' },
            { name: 'Fălciu ', simpleName: 'Falciu ' },
            { name: 'Ghergheşti ', simpleName: 'Gherghesti ' },
            { name: 'Griviţa ', simpleName: 'Grivita ' },
            { name: 'Gârceni ', simpleName: 'Garceni ' },
            { name: 'Găgeşti ', simpleName: 'Gagesti ' },
            { name: 'Hoceni ' },
            { name: 'Huşi ', simpleName: 'Husi ' },
            { name: 'Iana ' },
            { name: 'Ibăneşti ', simpleName: 'Ibanesti ' },
            { name: 'Iveşti ', simpleName: 'Ivesti ' },
            { name: 'Ivăneşti ', simpleName: 'Ivanesti ' },
            { name: 'Laza ' },
            { name: 'Lipovăţ ', simpleName: 'Lipovat ' },
            { name: 'Lunca Banului ' },
            { name: 'Micleşti ', simpleName: 'Miclesti ' },
            { name: 'Muntenii de Jos ' },
            { name: 'Murgeni ' },
            { name: 'Măluşteni ', simpleName: 'Malusteni ' },
            { name: 'Negreşti ', simpleName: 'Negresti ' },
            { name: 'Olteneşti ', simpleName: 'Oltenesti ' },
            { name: 'Oşeşti ', simpleName: 'Osesti ' },
            { name: 'Perieni ' },
            { name: 'Pogana ' },
            { name: 'Poieneşti ', simpleName: 'Poienesti ' },
            { name: 'Puieşti ', simpleName: 'Puiesti ' },
            { name: 'Pungeşti ', simpleName: 'Pungesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ' },
            { name: 'Rebricea ' },
            { name: 'Roşieşti ', simpleName: 'Rosiesti ' },
            { name: 'Soleşti ', simpleName: 'Solesti ' },
            { name: 'Stănileşti ', simpleName: 'Stanilesti ' },
            { name: 'Tanacu ' },
            { name: 'Todireşti ', simpleName: 'Todiresti ' },
            { name: 'Tutova ' },
            { name: 'Tăcuta ', simpleName: 'Tacuta ' },
            { name: 'Tătărăni ', simpleName: 'Tatarani ' },
            { name: 'Vaslui ' },
            { name: 'Vetrişoaia ', simpleName: 'Vetrisoaia ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ' },
            { name: 'Vinderei ' },
            { name: 'Voineşti ', simpleName: 'Voinesti ' },
            { name: 'Vultureşti ', simpleName: 'Vulturesti ' },
            { name: 'Vutcani ' },
            { name: 'Văleni ', simpleName: 'Valeni ' },
            { name: 'Zorleni ' },
            { name: 'Zăpodeni ', simpleName: 'Zapodeni ' },
            { name: 'Ştefan cel Mare ', simpleName: 'Stefan cel Mare ' },
            { name: 'Şuletea ', simpleName: 'Suletea ' },
            { name: 'Corni-Albeşti ', simpleName: 'Corni-Albesti ', village: 'Albesti ' },
            { name: 'Crasna ', village: 'Albesti ' },
            { name: 'Gura Albeşti ', simpleName: 'Gura Albesti ', village: 'Albesti ' },
            { name: 'Buda ', village: 'Alexandru Vlahuta ' },
            { name: 'Ghicani ', village: 'Alexandru Vlahuta ' },
            { name: 'Morăreni ', simpleName: 'Morareni ', village: 'Alexandru Vlahuta ' },
            { name: 'Fundătura ', simpleName: 'Fundatura ', village: 'Arsura ' },
            { name: 'Mihail Kogălniceanu ', simpleName: 'Mihail Kogalniceanu ', village: 'Arsura ' },
            { name: 'Pâhneşti ', simpleName: 'Pahnesti ', village: 'Arsura ' },
            { name: 'Băltăţeni ', simpleName: 'Baltateni ', village: 'Bacani ' },
            { name: 'Drujeşti ', simpleName: 'Drujesti ', village: 'Bacani ' },
            { name: 'Suseni ', village: 'Bacani ' },
            { name: 'Vulpăşeni ', simpleName: 'Vulpaseni ', village: 'Bacani ' },
            { name: 'Armăşeni ', simpleName: 'Armaseni ', village: 'Bacesti ' },
            { name: 'Băbuşa ', simpleName: 'Babusa ', village: 'Bacesti ' },
            { name: 'Păltiniş ', simpleName: 'Paltinis ', village: 'Bacesti ' },
            { name: 'Vovrieşti ', simpleName: 'Vovriesti ', village: 'Bacesti ' },
            { name: 'Ţibăneştii Buhlii ', simpleName: 'Tibanestii Buhlii ', village: 'Bacesti ' },
            { name: 'Bălteni-Deal ', simpleName: 'Balteni-Deal ', village: 'Balteni ' },
            { name: 'Chetreşti ', simpleName: 'Chetresti ', village: 'Balteni ' },
            { name: '1 Decembrie ', village: 'Banca ' },
            { name: 'Gara Banca ', village: 'Banca ' },
            { name: 'Ghermăneşti ', simpleName: 'Ghermanesti ', village: 'Banca ' },
            { name: 'Micleşti ', simpleName: 'Miclesti ', village: 'Banca ' },
            { name: 'Mitoc ', village: 'Banca ' },
            { name: 'Satu Nou ', village: 'Banca ' },
            { name: 'Stoişeşti ', simpleName: 'Stoisesti ', village: 'Banca ' },
            { name: 'Strâmtura-Mitoc ', simpleName: 'Stramtura-Mitoc ', village: 'Banca ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Banca ' },
            { name: 'Sălcioara ', simpleName: 'Salcioara ', village: 'Banca ' },
            { name: 'Ţifu ', simpleName: 'Tifu ', village: 'Banca ' },
            { name: 'Muşata ', simpleName: 'Musata ', village: 'Berezeni ' },
            { name: 'Rânceni ', simpleName: 'Ranceni ', village: 'Berezeni ' },
            { name: 'Satu Nou ', village: 'Berezeni ' },
            { name: 'Stuhuleţ ', simpleName: 'Stuhulet ', village: 'Berezeni ' },
            { name: 'Bârlad ', simpleName: 'Barlad ', village: 'Birlad ' },
            { name: 'Igeşti ', simpleName: 'Igesti ', village: 'Blagesti ' },
            { name: 'Sipeni ', village: 'Blagesti ' },
            { name: 'Arşiţa ', simpleName: 'Arsita ', village: 'Bogdana ' },
            { name: 'Fântâna Blănarului ', simpleName: 'Fantana Blanarului ', village: 'Bogdana ' },
            { name: 'Găvanu ', simpleName: 'Gavanu ', village: 'Bogdana ' },
            { name: 'Lacu Babei ', village: 'Bogdana ' },
            { name: 'Plopeni ', village: 'Bogdana ' },
            { name: 'Similişoara ', simpleName: 'Similisoara ', village: 'Bogdana ' },
            { name: 'Suceveni ', village: 'Bogdana ' },
            { name: 'Verdeş ', simpleName: 'Verdes ', village: 'Bogdana ' },
            { name: 'Buda ', village: 'Bogdanesti ' },
            { name: 'Horoiata ', village: 'Bogdanesti ' },
            { name: 'Hupca ', village: 'Bogdanesti ' },
            { name: 'Orgoieşti ', simpleName: 'Orgoiesti ', village: 'Bogdanesti ' },
            { name: 'Ulea ', village: 'Bogdanesti ' },
            { name: 'Unţeşti ', simpleName: 'Untesti ', village: 'Bogdanesti ' },
            { name: 'Vişinari ', simpleName: 'Visinari ', village: 'Bogdanesti ' },
            { name: 'Vlădeşti ', simpleName: 'Vladesti ', village: 'Bogdanesti ' },
            { name: 'Cepeşti ', simpleName: 'Cepesti ', village: 'Bogdanita ' },
            { name: 'Coroieşti ', simpleName: 'Coroiesti ', village: 'Bogdanita ' },
            { name: 'Cârţibaşi ', simpleName: 'Cartibasi ', village: 'Bogdanita ' },
            { name: 'Rădăeşti ', simpleName: 'Radaesti ', village: 'Bogdanita ' },
            { name: 'Schitu ', village: 'Bogdanita ' },
            { name: 'Tunseşti ', simpleName: 'Tunsesti ', village: 'Bogdanita ' },
            { name: 'Gugeşti ', simpleName: 'Gugesti ', village: 'Botesti ' },
            { name: 'Găneşti ', simpleName: 'Ganesti ', village: 'Botesti ' },
            { name: 'Tălpigeni ', simpleName: 'Talpigeni ', village: 'Botesti ' },
            { name: 'Armăşeni ', simpleName: 'Armaseni ', village: 'Bunesti-Averesti ' },
            { name: 'Avereşti ', simpleName: 'Averesti ', village: 'Bunesti-Averesti ' },
            { name: 'Buneşti ', simpleName: 'Bunesti ', village: 'Bunesti-Averesti ' },
            { name: 'Plopi ', village: 'Bunesti-Averesti ' },
            { name: 'Podu Oprii ', village: 'Bunesti-Averesti ' },
            { name: 'Roşiori ', simpleName: 'Rosiori ', village: 'Bunesti-Averesti ' },
            { name: 'Tăbălăeşti ', simpleName: 'Tabalaesti ', village: 'Bunesti-Averesti ' },
            { name: 'Ghergheleu ', village: 'Codaesti ' },
            { name: 'Pribeşti ', simpleName: 'Pribesti ', village: 'Codaesti ' },
            { name: 'Rediu Galian ', village: 'Codaesti ' },
            { name: 'Chilieni ', village: 'Coroiesti ' },
            { name: 'Coroieştii de Sus ', simpleName: 'Coroiestii de Sus ', village: 'Coroiesti ' },
            { name: 'Hreasca ', village: 'Coroiesti ' },
            { name: 'Mireni ', village: 'Coroiesti ' },
            { name: 'Movileni ', village: 'Coroiesti ' },
            { name: 'Păcurăreşti ', simpleName: 'Pacuraresti ', village: 'Coroiesti ' },
            { name: 'Chiţcani ', simpleName: 'Chitcani ', village: 'Costesti ' },
            { name: 'Dinga ', village: 'Costesti ' },
            { name: 'Puntişeni ', simpleName: 'Puntiseni ', village: 'Costesti ' },
            { name: 'Pârveşti ', simpleName: 'Parvesti ', village: 'Costesti ' },
            { name: 'Rădeşti ', simpleName: 'Radesti ', village: 'Costesti ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Cretesti ' },
            { name: 'Creţeştii de Sus ', simpleName: 'Cretestii de Sus ', village: 'Cretesti ' },
            { name: 'Satu Nou ', village: 'Cretesti ' },
            { name: 'Bereasa ', village: 'Danesti ' },
            { name: 'Boţoaia ', simpleName: 'Botoaia ', village: 'Danesti ' },
            { name: 'Emil Racoviţă ', simpleName: 'Emil Racovita ', village: 'Danesti ' },
            { name: 'Răşcani ', simpleName: 'Rascani ', village: 'Danesti ' },
            { name: 'Tătărăni ', simpleName: 'Tatarani ', village: 'Danesti ' },
            { name: 'Bulboaca ', village: 'Deleni ' },
            { name: 'Moreni ', village: 'Deleni ' },
            { name: 'Zizinca ', village: 'Deleni ' },
            { name: 'Albeşti ', simpleName: 'Albesti ', village: 'Delesti ' },
            { name: 'Băleşti ', simpleName: 'Balesti ', village: 'Delesti ' },
            { name: 'Cozmeşti ', simpleName: 'Cozmesti ', village: 'Delesti ' },
            { name: 'Fundătura ', simpleName: 'Fundatura ', village: 'Delesti ' },
            { name: 'Fâstâci ', simpleName: 'Fastaci ', village: 'Delesti ' },
            { name: 'Hordileşti ', simpleName: 'Hordilesti ', village: 'Delesti ' },
            { name: 'Hârsova ', simpleName: 'Harsova ', village: 'Delesti ' },
            { name: 'Mânăstirea ', simpleName: 'Manastirea ', village: 'Delesti ' },
            { name: 'Răduieşti ', simpleName: 'Raduiesti ', village: 'Delesti ' },
            { name: 'Grumezoaia ', village: 'Dimitrie Cantemir ' },
            { name: 'Guşiţei ', simpleName: 'Gusitei ', village: 'Dimitrie Cantemir ' },
            { name: 'Hurdugi ', village: 'Dimitrie Cantemir ' },
            { name: 'Plotoneşti ', simpleName: 'Plotonesti ', village: 'Dimitrie Cantemir ' },
            { name: 'Urlaţi ', simpleName: 'Urlati ', village: 'Dimitrie Cantemir ' },
            { name: 'Belzeni ', village: 'Dragomiresti ' },
            { name: 'Botoi ', village: 'Dragomiresti ' },
            { name: 'Băbuţa ', simpleName: 'Babuta ', village: 'Dragomiresti ' },
            { name: 'Ciuperca ', village: 'Dragomiresti ' },
            { name: 'Doagele ', village: 'Dragomiresti ' },
            { name: 'Poiana Pietrei ', village: 'Dragomiresti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Dragomiresti ' },
            { name: 'Rădeni ', simpleName: 'Radeni ', village: 'Dragomiresti ' },
            { name: 'Semenea ', village: 'Dragomiresti ' },
            { name: 'Tuleşti ', simpleName: 'Tulesti ', village: 'Dragomiresti ' },
            { name: 'Vladia ', village: 'Dragomiresti ' },
            { name: 'Albiţa ', simpleName: 'Albita ', village: 'Dranceni ' },
            { name: 'Băile Drânceni ', simpleName: 'Baile Dranceni ', village: 'Dranceni ' },
            { name: 'Ghermăneşti ', simpleName: 'Ghermanesti ', village: 'Dranceni ' },
            { name: 'Râşeşti ', simpleName: 'Rasesti ', village: 'Dranceni ' },
            { name: 'Şopârleni ', simpleName: 'Soparleni ', village: 'Dranceni ' },
            { name: 'Bobeşti ', simpleName: 'Bobesti ', village: 'Duda-Epureni ' },
            { name: 'Duda ', village: 'Duda-Epureni ' },
            { name: 'Epureni ', village: 'Duda-Epureni ' },
            { name: 'Valea Grecului ', village: 'Duda-Epureni ' },
            { name: 'Dumeştii Vechi ', simpleName: 'Dumestii Vechi ', village: 'Dumesti ' },
            { name: 'Schinetea ', village: 'Dumesti ' },
            { name: 'Valea Mare ', village: 'Dumesti ' },
            { name: 'Bursuci ', village: 'Epureni ' },
            { name: 'Bârlăleşti ', simpleName: 'Barlalesti ', village: 'Epureni ' },
            { name: 'Horga ', village: 'Epureni ' },
            { name: 'Bogdăneşti ', simpleName: 'Bogdanesti ', village: 'Falciu ' },
            { name: 'Bozia ', village: 'Falciu ' },
            { name: 'Copăceana ', simpleName: 'Copaceana ', village: 'Falciu ' },
            { name: 'Odaia Bogdana ', village: 'Falciu ' },
            { name: 'Rânzeşti ', simpleName: 'Ranzesti ', village: 'Falciu ' },
            { name: 'Giurcani ', village: 'Gagesti ' },
            { name: 'Peicani ', village: 'Gagesti ' },
            { name: 'Popeni ', village: 'Gagesti ' },
            { name: 'Tupilaţi ', simpleName: 'Tupilati ', village: 'Gagesti ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ', village: 'Garceni ' },
            { name: 'Racova ', village: 'Garceni ' },
            { name: 'Racoviţa ', simpleName: 'Racovita ', village: 'Garceni ' },
            { name: 'Slobozia ', village: 'Garceni ' },
            { name: 'Trohan ', village: 'Garceni ' },
            { name: 'Chetrosu ', village: 'Gherghesti ' },
            { name: 'Corodeşti ', simpleName: 'Corodesti ', village: 'Gherghesti ' },
            { name: 'Dragomăneşti ', simpleName: 'Dragomanesti ', village: 'Gherghesti ' },
            { name: 'Drăxeni ', simpleName: 'Draxeni ', village: 'Gherghesti ' },
            { name: 'Lazu ', village: 'Gherghesti ' },
            { name: 'Lunca ', village: 'Gherghesti ' },
            { name: 'Soci ', village: 'Gherghesti ' },
            { name: 'Valea Lupului ', village: 'Gherghesti ' },
            { name: 'Fruntişeni ', simpleName: 'Fruntiseni ', village: 'Grivita ' },
            { name: 'Grăjdeni ', simpleName: 'Grajdeni ', village: 'Grivita ' },
            { name: 'Odaia Bursucani ', village: 'Grivita ' },
            { name: 'Trestiana ', village: 'Grivita ' },
            { name: 'Barboşi ', simpleName: 'Barbosi ', village: 'Hoceni ' },
            { name: 'Deleni ', village: 'Hoceni ' },
            { name: 'Oţeleni ', simpleName: 'Oteleni ', village: 'Hoceni ' },
            { name: 'Rediu ', village: 'Hoceni ' },
            { name: 'Tomşa ', simpleName: 'Tomsa ', village: 'Hoceni ' },
            { name: 'Şişcani ', simpleName: 'Siscani ', village: 'Hoceni ' },
            { name: 'Hălăreşti ', simpleName: 'Halaresti ', village: 'Iana ' },
            { name: 'Recea ', village: 'Iana ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Iana ' },
            { name: 'Vadurile ', village: 'Iana ' },
            { name: 'Mânzaţi ', simpleName: 'Manzati ', village: 'Ibanesti ' },
            { name: 'Puţu Olarului ', simpleName: 'Putu Olarului ', village: 'Ibanesti ' },
            { name: 'Albina ', village: 'Ivanesti ' },
            { name: 'Bleşca ', simpleName: 'Blesca ', village: 'Ivanesti ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ', village: 'Ivanesti ' },
            { name: 'Buscata ', village: 'Ivanesti ' },
            { name: 'Coşca ', simpleName: 'Cosca ', village: 'Ivanesti ' },
            { name: 'Coşeşti ', simpleName: 'Cosesti ', village: 'Ivanesti ' },
            { name: 'Fundătura Mare ', simpleName: 'Fundatura Mare ', village: 'Ivanesti ' },
            { name: 'Fundătura Mică ', simpleName: 'Fundatura Mica ', village: 'Ivanesti ' },
            { name: 'Hârşoveni ', simpleName: 'Harsoveni ', village: 'Ivanesti ' },
            { name: 'Iezerel ', village: 'Ivanesti ' },
            { name: 'Ursoaia ', village: 'Ivanesti ' },
            { name: 'Valea Mare ', village: 'Ivanesti ' },
            { name: 'Valea Oanei ', village: 'Ivanesti ' },
            { name: 'Belceşti ', simpleName: 'Belcesti ', village: 'Ivesti ' },
            { name: 'Pogoneşti ', simpleName: 'Pogonesti ', village: 'Ivesti ' },
            { name: 'Polocin ', village: 'Ivesti ' },
            { name: 'Bejeneşti ', simpleName: 'Bejenesti ', village: 'Laza ' },
            { name: 'Poiana lui Alexa ', village: 'Laza ' },
            { name: 'Puşcaşi ', simpleName: 'Puscasi ', village: 'Laza ' },
            { name: 'Râşniţa ', simpleName: 'Rasnita ', village: 'Laza ' },
            { name: 'Sauca ', village: 'Laza ' },
            { name: 'Teişoru ', simpleName: 'Teisoru ', village: 'Laza ' },
            { name: 'Valea Târgului ', simpleName: 'Valea Targului ', village: 'Laza ' },
            { name: 'Chiţoc ', simpleName: 'Chitoc ', village: 'Lipovat ' },
            { name: 'Corbu ', village: 'Lipovat ' },
            { name: 'Căpuşneni ', simpleName: 'Capusneni ', village: 'Lipovat ' },
            { name: 'Fundu Văii ', simpleName: 'Fundu Vaii ', village: 'Lipovat ' },
            { name: 'Broscoşeşti ', simpleName: 'Broscosesti ', village: 'Lunca Banului ' },
            { name: 'Condrea ', village: 'Lunca Banului ' },
            { name: 'Focşa ', simpleName: 'Focsa ', village: 'Lunca Banului ' },
            { name: 'Lunca Veche ', village: 'Lunca Banului ' },
            { name: 'Oţetoaia ', simpleName: 'Otetoaia ', village: 'Lunca Banului ' },
            { name: 'Răducani ', simpleName: 'Raducani ', village: 'Lunca Banului ' },
            { name: 'Ghireasca ', village: 'Malusteni ' },
            { name: 'Lupeşti ', simpleName: 'Lupesti ', village: 'Malusteni ' },
            { name: 'Mânzăteşti ', simpleName: 'Manzatesti ', village: 'Malusteni ' },
            { name: 'Mânăstirea ', simpleName: 'Manastirea ', village: 'Malusteni ' },
            { name: 'Ţuţcani ', simpleName: 'Tutcani ', village: 'Malusteni ' },
            { name: 'Chirceşti ', simpleName: 'Chircesti ', village: 'Miclesti ' },
            { name: 'Popeşti ', simpleName: 'Popesti ', village: 'Miclesti ' },
            { name: 'Băcăoani ', simpleName: 'Bacaoani ', village: 'Muntenii De Jos ' },
            { name: 'Mânjeşti ', simpleName: 'Manjesti ', village: 'Muntenii De Jos ' },
            { name: 'Secuia ', village: 'Muntenii De Jos ' },
            { name: 'Cârja ', simpleName: 'Carja ', village: 'Murgeni ' },
            { name: 'Floreni ', village: 'Murgeni ' },
            { name: 'Lăţeşti ', simpleName: 'Latesti ', village: 'Murgeni ' },
            { name: 'Raiu ', village: 'Murgeni ' },
            { name: 'Schineni ', village: 'Murgeni ' },
            { name: 'Sărăţeni ', simpleName: 'Sarateni ', village: 'Murgeni ' },
            { name: 'Cioatele ', village: 'Negresti ' },
            { name: 'Căzăneşti ', simpleName: 'Cazanesti ', village: 'Negresti ' },
            { name: 'Glodeni ', village: 'Negresti ' },
            { name: 'Parpaniţa ', simpleName: 'Parpanita ', village: 'Negresti ' },
            { name: 'Poiana ', village: 'Negresti ' },
            { name: 'Valea Mare ', village: 'Negresti ' },
            { name: 'Curteni ', village: 'Oltenesti ' },
            { name: 'Pâhna ', simpleName: 'Pahna ', village: 'Oltenesti ' },
            { name: 'Târzii ', simpleName: 'Tarzii ', village: 'Oltenesti ' },
            { name: 'Vineţeşti ', simpleName: 'Vinetesti ', village: 'Oltenesti ' },
            { name: 'Zgura ', village: 'Oltenesti ' },
            { name: 'Buda ', village: 'Osesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Osesti ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Osesti ' },
            { name: 'Căpoteşti ', simpleName: 'Capotesti ', village: 'Padureni ' },
            { name: 'Davideşti ', simpleName: 'Davidesti ', village: 'Padureni ' },
            { name: 'Ivăneşti ', simpleName: 'Ivanesti ', village: 'Padureni ' },
            { name: 'Leoşti ', simpleName: 'Leosti ', village: 'Padureni ' },
            { name: 'Rusca ', village: 'Padureni ' },
            { name: 'Todireni ', village: 'Padureni ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Padureni ' },
            { name: 'Ciocani ', village: 'Perieni ' },
            { name: 'Crâng ', simpleName: 'Crang ', village: 'Perieni ' },
            { name: 'Crângu Nou ', simpleName: 'Crangu Nou ', village: 'Perieni ' },
            { name: 'Podu Petriş ', simpleName: 'Podu Petris ', village: 'Perieni ' },
            { name: 'Bogeşti ', simpleName: 'Bogesti ', village: 'Pogana ' },
            { name: 'Cârjoani ', simpleName: 'Carjoani ', village: 'Pogana ' },
            { name: 'Măscurei ', simpleName: 'Mascurei ', village: 'Pogana ' },
            { name: 'Tomeşti ', simpleName: 'Tomesti ', village: 'Pogana ' },
            { name: 'Dealu Secării ', simpleName: 'Dealu Secarii ', village: 'Poienesti ' },
            { name: 'Floreşti ', simpleName: 'Floresti ', village: 'Poienesti ' },
            { name: 'Frasinu ', village: 'Poienesti ' },
            { name: 'Fundu Văii ', simpleName: 'Fundu Vaii ', village: 'Poienesti ' },
            { name: 'Oprişiţa ', simpleName: 'Oprisita ', village: 'Poienesti ' },
            { name: 'Poieneşti-Deal ', simpleName: 'Poienesti-Deal ', village: 'Poienesti ' },
            { name: 'Bărtăluş-Mocani ', simpleName: 'Bartalus-Mocani ', village: 'Puiesti ' },
            { name: 'Bărtăluş-Răzeşi ', simpleName: 'Bartalus-Razesi ', village: 'Puiesti ' },
            { name: 'Cetăţuia ', simpleName: 'Cetatuia ', village: 'Puiesti ' },
            { name: 'Cristeşti ', simpleName: 'Cristesti ', village: 'Puiesti ' },
            { name: 'Călimăneşti ', simpleName: 'Calimanesti ', village: 'Puiesti ' },
            { name: 'Fulgu ', village: 'Puiesti ' },
            { name: 'Fântânele ', simpleName: 'Fantanele ', village: 'Puiesti ' },
            { name: 'Gâlţeşti ', simpleName: 'Galtesti ', village: 'Puiesti ' },
            { name: 'Iezer ', village: 'Puiesti ' },
            { name: 'Lăleşti ', simpleName: 'Lalesti ', village: 'Puiesti ' },
            { name: 'Rotari ', village: 'Puiesti ' },
            { name: 'Ruşi ', simpleName: 'Rusi ', village: 'Puiesti ' },
            { name: 'Armăşoaia ', simpleName: 'Armasoaia ', village: 'Pungesti ' },
            { name: 'Curseşti-Deal ', simpleName: 'Cursesti-Deal ', village: 'Pungesti ' },
            { name: 'Curseşti-Vale ', simpleName: 'Cursesti-Vale ', village: 'Pungesti ' },
            { name: 'Hordila ', village: 'Pungesti ' },
            { name: 'Rapşa ', simpleName: 'Rapsa ', village: 'Pungesti ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Pungesti ' },
            { name: 'Stejaru ', village: 'Pungesti ' },
            { name: 'Toporăşti ', simpleName: 'Toporasti ', village: 'Pungesti ' },
            { name: 'Bolaţi ', simpleName: 'Bolati ', village: 'Rebricea ' },
            { name: 'Crăciuneşti ', simpleName: 'Craciunesti ', village: 'Rebricea ' },
            { name: 'Draxeni ', village: 'Rebricea ' },
            { name: 'Măcreşti ', simpleName: 'Macresti ', village: 'Rebricea ' },
            { name: 'Rateşu Cuzei ', simpleName: 'Ratesu Cuzei ', village: 'Rebricea ' },
            { name: 'Sasova ', village: 'Rebricea ' },
            { name: 'Tatomireşti ', simpleName: 'Tatomiresti ', village: 'Rebricea ' },
            { name: 'Tufeştii de Jos ', simpleName: 'Tufestii de Jos ', village: 'Rebricea ' },
            { name: 'Codreni ', village: 'Rosiesti ' },
            { name: 'Gara Roşieşti ', simpleName: 'Gara Rosiesti ', village: 'Rosiesti ' },
            { name: 'Gura Idrici ', village: 'Rosiesti ' },
            { name: 'Idrici ', village: 'Rosiesti ' },
            { name: 'Rediu ', village: 'Rosiesti ' },
            { name: 'Valea lui Darie ', village: 'Rosiesti ' },
            { name: 'Bouşori ', simpleName: 'Bousori ', village: 'Solesti ' },
            { name: 'Iaz ', village: 'Solesti ' },
            { name: 'Satu Nou ', village: 'Solesti ' },
            { name: 'Valea Siliştei ', simpleName: 'Valea Silistei ', village: 'Solesti ' },
            { name: 'Şerboteşti ', simpleName: 'Serbotesti ', village: 'Solesti ' },
            { name: 'Ştioborăni ', simpleName: 'Stioborani ', village: 'Solesti ' },
            { name: 'Bogdana-Voloseni ', village: 'Stanilesti ' },
            { name: 'Budu Cantemir ', village: 'Stanilesti ' },
            { name: 'Chersăcosu ', simpleName: 'Chersacosu ', village: 'Stanilesti ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Stanilesti ' },
            { name: 'Pogăneşti ', simpleName: 'Poganesti ', village: 'Stanilesti ' },
            { name: 'Săratu ', simpleName: 'Saratu ', village: 'Stanilesti ' },
            { name: 'Brăhăşoaia ', simpleName: 'Brahasoaia ', village: 'Stefan Cel Mare ' },
            { name: 'Bârzeşti ', simpleName: 'Barzesti ', village: 'Stefan Cel Mare ' },
            { name: 'Călugăreni ', simpleName: 'Calugareni ', village: 'Stefan Cel Mare ' },
            { name: 'Cănţălăreşti ', simpleName: 'Cantalaresti ', village: 'Stefan Cel Mare ' },
            { name: 'Munteneşti ', simpleName: 'Muntenesti ', village: 'Stefan Cel Mare ' },
            { name: 'Mărăşeni ', simpleName: 'Maraseni ', village: 'Stefan Cel Mare ' },
            { name: 'Fedeşti ', simpleName: 'Fedesti ', village: 'Suletea ' },
            { name: 'Jigălia ', simpleName: 'Jigalia ', village: 'Suletea ' },
            { name: 'Răşcani ', simpleName: 'Rascani ', village: 'Suletea ' },
            { name: 'Cujba ', village: 'Tacuta ' },
            { name: 'Dumasca ', village: 'Tacuta ' },
            { name: 'Focşeaşca ', simpleName: 'Focseasca ', village: 'Tacuta ' },
            { name: 'Mirceşti ', simpleName: 'Mircesti ', village: 'Tacuta ' },
            { name: 'Protopopeşti ', simpleName: 'Protopopesti ', village: 'Tacuta ' },
            { name: 'Sofieni ', village: 'Tacuta ' },
            { name: 'Beneşti ', simpleName: 'Benesti ', village: 'Tanacu ' },
            { name: 'Muntenii de Sus ', village: 'Tanacu ' },
            { name: 'Satu Nou ', village: 'Tanacu ' },
            { name: 'Bălţaţi ', simpleName: 'Baltati ', village: 'Tatarani ' },
            { name: 'Crăsnăşeni ', simpleName: 'Crasnaseni ', village: 'Tatarani ' },
            { name: 'Giurgeşti ', simpleName: 'Giurgesti ', village: 'Tatarani ' },
            { name: 'Leoşti ', simpleName: 'Leosti ', village: 'Tatarani ' },
            { name: 'Mantu ', village: 'Tatarani ' },
            { name: 'Stroieşti ', simpleName: 'Stroiesti ', village: 'Tatarani ' },
            { name: 'Valea Seacă ', simpleName: 'Valea Seaca ', village: 'Tatarani ' },
            { name: 'Valea lui Bosie ', village: 'Tatarani ' },
            { name: 'Cotic ', village: 'Todiresti ' },
            { name: 'Drăgeşti ', simpleName: 'Dragesti ', village: 'Todiresti ' },
            { name: 'Huc ', village: 'Todiresti ' },
            { name: 'Plopoasa ', village: 'Todiresti ' },
            { name: 'Rafaila ', village: 'Todiresti ' },
            { name: 'Siliştea ', simpleName: 'Silistea ', village: 'Todiresti ' },
            { name: 'Sofroneşti ', simpleName: 'Sofronesti ', village: 'Todiresti ' },
            { name: 'Valea Popii ', village: 'Todiresti ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Todiresti ' },
            { name: 'Borodeşti ', simpleName: 'Borodesti ', village: 'Tutova ' },
            { name: 'Bădeana ', simpleName: 'Badeana ', village: 'Tutova ' },
            { name: 'Ciortolom ', village: 'Tutova ' },
            { name: 'Coroiu ', village: 'Tutova ' },
            { name: 'Criveşti ', simpleName: 'Crivesti ', village: 'Tutova ' },
            { name: 'Pochidia ', village: 'Tutova ' },
            { name: 'Satu Nou ', village: 'Tutova ' },
            { name: 'Sălceni ', simpleName: 'Salceni ', village: 'Tutova ' },
            { name: 'Vizureni ', village: 'Tutova ' },
            { name: 'Fereşti ', simpleName: 'Feresti ', village: 'Valeni ' },
            { name: 'Moara Domnească ', simpleName: 'Moara Domneasca ', village: 'Valeni ' },
            { name: 'Bahnari ', village: 'Vaslui ' },
            { name: 'Brodoc ', village: 'Vaslui ' },
            { name: 'Moara Grecilor ', village: 'Vaslui ' },
            { name: 'Rediu ', village: 'Vaslui ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Vaslui ' },
            { name: 'Bumbăta ', simpleName: 'Bumbata ', village: 'Vetrisoaia ' },
            { name: 'Dodeşti ', simpleName: 'Dodesti ', village: 'Viisoara ' },
            { name: 'Halta Dodeşti ', simpleName: 'Halta Dodesti ', village: 'Viisoara ' },
            { name: 'Urdeşti ', simpleName: 'Urdesti ', village: 'Viisoara ' },
            { name: 'Viltoteşti ', simpleName: 'Viltotesti ', village: 'Viisoara ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Viisoara ' },
            { name: 'Brădeşti ', simpleName: 'Bradesti ', village: 'Vinderei ' },
            { name: 'Docani ', village: 'Vinderei ' },
            { name: 'Docăneasa ', simpleName: 'Docaneasa ', village: 'Vinderei ' },
            { name: 'Gara Docăneasa ', simpleName: 'Gara Docaneasa ', village: 'Vinderei ' },
            { name: 'Gara Tălăşman ', simpleName: 'Gara Talasman ', village: 'Vinderei ' },
            { name: 'Obârşeni ', simpleName: 'Obarseni ', village: 'Vinderei ' },
            { name: 'Valea Lungă ', simpleName: 'Valea Lunga ', village: 'Vinderei ' },
            { name: 'Avrămeşti ', simpleName: 'Avramesti ', village: 'Voinesti ' },
            { name: 'Bănceşti ', simpleName: 'Bancesti ', village: 'Voinesti ' },
            { name: 'Corobăneşti ', simpleName: 'Corobanesti ', village: 'Voinesti ' },
            { name: 'Gârdeşti ', simpleName: 'Gardesti ', village: 'Voinesti ' },
            { name: 'Mărăşeşti ', simpleName: 'Marasesti ', village: 'Voinesti ' },
            { name: 'Obârşeni ', simpleName: 'Obarseni ', village: 'Voinesti ' },
            { name: 'Obârşenii Lingurari ', simpleName: 'Obarsenii Lingurari ', village: 'Voinesti ' },
            { name: 'Rugăria ', simpleName: 'Rugaria ', village: 'Voinesti ' },
            { name: 'Stâncăşeni ', simpleName: 'Stancaseni ', village: 'Voinesti ' },
            { name: 'Uricari ', village: 'Voinesti ' },
            { name: 'Buhăieşti ', simpleName: 'Buhaiesti ', village: 'Vulturesti ' },
            { name: 'Podeni ', village: 'Vulturesti ' },
            { name: 'Voineşti ', simpleName: 'Voinesti ', village: 'Vulturesti ' },
            { name: 'Mălăieşti ', simpleName: 'Malaiesti ', village: 'Vutcani ' },
            { name: 'Poşta Elan ', simpleName: 'Posta Elan ', village: 'Vutcani ' },
            { name: 'Butucăria ', simpleName: 'Butucaria ', village: 'Zapodeni ' },
            { name: 'Ciofeni ', village: 'Zapodeni ' },
            { name: 'Delea ', village: 'Zapodeni ' },
            { name: 'Dobrosloveşti ', simpleName: 'Dobroslovesti ', village: 'Zapodeni ' },
            { name: 'Măcreşti ', simpleName: 'Macresti ', village: 'Zapodeni ' },
            { name: 'Portari ', village: 'Zapodeni ' },
            { name: 'Telejna ', village: 'Zapodeni ' },
            { name: 'Unceşti ', simpleName: 'Uncesti ', village: 'Zapodeni ' },
            { name: 'Dealu Mare ', village: 'Zorleni ' },
            { name: 'Popeni ', village: 'Zorleni ' },
            {
                name: 'Simila ', village: 'Zorleni '
            }
        ]
    },
    {
        auto: 'VN ',
        name: 'Vrancea ',
        cities: [
            { name: 'Adjud ' },
            { name: 'Andreiaşu de Jos ', simpleName: 'Andreiasu de Jos ' },
            { name: 'Bogheşti ', simpleName: 'Boghesti ' },
            { name: 'Boloteşti ', simpleName: 'Bolotesti ' },
            { name: 'Bordeşti ', simpleName: 'Bordesti ' },
            { name: 'Broşteni ', simpleName: 'Brosteni ' },
            { name: 'Bârseşti ', simpleName: 'Barsesti ' },
            { name: 'Băleşti ', simpleName: 'Balesti ' },
            { name: 'Chiojdeni ' },
            { name: 'Ciorăşti ', simpleName: 'Ciorasti ' },
            { name: 'Corbiţa ', simpleName: 'Corbita ' },
            { name: 'Coteşti ', simpleName: 'Cotesti ' },
            { name: 'Câmpineanca ', simpleName: 'Campineanca ' },
            { name: 'Câmpuri ', simpleName: 'Campuri ' },
            { name: 'Cârligele ', simpleName: 'Carligele ' },
            { name: 'Dumbrăveni ', simpleName: 'Dumbraveni ' },
            { name: 'Dumitreşti ', simpleName: 'Dumitresti ' },
            { name: 'Fitioneşti ', simpleName: 'Fitionesti ' },
            { name: 'Focşani ', simpleName: 'Focsani ' },
            { name: 'Garoafa ' },
            { name: 'Goleşti ', simpleName: 'Golesti ' },
            { name: 'Gugeşti ', simpleName: 'Gugesti ' },
            { name: 'Gura Caliţei ', simpleName: 'Gura Calitei ' },
            { name: 'Homocea ' },
            { name: 'Jariştea ', simpleName: 'Jaristea ' },
            { name: 'Jitia ' },
            { name: 'Mera ' },
            { name: 'Milcovul ' },
            { name: 'Moviliţa ', simpleName: 'Movilita ' },
            { name: 'Măicăneşti ', simpleName: 'Maicanesti ' },
            { name: 'Mărăşeşti ', simpleName: 'Marasesti ' },
            { name: 'Negrileşti ', simpleName: 'Negrilesti ' },
            { name: 'Nereju ' },
            { name: 'Nistoreşti ', simpleName: 'Nistoresti ' },
            { name: 'Năneşti ', simpleName: 'Nanesti ' },
            { name: 'Năruja ', simpleName: 'Naruja ' },
            { name: 'Odobeşti ', simpleName: 'Odobesti ' },
            { name: 'Paltin ' },
            { name: 'Panciu ' },
            { name: 'Ploscuţeni ', simpleName: 'Ploscuteni ' },
            { name: 'Poiana Cristei ' },
            { name: 'Popeşti ', simpleName: 'Popesti ' },
            { name: 'Pufeşti ', simpleName: 'Pufesti ' },
            { name: 'Păuleşti ', simpleName: 'Paulesti ' },
            { name: 'Păuneşti ', simpleName: 'Paunesti ' },
            { name: 'Reghiu ' },
            { name: 'Rugineşti ', simpleName: 'Ruginesti ' },
            { name: 'Răcoasa ', simpleName: 'Racoasa ' },
            { name: 'Sihlea ' },
            { name: 'Slobozia Bradului ' },
            { name: 'Slobozia Ciorăşti ', simpleName: 'Slobozia Ciorasti ' },
            { name: 'Străoane ', simpleName: 'Straoane ' },
            { name: 'Suraia ' },
            { name: 'Tulnici ' },
            { name: 'Tâmboeşti ', simpleName: 'Tamboesti ' },
            { name: 'Tănăsoaia ', simpleName: 'Tanasoaia ' },
            { name: 'Tătăranu ', simpleName: 'Tataranu ' },
            { name: 'Urecheşti ', simpleName: 'Urechesti ' },
            { name: 'Valea Sării ', simpleName: 'Valea Sarii ' },
            { name: 'Vidra ' },
            { name: 'Vintileasca ' },
            { name: 'Vrâncioaia ', simpleName: 'Vrancioaia ' },
            { name: 'Vulturu ' },
            { name: 'Vânători ', simpleName: 'Vanatori ' },
            { name: 'Vârteşcoiu ', simpleName: 'Vartescoiu ' },
            { name: 'Ţifeşti ', simpleName: 'Tifesti ' },
            { name: 'Adjudu Vechi ', village: 'Adjud ' },
            { name: 'Burcioaia ', village: 'Adjud ' },
            { name: 'Şişcani ', simpleName: 'Siscani ', village: 'Adjud ' },
            { name: 'Andreiaşu de Sus ', simpleName: 'Andreiasu de Sus ', village: 'Andreiasu De Jos ' },
            { name: 'Arşiţa ', simpleName: 'Arsita ', village: 'Andreiasu De Jos ' },
            { name: 'Fetig ', village: 'Andreiasu De Jos ' },
            { name: 'Hotaru ', village: 'Andreiasu De Jos ' },
            { name: 'Răchitaşu ', simpleName: 'Rachitasu ', village: 'Andreiasu De Jos ' },
            { name: 'Titila ', village: 'Andreiasu De Jos ' },
            { name: 'Topeşti ', simpleName: 'Topesti ', village: 'Barsesti ' },
            { name: 'Bicheşti ', simpleName: 'Bichesti ', village: 'Boghesti ' },
            { name: 'Bogheştii de Sus ', simpleName: 'Boghestii de Sus ', village: 'Boghesti ' },
            { name: 'Chiţcani ', simpleName: 'Chitcani ', village: 'Boghesti ' },
            { name: 'Iugani ', village: 'Boghesti ' },
            { name: 'Pleşeşti ', simpleName: 'Plesesti ', village: 'Boghesti ' },
            { name: 'Plăcinţeni ', simpleName: 'Placinteni ', village: 'Boghesti ' },
            { name: 'Prisecani ', village: 'Boghesti ' },
            { name: 'Tăbuceşti ', simpleName: 'Tabucesti ', village: 'Boghesti ' },
            { name: 'Găgeşti ', simpleName: 'Gagesti ', village: 'Bolotesti ' },
            { name: 'Ivănceşti ', simpleName: 'Ivancesti ', village: 'Bolotesti ' },
            { name: 'Pietroasa ', village: 'Bolotesti ' },
            { name: 'Putna ', village: 'Bolotesti ' },
            { name: 'Vităneştii de Sub Măgură ', simpleName: 'Vitanestii de Sub Magura ', village: 'Bolotesti ' },
            { name: 'Bordeştii de Jos ', simpleName: 'Bordestii de Jos ', village: 'Bordesti ' },
            { name: 'Arva ', village: 'Brosteni ' },
            { name: 'Pituluşa ', simpleName: 'Pitulusa ', village: 'Brosteni ' },
            { name: 'Pietroasa ', village: 'Campineanca ' },
            { name: 'Vâlcele ', simpleName: 'Valcele ', village: 'Campineanca ' },
            { name: 'Feteşti ', simpleName: 'Fetesti ', village: 'Campuri ' },
            { name: 'Gura Văii ', simpleName: 'Gura Vaii ', village: 'Campuri ' },
            { name: 'Rotileştii Mari ', simpleName: 'Rotilestii Mari ', village: 'Campuri ' },
            { name: 'Rotileştii Mici ', simpleName: 'Rotilestii Mici ', village: 'Campuri ' },
            { name: 'Blidari ', village: 'Carligele ' },
            { name: 'Bonţeşti ', simpleName: 'Bontesti ', village: 'Carligele ' },
            { name: 'Dălhăuţi ', simpleName: 'Dalhauti ', village: 'Carligele ' },
            { name: 'Cătăuţi ', simpleName: 'Catauti ', village: 'Chiojdeni ' },
            { name: 'Lojniţa ', simpleName: 'Lojnita ', village: 'Chiojdeni ' },
            { name: 'Luncile ', village: 'Chiojdeni ' },
            { name: 'Mărăcini ', simpleName: 'Maracini ', village: 'Chiojdeni ' },
            { name: 'Podurile ', village: 'Chiojdeni ' },
            { name: 'Seciu ', village: 'Chiojdeni ' },
            { name: 'Tulburea ', village: 'Chiojdeni ' },
            { name: 'Codreşti ', simpleName: 'Codresti ', village: 'Ciorasti ' },
            { name: 'Mihălceni ', simpleName: 'Mihalceni ', village: 'Ciorasti ' },
            { name: 'Salcia Nouă ', simpleName: 'Salcia Noua ', village: 'Ciorasti ' },
            { name: 'Salcia Veche ', village: 'Ciorasti ' },
            { name: 'Satu Nou ', village: 'Ciorasti ' },
            { name: 'Spătăreasa ', simpleName: 'Spatareasa ', village: 'Ciorasti ' },
            { name: 'Buda ', village: 'Corbita ' },
            { name: 'Izvoarele ', village: 'Corbita ' },
            { name: 'Lărgăşeni ', simpleName: 'Largaseni ', village: 'Corbita ' },
            { name: 'Ocheşeşti ', simpleName: 'Ochesesti ', village: 'Corbita ' },
            { name: 'Rădăcineşti ', simpleName: 'Radacinesti ', village: 'Corbita ' },
            { name: 'Tuţu ', simpleName: 'Tutu ', village: 'Corbita ' },
            { name: 'Vâlcelele ', simpleName: 'Valcelele ', village: 'Corbita ' },
            { name: 'Şerbăneşti ', simpleName: 'Serbanesti ', village: 'Corbita ' },
            { name: 'Budeşti ', simpleName: 'Budesti ', village: 'Cotesti ' },
            { name: 'Goleştii de Sus ', simpleName: 'Golestii de Sus ', village: 'Cotesti ' },
            { name: 'Valea Coteşti ', simpleName: 'Valea Cotesti ', village: 'Cotesti ' },
            { name: 'Alexandru Vlahuţă ', simpleName: 'Alexandru Vlahuta ', village: 'Dumbraveni ' },
            { name: 'Cândeşti ', simpleName: 'Candesti ', village: 'Dumbraveni ' },
            { name: 'Dragosloveni ', village: 'Dumbraveni ' },
            { name: 'Biceştii de Jos ', simpleName: 'Bicestii de Jos ', village: 'Dumitresti ' },
            { name: 'Biceştii de Sus ', simpleName: 'Bicestii de Sus ', village: 'Dumitresti ' },
            { name: 'Blidari ', village: 'Dumitresti ' },
            { name: 'Dumitreştii de Sus ', simpleName: 'Dumitrestii de Sus ', village: 'Dumitresti ' },
            { name: 'Dumitreştii-Faţă ', simpleName: 'Dumitrestii-Fata ', village: 'Dumitresti ' },
            { name: 'Găloieşti ', simpleName: 'Galoiesti ', village: 'Dumitresti ' },
            { name: 'Lupoaia ', village: 'Dumitresti ' },
            { name: 'Lăstuni ', simpleName: 'Lastuni ', village: 'Dumitresti ' },
            { name: 'Motnău ', simpleName: 'Motnau ', village: 'Dumitresti ' },
            { name: 'Poieniţa ', simpleName: 'Poienita ', village: 'Dumitresti ' },
            { name: 'Roşcari ', simpleName: 'Roscari ', village: 'Dumitresti ' },
            { name: 'Siminoc ', village: 'Dumitresti ' },
            { name: 'Tinoasa ', village: 'Dumitresti ' },
            { name: 'Trestia ', village: 'Dumitresti ' },
            { name: 'Valea Mică ', simpleName: 'Valea Mica ', village: 'Dumitresti ' },
            { name: 'Ciolăneşti ', simpleName: 'Ciolanesti ', village: 'Fitionesti ' },
            { name: 'Ghimiceşti ', simpleName: 'Ghimicesti ', village: 'Fitionesti ' },
            { name: 'Holbăneşti ', simpleName: 'Holbanesti ', village: 'Fitionesti ' },
            { name: 'Mănăstioara ', simpleName: 'Manastioara ', village: 'Fitionesti ' },
            { name: 'Mândreşti-Moldova ', simpleName: 'Mandresti-Moldova ', village: 'Focsani ' },
            { name: 'Mândreşti-Munteni ', simpleName: 'Mandresti-Munteni ', village: 'Focsani ' },
            { name: 'Bizigheşti ', simpleName: 'Bizighesti ', village: 'Garoafa ' },
            { name: 'Ciuşlea ', simpleName: 'Ciuslea ', village: 'Garoafa ' },
            { name: 'Doaga ', village: 'Garoafa ' },
            { name: 'Făurei ', simpleName: 'Faurei ', village: 'Garoafa ' },
            { name: 'Precistanu ', village: 'Garoafa ' },
            { name: 'Răchitosu ', simpleName: 'Rachitosu ', village: 'Garoafa ' },
            { name: 'Străjescu ', simpleName: 'Strajescu ', village: 'Garoafa ' },
            { name: 'Ceardac ', village: 'Golesti ' },
            { name: 'Oreavu ', village: 'Gugesti ' },
            { name: 'Bălăneşti ', simpleName: 'Balanesti ', village: 'Gura Calitei ' },
            { name: 'Cocoşari ', simpleName: 'Cocosari ', village: 'Gura Calitei ' },
            { name: 'Dealul Lung ', village: 'Gura Calitei ' },
            { name: 'Groapa Tufei ', village: 'Gura Calitei ' },
            { name: 'Lacu lui Baban ', village: 'Gura Calitei ' },
            { name: 'Plopu ', village: 'Gura Calitei ' },
            { name: 'Poenile ', village: 'Gura Calitei ' },
            { name: 'Raşca ', simpleName: 'Rasca ', village: 'Gura Calitei ' },
            { name: 'Şotârcari ', simpleName: 'Sotarcari ', village: 'Gura Calitei ' },
            { name: 'Costişa ', simpleName: 'Costisa ', village: 'Homocea ' },
            { name: 'Lespezi ', village: 'Homocea ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Jaristea ' },
            { name: 'Scânteia ', simpleName: 'Scanteia ', village: 'Jaristea ' },
            { name: 'Vărsătura ', simpleName: 'Varsatura ', village: 'Jaristea ' },
            { name: 'Cerbu ', village: 'Jitia ' },
            { name: 'Dealu Sării ', simpleName: 'Dealu Sarii ', village: 'Jitia ' },
            { name: 'Jitia de Jos ', village: 'Jitia ' },
            { name: 'Măgura ', simpleName: 'Magura ', village: 'Jitia ' },
            { name: 'Belciugele ', village: 'Maicanesti ' },
            { name: 'Râmniceni ', simpleName: 'Ramniceni ', village: 'Maicanesti ' },
            { name: 'Slobozia Boteşti ', simpleName: 'Slobozia Botesti ', village: 'Maicanesti ' },
            { name: 'Stupina ', village: 'Maicanesti ' },
            { name: 'Tătaru ', simpleName: 'Tataru ', village: 'Maicanesti ' },
            { name: 'Călimăneşti ', simpleName: 'Calimanesti ', village: 'Marasesti ' },
            { name: 'Haret ', village: 'Marasesti ' },
            { name: 'Modruzeni ', village: 'Marasesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Marasesti ' },
            { name: 'Siretu ', village: 'Marasesti ' },
            { name: 'Tişiţa ', simpleName: 'Tisita ', village: 'Marasesti ' },
            { name: 'Livada ', village: 'Mera ' },
            { name: 'Milcovel ', village: 'Mera ' },
            { name: 'Roşioara ', simpleName: 'Rosioara ', village: 'Mera ' },
            { name: 'Vulcăneasa ', simpleName: 'Vulcaneasa ', village: 'Mera ' },
            { name: 'Gologanu ', village: 'Milcovul ' },
            { name: 'Lămoteşti ', simpleName: 'Lamotesti ', village: 'Milcovul ' },
            { name: 'Răstoaca ', simpleName: 'Rastoaca ', village: 'Milcovul ' },
            { name: 'Diocheţirediu ', simpleName: 'Diochetirediu ', village: 'Movilita ' },
            { name: 'Frecăţei ', simpleName: 'Frecatei ', village: 'Movilita ' },
            { name: 'Trotuşanu ', simpleName: 'Trotusanu ', village: 'Movilita ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Movilita ' },
            { name: 'Călienii Noi ', simpleName: 'Calienii Noi ', village: 'Nanesti ' },
            { name: 'Călienii Vechi ', simpleName: 'Calienii Vechi ', village: 'Nanesti ' },
            { name: 'Podu Nărujei ', simpleName: 'Podu Narujei ', village: 'Naruja ' },
            { name: 'Podu Stoica ', village: 'Naruja ' },
            { name: 'Rebegari ', village: 'Naruja ' },
            { name: 'Brădăceşti ', simpleName: 'Bradacesti ', village: 'Nereju ' },
            { name: 'Chiricani ', village: 'Nereju ' },
            { name: 'Nereju Mic ', village: 'Nereju ' },
            { name: 'Sahastru ', village: 'Nereju ' },
            { name: 'Brădetu ', simpleName: 'Bradetu ', village: 'Nistoresti ' },
            { name: 'Bâtcari ', simpleName: 'Batcari ', village: 'Nistoresti ' },
            { name: 'Făgetu ', simpleName: 'Fagetu ', village: 'Nistoresti ' },
            { name: 'Podu Şchiopului ', simpleName: 'Podu Schiopului ', village: 'Nistoresti ' },
            { name: 'Romăneşti ', simpleName: 'Romanesti ', village: 'Nistoresti ' },
            { name: 'Ungureni ', village: 'Nistoresti ' },
            { name: 'Valea Neagră ', simpleName: 'Valea Neagra ', village: 'Nistoresti ' },
            { name: 'Vetreşti-Herăstrău ', simpleName: 'Vetresti-Herastrau ', village: 'Nistoresti ' },
            { name: 'Unirea ', village: 'Odobesti ' },
            { name: 'Carşocheşti-Corăbiţa ', simpleName: 'Carsochesti-Corabita ', village: 'Paltin ' },
            { name: 'Ghebari ', village: 'Paltin ' },
            { name: 'Morăreşti ', simpleName: 'Moraresti ', village: 'Paltin ' },
            { name: 'Prahuda ', village: 'Paltin ' },
            { name: 'Păvălari ', simpleName: 'Pavalari ', village: 'Paltin ' },
            { name: 'Spulber ', village: 'Paltin ' },
            { name: 'Tojanii de Jos ', village: 'Paltin ' },
            { name: 'Tojanii de Sus ', village: 'Paltin ' },
            { name: 'Vâlcani ', simpleName: 'Valcani ', village: 'Paltin ' },
            { name: 'Ţepa ', simpleName: 'Tepa ', village: 'Paltin ' },
            { name: 'Ţipău ', simpleName: 'Tipau ', village: 'Paltin ' },
            { name: 'Crucea de Jos ', village: 'Panciu ' },
            { name: 'Crucea de Sus ', village: 'Panciu ' },
            { name: 'Dumbrava ', village: 'Panciu ' },
            { name: 'Neicu ', village: 'Panciu ' },
            { name: 'Satu Nou ', village: 'Panciu ' },
            { name: 'Hăulişca ', simpleName: 'Haulisca ', village: 'Paulesti ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Paunesti ' },
            { name: 'Argea ', village: 'Ploscuteni ' },
            { name: 'Dealu Cucului ', village: 'Poiana Cristei ' },
            { name: 'Dumbrava ', village: 'Poiana Cristei ' },
            { name: 'Mahriu ', village: 'Poiana Cristei ' },
            { name: 'Odobasca ', village: 'Poiana Cristei ' },
            { name: 'Petreanu ', village: 'Poiana Cristei ' },
            { name: 'Podu Lacului ', village: 'Poiana Cristei ' },
            { name: 'Târâtu ', simpleName: 'Taratu ', village: 'Poiana Cristei ' },
            { name: 'Tercheşti ', simpleName: 'Terchesti ', village: 'Popesti ' },
            { name: 'Ciorani ', village: 'Pufesti ' },
            { name: 'Domneşti-Sat ', simpleName: 'Domnesti-Sat ', village: 'Pufesti ' },
            { name: 'Domneşti-Târg ', simpleName: 'Domnesti-Targ ', village: 'Pufesti ' },
            { name: 'Gogoiu ', village: 'Racoasa ' },
            { name: 'Mărăşti ', simpleName: 'Marasti ', village: 'Racoasa ' },
            { name: 'Varniţa ', simpleName: 'Varnita ', village: 'Racoasa ' },
            { name: 'Verdea ', village: 'Racoasa ' },
            { name: 'Farcaş ', simpleName: 'Farcas ', village: 'Reghiu ' },
            { name: 'Jgheaburi ', village: 'Reghiu ' },
            { name: 'Piscu Reghiului ', village: 'Reghiu ' },
            { name: 'Răiuţi ', simpleName: 'Raiuti ', village: 'Reghiu ' },
            { name: 'Ursoaia ', village: 'Reghiu ' },
            { name: 'Valea Milcovului ', village: 'Reghiu ' },
            { name: 'Şindrilari ', simpleName: 'Sindrilari ', village: 'Reghiu ' },
            { name: 'Angheleşti ', simpleName: 'Anghelesti ', village: 'Ruginesti ' },
            { name: 'Copăceşti ', simpleName: 'Copacesti ', village: 'Ruginesti ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Ruginesti ' },
            { name: 'Bogza ', village: 'Sihlea ' },
            { name: 'Căiata ', simpleName: 'Caiata ', village: 'Sihlea ' },
            { name: 'Voetin ', village: 'Sihlea ' },
            { name: 'Cornetu ', village: 'Slobozia Bradului ' },
            { name: 'Coroteni ', village: 'Slobozia Bradului ' },
            { name: 'Lieşti ', simpleName: 'Liesti ', village: 'Slobozia Bradului ' },
            { name: 'Olăreni ', simpleName: 'Olareni ', village: 'Slobozia Bradului ' },
            { name: 'Valea Beciului ', village: 'Slobozia Bradului ' },
            { name: 'Armeni ', village: 'Slobozia Ciorasti ' },
            { name: 'Jilişte ', simpleName: 'Jiliste ', village: 'Slobozia Ciorasti ' },
            { name: 'Dragosloveni ', village: 'Soveja ' },
            { name: 'Rucăreni ', simpleName: 'Rucareni ', village: 'Soveja ' },
            { name: 'Muncelu ', village: 'Straoane ' },
            { name: 'Repedea ', village: 'Straoane ' },
            { name: 'Văleni ', simpleName: 'Valeni ', village: 'Straoane ' },
            { name: 'Bilieşti ', simpleName: 'Biliesti ', village: 'Suraia ' },
            { name: 'Obrejiţa ', simpleName: 'Obrejita ', village: 'Tamboesti ' },
            { name: 'Pietroasa ', village: 'Tamboesti ' },
            { name: 'Pădureni ', simpleName: 'Padureni ', village: 'Tamboesti ' },
            { name: 'Slimnic ', village: 'Tamboesti ' },
            { name: 'Trestieni ', village: 'Tamboesti ' },
            { name: 'Costişa ', simpleName: 'Costisa ', village: 'Tanasoaia ' },
            { name: 'Costişa de Sus ', simpleName: 'Costisa de Sus ', village: 'Tanasoaia ' },
            { name: 'Covrag ', village: 'Tanasoaia ' },
            { name: 'Călimăneasa ', simpleName: 'Calimaneasa ', village: 'Tanasoaia ' },
            { name: 'Feldioara ', village: 'Tanasoaia ' },
            { name: 'Galbeni ', village: 'Tanasoaia ' },
            { name: 'Năneşti ', simpleName: 'Nanesti ', village: 'Tanasoaia ' },
            { name: 'Vladnicu de Jos ', village: 'Tanasoaia ' },
            { name: 'Vladnicu de Sus ', village: 'Tanasoaia ' },
            { name: 'Bordeasca Nouă ', simpleName: 'Bordeasca Noua ', village: 'Tataranu ' },
            { name: 'Bordeasca Veche ', village: 'Tataranu ' },
            { name: 'Mărtineşti ', simpleName: 'Martinesti ', village: 'Tataranu ' },
            { name: 'Vâjâitoarea ', simpleName: 'Vajaitoarea ', village: 'Tataranu ' },
            { name: 'Bătineşti ', simpleName: 'Batinesti ', village: 'Tifesti ' },
            { name: 'Clipiceşti ', simpleName: 'Clipicesti ', village: 'Tifesti ' },
            { name: 'Igeşti ', simpleName: 'Igesti ', village: 'Tifesti ' },
            { name: 'Oleşeşti ', simpleName: 'Olesesti ', village: 'Tifesti ' },
            { name: 'Pătrăşcani ', simpleName: 'Patrascani ', village: 'Tifesti ' },
            { name: 'Sârbi ', simpleName: 'Sarbi ', village: 'Tifesti ' },
            { name: 'Vităneşti ', simpleName: 'Vitanesti ', village: 'Tifesti ' },
            { name: 'Coza ', village: 'Tulnici ' },
            { name: 'Gresu ', village: 'Tulnici ' },
            { name: 'Lepşa ', simpleName: 'Lepsa ', village: 'Tulnici ' },
            { name: 'Colacu ', village: 'Valea Sarii ' },
            { name: 'Mătăcina ', simpleName: 'Matacina ', village: 'Valea Sarii ' },
            { name: 'Poduri ', village: 'Valea Sarii ' },
            { name: 'Prisaca ', village: 'Valea Sarii ' },
            { name: 'Balta Raţei ', simpleName: 'Balta Ratei ', village: 'Vanatori ' },
            { name: 'Jorăşti ', simpleName: 'Jorasti ', village: 'Vanatori ' },
            { name: 'Mirceştii Noi ', simpleName: 'Mircestii Noi ', village: 'Vanatori ' },
            { name: 'Mirceştii Vechi ', simpleName: 'Mircestii Vechi ', village: 'Vanatori ' },
            { name: 'Petreşti ', simpleName: 'Petresti ', village: 'Vanatori ' },
            { name: 'Răduleşti ', simpleName: 'Radulesti ', village: 'Vanatori ' },
            { name: 'Beciu ', village: 'Vartescoiu ' },
            { name: 'Faraoanele ', village: 'Vartescoiu ' },
            { name: 'Olteni ', village: 'Vartescoiu ' },
            { name: 'Pietroasa ', village: 'Vartescoiu ' },
            { name: 'Râmniceanca ', simpleName: 'Ramniceanca ', village: 'Vartescoiu ' },
            { name: 'Burca ', village: 'Vidra ' },
            { name: 'Ireşti ', simpleName: 'Iresti ', village: 'Vidra ' },
            { name: 'Ruget ', village: 'Vidra ' },
            { name: 'Scafari ', village: 'Vidra ' },
            { name: 'Tichiriş ', simpleName: 'Tichiris ', village: 'Vidra ' },
            { name: 'Viişoara ', simpleName: 'Viisoara ', village: 'Vidra ' },
            { name: 'Voloşcani ', simpleName: 'Voloscani ', village: 'Vidra ' },
            { name: 'Şerbeşti ', simpleName: 'Serbesti ', village: 'Vidra ' },
            { name: 'Bahnele ', village: 'Vintileasca ' },
            { name: 'După Măgura ', simpleName: 'Dupa Magura ', village: 'Vintileasca ' },
            { name: 'Neculele ', village: 'Vintileasca ' },
            { name: 'Poiana Stoichii ', village: 'Vintileasca ' },
            { name: 'Tănăsari ', simpleName: 'Tanasari ', village: 'Vintileasca ' },
            { name: 'Livezile ', village: 'Vizantea-Livezi ' },
            { name: 'Mesteacănu ', simpleName: 'Mesteacanu ', village: 'Vizantea-Livezi ' },
            { name: 'Piscu Radului ', village: 'Vizantea-Livezi ' },
            { name: 'Vizantea Mănăstirească ', simpleName: 'Vizantea Manastireasca ', village: 'Vizantea-Livezi ' },
            { name: 'Vizantea Răzăşească ', simpleName: 'Vizantea Razaseasca ', village: 'Vizantea-Livezi ' },
            { name: 'Bodeşti ', simpleName: 'Bodesti ', village: 'Vrancioaia ' },
            { name: 'Muncei ', village: 'Vrancioaia ' },
            { name: 'Ploştina ', simpleName: 'Plostina ', village: 'Vrancioaia ' },
            { name: 'Poiana ', village: 'Vrancioaia ' },
            { name: 'Spineşti ', simpleName: 'Spinesti ', village: 'Vrancioaia ' },
            { name: 'Boţârlău ', simpleName: 'Botarlau ', village: 'Vulturu ' },
            { name: 'Hânguleşti ', simpleName: 'Hangulesti ', village: 'Vulturu ' },
            { name: 'Maluri ', village: 'Vulturu ' },
            {
                name: 'Vadu Roşca ', simpleName: 'Vadu Rosca ', village: 'Vulturu '
            }
        ]
    }
];
