import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from './patients.service';
import { Patient } from './patinet.model';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'patient' },
})
export class PatientsComponent implements OnInit {
  displayedColumns: string[] = ['lastName', 'firstName', 'age', 'email', 'phone', 'address', 'actions'];
  dataSource;

  constructor(private patientService: PatientService, private router: Router) {}
  ngOnInit(): void {
    this.patientService.patient = null;
    this.dataSource = this.patientService.getPatients();
  }

  edit(patient: Patient): void {
    this.patientService.patient = patient;
    this.router.navigate(['/patient']);
  }
}
