<ng-container *ngFor="let interval of intervals">
  <div class="app-PlanningDayView-row"
      [ngClass]="{'app-PlanningDayView-row--currentInterval': isCurrentInterval(interval)}">
    <div class="app-PlanningDayView-hour">
      {{interval.startTime | date:'shortTime'}}
      <!-- {{interval.endTime | date:'shortTime'}} -->
    </div>
    <div class="app-PlanningDayView-event">
      <button mat-button
          [matMenuTriggerFor]="menu">Event</button>
    </div>
    <div class="app-PlanningDayView-options">
      <button mat-icon-button
          aria-label="Options"
          [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>Add New</button>
      <button mat-menu-item>Edit</button>
    </mat-menu>
  </div>

</ng-container>
