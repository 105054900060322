import { Injectable } from '@angular/core';
import { PlanningHour } from './planning.model';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class PlanningService {

  selectedDate: BehaviorSubject<moment.Moment> = new BehaviorSubject(moment());
  constructor() { }

  convertToPlanningHour(hour: string): PlanningHour {
    const result = hour.split(':');
    return {
      hour: parseInt(result[0], 10),
      minutes: parseInt(result[1], 10),
    };
  }
  convertToDate(hour: string): Date {
    const [h, m] = hour.split(':');
    return new Date(new Date().setHours(parseInt(h, 10), parseInt(m, 10), 0, 0));
  }
}
