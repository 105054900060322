import { Component, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';
import { PlanningService } from '../planning.service';

@Component({
  selector: 'app-planning-calendar',
  templateUrl: './planning-calendar.component.html',
  styleUrls: ['./planning-calendar.component.scss'],
})
export class PlanningCalendarComponent {
  selectedDate = moment();

  @ViewChild('calendar')
  calendar: MatCalendar<Date>;

  constructor(private planningService: PlanningService) { }

  dateChanged(): void {
    this.planningService.selectedDate.next(this.selectedDate);
  }
}
