import { Injectable } from '@angular/core';
import { Patient } from './patinet.model';

@Injectable({ providedIn: 'root' })
export class PatientService {
  private currentPatient: Patient;
  constructor() { }

  set patient(p: Patient) {
    this.currentPatient = p;
  }
  get patient(): Patient {
    return this.currentPatient;
  }

  getPatients(): Patient[] {
    return [
      {
        id: '5efe057ae769e36824d01640',
        age: 30,
        firstName: 'Bernadine',
        lastName: 'Strong',
        company: 'KONGENE',
        email: 'bernadine.strong@kongene.biz',
        phone: '+1 (810) 417-2581',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a1a722d58df9c1051',
        age: 24,
        firstName: 'Daugherty',
        lastName: 'Hunter',
        company: 'ANDRYX',
        email: 'daugherty.hunter@andryx.info',
        phone: '+1 (926) 554-3416',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a278e50cfcad5357c',
        age: 26,
        firstName: 'Colette',
        lastName: 'Mendez',
        company: 'ESCENTA',
        email: 'colette.mendez@escenta.net',
        phone: '+1 (818) 509-2123',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a804d52e207b4cf41',
        age: 35,
        firstName: 'Lizzie',
        lastName: 'Walker',
        company: 'PREMIANT',
        email: 'lizzie.walker@premiant.com',
        phone: '+1 (960) 403-2580',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a14cec90b7340c8ea',
        age: 20,
        firstName: 'Oneal',
        lastName: 'Morton',
        company: 'UNISURE',
        email: 'oneal.morton@unisure.io',
        phone: '+1 (959) 400-2116',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057ae0268efc90ac3950',
        age: 34,
        firstName: 'Whitley',
        lastName: 'Pace',
        company: 'INVENTURE',
        email: 'whitley.pace@inventure.biz',
        phone: '+1 (887) 425-3817',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a6a33a0cc9524de36',
        age: 21,
        firstName: 'Delaney',
        lastName: 'Jimenez',
        company: 'KRAGGLE',
        email: 'delaney.jimenez@kraggle.org',
        phone: '+1 (874) 524-2820',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a96f7ae29fdb17dab',
        age: 20,
        firstName: 'Fields',
        lastName: 'Buck',
        company: 'CAXT',
        email: 'fields.buck@caxt.ca',
        phone: '+1 (824) 591-3147',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057ae6a29e183483648e',
        age: 25,
        firstName: 'Chen',
        lastName: 'Frost',
        company: 'GEEKOSIS',
        email: 'chen.frost@geekosis.name',
        phone: '+1 (800) 421-3976',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a8012fb8a81cdd19a',
        age: 21,
        firstName: 'Chan',
        lastName: 'Hill',
        company: 'OULU',
        email: 'chan.hill@oulu.us',
        phone: '+1 (836) 417-3487',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a39bdab7360346f86',
        age: 28,
        firstName: 'Powers',
        lastName: 'Mcguire',
        company: 'KROG',
        email: 'powers.mcguire@krog.me',
        phone: '+1 (896) 548-2386',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a69198be5184f4e7d',
        age: 33,
        firstName: 'Ida',
        lastName: 'Dominguez',
        company: 'GEEKOLA',
        email: 'ida.dominguez@geekola.tv',
        phone: '+1 (892) 582-2234',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a87c49ef9d6a5ebad',
        age: 26,
        firstName: 'Keith',
        lastName: 'Rogers',
        company: 'KANGLE',
        email: 'keith.rogers@kangle.biz',
        phone: '+1 (968) 497-2838',

        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a8c87e9306d0a0c67',
        age: 25,
        firstName: 'Jimmie',
        lastName: 'Raymond',
        company: 'RENOVIZE',
        email: 'jimmie.raymond@renovize.info',
        phone: '+1 (813) 523-2551',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057ade7930021b6576f3',
        age: 38,
        firstName: 'Mandy',
        lastName: 'Simpson',
        company: 'DIGIGENE',
        email: 'mandy.simpson@digigene.net',
        phone: '+1 (951) 474-2605',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a7f3140b8af8f6ef2',
        age: 33,
        firstName: 'Tami',
        lastName: 'Waters',
        company: 'RETROTEX',
        email: 'tami.waters@retrotex.com',
        phone: '+1 (907) 537-3051',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057ab6c0575204576273',
        age: 30,
        firstName: 'Vargas',
        lastName: 'Ratliff',
        company: 'KONNECT',
        email: 'vargas.ratliff@konnect.io',
        phone: '+1 (922) 580-2707', address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a0634b70b56e45988',
        age: 31,
        firstName: 'Dickson',
        lastName: 'Daniels',
        company: 'AMTAS',
        email: 'dickson.daniels@amtas.biz',
        phone: '+1 (894) 494-2905',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a5755ff836b6e4ce8',
        age: 38,
        firstName: 'Acosta',
        lastName: 'Strickland',
        company: 'BLUPLANET',
        email: 'acosta.strickland@bluplanet.org',
        phone: '+1 (997) 478-3279',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
      {
        id: '5efe057a7144cb2626c15bad',
        age: 39,
        firstName: 'Ella',
        lastName: 'Miller',
        company: 'LYRICHORD',
        email: 'ella.miller@lyrichord.ca',
        phone: '+1 (926) 477-3358',
        address: {
          street: 'Hinsdale Street',
          streetNumber: 927,
          building: null,
          flat: null,
          city: 'Vincent',
          district: 'Minnesota',
          code: '3826',
        }
      },
    ];
  }
}
