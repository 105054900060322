import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { PlanningService } from '../planning.service';
import { PlanningInterval } from '../planning.model';

export enum PlanningFrequency {
  '15min' = 15,
  '30min' = 30,
  '60min' = 60,
}

@Component({
  selector: 'app-planning-day-view',
  templateUrl: './planning-day-view.component.html',
  styleUrls: ['./planning-day-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-PlanningDayView'
  }
})
export class PlanningDayViewComponent implements OnInit {
  @Input()
  frequency: 15 | 30 | 60 = 30;
  @Input()
  startingHour: string = '09:30';
  @Input()
  endingHour: string = '18:45';

  intervals: PlanningInterval[] = [];
  currentDay = new Date();

  startHour: Date;
  endHour: Date;

  constructor(private planningService: PlanningService) { }

  ngOnInit(): void {
    this.generateIntervals();
    this.planningService.selectedDate.subscribe((date: moment.Moment) => {
      console.log('current selected Date', date.toDate());
    });

  }
  private generateIntervals(): void {
    this.startHour = this.planningService.convertToDate(this.startingHour);
    this.endHour = this.planningService.convertToDate(this.endingHour);

    do {
      const startTime = new Date(this.startHour);
      const endTime = new Date(this.startHour.setMinutes(this.startHour.getMinutes() + this.frequency));
      this.intervals.push({
        startTime: startTime,
        endTime: endTime
      });
      this.startHour = new Date(endTime);
    } while (this.startHour.getTime() < this.endHour.getTime());
  }
  isCurrentInterval(interval: PlanningInterval): boolean {
    const now = new Date();
    console.log(now, interval.startTime, interval.endTime)
    return now >= interval.startTime && now < interval.endTime;
  }
}
