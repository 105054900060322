import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PlanningCalendarComponent } from './planning-calendar/index';
import { PlanningDayViewComponent } from './planning-day-view/index';
import { PlanningComponent } from './planning.component';


@NgModule({
  declarations: [
    PlanningComponent,
    PlanningDayViewComponent,
    PlanningCalendarComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatMomentDateModule,
    MatDatepickerModule
  ],
})
export class PlanningModule { }
