import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/index';
import { PatientFormComponent } from './patients';
import { PatientsComponent } from './patients/patients.component';
import { PlanningComponent } from './planning/planning.component';
import { SettingsComponent } from './settings/settings.component';
import { WarrantyComponent } from './warranty/warranty.component';

const routes: Routes = [
  { path: '', redirectTo: '/patients', pathMatch: 'full' },
  { path: 'admin', component: AdminComponent },
  { path: 'patients', component: PatientsComponent },
  {
    path: 'patient',
    component: PatientFormComponent,
    children: [{ path: 'new', component: PatientFormComponent }],
  },
  { path: 'planning', component: PlanningComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'warranty', component: WarrantyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
