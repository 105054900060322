import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Schedule } from './settings.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  settingsForm: FormGroup = new FormGroup({});
  orar: any = {};
  currentSchedule: Schedule = {
    monday: false,
    mondayStartTime: '00:00',
    mondayEndTime: '00:00',
    tuesday: true,
    tuesdayStartTime: '15:00',
    tuesdayEndTime: '18:00',
    wednesday: true,
    wednesdayStartTime: '15:00',
    wednesdayEndTime: '18:00',
    thursday: true,
    thursdayStartTime: '15:00',
    thursdayEndTime: '18:00',
    friday: true,
    fridayStartTime: '15:00',
    fridayEndTime: '18:00',
    saturday: true,
    saturdayStartTime: '09:15',
    saturdayEndTime: '13:00',
    sunday: false,
    sundayStartTime: '00:00',
    sundayEndTime: '00:00',
  };

  constructor(@Inject(FormBuilder) private fb: FormBuilder) { }

  ngOnInit(): void {
    this.settingsForm = this.fb.group({
      monday: [this.currentSchedule.monday, []],
      mondayStartTime: [{
        value: this.currentSchedule.mondayStartTime,
        disabled: !this.currentSchedule.monday
      }, []],
      mondayEndTime: [{
        value: this.currentSchedule.mondayEndTime,
        disabled: !this.currentSchedule.monday
      }, []],
      tuesday: [this.currentSchedule.tuesday, []],
      tuesdayStartTime: [{
        value: this.currentSchedule.tuesdayStartTime,
        disabled: !this.currentSchedule.tuesday
      }, []],
      tuesdayEndTime: [{
        value: this.currentSchedule.tuesdayEndTime,
        disabled: !this.currentSchedule.tuesday
      }, []],
      wednesday: [this.currentSchedule.wednesday, []],
      wednesdayStartTime: [{
        value: this.currentSchedule.wednesdayStartTime,
        disabled: !this.currentSchedule.wednesday
      }, []],
      wednesdayEndTime: [{
        value: this.currentSchedule.wednesdayEndTime,
        disabled: !this.currentSchedule.wednesday
      }, []],
      thursday: [this.currentSchedule.thursday, []],
      thursdayStartTime: [{
        value: this.currentSchedule.thursdayStartTime,
        disabled: !this.currentSchedule.thursday
      }, []],
      thursdayEndTime: [{
        value: this.currentSchedule.thursdayEndTime,
        disabled: !this.currentSchedule.thursday
      }, []],
      friday: [this.currentSchedule.friday, []],
      fridayStartTime: [{
        value: this.currentSchedule.fridayStartTime,
        disabled: !this.currentSchedule.friday
      }, []],
      fridayEndTime: [{
        value: this.currentSchedule.fridayEndTime,
        disabled: !this.currentSchedule.friday
      }, []],
      saturday: [this.currentSchedule.saturday, []],
      saturdayStartTime: [{
        value: this.currentSchedule.saturdayStartTime,
        disabled: !this.currentSchedule.saturday
      }, []],
      saturdayEndTime: [{
        value: this.currentSchedule.saturdayEndTime,
        disabled: !this.currentSchedule.saturday
      }, []],
      sunday: [this.currentSchedule.sunday, []],
      sundayStartTime: [{
        value: this.currentSchedule.sundayStartTime,
        disabled: !this.currentSchedule.sunday
      }, []],
      sundayEndTime: [{
        value: this.currentSchedule.sundayEndTime,
        disabled: !this.currentSchedule.sunday
      }, []],
    });
  }
  
  save(): void {
    console.log(this.settingsForm.value);
  }

  toggleDisable(val: MatCheckboxChange, day: string): void {
    if (val.checked) {
      this.settingsForm.controls[day + 'StartTime'].enable();
      this.settingsForm.controls[day + 'EndTime'].enable();
    } else {
      this.settingsForm.controls[day + 'StartTime'].disable();
      this.settingsForm.controls[day + 'EndTime'].disable();
    }
  }

}
