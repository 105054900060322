<mat-sidenav-container class="app-Shell-sidenav-container">
  <mat-sidenav #sidenav
      mode="side"
      opened
      class="app-Shell-sidenav">
    <mat-nav-list role="list"
        color="primary"
        class="app-Shell-sidenav-menu">
      <a mat-list-item
          [routerLink]="['/planning']">Planificare </a>
      <a mat-list-item
          [routerLink]="['/patients']">Pacienti </a>
      <a mat-list-item
          [routerLink]="['/admin']">Administrare </a>
      <a mat-list-item
          [routerLink]="['/warranty']">Garantii </a>
      <a mat-list-item
          [routerLink]="['/settings']">Setari </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="app-Shell-sidenav-content">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
