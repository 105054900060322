import { Component } from '@angular/core';
import { PlanningService } from './planning.service';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
  providers: [PlanningService],
})
export class PlanningComponent {}
